import React from "react";
import { Grid,FormControlLabel, Radio } from "@material-ui/core";
import {convertToIsoFormate} from '../../shared/methods/methods';

const CustomerEmail = ({ ind,selectedRadioVal, item }) => {
  return (
    <div >
    <>
      <Grid container>
        <Grid item md={12} xs={12}>
        <div className="send-to-insurer">
          <FormControlLabel value={ind} control={<Radio />}  checked={selectedRadioVal == ind}/>
        </div>
        
          <Grid container>
            <Grid md={12} xs={12}>
              <p className="subject-box"><strong>Subject: {item.Subject}</strong><span className="date-time">{convertToIsoFormate(item.DisplayDate)}</span></p>
            </Grid>
            <Grid md={12} xs={12}>
                {
              item.From &&
              <p className="subject-box">
                <span className="from-to">From: {item.DisplaySenderName} ({item.From})</span>
              </p>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div id="Mail-body" dangerouslySetInnerHTML={{ __html: item.Body }}></div>
    </>
    </div>
  );
};

export default CustomerEmail;
