import React from 'react'

export default function BrokenPage() {
    return (
        <div className='broken-page'>
            <h1 className='error'>404</h1>
            <h1 className='text'>Page Not Found</h1>
        </div>
    )
}
