import React, { useRef, useState, useContext, useEffect } from "react";
import CKEditor from "react-ckeditor-component";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  FormHelperText,
  InputBase,
} from "@material-ui/core";
import {
  GetHTMLTemplate,
  UpdateTicketRemarks,
  GetHtmlTemplate_V2,
} from "../../../../../constants/apiUrls";
import { GET, POST } from "../../../../../services/apiServices";
import { useSnackbar } from "notistack";
import {
  validateEmail,
  convertToIsoFormate,
  handleDrag,
  handleDragIn,
  handleDragOut,
  handleDrop,
} from "../../../../../shared/methods/methods";
import Spinner from "../../../../../shared/components/Spinner";
import {
  From,
  DisplayName,
  CAR,
  isCommunicationEligible,
  isReplyThreadEligible,
  SME,
} from "../../../../../Config";
import CloseIcon from "@material-ui/icons/Close";
import CreateTicketDialog from "./CreateTicketDialog";
import ConfirmationDialog from "../../../../../shared/components/ConfirmationDialog";
import TicketContext from "../../../../../TicketContext";
import FileUploadComponent from "../../../../../shared/components/FileUploadComponent";
import CommunicationDialogForTriggers from "./CommunicationDialogForTriggers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    minWidth: "100%",
  },
}));

export default function ReplyBoxContent({
  ticketData,
  templateList,
  oldTicketDetails,
  AccessTypeVal,
  viewtype,
  isValidForSME,
  goToTemplate,
  setGoToTemplate,
  index,
  fetchLatestTicketCommunication,
}) {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const combineCCAndTo = (returnType = "") => {
    let data = [];
    if (ticketData.CCEmail && Array.isArray(ticketData.CCEmail)) {
      ticketData.CCEmail.forEach((email) => {
        if (email) {
          data.push(email);
        }
      });
    }
    if (ticketData.ToReceipent && Array.isArray(ticketData.ToReceipent)) {
      ticketData.ToReceipent.forEach((email) => {
        if (email) {
          data.push(email);
        }
      });
    }

    let unique = data.filter(onlyUnique);

    if (returnType === "string") {
      return unique.join(",");
    }

    return unique;
  };

  const classes = useStyles();
  const ticketContext = useContext(TicketContext);
  const ReplyButtRef = React.useRef(null);
  const [inputMessage, setInputMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [canResopnse, setCanResopnse] = useState("");
  const [earlyCanResopnse, setEarlyCanResopnse] = useState("");
  const [replyInProgress, setReplyInProgress] = useState(false);
  const [replyType, setReplyType] = useState("");
  const [isCreateTicketDialogOpen, setIsCreateTicketDialogOpen] =
    useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  // const [CCEmails, setCCEmails] = useState(combineCCAndTo("string"));
  const [CCEmails, setCCEmails] = useState("");
  const [CCForwardEmails, setCCForwardEmails] = useState("");
  const [CCErrMsg, setCCErrMsg] = useState("");
  const [ToEmails, setToEmails] = useState("");
  const [ToErrMsg, setToErrMsg] = useState("");
  const [isCommunicationDialogOpen, setIsCommunicationDialogOpen] =
    useState(false);
  const [triggerInput, setTriggerInput] = useState([]);
  const [values, setValues] = useState({});
  const [requestId, setRequestId] = useState(null);
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
  const [replyAppendMsg, setReplyAppendMsg] = useState("");
  const [subject, setSubject] = useState("");
  const [config, setConfig] = useState({
    // height : 'auto',
    width: "auto",
    toolbarGroups: [
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },

      { name: "links", groups: ["links"] },
      { name: "insert", groups: ["insert"] },

      { name: "colors", groups: ["colors"] },
      { name: "tools", groups: ["tools"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    format_tags: "p;h1;h2;h3;pre;div",
    removeDialogTabs: "image:advanced;link:advanced",
    allowedContent: true,
    // readOnly: false,
  });
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);

  useEffect(() => {
    if (replyType == "replyAll") {
      let data = [];
      if (ticketData.CCEmail && Array.isArray(ticketData.CCEmail)) {
        ticketData.CCEmail.forEach((email) => {
          if (email) {
            data.push(email);
          }
        });
      }

      let unique = data.filter(onlyUnique).join(",");

      setCCEmails(unique);
    } else {
      setCCEmails("");
    }
  }, [replyType]);

  useEffect(() => {
    if (isCommunicationDialogOpen && canResopnse != "select") {
      setTriggerInput(
        templateList.filter((i) => i.TriggerName == canResopnse)[0].TriggerInput
      );
    }
  }, [isCommunicationDialogOpen]);

  const GetEmailList = (emails) => {
    const emailsArr = emails.split(",");
    const newArr = [];
    emailsArr.forEach((email) => {
      newArr.push(email.trim());
    });
    return newArr;
  };

  // const getAllTicketSummary = (RefObjectId, TicketDetailsID) => {
  //   ticketContext.setAllTicketsLoading(true);
  //   GET(`${GetTicketSummary}/${RefObjectId}/${TicketDetailsID}`)
  //     .then((response) => {
  //       if (response.ErrorCode === 0) {
  //         const sortedData1 = response.Data.AllTicketList
  //           ? response.Data.AllTicketList.filter(
  //             (i) => i.StatusID != 3 && i.StatusID != 4
  //           ).sort(
  //             (a, b) =>
  //               (b.LastUpdatedOn || b.CreatedOn) -
  //               (a.LastUpdatedOn || a.CreatedOn)
  //           )
  //           : [];
  //         const sortedData2 = response.Data.AllTicketList
  //           ? response.Data.AllTicketList.filter((i) => i.StatusID == 3).sort(
  //             (a, b) =>
  //               (b.LastUpdatedOn || b.CreatedOn) -
  //               (a.LastUpdatedOn || a.CreatedOn)
  //           )
  //           : [];
  //         const sortedData3 = response.Data.AllTicketList
  //           ? response.Data.AllTicketList.filter((i) => i.StatusID == 4).sort(
  //             (a, b) =>
  //               (b.LastUpdatedOn || b.CreatedOn) -
  //               (a.LastUpdatedOn || a.CreatedOn)
  //           )
  //           : [];
  //         ticketContext.setAllTickets({
  //           ...response.Data,
  //           AllTicketList: [...sortedData1, ...sortedData2, ...sortedData3],
  //         });
  //       } else {
  //         enqueueSnackbar("Something went wrong!", {
  //           variant: "error",
  //           onClick: () => {
  //             closeSnackbar();
  //           },
  //         });
  //       }
  //       ticketContext.setAllTicketsLoading(false);
  //       ticketContext.setActiveTicketId(response.Data.TicketId);
  //     })
  //     .catch((err) => {
  //       ticketContext.setAllTicketsLoading(false);
  //       enqueueSnackbar("Something went wrong!", {
  //         variant: "error",
  //         onClick: () => {
  //           closeSnackbar();
  //         },
  //       });
  //     });
  // };

  // const GetTicketDetailsByTicketIdApi = (RefObjectId, TicketDetailsID) => {
  //   GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
  //     .then((response) => {
  //       if (response.ErrorCode === 0) {
  //         const { IsCallBackRequest, IsInsurerBucket } = response.Data;

  //         let callbackVal;
  //         if (IsCallBackRequest === "0") {
  //           callbackVal = "No";
  //         } else if (IsCallBackRequest === "1") {
  //           callbackVal = "Yes";
  //         } else if (IsCallBackRequest === "2") {
  //           callbackVal = "Completed";
  //         }

  //         ticketContext.setFormValues((prevValue) => ({
  //           ...prevValue,
  //           ...response.Data,
  //           originalLeadID: response.Data.LeadID,
  //           IsCallBackRequest: +IsCallBackRequest,
  //           CallbackVal: callbackVal,
  //           SendToInsurer: IsInsurerBucket,
  //           SendToInsurerMsg: "",
  //         }));
  //         ticketContext.setoldTicketDetails((prevValue) => ({
  //           ...prevValue,
  //           ...response.Data,
  //           IsCallBackRequest: +IsCallBackRequest,
  //           CallbackVal: callbackVal,
  //           SendToInsurer: IsInsurerBucket,
  //           SendToInsurerMsg: "",
  //         }));
  //       } else {
  //         enqueueSnackbar("Something went wrong!", {
  //           variant: "error",
  //           onClick: () => {
  //             closeSnackbar();
  //           },
  //         });
  //         console.log('error in GetTicketDetailsByTicketIdApi reaplyMailContent')
  //       }
  //     })
  //     .catch((err) => {
  //       enqueueSnackbar("Something went wrong!", {
  //         variant: "error",
  //         onClick: () => {
  //           closeSnackbar();
  //         },
  //       });
  //       console.log(err);
  //     });
  // };

  const handleReplySend = () => {
    if (replyInProgress || CCErrMsg || ToErrMsg) {
      return;
    }
    const payload = {
      PrevObjectID: oldTicketDetails.RefObjectId,
      TicketId: oldTicketDetails.TicketId,
      TicketDetailId: oldTicketDetails.TicketDetailId,
      Subject: subject ? subject : ticketData.Subject,
      ConversationTopic: ticketData.ConversationTopic,
      LeadID: oldTicketDetails.LeadID,
      CustomerName: oldTicketDetails.CustomerName,
      CustomerID: oldTicketDetails.CustomerId,
      ProductID: oldTicketDetails.ProductId,
      ProductName: oldTicketDetails.ProductName,
      IsSpam: ticketData.IsSpam,
      IsManualComment: ticketData.IsManualComment,
      From: From,
      CCEmail:
        replyType === "forward" && CCForwardEmails
          ? GetEmailList(CCForwardEmails)
          : "",
      ToReceipent: GetEmailList(ToEmails),
      BccEmail: ticketData.BccEmail,
      Body: inputMessage,
      IsInsurer: false,
      IsMoved: ticketData.IsMoved,
      MailAttachments:
        replyType == "forward"
          ? ticketData.MailAttachments
          : selectedFile.length > 0
          ? selectedFile.map((item) => ({
              FileName: item.FileName,
              AttachemntContent: item.AttachemntContent,
            }))
          : [],
      HasAttachment: selectedFile.length > 0 ? true : false,
      DisplaySenderName: DisplayName,
      TicketSource: oldTicketDetails.Source,
      ActionType: ticketData.ActionType,
      StatusName: oldTicketDetails.Status,
      SubStatusName: oldTicketDetails.SubStatusName,
      InsurerID: oldTicketDetails.SupplierId,
      ParentID: ticketData.ParentID,
      StatusID: oldTicketDetails.StatusID,
      SubStatusID: oldTicketDetails.SubStatusID,
      GroupId: ticketData.GroupId,
      TicketSubSource: ticketData.TicketSubSource,
      RequestId: requestId,
      EscalationType: oldTicketDetails.EscalationType,
      IssueTypeName: oldTicketDetails.IssueName,
      TicketDetailsID: oldTicketDetails.TicketDetailsID,
      IsJAG:
        oldTicketDetails && oldTicketDetails.IsJAG ? oldTicketDetails.IsJAG : 0,
      JAGEmployeeId:
        oldTicketDetails && oldTicketDetails.JAGEmployeeId
          ? oldTicketDetails.JAGEmployeeId
          : "",
      IssueTypeID:
        oldTicketDetails && oldTicketDetails.IssueTypeId
          ? oldTicketDetails.IssueTypeId
          : 0,
      HCSSource: oldTicketDetails && oldTicketDetails.HCSSource
    };
    setReplyInProgress(true);
    POST(
      `${UpdateTicketRemarks}/${
        canResopnse
          ? canResopnse
          : templateList && templateList.length > 0
          ? templateList[0].TriggerName
          : "DefaultTemplate"
      }`,
      payload
    )
      .then((res) => {
        if (res.ErrorCode === 0) {
          fetchLatestTicketCommunication({
            RefObjectId: oldTicketDetails.RefObjectId,
            TicketDetailsID: oldTicketDetails.TicketDetailsID,
            Source: "WeCare",
          });

          // if (replyType === "reply" || replyType === "replyAll") {
          //   // getAllTicketSummary(
          //   //   oldTicketDetails.RefObjectId,
          //   //   oldTicketDetails.TicketDetailsID
          //   // );
          //   fetchLatestTicketdetails({
          //     RefObjectId : oldTicketDetails.RefObjectId,
          //     TicketDetailsID : oldTicketDetails.TicketDetailsID
          //   })
          //   // GetTicketDetailsByTicketIdApi(
          //   //   oldTicketDetails.RefObjectId,
          //   //   oldTicketDetails.TicketDetailsID
          //   // );
          // }

          enqueueSnackbar(res.Message || "Success", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
          handleDisCard();
        } else {
          enqueueSnackbar(res.Message || "Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setReplyInProgress(false);
      })
      .catch((err) => {
        console.log(err);
        setReplyInProgress(false);
        window.location.reload(false);
      });
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onEditorChange = (event) => {
    setInputMessage(event.editor.getData());
  };

  const [selectedFile, setselectedFile] = useState([]);

  const hiddenFileInput = useRef(null);

  // Changes Templates based on the dropdown selected---> Use Case 1. Reply 2. Reply All
  const getHtmlTemplateApi = (triggerName, msg = "") => {
    return;
    // let payload;
    // let apiEndPoint = GetHtmlTemplate;
    // if (isCommunicationEligible(oldTicketDetails.ProductId)) {
    //   apiEndPoint = GetHtmlTemplate_V2;
    //   payload = {
    //     TriggerName: triggerName,
    //     LeadID: oldTicketDetails.LeadID,
    //     TicketId: oldTicketDetails.TicketId,
    //     CommunicationType: 1,
    //     ProductId: oldTicketDetails.ProductId,
    //     RefObjectId: oldTicketDetails.RefObjectId,
    //   };
    //   setIsCommunicationDialogOpen(true);
    // } else {
    //   payload = {
    //     ProductID: oldTicketDetails.ProductId,
    //     TriggerName: triggerName,
    //     RefObjectID: oldTicketDetails.RefObjectId,
    //     Type: 1,
    //   };
    // }

    // POST(apiEndPoint, payload)
    //   .then((res) => {
    //     if (res.ErrorCode === 0) {
    //       setInputMessage(
    //         isCommunicationEligible(oldTicketDetails.ProductId)
    //           ? res.Data.HtmlBody + msg
    //           : res.Data.EmailTemplate + msg
    //       );
    //       setSubject(res.Data.Subject || res.Data.SubjectTemplate || ticketData.Source);
    //       setSendBtnDisabled(false)
    //     } else {
    //       enqueueSnackbar("Something went wrong!", {
    //         variant: "error",
    //         onClick: () => {
    //           closeSnackbar();
    //         },
    //       });
    //       setSendBtnDisabled(false)
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const getHTMLForNewCar = (triggerName, _msg = "") => {
    setEarlyCanResopnse(canResopnse);
    setIsTemplateLoaded(true);
    const payload = {
      triggerName: triggerName,
      leadID: oldTicketDetails.LeadID,
      ticketId: oldTicketDetails.TicketId.toString(),
      communicationType: 1,
      productId: oldTicketDetails.ProductId,
      refObjectId: oldTicketDetails.RefObjectId,
      triggerInput: {
        ShortfallPayment:
          values && values["shortfallPayment"]
            ? values["shortfallPayment"]
            : "",
        PaymentLink:
          values && values["PaymentLink"] ? values["PaymentLink"] : "",
        RejectionReason:
          values && values["RejectionReason"] ? values["RejectionReason"] : "",
        Reason: values && values["Reason"] ? values["Reason"] : "",
        UserComment:
          values && values["UserComment"]
            ? values["UserComment"].replace(new RegExp("\r?\n", "g"), "<br />")
            : "",
      },
      CustomerId : oldTicketDetails?.CustomerId,
    };
    POST(GetHtmlTemplate_V2, payload)
      .then((res) => {
        setSendBtnDisabled(false);
        let msg = `
        <div>---------- Forwarded message ----------</div>
        <div>From: ${ticketData.From}</div>
        <div>Sent: ${
          ticketData.DisplayDate
            ? convertToIsoFormate(ticketData.DisplayDate)
            : ""
        }</div>
        <div>To: ${
          ticketData.ToReceipent &&
          Array.isArray(ticketData.ToReceipent) &&
          FilteredEmailList(ticketData.ToReceipent).length > 0
            ? FilteredEmailList(ticketData.ToReceipent).join(",")
            : ""
        }</div>
        <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""}</div>
        <div>${ticketData.Body}</div>
        `;
        if (res.ErrorCode === 0) {
          if (replyType == "forward") {
            setInputMessage(res.Data.HtmlBody + msg);
            // setSubject(res.Data.Subject);
          } else {
            setInputMessage(res.Data.HtmlBody + _msg);
          }
          setSubject(res.Data.Subject);
          setIsCommunicationDialogOpen(false);
          setValues({});
          setRequestId(res.Data.RequestId);
          setIsTemplateLoaded(false);
        } else {
          enqueueSnackbar(res.Message, {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          setCanResopnse(canResopnse);
          // setInputMessage("")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCanResponseChange = (e) => {
    setCanResopnse(e.target.value);
    setSendBtnDisabled(true);
    // setEarlyCanResopnse(e.target.value);
    if (e.target.value != "select") {
      setConfig((config) => ({
        ...config,
        readOnly: false,
      }));
      if (isCommunicationEligible(oldTicketDetails.ProductId)) {
        if (
          templateList.filter((i) => i.TriggerName == e.target.value)[0]
            .TriggerInput.length > 0
        ) {
          setIsCommunicationDialogOpen(true);
        } else {
          getHTMLForNewCar(e.target.value, replyAppendMsg);
        }
      } else {
        getHtmlTemplateApi(e.target.value, replyAppendMsg);
      }
    } else if (e.target.value == "select") {
      setConfig((config) => ({
        ...config,
        readOnly: true,
      }));
      setInputMessage("");
    }
  };

  const handleDisCard = () => {
    setInputMessage("");
    setCanResopnse("");
    setselectedFile([]);
    setIsOpen(false);
    setReplyType("");
  };

  const FilteredEmailList = (emails) => {
    const data = [];
    emails.forEach((email) => {
      if (email) {
        data.push(email);
      }
    });
    return data;
  };
  useEffect(() => {
    if (goToTemplate && index == 0) {
      ReplyButtRef.current.click(); // focusing on the latest mail replyall button
      handleReplyFromCompose();
    }
  }, [goToTemplate]);

  const handleReplyFromCompose = () => {
    setGoToTemplate(false);
    setCanResopnse("HI_Refund_Em_CancellationCxIneligible");
    let msg = "";
    if (isReplyThreadEligible(ticketData.TicketSource)) {
      msg = `
      <br/>
      <div style="text-align:left; border-top:1px dashed #ccc;padding:5px;">
      <div>From: ${ticketData.From}</div>
      <div>Sent: ${
        ticketData.DisplayDate
          ? convertToIsoFormate(ticketData.DisplayDate)
          : ""
      }</div>
      <div>To: ${
        ticketData.ToReceipent &&
        Array.isArray(ticketData.ToReceipent) &&
        FilteredEmailList(ticketData.ToReceipent).length > 0
          ? FilteredEmailList(ticketData.ToReceipent).join(",")
          : ""
      }</div>
      <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""}</div>
      <div>${ticketData.Body}</div>
      </div>
      `;
    }
    setReplyAppendMsg(msg);
    getHtmlTemplateApi("HI_Refund_Em_CancellationCxIneligible", msg);
  };

  const handleReplyClick = (replyTypeVal) => {
    let msg = "";
    switch (replyTypeVal) {
      case "forward":
        msg = `
          <div>---------- Forwarded message ----------</div>
          <div>From: ${ticketData.From}</div>
          <div>Sent: ${
            ticketData.DisplayDate
              ? convertToIsoFormate(ticketData.DisplayDate)
              : ""
          }</div>
          <div>To: ${
            ticketData.ToReceipent &&
            Array.isArray(ticketData.ToReceipent) &&
            FilteredEmailList(ticketData.ToReceipent).length > 0
              ? FilteredEmailList(ticketData.ToReceipent).join(",")
              : ""
          }</div>
          <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""}</div>
          <div>${ticketData.Body}</div>
          `;
        setInputMessage(msg);
        setCCEmails("");

      default:
        break;
    }

    if (!isOpen) {
      setIsOpen(true);
    }
    if (replyType === replyTypeVal && isOpen) {
      handleDisCard();
    } else {
      setReplyType(replyTypeVal);
    }
  };

  const handleRemoveFile = (id) => {
    const fileList = selectedFile.filter((item) => item.id !== id);
    setselectedFile(fileList);
  };

  const sourceArr = ["PB Manual", "PBReply"];

  const handleCCAndTo = (e, type) => {
    const emails = e.target.value.split(",");
    let isErrMsg = false;
    emails.forEach((email) => {
      const trimmedEmail = email.trim();
      if (!validateEmail(trimmedEmail)) {
        type === "cc"
          ? setCCErrMsg("Invalid email")
          : setToErrMsg("Invalid email");
        isErrMsg = true;
        return;
      } else {
        isErrMsg = false;
      }
    });

    if (!isErrMsg || !e.target.value) {
      type === "cc" ? setCCErrMsg("") : setToErrMsg("");
    }

    type === "cc" && replyType != "forward"
      ? setCCEmails(e.target.value)
      : type === "cc" && replyType == "forward"
      ? setCCForwardEmails(e.target.value)
      : setToEmails(e.target.value);
  };

  const sendClicked = () => {
    if (ToEmails && !ToErrMsg) {
      inputMessage && inputMessage.length > 0
        ? setShowConfirmationBox(true)
        : enqueueSnackbar("Mail body cannot be empty!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
      return;
    } else {
      enqueueSnackbar("Please enter recepient email address", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    }
  };

  // const showForwardButton = (source) =>{
  //   let allowedSources = ["care","myaccount"]
  //  if(allowedSources.includes(source.toLowerCase())){
  //   return true
  //  }
  //  else{
  //   return false
  //  }

  // }

  return (
    <>
      <Grid>
        <Grid container>
          {console.log("can response-->", canResopnse)}
          <Grid item md={8} xs={12}>
            <Button
              className={
                "response-chip" + (replyType == "forward" ? " active" : "")
              }
              onClick={() => handleReplyClick("forward")}
              // disabled={!isValidForSME}
            >
              <img src="/images/forward.svg" alt="forward " /> forward
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {isOpen && (
        <div
          className="reply-popup"
          onDragOver={handleDrag}
          onDrop={(e) => handleDrop(e, setselectedFile, hiddenFileInput)}
          onDragLeave={handleDragOut}
          onDragEnter={handleDragIn}
        >
          <Grid container spacing={1}>
            {replyType !== "forward" && (
              <Grid item md={4} sm={4} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Can Response
                  </InputLabel>
                  <Select
                    native
                    value={canResopnse}
                    onChange={(e) => handleCanResponseChange(e)}
                    label="Can Response"
                    inputProps={{
                      name: "canResponse",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {isCommunicationEligible(oldTicketDetails.ProductId) ? (
                      <option value="select">Select</option>
                    ) : null}
                    {templateList.map((item) => {
                      return (
                        <option value={item.TriggerName}>
                          {item.Description}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container className="mail-to-info">
            <Grid item md={12} sm={12} xs={12} className="border-bottom">
              <strong className="mail-to-input">
                {replyType !== "forward" && "To: "}
                <span>
                  <FormControl variant="outlined" error={ToErrMsg}>
                    <label className="lable-to">To:</label>
                    <TextField
                      fullWidth
                      value={ToEmails}
                      onChange={(e) => handleCCAndTo(e, "to")}
                    />
                    {ToErrMsg && <FormHelperText>{ToErrMsg}</FormHelperText>}
                  </FormControl>
                </span>
              </strong>
            </Grid>
            <span
              style={{
                fontSize: "11px",
                color: "#253858",
                background: "#f7f7f6cc",
                padding: "3px 6px",
                borderRadius: "4px",
                margin: "2px 0 0",
              }}
            >
              (Add multiple emails with ',' separated.)
            </span>
            {(replyType === "replyAll" || replyType === "forward") && (
              <Grid item md={12} sm={12} xs={12} className="border-bottom">
                <strong className="mail-to-input">
                  <FormControl variant="outlined" error={CCErrMsg}>
                    <label className="lable-to">CC:</label>
                    <TextField
                      fullWidth
                      value={
                        replyType != "forward" ? CCEmails : CCForwardEmails
                      }
                      onChange={(e) => handleCCAndTo(e, "cc")}
                    />
                    {CCErrMsg && <FormHelperText>{CCErrMsg}</FormHelperText>}
                  </FormControl>
                </strong>
              </Grid>
            )}

            <Grid item md={12} sm={12} xs={12} className="border-bottom">
              <strong>
                Subject:{" "}
                {/* <span>{subject ? subject : ticketData.Subject} </span> */}
                {/* {subject ? subject : ticketData.Subject} */}
                <InputBase
                  className={classes.margin}
                  defaultValue={ticketData.Subject}
                  inputProps={{ "aria-label": "Subject" }}
                  value={subject ? subject : ticketData.Subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </strong>
            </Grid>
            {replyType == "forward" ? (
              <Grid item md={12} sm={12} xs={12}>
                <div className="view-attachements">
                  <strong>Attachments: </strong>
                  {ticketData.MailAttachments &&
                  Array.isArray(ticketData.MailAttachments)
                    ? ticketData.MailAttachments.map((item) => {
                        return <span>{item.FileName} </span>;
                      })
                    : ""}
                </div>
              </Grid>
            ) : (
              ""
            )}
            <Grid item md={12} xs={12} className="reply-box">
              <CKEditor
                config={config}
                content={inputMessage}
                events={{
                  change: onEditorChange,
                  blur: onEditorChange,
                }}
              />
            </Grid>
          </Grid>
          <div className="action-bar">
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={8} xs={6}>
                <div className="attach-btn" id="actionBarAttachement">
                  <FileUploadComponent
                    setFiles={setselectedFile}
                    fileRef={hiddenFileInput}
                  />
                  {selectedFile.map((item) => {
                    return (
                      <span className="file-detail" key={item.id}>
                        {item.FileName}{" "}
                        <button onClick={() => handleRemoveFile(item.id)}>
                          <CloseIcon />
                        </button>
                      </span>
                    );
                  })}
                </div>
              </Grid>
              <Grid item md={4} xs={6}>
                {!replyInProgress && (
                  <Button
                    autoFocus
                    className="discard-btn"
                    color="primary"
                    onClick={handleDisCard}
                    id="actionBarDiscard"
                  >
                    Discard
                  </Button>
                )}
                {
                  <Button
                    className="send-btn"
                    color="primary"
                    onClick={sendClicked}
                    disabled={sendBtnDisabled}
                    id="actionBarSendBtn"
                  >
                    {replyInProgress ? <Spinner /> : "Send"}
                  </Button>
                }
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <ConfirmationDialog
        isConfirmDialogOpen={showConfirmationBox}
        setConfirmDialogOpen={setShowConfirmationBox}
        updateFn={handleReplySend}
      />

      {isCommunicationDialogOpen && triggerInput && triggerInput.length > 0 ? (
        <CommunicationDialogForTriggers
          isDialogOpen={isCommunicationDialogOpen}
          setDialogOpen={setIsCommunicationDialogOpen}
          triggerInput={triggerInput}
          setInputMessage={setInputMessage}
          oldTicketDetails={oldTicketDetails}
          canResopnse={canResopnse}
          getHTMLForNewCar={getHTMLForNewCar}
          values={values}
          setValues={setValues}
          setCanResopnse={setCanResopnse}
          isTemplateLoaded={isTemplateLoaded}
          setIsTemplateLoaded={setIsTemplateLoaded}
          replyAppendMsg={replyAppendMsg}
        />
      ) : (
        ""
      )}
    </>
  );
}
