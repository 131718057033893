import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { POST } from '../../../../services/apiServices';
import { getDocUrl, handleCatch } from '../../../../shared/methods/apiMethods';
import { Toast } from '../../../../shared/components';
import Spinner from '../../../../shared/components/Spinner';
import { convertToIsoFormate } from '../../../../shared/methods/methods';
import CustomBackdrop from '../../../../pages/Components/CustomBackdrop';
import DocumentPendency from '../../../PendencyDescription/Components/PendencyDialog';
import { BYPASS_REOPEN_TICKET_FOR_CUSTOMER_PENDENCY_DIALOG, ENABLE_CUSTOMER_PENDENCY_DIALOG } from '../../../../Config';
import PendencyLogs from '../../../PendencyDescription/Components/PendencyLogs';
import NoDataFound from '../../../../shared/components/NoDataFound';

const CustomerPendencyLogs = () => {

  const [logs, setLogs] = useState(null);
  const [logsV2, setLogsV2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fileDownloading, setFileDownloading] = useState(false);

  const TicketID_store = useSelector(state => state && state.ticketdetails && state.ticketdetails.ticketDetails && state.ticketdetails.ticketDetails.TicketId || 0)
  const TicketDetails_store = useSelector(state => state && state.ticketdetails && state.ticketdetails.ticketDetails)
  const ConditionalRenderNewCustomerPendency_store = useSelector(state => state && state.Utility && state.Utility.conditionalRenderNewCustomerPendency || false)

  useEffect(() => {
    if (TicketID_store) {
      fetchCustomerPendencyLogs();
    }
  }, [TicketID_store])

  const fetchCustomerPendencyLogs = function () {
    setLoading(true);
    let endpoint = 'GetPendencyDetailHistory';
    let payload = { "TicketID": TicketID_store }
    POST(endpoint, payload).then(res => {
      setLoading(false);
      console.log('response--->', res)
      if (res && res.ErrorCode === 0) {
        setLogs(res.Data?.V1);
        setLogsV2(res.Data?.V2);
      }
      else Toast.error(res['Message'] || 'Failed to fetch Customer Pendency Logs')
    }).catch(err => {
      setLoading(false);
      handleCatch(err);
    })
  }

  const handleFileDownload = (attachment) => {
    if (attachment.DocId) {
      setFileDownloading(true);
      let cb = () => {
        setFileDownloading(false);
      }
      getDocUrl(attachment.DocId, cb);
    }
  }

  const renderRaisePendency = (TicketDetails_store && ENABLE_CUSTOMER_PENDENCY_DIALOG.includes(TicketDetails_store.ProductId) &&
    TicketDetails_store.SubStatusName && TicketDetails_store.SubStatusName.toLowerCase() === 'customer pendency' && !TicketDetails_store.IsEscalated && (BYPASS_REOPEN_TICKET_FOR_CUSTOMER_PENDENCY_DIALOG.includes(TicketDetails_store.ProductId) ? true : !TicketDetails_store.IsReopen) && TicketDetails_store.ShowCustPendency) ? true : false

  return (
    <>
      {fileDownloading || loading && <CustomBackdrop open={fileDownloading || loading} />}
      {!loading && (renderRaisePendency || ConditionalRenderNewCustomerPendency_store) ? <DocumentPendency fetchLatestLogs={fetchCustomerPendencyLogs}/> : <></>}
      {Boolean(logs && logs.length > 0) && <div className="customer-pendency-logs">
        <ul className='pendency-log-list'>
          <>
            {logs.map((item, index) => {
              return (
                <li key={`customer-pendency-log-${index}`}>
                  <h5>{item.CreatedByName} <span>{convertToIsoFormate(item.CreatedOn)}</span></h5>
                  <p dangerouslySetInnerHTML={{ __html: item.Details, }}>
                  </p>
                  <div className="attachment-outer-box">
                    {item.PendencyAttachments && item.PendencyAttachments.length > 0 && item.PendencyAttachments.map((attachment, index2) => {
                      return <span key={`customer-pendency-attachment-${index2}`} title={attachment.FileName} onClick={() => handleFileDownload(attachment)}>{attachment.FileName}</span>
                    })}
                  </div>
                </li>
              )
            })
            }
          </>
        </ul>
      </div>}
      {logsV2 && logsV2.NewPendencies && logsV2.RaisedPendencies && logsV2.RaisedPendencies.length > 0 ? <PendencyLogs logs={[...logsV2.RaisedPendencies,]} /> : null}
      {logsV2 && logsV2.RaisedPendencies && logsV2.RaisedPendencies.length === 0 && (logs === null || (logs && logs.length === 0)) && 
      // <div className='no-data-found-box'>
        <NoDataFound title='No Logs found!' />
      // </div>
      }
    </>
  )
}

export default CustomerPendencyLogs