import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useReducer,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  FormControl,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  TextareaAutosize,
  RadioGroup,
  Radio,
  Tooltip,
} from "@material-ui/core";
import ConfirmationDialog from "../../shared/components/ConfirmationDialog";
import { GET, POST } from "../../services/apiServices";
import {
  GetIssueTypeByLeadId,
  UpdateTicketDetails,
  GetTicketDetailsByTicketID,
  UpdateTicketInsurerStatus,
  GetTemplates,
  UpdateTicketRemarks,
  GetTicketSummary,
  GetIssueSubIssueAndLeadDetails,
  getRedAmberResponse,
  GetTemplates_V2,
  CheckRefundEligibility,
  UpdateSubIssueOnRefundReject,
  GetRefundDetails,
  GetDialerAgentList,
  MergeTickets,
  CheckSMEEmployeeDetails,
} from "../../constants/apiUrls";
import SelectInput from "./Components/SelectInput";
import { useSnackbar } from "notistack";
import EventIcon from "@material-ui/icons/Event";
import Spinner from "../../shared/components/Spinner";
import {
  checkIssueSubIssueForDispatchRequest,
  checkProductIssueSubIssue,
  openNewView,
  // showEndorsementDialogForTicket,
  showRenewalLeadIDBox,
  validateLeadId,
} from "../../shared/methods/methods";
import {
  CAR,
  From,
  openTickets,
  isCommunicationEligible,
  isCustomerPendancyEligible,
  checkForSameIssueSubIssue,
  endorsementIssue,
  isEndorsementEligible,
  SME,
  showEndorsementId,
  handleCancellationIssue,
  envUrl,
  showPriorityFlag,
  getFollowUpdateTimeInProperFormate,
  showAHCId,
  ahcSubissue,
  ahcIssue,
  AHC_SUPPLIERS,
  RESOLVE_TICKET_DIRECTLY,
  FETCH_TEMPLATE_ON_TICKET_CHANGE,
  ENABLE_CUSTOMER_PENDENCY_DIALOG,
  RESTRICT_MANUAL_TICKET_CLOSURE,
  BYPASS_REOPEN_TICKET_FOR_CUSTOMER_PENDENCY_DIALOG,
  RAISE_TO_BRANCH_PRODUCTS,
  TWO_WHEELER,
  STU,
  TERM,
  INVESTMENT,
  HEALTH,
  POLICY_DISPATCH_PRODUCTS,
  OPEN_ENDORSEMENT_IN_CUSTOMER_PENDENCY_SUBSTATUS,
  DISABLED_SEND_TO_INSURER_PRDOUCTS_SUPPLIER,
} from "../../Config";
import CloseIcon from "@material-ui/icons/Close";
import CachedIcon from "@material-ui/icons/Cached";
import TicketContext from "../../TicketContext";
import FileUploadComponent from "../../shared/components/FileUploadComponent";
import { GetEndorsementDetails } from "../../constants/apiUrls";
import { handleCancellationSelection } from "../../Config";
import { ProductEligibleForRefund } from "../../Config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/ticketDetails";
import RefundCard from "./Components/RefundCard";
import EndorsementDetailsDialog from "./Components/EndorsementDetailsDialog";
import RaiseNewEndorsement from "./Components/RaiseNewEndorsement";
import EndorsementDialog from "../../../src/Components/Topbar/components/components/EndorsementDialog";
import AHCDetailsDialog from "./Components/AHCDetailsDialog";
import { fetchAHCUrl,fetchDispatcherURL,fetchEndosementURLForExisting, handleCatch } from "../../shared/methods/apiMethods";
import CustomRaiseRequestModal from "../Dialog/CustomRaiseRequestModal";
import { fetchEndosementURL } from "../../shared/methods/apiMethods";
import Toast from "../../shared/components/Toast";
import EditorComponent from "./Components/EditorComponent";
import CustomPopup from "../../shared/components/CustomPopup";
import PendencyDescription from "../PendencyDescription/PendencyDescription";
import ChildErrorBoundary from "../../shared/components/ChildErrorBoundary";
import { DialogFromRight } from "../../shared/components";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
  },
  title: {
    flex: 1,
    fontSize: "16px",
  },
  h6: {
    color: "#414141",
    fontSize: "14px",
  },

  spinerIcon: {
    position: "absolute",
    top: "9px",
    left: "39%",
    right: "39%",
    margin: "auto",
    "& svg": {
      color: "#0052cc",
    },
  },
}));

const TicketDetail = ({
  formValues,
  setFormValues,
  isTicketDetailEditable,
  setIsTicketDetailEditable,
  oldTicketDetails,
  templateList,
  setTemplateList,
  selectFormOptions,
  setSelectFormOptions,
  errors,
  setErrors,
  setIsEsclationLoading,
  setEsclationInfo,
  setEsclationLevelTime,
  IsEscalated,
  isTopMgmt,
  isHCS,
  isSocial,
  isCGO,
  isIRDA,
  viewtype,
  showNewTabs,
  endorsementDetails,
  raiseEndorsementURL,
  setIsValidForSME,
  RootCauses,
  setRootCauses,
  setGoToTemplate,
  communications, // pg refund v2 modal emails from parent
  onSetOldTicketDetails,
  OpenedTicketDetails,
  IsReadOnlyUser,
  UserInfo,
  setTicketLoading_store,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const forceRefreshTicketDetails = useSelector(state=> state && state.Utility && state.Utility.refreshAnyThing && state.Utility.refreshAnyThing.TicketDetails || 0)
  const ActiveTicket_store = useSelector(state => state.ticketdetails?.selectedTicketID)
  const ticketContext = useContext(TicketContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const [isShowLess, setIsShowLess] = useState(true);
  const [showConfirmationBox, setShowConfirmationBox] = useState(null);
  const [isFollowUpDateCalederOpen, setFollowUpDateCalederOpen] =
    useState(false);
  const [ticketUpdateInProgress, setTicketUpdateInProgress] = useState(false);
  const [ActivateRadioVal, setActivateRadioVal] = useState("");
  const [changeInPolicyFile, setChangeInPolicyFile] = useState({});
  const ActionType = { Value: 1, Text: "Email" };
  const iframeRef = useRef(null);
  const fileRef = useRef(null);
  const [popup, showPopup] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openDetailsURL, setOpenDetailsURL] = useState("");
  const [loadingEndorsement, setLoadingEndorsement] = useState(false);

  const [raiseEndorsement, setRaiseEndorsement] = useState(null);
  const [isViewDetail, setIsViewDetail] = useState(null);
  const [endorsementTabClosed, setEndorsementTabClosed] = useState(false);
  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const [isEndoresmentDialogOpen, setEndoresmentDialogOpen] = useState(false);
  const [checkTalkTime, setcheckTalkTime] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      show: false,
      value: null,
    }
  );

  const customDialogInitialState = {
    title: false,
    actionButtons: [],
    open: false,
    handleClose: null,
  };
  const [customDialogControl, setCustomDialogControl] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    customDialogInitialState
  );

  const [commonLoaderState, setCommonLoaderState] = useReducer(
    (state, newState) => ({...state, ...newState}), {
    }
  )

  const [customPopupControl, setCustomPopupControl] = useState();

  const [AHCDialog, setAHDDialog] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      show: false,
      url: null,
    }
  );

  const [dialogWithControls, setDialogWithControls] = useState();
  const [customerPendencyState, setCustomerPendencyState] = useState({
    PendencyList: [],
    RaisedPendencyList : [],
    PendencyListHasError: false,
    randomValueForUpdate: 0,
  })

  let forceRefreshObj = {
    'TicketDetails' :  ()=> GetTicketDetailsByTicketIdApi(formValues.RefObjectId,formValues.TicketDetailsID),
    'AllTickets' : () => getAllTicketSummary(formValues.RefObjectId, formValues.TicketDetailsID),
  }

  useEffect(()=>{
    if(forceRefreshTicketDetails && formValues && ActiveTicket_store && formValues?.TicketId === ActiveTicket_store){
      agentResetOnCancel();
      forceRefreshObj['TicketDetails']()
      }
  },[forceRefreshTicketDetails])

  useEffect(() => {
    setIsViewDetail(formValues.RefundId);
    if (
      formValues.ProductId == SME && 
      formValues.SubStatusID &&
      formValues.IssueTypeId &&
      formValues.LeadID &&
      formValues.SubIssueID
    ) {
      setIsValidForSME(true);
    } else {
      setIsValidForSME(false);
    }
  }, [formValues]);

  useEffect(() => {
    if (endorsementTabClosed) {
      getAllTicketSummary(formValues.RefObjectId, formValues.TicketDetailsID);
      GetTicketDetailsByTicketIdApi(
        formValues.RefObjectId,
        formValues.TicketDetailsID
      );
      const payload = {
        EndorsementID: 0, //10070,
        BookingID: oldTicketDetails.LeadID || 0, //43362530,
        TicketNumber: oldTicketDetails.TicketDetailsID || "",
        ProductID: oldTicketDetails.ProductId || 0,
        TicketID: oldTicketDetails.TicketId || 0,
        CustomerID: oldTicketDetails.CustomerId || 0,
      };
      if (
        isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails?.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
        !oldTicketDetails.ParentTicketID
      ) {
        POST(`${GetEndorsementDetails}`, payload)
          .then((res) => {
            setRaiseEndorsement(res.Data.RaiseNewEndorsementURL);
          })
          .catch((e) => console.log(e));
      }
      window.location.reload();
    }
  }, [endorsementTabClosed]);

  const getClassificationDefaultValue = (ClassificationID) => {
    for (let i = 0; i < selectFormOptions.classificationOptions.length; i++) {
      if (
        selectFormOptions.classificationOptions[i].RequestTypeID ===
        ClassificationID
      ) {
        return selectFormOptions.classificationOptions[i].RequestTypeName;
      }
    }
  };

  useEffect(() => {
    if (formValues?.RootCause?.length > 0) {
      setRootCauses(formValues.RootCause.split(","));
    }
  }, []);

  useEffect(() => {
    if (formValues.ProductId === SME) {
      setRootCauses([""]);
    }
  },[formValues.Status, formValues.SubStatusName])

  // Customer Pendency Sub-Issue DialogOpen and ticket is editable
  useEffect(() => {
    if (ENABLE_CUSTOMER_PENDENCY_DIALOG.includes(oldTicketDetails && oldTicketDetails.ProductId) && !oldTicketDetails['DisableCustomerPendencyPopUp']) {
      if (formValues && formValues.SubStatusName && formValues.SubStatusName.toLowerCase() === 'customer pendency' && isTicketDetailEditable && !formValues.CustomerPendencyDetails && oldTicketDetails && !oldTicketDetails.IsEscalated &&
        (BYPASS_REOPEN_TICKET_FOR_CUSTOMER_PENDENCY_DIALOG.includes(oldTicketDetails.ProductId) ? true : !oldTicketDetails.IsReopen)) {
        if (!oldTicketDetails.ShowCustPendency) {
          handleCustomerPendencyEditor();
        }
        else {
          setCustomerPendencyState({ PendencyList: [], PendencyListHasError: false, randomValueForUpdate: 0 });
        }
      }
      else {
        setCustomPopupControl()
      }
    }
  }, [formValues.SubStatusName, isTicketDetailEditable])

  const handleCustomerPendencyEditor = () => {
    setCustomPopupControl({
      open: true,
      handleClose: formValues.CustomerPendencyDetails ? () => setCustomPopupControl() : null, // if value is not present then agent not allowed to close the popup
      title: 'Customer Pendency Reason',
      className: 'customer-pendency-reason-editor',
      children: <>
        <EditorComponent data={formValues.CustomerPendencyDetails || ""}
          files={formValues.CustomerPendencyAttachment || []}
          TriggeredBy={"SubStatus"}
          AssignValueTo="CustomerPendencyDetails"
          handleClose={() => setCustomPopupControl()}
          allowEmpty={false}
          showFileSelection={true}
          placeholder='Please enter text'
          buttonsToRender={
            [
              { Name: "Cancel", className: 'clear-all', onClick: () => { setCustomPopupControl(); setFormValues((prev) => ({ ...prev, Status: oldTicketDetails.Status, StatusID: oldTicketDetails.StatusID, SubStatusID: oldTicketDetails.SubStatusID, SubStatusName: oldTicketDetails.SubStatusName, CustomerPendencyAttachment: oldTicketDetails.CustomerPendencyAttachment, CustomerPendencyDetails: oldTicketDetails.CustomerPendencyDetails, CustConfPending : oldTicketDetails.CustConfPending })) } },
              { Name: "OK", className: 'save-btn', onClick: (content, attachments) => setFormValues((prev) => ({ ...prev, CustomerPendencyDetails: content, CustomerPendencyAttachment: attachments })) },
            ]
          }
          checkBoxToRender = {[
            {
              Name : "Confirmation Pending",
              checked : formValues && formValues.CustConfPending,
              onChange : (checked) => setFormValues((prev) => ({ ...prev, CustConfPending: checked})),
            }
          ]}
          />
      </>,
    })
  }

  const handleFormValues = (e, name) => {
    const { value } = e.target;
    if (name === "LeadID" || name === "RCA" || name === "RCAAgent") {
      if (name === "LeadID") {
        setSelectFormOptions({
          ...selectFormOptions,
          issueOptions: [],
          allIssueOptions: [],
        });
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
          SubIssueID: "",
          SubIssueName: "",
          IssueTypeId: "",
          IssueName: "",
          ProductId: "",
          ProductName: "",
          SupplierName: "",
          SupplierId: "",
        }));
        return;
      }
      if (name === "RCAAgent") {
        setFormValues((prev) => ({ ...prev, [name]: value.slice(0, 50) }));
        return;
      }
    }
    if (name === "RootCause") {
      const {
        target: { value },
      } = e;
      setRootCauses(typeof value === "string" ? value.split(",") : value);
      return;
    }
    if (
      name === "StatusID" ||
      ("SubStatusID" && formValues.ProductID === 113)
    ) {
      setRootCauses(null);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
    if (name === "ChangeInPolicyFlag") {
      setChangeInPolicyFile({});
    }
  };

  const getRedAmberResponseapi = (ticketId) => {
    setIsEsclationLoading(true);
    GET(`${getRedAmberResponse}/${ticketId}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setEsclationInfo(response.Data);
          setEsclationLevelTime({
            L0: response.Data.L0_Green,
            L1: response.Data.L1_Amber,
            L2: response.Data.L2_Red,
          });
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setIsEsclationLoading(false);
      })
      .catch((err) => {
        setIsEsclationLoading(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };


  const GetTicketDetailsByTicketIdApi = (RefObjectId, TicketDetailsID) => {
    setTicketLoading_store(true);
    GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        setTicketLoading_store(false);
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest, IsInsurerBucket } = response.Data;
          onSetOldTicketDetails(response.Data);
          let callbackVal;
          if (IsCallBackRequest === "0") {
            callbackVal = "No";
          } else if (IsCallBackRequest === "1") {
            callbackVal = "Yes";
          } else if (IsCallBackRequest === "2") {
            callbackVal = "Completed";
          }
          setIsViewDetail(response.Data.RefundId);
          setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            RefundId: response.Data.RefundId,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
          setRootCauses(response.Data.RootCause.split(","));
          ticketContext.setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
          GetIssueTypeByLeadIdApi(response.Data.LeadID);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log("error TicketDetails line 394");
        }
      })
      .catch((err) => {
        setTicketLoading_store(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log("error", err);
      });
  };

  const getAllTicketSummary = (RefObjectId, TicketDetailsID) => {
    ticketContext.setAllTicketsLoading(true);
    GET(`${GetTicketSummary}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter(
                (i) => i.StatusID != 3 && i.StatusID != 4
              ).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData2 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 3).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData3 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 4).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          ticketContext.setAllTickets({
            ...response.Data,
            AllTicketList: [...sortedData1, ...sortedData2, ...sortedData3],
          });
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        ticketContext.setAllTicketsLoading(false);
        dispatch(actions.setActiveTicketID(response.Data.TicketId))
        ticketContext.setActiveTicketId(response.Data.TicketId);
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };

  const showErrMsg = (msg) => {
    enqueueSnackbar(msg, {
      variant: "error",
      onClick: () => {
        closeSnackbar();
      },
    });
  };

  const isIssueExistInDropDown = () => {
    let isExist = false;
    for (let i = 0; i < selectFormOptions.issueOptions.length; i++) {
      if (
        selectFormOptions.issueOptions[i].IssueTypeId == formValues.IssueTypeId
      ) {
        isExist = true;
        break;
      }
    }
    return isExist;
  };

  const isSubIssueExistInDropDown = () => {
    let isExist = false;
    for (let i = 0; i < selectFormOptions.subIssueOptions.length; i++) {
      if (
        selectFormOptions.subIssueOptions[i].IssueTypeId ==
        formValues.SubIssueID
      ) {
        isExist = true;
        break;
      }
    }
    return isExist;
  };

  useEffect(()=>{
    if(isTicketDetailEditable && renderCustomerPendencyDialog()){
      setCustomerPendencyState(prev=>({...prev, randomValueForUpdate : prev.randomValueForUpdate + 1}))
    }
    else setCustomerPendencyState({ PendencyList : [], RaisedPendencyList : [], PendencyListHasError : false, randomValueForUpdate : 0})
  },[isTicketDetailEditable])

  const handleUpdateTicket = () => {

    const {
      LeadID,
      ProductId,
      TicketDetailsID,
      StatusID,
      SubIssueID,
      IssueName,
      SubIssueName,
      SubStatusID,
      FollowUpDate,
      FollowupTime,
      RefObjectId,
      TicketId,
      IsCallBackRequest,
      RootCause,
      RCA,
      ResponsibilityId,
      ResponsibilityReason,
      SIFlag,
      ChangeInPolicyFlag,
      EndorsementID,
      LeadStatusId,
      ProductName,
      Source,
      SupplierId,
      SupplierName,
      SeverityID,
      QueryTypeId,
      InsurerRefID,
      RCAAgent,
      IsMultipleEndorsement,
      RetentionTypeId,
      RetentionTypeName,
      RetentionReasonName,
      RetentionReasonId,
      EmailId,
      CustomerName,
      UploadURL,
      DoubleDecutionFlag,
      RejectReasonFlag,
      MaskMobileNo,
      BlockAutoComm,
      ConversationTopic,
      SendToInsurerMsg,
      Status,
      SubStatusName,
      PolicyNo,
      CustomerId,
      ClassificationID,
      IsReopen,
      PriorityFlag,
      SubSource,
      RenewalLeadId,
      CreatedOn,
      CustConfPending,
      LeadSubStatusId,
    } = formValues;

    const payload = {
      LeadID: LeadID,
      ProductID: ProductId != "" ? ProductId : 0,
      ProductName: ProductName,
      Source: Source,
      TicketDetailsID: TicketDetailsID,
      InsurerID: SupplierId != "" ? SupplierId : 0,
      InsurerName: SupplierName,
      StatusID: StatusID,
      SubStatusID: SubStatusID,
      StatusName: Status,
      OldStatusID: oldTicketDetails.StatusID,
      IssueTypeId: SubIssueID != "" ? SubIssueID : 0,
      IssueTypeName: IssueName,
      OldSubIssueTypeId: oldTicketDetails.SubIssueID,
      SubIssueTypeId: SubIssueID != "" ? SubIssueID : 0,
      SubIssueTypeName: SubIssueName,
      SubStatusID: SubStatusID ? SubStatusID : 0,
      SubStatusName: SubStatusName,
      OldSubStatusID: oldTicketDetails.SubStatusID,
      FollowUpDate: FollowUpDate,
      OldFollowUpDate: oldTicketDetails.FollowUpDate,
      FollowUpTime: FollowupTime,
      RefObjectId: RefObjectId,
      TicketId: TicketId,
      IsCallBackRequest: IsCallBackRequest,
      SeverityID: SeverityID,
      QueryTypeId: QueryTypeId,
      RootCause: RootCauses.filter(Boolean).join(","),
      RCA: RCA,
      ResponsibilityId: ResponsibilityId,
      InsurerRefID: InsurerRefID,
      rcaAgent: selectedMultiAgent,
      ResponsibilityReason: ResponsibilityReason,
      IsMultipleEndorsement: IsMultipleEndorsement,
      RetentionTypeId: RetentionTypeId,
      RetentionTypeName: RetentionTypeName,
      RetentionReasonId: RetentionReasonId,
      RetentionReasonName: RetentionReasonName,
      EmailId: EmailId,
      CustomerName: CustomerName,
      FileName: changeInPolicyFile.FileName ? changeInPolicyFile.FileName : "",
      UploadURL: changeInPolicyFile.AttachementContent
        ? changeInPolicyFile.AttachementContent
        : UploadURL,
      SIFlag: SIFlag ? (ActivateRadioVal === "1" ? 1 : 2) : 0,
      ChangeInPolicyFlag: ChangeInPolicyFlag ? 1 : 0,
      DoubleDecutionFlag: DoubleDecutionFlag,
      RejectReasonFlag: RejectReasonFlag,
      MobileNo: MaskMobileNo,
      EndorsementID: EndorsementID ? EndorsementID : "0",
      OldEndorsementID: oldTicketDetails.EndorsementID,
      EscalationType: oldTicketDetails.EscalationType,
      BlockAutoComm: BlockAutoComm,
      LeadStatusId: LeadStatusId,
      LeadSubStatusId : LeadSubStatusId,
      QueryTypeId: ClassificationID,
      IsRefunded: oldTicketDetails.ISREFUNDED,
      IsReopen: IsReopen,
      RefundId: formValues && formValues.RefundId ? formValues.RefundId : 0,
      IsJAG: formValues && formValues.IsJAG ? formValues.IsJAG : 0,
      JAGEmployeeId:
        formValues && formValues.JAGEmployeeId ? formValues.JAGEmployeeId : "",
      PriorityFlag:
        formValues && formValues.PriorityFlag
          ? 1
          : formValues && formValues.PriorityFlag == false
          ? 0
          : null,
      SubSource: SubSource,
      RenewalLeadId: RenewalLeadId,
      CreatedOn: CreatedOn,
      OldLeadID: oldTicketDetails.LeadID,
      AHCId: oldTicketDetails.AHCId,
      CustomerPendencyDetails : formValues.CustomerPendencyDetails,
      CustomerPendencyAttachment : formValues.CustomerPendencyAttachment,
      CustConfPending: CustConfPending,
      IsRaiseToBranch: formValues?.IsRaiseToBranch,
      CustomerID : CustomerId,
      DispatcherId: oldTicketDetails?.DispatcherId
    };

    // for Dynamically changing the agent names based on conditions
    if(!payload['RootCause'].includes("Agent Delay")){
      payload["rcaAgent"] = "";
    }
    //related to customer Pendency update
    if(renderCustomerPendencyDialog()){
      //if customer pendency has already raised pendency=> then on update new pendency will be [] even if api returns new Pendency array
      payload['NewPendencies'] = customerPendencyState.RaisedPendencyList && customerPendencyState.RaisedPendencyList.length ? [] : customerPendencyState.PendencyList
    }
    setTicketUpdateInProgress(true);
    POST(UpdateTicketDetails, payload)
      .then((res) => {
        if (res.ErrorCode === 0) {
          const insurerStatusPayload = {
            TicketId: formValues.TicketId,
            InsurerStatusID: 7,
            InsurerSubStatusID: 0,
            OldInsurerStatusID: oldTicketDetails.InsurerStatusID,
            OldInsurerSubStatusID: 0,
          };

          let refobjectId = RefObjectId;
          let ticketDetailsId = TicketDetailsID;
          let ticketId = TicketId;
          if (res.Data.MergTicketId && res.Data.MergTicketId !== "0") {
            refobjectId = res.Data.MergRefObjectID;
            ticketDetailsId = res.Data.MergTicketDetailsID;
            ticketId = res.Data.MergTicketId;
          }

          if (
            (formValues.StatusID === 2 &&
              oldTicketDetails.IsInsurerBucket != formValues.SendToInsurer) ||
            (formValues.SubStatusID == 2 &&
              formValues.SubStatusID != oldTicketDetails.SubStatusID &&
              formValues.SendToInsurer == 1)
          ) {
            POST(UpdateTicketInsurerStatus, insurerStatusPayload)
              .then((resp) => {
                if (resp.ErrorCode === 0) {
                  const updateRemarksPayload = {
                    PrevObjectID: RefObjectId,
                    TicketId: TicketId,
                    TicketDetailId: TicketDetailsID,
                    Subject: PolicyNo + " : " + ConversationTopic,
                    ConversationTopic: ConversationTopic,
                    LeadID: LeadID,
                    CustomerName: CustomerName,
                    CustomerID: CustomerId,
                    ProductID: ProductId,
                    ProductName: ProductName,
                    From: From,
                    Body: SendToInsurerMsg,
                    IsInsurer: false,
                    TicketSource: Source,
                    ActionType: 4,
                    StatusName: Status,
                    SubStatusName: SubStatusName,
                    InsurerID: SupplierId,
                    StatusID: StatusID,
                    SubStatusID: SubStatusID,
                    RefundId:
                      formValues && formValues.RefundId
                        ? formValues.RefundId
                        : 0,
                    IsJAG:
                      formValues && formValues.IsJAG ? formValues.IsJAG : 0,
                    JAGEmployeeId:
                      formValues && formValues.JAGEmployeeId
                        ? formValues.JAGEmployeeId
                        : "",
                    PriorityFlag: PriorityFlag,
                    IssueTypeID:
                      formValues && formValues.IssueTypeId
                        ? formValues.IssueTypeId
                        : 0,
                    HCSSource: oldTicketDetails && oldTicketDetails.HCSSource
                  };


                  POST(
                    `${UpdateTicketRemarks}/${
                      templateList && templateList.length > 0
                        ? templateList[0].TriggerName
                        : "DefaultTemplate"
                    }`,
                    updateRemarksPayload
                  )
                    .then((updateTicketRemarkResponse) => {
                      if (updateTicketRemarkResponse.ErrorCode === 0) {
                        enqueueSnackbar(
                          res.Data.MergeMsg ||
                            "Ticket information updated successfully!!",
                          {
                            variant: "success",
                            onClick: () => {
                              closeSnackbar();
                            },
                          }
                        );
                        setIsTicketDetailEditable(false);
                        GetTicketDetailsByTicketIdApi(
                          refobjectId,
                          ticketDetailsId
                        );
                        getAllTicketSummary(refobjectId, ticketDetailsId);
                        ticketContext.GetTicketCommApi(
                          refobjectId,
                          ticketDetailsId
                        );
                        ticketContext.GetTicketLogsApi(ticketId);
                        ticketContext.GetFilterCountApi(ticketId);
                        getRedAmberResponseapi(ticketId);
                        if (isEndorsementEligible(oldTicketDetails.ProductId) && openNewView(oldTicketDetails?.ProductId)) {
                          history.push(
                            `/ticketdetails/${refobjectId}/${ticketDetailsId}/${viewtype}`
                          );
                        } else {
                          history.push(
                            `/alltickets/${refobjectId}/${ticketDetailsId}/${viewtype}`
                          );
                        }
                      } else {
                        enqueueSnackbar(
                          updateTicketRemarkResponse.Message || "Failed",
                          {
                            variant: "error",
                            onClick: () => {
                              closeSnackbar();
                            },
                          }
                        );
                      }
                      setTicketUpdateInProgress(false);
                    })
                    .catch((err) => {
                      console.log(err);
                      enqueueSnackbar("Failed", {
                        variant: "error",
                        onClick: () => {
                          closeSnackbar();
                        },
                      });
                      setTicketUpdateInProgress(false);
                    });
                } else {
                  enqueueSnackbar("Failed", {
                    variant: "error",
                    onClick: () => {
                      closeSnackbar();
                    },
                  });
                }
                setTicketUpdateInProgress(false);
              })
              .catch((err) => {
                console.log(err);
                enqueueSnackbar("Failed", {
                  variant: "error",
                  onClick: () => {
                    closeSnackbar();
                  },
                });
                setTicketUpdateInProgress(false);
              });
          } else {
            enqueueSnackbar(
              res.Data.MergeMsg || "Ticket information updated successfully!!",
              {
                variant: "success",
                onClick: () => {
                  closeSnackbar();
                },
              }
            );
            setIsTicketDetailEditable(false);
            GetTicketDetailsByTicketIdApi(refobjectId, ticketDetailsId);
            getAllTicketSummary(refobjectId, ticketDetailsId);
            ticketContext.GetTicketCommApi(refobjectId, ticketDetailsId);
            ticketContext.GetTicketLogsApi(ticketId);
            ticketContext.GetFilterCountApi(ticketId);
            getRedAmberResponseapi(ticketId);
            isCommunicationEligible(oldTicketDetails.ProductId) && dispatch(actions.fetchLastestTemplateList(oldTicketDetails));
          }
        } else {
          enqueueSnackbar(res.Message || "Failed", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setTicketUpdateInProgress(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Failed", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        setTicketUpdateInProgress(false);
      });
  };

  const handleFolloupCalenderClose = (e) => {
    if (e.target.id !== iframeRef.current.id) {
      setFollowUpDateCalederOpen(false);
      GET(
        `${GetTicketDetailsByTicketID}/${formValues.RefObjectId}/${formValues.TicketDetailsID}`
      )
        .then((response) => {
          if (response.ErrorCode === 0) {
            const { FollowUpDate } = response.Data;
            if (FollowUpDate) {
              setErrors((prev) => ({ ...prev, followUpdate: false }));
            }
            setFormValues((prevValue) => ({
              ...prevValue,
              FollowUpDate: FollowUpDate,
              SendToInsurerMsg: "",
            }));
            ticketContext.setoldTicketDetails((prevValue) => ({
              ...prevValue,
              FollowUpDate: FollowUpDate,
              SendToInsurerMsg: "",
            }));
          } else {
            enqueueSnackbar("Something went wrong!", {
              variant: "error",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log(err);
        });
    }
  };

  const getDispatchStatusForTicketUpdate = () => {
    setTicketUpdateInProgress(true);
    return new Promise((resolve, reject) => {
      let endpoint = '/GetPolicyDispatchDetailsByLeadAndTicket';
      let payload = {
        LeadId: oldTicketDetails?.LeadID,
        TicketId: oldTicketDetails?.TicketDetailsID
      }
      POST(endpoint, payload).then(res => {
        setTicketUpdateInProgress(false);
        if (res && res.ErrorCode === 0) {
          let Data = res?.Data?.[0]
          if (Data && Data.DispatchStatusId && ![8, 10].includes(Data.DispatchStatusId)) {
            resolve(true);
          }
          else resolve(false)
        }
        else {
          reject(false);
          Toast.error(res.Message || 'Some error occurred')
        }
      }).catch(err => {
        setTicketUpdateInProgress(false);
        handleCatch(err);
        reject(false)
      })
    })
  }

  const handleSaveChanges = () => {
    console.log('payload', formValues)
    let [
      isLeadIdErr,
      isIssueErr,
      isSubIssueErr,
      isFollowUpdateErr,
      isProductErr,
      isSupplierErr,
    ] = [false, false, false, false, false, false];

    if (validateLeadId(formValues.LeadID)) {
      isLeadIdErr = true;
    }
    if (
      isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
        // (
          // ((formValues.IssueName.toLowerCase() ===
          //   "changes required in policy details" || //Endorsement Dialog is shown if  condition matches IssueName
          //   (formValues.IssueName.toLowerCase() === "renewal related query" && //Endorsement Dialog is shown if either condition matches IssueName and subIssue name, in condition
          //     formValues.SubIssueName.toLowerCase() ===
          //     "make changes to policy before renewal")) &&
          //   formValues.SubStatusName.toLowerCase() !== "customer pendency" &&
          //   !oldTicketDetails.ParentTicketID &&
          //   (oldTicketDetails.EndorsementID === "0" ||
          //     Number(oldTicketDetails.EndorsementID) === 0)
          // ) ||
      (
        checkProductIssueSubIssue(formValues?.ProductId, formValues?.IssueName, formValues?.SubIssueName) &&
        (formValues.SubStatusName.toLowerCase() === "customer pendency" &&
          !OPEN_ENDORSEMENT_IN_CUSTOMER_PENDENCY_SUBSTATUS.includes(formValues?.ProductId) ? false : true) &&
        !oldTicketDetails.ParentTicketID &&
        (oldTicketDetails.EndorsementID === "0" ||
          Number(oldTicketDetails.EndorsementID) === 0) && oldTicketDetails?.LeadID
        // )
      )
      ) {
      showPopup(true);
      isIssueErr = true;
    }
    if (formValues.SubStatusID != 21) {
      if (
        (!isIssueExistInDropDown() || !formValues.IssueTypeId) &&
        formValues.originalLeadID == Number(formValues.LeadID)
      ) {
        isIssueErr = true;
      }
    }
    if (!formValues.ProductId && formValues.LeadID && formValues.LeadID != 0) {
      isProductErr = true;
    }
    if (
      (!formValues.SupplierName || !formValues.SupplierId) &&
      formValues.LeadID &&
      formValues.LeadID == 0
    ) {
      isSupplierErr = false;
    }

    if (formValues.SubStatusID != 21) {
      if (
        (!formValues.SubIssueID || !isSubIssueExistInDropDown()) &&
        formValues.originalLeadID == Number(formValues.LeadID)
      ) {
        isSubIssueErr = true;
      }
    }
    if(formValues.IssueName && formValues.IssueName.toLowerCase() === ahcIssue &&
        formValues.SubIssueName && formValues.SubIssueName.toLowerCase() === ahcSubissue &&
      !oldTicketDetails.AHCId && AHC_SUPPLIERS.includes(formValues.SupplierId))
    {
      // console.log('error should be thrown')
        isSubIssueErr = true;
    }

    if (
      !checkForSameIssueSubIssue([
        formValues.IssueTypeId,
        formValues.SubIssueID,
      ])
    ) {
      Toast.error('Please select the sub-issue (field) requested for change');
      isSubIssueErr = true;
    }

    if (formValues.FollowUpDate == "") {
      if ([CAR, TWO_WHEELER, STU, TERM, INVESTMENT].indexOf(formValues.ProductId) > -1) {
        Toast.error('Follow up date is required!');
        isFollowUpdateErr = true;
      }
      if ([HEALTH].includes(formValues?.ProductId)){
        if(formValues.SubStatusID !== 3){ // 3- Customer Pendency
          Toast.error('Follow up date is required!');
          isFollowUpdateErr = true;
        }
    }
  }
    

    setErrors({
      issue: isIssueErr,
      subIssue: isSubIssueErr,
      isFollowUpdateErr: isFollowUpdateErr,
      product: isProductErr,
      supplier: isSupplierErr,
    });

    if (
      ticketUpdateInProgress ||
      isLeadIdErr ||
      isIssueErr ||
      isSubIssueErr ||
      isFollowUpdateErr ||
      isProductErr ||
      isSupplierErr
    )
      return;

    //Related to Customer Pendency
    if (renderCustomerPendencyDialog()) {
      setCustomerPendencyState(prev => ({ ...prev, randomValueForUpdate: prev.randomValueForUpdate + 1 }))
      if (Array.isArray(customerPendencyState.RaisedPendencyList) && customerPendencyState.RaisedPendencyList.length === 0) {
        if (customerPendencyState.PendencyList.length === 0) {
          return showErrMsg("Please add Customer Pendency type")
        }
        if (customerPendencyState.PendencyListHasError) {
          return showErrMsg("Please fill necessary fields to continue")
        }
      }
    }

    if (oldTicketDetails.ProductId === SME) {
      //New To Resolve condition
      if (oldTicketDetails.StatusID !== formValues.StatusID && formValues.StatusID === 3) {
        if (formValues.IssueName) {
          if (["sales enquiry", "other issue"].includes(formValues.IssueName.toLowerCase())) {
            console.log('No Validation')
          }
          else if (!formValues.LeadID) {
            return showErrMsg('Lead ID is mandatory to resolve the ticket');
          }
          else if(formValues.StatusID === 1 && formValues.StatusID === 3 && 
            !["sales enquiry", "other issue"].includes(formValues.IssueName.toLowerCase())){
            return showErrMsg("New to Resolved not possible");
          }
          else {
            console.log('no condition')
          }
        }
        else {
          return showErrMsg("Please enter issue to continue");
        }
      }
    }
    if (oldTicketDetails.StatusID !== 1 && formValues.StatusID === 1) {
      showErrMsg("You can not mark status as new ");
      return;
    }

    if (oldTicketDetails.StatusID !== 1 && formValues.StatusID === 1) {
      showErrMsg("You can not mark status as new ");
      return;
    }

    if (RootCauses.indexOf("Agent Delay") > -1 && !selectedMultiAgent) {
      showErrMsg("Please enter the Agent Name");
      return;
    }

    if (
      formValues.ChangeInPolicyFlag &&
      !formValues.UploadURL &&
      !changeInPolicyFile.FileName
    ) {
      showErrMsg("Please Upload the file");
      return;
    }
    
    let AutoClosureCondition = true

    if (
      oldTicketDetails.IssueChangeInPolicyFlag === 1 &&
      (oldTicketDetails.ProductId === 7 ||
        oldTicketDetails.ProductId === 115) &&
      formValues.ChangeInPolicyFlag &&
      changeInPolicyFile.FileName
    ) {
      AutoClosureCondition = false;
    }
    if (formValues.StatusID === 3 && !RESOLVE_TICKET_DIRECTLY.includes(oldTicketDetails.ProductId)) {
      if (
        formValues &&
        oldTicketDetails.IsAutoClosure &&
        !formValues.IsReopen &&
        !IsEscalated &&
        !isTopMgmt &&
        !isHCS &&
        !isSocial &&
        !isCGO &&
        !isIRDA &&
        !["Escalation", "Social", "SOCIALMANUAL"].includes(
          oldTicketDetails.Source
        ) &&
        !oldTicketDetails.RenewalLeadId
      ) {
        showErrMsg("You can not mark status as Resolved");
        return;
      }

      if (oldTicketDetails.StatusID !== 2) {
        showErrMsg("Previous Status to be In Progress");
        return;
      }

      if (!formValues.IssueTypeId || !formValues.SubIssueID) {
        showErrMsg("Issue SubIssue to be updated");
        return;
      }
    }

    if (oldTicketDetails.StatusID !== 5 && formValues.StatusID === 5) {
      showErrMsg("You can not mark status as Reopen");
      return;
    }

    if (oldTicketDetails.StatusID !== 4 && formValues.StatusID === 4) {
      showErrMsg("You can not mark status as Closed");
      return;
    }
    if (
      oldTicketDetails.StatusID === 2 &&
      (oldTicketDetails.SubStatusID === 3 ||
        oldTicketDetails.SubStatusID === 21) &&
      !RESOLVE_TICKET_DIRECTLY.includes(oldTicketDetails.ProductId) &&
      formValues.StatusID === 3 && !oldTicketDetails.IsReopen && !oldTicketDetails.IsEscalated
    ) {
      showErrMsg("Ticket can not be resolved manually");
      return;
    }
    if (
      oldTicketDetails.SubIssueID !== formValues.StatusID && 
      RESTRICT_MANUAL_TICKET_CLOSURE(oldTicketDetails.ProductId, oldTicketDetails.SupplierId) &&
      formValues.StatusID === 3 && oldTicketDetails.EndorsementID && oldTicketDetails.EndorsementID > 0
      && !oldTicketDetails.IsEscalated && !oldTicketDetails.IsReopen
    ) {
      showErrMsg("Ticket can not be resolved manually");
      return;
    }
    
    if (formValues.SIFlag && ActivateRadioVal === "") {
      showErrMsg("Please select Activate or Deactivate");
      return;
    }

    if (
      !oldTicketDetails.SendToInsurer &&
      formValues.SubStatusName === "Insurer Pendency" &&
      formValues.SendToInsurer &&
      !formValues.SendToInsurerMsg
    ) {
      showErrMsg("Please Enter the comment first");
      return;
    }

    if (
      formValues.StatusID &&
      !formValues.SubStatusName &&
      Array.isArray(selectFormOptions.subStatusOptions) &&
      selectFormOptions.subStatusOptions.length > 0
    ) {
      showErrMsg("Please Select the sub status");
      return;
    }

    //related to AHC- BMSTICKET-400
    if ( formValues.StatusID === 3 &&
          !(oldTicketDetails.IsReopen || oldTicketDetails.IsEscalated)
          && oldTicketDetails.AHCId > 0 && AHC_SUPPLIERS.includes(formValues.SupplierId)
    ) {
      showErrMsg("AHC ID exists, Ticket can not be resolved manually");
      return;
    }

    if(oldTicketDetails?.ShowCustPendency && oldTicketDetails?.SubStatusName.toLowerCase() === 'customer pendency' && formValues?.SubStatusName !== oldTicketDetails?.SubStatusName){
      return setShowConfirmationBox({
        open : true,
        title : 'All currently raised pendencies will be resolved. Are you sure, you want to proceed?'
      })
    }

    if(oldTicketDetails?.ShowCustPendency && formValues?.SubStatusName.toLowerCase() === 'customer pendency' && formValues?.SubStatusName !== oldTicketDetails?.SubStatusName){
      return setShowConfirmationBox({
        open : true,
        title : 'Once you confirm, Customer Pendency mail will be sent to the customer by the system?'
      })
    }

    if (formValues && formValues.ProductId == 131) {
      checkSMEEmployeeDetails();
    } else {
      //related to BMSTICKET-747-Hard-Copy-Dispatch_Phase-1
      if (oldTicketDetails?.DispatcherId && oldTicketDetails?.IsEscalated && formValues.Status &&
        formValues.Status.toLowerCase() == "resolved" && oldTicketDetails?.Status !== formValues.Status) {
        getDispatchStatusForTicketUpdate().then(flag => {
          if (flag) {
            setShowConfirmationBox({
              open: true,
              title: 'Dispatch is under-process. Do you still want to proceed?'
            })
          }
          else {
            setShowConfirmationBox({
              open: true,
              title: 'Are you sure?'
            })
          }
        }).catch(err => {
          return
        })
      }
      else {
        setShowConfirmationBox({ open: true});
      }
    }
  };

  const openCalender = () => {
    if ([2, 130].indexOf(formValues.ProductId) > -1) {
      openFollowUpCalender(formValues.TicketId);
      return;
    } else {
      if (ticketContext.isActionsAllowed) {
        setFollowUpDateCalederOpen(true);
        return;
      }
    }
    return;
  };

  const GetIssueSubIssueAndLeadDetailsapi = (leadId) => {
    if (leadId) {
      GET(`${GetIssueSubIssueAndLeadDetails}/${leadId}/${1}`)
        .then((response) => {
          if (response.ErrorCode === 0) {
            if (response.Data && response.Data.issueTypeMasters) {
              const issueList = response.Data.issueTypeMasters.filter(
                (item) => item.ParentID === 0
              );
              setSelectFormOptions({
                ...selectFormOptions,
                issueOptions: issueList,
                allIssueOptions: response.Data.issueTypeMasters,
              });
            }
            if (response.Data && response.Data.leadDetailResForUI) {
              const { ProductID, ProductName, SupplierName, SupplierID } =
                response.Data.leadDetailResForUI;
              if (ProductID) {
                setErrors((error) => ({
                  ...error,
                  product: false,
                }));
              } else if (!ProductID && leadId == 0) {
                setErrors((error) => ({
                  ...error,
                  product: false,
                }));
              }
              if (SupplierID) {
                setErrors((error) => ({
                  ...error,
                  supplier: false,
                }));
              } else if (!SupplierID && leadId == 0) {
                setErrors((error) => ({
                  ...error,
                  supplier: false,
                }));
              }
              ticketContext.setIsProductValueChanged(true);
              setFormValues((prev) => ({
                ...prev,
                ProductId: ProductID,
                ProductName:
                  ProductName ||
                  selectFormOptions.productsList.find(
                    (item) => item.ProductID === ProductID
                  )?.ProductName,
                SupplierName:
                  SupplierName ||
                  selectFormOptions.suppliersList.find(
                    (item) => item.SupplierID === SupplierID
                  )
                    ? selectFormOptions.suppliersList.find(
                        (item) => item.SupplierID === SupplierID
                      ).SupplierName
                    : "",
                SupplierId: SupplierID ? SupplierID : "",
              }));
            }
          } else {
            showErrMsg("Something went wrong!");
          }
        })
        .catch((err) => {
          showErrMsg("Something went wrong!");
        });
    }
  };

  const GetIssueTypeByLeadIdApi = (leadId) => {
    const param = 1;
    GET(`${GetIssueTypeByLeadId}/${leadId}/${param}`)
      .then((res) => {
        if (res.ErrorCode === 0) {
          const issueList = res.Data.filter((item) => item.ParentID === 0);
          setSelectFormOptions({
            ...selectFormOptions,
            issueOptions: issueList,
            allIssueOptions: res.Data,
          });
        } else {
          showErrMsg("Something went wrong!");
        }
      })
      .catch((err) => {
        showErrMsg("Something went wrong!");
        console.log(err);
      });
  };


  useEffect(() => {
    if (oldTicketDetails.SIFlag) {
      setActivateRadioVal(oldTicketDetails.SIFlag);
    }
  }, [oldTicketDetails.SIFlag]);

  useEffect(() => {
    if (openTickets.includes(oldTicketDetails.StatusID)) {
      setIsShowLess(false);
    } else {
      setIsShowLess(true);
    }
  }, [oldTicketDetails.StatusID, ActiveTicket_store]);

  useEffect(() => {
    const subIssueOptions = [];
    const subStatusOptions = [];
    const insurerOptions = [];
    const checkDuplicate = (Options, Id, IdVal) => {
      for (let i = 0; i < Options.length; i++) {
        if (Options[i][Id] === IdVal) return true;
      }
      return false;
    };

    if (selectFormOptions.allSubStatusOptions) {
      selectFormOptions.allSubStatusOptions.map((item) => {
        if (
          item.StatusName === formValues.Status &&
          item.SubStatusName &&
          !checkDuplicate(subStatusOptions, "SubStatusID", item.SubStatusID)
        ) {
          if (item.StatusID === 3 && item.SubStatusID === 7) {
            return;
          }
          subStatusOptions.push(item);
        }
        setSelectFormOptions({
          ...selectFormOptions,
          subStatusOptions: subStatusOptions,
        });
      });
    }
    if (selectFormOptions.allIssueOptions) {
      selectFormOptions.allIssueOptions.map((item) => {
        if (item.ParentID === formValues.IssueTypeId && item.IssueName) {
          subIssueOptions.push(item);
        }
      });
      if (
        subIssueOptions
          .map((i) => i.IssueTypeId)
          .indexOf(formValues.SubIssueID) == -1
      ) {
        subIssueOptions.push({
          IssueTypeId: formValues.SubIssueID,
          IssueName: formValues.SubIssueName,
        });
      }
    }

    if (selectFormOptions.suppliersList) {
      selectFormOptions.suppliersList.map((item) => {
        if (item.ProductID === formValues.ProductId && item.SupplierName) {
          insurerOptions.push(item);
        }
      });
    }

    setSelectFormOptions({
      ...selectFormOptions,
      subStatusOptions: subStatusOptions,
      subIssueOptions: subIssueOptions,
      insurerOptions: insurerOptions,
    });
  }, [
    formValues.Status,
    selectFormOptions.allSubStatusOptions,
    formValues.IssueName,
    formValues.IssueTypeId,
    selectFormOptions.allIssueOptions,
    formValues.ProductId,
  ]);

  const handleOpenDetailIFrame = () => {
    setLoadingEndorsement(true); // loader for endorsement button
    let cb = (data, error) => {
      setLoadingEndorsement(false); 
      if (data) {
        setOpenDetailsURL(data);
        setOpenDetails(true);
      } else showErrMsg(error);
    };
    fetchEndosementURLForExisting("/GetEndorsementSystemUrl", oldTicketDetails, cb);
  };

  const closeDetails = () => {
    setOpenDetails(false);
    window.location.reload();
  };

  const callNewEndorsement = () => {
    const payload = {
      EndorsementID: 0, //10070,
      BookingID: oldTicketDetails.LeadID || 0, //43362530,
      TicketNumber: oldTicketDetails.TicketDetailsID || "",
      ProductID: oldTicketDetails.ProductId || 0,
      TicketID: oldTicketDetails.TicketId || 0,
      CustomerID: oldTicketDetails.CustomerId || 0,
    };
    POST(`${GetEndorsementDetails}`, payload)
      .then((res) => {
        setRaiseEndorsement(res.Data.RaiseNewEndorsementURL);
      })
      .catch((e) => console.log(e));
  };

  const closeRaiseEndorsement = () => {
    setRaiseEndorsement(null);
    window.location.reload();
  };

  // Opens up Refund Card Dialog automatically
  const handleClickOpen = () => {
    setOpenRefundDialog(true);
  };

  const [nameList, setNameList] = useState([]);
  const [names, setNames] = useState();
  const [selectedMultiAgent, setSelectedMultiAgent] = useState(null);

  const onValueChange = (e) => {
    if (formValues.ProductId) {
      let payload = {
        ProductID: formValues.ProductId,
        AgentType: "0",
        GroupID: 0,
        AgentNameEmpID: e.target.value,
        Type: "2",
      };
      if (e.target.value && e.target.value.length >= 3) {
        POST(GetDialerAgentList, payload).then((response) => {
          setNameList(response.Data);
        });
      }
    }
  };
  useEffect(() => {
    if (nameList) {
      var data = nameList.map((item) => {
        return item.AgentName;
      });
      setNames(data);
    }
  }, [nameList]);

  useEffect(() => {
    if (formValues.RCAAgent) {
      setSelectedMultiAgent(formValues.RCAAgent);
    }
  }, [formValues.RCAAgent]);

  const agentResetOnCancel = () => {
    if (formValues.RCAAgent) {
      setSelectedMultiAgent(formValues.RCAAgent);
    }
    setIsTicketDetailEditable(false);
    setFormValues(oldTicketDetails);
  };

  const agentHandle = (event, value) => {
    setNameList([]);
    if (value && value.length > 0) {
      let agentsSelect = value.join(",");
      setSelectedMultiAgent(agentsSelect);
    }
    if (value && value.length == 0) {
      setSelectedMultiAgent([]);
    }
  };

  const openFollowUpCalender = (tid) => {
    GET(`CheckLastReply/${tid}`)
      .then((response) => {
        if (response && response.Data) {
          if (ticketContext.isActionsAllowed) {
            setFollowUpDateCalederOpen(true);
          }
          return;
        } else if (response && !response.Data && response.Message) {
          enqueueSnackbar(response.Message, {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          return false;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  //===============checkSMEEmployeeDetails==================
  const checkSMEEmployeeDetails = () => {
    if (
      oldTicketDetails.ProductId == 131 &&
      oldTicketDetails.InvestmentTypeID === 1 &&
      !(
        ["sales enquiry", "other issue"].indexOf(
          formValues.IssueName.toLowerCase()
        ) > -1
      )
    ) {
      GET(`${CheckSMEEmployeeDetails}/${oldTicketDetails.TicketId}`)
        .then((response) => {
          if (response.ErrorCode === 0) {
            if (response.Data == true) {
              setShowConfirmationBox({ open: true});
            } else {
              Toast.error('Employee Details not present!')
              setShowConfirmationBox({ open: true});
              return false;
            }
          } else {
            Toast.error('Oops! Something went wrong')
            setShowConfirmationBox({ open: true});
            return false;
          }
        })
        .catch((err) => {
          handleCatch(err);
          setShowConfirmationBox({ open: true});
          return false;
        });
    } else {
      setShowConfirmationBox({ open: true});
      return true; // default if api is not called
    }
  };
  //===============xxxxxxxxxxxxxxxxx========================

  const getDocUrl = (docId) => {
    if (docId.length > 0) {
      GET(`/GetDocUrl/${docId}`)
        .then((res) => {
          if (res && res.ErrorCode == 0) {
            let newWindow = window.open(res.Data, "_blank");
            if (newWindow) {
              newWindow.focus();
            } else {
              return Toast.error('Please allow popup to view PDF')
            }
          } 
          else Toast.error(`Oops! Something's not right`)
        })
        .catch((err) => {
          handleCatch(err)
        });
    }
  };

  // fetchCheckSalesTalktime
  const fetchCheckSalesTalktime = () => {
    let url = `CheckIfRenewalTicketResolvableMotor/${OpenedTicketDetails.RenewalLeadId}/${OpenedTicketDetails.CreatedOn}`;
    GET(url)
      .then((res) => {
        if (res && res.ErrorCode === 0) {
          setcheckTalkTime({ show: true, value: res.Data });
        } else showErrMsg(res["Message"] || "Error occurred!");
      })
      .catch((err) => {
        if (err && err.response) {
          showErrMsg(err.response.data.message || "Something went wrong!");
        } else showErrMsg("Something went wrong!");
      });
  };

  const fetchAHCFromAPI = () => {
    let url = "/GetAHCDetails";
    let callback = (data, error) => {
      console.log("daa and error", data, error);
      if (data) {
        setAHDDialog({ show: true, url: data });
      } else showErrMsg(error);
    };
    fetchAHCUrl(url, oldTicketDetails, UserInfo, callback);
  };

  // const fetch

  // for Endorsement Dialog
  useEffect(() => {
    if (oldTicketDetails && formValues && isTicketDetailEditable) {
      console.log('isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails.SupplierId, oldTicketDetails?.InvestmentTypeID)', isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails.SupplierId, oldTicketDetails?.InvestmentTypeID))
      console.log('checkProductIssueSubIssue(formValues?.ProductId, formValues?.IssueName, formValues?.SubIssueName)', checkProductIssueSubIssue(formValues?.ProductId, formValues?.IssueName, formValues?.SubIssueName))

      if (
        isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
        (
            checkProductIssueSubIssue(formValues?.ProductId, formValues?.IssueName, formValues?.SubIssueName) &&
            (formValues.SubStatusName.toLowerCase() === "customer pendency" &&
             !OPEN_ENDORSEMENT_IN_CUSTOMER_PENDENCY_SUBSTATUS.includes(formValues?.ProductId) ? false : true) &&
            !oldTicketDetails.ParentTicketID &&
            (oldTicketDetails.EndorsementID === "0" ||
              Number(oldTicketDetails.EndorsementID) === 0) && oldTicketDetails?.LeadID
          )
      ) {
        let cbForEndorsement = (data, error) => {
          setCustomDialogControl(customDialogInitialState);
          if (data) {
            setRaiseEndorsement(data);
          } else showErrMsg(error);
        };

        let fetchEndoresmentURL = () => {
          fetchEndosementURL(
            "/GetEndorsementSystemUrl",
            oldTicketDetails,
            cbForEndorsement
          );
        };

        setCustomDialogControl({
          title: "Raise new endorsement?",
          open: true,
          actionButtons: [
            {
              Name: "Cancel",
              className: "raise-cencel",
              onClick: () => setCustomDialogControl(customDialogInitialState),
            },
            {
              Name: "Raise Endorsement",
              className: "raise-btn",
              onClick: fetchEndoresmentURL,
            },
          ],
          handleClose: () => setCustomDialogControl(customDialogInitialState),
        });
      }
    }
  }, [formValues.IssueName, formValues.SubIssueName, isTicketDetailEditable]);

  const OpenDispatchDialog = () => {
    setCommonLoaderState({ ConfirmDispatchLoader : true})
    let dispatchPayload = {
      "TicketId": oldTicketDetails?.TicketDetailsID,
      "BookingId": oldTicketDetails?.LeadID,
      "DispatcherId": oldTicketDetails?.DispatcherId,
      "ProductId": oldTicketDetails?.ProductId
    }
    let cb = (url) => {
      setCommonLoaderState({ ConfirmDispatchLoader : false})
      if (url) {
        setDialogWithControls({
          open: true,
          title: `Dispatch Request ${oldTicketDetails?.DispatcherId ? '- ' + oldTicketDetails.DispatcherId : ''}`,
          handleClose: () => { setDialogWithControls(); window.location.reload() },
          size: 80,
          children: <div className="iframe-box"><iframe src={url} referrerPolicy="no-referrer" /></div>
        })
      }
    }
    fetchDispatcherURL(dispatchPayload, cb)
  }

  //Show AHC Dialog
  useEffect(() => {
    if (formValues && isTicketDetailEditable) {
      if (
        formValues.IssueName.toLowerCase() === ahcIssue &&
        formValues.SubIssueName &&
        formValues.SubIssueName.toLowerCase() === ahcSubissue && 
        !oldTicketDetails.AHCId && AHC_SUPPLIERS.includes(formValues.SupplierId)){
        console.log("true value ");
        setCustomDialogControl({
          title: " Raise AHC?",
          open: true,
          actionButtons: [
            {
              Name: "Cancel",
              className: "raise-cencel",
              onClick: () => setCustomDialogControl(customDialogInitialState),
            },
            {
              Name: "Raise AHC",
              className: "raise-btn",
              onClick: fetchAHCFromAPI,
            },
          ],
          handleClose: () => setCustomDialogControl(customDialogInitialState),
        });
      }
      else if(formValues.IssueName && formValues.SubIssueName && checkIssueSubIssueForDispatchRequest(formValues?.ProductId, formValues.IssueName, formValues.SubIssueName)){
        let resetIssueSubIssue = setFormValues((prev) => ({ ...prev, IssueTypeId: oldTicketDetails.IssueTypeId, SubIssueID: oldTicketDetails.StatusID, IssueName: oldTicketDetails.IssueName, SubIssueName: oldTicketDetails.SubIssueName}))
        setCustomDialogControl({
          title: "Raise Dispatch Request",
          open: true,
          actionButtons: [
            {
              Name: "Cancel",
              className: "raise-cencel",
              onClick: () => {setCustomDialogControl(customDialogInitialState); },
            },
            {
              Name: commonLoaderState.ConfirmDispatchLoader ? <Spinner color='white'/> : "Raise Request",
              className: "raise-btn",
              onClick: OpenDispatchDialog,
            },
          ],
          handleClose: () => {setCustomDialogControl(customDialogInitialState);  resetIssueSubIssue()},
        });
      }
    }
  }, [formValues.SubIssueName, commonLoaderState.ConfirmDispatchLoader]);


  const handleViewButton = (fieldName) => {
    let conditions = {
      'SubStatusName' : {
        show: formValues && formValues['SubStatusName'] && formValues['SubStatusName'].toLowerCase() === 'customer pendency' && ENABLE_CUSTOMER_PENDENCY_DIALOG.includes(oldTicketDetails.ProductId) && !oldTicketDetails.ShowCustPendency && !oldTicketDetails.DisableCustomerPendencyPopUp ? true : false,
        onClick: handleCustomerPendencyEditor
      },
      
    }
   return conditions[fieldName] || null
  }

  const renderCustomerPendencyDialog = () => {
    if(oldTicketDetails?.DisableCustomerPendencyPopUp){ return false}
    if (ENABLE_CUSTOMER_PENDENCY_DIALOG.includes(oldTicketDetails && oldTicketDetails.ProductId)) {
      if (formValues && formValues.SubStatusName && formValues.SubStatusName.toLowerCase() === 'customer pendency' && isTicketDetailEditable && !oldTicketDetails.IsEscalated &&
        (BYPASS_REOPEN_TICKET_FOR_CUSTOMER_PENDENCY_DIALOG.includes(oldTicketDetails.ProductId) ? true : !oldTicketDetails.IsReopen)
        && oldTicketDetails.ShowCustPendency) {
        return true
      }
      else return false
    }
  }

  if (viewtype == 0) {
    const { ProductId, IssueName } = OpenedTicketDetails;
    return (
      <div className="ticket-details ">
        {console.log('formvalues', formValues)}
        <List>
          <SelectInput
            isTicketDetailEditable={isTicketDetailEditable}
            inputValue={formValues.Status}
            inputValueId={formValues.StatusID}
            labelVal="Status"
            inputFieldName="Status"
            inputFieldId="StatusID"
            setFormValues={setFormValues}
            options={selectFormOptions.statusOptions.map((option) => ({
              StatusID: option.StatusID,
              Status: option.StatusName,
            }))}
            endorsementDetails={endorsementDetails}
            raiseEndorsementURL={raiseEndorsementURL}
            GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
            RefObjectId={formValues.RefObjectId}
            TicketDetailsID={formValues.TicketDetailsID}
            oldTicketDetails={oldTicketDetails}
            setEndorsementTabClosed={setEndorsementTabClosed}
            popup={popup}
            formValues={formValues}
            showPopup={showPopup}
            setRootCauses={setRootCauses}
          />

          <SelectInput
            isTicketDetailEditable={isTicketDetailEditable}
            inputValue={formValues.SubStatusName}
            inputValueId={formValues.SubStatusID}
            labelVal="Sub Status"
            inputFieldName="SubStatusName"
            inputFieldId="SubStatusID"
            setFormValues={setFormValues}
            options={selectFormOptions.subStatusOptions.map((opt) => ({
              SubStatusID: opt.SubStatusID,
              SubStatusName: opt.SubStatusName,
              ResolvedStatusId: formValues.StatusID,
              EnabledSubStatus:
                formValues.StatusID === 3 &&
                (opt.SubStatusID == 5 ||
                  (opt.SubStatusID == 6 &&
                    isCustomerPendancyEligible(formValues.ProductId)))
                  ? "YES"
                  : "NO",
            }))}
            endorsementDetails={endorsementDetails}
            raiseEndorsementURL={raiseEndorsementURL}
            GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
            RefObjectId={formValues.RefObjectId}
            TicketDetailsID={formValues.TicketDetailsID}
            oldTicketDetails={oldTicketDetails}
            setEndorsementTabClosed={setEndorsementTabClosed}
            popup={popup}
            formValues={formValues}
            showPopup={showPopup}
            setRootCauses={setRootCauses}
            viewButton={handleViewButton('SubStatusName')}
          />
          <ListItem>
            <div
              className="classification-box"
              id='ticketDetailFollowUpDate'
              style={{ pointerEvents: isTicketDetailEditable ? false : "none" }}
              onClick={openCalender}
            >
              <label>Follow-up Date</label>
              <p className="calander-date" id="ticketDetailFollowUpDate-inner">
                {getFollowUpdateTimeInProperFormate(formValues.FollowUpDate)}
                <EventIcon />
              </p>
            </div>
          </ListItem>
          {
            renderCustomerPendencyDialog() && <ChildErrorBoundary>
              <PendencyDescription
                setCustomerPendencyListParent={(val) => setCustomerPendencyState(prev => ({ ...prev, PendencyList: val }))}
                setRaisedPendencyListParent = {(val) => setCustomerPendencyState(prev => ({ ...prev, RaisedPendencyList: val }))}
                setCustomerPendencyValidatedParent={(val) => setCustomerPendencyState(prev => ({ ...prev, PendencyListHasError: val }))}
                randomValueParent={customerPendencyState.randomValueForUpdate}
              />
            </ChildErrorBoundary>
          }
          {(!isShowLess || showNewTabs) && (
            <>
              <SelectInput
                handleClickOpen={handleClickOpen} // function for opening the check eligibility modal
                isTicketDetailEditable={isTicketDetailEditable}
                inputValue={formValues.IssueName}
                inputValueId={formValues.IssueTypeId}
                labelVal="Issue"
                inputFieldName="IssueName"
                inputFieldId="IssueTypeId"
                setFormValues={setFormValues}
                options={selectFormOptions.issueOptions.map((issueOpt) => ({
                  IssueTypeId: issueOpt.IssueTypeId,
                  IssueName: issueOpt.IssueName,
                  IssueCode: issueOpt.IssueCode
                }))}
                isError={errors.issue}
                setErrors={setErrors}
                setSelectFormOptions={setSelectFormOptions}
                endorsementDetails={endorsementDetails}
                raiseEndorsementURL={raiseEndorsementURL}
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                RefObjectId={formValues.RefObjectId}
                TicketDetailsID={formValues.TicketDetailsID}
                oldTicketDetails={oldTicketDetails}
                setEndorsementTabClosed={setEndorsementTabClosed}
                popup={popup}
                formValues={formValues}
                showPopup={showPopup}
              />
              <SelectInput
                isTicketDetailEditable={isTicketDetailEditable}
                inputValue={formValues.SubIssueName}
                inputValueId={formValues.SubIssueID}
                labelVal="Sub Issue"
                inputFieldName="SubIssueName"
                setFormValues={setFormValues}
                inputFieldId="SubIssueID"
                options={selectFormOptions.subIssueOptions.map(
                  (subIssueOpt) => ({
                    SubIssueID: subIssueOpt.IssueTypeId,
                    SubIssueName: subIssueOpt.IssueName,
                  })
                )}
                isError={errors.subIssue}
                setErrors={setErrors}
                setSelectFormOptions={setSelectFormOptions}
                endorsementDetails={endorsementDetails}
                raiseEndorsementURL={raiseEndorsementURL}
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                RefObjectId={formValues.RefObjectId}
                TicketDetailsID={formValues.TicketDetailsID}
                oldTicketDetails={oldTicketDetails}
                setEndorsementTabClosed={setEndorsementTabClosed}
                popup={popup}
                formValues={formValues}
                showPopup={showPopup}
              />
              <ListItem>
                {isTicketDetailEditable ? (
                  <FormControl
                    variant="outlined"
                    className="select-box"
                    error={validateLeadId(formValues.LeadID) ? true : false}
                  >
                    <TextField
                      label="Lead ID"
                      id="outlined-margin-normal ticketDetailLeadID"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      value={formValues.LeadID}
                      disabled={
                        formValues.EndorsementID > 0 || formValues.RefundId > 0 || formValues.AHCId > 0
                      }
                      onChange={(e) => handleFormValues(e, "LeadID")}
                    />
                    {validateLeadId(formValues.LeadID) && (
                      <FormHelperText>
                        {validateLeadId(formValues.LeadID)}
                      </FormHelperText>
                    )}
                    <Button
                      className="refresh-btn"
                      id="isTicketDetailAHCID"
                      disabled={
                         formValues.AHCId > 0
                      }
                      onClick={() =>
                        GetIssueSubIssueAndLeadDetailsapi(formValues.LeadID)
                      }
                    >
                      <CachedIcon />
                    </Button>
                  </FormControl>
                ) : (
                  <div className="classification-box">
                    <label>Lead ID</label>
                    <p id="isTicketDetailLeadID">{formValues.LeadID}</p>
                  </div>
                )}
              </ListItem>

              {/* Refund Card */}
              {ProductEligibleForRefund(
                oldTicketDetails.ProductId,
                oldTicketDetails.SupplierId
              ) &&
              handleCancellationSelection(formValues.IssueCode) &&
              oldTicketDetails.StatusID != 3 ? (
                <RefundCard
                  isViewDetail={isViewDetail}
                  setIsTicketDetailEditable={setIsTicketDetailEditable}
                  formValues={formValues}
                  setGoToTemplate={setGoToTemplate}
                  communications={communications}
                  autoOpenRefundCard={openRefundDialog}
                />
              ) : null}

              <SelectInput
                isTicketDetailEditable={
                  isTicketDetailEditable &&
                  formValues.LeadID == 0 &&
                  (ticketContext.isProductValueChanged ||
                    oldTicketDetails.LeadID == 0)
                    ? true
                    : false
                }
                inputValue={formValues.ProductName}
                inputValueId={formValues.ProductId}
                labelVal="Product"
                inputFieldName="ProductName"
                setFormValues={setFormValues}
                inputFieldId="ProductId"
                options={selectFormOptions.productsList.map((option) => ({
                  ProductId: option.ProductID,
                  ProductName: option.ProductName,
                }))}
                isError={errors.product}
                setErrors={setErrors}
                endorsementDetails={endorsementDetails}
                raiseEndorsementURL={raiseEndorsementURL}
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                RefObjectId={formValues.RefObjectId}
                TicketDetailsID={formValues.TicketDetailsID}
                oldTicketDetails={oldTicketDetails}
                setEndorsementTabClosed={setEndorsementTabClosed}
                popup={popup}
                formValues={formValues}
                showPopup={showPopup}
              />
              <SelectInput
                isTicketDetailEditable={
                  isTicketDetailEditable &&
                  formValues.LeadID == 0 &&
                  (ticketContext.isProductValueChanged ||
                    oldTicketDetails.LeadID == 0)
                    ? true
                    : false
                }
                inputValue={formValues.SupplierName}
                inputValueId={formValues.SupplierId}
                labelVal="Insurer"
                inputFieldName="SupplierName"
                setFormValues={setFormValues}
                inputFieldId="SupplierId"
                options={selectFormOptions.insurerOptions.map((option) => ({
                  SupplierId: option.SupplierID,
                  SupplierName: option.SupplierName,
                }))}
                isError={errors.supplier}
                setErrors={setErrors}
                endorsementDetails={endorsementDetails}
                raiseEndorsementURL={raiseEndorsementURL}
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                RefObjectId={formValues.RefObjectId}
                TicketDetailsID={formValues.TicketDetailsID}
                oldTicketDetails={oldTicketDetails}
                setEndorsementTabClosed={setEndorsementTabClosed}
                popup={popup}
                formValues={formValues}
                showPopup={showPopup}
              />
              <SelectInput
                isTicketDetailEditable={isTicketDetailEditable}
                inputValue={getClassificationDefaultValue(
                  formValues.ClassificationID
                )}
                inputValueId={formValues.ClassificationID}
                labelVal="Request Type"
                inputFieldName="RequestType"
                inputFieldId="ClassificationID"
                setFormValues={setFormValues}
                options={selectFormOptions.classificationOptions.map(
                  (option) => ({
                    ClassificationID: option.RequestTypeID,
                    RequestType: option.RequestTypeName,
                  })
                )}
                endorsementDetails={endorsementDetails}
                raiseEndorsementURL={raiseEndorsementURL}
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                RefObjectId={formValues.RefObjectId}
                TicketDetailsID={formValues.TicketDetailsID}
                oldTicketDetails={oldTicketDetails}
                setEndorsementTabClosed={setEndorsementTabClosed}
                popup={popup}
                formValues={formValues}
                showPopup={showPopup}
              />
              <SelectInput
                isTicketDetailEditable={isTicketDetailEditable}
                inputValue={RootCauses}
                inputValueId={RootCauses}
                labelVal="Root Cause"
                inputFieldName="RootCause"
                setFormValues={setFormValues}
                options={selectFormOptions.rootCauseOptions}
                handleFormValues={handleFormValues}
                endorsementDetails={endorsementDetails}
                raiseEndorsementURL={raiseEndorsementURL}
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                RefObjectId={formValues.RefObjectId}
                TicketDetailsID={formValues.TicketDetailsID}
                oldTicketDetails={oldTicketDetails}
                setEndorsementTabClosed={setEndorsementTabClosed}
                popup={popup}
                formValues={formValues}
                showPopup={showPopup}
              />

              {((Array.isArray(formValues.RootCause) &&
                formValues.RootCause.includes("Agent Delay")) ||
                RootCauses.indexOf("Agent Delay")) > -1 ? (
                <ListItem>
                  <div className="multiple-agent-box">
                    {isTicketDetailEditable ? (
                      <Autocomplete
                        multiple
                        disabled={!isTicketDetailEditable}
                        id="ticketDetailAgentName"
                        options={names ? names : []}
                        getOptionLabel={(names) => names}
                        defaultValue={formValues.RCAAgent.split(",").filter(
                          (x) => x
                        )}
                        onChange={agentHandle}
                        onBlur={agentHandle}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Agent Name"
                            onChange={(e) => onValueChange(e)}
                          />
                        )}
                      />
                    ) : (
                      <div className="multi-agent-view">
                        <label>Assign to</label>
                        <div className="agents-names">
                          {formValues.RCAAgent.split(",").map((x) => (
                            <span className="tag-chip">{x}</span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </ListItem>
              ) : null}
              <SelectInput
                isTicketDetailEditable={isTicketDetailEditable}
                inputValue={formValues.CallbackVal}
                inputValueId={formValues.IsCallBackRequest}
                labelVal="Callback"
                inputFieldName="CallbackVal"
                inputFieldId="IsCallBackRequest"
                setFormValues={setFormValues}
                options={selectFormOptions.callbackOptions.map(
                  (callbackValue) => ({
                    IsCallBackRequest: callbackValue.Value,
                    CallbackVal: callbackValue.Text,
                  })
                )}
                endorsementDetails={endorsementDetails}
                raiseEndorsementURL={raiseEndorsementURL}
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                RefObjectId={formValues.RefObjectId}
                TicketDetailsID={formValues.TicketDetailsID}
                oldTicketDetails={oldTicketDetails}
                setEndorsementTabClosed={setEndorsementTabClosed}
                popup={popup}
                formValues={formValues}
                showPopup={showPopup}
              />
              {formValues.SubStatusName === "Insurer Pendency" &&
              formValues.SendToInsurer &&
              isTicketDetailEditable &&
              !oldTicketDetails.SendToInsurer ? (
                <ListItem className="textarae-box">
                  <TextareaAutosize
                    placeholder="Enter comment"
                    onChange={(e) => handleFormValues(e, "SendToInsurerMsg")}
                    value={formValues.SendToInsurerMsg}
                    disabled={!isTicketDetailEditable}
                  />
                </ListItem>
              ) : (
                ""
              )}
              <ListItem>
                {isTicketDetailEditable ? (
                  <FormControl variant="outlined" className="select-box">
                    <TextField
                      label="RCA"
                      id="ticketDetailRCA"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      value={formValues.RCA}
                      onChange={(e) => handleFormValues(e, "RCA")}
                    />
                  </FormControl>
                ) : (
                  <div className="classification-box">
                    <label>RCA</label>
                    <Tooltip
                      title={formValues.RCA}
                      aria-label="autoClosure"
                      placement="top-start"
                    >
                      <p id="isTicketDetailRCA" className="rca-value-box">{formValues.RCA}</p>
                    </Tooltip>
                  </div>
                )}
              </ListItem>

              {oldTicketDetails.ProductId === 2 && (
                <SelectInput
                  isTicketDetailEditable={false}
                  inputValue={oldTicketDetails.InitiatedBy}
                  inputValueId={1}
                  labelVal="Initiated By"
                  inputFieldName="InitiatedBy"
                  setFormValues={setFormValues}
                  options={[]}
                  handleFormValues={handleFormValues}
                  endorsementDetails={endorsementDetails}
                  raiseEndorsementURL={raiseEndorsementURL}
                  GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                  RefObjectId={formValues.RefObjectId}
                  TicketDetailsID={formValues.TicketDetailsID}
                  oldTicketDetails={oldTicketDetails}
                  setEndorsementTabClosed={setEndorsementTabClosed}
                  popup={popup}
                  formValues={formValues}
                  showPopup={showPopup}
                />
              )}
              {/*  Renewal Lead ID is shown only if Renewal ID is there in TIcketDetails and SubIssue is change in Policy required */}
              {oldTicketDetails?.RenewalLeadId ? (
                <ListItem className="renewal-leadid-box">
                  <FormControl className="select-box">
                    <TextField
                      id="outlined-basic isTicketDetailRenewalLeadId"
                      label="Renewal LeadID"
                      variant="outlined"
                      disabled
                      value={oldTicketDetails && oldTicketDetails.RenewalLeadId}
                    />
                  </FormControl>
                </ListItem>
              ) : null}

              {isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails.SupplierId, oldTicketDetails?.InvestmentTypeID) && formValues.EndorsementID && Number(formValues.EndorsementID) > 0
                 ? (
                <ListItem>
                  {isTicketDetailEditable ? (
                    <FormControl variant="outlined" className="select-box">
                      <TextField
                        label="Endorsement ID"
                        id="outlined-margin-normal isTicketDeatilEndorsementID"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={formValues?.EndorsementID}
                        disabled
                      />
                    </FormControl>
                  ) : (
                    <div className="classification-box">
                      <label>Endorsement ID</label>
                      <p>{formValues.EndorsementID}</p>
                          {formValues.EndorsementID > 0 && (
                            <Button
                              className="view-btn"
                              onClick={handleOpenDetailIFrame}
                              disabled={IsReadOnlyUser || loadingEndorsement}
                            >
                              {loadingEndorsement ? <Spinner /> : "View Details"}
                            </Button>
                          )}
                    </div>
                  )}
                </ListItem>
              ) : null}
              {POLICY_DISPATCH_PRODUCTS.includes(oldTicketDetails.ProductId) &&
                oldTicketDetails.DispatcherId ? (
                <ListItem>
                  <div className="classification-box">
                    <label>Dispatcher ID</label>
                    <p>{oldTicketDetails.DispatcherId}</p>
                    {oldTicketDetails?.DispatcherId > 0 && (
                      <Button className="view-btn" onClick={OpenDispatchDialog}>
                        {commonLoaderState.ConfirmDispatchLoader ? <Spinner color={'white'}/> : `View Details`}
                      </Button>
                    )}
                  </div>
                </ListItem>
              ) : null}
              {/* AHC Details */}
              {showAHCId(oldTicketDetails.ProductId) &&
              oldTicketDetails.AHCId ? (
                  <ListItem>
                    <div className="classification-box">
                      <label>AHC ID</label>
                      <p>{formValues.AHCId}</p>
                      {formValues.AHCId > 0 && (
                        <Button className="view-btn" onClick={fetchAHCFromAPI}>
                          View Details
                        </Button>
                      )}
                    </div>
                </ListItem>
              ) : null}
              {/* )} */}
              <br />
              {Number(OpenedTicketDetails.EndorsementID) > 0 &&
              Number(OpenedTicketDetails.RenewalLeadId) > 0 ? (
                <div className="check-talktime-box">
                  <Button
                    className="check-talktime-btn"
                    onClick={fetchCheckSalesTalktime}
                    id="isTicketDeatilEndorsementID"
                  >
                    Check Sales Talktime <i className="fa fa-angle-right"></i>
                  </Button>
                  {checkTalkTime &&
                    checkTalkTime.show &&
                    checkTalkTime.value && (
                      <span className="talktime-fullfilled">
                        Talktime fulfilled
                      </span>
                    )}
                  {checkTalkTime &&
                    checkTalkTime.show &&
                    !checkTalkTime.value && (
                      <span className="talktime-not-fullfilled">
                        Talktime Not fulfilled
                      </span>
                    )}
                </div>
              ) : null}
              {isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails?.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
              !oldTicketDetails.ParentTicketID &&
              oldTicketDetails?.StatusID == 5 &&
              // oldTicketDetails?.IssueName?.toLowerCase() == endorsementIssue 
              checkProductIssueSubIssue(oldTicketDetails?.ProductId, oldTicketDetails?.IssueName, oldTicketDetails?.SubIssueName) && oldTicketDetails?.LeadID
              ? (
                <ListItem>
                  <Button className="endorse-btn" onClick={callNewEndorsement}>
                    Raise new endorsement
                  </Button>
                </ListItem>
              ) : (
                ""
              )}
              
              {ProductId == SME && formValues.InvestmentTypeID !== 13 && formValues.LeadID !== 0 &&
                  IssueName && 
                  IssueName.toLowerCase().includes("endorsement request") && (
                    <Button
                      className="endosrement-btn"
                      onClick={() => setEndoresmentDialogOpen(true)}
                      id="isTicketDeatilAddEndorsement"
                                          >
                      Add Endorsement Details
                    </Button>
                )}
              <ListItem className="activity-box radio-box">
                {/* Priority Flag */}
                {showPriorityFlag(oldTicketDetails.ProductId) ? (
                    <span className="activity-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formValues.PriorityFlag ? true : false}
                            onChange={handleChange}
                            name="PriorityFlag"
                            color="primary"
                            disabled={!isTicketDetailEditable}
                            id="isTicketDetailPriorityFlag"
                          />
                        }
                        label="Priority Flag"
                      />
                    </span>
                ) : null}

                {oldTicketDetails.IssueSIFlag === 1 &&
                  (oldTicketDetails.ProductId === 7 ||
                    oldTicketDetails.ProductId === 115) && (
                    <>
                      <span className="activity-checkbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formValues.SIFlag}
                              onChange={handleChange}
                              name="SIFlag"
                              color="primary"
                              id="isTicketDeatilSiFlag"
                              disabled={!isTicketDetailEditable}
                            />
                          }
                          label="SI Flag"
                        />
                      </span>
                      {formValues.SIFlag ? (
                        <RadioGroup
                          className="radio-block"
                          row
                          aria-label="position"
                          name="position"
                          value={String(ActivateRadioVal)}
                          onChange={(e) => setActivateRadioVal(e.target.value)}
                          defaultValue={
                            formValues.SIFlag === 1
                              ? "1"
                              : formValues.SIFlag === 2
                              ? "2"
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="Active"
                            disabled={!isTicketDetailEditable}
                            id="isTicketDeatilSiFlagActive"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label="Inactive"
                            disabled={!isTicketDetailEditable}
                            id="isTicketDeatilSiFlagDeactive"
                          />
                        </RadioGroup>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                {formValues.SubStatusName === "Insurer Pendency" &&
                  !IsReadOnlyUser && oldTicketDetails.ProductId !== 131 && (
                    <span className="activity-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formValues.SendToInsurer}
                            onChange={handleChange}
                            name="SendToInsurer"
                            color="primary"
                            disabled={
                              !isTicketDetailEditable ||
                              oldTicketDetails.SendToInsurer ||
                              (DISABLED_SEND_TO_INSURER_PRDOUCTS_SUPPLIER(oldTicketDetails?.ProductId, oldTicketDetails?.SupplierId) &&  oldTicketDetails.EndorsementID > 0) || 
                              (RAISE_TO_BRANCH_PRODUCTS.includes(oldTicketDetails?.ProductId) && (oldTicketDetails?.IsRaiseToBranch || formValues?.IsRaiseToBranch))
                            }
                            id="sendToInsurerButton_ticketDetails"
                          />
                        }
                        label="Send To Insurer"
                      />
                    </span>
                  )}
                {formValues.SubStatusName === "Insurer Pendency" &&
                  !IsReadOnlyUser && RAISE_TO_BRANCH_PRODUCTS.includes(oldTicketDetails?.ProductId) && (
                    <span className="activity-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formValues.IsRaiseToBranch}
                            onChange={handleChange}
                            name="IsRaiseToBranch"
                            color="primary"
                            disabled={
                              !isTicketDetailEditable ||
                              oldTicketDetails?.IsRaiseToBranch ||
                              oldTicketDetails?.SendToInsurer || formValues?.SendToInsurer
                            }
                            id="is-raise-to-branch"
                          />
                        }
                        label="Raise to branch"
                      />
                    </span>
                  )}
                {oldTicketDetails.IssueChangeInPolicyFlag === 1 &&
                  (oldTicketDetails.ProductId === 7 ||
                    oldTicketDetails.ProductId === 115) && (
                    <span className="activity-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formValues.ChangeInPolicyFlag}
                            onChange={handleChange}
                            name="ChangeInPolicyFlag"
                            color="primary"
                            disabled={!isTicketDetailEditable}
                            id="isTicketDeatilChangeInPolicyFlag"
                          />
                        }
                        label="Change in Policy"
                      />
                      {formValues.ChangeInPolicyFlag ? (
                        <>
                          {formValues.UploadURL ? (
                            // <a
                            //   // href={formValues.UploadURL}
                            //   target="_blank"
                            //   rel="noreferrer"
                            // >

                            // </a>
                            <Button
                              className="policy-copy-btn"
                              onClick={() => getDocUrl(formValues.UploadURL)}
                              disabled={IsReadOnlyUser}
                              id="isTicketDeatilUploadUrl"
                            >
                              Policy copy
                            </Button>
                          ) : (
                            ""
                          )}
                          {oldTicketDetails.StatusID === 4 ||
                          oldTicketDetails.StatusID === 3 ||
                          oldTicketDetails.StatusID === 6 ? (
                            ""
                          ) : isTicketDetailEditable ? (
                            <FileUploadComponent
                              setFiles={setChangeInPolicyFile}
                              fileRef={fileRef}
                              isMultipleFileAllowed={false}
                              isAttachementIcon={false}
                              acceptValue="application/pdf"
                            />
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {isTicketDetailEditable && changeInPolicyFile.FileName && (
                        <>
                          <span className="name-file">
                            {changeInPolicyFile.FileName}
                            {isTicketDetailEditable && (
                              <button onClick={() => setChangeInPolicyFile({})}>
                                <CloseIcon />
                              </button>
                            )}
                          </span>
                        </>
                      )}
                    </span>
                  )}
              </ListItem>
              {oldTicketDetails.StatusID === 4 ||
              oldTicketDetails.StatusID === 3 ||
              oldTicketDetails.StatusID === 6 ||
              oldTicketDetails.ParentTicketID ||
              !ticketContext.isActionsAllowed ? (
                ""
              ) : (
                <Grid container>
                  <Grid item md={12} xs={12} className="footer-ticket-data">
                    {!ticketUpdateInProgress &&
                      !IsReadOnlyUser &&
                      !isTicketDetailEditable && (
                        <Button
                          className="submit-btn"
                          id="ticketDetailsEditbtn"
                          onClick={() => {
                            isTicketDetailEditable &&
                              setFormValues(oldTicketDetails);
                            isTicketDetailEditable &&
                              GetIssueSubIssueAndLeadDetailsapi(
                                oldTicketDetails.LeadID
                              );
                            setIsTicketDetailEditable(!isTicketDetailEditable);
                            ticketContext.setIsProductValueChanged(false);
                            setChangeInPolicyFile({});
                            setRootCauses(
                              oldTicketDetails.RootCause.split(",")
                            );
                            setErrors({
                              issue: false,
                              subIssue: false,
                              followUpdate: false,
                            });
                          }}
                        >
                          <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                          Edit
                        </Button>
                      )}
                    {isTicketDetailEditable && (
                      <Button
                        className="submit-btn"
                        onClick={agentResetOnCancel}
                        id="ticketDetailsCancelbtn"
                      >
                        Cancel
                      </Button>
                    )}

                    {isTicketDetailEditable && (
                      <Button
                        className="submit-btn"
                        onClick={handleSaveChanges}
                        disabled={ticketUpdateInProgress}
                        id="ticketDetailsSaveChangesbtn"
                      >
                        {ticketUpdateInProgress ? <Spinner /> : "Save Changes"}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </List>
        {!showNewTabs ? (
          <Button
            className="show-more-btn"
            onClick={() => setIsShowLess(!isShowLess)}
            id="ticketDetailsShowMore"
          >
            <span>{isShowLess ? "Show more" : "Show less"}</span>
          </Button>
        ) : null}
        {isEndoresmentDialogOpen && (
          <EndorsementDialog
            isEndoresmentDialogOpen={isEndoresmentDialogOpen}
            setEndoresmentDialogOpen={setEndoresmentDialogOpen}
          />
        )}
        <ConfirmationDialog
          isConfirmDialogOpen={showConfirmationBox?.open}
          setConfirmDialogOpen={setShowConfirmationBox}
          updateFn={handleUpdateTicket}
          claimsMsg={
            showConfirmationBox?.title ||
              (oldTicketDetails.ProductId == 131 &&
                formValues.Status &&
                formValues.Status.toLowerCase() == "resolved" &&
                ["cashless claim issue", "claim reimbursement issue"].includes(
                  formValues.IssueName.toLowerCase()
                )
                ? "Claim is not settled by claim team, do you still want to resolve the customer query?"
                : formValues.IssueName &&
                  formValues.IssueName !== oldTicketDetails.IssueName &&
                  [2, 114, 3, 117, 106, 118, 131].includes(
                    oldTicketDetails.ProductId
                  )
                  ? formValues.IssueName.toLowerCase().includes("claim")
                    ? "Are you sure you want to transfer this case to the claims team?"
                    : ""
                  : "")
          }
        />
        {isFollowUpDateCalederOpen && (
          <div
            className="follow-update-view"
            onClick={handleFolloupCalenderClose}
          >
            <iframe
              id="followUpCalender-calender"
              ref={iframeRef}
              className="iframe-box"
              src={
                oldTicketDetails.FollowUpURL ? oldTicketDetails.FollowUpURL : ""
              }
            ></iframe>
          </div>
        )}
        <EndorsementDetailsDialog
          openDetails={openDetails}
          closeDetails={closeDetails}
          iframeRef={iframeRef}
          openDetailsURL={openDetailsURL}
        />

        {/* AHC Dialog */}
        <AHCDetailsDialog
          open={AHCDialog.show}
          close={() => {
            setAHDDialog({ show: false });
            window.location.reload();
          }}
          ref={iframeRef}
          url={AHCDialog.url}
        />

        <RaiseNewEndorsement
          open={Boolean(raiseEndorsement)}
          raiseEndorsementURL={raiseEndorsement}
          closeRaiseEndorsement={closeRaiseEndorsement}
          iframeRef={iframeRef}
        />

        <CustomRaiseRequestModal
          open={customDialogControl.open}
          title={customDialogControl.title}
          buttonWithFunction={customDialogControl.actionButtons}
          handleClose={customDialogControl.handleClose}
        />
        {customPopupControl && <CustomPopup
          open={customPopupControl.open}
          handleClose={customPopupControl.handleClose}
          title={customPopupControl.title}
          children={customPopupControl.children}
          className={customPopupControl.className}
                  />
        }
        {dialogWithControls && dialogWithControls.open &&
          <DialogFromRight
            open={dialogWithControls.open}
            handleClose={dialogWithControls.handleClose}
            title={dialogWithControls.title}
            children={dialogWithControls.children}
            size={dialogWithControls.size}
          />
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state && state.auth && state.auth.auth ? state.auth.auth : {},
    OpenedTicketDetails:
      state && state.ticketdetails && state.ticketdetails.ticketDetails
        ? state.ticketdetails.ticketDetails
        : {},
    IsReadOnlyUser:
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser,
    UserInfo: state && state.auth && state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetOldTicketDetails: (payload) =>
      dispatch(actions.setTicketDetails(payload)),
    setTicketLoading_store: (payload) =>
      dispatch(actions.setTicketLoading(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetail);
