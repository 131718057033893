// Topbar Create Ticket Button  adjacent to More Options
import React, { useState, useEffect, useRef, useContext, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { GET, POST } from "../../../services/apiServices";
import {
  GetIssueSubIssueAndLeadDetails,
  CreateTicketForCare,
  GetTicketSummary,
  GetEndorsementDetails,
} from "../../../constants/apiUrls";
import {
  InputLabel,
  Select,
  Grid,
  FormControl,
  Button,
  TextareaAutosize,
  FormHelperText,
  TextField,
  MenuItem,
  Slide,
  Typography,
  Dialog,
} from "@material-ui/core";
import {
  validateLeadId,
  handleDrag,
  handleDragOut,
  handleDragIn,
  handleDrop,
  showEndorsementDialogInTicketCreation,
  checkProductIssueSubIssue,
  checkIssueSubIssueForDispatchRequest,
} from "../../../shared/methods/methods";
import Spinner from "../../../shared/components/Spinner";
import CloseIcon from "@material-ui/icons/Close";
import TicketContext from "../../../TicketContext";
import CopyTicketIdDialog from "../../../shared/components/CopyTicketIdDialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FileUploadComponent from "../../../shared/components/FileUploadComponent";
import { useSelector, useDispatch } from "react-redux";
import {
  SME,
  endorsementIssue,
  isEndorsementEligible,
  UsersAllowedForTicketCreation,
  ahcIssue,
  ahcSubissue,
  AHC_SUPPLIERS,
} from "../../../Config";
import { connect } from "react-redux";
import { CloseOutlined } from "@material-ui/icons";
import ErrorMessages from "../../../shared/constants";
import CustomRaiseRequestModal from "../../Dialog/CustomRaiseRequestModal";
import AHCDetailsDialog from "../../TicketDetail/Components/AHCDetailsDialog";
import { fetchAHCUrl, fetchDispatcherURL, fetchEndosementURL } from "../../../shared/methods/apiMethods";
import { DialogFromRight, Toast } from "../../../shared/components";
import CustomBackdrop from "../../../pages/Components/CustomBackdrop";
import { setActiveTicketID } from "../../../redux/actions/ticketDetails";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
  },
  title: {
    flex: 1,
    fontSize: "16px",
  },
  formControl: {
    minWidth: "100%",
  },
  h6: {
    color: "#414141",
    fontSize: "14px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NativeSelects = ({
  OpenedThroughSource,
  sourceList,
  setCreateDialog,
  TicketDetailsID,
  RefObjectId,
  isCreateDialogOpen,
  supplierList = [],
  AccessTypeVal,
  Body,
  Attachments,
  subSource,
  leadId,
  email,
  employeeName,
  FetchedTicketDetails, // fetching ticketDetails from redux
}) => {
  const classes = useStyles();
  const iframeRef = useRef(null);
  const dispatch = useDispatch();
  const ticketContext = useContext(TicketContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isShowLess, setIsShowLess] = useState(true);
  const fileRef = useRef("");
  const [fetchDetailFormValues, setfetchDetailFormValues] = useState({
    Source: "PB Manual",
    leadId: "",
  });
  const [endorsementLoading, setEndorsementLoading] = useState(false);

  //Code for Custom/ Common Dialog--
  const customDialogInitialState = {
    title: false,
    actionButtons: [],
    open: false,
    handleClose: null,
  };
  const [customDialogControl, setCustomDialogControl] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    customDialogInitialState
  );
  //------------------------------------

  //For AHC dialog control
  const [AHCDialog, setAHCDialog] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      show: false,
      url: null,
    }
  );


  const [createTicketFormValues, setCreateTicketFormValues] = useState({
    issue: "",
    issueId: "",
    subIssueId: "",
    subIssue: "",
    initiatedby: "",
    comment: "",
  });

  const [isCreateTicketInProgress, setIsCreateTicketInProgress] =
    useState(false);

  const [fetchDetailFormErrors, setfetchDetailFormErrors] = useState({
    Source: false,
    leadId: false,
  });

  const [createTicketFormErrors, setCreateTicketFormErrors] = useState({
    issue: false,
    subIssue: false,
    initiatedby: false,
  });
  const [errMessages, setErrMessages] = useState({ leadId: "" });
  const [isFetchDetail, setIsFetchDetail] = useState({});
  const [fetchingDetail, setFetchingDetail] = useState(false);
  const [issueOptions, setIssueOptions] = useState([]);
  const [subIssueOptions, setSubIssueOptions] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [TicketCreatedMsg, setTicketCreatedMsg] = useState("");
  const [raiseEndorsementURL, setRaiseEndorsementURL] = useState("");
  const [open, setOpen] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);
  const [dialogWithControls, setDialogWithControls] = useState();

  const auth = useSelector((state) => state.auth.userInfo);
  useEffect(() => {
    if (leadId) {
      setfetchDetailFormValues((prevState) => {
        return {
          ...prevState,
          leadId: leadId,
        };
      });
      handleFetchDetail("", leadId);
    }
  }, [leadId]);

  const InitiatedByList = ["Customer", "Insurer"];

  const validateForm = (values, setErr) => {
    let errObj = {};
    Object.keys(values).forEach((field) => {
      !values[field] && (errObj[field] = true);
      if (!fetchDetailFormValues.leadId) {
        setErrMessages({
          leadId: validateLeadId(fetchDetailFormValues.leadId),
        });
      }
    });

    setErr(errObj);
  };

  const handleIssueSubIssue = (id, val, passedFieldName = "subIssue") => {
    if (passedFieldName === "issue" || passedFieldName === 'subIssue') {
      let issueName = passedFieldName === 'issue' ? val : createTicketFormValues.issue
      let subIssueName = passedFieldName === 'subIssue' ? val : createTicketFormValues.subIssue
      if (
        // showEndorsementDialogInTicketCreation(val) &&
        checkProductIssueSubIssue(isFetchDetail.leadDetailResForUI?.ProductID, issueName, subIssueName) &&
        isEndorsementEligible(isFetchDetail.leadDetailResForUI.ProductID, isFetchDetail.leadDetailResForUI.SupplierID, isFetchDetail.leadDetailResForUI?.InvestmentTypeID)
        && isFetchDetail.leadDetailResForUI.LeadID
      ) {
        const payload = {
          EndorsementID: 0, //10070,
          LeadID: fetchDetailFormValues.leadId || 0, //43362530,
          TicketDetailsID: "",
          ProductId: isFetchDetail.leadDetailResForUI.ProductID || 0,
          TicketId: 0,
          CustomerId:
            (isFetchDetail &&
              isFetchDetail.leadDetailResForUI &&
              isFetchDetail.leadDetailResForUI.CustomerID) ||
            0,
        };
        let cb = (data, err) => {
          setEndorsementLoading(false);
          if (data) {
            setRaiseEndorsementURL(data);
            setOpen(true);
          }
          else {
            Toast.error(err);
          }
        }
        setEndorsementLoading(true);
        fetchEndosementURL('/GetEndorsementSystemUrl', payload, cb);
      }
      else {
        return passedFieldName === 'issue' ? setCreateTicketFormValues((prev) => ({
          ...prev,
          issueId: id,
          issue: val,
        })) :
          setCreateTicketFormValues((prev) => ({
            ...prev,
            subIssueId: id,
            subIssue: val,
          }))
      }
    } else if (passedFieldName === "initiatedby") {
      setCreateTicketFormValues((prev) => ({
        ...prev,
        initiatedby: val,
      }));
    } else {
      setCreateTicketFormValues((prev) => ({
        ...prev,
        subIssueId: id,
        subIssue: val,
      }));
    }
  };

  const GetIssueSubIssueAndLeadDetailsapi = (leadId = "") => {
    setFetchingDetail(true);
    GET(
      `${GetIssueSubIssueAndLeadDetails}/${
        leadId ? leadId : fetchDetailFormValues.leadId
      }/${1}`
    )
      .then((response) => {
        if (response.ErrorCode === 0) {
          setIsFetchDetail(response.Data);
          const issueList = response.Data.issueTypeMasters.filter(
            (item) => item.ParentID === 0
          );
          setIssueOptions(issueList);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setFetchingDetail(false);
      })
      .catch((err) => {
        setFetchingDetail(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };

  const getAllTicketSummary = (RefObjectId, TicketDetailsID) => {
    ticketContext.setAllTicketsLoading(true);
    GET(`${GetTicketSummary}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter(
                (i) => i.StatusID != 3 && i.StatusID != 4
              ).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData2 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 3).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData3 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 4).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          ticketContext.setAllTickets({
            ...response.Data,
            AllTicketList: [...sortedData1, ...sortedData2, ...sortedData3],
          });
          dispatch(setActiveTicketID(response.Data.TicketId))
          ticketContext.setActiveTicketId(response.Data.TicketId);
        } else {
          // console.log("hello2");
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        ticketContext.setAllTicketsLoading(false);
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };

  useEffect(() => {
    const subIssueOptionsList = [];
    if (createTicketFormValues.issue) {
      isFetchDetail.issueTypeMasters.map((item) => {
        if (item.ParentID === createTicketFormValues.issueId) {
          subIssueOptionsList.push(item);
        }
      });
      setSubIssueOptions(subIssueOptionsList);
    }
  }, [createTicketFormValues.issue]);

  const handleFetchDetail = (e, leadId = "") => {
    if (!leadId) {
      validateForm(fetchDetailFormValues, setfetchDetailFormErrors);
      if (
        !fetchDetailFormValues.Source ||
        !fetchDetailFormValues.leadId ||
        errMessages.leadId
      ) {
        return;
      }
    }
    GetIssueSubIssueAndLeadDetailsapi(leadId);
    setIsShowLess(false);
  };

  const showErrMsg = (msg) => {
    enqueueSnackbar(msg, {
      variant: "error",
      onClick: () => {
        closeSnackbar();
      },
    });
  };

  const getSupplierName = () => {
    if (
      isFetchDetail &&
      isFetchDetail.leadDetailResForUI &&
      ticketContext.suppliersList.length > 0
    ) {
      const supplier = ticketContext.suppliersList.find(
        (item) =>
          item.SupplierID === isFetchDetail.leadDetailResForUI.SupplierID
      );
      if (supplier) {
        return supplier.SupplierName;
      }
    } else if (
      isFetchDetail &&
      isFetchDetail.leadDetailResForUI &&
      supplierList.length > 0
    ) {
      const supplier = supplierList.find(
        (item) =>
          item.SupplierID === isFetchDetail.leadDetailResForUI.SupplierID
      );
      if (supplier) {
        return supplier.SupplierName;
      }
    }
    return "";
  };

  const handleCreateTicket = () => {
    if (
      isEndorsementEligible(isFetchDetail.leadDetailResForUI.ProductID, isFetchDetail.leadDetailResForUI.SupplierID, isFetchDetail.leadDetailResForUI?.InvestmentTypeID) &&
      // createTicketFormValues.issue.toLowerCase() == endorsementIssue
      checkProductIssueSubIssue(isFetchDetail.leadDetailResForUI?.ProductID, createTicketFormValues?.issue, createTicketFormValues?.subIssue) && isFetchDetail.leadDetailResForUI?.LeadID
    ) {
      if(raiseEndorsementURL){
        return setOpen(true);
      }
      else{
        return Toast.error('Please raise endorsement ticket from the endorsement portal')
      }
    }
    if (
      fetchDetailFormValues.Source === "PB Manual" &&
      isFetchDetail.leadDetailResForUI &&
      [115, 7].includes(isFetchDetail.leadDetailResForUI.ProductID) &&
      auth &&
      auth.Source &&
      auth.Source.toLowerCase() != "matrix" &&
      auth &&
      auth.UserID &&
      !UsersAllowedForTicketCreation(auth.UserID)
    ) {
      showErrMsg(
        "PB Manual ticket can't be created for Term & Investment Products"
      );
      return;
    }

    if (
      fetchDetailFormValues.Source === "PB Manual" &&
      OpenedThroughSource &&
      OpenedThroughSource != "SMEPage" &&
      isFetchDetail.leadDetailResForUI &&
      [131].includes(isFetchDetail.leadDetailResForUI.ProductID) &&
      isFetchDetail.leadDetailResForUI.InvestmentTypeID == 1 &&
      auth &&
      auth.Source &&
      auth.Source.toLowerCase() != "matrix" &&
      auth &&
      auth.UserID
    ) {
      showErrMsg(
        "For GHI, manual ticket can only be created from Corporate Search panel"
      );
      return;
    }

    if (!createTicketFormValues.comment && (!Body ||Body==="")) {
      showErrMsg("Please enter the comment");
      return;
    }

    if (isCreateTicketInProgress) {
      return;
    }

    !createTicketFormValues.issue &&
      setCreateTicketFormErrors((prev) => ({ ...prev, issue: true }));
    !createTicketFormValues.subIssue &&
      setCreateTicketFormErrors((prev) => ({ ...prev, subIssue: true }));
    if (
      !createTicketFormValues.initiatedby &&
      [2].includes(isFetchDetail.leadDetailResForUI.ProductID)
    ) {
      setCreateTicketFormErrors((prev) => ({ ...prev, initiatedby: true }));
      return;
    }
    if (!createTicketFormValues.issue || !createTicketFormValues.subIssue) {
      return;
    }

    const {
      PolicyNo,
      ProductID,
      ProductName,
      SupplierID,
      SupplierName,
      EmailID,
      LeadID,
      RegistrationNo,
      ApplicationNo,
      CustomerName,
    } = isFetchDetail.leadDetailResForUI;
    const payload = {
      Name: employeeName
        ? employeeName
        : isFetchDetail &&
          isFetchDetail.leadDetailResForUI &&
          isFetchDetail.leadDetailResForUI.CustomerName,
      PolicyNumber: PolicyNo,
      ClaimNumber: null,
      Source: fetchDetailFormValues.Source,
      ProductID: ProductID,
      ProductName: ProductName,
      InsurerID: SupplierID,
      InsurerName: SupplierName || getSupplierName(),
      EmailID: email
        ? email
        : isFetchDetail &&
          isFetchDetail.leadDetailResForUI &&
          isFetchDetail.leadDetailResForUI.email,
      Comments:  Body ? Body : createTicketFormValues.comment,
      DialerUniqueID: null,
      Subject: null,
      LeadID: LeadID,
      CustomerID: 0,
      Issue: createTicketFormValues.issueId,
      IsCallBackRequest: 0,
      AutoClosure: 0,
      Attachments: Attachments ? 
        Attachments : (
        fileUpload.length > 0
          ? fileUpload.map((item) => ({
              FileName: item.FileName,
              AttachemntContent: item.AttachemntContent,
            }))
          : []),
      RefObjectId: null,
      FollowUpDate: null,
      AddressDetails: null,
      Pincode: 0,
      RegistrationNo: RegistrationNo,
      ApplicationNo: ApplicationNo,
      EndorsementID: null,
      IsAutoResolved: null,
      QueryTypeID: 0,
      IssueIn: 0,
      SubIssueIn: 0,
      SubIssueId: createTicketFormValues.subIssueId,
      SubSource: subSource ? subSource : ticketContext.sourceForCreateTicket,
      IsAttachment: Attachments ? true : fileUpload.length > 0 ? true : false,
      PrevRefObjectId: null,
      SubIssueIDList: null,
      IsMultipleEndorsement: false,
      InitiatedBy: createTicketFormValues.initiatedby,
    };
    setIsCreateTicketInProgress(true);
    POST(CreateTicketForCare, payload)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setTicketCreatedMsg(`Ticket ID: ${response.Data.TicketDetailsId}`);
          setDialogOpen(true);
          if (TicketDetailsID && RefObjectId) {
            getAllTicketSummary(RefObjectId, TicketDetailsID);

            if (OpenedThroughSource != "Email") {
              ticketContext.GetTicketCommApi(RefObjectId, TicketDetailsID);
            }
            ticketContext.GetTicketDetailsByTicketIdApi(
              RefObjectId,
              TicketDetailsID
            );
          }
          handleClose();
          ticketContext.GetTicketLogsApi();
          ticketContext.GetFilterCountApi();
        } else {
          showErrMsg("Something went wrong!");
        }
        setIsCreateTicketInProgress(false);
      })
      .catch((err) => {
        console.log("Error", err, err.response);
        setIsCreateTicketInProgress(false);
        showErrMsg(ErrorMessages[err.response.status]);
      });
  };

  const handleChange = (event, setValue) => {
    const { name, value } = event.target;
    if (name === "leadId") {
      setErrMessages({ ...errMessages, leadId: validateLeadId(value) });
      setIsShowLess(true);
      setIsFetchDetail({});
      setIssueOptions([]);
      setSubIssueOptions([]);
      setFileUpload([]);
      setCreateTicketFormErrors({
        issue: false,
        subIssue: false,
      });
      setCreateTicketFormValues({
        issue: "",
        issueId: "",
        subIssueId: "",
        subIssue: "",
        comment: "",
      });
    }
    setValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleRemoveFile = (id) => {
    const fileList = fileUpload.filter((item) => item.id !== id);
    setFileUpload(fileList);
  };

  const handleEndorsementClose = () => {
    setOpen(false);
    // window.location.reload();
  };

  const handleClose = () => {
    setCreateDialog(false);
    if (AccessTypeVal != 4) {
      setFileUpload([]);
      setfetchDetailFormValues({
        Source: "",
        leadId: "",
      });
      setCreateTicketFormValues({
        issue: "",
        issueId: "",
        subIssueId: "",
        subIssue: "",
        comment: "",
      });
      setIsShowLess(true);
      setfetchDetailFormErrors({
        Source: false,
        leadId: false,
      });
      setCreateTicketFormErrors({
        issue: false,
        subIssue: false,
      });
      setErrMessages({ leadId: "" });
      setIsFetchDetail({});
      setIssueOptions([]);
      setSubIssueOptions([]);
    }
  };

  useEffect(() => {
    if (ticketContext.LeadID && isCreateDialogOpen) {
      setfetchDetailFormValues((prevState) => {
        return {
          ...prevState,
          Source: "PB Manual",
          leadId: ticketContext.LeadID,
        };
      });
      handleFetchDetail("", ticketContext.LeadID);
    }
  }, [ticketContext.LeadID, isCreateDialogOpen]);

  const raiseEndorsementFunc = () => {
    setOpenIframe(true);
    setOpen(false);
  };

  const closeRaiseEndorsement = () => {
    setRaiseEndorsementURL(null);
    setOpenIframe(false);
    handleClose();
    window.location.reload();
  };

  let resetIssueSubIssue = () => setCreateTicketFormValues({
    issue : "",
    issueId : "", 
    subIssue : "",
    subIssueId : ""
  })


  const fetchAHCFromAPI = () => {
    let url = "/GetAHCDetails";
    let callback = (data, error) => {
      console.log("daa and error", data, error);
      if (data) {
        setAHCDialog({ show: true, url: data });
      } else showErrMsg(error);
    };
    fetchAHCUrl(url, isFetchDetail.leadDetailResForUI, auth, callback);
  };
  
  const OpenDispatchDialog = () => {
    let dispatchPayload = {
      "TicketId": "",
      "BookingId": isFetchDetail?.leadDetailResForUI?.LeadID,
      "DispatcherId": isFetchDetail?.leadDetailResForUI?.DispatcherId,
      "ProductId": isFetchDetail?.leadDetailResForUI?.ProductID
    }
    let cb = (url) => {
      if (url) {
        setDialogWithControls({
          open: true,
          title: 'Dispatch Request',
          handleClose: () => { setCustomDialogControl(); window.location.reload() },
          size: 80,
          children: <div className="iframe-box"><iframe src={url} referrerPolicy="no-referrer" /></div>
        })
      }
    }
    fetchDispatcherURL(dispatchPayload, cb)
  }

  useEffect(()=>{
    if(createTicketFormValues.issue && createTicketFormValues.subIssue && createTicketFormValues.issue.toLowerCase()===ahcIssue && createTicketFormValues.subIssue.toLowerCase()===ahcSubissue && AHC_SUPPLIERS.includes(FetchedTicketDetails.SupplierId)){
      setCustomDialogControl({
        title: " Raise AHC?",
        open: true,
        actionButtons: [
          {
            Name: "Cancel",
            className: "raise-cencel",
            onClick: () =>{
               setCustomDialogControl(customDialogInitialState);
               resetIssueSubIssue()
           },
          },
          {
            Name: "Raise AHC",
            className: "raise-btn",
            onClick: fetchAHCFromAPI,
          },
        ],
        handleClose: () => { 
          setCustomDialogControl(customDialogInitialState);
          resetIssueSubIssue()
         },
      });
    }
    else if(checkIssueSubIssueForDispatchRequest(isFetchDetail?.leadDetailResForUI?.ProductID, createTicketFormValues.issue, createTicketFormValues.subIssue)){
      setCustomDialogControl({
        title: "Raise Dispatch Request ?",
        open: true,
        actionButtons: [
          {
            Name: "Cancel",
            className: "raise-cencel",
            onClick: () => {setCustomDialogControl(customDialogInitialState); resetIssueSubIssue()},
          },
          {
            Name: "Raise Request",
            className: "raise-btn",
            onClick: OpenDispatchDialog,
          },
        ],
        handleClose: () => {setCustomDialogControl(customDialogInitialState);  resetIssueSubIssue()},
      });
    }
    else{
     setCustomDialogControl(customDialogInitialState) 
    }
  },[createTicketFormValues.issue, createTicketFormValues.subIssue])

  return (
    <div>
      <Dialog
        className="dialog-create-ticket"
        fullScreen
        open={isCreateDialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        {endorsementLoading && <CustomBackdrop open={endorsementLoading}/>}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="right"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Create New Ticket{" "}
              <span className="sourceticket">{OpenedThroughSource}</span>
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="all-comments">
          <div className="create-new-ticket">
            <Grid container spacing={1}>
              {isFetchDetail &&
                isFetchDetail.leadDetailResForUI &&
                isFetchDetail.leadDetailResForUI.ProductID == SME ? (
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        label="Employee Name"
                        id="outlined-margin-normal"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={
                          employeeName
                            ? employeeName
                            : isFetchDetail &&
                              isFetchDetail.leadDetailResForUI &&
                              isFetchDetail.leadDetailResForUI.CustomerName
                        }
                        //value={createTicketFormValues.employeeName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled="true"
                      />
                    </FormControl>
                  </Grid>
                ) : null}

              {isFetchDetail &&
                isFetchDetail.leadDetailResForUI &&
                isFetchDetail.leadDetailResForUI.ProductID ? (
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      {/* {console.log("email ....",email)} */}
                      <TextField
                        label="Email"
                        id="outlined-margin-normal"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={
                          email ||
                          (isFetchDetail &&
                            isFetchDetail.leadDetailResForUI &&
                            isFetchDetail.leadDetailResForUI.EmailID)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled="true"
                      />
                    </FormControl>
                  </Grid>
                ) : null}
              {AccessTypeVal != 4 ? (
                <Grid item md={12} xs={12} className="select-box">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={
                      !fetchDetailFormValues.Source &&
                      fetchDetailFormErrors.Source
                    }
                  >
                    <div className="souce-name">
                      <label>Source</label>
                      PB Manual
                    </div>
                    {/* <InputLabel htmlFor="outlined-age-native-simple">
                      SOURCE
                    </InputLabel>
                    <Select
                      value={fetchDetailFormValues.Source}
                      onChange={(e) =>
                        handleChange(e, setfetchDetailFormValues)
                      }
                      label="Source"
                      inputProps={{
                        name: "Source",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      {sourceList &&
                        sourceList.map((item) => (
                          <MenuItem value={item.SourceName}>
                            {item.SourceName}
                          </MenuItem>
                        ))}
                    </Select>
                    {!fetchDetailFormValues.Source &&
                      fetchDetailFormErrors.Source && (
                        <FormHelperText>Required</FormHelperText>
                      )} */}
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={
                    (!fetchDetailFormValues.leadId &&
                      fetchDetailFormErrors.leadId) ||
                    (errMessages && true)
                  }
                >
                  <TextField
                    disabled={AccessTypeVal == 4 ? true : false}
                    label="Enter Lead ID"
                    id="outlined-margin-normal"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    name="leadId"
                    value={fetchDetailFormValues.leadId}
                    onChange={(e) => handleChange(e, setfetchDetailFormValues)}
                  />
                  {errMessages.leadId && (
                    <FormHelperText>{errMessages.leadId}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {AccessTypeVal != 4 ? (
                <Grid item md={12} xs={12}>
                  <Button
                    className="fetch-details-btn"
                    onClick={(e) => {
                      handleFetchDetail(e);
                    }}
                  >
                    <span>Fetch Details</span>
                  </Button>
                </Grid>
              ) : null}
              {!isShowLess &&
                (fetchingDetail ? (
                  <div className="spinner-loader">
                    <Spinner />
                  </div>
                ) : (
                  <Grid
                    container
                    spacing={1}
                    onDragOver={handleDrag}
                    onDrop={(e) => handleDrop(e, setFileUpload, fileRef)}
                    onDragLeave={handleDragOut}
                    onDragEnter={handleDragIn}
                  >
                    <Grid item md={12} xs={12} className="select-box">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        error={
                          !createTicketFormValues.issue &&
                          createTicketFormErrors.issue
                        }
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Issue
                        </InputLabel>
                        <Select
                          value={createTicketFormValues.issue}
                          label="Issue"
                          inputProps={{
                            name: "issue",
                            id: "outlined-age-native-simple",
                          }}
                        >
                          {issueOptions.map((item) => {
                            return (
                              <MenuItem
                                value={item.IssueName}
                                onClick={() =>
                                  handleIssueSubIssue(
                                    item.IssueTypeId,
                                    item.IssueName,
                                    "issue"
                                  )
                                }
                              >
                                {" "}
                                {item.IssueName}{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {!createTicketFormValues.issue &&
                          createTicketFormErrors.issue && (
                            <FormHelperText>Required</FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} className="select-box">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        error={
                          !createTicketFormValues.subIssue &&
                          createTicketFormErrors.subIssue
                        }
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Sub Issue
                        </InputLabel>
                        <Select
                          value={createTicketFormValues.subIssue}
                          label="Sub Issue"
                          inputProps={{
                            name: "subIssue",
                            id: "outlined-age-native-simple",
                          }}
                        >
                          {subIssueOptions.map((item) => {
                            return (
                              <MenuItem
                                value={item.IssueName}
                                onClick={() =>
                                  handleIssueSubIssue(
                                    item.IssueTypeId,
                                    item.IssueName,
                                    'subIssue'
                                  )
                                }
                              >
                                {" "}
                                {item.IssueName}{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {!createTicketFormValues.subIssue &&
                          createTicketFormErrors.subIssue && (
                            <FormHelperText>Required</FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    {isFetchDetail &&
                      isFetchDetail.leadDetailResForUI &&
                      isFetchDetail.leadDetailResForUI.ProductID != SME && (
                        <Grid item md={12} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <TextField
                              label="customerName"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              value={
                                isFetchDetail &&
                                isFetchDetail.leadDetailResForUI &&
                                isFetchDetail.leadDetailResForUI.CustomerName
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled="true"
                            />
                          </FormControl>
                        </Grid>
                      )}
                    {isFetchDetail &&
                      isFetchDetail.leadDetailResForUI &&
                      isFetchDetail.leadDetailResForUI.ProductID != SME && (
                        <Grid item md={12} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <TextField
                              label="Email"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              value={
                                isFetchDetail &&
                                isFetchDetail.leadDetailResForUI &&
                                isFetchDetail.leadDetailResForUI.EmailID
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled="true"
                            />
                          </FormControl>
                        </Grid>
                      )}
                    <Grid item md={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          label="product"
                          id="outlined-margin-normal"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={
                            isFetchDetail &&
                            isFetchDetail.leadDetailResForUI &&
                            isFetchDetail.leadDetailResForUI.ProductName
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled="true"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          label="insurer"
                          id="outlined-margin-normal"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={
                            (isFetchDetail &&
                              isFetchDetail.leadDetailResForUI &&
                              isFetchDetail.leadDetailResForUI.SupplierName) ||
                            getSupplierName()
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled="true"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          label="Application No"
                          id="outlined-margin-normal"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={
                            isFetchDetail &&
                            isFetchDetail.leadDetailResForUI &&
                            isFetchDetail.leadDetailResForUI.ApplicationNo
                          }
                          disabled="true"
                        />
                      </FormControl>
                    </Grid>

                    {isFetchDetail.leadDetailResForUI &&
                      (isFetchDetail.leadDetailResForUI.ProductID == 117 ||
                        isFetchDetail.leadDetailResForUI.ProductID === 114) && (
                        <Grid item md={12} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <TextField
                              label="Registraion No"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              value={
                                isFetchDetail &&
                                isFetchDetail.leadDetailResForUI &&
                                isFetchDetail.leadDetailResForUI.RegistrationNo
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled="true"
                            />
                          </FormControl>
                        </Grid>
                      )}
                    {isFetchDetail.leadDetailResForUI &&
                      isFetchDetail.leadDetailResForUI.ProductID == 2 && (
                        <Grid item md={12} xs={12} className="select-box">
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            error={
                              !createTicketFormValues.initiatedby &&
                              createTicketFormErrors.initiatedby
                            }
                          >
                            <InputLabel htmlFor="outlined-age-native-simple">
                              Initiated By
                            </InputLabel>
                            <Select
                              value={createTicketFormValues.initiatedby}
                              label="Initiated By"
                              inputProps={{
                                name: "initiatedby",
                                id: "outlined-age-native-simple",
                              }}
                            >
                              {InitiatedByList.map((item) => {
                                return (
                                  <MenuItem
                                    value={item}
                                    onClick={() =>
                                      handleIssueSubIssue(
                                        0,
                                        item,
                                        "initiatedby"
                                      )
                                    }
                                  >
                                    {" "}
                                    {item}{" "}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {!createTicketFormValues.initiatedby &&
                              createTicketFormErrors.initiatedby && (
                                <FormHelperText>Required</FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                      )}
                    { !Body || Body==="" ?
                      <Grid item md={12} xs={12}>
                        <FormControl
                          variant="outlined"
                          s
                          className={classes.formControl}
                        >
                          <TextareaAutosize
                            placeholder="Enter Comments"
                            name="comment"
                            value={createTicketFormValues.comment}
                            onChange={(e) =>
                              handleChange(e, setCreateTicketFormValues)
                            }
                          />
                        </FormControl>
                      </Grid> :null
                    }
                    { !Body || Body==="" ?
                      <Grid item md={12} xs={12}>
                        <FileUploadComponent
                          setFiles={setFileUpload}
                          fileRef={fileRef}
                        />
                        <div className="uplaod-doc-view">
                          {fileUpload.map((item) => {
                            return (
                              <span className="file-detail" key={item.id}>
                                {item.FileName}{" "}
                                <button onClick={() => handleRemoveFile(item.id)}>
                                  <CloseIcon />
                                </button>
                              </span>
                            );
                          })}
                        </div>
                      </Grid>:null
                    }
                    <Grid item md={12} xs={12}>
                      <Button
                        className="submit-btn"
                        onClick={handleCreateTicket}
                        disabled={isCreateTicketInProgress}
                      >
                        {isCreateTicketInProgress ? (
                          <Spinner classaName="spinner-btn" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </Dialog>
      {isDialogOpen ? (
        <CopyTicketIdDialog
          isDialogOpen={isDialogOpen}
          setDialogOpen={setDialogOpen}
          Msg={TicketCreatedMsg}
          setTicketCreatedMsg={setTicketCreatedMsg}
        />
      ) : null}

      <Dialog
        onClose={handleEndorsementClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        className="raised-dialog"
      >
        {/* <Button className="close-icon" onClick={handleClose}>
          <CloseIcon />
        </Button> */}
        <div className="raise-ticket-pop">
          <h3>
            <img width="50px" src="/images/raised_ticket.png" />
            Raise new endorsement request?
          </h3>

          <Button className="raise-cencel" onClick={handleEndorsementClose}>
            Cancel
          </Button>
          <Button className="raise-btn" onClick={raiseEndorsementFunc}>
            Raise Endorsement
          </Button>
        </div>
      </Dialog>

      <Dialog
        className="dialog-create-endorsement"
        fullScreen
        open={openIframe}
        // onClose={() => {return leadId ? null : handleClose}}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="right"
              color="inherit"
              onClick={closeRaiseEndorsement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Raise New Endorsement
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="endorsement-dialog-popup">
          <iframe
            style={{ width: "100%", height: "100vh" }}
            // id="followUpCalender-calender"
            ref={iframeRef}
            // className="iframe-box"
            src={raiseEndorsementURL}
          ></iframe>
        </div>
      </Dialog>

      {/* ---------Custom Dialog ----------- */}
      <CustomRaiseRequestModal
          open={customDialogControl.open}
          title={customDialogControl.title}
          buttonWithFunction={customDialogControl.actionButtons}
          handleClose={customDialogControl.handleClose}
        />
      {/* ------------------------------------ */}
      {dialogWithControls && dialogWithControls.open &&
        <DialogFromRight
          open={dialogWithControls.open}
          handleClose={dialogWithControls.handleClose}
          title={dialogWithControls.title}
          children={dialogWithControls.children}
          size={dialogWithControls.size}
        />
      }
      {/* AHC Dialog */}
      <AHCDetailsDialog
        open={AHCDialog.show}
        close={() => {
          setAHCDialog({ show: false });
          window.location.reload();
        }}
        ref={iframeRef}
        url={AHCDialog.url}
      />
      {/* ============ */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: (state && state.auth && state.auth.userInfo) || {},
    FetchedTicketDetails:
      state && state.ticketdetails && state.ticketdetails.ticketDetails
        ? state.ticketdetails.ticketDetails
        : {},
  };
};
export default connect(mapStateToProps)(NativeSelects);
