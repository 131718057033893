import React, { useEffect, useState, useReducer } from 'react'
import { ListItem, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, Checkbox, ListItemText, TextField, List } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
import { POST } from '../../../../services/apiServices';
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductGroupMaster } from '../../../../redux/actions/common_action';
import { exportDashboardData_method, handleCatch } from '../../../../shared/methods/apiMethods'
import CustomBackdrop from '../../../Components/CustomBackdrop';
import { HashLoader } from 'react-spinners';
import { getProductListFromProductGroupMasterV2 } from '../../../../shared/methods/methods';
import { Toast } from '../../../../shared/components';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ADMIN_TAT_SUMMARY_DASHBOARD_TYPE_V2 } from '../../../../Config';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
  },
}))

const AdminTATSummaryV2 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productGroupMaster = useSelector(state => state && state.common && state.common.productGroupMaster);
  const zeroLeadSelection = useSelector(state => state.pbDashboard.zeroLeadSelected || false);
  const [productList, setProductList] = useState();
  const [productListToRender, setProductListToRender] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState(null)
  const [formValues, setFormValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ProductID: [],
      ProductGroupID: null,
      IsEsc: 0,
    }
  )

  const [formValuesTouched, setFormValuesTouched] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ProductID: false,
      IsEsc: false,
    }
  )

  useEffect(()=>{
    dispatch(fetchProductGroupMaster())
  },[])

  useEffect(() => {
    // if (productGroupMaster && productGroupMaster.length === 0) {
    //   dispatch(fetchProductGroupMaster())
    // }
    // else 
    if (productGroupMaster) {
      console.log('hello')
      setProductListsFromAPI();
    }
  }, [productGroupMaster]);

  const setProductListsFromAPI = () => {
    let arr = getProductListFromProductGroupMasterV2(productGroupMaster);
    setProductList(arr);
    setProductListToRender(arr);
  }

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormValues({ [name]: value });
    setFormValuesTouched({ [name]: true });
  }


  useEffect(() => {
    if (formValues.ProductID.length > 0) {
      if (formValues.ProductID && productList) {
        let productGroupID = null;
        productGroupID = productList.filter(item => item.ProductId === formValues.ProductID[0])[0]['ProductGroupId']
        let updatedArr = getProductListFromProductGroupMasterV2(productGroupMaster, productGroupID);
        setFormValues({ ProductGroupID: productGroupID })
        setProductListToRender(updatedArr)
      }
      else setProductListToRender(getProductListFromProductGroupMasterV2(productGroupMaster))
    }
    
    else setProductListToRender(getProductListFromProductGroupMasterV2(productGroupMaster))
  }, [formValues.ProductID])

  // useEffect(() => {
  //   setFormValues({ ProductID: [], ProductGroupID: null });
  // }, [zeroLeadSelection])

  const getDashboardData = (type, value, fileName) => {
    if (!value) { return Toast.error('No Data Available!') };
    setDataLoading(true);
    const payload = {
      "IsUpdatedOn": 0,
      "FilterString": "",
      "FromDate": 1635928641637,
      "ToDate": 1635928641637,
      "Source": "",
      "SubSource": "",
      "ProductID": 0,
      "SupplierID": 0,
      "CBRequest": 0,
      "StatusID": 0,
      "SubStatusID": 0,
      "TATFilter": 0,
      "InsurerStatusID": 0,
      "STP": -1,
      "IsTAT": 1,
      "IsEsc": formValues.IsEsc,
      "Type": type,
      "ProductIDs": formValues.ProductID.toString(),
      "SendToReport": "",
      "IsZeroLead": 0,
      "UserGroupID": '',
      "ProductGroupId": formValues.ProductGroupID || 0,
    }
    let url = '/GetDashboardDataExportV2';
    let callback = () => {
      setDataLoading(false)
    }
    exportDashboardData_method(url, payload, callback, fileName)
  }

  const handleLoadData = () => {
    setFormValuesTouched({
      ProductID: true,
      IsEsc: true
    })
    if (formValues.ProductID.length === 0) { return };

    let url = `GetDashboardCountV2`;
    let payload = {
      "ProductGroupId": formValues.ProductGroupID || 0,
      "ProductIds": (formValues.ProductID).toString(),
      "DashboardType": ADMIN_TAT_SUMMARY_DASHBOARD_TYPE_V2,
      "IsEsc": formValues.IsEsc || 0,
      "IsZeroLead": 0,
    }

    setDataLoading(true);
    POST(url, payload, 'Dashboard').then(res => {
      setDataLoading(false);
      if (res && res && res.ErrorCode === 0) {
        setDataResponse(res.Data)
      }
      else Toast.error(res.Message || 'Failed to fetch data')
    }).catch(err => {
      setDataLoading(false);
      handleCatch(err);
    })
  }


  useEffect(() => {
    setDataResponse(null)
  }, [zeroLeadSelection, formValues])

  return (
    <div className={classes.root}>
      {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white' /></CustomBackdrop> : null}
      <div className="pbdashboard-summary-new ">
        {/*list */}
        <div className='tat-summary-box  tat-summary-box-one'>
          <ul>
            {console.log(productListToRender)}
            <li>
              <FormControl className="form-control" error={formValuesTouched.ProductID && formValues.ProductID.length === 0}>
                {/* <InputLabel id="demo-customized-select-label">
                  Select Product
                </InputLabel> */}
                <Autocomplete
                  options={productListToRender}
                  fullWidth
                  className="single-select"
                  name="ProductID"
                  id="admin-tat-summary_v2"
                  disableCloseOnSelect
                  getOptionLabel={(item) => item.ProductName}
                  value={productListToRender.filter(item => formValues.ProductID.includes(item.ProductId))}
                  onChange={(e, newSelectedProducts) => setFormValues({ ProductID: newSelectedProducts.map(product => product.ProductId) })}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={formValues.ProductID.includes(option.ProductId) ? true : false}
                      />
                      {option.ProductName}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Products"
                    />
                  )}
                  multiple // Enable multi-select
                />
                {/* <Select
                  labelId="adminTATSummarySelectProduct"
                  value={formValues.ProductID}
                  multiple
                  onChange={handleChange}
                  renderValue={(selected) => selected && selected.length > 0 && selected.map(val => {
                    return productListToRender.filter(item => {
                      return item.ProductId === val
                    })[0].ProductName
                  }).join(',')
                  }
                  name="ProductID"
                >
                  {
                    productListToRender && productListToRender.map((item) => (
                      <MenuItem key={item.ProductId} value={item.ProductId}>

                        <Checkbox
                          checked={formValues.ProductID.indexOf(item.ProductId) > -1}
                        />
                        <ListItemText primary={item.ProductName} />
                      </MenuItem>
                    ))}=
                </Select> */}
                {formValuesTouched.ProductID && formValues.ProductID.length === 0 ? <FormHelperText className='error-msg'>Please select product(s)</FormHelperText> :
                  formValues.ProductID && formValues.ProductID.length > 0 ? <FormHelperText className='info-select-product'>{formValues.ProductID.length} Selected</FormHelperText> : ''}
              </FormControl>
            </li>
            <li className='escalation-list'>
              <FormControl className="form-control">
                <InputLabel id="demo-customized-select-label">
                  Select Escalation
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select tatSummarySelectEscalation"
                  onChange={handleChange}
                  name="IsEsc"
                >
                  <MenuItem value={"0"}>Select</MenuItem>
                  <MenuItem value={"1"}>ALL</MenuItem>

                </Select>
              </FormControl>
            </li>
            <li>
              <Button id="tatSummaryExportBtn" className="export-btn" onClick={handleLoadData}>Load Data</Button>
            </li>
          </ul>
        </div>

        {dataResponse && dataResponse.length > 0 ? <div className="ticket-summary-list">
          <List>
            {dataResponse.filter(item => item.IsAvailable).map(item2 => {
              return (
                <ListItem id="summaryNewTicket" onClick={() => getDashboardData(item2.BucketName, item2.Count, item2.DisplayName)}>
                  <div>
                    <span style={{ color: item2.Colour }}>{item2.DisplayName}</span>
                    <p>{item2.Count}</p>
                  </div>
                </ListItem>
              )
            })}
          </List>
        </div> : dataResponse && dataResponse.length === 0 ? <div>No Data found</div> : null}
      </div>
    </div>
  )
}

export default AdminTATSummaryV2
