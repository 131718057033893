import React from 'react' 
import CachedIcon from '@material-ui/icons/Cached';
import ErrorIcon from '@material-ui/icons/Error';
import { VERSION } from './Config';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { STORAGE } from './shared/methods/methods';
import { userLogout } from './redux/actions/auth';

class ParentErrorBoundary extends React.Component {
    state = {
      hasError: false,
      error: { message: '', stack: '' },
      info: { componentStack: '' }
    };
    
    static getDerivedStateFromError = error => {
      return { hasError: true };
    };
  
    componentDidCatch = (error, info) => {
      this.setState({ error, info });
      console.log('infos',info)
    };

    render() {
      const { hasError, error, info } = this.state;
      const { children, logoutUser_store } = this.props;

      const cleanStorage = () => {
        sessionStorage.clear();
        logoutUser_store();
        STORAGE.deleteAuth().then(() => {
          localStorage.clear();
          window.location.reload();
        })
      }
  
      return hasError ? 
      <div className="error-boundary-msg">
        <div className="card my-5">
        <div className="card-header">
            <p>
              <img src="/images/error_boundary_img.png" alt="error_boundary"/>
            </p>
            <p>
            <ErrorIcon/>There was an error in loading this page.{' '}
            <span
                style={{ cursor: 'pointer', color: '#0077FF' }}
                onClick={() => {
                window.location.reload();
                }}
            >
                Reload this page <CachedIcon/>
            </span>{' '}
            </p>
        </div>
        <div className="card-body">
            <span style={{fontSize: '16px'}}>Version : {VERSION}</span>
            <div style={{fontSize: '12px'}}><b>NOTE</b> : If issue persist with latest version click the below button. You will need to log in again.</div>
            <div><Button variant="outlined" color="primary" onClick={cleanStorage}>Clean</Button></div>
            <details className="error-details">
            <summary style={{cursor:'pointer',textDecoration:'underline'}}>Click for error details</summary>
            <p style={{fontSize:'14px', marginTop:'33px', marginBottom:'33px'}}>{info && info.componentStack}</p>
            </details>
        </div>
        </div></div>: children;
    }
  }

  const mapDispatchToProps = (dispatch) =>{
    return{
      logoutUser_store : () => dispatch(userLogout()),
    }
  }
 
  export default connect(null, mapDispatchToProps)(ParentErrorBoundary)