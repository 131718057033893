import * as type from "../types/types";

const initialState = {
  ticketDetails: null,
  ticketComm: null,
  templateList: null,
  insurerTemplateList: null,
  isTicketLoading: null,
  selectedTicketID: null,
};

export default function ticketDetails(state = initialState, action) {
  switch (action.type) {
    case type.SET_TICKET_DETAILS:
      return {
        ...state,
        ticketDetails: action.payload,
      };
    case type.SET_TICKET_COMM_DATA:
      return {
        ...state,
        ticketComm: action.payload,
      };
    case type.SET_TEMPLATE_LIST:
      return {
        ...state,
        templateList: action.payload
      }
    case type.SET_TICKET_LOADING:
      return {
        ...state,
        isTicketLoading: action.payload
      }
    case type.SET_INSURER_TEMPLATE_LIST:
      return {
        ...state,
        insurerTemplateList: action.payload
      }
    case type.SET_ACTIVE_TICKET_ID:
      return {
        ...state,
        selectedTicketID: action.payload
      }
    default:
      return state;
  }
}