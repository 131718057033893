const Constants = {
    400: "Bad Request",
    401: "Unauthorized",
    402: "Payment Required",
    404: "Network Error",
    405: "Method Not Allowed",
    500: "Internal Server Error",
    URL_ERROR : "No URL Found"
}

export default Constants