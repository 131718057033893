import React, { useState, useContext, useEffect } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
    convertToIsoFormateLogActivity,
    MaskEmailID,
} from "../../../../shared/methods/methods";
import {
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ListItem,
    List,
    Grid,
} from "@material-ui/core";

import ReplyMailContent from "../TicketsTimelines/Components/ReaplyMailContent";
import Spinner from "../../../../shared/components/Spinner";
import {
    TICKET_SOURCE_CARE,
    MY_ACCOUNT,
} from "../../../../Config";
import MailContent from "../TicketsTimelines/Components/MailContent";
import AttachementList from "../TicketsTimelines/Components/AttachementList";
import TicketContext from "../../../../TicketContext";
import DownloadMail from "../TicketsTimelines/Components/DownloadMail";
import { useDispatch, useSelector } from "react-redux";
import ChildErrorBoundary from '../../../../shared/components/ChildErrorBoundary';
import Tooltip from '@material-ui/core/Tooltip';
import ComposeEmail from "./ComposeEmail";
import { GET } from "../../../../services/apiServices";
import { Toast } from "../../../../shared/components";
import { handleCatch } from "../../../../shared/methods/apiMethods";
import { fetchBMSInsurerTemplateList } from "../../../../redux/actions/ticketDetails";

const useStyles = makeStyles((theme) => ({
    chatHistoryView: {
        position: "relative",
        bottom: "10%",
        right: "2%",
        zIndex: "999",
    },
}));

export default function InsurerActivity({
    templateList,
    oldTicketDetails,
    AccessTypeVal,
    viewtype,
    isValidForSME,
    goToTemplate,
    setGoToTemplate,
}) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const ticketDetails_store = useSelector(state => state.ticketdetails.ticketDetails);
    const templateListInsurer_store = useSelector(state => state && state.ticketdetails && state.ticketdetails.insurerTemplateList)
    const [loadingMails, setLoadingMails] = useState(true);
    const [openEmailComposeWindow, setOpenEmailComposeWindow] = useState(false);
    const [mailArray, setMailArray] = useState(null)

    //reads from Store if the user is ReadOnlyUser
    const IsReadOnlyUser = useSelector((state) => state.ticketdetails.ticketDetails.IsReadOnlyUser || false);

    const ticketContext = useContext(TicketContext);

    useEffect(() => {
        if (ticketDetails_store && ticketDetails_store.TicketId && ticketDetails_store.RefObjectId) {
            fetchTicketingInsurerMails()
        }
        if(!templateListInsurer_store){
            dispatch(fetchBMSInsurerTemplateList(ticketDetails_store))
        }
    }, [ticketDetails_store])

    const fetchTicketingInsurerMails = () => {
        let endpoint = `GetInsurerEmailComm/${ticketDetails_store?.RefObjectId}/${ticketDetails_store?.TicketId}`
        GET(endpoint).then(res => {
            setLoadingMails(false);
            if (res && res.ErrorCode === 0) {
                setMailArray(res.Data)
            }
            else {
                Toast.error(res.Message || 'No Mails Found')
            }
        }).catch(err => {
            setLoadingMails(false);
            handleCatch(err);
        })
    }

    const FilteredEmailList = (emails) => {
        const data = [];
        emails.forEach((email) => {
            if (email) {
                data.push(email);
            }
        });
        return data;
    };

    const isDownloadMailVisible = (source = "", eventType = "") => {
        if (
            (source && source.toLowerCase().includes(TICKET_SOURCE_CARE)) ||
            (eventType && eventType.toLowerCase().includes(TICKET_SOURCE_CARE))
        ) {
            return true;
        } else if (
            (source && source.toLowerCase().includes(MY_ACCOUNT)) ||
            (eventType && eventType.toLowerCase().includes(MY_ACCOUNT))
        ) {
            return true;
        } else {
            return false;
        }
    };

    const renderMails = () => {
        return (
            <span className="email-chain">
                {loadingMails ? <Spinner /> :
                    mailArray && Array.isArray(mailArray) ? (
                        mailArray.length > 0 ?
                            mailArray.map((item, index) => {
                                return (
                                    <List key={item?.TicketSource+index}>
                                        <ListItem
                                            className={
                                                "email-customer" +
                                                (index === 0 &&
                                                    item.TicketSource &&
                                                    item.TicketSource.toLowerCase() !== "pbreply"
                                                    ? " pb-reply"
                                                    : "")
                                            }
                                        >
                                            <span className="email-tag email">
                                                {item.TicketSource}{" "}
                                                {item.TicketSubSource ? " - " + item.TicketSubSource : ""}
                                            </span>

                                            <ExpansionPanel defaultExpanded={true}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon className="arrow" />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item md={12} xs={12}>
                                                            <span className="user-iocn"><i className="fa fa-user-circle"></i></span>
                                                            <Tooltip title={item.EventType ? item.EventType : item.TicketSource} placement="top-start">
                                                                <p className="heading">
                                                                    {ticketContext.activeTicketId != item.TicketId
                                                                        ? "(C)"
                                                                        : ""}
                                                                    {item.EventType ? item.EventType : item.TicketSource}{" "}
                                                                    {isDownloadMailVisible(
                                                                        item.TicketSource,
                                                                        item.EventType
                                                                    ) ? (
                                                                        <DownloadMail
                                                                            MailRefObjId={item.ObjectID}
                                                                            IsReadOnlyUser={IsReadOnlyUser}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </p>
                                                            </Tooltip>

                                                            <div className="mail-timing">
                                                                <span className={classes.chatHistoryView}></span>
                                                                {item.callRecording && (
                                                                    <a
                                                                        href={item.callRecording}
                                                                        className="recording-box"
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <img src="/images/play_circle.svg" />
                                                                    </a>
                                                                )}
                                                                <span>
                                                                    {convertToIsoFormateLogActivity(item.ReceivedOn)}
                                                                </span>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails style={{ display: "block" }}>
                                                    <Grid container className="mail-heading">
                                                        <Grid item md={12} xs={12}>
                                                            <Grid container>
                                                                <Grid md={9} xs={7}>
                                                                    <p>{item.Subject}</p>
                                                                </Grid>
                                                            </Grid>
                                                            {item.From
                                                                && (
                                                                    <p>
                                                                        <span className="from-to">
                                                                            From: {item.DisplaySenderName} ({MaskEmailID(item.From, IsReadOnlyUser)})
                                                                        </span>
                                                                    </p>
                                                                )}
                                                            {item.ToReceipent &&
                                                                Array.isArray(item.ToReceipent) &&
                                                                FilteredEmailList(item.ToReceipent).length > 0 && (
                                                                    <span className="from-to">
                                                                        To:{" "}
                                                                        {FilteredEmailList(item.ToReceipent).map(item => MaskEmailID(item, IsReadOnlyUser)).join(",")}
                                                                    </span>
                                                                )}
                                                            {item.CCEmail &&
                                                                Array.isArray(item.CCEmail) &&
                                                                FilteredEmailList(item.CCEmail).length > 0 && (
                                                                    <p>
                                                                        <span className="from-to">
                                                                            CC:{" "}
                                                                            {FilteredEmailList(item.CCEmail).map(item => MaskEmailID(item, IsReadOnlyUser)).join(",")}
                                                                        </span>
                                                                    </p>
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                    <AttachementList
                                                        attachments={item.MailAttachments}
                                                        isActionsAllowed={true}
                                                        IsReadOnlyUser={IsReadOnlyUser}
                                                    />
                                                    <MailContent
                                                        ind={index}
                                                        Body={item.Body}
                                                        EventType={item.EventType}
                                                        TicketSource={item.TicketSource}
                                                    />
                                                    <ChildErrorBoundary>
                                                        <ReplyMailContent
                                                            goToTemplate={goToTemplate}
                                                            setGoToTemplate={setGoToTemplate}
                                                            ticketData={item}
                                                            templateList={templateList}
                                                            oldTicketDetails={oldTicketDetails}
                                                            AccessTypeVal={AccessTypeVal}
                                                            viewtype={viewtype}
                                                            isValidForSME={isValidForSME}
                                                            index={index}
                                                            openedThroughSource="BMSInsurerActivity"
                                                        />
                                                    </ChildErrorBoundary>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </ListItem>
                                    </List>
                                );
                            })
                            : 'No Mails Found'
                    )
                        : null}
            </span>
        );
    };



    return (
        <div className="email-chain scrool-toll">
            {openEmailComposeWindow ? <Button className="compose-close-btn" onClick={() => setOpenEmailComposeWindow(false)}> Close</Button> : <Button  className="compose-btn" variant="outline" primary onClick={() => setOpenEmailComposeWindow(true)}>Compose</Button>}
            {
                openEmailComposeWindow && <ChildErrorBoundary>
                    <ComposeEmail 
                    handleCloseCompose={() => setOpenEmailComposeWindow(false)}
                    fetchLatestInsurerMails={fetchTicketingInsurerMails}/>
                </ChildErrorBoundary>
            }
            {renderMails()}
        </div>
    );
}
