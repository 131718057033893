import React, { useRef } from "react";
import {
  List,
  FormControlLabel,
  Checkbox,
  Button,
  ListItem,
} from "@material-ui/core";
import ViewComments from "./ViewComments";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useSelector } from "react-redux";

export default function TicketActivity({
  showLogs,
  setShowLogs,
  filterCounts,
  oldTicketDetails,
  templateList,
  toggleFilterBtn,
  handleFilter,
  setRefresh,
  viewtype,
}) {
  const ActivityFiletrContainerRef = useRef(null);

  const IsReadOnlyUser = useSelector(
    (state) =>
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser
  );

  const handleChange = () => {
    setShowLogs(!showLogs);
  };

  const moveLeft = () => {
    if (
      ActivityFiletrContainerRef.current &&
      ActivityFiletrContainerRef.current.scrollLeft
    ) {
      ActivityFiletrContainerRef.current.scrollLeft -= 70;
    }
  };

  const moveRight = () => {
    if (ActivityFiletrContainerRef.current) {
      ActivityFiletrContainerRef.current.scrollLeft += 70;
    }
  };

  return (
    (viewtype == 0 && (
      <div className="activity-box">
        <div className="activity-filter">
          {
            <span className="activity-checkbox  show-log-icon">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showLogs}
                    onChange={handleChange}
                    name="checkboxShowLog"
                    color="primary"
                    id="showLogsForMails"
                  />
                }
                label="Show Logs"
              />
            </span>
          }
          <span className="activity-comments">
            <ViewComments
              oldTicketDetails={oldTicketDetails}
              templateList={templateList}
              setRefresh={setRefresh}
              viewtype={viewtype}
              IsReadOnlyUser={IsReadOnlyUser}
            />
          </span>
          <Button className="left-scroll-btn" onClick={moveLeft}>
            <ChevronLeftIcon />
          </Button>
          <List className="scrool-toll" ref={ActivityFiletrContainerRef}>
            <ListItem>
              <Button
                className={toggleFilterBtn.all ? "active" : null}
                onClick={() => handleFilter("all")}
                id="toggleFilterLogsBtn_All"
              >
                All
              </Button>
            </ListItem>
            <ListItem>
              <Button
                className={toggleFilterBtn.email ? "active" : null}
                onClick={() => handleFilter("email")}
                id="toggleFilterLogsBtn_Email" 
              >
                Email <em>{filterCounts.CustEmailCount}</em>
              </Button>
            </ListItem>

            <ListItem>
              <Button
                className={toggleFilterBtn.esclation ? "active" : null}
                onClick={() => handleFilter("esclation")}
                id="toggleFilterLogsBtn_Escalation" 
              >
                Escalation <em>{filterCounts.EscalationCount}</em>
              </Button>
            </ListItem>

            <ListItem>
              <Button
                className={toggleFilterBtn.customerReply ? "active" : null}
                onClick={() => handleFilter("customerReply")}
                id="toggleFilterLogsBtn_CustomerFollowUp" 
              >
                Customer Follow Up <em>{filterCounts.CustomerReplyCount}</em>
              </Button>
            </ListItem>
            <ListItem>
              <Button
                className={toggleFilterBtn.attachments ? "active" : null}
                onClick={() => handleFilter("attachments")}
                id="toggleFilterLogsBtn_Attachments" 
              >
                Attachments <em>{filterCounts.CustAttachmentCount}</em>
              </Button>
            </ListItem>
            <ListItem>
              <Button
                className={toggleFilterBtn.pbReply ? "active" : null}
                onClick={() => handleFilter("pbReply")}
                id="toggleFilterLogsBtn_PBReply" 
              >
                PB Reply <em>{filterCounts.PBReplyCount}</em>
              </Button>
            </ListItem>

            <ListItem>
              <Button
                className={toggleFilterBtn.IB ? "active" : null}
                onClick={() => handleFilter("IB")}
                id="toggleFilterLogsBtn_Inbound" 
              >
                Inbound <em>{filterCounts.IBCount}</em>
              </Button>
            </ListItem>

            <ListItem>
              <Button
                className={toggleFilterBtn.C2C ? "active" : null}
                onClick={() => handleFilter("C2C")}
                id="toggleFilterLogsBtn_C2C" 
              >
                C2C <em>{filterCounts.C2CCount}</em>
              </Button>
            </ListItem>
          </List>
          <Button className="right-scroll-btn" id="toggleFilterLogsBtn_MoveRight"  onClick={moveRight}>
            <ChevronRightIcon />
          </Button>
        </div>
      </div>
    )) 
    // ||
    // (viewtype == 1 && (
    //   <div className="activity-box">
    //     <div className="activity-filter">
    //       <span className="activity-comments">
    //         <ViewComments
    //           oldTicketDetails={oldTicketDetails}
    //           templateList={templateList}
    //           setRefresh={setRefresh}
    //           viewtypeComment={0}
    //         />
    //       </span>
    //     </div>
    //   </div>
    // ))
  );
}
