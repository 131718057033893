import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { GET, POST } from "../../services/apiServices";
import { GetPolicyLink } from "../../constants/apiUrls";
import {
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  TableFooter,
  TablePagination,
  TableRow,
  IconButton,
} from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import Sidebar from "./Components/Sidebar";
import UserProfile from "./Components/UserProfile";
import Spinner from "../../shared/components/Spinner";
import { useSnackbar, withSnackbar } from "notistack";
import { downloadPolicyCopy } from "../../redux/actions/ticketDetails";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function PolicyLink(props) {
  const { downloadPolicyCopy_ReduxFunction } = props 
  const classes = useStyles();
  const [isDataLoading, setDataLoading] = useState(false);
  const [insurerStatus, setInsurerStatus] = React.useState({
    applicationno: null,
    policyno: null,
    registrationNo: null,
  });
  var [data, setdata] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showNoData, setShowNoData] = useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    setInsurerStatus((insurerStatus) => ({
      ...insurerStatus,
      [event.target.name]: event.target.value,
    }));
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const showMessage =(message, varient) =>{
    return enqueueSnackbar(message, {variant: varient, onClick: () => { closeSnackbar()}});
  }

  const handleSubmit = (event) => {
    if (!insurerStatus.applicationno && !insurerStatus.policyno &&  !insurerStatus.registrationNo) {
      showMessage("Any 1 value is required","error");
    }
    if (event && event.persist) {
      event.persist();
    }
    setDataLoading(true);
    const payload = {
      ApplicationNo: insurerStatus.applicationno,
      PolicyNo: insurerStatus.policyno,
      PlanType:insurerStatus.planType,
      RegNo:insurerStatus.registrationNo,  
    };
    POST(GetPolicyLink, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          if (response?.Data?.length) {
            setdata(response.Data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        }
        else showMessage(response?.Message || "Failed", "error");
      })
      .catch((err) => console.log(err));
    setDataLoading(false);
  };
  const downloadPolicyBtn =(DocId) =>{
    let cb=(varient, msg)=>{
         enqueueSnackbar(msg, {
              variant: varient,
              onClick: () => {
                closeSnackbar()
              },
            })
    }
    downloadPolicyCopy_ReduxFunction(DocId, cb)
  }

  return (
    <div className="insurer-dashbaord">
      <div className="dashboard">
        {/* {console.log('whole state--->', insurerStatus)} */}
        <ul>
          <li className="rightbar">
            <div className="insurer-admin-dash">
              {/* <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <h2>Policy Link</h2>
                </Grid>
              </Grid> */}
              <div className="search-option-list policy-link-view">
                <ul>
                  <li>
                    <FormControl className="form-control">
                      <TextField
                        label="Application No"
                        id="outlined-margin-normal"
                        margin="normal"
                        name="applicationno"
                        onChange={handleChange}
                        onKeyPress={handleKeypress}
                      />
                    </FormControl>
                  </li>
                  <li>
                    <FormControl className="form-control">
                      <TextField
                        label="Policy No"
                        id="outlined-margin-normal"
                        margin="normal"
                        name="policyno"
                        onChange={handleChange}
                        onKeyPress={handleKeypress}
                      />
                    </FormControl>
                  </li>
                  <li>
                    <FormControl className="form-control">
                      <TextField
                        label="Registration No"
                        id="outlined-margin-normal"
                        margin="normal"
                        name="registrationNo"
                        onChange={handleChange}
                        onKeyPress={handleKeypress}
                      />
                    </FormControl>
                  </li>
                  <li>
                    <Button
                      type="submit"
                      className="search-btn"
                      onClick={(event) => handleSubmit(event)}
                    >
                      Get Policy Link
                    </Button>
                  </li>
                </ul>
              </div>
              {isDataLoading ? (
                <div className="spinner-loader">
                  <Spinner />
                </div>
              ) : (
                <Grid item md={12} xs={12}>
                  {!showNoData && data && data.length > 0 ? (
                    <TableContainer component={Paper} className="table-view">
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell align="left">Policy No</TableCell>
                            <TableCell align="left">Application No</TableCell>
                            {/* <TableCell align="left">RegistrationNo</TableCell> */}
                            <TableCell align="left">Lead ID</TableCell>
                            <TableCell align="left">Policy Link</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? data.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : data
                          ).map((row, i) => (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">{row.PolicyNo}</TableCell>
                              <TableCell align="left">
                                {row.ApplicationNo}
                              </TableCell>
                              {/* <TableCell align="left">{row.registrationNo}</TableCell> */}
                              <TableCell align="left">{row.LeadID}</TableCell>
                              <TableCell align="left">
                                {row.DocUploadId ? 
                                <span  
                                  onClick={()=>downloadPolicyBtn(row.DocUploadId)} 
                                  style={{color:'blue', style:'underline', cursor:'pointer'}}>Download</span>
                                : <span>-</span>}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            {/* <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, { label: 'All', value: -1 }]}
              colSpan={3}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            /> */}
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  ) : (
                    showNoData && (
                      <div className="no-data-found">
                        <img
                          src="images/no_data_found.svg"
                          alt="no_data_found.svg"
                        />
                        <p>No data found</p>
                      </div>
                    )
                  )}
                </Grid>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

const mapDispatchToProps =(dispatch)=>{
  return{
    downloadPolicyCopy_ReduxFunction : (payload, cb) => dispatch(downloadPolicyCopy(payload,cb))
  }
}

export default withSnackbar(connect(null, mapDispatchToProps)(PolicyLink))


