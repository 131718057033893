import React from 'react';
import { useSelector } from 'react-redux';
import { PB_ADMIN } from '../Config';
import ChildErrorBoundary from '../shared/components/ChildErrorBoundary';
import PBAdminDashboard from './DashboardPB/Admin/PBAdminDashboard';
import PBAgentDashboard from './DashboardPB/Agent/PBAgentDashboard';

export default function PBDashboard() {
    const AccessType_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.AccessType)
    return (
        AccessType_store === PB_ADMIN ? <ChildErrorBoundary>
            <PBAdminDashboard/>
        </ChildErrorBoundary>
            : <ChildErrorBoundary>
                <PBAgentDashboard />
            </ChildErrorBoundary>
    )
}
