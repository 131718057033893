// Topbar Create Ticket Button  adjacent to More Options
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useReducer,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import { GET, POST } from '../../../services/apiServices'
import {
  CreateTicketForWeCare,
  GetTicketSummary,
} from '../../../constants/apiUrls'
import {
  InputLabel,
  Select,
  Grid,
  FormControl,
  Button,
  TextareaAutosize,
  FormHelperText,
  TextField,
  MenuItem,
  Slide,
  Typography,
  Dialog,
} from '@material-ui/core'
import {
  handleDrag,
  handleDragOut,
  handleDragIn,
  handleDrop,
  validatePolicyNumber,
} from '../../../shared/methods/methods'
import Spinner from '../../../shared/components/Spinner'
import CloseIcon from '@material-ui/icons/Close'
import TicketContext from '../../../TicketContext'
import CopyTicketIdDialog from '../../../shared/components/CopyTicketIdDialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import FileUploadComponent from '../../../shared/components/FileUploadComponent'
import { useSelector } from 'react-redux'
import {
  SME,
  UsersAllowedForTicketCreation,
  ahcIssue,
  ahcSubissue,
  AHC_SUPPLIERS,
} from '../../../Config'
import { connect } from 'react-redux'
import ErrorMessages from '../../../shared/constants'
import CustomRaiseRequestModal from '../../Dialog/CustomRaiseRequestModal'

import { fetchAHCUrl } from '../../../shared/methods/apiMethods'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {},
  appBar: {
    position: 'relative',
    backgroundColor: '#ffffff !important',
    color: '#414141',
  },
  title: {
    flex: 1,
    fontSize: '16px',
  },
  formControl: {
    minWidth: '100%',
  },
  h6: {
    color: '#414141',
    fontSize: '14px',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const NativeSelects = ({
  OpenedThroughSource,
  sourceList,
  setCreateDialog,
  TicketDetailsID,
  RefObjectId,
  openTicketCreation,
  closeTicketCreation,
  supplierList = [],
  AccessTypeVal,
  leadId,
  email,
  //PolicyNumber,
  employeeName,
  FetchedTicketDetails, // fetching ticketDetails from redux
  InsurerID_store,
  ProductID_store,
  EmailID_store,
  UserID_store,
}) => {
  const classes = useStyles()
  const iframeRef = useRef(null)

  const ticketContext = useContext(TicketContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isShowLess, setIsShowLess] = useState(true)
  const fileRef = useRef('')
  const [fetchDetailFormValues, setfetchDetailFormValues] = useState({
    Source: 'PB Insurer',
    // LeadID: '',
    PolicyNo: '',
  })

  //Code for Custom/ Common Dialog--
  const customDialogInitialState = {
    title: false,
    actionButtons: [],
    open: false,
    handleClose: null,
  }
  const [customDialogControl, setCustomDialogControl] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    customDialogInitialState,
  )
  //------------------------------------

  //For AHC dialog control
  const [AHCDialog, setAHCDialog] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      show: false,
      url: null,
    },
  )

  const [createTicketFormValues, setCreateTicketFormValues] = useState({
    issue: '',
    issueId: '',
    subIssueId: '',
    subIssue: '',
    initiatedby: '',
    comment: '',
  })

  const [isCreateTicketInProgress, setIsCreateTicketInProgress] = useState(
    false,
  )

  const [fetchDetailFormErrors, setfetchDetailFormErrors] = useState({
    Source: false,
    leadId: false,
  })

  const [createTicketFormErrors, setCreateTicketFormErrors] = useState({
    issue: false,
    subIssue: false,
    initiatedby: false,
  })
  const [errMessages, setErrMessages] = useState({ PolicyNo: '' })
  const [isFetchDetail, setIsFetchDetail] = useState({})
  const [fetchingDetail, setFetchingDetail] = useState(false)
  const [issueOptions, setIssueOptions] = useState([])
  const [subIssueOptions, setSubIssueOptions] = useState([])
  const [fileUpload, setFileUpload] = useState([])
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [TicketCreatedMsg, setTicketCreatedMsg] = useState('')
  const [open, setOpen] = useState(false)
  const auth = useSelector((state) => state.auth.userInfo)

  const InitiatedByList = ['Customer', 'Insurer']

  const validateForm = (values, setErr) => {
    let errObj = {}
    Object.keys(values).forEach((field) => {
      !values[field] && (errObj[field] = true)
      if (!fetchDetailFormValues.leadId) {
        setErrMessages({
          leadId: validatePolicyNumber(fetchDetailFormValues.leadId),
        })
      }
    })

    setErr(errObj)
  }

  const handleIssueSubIssue = (id, val, name = 'subIssue') => {
    if (name === 'issue') {
      setCreateTicketFormValues((prev) => ({
        ...prev,
        issueId: id,
        issue: val,
      }))
    } else if (name === 'initiatedby') {
      setCreateTicketFormValues((prev) => ({
        ...prev,
        initiatedby: val,
      }))
    } else {
      setCreateTicketFormValues((prev) => ({
        ...prev,
        subIssueId: id,
        subIssue: val,
      }))
    }
  }

  const GetIssueSubIssueAndLeadDetailsForWeCare = () => {
    setFetchingDetail(true)
    const payload = {
      UserId : UserID_store,
      SearchValue : fetchDetailFormValues.PolicyNo
    };
    POST(
      'GetIssueSubIssueAndLeadDetailsForWeCare', payload
    )    
    .then((response) => {
        if (response.ErrorCode === 0 && response.Data) {
          setIsFetchDetail(response.Data)
          const issueList = response.Data.issueTypeMasters.filter(
            (item) => item.ParentID === 0,
          )
          setIssueOptions(issueList)
        } else {
          let errmsg = response.Message ? response.Message : 'Something went wrong!';
          enqueueSnackbar(errmsg, {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
        }
        setFetchingDetail(false)
      })
      .catch((err) => {
        setFetchingDetail(false)
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          onClick: () => {
            closeSnackbar()
          },
        })
      })
  }

  const getAllTicketSummary = (RefObjectId, TicketDetailsID) => {
    ticketContext.setAllTicketsLoading(true)
    GET(`${GetTicketSummary}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter(
                (i) => i.StatusID != 3 && i.StatusID != 4,
              ).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn),
              )
            : []
          const sortedData2 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 3).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn),
              )
            : []
          const sortedData3 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 4).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn),
              )
            : []
          ticketContext.setAllTickets({
            ...response.Data,
            AllTicketList: [...sortedData1, ...sortedData2, ...sortedData3],
          })
          ticketContext.setActiveTicketId(response.Data.TicketId)
        } else {
          // console.log("hello2");
          enqueueSnackbar('Something went wrong!', {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
        }
        ticketContext.setAllTicketsLoading(false)
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false)
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          onClick: () => {
            closeSnackbar()
          },
        })
      })
  }

  useEffect(() => {
    const subIssueOptionsList = []
    if (createTicketFormValues.issue) {
      isFetchDetail.issueTypeMasters.map((item) => {
        if (item.ParentID === createTicketFormValues.issueId) {
          subIssueOptionsList.push(item)
        }
      })
      setSubIssueOptions(subIssueOptionsList)
    }
  }, [createTicketFormValues.issue])

  const handleFetchDetail = (PolicyNo = '') => {
    console.log(
      'hello this is my first page',
     fetchDetailFormValues.PolicyNo,
    )
    if (!PolicyNo) {
      validateForm(fetchDetailFormValues, setfetchDetailFormErrors)
      if (
        !fetchDetailFormValues.Source ||
        !fetchDetailFormValues.PolicyNo ||
        errMessages.PolicyNo
      ) {
        return
      }
    }
    GetIssueSubIssueAndLeadDetailsForWeCare(PolicyNo)
    setIsShowLess(false)
  }

  const showErrMsg = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'error',
      onClick: () => {
        closeSnackbar()
      },
    })
  }

  const getSupplierName = () => {
    if (
      isFetchDetail &&
      isFetchDetail.leadDetailResForUI &&
      ticketContext.suppliersList.length > 0
    ) {
      const supplier = ticketContext.suppliersList.find(
        (item) =>
          item.SupplierID === isFetchDetail.leadDetailResForUI.SupplierID,
      )
      if (supplier) {
        return supplier.SupplierName
      }
    } else if (
      isFetchDetail &&
      isFetchDetail.leadDetailResForUI &&
      supplierList.length > 0
    ) {
      const supplier = supplierList.find(
        (item) =>
          item.SupplierID === isFetchDetail.leadDetailResForUI.SupplierID,
      )
      if (supplier) {
        return supplier.SupplierName
      }
    }
    return ''
  }

  const handleCreateTicket = () => {
    if (
      fetchDetailFormValues.Source === 'PB Insurer' &&
      isFetchDetail.leadDetailResForUI &&
      [115, 7].includes(isFetchDetail.leadDetailResForUI.ProductID) &&
      auth &&
      auth.Source &&
      auth.Source.toLowerCase() != 'matrix' &&
      auth &&
      auth.UserID &&
      !UsersAllowedForTicketCreation(auth.UserID)
    ) {
      showErrMsg(
        "PB Manual ticket can't be created for Term & Investment Products",
      )
      return
    }
    if (
      fetchDetailFormValues.Source === 'PB Manual' &&
      OpenedThroughSource &&
      OpenedThroughSource != 'SMEPage' &&
      isFetchDetail.leadDetailResForUI &&
      [131].includes(isFetchDetail.leadDetailResForUI.ProductID) &&
      isFetchDetail.leadDetailResForUI.InvestmentTypeID == 1 &&
      auth &&
      auth.Source &&
      auth.Source.toLowerCase() != 'matrix' &&
      auth &&
      auth.UserID
    ) {
      showErrMsg(
        'For GHI, manual ticket can only be created from Corporate Search panel',
      )
      return
    }

    if (!createTicketFormValues.comment) {
      showErrMsg('Please enter the comment')
      return
    }

    if (isCreateTicketInProgress) {
      return
    }

    !createTicketFormValues.issue &&
      setCreateTicketFormErrors((prev) => ({ ...prev, issue: true }))
    !createTicketFormValues.subIssue &&
      setCreateTicketFormErrors((prev) => ({ ...prev, subIssue: true }))
    if (
      !createTicketFormValues.initiatedby &&
      [2].includes(isFetchDetail.leadDetailResForUI.ProductID)
    ) {
      setCreateTicketFormErrors((prev) => ({ ...prev, initiatedby: true }))
      return
    }
    if (!createTicketFormValues.issue || !createTicketFormValues.subIssue) {
      return
    }

    const {
      PolicyNo,
      ProductID,
      ProductName,
      SupplierID,
      SupplierName,
      EmailID,
      LeadID,
      RegistrationNo,
      ApplicationNo,
      CustomerName,
    } = isFetchDetail.leadDetailResForUI
    const payload = {
      Name: employeeName
        ? employeeName
        : isFetchDetail &&
          isFetchDetail.leadDetailResForUI &&
          isFetchDetail.leadDetailResForUI.CustomerName,
      PolicyNumber: PolicyNo,
      ClaimNumber: null,
      Source: fetchDetailFormValues.Source,
      CreatedBy: UserID_store,
      Comments:createTicketFormValues.comment,
      ProductID: ProductID,
      ProductName: ProductName,
      InsurerID: SupplierID,
      InsurerName: SupplierName || getSupplierName(),
      EmailID: EmailID_store,
      DialerUniqueID: null,
      Subject: null,
      LeadID: LeadID,
      CustomerID: 0,
      Issue: createTicketFormValues.issueId,
      IsCallBackRequest: 0,
      AutoClosure: 0,
      Attachments:
        fileUpload.length > 0
          ? fileUpload.map((item) => ({
              FileName: item.FileName,
              AttachemntContent: item.AttachemntContent,
            }))
          : [],
      RefObjectId: null,
      FollowUpDate: null,
      AddressDetails: null,
      Pincode: 0,
      RegistrationNo: RegistrationNo,
      ApplicationNo: ApplicationNo,
      EndorsementID: null,
      IsAutoResolved: null,
      QueryTypeID: 0,
      IssueIn: 0,
      SubIssueIn: 0,
      SubIssueId: createTicketFormValues.subIssueId,
      SubSource: ticketContext.sourceForCreateTicket,
      IsAttachment: fileUpload.length > 0 ? true : false,
      PrevRefObjectId: null,
      SubIssueIDList: null,
      IsMultipleEndorsement: false,
      InitiatedBy: createTicketFormValues.initiatedby,
    }
    // return console.log('payload', payload)
    setIsCreateTicketInProgress(true)
    POST(CreateTicketForWeCare, payload)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setTicketCreatedMsg(`Ticket ID: ${response.Data.TicketDetailsId}`)
          setDialogOpen(true)
          if (TicketDetailsID && RefObjectId) {
            getAllTicketSummary(RefObjectId, TicketDetailsID)

            if (OpenedThroughSource != 'Email') {
              ticketContext.GetTicketCommApi(RefObjectId, TicketDetailsID)
            }
            ticketContext.GetTicketDetailsByTicketIdApi(
              RefObjectId,
              TicketDetailsID,
            )
          }
          handleClose()
          ticketContext.GetTicketLogsApi()
          ticketContext.GetFilterCountApi()
        } else {
          showErrMsg('Something went wrong!')
        }
        setIsCreateTicketInProgress(false)
      })
      .catch((err) => {
        console.log('Error', err, err.response)
        setIsCreateTicketInProgress(false)
        showErrMsg(ErrorMessages[err.response.status])
      })
  }
  const handleChange = (event, setValue) => {
    const { name, value } = event.target
    if (name === 'PolicyNo') {
      setErrMessages({
        ...errMessages,
        PolicyNo: validatePolicyNumber(value),
      })
      setIsShowLess(true)
      setIsFetchDetail({})
      setIssueOptions([])
      setSubIssueOptions([])
      setFileUpload([])
      setCreateTicketFormErrors({
        issue: false,
        subIssue: false,
      })
      setCreateTicketFormValues({
        issue: '',
        issueId: '',
        subIssueId: '',
        subIssue: '',
        comment: '',
      })
    }
    setValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handleRemoveFile = (id) => {
    const fileList = fileUpload.filter((item) => item.id !== id)
    setFileUpload(fileList)
  }

  const handleClose = () => {
    setCreateDialog(false)
    if (AccessTypeVal != 4) {
      setFileUpload([])
      setfetchDetailFormValues({
        Source: '',
        leadId: '',
      })
      setCreateTicketFormValues({
        issue: '',
        issueId: '',
        subIssueId: '',
        subIssue: '',
        comment: '',
      })
      setIsShowLess(true)
      setfetchDetailFormErrors({
        Source: false,
        leadId: false,
      })
      setCreateTicketFormErrors({
        issue: false,
        subIssue: false,
      })
      setErrMessages({ leadId: '' })
      setIsFetchDetail({})
      setIssueOptions([])
      setSubIssueOptions([])
    }
  }

  const fetchAHCFromAPI = () => {
    let url = '/GetAHCDetails'
    let callback = (data, error) => {
      console.log('daa and error', data, error)
      if (data) {
        setAHCDialog({ show: true, url: data })
      } else showErrMsg(error)
    }
    fetchAHCUrl(url, isFetchDetail.leadDetailResForUI, auth, callback)
  }

  useEffect(() => {
    if (
      createTicketFormValues.issue &&
      createTicketFormValues.subIssue &&
      createTicketFormValues.issue.toLowerCase() === ahcIssue &&
      createTicketFormValues.subIssue.toLowerCase() === ahcSubissue &&
      AHC_SUPPLIERS.includes(FetchedTicketDetails.SupplierId)
    ) {
      setCustomDialogControl({
        title: ' Raise AHC?',
        open: true,
        actionButtons: [
          {
            Name: 'Cancel',
            className: 'raise-cencel',
            onClick: () => {
              setCustomDialogControl(customDialogInitialState)
              setCreateTicketFormValues((prev) => ({
                ...prev,
                issueId: null,
                issue: null,
                subIssueId: null,
                subIssue: null,
              }))
            },
          },
          {
            Name: 'Raise AHC',
            className: 'raise-btn',
            onClick: fetchAHCFromAPI,
          },
        ],
        handleClose: () => {
          setCustomDialogControl(customDialogInitialState)
          setCreateTicketFormValues((prev) => ({
            ...prev,
            issueId: null,
            issue: null,
            subIssueId: null,
            subIssue: null,
          }))
        },
      })
    } else {
      setCustomDialogControl(customDialogInitialState)
    }
  }, [createTicketFormValues.issue, createTicketFormValues.subIssue])

  return (
    <div>
      <Dialog
        className="dialog-create-ticket"
        fullScreen
        open={openTicketCreation}
        onClose={() => closeTicketCreation()}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        {/* {console.log("FetchedTicketDetails--------> create ticket", FetchedTicketDetails)}
        {console.log('fetchformvalues', fetchDetailFormValues)} */}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="right"
              color="inherit"
              onClick={() => closeTicketCreation()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Create New Ticket{' '}
              <span className="sourceticket">{OpenedThroughSource}</span>
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="all-comments">
          <div className="create-new-ticket">
            <Grid container spacing={1}>
              {isFetchDetail &&
                isFetchDetail.leadDetailResForUI &&
                isFetchDetail.leadDetailResForUI.ProductID == SME && (
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        label="Employee Name"
                        id="outlined-margin-normal"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={
                          employeeName
                            ? employeeName
                            : isFetchDetail &&
                              isFetchDetail.leadDetailResForUI &&
                              isFetchDetail.leadDetailResForUI.CustomerName
                        }
                        //value={createTicketFormValues.employeeName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled="true"
                      />
                    </FormControl>
                  </Grid>
                )}
                
              {/* reemove mail */}
              {/* {AccessTypeVal != 4 ? (
                <Grid item md={12} xs={12} className="select-box">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={
                      !fetchDetailFormValues.Source &&
                      fetchDetailFormErrors.Source
                    }
                  >
                    <div className="souce-name">
                      <label>Soucre</label>
                      PB Insurer
                    </div>
                    <InputLabel htmlFor="outlined-age-native-simple">
                      SOURCE
                    </InputLabel>
                    <Select
                      value={fetchDetailFormValues.Source}
                      onChange={(e) =>
                        handleChange(e, setfetchDetailFormValues)
                      }
                      label="Source"
                      inputProps={{
                        name: "Source",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      {sourceList &&
                        sourceList.map((item) => (
                          <MenuItem value={item.SourceName}>
                            {item.SourceName}
                          </MenuItem>
                        ))}
                    </Select>
                    {!fetchDetailFormValues.Source &&
                      fetchDetailFormErrors.Source && (
                        <FormHelperText>Required</FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              ) : null}
              */}
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={
                    (!fetchDetailFormValues.PolicyNumber &&
                      fetchDetailFormErrors.PolicyNumber) ||
                    (errMessages && true)
                  }
                >
                  <TextField
                    disabled={AccessTypeVal == 4 ? true : false}
                    label="Enter Policy / Application Number"
                    id="outlined-margin-normal"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    name="PolicyNo"
                    value={fetchDetailFormValues.PolicyNumber || fetchDetailFormValues.ApplicationNo}
                    onChange={(e) => handleChange(e, setfetchDetailFormValues)}
                  />
                  {errMessages.PolicyNumber && (
                    <FormHelperText>{errMessages.PolicyNumber}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {console.log('fetchformvalues', fetchDetailFormValues)}
              {AccessTypeVal != 4 ? (
                <Grid item md={12} xs={12}>
                  <Button
                    className="fetch-details-btn"
                    onClick={(e) => {
                      handleFetchDetail(fetchDetailFormValues.PolicyNo)
                    }}
                  >
                    <span>Fetch Details</span>
                  </Button>
                </Grid>
              ) : null}
              {!isShowLess &&
                (fetchingDetail ? (
                  <div className="spinner-loader">
                    <Spinner />
                  </div>
                ) : (
                  <Grid
                    container
                    spacing={1}
                    onDragOver={handleDrag}
                    onDrop={(e) => handleDrop(e, setFileUpload, fileRef)}
                    onDragLeave={handleDragOut}
                    onDragEnter={handleDragIn}
                  >
                    <Grid item md={12} xs={12} className="select-box">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        error={
                          !createTicketFormValues.issue &&
                          createTicketFormErrors.issue
                        }
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Issue
                        </InputLabel>
                        <Select
                          value={createTicketFormValues.issue}
                          label="Issue"
                          inputProps={{
                            name: 'issue',
                            id: 'outlined-age-native-simple',
                          }}
                        >
                          {issueOptions.map((item) => {
                            return (
                              <MenuItem
                                value={item.IssueName}
                                onClick={() =>
                                  handleIssueSubIssue(
                                    item.IssueTypeId,
                                    item.IssueName,
                                    'issue',
                                  )
                                }
                              >
                                {' '}
                                {item.IssueName}{' '}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {!createTicketFormValues.issue &&
                          createTicketFormErrors.issue && (
                            <FormHelperText>Required</FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} className="select-box">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        error={
                          !createTicketFormValues.subIssue &&
                          createTicketFormErrors.subIssue
                        }
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Sub Issue
                        </InputLabel>
                        <Select
                          value={createTicketFormValues.subIssue}
                          label="Sub Issue"
                          inputProps={{
                            name: 'subIssue',
                            id: 'outlined-age-native-simple',
                          }}
                        >
                          {subIssueOptions.map((item) => {
                            return (
                              <MenuItem
                                value={item.IssueName}
                                onClick={() =>
                                  handleIssueSubIssue(
                                    item.IssueTypeId,
                                    item.IssueName,
                                  )
                                }
                              >
                                {' '}
                                {item.IssueName}{' '}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {!createTicketFormValues.subIssue &&
                          createTicketFormErrors.subIssue && (
                            <FormHelperText>Required</FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    {isFetchDetail &&
                      isFetchDetail.leadDetailResForUI &&
                      isFetchDetail.leadDetailResForUI.ProductID != SME && (
                        <Grid item md={12} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <TextField
                              label="customerName"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              value={
                                isFetchDetail &&
                                isFetchDetail.leadDetailResForUI &&
                                isFetchDetail.leadDetailResForUI.CustomerName
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled="true"
                            />
                          </FormControl>
                        </Grid>
                      )}
                    {console.log("email ....",email)}
                    {isFetchDetail &&
                      isFetchDetail.leadDetailResForUI &&
                      isFetchDetail.leadDetailResForUI.ProductID && (
                        <Grid item md={12} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <TextField
                              label="Email"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              // value={
                              //   isFetchDetail &&
                              //   isFetchDetail.leadDetailResForUI &&
                              //   isFetchDetail.leadDetailResForUI.EmailID
                              // }
                              value={EmailID_store}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled="true"
                            />
                          </FormControl>
                        </Grid>
                      )}
                    <Grid item md={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          label="product"
                          id="outlined-margin-normal"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={
                            isFetchDetail &&
                            isFetchDetail.leadDetailResForUI &&
                            isFetchDetail.leadDetailResForUI.ProductName
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled="true"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          label="insurer"
                          id="outlined-margin-normal"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={
                            (isFetchDetail &&
                              isFetchDetail.leadDetailResForUI &&
                              isFetchDetail.leadDetailResForUI.SupplierName) ||
                            getSupplierName()
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled="true"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          label="Application No"
                          id="outlined-margin-normal"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={
                            isFetchDetail &&
                            isFetchDetail.leadDetailResForUI &&
                            isFetchDetail.leadDetailResForUI.ApplicationNo
                          }
                          disabled="true"
                        />
                      </FormControl>
                    </Grid>

                    {isFetchDetail.leadDetailResForUI &&
                      (isFetchDetail.leadDetailResForUI.ProductID == 117 ||
                        isFetchDetail.leadDetailResForUI.ProductID === 114) && (
                        <Grid item md={12} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <TextField
                              label="Registraion No"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              value={
                                isFetchDetail &&
                                isFetchDetail.leadDetailResForUI &&
                                isFetchDetail.leadDetailResForUI.RegistrationNo
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled="true"
                            />
                          </FormControl>
                        </Grid>
                      )}
                    {isFetchDetail.leadDetailResForUI &&
                      isFetchDetail.leadDetailResForUI.ProductID == 2 && (
                        <Grid item md={12} xs={12} className="select-box">
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            error={
                              !createTicketFormValues.initiatedby &&
                              createTicketFormErrors.initiatedby
                            }
                          >
                            <InputLabel htmlFor="outlined-age-native-simple">
                              Initiated By
                            </InputLabel>
                            <Select
                              value={createTicketFormValues.initiatedby}
                              label="Initiated By"
                              inputProps={{
                                name: 'initiatedby',
                                id: 'outlined-age-native-simple',
                              }}
                            >
                              {InitiatedByList.map((item) => {
                                return (
                                  <MenuItem
                                    value={item}
                                    onClick={() =>
                                      handleIssueSubIssue(
                                        0,
                                        item,
                                        'initiatedby',
                                      )
                                    }
                                  >
                                    {' '}
                                    {item}{' '}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                            {!createTicketFormValues.initiatedby &&
                              createTicketFormErrors.initiatedby && (
                                <FormHelperText>Required</FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                      )}
                    <Grid item md={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        s
                        className={classes.formControl}
                      >
                        <TextareaAutosize
                          placeholder="Enter Comments"
                          name="comment"
                          value={createTicketFormValues.comment}
                          onChange={(e) =>
                            handleChange(e, setCreateTicketFormValues)
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FileUploadComponent
                        setFiles={setFileUpload}
                        fileRef={fileRef}
                      />
                      <div className="uplaod-doc-view">
                        {fileUpload.map((item) => {
                          return (
                            <span className="file-detail" key={item.id}>
                              {item.FileName}{' '}
                              <button onClick={() => handleRemoveFile(item.id)}>
                                <CloseIcon />
                              </button>
                            </span>
                          )
                        })}
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Button
                        className="submit-btn"
                        onClick={handleCreateTicket}
                        disabled={isCreateTicketInProgress}
                      >
                        {isCreateTicketInProgress ? (
                          <Spinner classaName="spinner-btn" />
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </Dialog>
      {isDialogOpen ? (
        <CopyTicketIdDialog
          isDialogOpen={isDialogOpen}
          setDialogOpen={setDialogOpen}
          Msg={TicketCreatedMsg}
          setTicketCreatedMsg={setTicketCreatedMsg}
        />
      ) : null}

      {/* ---------Custom Dialog ----------- */}
      <CustomRaiseRequestModal
        open={customDialogControl.open}
        title={customDialogControl.title}
        buttonWithFunction={customDialogControl.actionButtons}
        handleClose={customDialogControl.handleClose}
      />
      {/* ------------------------------------ */}

      {/* ============ */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: (state && state.auth && state.auth.userInfo) || {},
    FetchedTicketDetails:
      state && state.ticketdetails && state.ticketdetails.ticketDetails
        ? state.ticketdetails.ticketDetails
        : {},
    ProductID_store: (state && state.auth && state.auth.userInfo && state.auth.userInfo.ProductID) || '',
    InsurerID_store: (state && state.auth && state.auth.userInfo && state.auth.userInfo.InsurerID) || '',
    EmailID_store: (state && state.auth && state.auth.userInfo && state.auth.userInfo.EmailID) || '', 
    UserID_store: (state && state.auth && state.auth.userInfo && state.auth.userInfo.UserID) || '',
  }
}
export default connect(mapStateToProps)(NativeSelects)
