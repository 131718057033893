import React, { memo } from 'react';
import MultipleTabs from '../../shared/components/MultipleTabs';
import InsertIssues from './InsertIssues';
import InsertSubIssues from './InsertSubIssues';
import InsertIssueFilter from './InsertIssueFilter';
import InsertIRDA_TATDetails from './InsertIRDA_TATDetails';
import InsertSubIssueCancellationReason from './InsertSubIssueCancellationReason';
import InsertIssueField from './InsertIssueField';


const InsertIssueSubIssue = memo(()=>{
    const tabsToRender =[
        {
            Name : 'Issue',
            Component : <InsertIssues/>
        },
        {
            Name : 'SubIssue',
            Component: <InsertSubIssues/>
        },
        {
            Name: 'IssueFilter',
            Component: <InsertIssueFilter/>
            
        },
        {
            Name: 'IRDA_TATDetails',
            Component: <InsertIRDA_TATDetails/>
        },
        {
            Name: 'SubIssueCancellationReason',
            Component: <InsertSubIssueCancellationReason/>
        },
        {
            Name: 'IssueField',
            Component: <InsertIssueField/>
        }
    ]
    return(
        <div className='insert-ticket-issue'>
            <MultipleTabs tabsToRender={tabsToRender}/>
        </div>
    )
})

export default InsertIssueSubIssue;