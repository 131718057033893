import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/index.js';
import rootSaga from './saga/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'pbDashboard', 'weCareDashboard']
};

const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;
const persistedReducer = persistReducer(persistConfig, rootReducer)
export default () => {
  let store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(sagaMiddleware,thunk)
    ));
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store)
  return { store, persistor }
}


// export default persistor;