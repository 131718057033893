import React from 'react'

function NoDataFound(props) {
    let { title, imagePath } = props
    return (
        <div className='no-data-found-box'>
            {imagePath ? <img src={imagePath} alt='Image Path' /> :
                <img src='/images/no-data-found.svg' />}
            <span>{title}</span>
        </div>
    )
}

export default NoDataFound