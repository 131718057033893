import React, { useState } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { UpdateInsurerView } from "../../../../../constants/apiUrls";
import { POST } from "../../../../../services/apiServices";

const SendToInsurerCheckbox = ({id,IsInsurer,oldTicketDetails}) => {
  const [isInsurerChecked, setIsInsurerChecked] = useState(IsInsurer);
  const [isSendToInsurerDisabled, setIsSendToInsurerDisabled] = useState(IsInsurer);

  const UpdateInsurerViewApi = ()=> {
      const payload = {id: id,IsInsurer: true};
      POST(UpdateInsurerView, payload)
      .then((res)=> {
          if(res.ErrorCode === 0){
              setIsSendToInsurerDisabled(true)
          }
      })
  }

  const handleCheckbox = ()=> {
      if(!isInsurerChecked){
          setIsInsurerChecked(true);
          UpdateInsurerViewApi();
      }
      else{
          setIsInsurerChecked(false);
      }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isInsurerChecked}
          onChange={handleCheckbox}
          name="SendToInsurer"
          color="primary"
          disabled={isSendToInsurerDisabled}
          id="sendToInsurerButton_Insurer"
        />
      }
      label="Send To Insurer"
      labelPlacement="start"
    />
  );
};

export default SendToInsurerCheckbox;
