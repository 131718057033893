import React, { useState, useRef, useEffect, useReducer } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Button, Grid, TextField,Link } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMasterList } from '../../redux/actions/common_action';
import { getUniqueSetBasedOnKey, toBase64 } from '../../shared/methods/methods';
import { Autocomplete } from '@material-ui/lab';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { SampleUploadIRDATAT, SampleUploadIssue } from './GenerateSample';
import { POST } from '../../services/apiServices';
import { Toast } from '../../shared/components';
import { handleCatch } from '../../shared/methods/apiMethods';
import Spinner from '../../shared/components/Spinner';

export default function InsertIRDA_TATDetails() {
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [productList, setProductList] = useState()
    const dispatch = useDispatch();
    const productSupplierMaster = useSelector(state => state && state.common && state.common.productSupplierMaster);
    const [fileUpload, setFileUpload] = useState();
    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef();
    

    useEffect(() => {
        if (productSupplierMaster && productSupplierMaster.length === 0) {
            dispatch(fetchMasterList())
        }
    }, [productSupplierMaster]);

    useEffect(() => {
        if (productSupplierMaster) {
            getProductSet();
        }
    }, [productSupplierMaster])

    const getProductSet = () => {
        let arr = getUniqueSetBasedOnKey(productSupplierMaster, 'ProductID', ['ProductID', 'ProductName']);
        setProductList(arr);
    }

    const handleRemoveFile = (id) => {
        const fileList = fileUpload.filter((item) => item.id !== id);
        setFileUpload(fileList);
    };

    // Download all the records
    const DownloadSampleFile = () => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const FileName = `SampleFile_UploadIRDA_TATDetails${selectedProduct}`
        const csvData = SampleUploadIRDATAT();
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, FileName + fileExtension);
    };

    const uploadIssueList = () => {
        let endpoint = 'InsertIrdaTATMapping';
        let payload = {
            FileData: fileUpload && fileUpload.AttachmentContent,
            FileName:fileUpload && fileUpload.FileName,
            ProductID: selectedProduct,
        }
        POST(endpoint, payload).then(res => {
            if (res && res.ErrorCode === 0) {
                Toast.success('Issue List uploaded successfully');
                setFileUpload();
            }
            else {
                Toast.error(res['Message'] || 'Error occurred while uploading issue list');
            }
        }).catch(err => {
            handleCatch(err);
        })

    }

    const handleFileSelect = async (e) => {
        const base64 = await toBase64(e.target.files[0]);
        setFileUpload({
            FileName: e.target.files[0].name,
            AttachmentContent: base64.split("base64,")[1],
        });
        fileUploadRef.current.value = "";
    }


    return (
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <h3>Insert IRDA_TAT Details</h3>
            </Grid>
            <input type='file' accept=".xlsx" style={{ display: 'none' }} ref={fileUploadRef} onChange={handleFileSelect} />
            
                <><Grid item md={2} xs={12}>
                    <Button id="insertIssueUploadBtn" variant='outlined' className="download-sample-btn" onClick={DownloadSampleFile}>Download Sample</Button>
                </Grid>
                    <Grid item md={12} xs={12}>
                        {fileUpload ? <Button className="download-sample-btn" disabled={loading} onClick={uploadIssueList}>
                            Upload {loading && <Spinner />}
                        </Button>
                            : <Button className="download-sample-btn selected-btn" onClick={() => fileUploadRef.current.click()}>
                                Select File
                            </Button>}
                        <div className="uplaod-doc-view">
                            {fileUpload &&
                                <span className="file-detail">
                                    {fileUpload.FileName}{" "}
                                    <button onClick={() => setFileUpload(null)}>
                                        <CloseIcon />
                                    </button>
                                </span>}
                        </div>
                    </Grid>
                </> 
                
        </Grid>
    );
}
