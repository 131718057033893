import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function CustomRaiseRequestModal({
  title,
  buttonWithFunction,
  open,
  handleClose,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="raised-dialog "
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <h3>
            <img width="50px" src="/images/raised_ticket.png" />
            {title}
          </h3>
        </DialogTitle>
        <DialogActions>
          <div className="raise-ticket-pop">
            {buttonWithFunction &&
              buttonWithFunction.length > 0 &&
              buttonWithFunction.map((item, index) => {
                return (
                  <Button
                    key={index + "Button"}
                    className={item.className}
                    onClick={item.onClick}
                  >
                    {item.Name}
                  </Button>
                );
              })}
            {/* <Button className="raise-cencel" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="raise-btn" onClick={raiseEndorsementFunc}>
              Raise Endorsement
            </Button> */}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
