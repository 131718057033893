import { GET, POST } from "../../services/apiServices";
import MessageConstants from "../constants";
import Toast from "../components/Toast";
import { CheckIfObjectInArrayObject } from "./methods";

//fetch AHC url
export const fetchAHCUrl = (url, ticketDetails, user, cb) => {
  console.log('ticketdetails--->', ticketDetails)
  let payload = {
    BookingID: ticketDetails.LeadID || 0, //43362530,
    CustomerID: ticketDetails.CustomerId || Number(ticketDetails.CustomerID) || 0,
    SourceID: 4,
    ProductID: ticketDetails.ProductId || ticketDetails.ProductID ||   0,
    UserID: user.UserID,
    UserName: user.Name,
    UserCode: user.EmployeeID,
    TicketID: ticketDetails.TicketId || 0,
    TicketNo: ticketDetails.TicketDetailsID || "",
  };
  POST(url, payload)
    .then((res) => {
      if (res && res.ErrorCode === 0 && res.Data && res.Data.ahcPortalURL) {
        cb(res.Data.ahcPortalURL, null);
      } else cb(null, res.Data.message || `Could't fetch AHC url`);
    })
    .catch((err) => {
      if (err && err.response) {
        return cb(
          null,
          err.response.data.message || MessageConstants[err.response.status]
        );
      } else return cb(null, "Some error occured!");
    });
};

//fetches Endorsement URL Motor/2Wheeler - NEW 
export const fetchEndosementURL = (url, ticketDetails, cb) =>{
    const payload = {
        EndorsementID: 0,
        BookingID: ticketDetails.LeadID || 0, 
        // TicketNumber: ticketDetails.TicketDetailsID || "",
        ProductID: ticketDetails.ProductId || 0,
        TicketID: ticketDetails.TicketId || 0,
        CustomerID: ticketDetails.CustomerId || 0,
        LeadID: ticketDetails.LeadID || 0,
        TicketNo:ticketDetails.TicketDetailsID || "",
        RequestTypeID:2,
       };
      POST(url,payload).then(res=>{
        if(res && res.Data && res.ErrorCode === 0 && res.Data.returnValue){
            return cb(res.Data.returnValue, null)
        }
        else {
            return cb(null, res.Message || "Couldn't fetch Endorsement URL");
        }
      }).catch(err=>{
        if (err && err.response) {
            return cb(
              null,
              err.response.data.message || MessageConstants[err.response.status]
            );
          } else return cb(null, "Some error occured!");
        });
}

//GetDoc URL

export const getDocUrl = (docId, cb) => { // cb is used for the loader handling
  if (docId.length > 0) {
    GET(`/GetDocURLEnc/${docId}`).then((res) => {
      if (cb) { cb() }
      if (res && res.ErrorCode == 0) {
        let newWindow = window.open(res.Data, "_blank");
        if (newWindow) {
          newWindow.focus();
        } else Toast.error("Please allow popup to view PDF")
      }
      else Toast.error(res.Message || "Couldn't fetch Doc URL")
    })
      .catch((err) => {
        if (cb) { cb() }
        handleCatch(err);
      });
  }
};

//catch common function
export const handleCatch = (err, returnErrorMessage) =>{
  let msg = ''
  if (err && err.response) {
    msg =  err.response.data.Message || MessageConstants[err.response.status]
    // return showMessage(err.response.data.message || MessageConstants[err.response.status], "error");
  } else msg = 'Some error occurred!'
  return returnErrorMessage ? msg : Toast.error(msg)
  // return showMessage("Some error occured!", "error");
}


// fetch Endorsement for Existing Endorsement Motor/2wheeler/health for all 
export const fetchEndosementURLForExisting = (url, ticketDetails, cb) =>{
  const payload = {
      EndorsementID: ticketDetails.EndorsementID,
      BookingID: ticketDetails.LeadID || 0, 
      // TicketNumber: ticketDetails.TicketDetailsID || "",
      ProductID: ticketDetails.ProductId || 0,
      TicketID: ticketDetails.TicketId || 0,
      CustomerID: ticketDetails.CustomerId || 0,
      LeadID: ticketDetails.LeadID || 0,
      TicketNo:ticketDetails.TicketDetailsID || "",
    };
    POST(url,payload).then(res=>{
      if(res && res.ErrorCode === 0 &&  res.Data && res.Data.returnValue){
          return cb(res.Data.returnValue, null)
      }
      else {
          return cb(null, res.Message || "Couldn't fetch Endorsement URL");
      }
    }).catch(err=>{
      if (err && err.response) {
          return cb(
            null,
            err.response.data.message || MessageConstants[err.response.status]
          );
        } else return cb(null, "Some error occured!");
      });
}

export const fetchDashboardData_method = (url, payload, cb) => {
  POST(url, payload, 'Dashboard').then((res) => {
    if (res && res["ErrorCode"] == 0) {
      var sortedArray
      if (res.Data && res.Data.length > 1) {
        sortedArray = res.Data.sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))
      }
      else sortedArray = res.Data
      cb(sortedArray)
    } else {
      cb([]);
      Toast.error(res["Message"] || "No data found!")
      // showMessage(res["Message"] || "No data found!", "error");
    }
  })
    .catch((err) => {
      cb([]);
      handleCatch(err);
    });
}

export const exportDashboardData_method = (url, payload, cb, fileName, controller='Dashboard') => {
  POST(url, payload, controller).then((res) => {
      cb();// for disabling loader
      if (res) {
        const linkSource = `data:application/xlsx;base64,${res}`;
        const downloadLink = document.createElement("a");
        const downloadFileName = fileName + ".xlsx";

        downloadLink.href = linkSource;
        downloadLink.download = downloadFileName;
        downloadLink.click();
        // showMessage("Successfully exported!", "success")
        Toast.success("Successfully exported")
      }
    else {
      Toast.error(res["Message"] || "No data found!")
      // showMessage(res["Message"] || "No data found!", "error");
    }
  })
    .catch((err) => {
      cb();
      handleCatch(err);
    });
}

//fetch Endorsmenet url for Health (Not getting used)
export const fetchEndosementURLForHealth = (url, ticketDetails, cb) =>{
  const payload = {
      EndorsementID: 0,
      BookingID: ticketDetails.LeadID || 0, 
      TicketNumber: ticketDetails.TicketDetailsID || "",
      ProductID: ticketDetails.ProductId || 0,
      TicketID: ticketDetails.TicketId || 0,
      CustomerID: ticketDetails.CustomerId || 0,
    };
    POST(url,payload).then(res=>{
      if(res && res.Data && res.Data.RaiseNewEndorsementURL){
          return cb(res.Data.RaiseNewEndorsementURL, null)
      }
      else {
          return cb(null, res.Message || "Couldn't fetch Endorsement URL");
      }
    }).catch(err=>{
      if (err && err.response) {
          return cb(
            null,
            err.response.data.message || MessageConstants[err.response.status]
          );
        } else return cb(null, "Some error occurred!");
      });
}

export const ReduxActionGeneratorFunction = (action, payload) => {
  return {
      type: action,
      payload
  }
}

export const GetUserGroupMaster = (products, cb) =>{
  let url = `GetTicketMaster/UserGroupMaster/${products}`;
  GET(url).then(res=>{
    if(res && res['ErrorCode']=== 0){
      let tempArr = [];
      if(res['Data']['UserGroups'] && res['Data']['UserGroups'].length > 0){
        let allGroups = res['Data']['UserGroups'];
        allGroups.forEach(item=>{
          if(item && !CheckIfObjectInArrayObject(item,['ProductId'], tempArr)){
            tempArr.push(item)
          }
        })
      }
      cb(tempArr)
    }
    else cb([])
  }).catch(err=>{
    handleCatch(err);
    cb([])
  })
}

export const fetchPendencyMasterCommon = (LeadID, cb) => {
  let endpoint = `/GetTicketMaster/PendencyTypeMasterByLeadID/${LeadID}`
  GET(endpoint).then(res => {
      if (res && res["ErrorCode"] === 0) {
          cb(res['Data']?.['PendencyMaster'])
      }
      else cb([])
  }).catch(err => {
      handleCatch(err);
      cb([])
  })
}

export const fetchPendencyDetailsCommon = (payload, cb) => {
  let endpoint = '/GetCustomerPendencyData';
  POST(endpoint, payload).then(res => {
      if (res && res['ErrorCode'] === 0) {
        cb(res['Data'])
      }
      else{
        cb({})
      }
  }).catch(err => {
      handleCatch(err);
      cb({})
  })
}

export const fetchDispatcherURL = (payload, cb) => {
  let url = '/GetDispatcherSystemUrl';
  POST(url, payload).then(res => {
    if (res && res.ErrorCode === 0) {
      if (cb) { cb(res?.Data?.Url) }
    }
    else {
      Toast.error(res?.Message || 'Failed to fetch dispatcher details')
    }
  }).catch(err => {
    handleCatch(err);
    if (cb) { cb(false) }
  })
}