import React, { useState, useReducer, useEffect } from 'react';
import { Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { POST } from '../../../services/apiServices';
import { fetchPendencyDetailsCommon, fetchPendencyMasterCommon, handleCatch } from '../../../shared/methods/apiMethods';
import { DialogFromRight, Toast } from '../../../shared/components';
import Spinner from '../../../shared/components/Spinner';
import { URLRegex } from '../../../Constants';
import CustomBackdrop from '../../../pages/Components/CustomBackdrop';
import { refreshAnythingBasedOnKey, setConfirmationDialogControls } from '../../../redux/actions/utlity_actions';
import { PAYMENT_PENDENCY_REMARKS_MASTER } from '../../../options.config';
import Autocomplete from "@material-ui/lab/Autocomplete";


function DocumentPendency(props) {
	const { fetchLatestLogs } = props;
	const dispatch = useDispatch()
	const ticketDetails_store = useSelector(state => state.ticketdetails && state.ticketdetails.ticketDetails || {})
	const [state, setState] = useReducer(
		(values, newValues) => ({ ...values, ...newValues }), {
		raisedPendencyList: [],
		dialogControls: null,
		createNewPendency: false,
		pendencyMaster: null,
		selectedNewPendency: {},
		savingSelectedNewPendency: false,
		// viewLogs: false
	})

	const [resolveState, setResolveState] = useState({});
	const [dynamicLoader, setDynamicLoader] = useState({})

	useEffect(() => {
		fetchPendencyDetails(true);
		refreshThings();
	}, [])

	const fetchPendencyDetails = (showLoader = false) => {
		let cb = (data) => {
			showLoader && setState({ loader: false });
			setState({ raisedPendencyList: data['RaisedPendencies'] });
		}
		showLoader && setState({ loader: true });
		fetchPendencyDetailsCommon(basePayload, cb)
	}

	useEffect(() => {
		if (!state.pendencyMaster) {
			let cb = (val) => {
				setState({ pendencyMaster: val })
			}
			fetchPendencyMasterCommon(basePayload.LeadId, cb)
		}
	}, [state.pendencyMaster])

	const handleResolveStateChange = (event) => {
		let { name, value } = event.target;
		setResolveState(prev => ({ ...prev, [name]: value }))
	}

	let basePayload = {
		"TicketId": ticketDetails_store?.TicketId,
		"LeadId": ticketDetails_store?.LeadID,
		"CustomerId": ticketDetails_store?.CustomerId,
		"TicketDetailsId": ticketDetails_store?.TicketDetailsID
	}

	const fetchDynamicPendencyUrl = (payloadPassed, title) => {
		let endpoint = 'GetPendencyPortalUrl';
		let payload = {
			...basePayload,
			...payloadPassed,
		}
		setState({ loader: true })
		POST(endpoint, payload).then(res => {
			setState({ loader: false })
			if (res && res['ErrorCode'] === 0 && res['Data']) {
				setState({
					dialogControls: {
						open: true,
						size: 70,
						renderComponent: <iframe src={res['Data']} height={"100%"} />,
						title: title
					}
				})
			}
			else {
				Toast.error(res['Message'] || `Failed to open ${title} Portal`)
			}
		}).catch(err => {
			setState({ loader: false })
			handleCatch(err)
		})
	}

	const confirmationDialog = (typeToResolve, DisplayText) => {
		dispatch(setConfirmationDialogControls({
			title: `Are you sure you want to resolve <u>${DisplayText}</u>?`,
			open: true,
			actionButtons: [
				{
					Name: "Yes",
					onClick: () => { handleResolvePendencyStatus(typeToResolve); dispatch(setConfirmationDialogControls()) },
					className: "confirm-yes",
					submitButton: true,
				}, {
					Name: "No",
					className: "confirm-no",
					onClick: () => dispatch(setConfirmationDialogControls())
				}
			],
			handleClose: () => dispatch(setConfirmationDialogControls())
		}))
	}

	const handleResolvePendencyStatus = (typeToResolve) => {
		let endpoint = 'InsertUpdateCustPendencyData';
		let obj = state.raisedPendencyList && state.raisedPendencyList.filter(item => item.PendencyType === typeToResolve)?.[0]
		if (obj) {
			let payload = {
				...basePayload,
				NewPendencies: [
					{
						...obj,
						Remarks: resolveState[obj['PendencyType']],
						IsResolved: true,
					}
				]
			}
			setDynamicLoader({ [typeToResolve]: true })
			POST(endpoint, payload).then(res => {
				setDynamicLoader({ [typeToResolve]: false })
				if (res && res['ErrorCode'] === 0) {
					Toast.success(res['Message'] || 'Saved successfully')
					setState({ selectedNewPendency: {} })
					fetchPendencyDetails(true);
					fetchLatestLogs()
				}
				else {
					Toast.err(res['Message'] || 'Failed to save the changes')
				}
			}).catch(err => {
				setDynamicLoader({ [typeToResolve]: false })
				handleCatch(err);
			})
		}
		else return

	}

	const renderCreateNewPendencyOption = () => {
		if (state.raisedPendencyList && state.pendencyMaster) {
			let filteredOptions = state.pendencyMaster && state.pendencyMaster.length > 0 && state.pendencyMaster.filter(item => !JSON.stringify(state.raisedPendencyList).toLowerCase().includes(item.PendencyType?.toLowerCase()))
			return filteredOptions
		}
		else return []
	}

	useEffect(() => {
		!state.createNewPendency && setState({ selectedNewPendency: {} })
	}, [state.createNewPendency])

	const handleChange = (e) => {
		let { name, value } = e.target;
		let obj
		obj = name === 'PendencyType' ? (value === 'Payment Pending' ? { PaymentAmount: null, PaymentLink: null, Remarks: null } : { Remarks: null })
			: Object.assign(state.selectedNewPendency, {})
		obj[name] = value;
		console.log(obj)
		setState({ selectedNewPendency: obj })
	}

	const checkIfHasError = (pendencyType, field) => {
		let { PaymentLink, PaymentAmount, Remarks } = state.selectedNewPendency;
		let master = {
			'PaymentLink': 'Payment Link',
			'PaymentAmount': 'Payment Amount',
			'Remarks': 'Remarks'
		}
		let error;
		if (pendencyType === 'Payment Pending') {
			switch (field) {
				case 'Remarks': error = !Remarks && Remarks !== null ? `${master[field]} cannot be empty` : ''
					break;
				case 'PaymentAmount': error = !PaymentAmount && PaymentAmount !== null ? `Please enter ${master[field]}` : PaymentAmount < 0 ? `${master[field]} needs to be positive` :
					''
					break;
				case 'PaymentLink': error = !PaymentLink && PaymentLink !== null ? `Please enter ${master[field]}` : PaymentLink && !URLRegex.test(PaymentLink) ? `Invalid ${master[field]}` : ''
				default:
					break;
			}
		}
		else if (!['Document Pending', 'KYC Pending', 'PF Pending', 'Counter offer Acceptance'].includes(pendencyType)) {
			switch (field) {
				case 'Remarks': error = !Remarks && Remarks !== null ? `${master[field]} cannot be empty` : ''
					break;
			}
		}
		return error
	}

	const conditionalRendering = () => {
		let { PendencyType } = state.selectedNewPendency;
		return PendencyType === 'Payment Pending' ?
			(
				<>
					<ul className='create-pendency-list pendency-listing'>

						<li>
							<FormControl fullWidth className='form-control create-pendency-form' error={checkIfHasError(PendencyType, 'PaymentAmount', state.selectedNewPendency['PaymentAmount'])} >
								<TextField id="outlined-basic"
									label="Amount"
									name='PaymentAmount'
									value={state.selectedNewPendency['PaymentAmount']}
									type='number'
									onChange={handleChange}
									variant="outlined"
									error={checkIfHasError(PendencyType, 'PaymentAmount', state.selectedNewPendency['PaymentAmount'])}
									helperText={checkIfHasError(PendencyType, 'PaymentAmount', state.selectedNewPendency['PaymentAmount'])}
								/>
							</FormControl>
						</li>
						{/* <li>
							<FormControl fullWidth className='form-control create-pendency-form payment-link' error={checkIfHasError(PendencyType, 'PaymentLink', state.selectedNewPendency['PaymentLink'])}>
								<TextField id="outlined-basic"
									label="Payment Link"
									name='PaymentLink'
									value={state.selectedNewPendency['PaymentLink']}
									onChange={handleChange}
									variant="outlined"
									error={checkIfHasError(PendencyType, 'PaymentLink', state.selectedNewPendency['PaymentLink'])}
									helperText={checkIfHasError(PendencyType, 'PaymentLink', state.selectedNewPendency['PaymentLink'])}
								/>
							</FormControl>
						</li> */}

						<li>
							<FormControl fullWidth className='remark-form-control  multi-select-box'>
								{/* <InputLabel id="demo-simple-select-outlined-label">Remarks</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									title={state.selectedNewPendency['Remarks']}
									value={state.selectedNewPendency['Remarks'] ? state.selectedNewPendency['Remarks'].split(',') : []}
									onChange={(e) => handleChange({...e, target : {...e.target, value: e.target.value ? (e.target.value).join(',') : e.target.value}})}
									label="Remarks"
									name='Remarks'
									multiple
								>
									{
										PAYMENT_PENDENCY_REMARKS_MASTER(ticketDetails_store?.ProductId).map((option) => {
											return <MenuItem value={option} key={option}>{option}</MenuItem>
																					})
									}
								</Select> */}
								<Autocomplete
									options={PAYMENT_PENDENCY_REMARKS_MASTER(ticketDetails_store?.ProductId)}
									fullWidth
									className="single-select"
									name="Remarks"
									id="pendencyDialog-remarks"
									disableCloseOnSelect
									getOptionLabel={(item) => item}
									value={PAYMENT_PENDENCY_REMARKS_MASTER(ticketDetails_store?.ProductId).filter(item => state.selectedNewPendency['Remarks']?.split(',')?.includes(item))}
									onChange={(e, optionsSelected) => handleChange({...e, target : {...e.target, name: 'Remarks', value: optionsSelected ? optionsSelected.join(',') : ""}})}
									renderOption={(option, { selected }) => (
										<React.Fragment>
											<Checkbox
												className='autocomplete-checkbox'
												checked={state.selectedNewPendency['Remarks'] && state.selectedNewPendency['Remarks'].split(',')?.includes(option) ? true : false}
											/>
											<span className='product-names'>{option}</span>
										</React.Fragment>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label="Select Remarks"
										/>
									)}
									multiple // Enable multi-select
								/>
							</FormControl>
						</li>
					</ul>
				</>
			)
			:
			!['Document Pending', 'KYC Pending', 'PF Pending'].includes(PendencyType) && <ul className='create-pendency-list'>
				<li>
					<div className='remarks'>
						<label>Remarks</label>
						<textarea
							name='Remarks'
							value={state.selectedNewPendency['Remarks']}
							onChange={handleChange}
						/>
						{console.log(checkIfHasError(PendencyType, 'Remarks'))}
						{checkIfHasError(PendencyType, 'Remarks') && <span className='error-msg'>{checkIfHasError(PendencyType, 'Remarks')}</span>}
					</div>
				</li>
			</ul>

	}

	const validateFields = () => {
		let { PendencyType, PaymentAmount, PaymentLink, Remarks } = state.selectedNewPendency;
		if (PendencyType === 'Payment Pending') {
			if (!Remarks || !PaymentAmount) { //!PaymentLink || !PaymentAmount || 
				let newObj = { ...state.selectedNewPendency, PaymentAmount: PaymentAmount || "", PaymentLink: PaymentLink || "", Remarks: Remarks || "" }
				setState({ selectedNewPendency: newObj })
				return false
			}
			else return true

		}
		else if (!['Document Pending', 'KYC Pending', 'Inspection Pending', 'PF Pending', 'Counter offer Acceptance'].includes(PendencyType)) {
			if (!Remarks) {
				let newObj = { ...state.selectedNewPendency, Remarks: Remarks || "" }
				setState({ selectedNewPendency: newObj })
				return false
			}
			else return true
		}
		else return true
	}

	// const disableSaveButton = () => {
	// 	let { PendencyType } = state.selectedNewPendency;
	// 	if (PendencyType === 'Payment Pending') {
	// 		return (checkIfHasError(PendencyType, 'Remarks') || checkIfHasError(PendencyType, 'PaymentAmount') || checkIfHasError(PendencyType, 'PaymentLink'))
	// 	}
	// 	else return checkIfHasError(PendencyType, 'Remarks')
	// }

	const saveNewPendency = () => {
		if (!validateFields()) { return }
		let endpoint = 'InsertUpdateCustPendencyData';
		let { PendencyType, PaymentAmount, PaymentLink, Remarks } = state.selectedNewPendency
		setState({ savingSelectedNewPendency: true })
		let payload = {
			...basePayload,
			NewPendencies: [
				{
					"PendencyType": PendencyType,
					"Remarks": Remarks,
					"PaymentLink": PaymentLink,
					"PaymentAmount": PaymentAmount,
					"IsResolved": false
				}
			]
		}
		POST(endpoint, payload).then(res => {
			setState({ savingSelectedNewPendency: false })
			if (res && res['ErrorCode'] === 0) {
				Toast.success(res['Message'] || 'Success');
				setState({ createNewPendency: false })
				fetchPendencyDetails();
				fetchLatestLogs();
				refreshThings()
			}
			else {
				Toast.error(res['Message'])
			}
		}).catch(err => {
			setState({ savingSelectedNewPendency: false })
			handleCatch(err)
		})
	}

	const refreshThings = () => {
		dispatch(refreshAnythingBasedOnKey('TicketDetails'));
		dispatch(refreshAnythingBasedOnKey('AllTickets'))
	}

	return (
		<>
			<div className='document-pendency-box'>
				{state.loader && <CustomBackdrop open={state.loader} textToShow='Loading...' />}
				<Grid container spacing={2}>
					{Boolean(Array.isArray(state.raisedPendencyList) && state.raisedPendencyList.length > 0) &&
						<Grid item md={7} sm={7} xs={12}>
							{state.raisedPendencyList.map(item => {
								if (item.PendencyType === 'Inspection Pending') {
									return (
										<div className='document-pendency'>
											<div className='pendency-head'>
												<h4>Inspection Pendency</h4>
												<div className='pendency-resolve-btns'>
													<Button className='docspoint-btn resolve-btn' disabled={dynamicLoader[item.PendencyType]} onClick={() => confirmationDialog(item.PendencyType, item.PendencyTypeDisplayName)}>{dynamicLoader && dynamicLoader[item.PendencyType] ? <Spinner color='white' /> : 'Resolve'}</Button>
												</div>
											</div>
											<div className='document-pendency-body'>
												<ul className='list'>
													<li>
														<p>Inspection ID : <strong>{item.InspectionId}</strong></p>
													</li>
													<li>
														<Button onClick={() => fetchDynamicPendencyUrl({ Type: item.PendencyType, InspectionId: item.InspectionId }, item.PendencyType)}>{item?.InspectionStatus || '-'}</Button>
													</li>
												</ul>
											</div>
										</div>
									)
								}
								else if (item.PendencyType === 'Payment Pending') {
									return (
										<div className='document-pendency'>
											<div className='pendency-head'>
												<h4>Payment Pendency</h4>
												<div className='pendency-resolve-btns'>
													<Button className='docspoint-btn resolve-btn' disabled={dynamicLoader[item.PendencyType]} onClick={() => confirmationDialog(item.PendencyType, item.PendencyTypeDisplayName)}>{dynamicLoader && dynamicLoader[item.PendencyType] ? <Spinner color='white' /> : 'Resolve'}</Button>
												</div>
											</div>
											<div className='document-pendency-body'>
												<ul className='list'>
													<li>
														<p>Shortfall Amount : <span className='price'>{item.PaymentAmount >= 0 ? <><i className='fa fa-inr'></i>{item.PaymentAmount}</> : null}</span></p>
													</li>
													{/* {item.PaymentLink && <li>
														{item.PaymentLink ? <><a style={{ textOverflow: 'ellipsis' }} href={item.PaymentLink} target='_blank'>{item.PaymentLink}</a>&nbsp;<span className='copy-btn'><CustomCopyToClipboard text={item.PaymentLink} /></span></> : ''}
													</li>} */}
												</ul>
												<ul className='list'>
													<li>
														<p>Remarks</p>
													</li>
													<li>
														<span>{item.Remarks || '-'}</span>
													</li>
												</ul>
											</div>

										</div>
									)
								}
								else if (item.PendencyType === 'Document Pending') {
									return (
										<div className='document-pendency'>
											<div className='pendency-head'>
												<h4>Document Pendency</h4>
												<div className='pendency-resolve-btns'>
													{(!item.DocumentModel || (item.DocumentCategory && item.DocumentCategory.length === 0)) &&
														<Button className='docspoint-btn' onClick={() => fetchDynamicPendencyUrl({ Type: item.PendencyType, InspectionId: item.InspectionId }, item.PendencyType)}>Raise Doc Request</Button>}
													<Button className='docspoint-btn resolve-btn' disabled={dynamicLoader[item.PendencyType]} onClick={() => confirmationDialog(item.PendencyType, item.PendencyTypeDisplayName)}>{dynamicLoader && dynamicLoader[item.PendencyType] ? <Spinner color='white' /> : 'Resolve'}</Button>
												</div>
											</div>
											<div className='document-pendency-body'>
												{
													item.DocumentModel && Array.isArray(item.DocumentModel) && item.DocumentModel.length > 0 ?
														item.DocumentModel.map(item2 => {
															return (
																<ul className='list'>
																	<li>
																		<p>{item2.DocumentCategory}</p>
																	</li>
																	<li>
																		<Button onClick={() => fetchDynamicPendencyUrl({ Type: item.PendencyType, InspectionId: item.InspectionId }, item.PendencyType)}>{item2.StatusName}</Button>
																	</li>
																</ul>
															)
														})
														: <></>
												}
											</div>
										</div>
									)
								}
								else if (item.PendencyType === 'KYC Pending') {
									return (
										<div className='document-pendency'>
											<div className='pendency-head'>
												<h4>KYC Pendency</h4>
												<div className='pendency-resolve-btns'>
													<Button className='docspoint-btn resolve-btn' disabled={dynamicLoader[item.PendencyType]} onClick={() => confirmationDialog(item.PendencyType, item.PendencyTypeDisplayName)}>{dynamicLoader && dynamicLoader[item.PendencyType] ? <Spinner color='white' /> : 'Resolve'}</Button>
												</div>
											</div>
											<div className='document-pendency-body'>
												<ul className='list'>
													<li>
														<p>KYC Status</p>
													</li>
													<li>
														<Button disabled>{item.KYCStatus || '-'}</Button>
													</li>
												</ul>
											</div>
										</div>
									)
								}
								else if (item.PendencyType === 'Confirmation Pending') {
									return (
										<div className='document-pendency'>
											<div className='pendency-head'>
												<h4>Confirmation Pendency</h4>
												<div className='pendency-resolve-btns'>
													<Button className='docspoint-btn resolve-btn' disabled={dynamicLoader[item.PendencyType]} onClick={() => confirmationDialog(item.PendencyType, item.PendencyTypeDisplayName)}>{dynamicLoader && dynamicLoader[item.PendencyType] ? <Spinner color='white' /> : 'Resolve'}</Button>
												</div>
											</div>
											<div className='document-pendency-body'>
												<ul className='list'>
													<li>
														<p>Remarks</p>
													</li>
													<li>
														<span>{item.Remarks || '-'}</span>
													</li>
												</ul>
											</div>
										</div>
									)
								}
								else if (item.PendencyType === 'Counter offer Acceptance') {
									return (
										<div className='document-pendency'>
											<div className='pendency-head'>
												<h4>Counter Offer Pendency</h4>
												<div className='pendency-resolve-btns'>
													<Button className='docspoint-btn resolve-btn' disabled={dynamicLoader[item.PendencyType]} onClick={() => confirmationDialog(item.PendencyType, item.PendencyTypeDisplayName)}>{dynamicLoader && dynamicLoader[item.PendencyType] ? <Spinner color='white' /> : 'Resolve'}</Button>
												</div>
											</div>
											<div className='document-pendency-body'>
												<ul className='list'>
													<li>
														<p>Remarks</p>
													</li>
													<li>
														<span>{item.Remarks || '-'}</span>
													</li>
												</ul>
											</div>
										</div>
									)
								}
								else if (item.PendencyType === 'PF Pending') {
									return (
										<div className='document-pendency'>
											<div className='pendency-head'>
												<h4>PF Pendency</h4>
												<div className='pendency-resolve-btns'>
													<Button className='docspoint-btn resolve-btn' disabled={dynamicLoader[item.PendencyType]} onClick={() => confirmationDialog(item.PendencyType, item.PendencyTypeDisplayName)}>{dynamicLoader && dynamicLoader[item.PendencyType] ? <Spinner color='white' /> : 'Resolve'}</Button>
												</div>
											</div>
											<div className='document-pendency-body'>
												<ul className='list'>
													<li>
														<p>Status</p>
													</li>
													<li>
														<Button onClick={() => setState({
															dialogControls: {
																open: true,
																size: 70,
																renderComponent: <iframe src={item.PFLink} height={"100%"} />,
																title: 'Proposal Form'
															}
														})}>Pending</Button>
													</li>
												</ul>
											</div>
										</div>
									)
								}
							})}
						</Grid>
					}
					{renderCreateNewPendencyOption().length ?
						<Grid item md={5} sm={5} xs={12}>
							<div className='create-pendency-box'>
								<Button className='create-new-btn' onClick={() => setState({ createNewPendency: !state.createNewPendency })}>
									{!state.createNewPendency ? <><i className='fa fa-plus'></i> Create New </> : <><i className='fa fa-close'></i> Close </>}
								</Button>
								{state.createNewPendency && <div className='create-pendency-view'>
									<div className='create-pendency-head'>
										<h4>Create Pendency</h4>
									</div>
									<div className='create-pendency-body'>
										<Grid container spacing={2}>
											<Grid item md={12} xs={12}>
												<FormControl variant="outlined" fullWidth className='form-control'>
													<InputLabel htmlFor="outlined-age-native-simple">Pendency Type</InputLabel>
													<Select
														value={state.selectedNewPendency?.PendencyType || ""}
														onChange={handleChange}
														label="Pendency Type"
														type=''
														inputProps={{
															name: 'PendencyType',
															id: 'outlined-age-native-simple',
														}}
													>
														<MenuItem>Select</MenuItem>
														{renderCreateNewPendencyOption().map(item => {
															return <MenuItem value={item.PendencyType}>{item.PendencyType}</MenuItem>
														})}
													</Select>
												</FormControl>
												{Boolean(state.selectedNewPendency?.PendencyType) && conditionalRendering()}
											</Grid>
											{Boolean(state.selectedNewPendency?.PendencyType) &&
												<>
													<Grid item md={6} xs={6}>
														<Button className='save-btn' disabled={state.savingSelectedNewPendency} onClick={saveNewPendency}>{state.savingSelectedNewPendency ? <Spinner color='white' /> : 'Save'}</Button>
													</Grid>
													{state.selectedNewPendency?.PendencyType === 'Document Pending' && <Grid item md={6} xs={6}>
														<Button className='save-btn' disabled={state.savingSelectedNewPendency} onClick={() => fetchDynamicPendencyUrl({ Type: state.selectedNewPendency?.PendencyType, InspectionId: state.selectedNewPendency?.PendencyType }, 'Document Pending')}>View Details</Button>
													</Grid>}
												</>
											}
										</Grid>
									</div>
								</div>
								}
							</div>
						</Grid>
						:
						<></>}
				</Grid>

				{state.dialogControls && state.dialogControls.open &&
					<DialogFromRight
						open={state.dialogControls.open}
						handleClose={() => { fetchPendencyDetails(); setState({ dialogControls: null }) }}
						title={state.dialogControls.title}
						size={state.dialogControls.size}
					>
						{state.dialogControls.renderComponent}
					</DialogFromRight>
				}
			</div>
			{/* <Button className="view-logs-btn" onClick={() => setState({ viewLogs: !state.viewLogs })}>View Logs</Button>
			{state.viewLogs && <PendencyLogs />} */}
		</>
	)
}

export default DocumentPendency
