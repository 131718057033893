import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControlLabel,
  Radio,
  Checkbox,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { convertToIsoFormate } from "../../shared/methods/methods";


const CustomerEmail = ({
  ind,
  selectedRadioVal,
  item,
  selectedCheckboxVal,
  errorIdList
}) => {
  const [elememntClassName, setElememntClassName] = useState('')
  const setErrorCSS =(errList)=>{
    if(errList && errList.length>0){
      if(errList.includes(ind)){
        setElememntClassName('error-msg')
      }
      else{
        setElememntClassName('remove-error-msg')
      }
    }
    else{
      setElememntClassName('remove-error-msg')
    }
  }
  useEffect(()=>{
    setErrorCSS(errorIdList)
  },[errorIdList])

  return (
    <div className="pdf-generate-view">
      <>
        <Grid container>
          <Grid item md={12} xs={12}>
            {/* <span className="send-to-insurer">
            <Button className="attach-btn">
              <FormControlLabel
                value={ind}
                control={<Checkbox />}
                multiple
                checked={selectedCheckboxVal == ind}
              />
              </Button>
              <Button className="attach-btn"><i class="fa fa-paperclip" aria-hidden="true"></i></Button>
            </span> */}
            <>
              <ExpansionPanel defaultExpanded={false}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon className="arrow" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={elememntClassName}
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid md={12} xs={12}>
                      <p className="subject-box">
                       <strong>Subject: {item.Subject}</strong>
                       <span className="attach-btn"> {Array.isArray(item.MailAttachments) && item.MailAttachments.length>0 && <i class="fa fa-paperclip" aria-hidden="true"></i>}</span>
                        <span className="date-time">
                          {convertToIsoFormate(item.DisplayDate)}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: "block" }}>
                  <Grid container justifyContent="center" alignItems="center">
                   <Grid md={12} xs={12}>
                      {item.From && (
                        <p className="subject-box">
                          <span className="from-to">
                            From: {item.DisplaySenderName} ({item.From})
                          </span>
                        </p>
                      )}
                      <div
                        id="Mail-body"
                        dangerouslySetInnerHTML={{ __html: item.Body }}
                        ></div>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* <div className="send-to-insurer">
                <Button className="attach-btn">
                  <FormControlLabel
                    value={ind}
                    control={<Checkbox />}
                    multiple
                    checked={selectedCheckboxVal == ind}
                  />
                </Button>
                <Button className="attach-btn"><i class="fa fa-paperclip" aria-hidden="true"></i></Button>
              </div> */}
            </>
          </Grid>
        </Grid>
      </>
    </div>
  );
};

export default CustomerEmail;
