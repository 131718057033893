import { useEffect, useState, React } from "react";
import { Link, ListItem, Grid, List, Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import SearchRadio from "./Components/SearchRadio";
import { GET, POST } from "../services/apiServices";
import { GetTicketCount, GetTicketData, GetTicketDataExport, GetTicketDetails } from "../constants/apiUrls";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import LoadDataList from "./Components/LoadDataList";
import FullScreenDialog from "../Components/Topbar/components/CreateTicketDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    background: "#fff",
  },
}));

const Dashboard = ({ isActionsAllowed = true }) => {
  const classes = useStyles();
  const [ticketCount, setTicketCount] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [isCreateTicketDialogOpen, setCreateTicketDialogOpen] = useState(null);
  const [sourceList, setSourceList] = useState(null);
  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    if (!ticketCount) {
      getTicketCount();
    }
  }, [ticketCount]);

  useEffect(() => {
    if (!sourceList) {
      getSourceList();
    }
  }, [sourceList]);

  const getTicketCount = () => {
    const params = {
      DashboardType: 1,
      ProductId: -1,
      IsEsc: 0,
    };
    GET(
      `${GetTicketCount}/${params.DashboardType}/${params.ProductId}/${params.IsEsc}`
    )
      .then((res) => {
        // console.log(res);
        if (res && res.Data) {
          setTicketCount(res.Data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getSourceList = () => {
    GET(`${GetTicketDetails}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const {
            ticketSources,
            suppliersList
          } = response.Data;

          setSourceList(ticketSources);
          setSupplierList(suppliersList)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getDashboardData = (type) => {
    setTicketData(null);
    const payload = {
      "IsUpdatedOn": 0,
      "FilterString": "",
      "FromDate": 1635928641637,
      "ToDate": 1635928641637,
      "Source": "",
      "SubSource": "",
      "ProductID": 0,
      "SupplierID": 0,
      "CBRequest": 0,
      "StatusID": 0,
      "SubStatusID": 0,
      "TATFilter": 0,
      "InsurerStatusID": 0,
      "STP": -1,
      "IsEsc": 0,
      //"UserId": "8496",
      "Type": type,
      "UserType": 1,
      "ProductIDs": "-1",
      "SendToReport": ""
    }
    POST(GetTicketData, payload)
      .then((res) => {
        console.log(res);
        if (res && res.Data && res.Data.length > 0) {
          setTicketData(res.Data);
        }
      })
      .catch(err => console.log(err))
  }

  // const getDashboardDataExport = () => {
  //   const payload = {
  //     "IsUpdatedOn": 0,
  //     "FilterString": "",
  //     "FromDate": 1635928641637,
  //     "ToDate": 1635928641637,
  //     "Source": "",
  //     "SubSource": "",
  //     "ProductID": 0,
  //     "SupplierID": 0,
  //     "CBRequest": 0,
  //     "StatusID": 0,
  //     "SubStatusID": 0,
  //     "TATFilter": 0,
  //     "InsurerStatusID": 0,
  //     "STP": -1,
  //     "IsEsc": 0,
  //     //"UserId": "8496",
  //     "Type": 1,
  //     "UserType": 1,
  //     "ProductIDs": "-1",
  //     "SendToReport": ""
  //   }
  //   POST(GetTicketDataExport, payload)
  //     .then((res) => {
  //       console.log(res);
  //       if(res && res.Data){
  //         const linkSource = `data:application/xlsx;base64,${res.Data}`;
  //         const downloadLink = document.createElement("a");
  //         const fileName = "ticket_data.xlsx";

  //         downloadLink.href = linkSource;
  //         downloadLink.download = fileName;
  //         downloadLink.click();
  //       }
  //     })
  //     .catch(err => console.log(err))
  // }

  const openCreateTicket = (event) => {
    if (event) {
      event.persist();
    }
    setCreateTicketDialogOpen(true)
  }

  return (
    <div className={classes.root}>
      <div className="dashboard-summary">
        <div className="dashboard-topbar">
          <Grid container justify="center">
            <Grid item md={6} xs={12}>
              <h2 id="ticketSummary">Ticket Summary</h2>
            </Grid>
            <Grid item md={6} xs={12} className="text-right">
              <Button id="createTickets" className="create-ticket" onClick={openCreateTicket}> <img src="/images/create_ticket_btn.svg" alt="create ticket" /> Create Tickets</Button>
              {/* <List>
                <ListItem>
                  <Link>
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link>
                    <i
                      className="fa fa-user-secret secret"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link>
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    href="https://cctechmobile.policybazaar.com/serviceapps/Home/NotificationCenter/eyJVc2VySWQiOiI4NTU2IiwiSXNBZ2VudCI6dHJ1ZSwiVXNlck5hbWUiOiJHYXVyYXYgIEJhaHVndW5hIiwiRW1wbG95ZWVJZCI6IkJFVDAyMjc0In0"
                    target="_blank"
                  >
                    <i className="fa fa-line-chart" aria-hidden="true"></i>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link>
                    <i className="fa fa-file" aria-hidden="true"></i>
                  </Link>
                </ListItem>
              </List> */}
            </Grid>
          </Grid>
        </div>
        <div className="ticket-summary-list">
          <List>
            <ListItem onClick={() => getDashboardData(1)}>
              <div className="new-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>New Ticket</span>
                <p id="dashboardNewCase">{ticketCount ? ticketCount.NewCase : null}</p>
              </div>
            </ListItem>
            <ListItem onClick={() => getDashboardData(2)}>
              <div className="due-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>Over Due</span>
                <p id="dashboardOverDue">{ticketCount ? ticketCount.OverDue : null}</p>
              </div>
            </ListItem>
            <ListItem onClick={() => getDashboardData(3)}>
              <div className="duetoday-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>Due Today</span>
                <p id="dashboardDueToday">{ticketCount ? ticketCount.DueToday : null}</p>
              </div>
            </ListItem>
            <ListItem onClick={() => getDashboardData(4)}>
              <div className="Unassigned-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>Unassigned</span>
                <p id="dashboardUnAssignedCase">{ticketCount ? ticketCount.UnAssignedCase : null}</p>
              </div>
            </ListItem>
            <ListItem onClick={() => getDashboardData(5)}>
              <div className="open-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>Open</span>
                <p id="dashboardOpenCase">{ticketCount ? ticketCount.OpenCase : null}</p>
              </div>
            </ListItem>
            {
              isActionsAllowed
              &&
              <ListItem onClick={() => getDashboardData(6)}>
                <div className="revert-ticket">
                  <em>
                    <i className="fa fa-ticket" aria-hidden="true"></i>
                  </em>
                  <span>Insurer Reverts</span>
                  <p id="dashboardInsurerRevert">{ticketCount ? ticketCount.InsurerRevert : null}</p>
                </div>
              </ListItem>
            }
            <ListItem onClick={() => getDashboardData(7)}>
              <div className="cust-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>Cust Reply</span>
                <p id="dashboardCustReply">{ticketCount ? ticketCount.CustReply : null}</p>
              </div>
            </ListItem>
            <ListItem onClick={() => getDashboardData(8)}>
              <div className="reopen-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>ReOpen</span>
                <p id="dashboardReOpen">{ticketCount ? ticketCount.ReOpen : null}</p>
              </div>
            </ListItem>
            <ListItem onClick={() => getDashboardData(9)}>
              <div className="resolved-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>Resolved Today</span>
                <p id="dashboardResolvedToday">{ticketCount ? ticketCount.ResolvedToday : null}</p>
              </div>
            </ListItem>
            <ListItem onClick={() => getDashboardData(10)}>
              <div className="escalation-ticket">
                <em>
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                </em>
                <span>Escalation</span>
                <p id="dashboardEscalation">{ticketCount ? ticketCount.Escalation : null}</p>
              </div>
            </ListItem>
          </List>
        </div>
        {/* <SearchRadio 
            getDashboardData={getDashboardData}
            //getDashboardDataExport={getDashboardDataExport}
            isActionsAllowed = {isActionsAllowed}
        /> */}
        <LoadDataList
          ticketData={ticketData}
        />
      </div>
      <FullScreenDialog
        isCreateDialogOpen={isCreateTicketDialogOpen}
        setCreateDialog={setCreateTicketDialogOpen}
        sourceList={sourceList}
        TicketDetailsID={null}
        RefObjectId={null}
        supplierList={supplierList}
      />
    </div>
  );
};

export default Dashboard;
