import { useEffect, useState, React } from "react";
import { Button,FormControl,TextField, FormHelperText } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { exportDashboardData_method, fetchDashboardData_method } from "../../../shared/methods/apiMethods";
import { useSnackbar } from "notistack";
import CustomBackdrop from "../../Components/CustomBackdrop";
import { HashLoader } from 'react-spinners';
import ED_DATA from "./ED_Data";
import { ED_SIMPLE_SEARCH_TYPE } from "../../../Config";
import { setAdminSimpleSearchData } from "../../../redux/actions/pbDashboard_action";


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
}));

const ED_SIMPLE_SEARCH = () => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const simpleSearchValue = useSelector(state=> state && state.pbDashboard && state.pbDashboard.adminSimpleSearch && state.pbDashboard.adminSimpleSearch.FilterString);
  const dispatch = useDispatch()
  const [textValue, setTextValue] = useState(simpleSearchValue);
  const [textValueTouched, setTextValueTouched] = useState(false);
  const [dataToRender, setDataToRender] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [screenRendered, setScreenRendered] = useState(false);

  const handleChange = (event) =>{
    let { value } = event.target;
    setTextValue(value);
  }

  useEffect(()=>{
    if(simpleSearchValue && !screenRendered){
      setTextValueTouched(true);
      handleSearch(true);
      setScreenRendered(true);
    }
  },[simpleSearchValue]);

  const showMessage =(message, varient) =>{
    return enqueueSnackbar(message, {variant: varient, onClick: () => {
        closeSnackbar();
      },
    });
  }

  const generatePayload = () =>{
    return {
      "IsUpdatedOn": "0",
      "FilterString": textValue,
      "FromDate": Date.now(),
      "ToDate": Date.now(),
      "Source": "",
      "SubSource": "",
      "ProductID": 0,
      "SupplierID": 0,
      "CBRequest": 0,
      "StatusID": 0,
      "SubStatusID": 0,
      "TATFilter": 0,
      "InsurerStatusID": 0,
      "STP": -1,
      "IsEsc": 0,
      "UserId": "90393",
      "Type": ED_SIMPLE_SEARCH_TYPE,
      "UserType": 1,
      "ProductIDs": ""
    }
  }

  const validate = () =>{
    setTextValueTouched(true);
    if(!textValue){
      return false;
    }
    else return true;
  }

  const handleSearch = (e, directSearch= false) =>{
      if(!directSearch){
        if(!validate()){ return }
      }
      console.log('direct serach', directSearch)
      let url = '/GetDashboardData';
      let payload = generatePayload();
      if(!directSearch){dispatch(setAdminSimpleSearchData(textValue))};
      let callback = (value) =>{
        setDataToRender(value);
        setDataLoading(false);
      }
      setDataLoading(true);
      fetchDashboardData_method(url, payload, callback, showMessage);
  }


  const exportData =() =>{
    if(!validate()){ return };
    let url = '/GetDashboardDataExport';
    let payload = generatePayload();
    setDataLoading(true);
    let cb= () =>{
      console.log('here')
      return setDataLoading(false);
    }
    return exportDashboardData_method(url, payload, cb, 'SimpleSearch_TicketList');
  }

  const clearField = function(){
    setTextValue("");
    dispatch(setAdminSimpleSearchData(null));
    setDataToRender(null);
  }

  return (
    <div className={classes.root}>
      {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white'/></CustomBackdrop> : null}
      <div className="pbdashboard-simple-seach">
        {/* <h4>
          Simple Search
          <span className="info-btn">
          <InfoIcon/>
            <em className="info-msg">TicketID, PhoneNo., LeadID, EmailID, RegistrationNo., PolicyNo., ApplicationNo. <span></span></em>
              </span>
        </h4> */}
          <ul>
          {/* <li className="search-box">
            <FormControl className="form-control">
              
              <TextField
                  id="outlined-full-width"
                  placeholder="Search.."
                  fullWidth
                  variant="outlined"
                  value={textValue}
                  onChange={handleChange}
                  error={textValueTouched && !textValue}
              />
              <Button className="search-btn" onClick={handleSearch}>Go</Button>
              {textValueTouched && !textValue && <FormHelperText className="error-msg">Enter value to continue</FormHelperText>}
            </FormControl>
          </li> */}
          <li className="search-box">
            <FormControl className="form-control">
              <TextField
                  id="outlined-full-width"
                  placeholder="Search.."
                  fullWidth
                  variant="outlined"
                  value={textValue}
                  onChange={handleChange}
                  error={textValueTouched && !textValue}
              />
              {textValueTouched && !textValue && <FormHelperText className="error-msg">Enter value to continue</FormHelperText>}
            </FormControl>
          </li>
          <li>
          <Button className="search-btn" onClick={handleSearch}>Search</Button>
              
          </li>
          <li>
            <Button className="export-btn" onClick={exportData}>Export</Button>
          </li>
          <li>
            <Button className="clear-btn" onClick={clearField}>Clear</Button>
          </li>
          </ul>
        <div>
       </div>
          {dataToRender && Array.isArray(dataToRender) && <ED_DATA dataToRender={dataToRender}/>}
       </div>
    </div>
  );
};

export default ED_SIMPLE_SEARCH;
