import React, { useState, useEffect } from "react";
import TicketActivity from "./Components/TicketActivity/TicketActivity";
import SingleTicket from "./Components/TicketsTimelines/SingleTicketTimeline";
import TicketLogs from "./Components/TicketsTimelines/TicketsLogs";
import ViewComments from "./Components/TicketActivity/ViewComments";
import TicketActivityLogs from "./Components/TicketActivity/TicketActivityLogs";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Endorsement from "../Endorsement/Endorsement";
import TicketDetailBox from "../TicketDetail/TicketDetail";
import TicketEsclationBox from "../TicketEscalation/TicketEsclation";
import Topbar from "../Topbar/Topbar";
import { GET, POST } from "../../services/apiServices";
import { GetEndorsementDetails } from "../../constants/apiUrls";
import { isEndorsementEligible, TWO_WHEELER, CAR, HEALTH, ENABLE_CUSTOMER_PENDENCY_DIALOG, TERM, INVESTMENT, PERSONAL_ACCIDENT, OPEN_ENDORSEMENTWINDOW_DIRECTLY } from "../../Config";
import { CustomerPendencyLogs } from "./Components/CustomerPendencyLogs";
import InsurerActivity from "./Components/InsurerActivity/InsurerActivity";
import { useDispatch, useSelector } from "react-redux";
import { navigateToTab, setConditionalRenderNewCustomerPendency } from "../../redux/actions/utlity_actions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    backgroundColor: "#0065FF",
    borderRadius: "8px",
    height: "2px",
  },
  chatHistoryView: {
    position: "fixed",
    bottom: "10%",
    right: "2%",
    zIndex: "999",
  },
}));

export default function TicketTimeline({
  issueName,
  ticketLogs,
  templateList,
  oldTicketDetails,
  isTicketTimelineLoading,
  isLogsLoading,
  filterCounts,
  AccessTypeVal,
  setRefresh,
  viewtype,
  showNewTabs,
  formValues,
  setFormValues,
  isTicketDetailEditable,
  setIsTicketDetailEditable,
  setTemplateList,
  setSelectFormOptions,
  selectFormOptions,
  CurrentLevel,
  errors,
  setErrors,
  setIsEsclationLoading,
  setEsclationInfo,
  setEsclationLevelTime,
  IsEscalated,
  isTopMgmt,
  isHCS,
  isSocial,
  isCGO,
  isIRDA,
  IsReopen,
  refresh,
  viewOnly,
  esclationInfo,
  UpdateL0ToL1Api,
  isEsclationLoading,
  EsclationLevelTime,
  sourceList,
  masters,
  EndorsementLink,
  PartnerLevel,
  resetTab,
  setResetTab,
  isValidForSME,
  setIsValidForSME,
  RootCauses,
  setRootCauses,
  goToTemplate,
  setGoToTemplate,
  IsJAG,
  communications,
}) {
  const forceNavigationToTab = useSelector(state => state && state.Utility && state.Utility.navigateToTab);
  const dispatch = useDispatch()
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [showLogs, setShowLogs] = useState(false);
  const [toggleFilterBtn, setToggleFilterBtn] = useState({
    email: false,
    all: true,
    insurerReply: false,
    esclation: false,
    customerReply: false,
    logs: false,
    IB: false,
    C2C: false,
    ob: false,
    myAccount: false,
    attachments: false,
    pbReply: false,
  });

  const [toggleFilterLogsBtn, setToggleFilterLogsBtn] = useState({
    all: true,
    assignment: false,
    statusSubStatus: false,
    issueSubIssue: false,
    followUp: false,
    leadId: false,
    insurerStatus: false,
  });

  const [endorsementDetails, setEndorsementDetails] = useState(null);
  const [tableDetails, setTableDetails] = useState(null);
  const [endorsementHistory, setEndorsementHistory] = useState(null);
  const [raiseEndorsementURL, setRaiseEndorsementURL] = useState("");

  useEffect(() => {
    if (resetTab == 0) {
      setValue(0);
    }
  }, [resetTab]);
  const fetchEndorsementDetail = (oldTicketDetails) =>{
    const payload = {
      EndorsementID: Number(oldTicketDetails.EndorsementID) || 0, //10070,
      BookingID: oldTicketDetails.LeadID || 0, //43362530,
      TicketNumber: oldTicketDetails.TicketDetailsID || "",
      ProductID: oldTicketDetails.ProductId || 0,
      TicketID: oldTicketDetails.TicketId || 0,
      CustomerID: oldTicketDetails.CustomerId || 0,
    };
    POST(`${GetEndorsementDetails}`, payload)
      .then((res) => {
        if (res && res.Data && res.ErrorCode == 0) {
          setRaiseEndorsementURL(
            res.Data && res.Data.RaiseNewEndorsementURL
              ? res.Data?.RaiseNewEndorsementURL
              : ""
          );
          if (res.Data.endorsements) {
            setEndorsementDetails(res.Data.endorsements[0]);
            setTableDetails(res.Data.endorsements[0].endorsedFields);
          }
          if (
            res &&
            res.Data &&
            res.Data.endorsementHistory &&
            res.Data.endorsementHistory.length > 0
          ) {
            setEndorsementHistory(res.Data.endorsementHistory[0]);
          }
        }
      })
      .catch((e) => console.log(e));
} 
useEffect(() => {
  oldTicketDetails && oldTicketDetails.TicketDetailsID && isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails?.SupplierId, oldTicketDetails?.InvestmentTypeID) && !OPEN_ENDORSEMENTWINDOW_DIRECTLY.includes(oldTicketDetails.ProductId) && fetchEndorsementDetail(oldTicketDetails);
}, [oldTicketDetails]);

  const handleFilter = (filterBtnName) => {
    setToggleFilterBtn({
      email: false,
      all: false,
      insurerReply: false,
      esclation: false,
      customerReply: false,
      logs: false,
      IB: false,
      C2C: false,
      ob: false,
      myAccount: false,
      attachments: false,
      pbReply: false,
      [filterBtnName]: true,
    });
  };

  const handleFilterLogs = (filterBtnName) => {
    setToggleFilterLogsBtn({
      all: false,
      assignment: false,
      statusSubStatus: false,
      issueSubIssue: false,
      followUp: false,
      leadId: false,
      insurerStatus: false,
      [filterBtnName]: true,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (resetTab != 0 && resetTab != undefined) {
      setResetTab(newValue);
    }
    console.log(newValue)
  };

  useEffect(() => {
    // debugger;
    if (goToTemplate) {
      if (showNewTabs) {
        handleChange(null, 1);
      } else {
        handleChange(null, 0);
      }
    }
  }, [goToTemplate]);

  useEffect(()=>{
    if(forceNavigationToTab !== null && forceNavigationToTab >=0 ){
      handleChange(null, forceNavigationToTab)
    }
  },[forceNavigationToTab])

  useEffect(()=>{
    if(value !== forceNavigationToTab){
      dispatch(navigateToTab(null));
      dispatch(setConditionalRenderNewCustomerPendency(false))
    }
  },[value])

  const renderTabsFor_showNewTabs = (component) => {
    let tabs = [
      {
        Label: 'Ticket Details',
        show: true,
        component: <>
          <TicketDetailBox
            goToTemplate={goToTemplate}
            setGoToTemplate={setGoToTemplate}
            formValues={formValues}
            setFormValues={setFormValues}
            isTicketDetailEditable={isTicketDetailEditable}
            setIsTicketDetailEditable={setIsTicketDetailEditable}
            oldTicketDetails={oldTicketDetails}
            templateList={templateList}
            setTemplateList={setTemplateList}
            setSelectFormOptions={setSelectFormOptions}
            selectFormOptions={selectFormOptions}
            CurrentLevel={CurrentLevel}
            errors={errors}
            setErrors={setErrors}
            setIsEsclationLoading={setIsEsclationLoading}
            setEsclationInfo={setEsclationInfo}
            setEsclationLevelTime={setEsclationLevelTime}
            IsEscalated={IsEscalated}
            isTopMgmt={isTopMgmt}
            isHCS={isHCS}
            isSocial={isSocial}
            isCGO={isCGO}
            isIRDA={isIRDA}
            IsReopen={IsReopen}
            viewOnly={viewOnly}
            refresh={refresh}
            setRefresh={setRefresh}
            viewtype={viewtype}
            showNewTabs={showNewTabs}
            handleChangeTab={handleChange}
            endorsementDetails={endorsementDetails}
            raiseEndorsementURL={raiseEndorsementURL}
            isValidForSME={isValidForSME}
            setIsValidForSME={setIsValidForSME}
            RootCauses={RootCauses}
            setRootCauses={setRootCauses}
            communications={communications}
          />
          <TicketEsclationBox
            esclationInfo={esclationInfo}
            UpdateL0ToL1Api={UpdateL0ToL1Api}
            isEsclationLoading={isEsclationLoading}
            StatusID={oldTicketDetails.StatusID}
            IssueName={oldTicketDetails.IssueName}
            SubIssueName={oldTicketDetails.SubIssueName}
            AssignedTo={oldTicketDetails.AssignedTo}
            EsclationLevelTime={EsclationLevelTime}
            setEsclationLevelTime={setEsclationLevelTime}
            ParentTicketID={oldTicketDetails.ParentTicketID}
            viewOnly={viewOnly}
            viewtype={viewtype}
          />
          <ViewComments
            oldTicketDetails={oldTicketDetails}
            templateList={templateList}
            setRefresh={setRefresh}
            viewtype={viewtype}
          /></>
      },
      {
        Label: 'Activity',
        show: true,
        component: <>
          <TicketActivity
            showLogs={showLogs}
            setShowLogs={setShowLogs}
            filterCounts={filterCounts}
            oldTicketDetails={oldTicketDetails}
            templateList={templateList}
            toggleFilterBtn={toggleFilterBtn}
            setToggleFilterBtn={setToggleFilterBtn}
            handleFilter={handleFilter}
            ticketLogs={ticketLogs}
            setRefresh={setRefresh}
            viewtype={viewtype}
          />
          <SingleTicket
            goToTemplate={goToTemplate}
            setGoToTemplate={setGoToTemplate}
            showLogs={showLogs}
            issueName={issueName}
            templateList={templateList}
            oldTicketDetails={oldTicketDetails}
            isTicketTimelineLoading={isTicketTimelineLoading}
            ticketLogs={ticketLogs}
            toggleFilterBtn={toggleFilterBtn}
            AccessTypeVal={AccessTypeVal}
            viewtype={viewtype}
            isValidForSME={isValidForSME}
          /></>
      },
      {
        Label: 'Logs',
        show: viewtype === '0' ? true : false,
        component: <>
          <TicketActivityLogs
            toggleFilterLogsBtn={toggleFilterLogsBtn}
            handleFilterLogs={handleFilterLogs}
          />
          <ViewComments
            oldTicketDetails={oldTicketDetails}
            templateList={templateList}
            setRefresh={setRefresh}
            viewtype={viewtype}
          />
          {viewtype == 0 && (
            <TicketLogs
              ticketLogs={ticketLogs}
              isLogsLoading={isLogsLoading}
              toggleFilterLogsBtn={toggleFilterLogsBtn}
            />
          )}</>
      },
      // {
      //   Label: 'Endorsement',
      //   show: isEndorsementEligible(oldTicketDetails.ProductId) && !oldTicketDetails.ParentTicketID && oldTicketDetails.EndorsementID > 0 && ![HEALTH].includes(oldTicketDetails.ProductId) ? true : false,
      //   component: <Endorsement
      //     endorsementDetails={endorsementDetails}
      //     tableDetails={tableDetails}
      //     endorsementHistory={endorsementHistory}
      //   />
      // },
      {
        Label: 'Customer Pendency Logs',
        show: ENABLE_CUSTOMER_PENDENCY_DIALOG.includes(oldTicketDetails && oldTicketDetails.ProductId) ? true : false,
        component: <CustomerPendencyLogs />
      }
    ]
    let tabArr = tabs.filter(item => item.show).map((item2, index) => {
      return <Tab label={item2.Label} {...a11yProps(index)} />
    })
    let panelArr = tabs.filter(item => item.show).map((item2, index) => {
      return <TabPanel value={value} index={index} id={item2.Label.replaceAll(" ","") || item2.Label}>{item2.component}</TabPanel>
    })
    return component === 'tabs' ? tabArr : panelArr;
  }

  const renderTabsFor_withoutNewTabs = (component) => {
    let tabs = [
      {
        Label: 'Activity',
        show: true,
        component: <>
          <TicketActivity
            showLogs={showLogs}
            setShowLogs={setShowLogs}
            filterCounts={filterCounts}
            oldTicketDetails={oldTicketDetails}
            templateList={templateList}
            toggleFilterBtn={toggleFilterBtn}
            setToggleFilterBtn={setToggleFilterBtn}
            handleFilter={handleFilter}
            ticketLogs={ticketLogs}
            setRefresh={setRefresh}
            viewtype={viewtype}
          />
          <SingleTicket
            goToTemplate={goToTemplate}
            setGoToTemplate={setGoToTemplate}
            showLogs={showLogs}
            issueName={issueName}
            templateList={templateList}
            oldTicketDetails={oldTicketDetails}
            isTicketTimelineLoading={isTicketTimelineLoading}
            ticketLogs={ticketLogs}
            toggleFilterBtn={toggleFilterBtn}
            AccessTypeVal={AccessTypeVal}
            viewtype={viewtype}
            isValidForSME={isValidForSME}
          /></>
      },
      {
        Label: 'Logs',
        show: viewtype == 0 ? true : false,
        component: <>
          <TicketActivityLogs
            toggleFilterLogsBtn={toggleFilterLogsBtn}
            handleFilterLogs={handleFilterLogs}
          />
          <ViewComments
            oldTicketDetails={oldTicketDetails}
            templateList={templateList}
            setRefresh={setRefresh}
            viewtype={viewtype}
          />
          {viewtype == 0 && (
            <TicketLogs
              ticketLogs={ticketLogs}
              isLogsLoading={isLogsLoading}
              toggleFilterLogsBtn={toggleFilterLogsBtn}
            />
          )}</>
      },
      {
        Label: 'Customer Pendency Logs',
        show: ENABLE_CUSTOMER_PENDENCY_DIALOG.includes(oldTicketDetails && oldTicketDetails.ProductId) ? true : false,
        component: <CustomerPendencyLogs />
      },
      {
        Label: 'Insurer Activity',
        show: true,
        component:  <InsurerActivity
        goToTemplate={goToTemplate}
        setGoToTemplate={setGoToTemplate}
        showLogs={showLogs}
        issueName={issueName}
        templateList={templateList}
        oldTicketDetails={oldTicketDetails}
        isTicketTimelineLoading={isTicketTimelineLoading}
        ticketLogs={ticketLogs}
        toggleFilterBtn={toggleFilterBtn}
        AccessTypeVal={AccessTypeVal}
        viewtype={viewtype}
        isValidForSME={isValidForSME}
      />
      }
    ]
    let tabArr = tabs.filter(item => item.show).map((item2, index) => {
      return <Tab label={item2.Label} {...a11yProps(index)} id={item2.Label.replaceAll(" ","") || item2.Label} />
    })
    let panelArr = tabs.filter(item => item.show).map((item2, index) => {
      return <TabPanel value={value} index={index}>{item2.component}</TabPanel>
    })
    return component === 'tabs' ? tabArr : panelArr;
  }

  return (
    <div className="ticket-timeline">
      {console.log(value)}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          classes={{
            indicator: classes.indicator,
          }}
        >
          {showNewTabs ? renderTabsFor_showNewTabs('tabs') : renderTabsFor_withoutNewTabs('tabs')}
        </Tabs>
      </AppBar>
      <Topbar
        TicketId={oldTicketDetails.TicketId}
        IsReopen={oldTicketDetails.IsReopen}
        sourceList={sourceList}
        IsEscalated={oldTicketDetails.IsEscalated}
        LeadId={oldTicketDetails.LeadID}
        setFormValues={setFormValues}
        TicketDetailsID={oldTicketDetails.TicketDetailsID}
        BlockAutoComm={oldTicketDetails.BlockAutoComm}
        TATEscalation={oldTicketDetails.TATEscalation}
        StatusID={oldTicketDetails.StatusID}
        masters={masters}
        RefObjectId={oldTicketDetails.RefObjectId}
        isBlockAutoCommVisible={oldTicketDetails.isBlockAutoCommVisible}
        EndorsementLink={EndorsementLink}
        Source={oldTicketDetails.Source}
        isTopMgmt={oldTicketDetails.HighComplaint > 0 ? true : false}
        isHCS={oldTicketDetails.EscLevel1 === "Yes" ? true : false}
        isSocial={
          oldTicketDetails.Source === "Social" || oldTicketDetails.IsSocial
            ? true
            : false
        }
        isCGO={oldTicketDetails.EscLevel2 === "Yes" ? true : false}
        isIRDA={oldTicketDetails.Escalation == "Yes" ? true : false}
        ParentTicketID={oldTicketDetails.ParentTicketID}
        ProductID={oldTicketDetails.ProductId}
        SubSource={oldTicketDetails.SubSource}
        AccessTypeVal={AccessTypeVal}
        PartnerLevel={PartnerLevel}
        viewOnly={viewOnly}
        viewtype={viewtype}
        showNewTabs={showNewTabs}
        isValidForSME={isValidForSME}
        IsJAG={IsJAG}
        RenewalLeadId={oldTicketDetails.RenewalLeadId ? true : false}
      />
      {showNewTabs ? renderTabsFor_showNewTabs('panel') : renderTabsFor_withoutNewTabs('panel')}
    </div>
  );
}
