import { GET, POST } from "../../services/apiServices";
import * as type from "../types/types";
import { useSnackbar } from 'notistack';
import ErrorMessages  from '../../shared/constants';
import { isCommunicationEligible } from "../../Config";
import { Toast } from "../../shared/components";


export function setTicketDetails(data){
  console.log('TD Redux set')
  return {
    type: type.SET_TICKET_DETAILS,
    payload: data
  }
}

export function setTicketCommunication(data){
  console.log('Comm Data set')
  return{
    type: type.SET_TICKET_COMM_DATA,
    payload : data
  }
}

export const setTemplateList = (data) => {
  return{
    type: type.SET_TEMPLATE_LIST,
    payload : data
  }
}

export const setTicketLoading = (data) => {
  return {
    type: type.SET_TICKET_LOADING,
    payload : data
  }
}

export const setInsurerTemplateList = (data) =>{
  return {
    type: type.SET_INSURER_TEMPLATE_LIST,
    payload: data
  }
} 
export const fetchLatestTicketdetails = (payload) => dispatch => {
  let { RefObjectId, TicketDetailsID } = payload
  console.log('obid--->', RefObjectId, TicketDetailsID)
  let url = `GetTicketDetailsByTicketID/${RefObjectId}/${TicketDetailsID}`;
  GET(url).then((res) => {
    if (res && res["ErrorCode"]==0) {
      dispatch(setTicketDetails(res["Data"]));
    } else {
    }
  })
    .catch((err) => {
      console.log("Error", err);
    });
};


export const fetchLatestTicketComm = (payload) =>dispatch =>{
  let {RefObjectId, TicketDetailsID, Source } = payload
  let urlPrefix = Source == 'WeCare' ? 'GetTicketInsurerComm' : 'GetTicketComm'
  let url = `${urlPrefix}/${RefObjectId}/${TicketDetailsID}`;
  GET(url).then((res) => {
    if (res && res["ErrorCode"]==0) {
      var sortedArray
      if(res.Data && res.Data.length>1){
        sortedArray=res.Data.sort((a,b)=>new Date(b.CreatedOn)- new Date(a.CreatedOn))
      }
      else sortedArray=res.Data
      dispatch(setTicketCommunication(sortedArray));
    } else {
    }
  })
    .catch((err) => {
      console.log("Error", err);
    });
};

export const downloadPolicyCopy = (payload, cb) => dispatch=> {
  GET(`GetDocURLEnc/${payload}`)
    .then((res) => {
      if (res && res.ErrorCode == 0 && res.Data) {
        window.open(res.Data, '_blank')
      } else {
        return cb('error', res.Message??ErrorMessages['URL_ERROR'])
      }
    })
    .catch((err) => {
      return cb('error', ErrorMessages[err.response.status])
    })
}

export const fetchLastestTemplateList = (ticketDetails) => (dispatch) => {
  if (ticketDetails) {
    if (isCommunicationEligible(ticketDetails.ProductId)) {
      const payload = {
        LeadID: ticketDetails.LeadID,
        TicketID: ticketDetails.TicketId.toString(),
        CommunicationType: 1,
        ProductId: ticketDetails.ProductId,
        SubProductId: 0,
        CorpClientId: ticketDetails.Source,
      };
      POST('GetTemplates_V2', payload)
        .then((res) => {
          if (res.ErrorCode === 0) {
            dispatch(setTemplateList(res.Data.TemplateTriggerList))
          } else {
            dispatch(setTemplateList([]));
            Toast.error(res['Message'] || 'Failed to get template list')
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      GET(`GetTemplates/${ticketDetails.ProductId}/${1}/${ticketDetails.TicketId}`)
        .then((res) => {
          if (res.ErrorCode === 0) {
            dispatch(setTemplateList(res.Data))
          } else {
            dispatch(setTemplateList([]));
            Toast.error(res['Message'] || 'Failed to get template lists')
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}

export const fetchBMSInsurerTemplateList = (ticketDetails) => dispatch => {
  if (ticketDetails) {
      const payload = {
        LeadID: ticketDetails.LeadID,
        TicketID: ticketDetails.TicketId.toString(),
        CommunicationType: 1,
        ProductId: ticketDetails.ProductId,
        SubProductId: 0,
        CorpClientId: ticketDetails.Source,
        TemplateCategory: "TICKETINSURER"
      };
      POST('GetTemplates_V2', payload)
        .then((res) => {
          if (res.ErrorCode === 0) {
            dispatch(setInsurerTemplateList(res.Data.TemplateTriggerList))
          } else {
            dispatch(setInsurerTemplateList([]));
            Toast.error(res['Message'] || 'Failed to get template list')
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
}

export const setActiveTicketID = (payload) => {
  return {
    type: type.SET_ACTIVE_TICKET_ID,
    payload
  }
}

