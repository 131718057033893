import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: "100%",
    },
    selectEmpty: {},
    appBar: {
        position: "relative",
        backgroundColor: "#ffffff !important",
        color: "#414141",
    },
    title: {
        flex: 1,
        fontSize: "16px",
    },
    h6: {
        color: "#414141",
        fontSize: "14px",
    },

    spinerIcon: {
        position: "absolute",
        top: "9px",
        left: "39%",
        right: "39%",
        margin: "auto",
        "& svg": {
            color: "#0052cc",
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const EndorsementDetailsDialog = ({ openDetails, closeDetails, iframeRef, openDetailsURL }) => {
    const classes = useStyles();
    return (
        <Dialog
            className="dialog-show-details"
            fullScreen
            open={openDetails}
            // onClose={() => {return leadId ? null : handleClose}}
            TransitionComponent={Transition}
            aria-labelledby="responsive-dialog-title"
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="right"
                        color="inherit"
                        onClick={closeDetails}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Endorsement Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="endorsement-dialog-popup">
                <iframe
                    style={{ width: "100%", height: "100vh" }}
                    // id="followUpCalender-calender"
                    ref={iframeRef}
                    // className="iframe-box"
                    src={openDetailsURL}
                ></iframe>
            </div>
        </Dialog>
    )
}

export default EndorsementDetailsDialog