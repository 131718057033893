import React, { useState, useContext, useEffect } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import CreateTicketDialog from "./Components/CreateTicketDialog";
import CreateTicketDialog from "../../../Topbar/components/CreateTicketDialog";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  convertToIsoFormate,
  getFileExtension,
  convertToIsoFormateLogActivity,
  MaskEmailID,
} from "../../../../shared/methods/methods";
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ListItem,
  List,
  Grid,
} from "@material-ui/core";

import ReplyMailContent from "./Components/ReaplyMailContent";
import ReplyMailContentOpen from "./Components/ReplyMailContentOpen";
import Spinner from "../../../../shared/components/Spinner";
import {
  customerReplyFilters,
  emailFilters,
  pbReplyFilters,
  esclationFilters,
  TICKET_SOURCE_CARE,
  MY_ACCOUNT,
  EMAIL_FROM_CUSTOMER,
} from "../../../../Config";
import MailContent from "./Components/MailContent";
import AttachementList from "./Components/AttachementList";
import SendToInsurerCheckbox from "./Components/SendToInsurerCheckbox";
import TicketContext from "../../../../TicketContext";
import DownloadMail from "./Components/DownloadMail";
import ChatHistoryView from "../../../Dialog/ChatHistory";
import { useSelector } from "react-redux";
import ChildErrorBoundary from '../../../../shared/components/ChildErrorBoundary';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  chatHistoryView: {
    position: "relative",
    bottom: "10%",
    right: "2%",
    zIndex: "999",
  },
}));

export default function SimpleExpansionPanel({
  issueName,
  templateList,
  oldTicketDetails,
  isTicketTimelineLoading,
  ticketLogs,
  showLogs,
  toggleFilterBtn,
  AccessTypeVal,
  viewtype,
  isValidForSME,
  goToTemplate,
  setGoToTemplate,
}) {
  const ActiveTicket_store = useSelector(state => state.ticketdetails?.selectedTicketID)
  const [DialogOpen, setDialogOpen] = useState({
    CreateTicket: false,
  });
  const classes = useStyles();
  //reads from Store if the user is ReadOnlyUser
  const IsReadOnlyUser = useSelector(
    (state) =>
      (state &&
        state.ticketdetails &&
        state.ticketdetails.ticketDetails &&
        state.ticketdetails.ticketDetails.IsReadOnlyUser) ||
      false
  );

  const ticketContext = useContext(TicketContext);

  const handleDialogs = (type, open) => {
    open
      ? setDialogOpen({ ...DialogOpen, [type]: true })
      : setDialogOpen({ ...DialogOpen, [type]: false });
  };

  //ChatWindow methods
  const [openChatWindow, setOpenChatWindow] = React.useState(false);
  const [openPdfGenerater, setOpenPdfGenerater] = React.useState(false);

  const FilteredEmailList = (emails) => {
    const data = [];
    emails.forEach((email) => {
      if (email) {
        data.push(email);
      }
    });
    return data;
  };

  const isDownloadMailVisible = (source = "", eventType = "") => {
    if (
      (source && source.toLowerCase().includes(TICKET_SOURCE_CARE)) ||
      (eventType && eventType.toLowerCase().includes(TICKET_SOURCE_CARE))
    ) {
      return true;
    } else if (
      (source && source.toLowerCase().includes(MY_ACCOUNT)) ||
      (eventType && eventType.toLowerCase().includes(MY_ACCOUNT))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const renderWithoutLogs = () => {
    return (
      <span className="email-chain">
        <ChatHistoryView
          oldTicketDetails={oldTicketDetails}
          setOpenChatWindow={setOpenChatWindow}
          openChatWindow={openChatWindow}
        />
        {getFilteredData &&
          getFilteredData().map((item, index) => {
            return (
              <List key={index}>
                <ListItem
                  className={
                    "email-customer" +
                    (index === 0 &&
                    item.TicketSource &&
                    item.TicketSource.toLowerCase() !== "pbreply"
                      ? " pb-reply"
                      : "")
                  }
                >
                  <span className="email-tag email">
                    {item.TicketSource}{" "}
                    {item.TicketSubSource ? " - " + item.TicketSubSource : ""}
                  </span>

                  <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon className="arrow" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item md={12} xs={12}>
                         <span className="user-iocn"><i className="fa fa-user-circle"></i></span>
                         <Tooltip title={item.EventType ? item.EventType : item.TicketSource} placement="top-start">
                          <p className="heading">
                              {ActiveTicket_store != item.TicketId
                                ? "(C)"
                                : ""}
                              {item.EventType ? item.EventType : item.TicketSource}{" "}
                              {isDownloadMailVisible(
                                item.TicketSource,
                                item.EventType
                              ) ? (
                                <DownloadMail
                                  MailRefObjId={item.ObjectID}
                                  IsReadOnlyUser={IsReadOnlyUser}
                                />
                              ) : (
                                ""
                              )}
                            </p>
                        </Tooltip>
                          
                          <div className="mail-timing">
                            {item &&
                              item.TicketSource &&
                              item.TicketSource.toLowerCase() === "chat" && (
                                <Button
                                  variant="outlined"
                                  onClick={() => setOpenChatWindow(true)}
                                  className="chat-btn-history"
                                >
                                  <i
                                    className="fa fa-comments"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              )}
                            <span className={classes.chatHistoryView}></span>
                            {item.callRecording && (
                              <a
                                href={item.callRecording}
                                className="recording-box"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src="/images/play_circle.svg" />
                              </a>
                            )}
                            <span>
                              {convertToIsoFormateLogActivity(item.DisplayDate)}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: "block" }}>
                      <Grid container className="mail-heading">
                        <Grid item md={12} xs={12}>
                          <Grid container>
                            <Grid md={9} xs={7}>
                              <p>{item.Subject}</p>
                            </Grid>
                            {ticketContext.isActionsAllowed &&
                            viewtype == 0 &&
                            !IsReadOnlyUser ? (
                              <Grid md={3} xs={5} className="send-to-insurer">
                                <SendToInsurerCheckbox
                                  id={item.id}
                                  IsInsurer={item.IsInsurer}
                                  oldTicketDetails={oldTicketDetails}
                                />
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                          {item.From 
                            && (
                              <p>
                                <span className="from-to">
                                  From: {item.DisplaySenderName} ({MaskEmailID(item.From, IsReadOnlyUser)})
                                </span>
                              </p>
                            )}
                          {item.ToReceipent && 
                            Array.isArray(item.ToReceipent) &&
                            FilteredEmailList(item.ToReceipent).length > 0 && (
                              <span className="from-to">
                                To:{" "}
                                {FilteredEmailList(item.ToReceipent).map(item=> MaskEmailID(item, IsReadOnlyUser)).join(",")}
                              </span>
                            )}
                          {item.CCEmail &&
                            Array.isArray(item.CCEmail) &&
                            FilteredEmailList(item.CCEmail).length > 0 && (
                              <p>
                                <span className="from-to">
                                  CC:{" "}
                                  {FilteredEmailList(item.CCEmail).map(item=> MaskEmailID(item, IsReadOnlyUser)).join(",")}
                                </span>
                              </p>
                            )}
                        </Grid>
                      </Grid>
                      <AttachementList
                        attachments={item.MailAttachments}
                        isActionsAllowed={true}
                        IsReadOnlyUser={IsReadOnlyUser}
                      />
                      <MailContent
                        ind={index}
                        Body={item.Body}
                        EventType={item.EventType}
                        TicketSource={item.TicketSource}
                      />
                      <ChildErrorBoundary>
                        <ReplyMailContent
                          goToTemplate={goToTemplate}
                          setGoToTemplate={setGoToTemplate}
                          ticketData={item}
                          templateList={templateList}
                          oldTicketDetails={oldTicketDetails}
                          AccessTypeVal={AccessTypeVal}
                          viewtype={viewtype}
                          isValidForSME={isValidForSME}
                          index={index}
                        />
                      </ChildErrorBoundary>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </ListItem>
              </List>
            );
          })}
      </span>
    );
  };

  const renderWithLogs = () => {
    const mergeData = [...ticketContext.ticketCommData, ...ticketLogs];
    const dataWithLog = mergeData
      .filter(
        (data) =>
          (data.ActionType !== 3 &&
            data.ActionType !== 1 &&
            data.ActionType !== 8 &&
            data.ActionType !== 9 &&
            data.ActionType !== 5) ||
          !data.hasOwnProperty("ActionType")
      )
      .sort((a, b) => b.DisplayDate - a.DisplayDate);
    return dataWithLog
      .filter(
        (data) =>
          (data.ActionType !== 3 &&
            data.ActionType !== 1 &&
            data.ActionType !== 8 &&
            data.ActionType !== 9 &&
            data.ActionType !== 5) ||
          !data.hasOwnProperty("ActionType")
      )
      .map((item, index) => {
        return (
          <List key={index}>
            {item.type == "data" && (
              <ListItem
                className={
                  "email-customer" +
                  (index === 0 &&
                  item.TicketSource &&
                  item.TicketSource.toLowerCase() !== "pbreply"
                    ? " pb-reply"
                    : "")
                }
              >
                <span className="email-tag email">
                  {item.TicketSource}{" "}
                  {item.TicketSubSource ? " - " + item.TicketSubSource : ""}
                </span>
                <ExpansionPanel defaultExpanded={true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="arrow" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item md={12} xs={12}>
                        <i className="fa fa-user-circle"></i>{" "}
                        {item.EventType ? item.EventType : item.TicketSource}
                        {isDownloadMailVisible(
                          item.TicketSource,
                          item.EventType
                        ) ? (
                          <DownloadMail MailRefObjId={item.ObjectID} />
                        ) : (
                          ""
                        )}
                        <div className="mail-timing">
                          {item.callRecording && (
                            <a
                              href={item.callRecording}
                              className="recording-box"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src="/images/play_circle.svg" />
                            </a>
                          )}
                          <span>
                            {convertToIsoFormateLogActivity(item.DisplayDate)}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{ display: "block" }}>
                    <Grid container className="mail-heading">
                      <Grid item md={12} xs={12}>
                        <Grid container>
                          <Grid md={9} xs={7}>
                            <p>{item.Subject}</p>
                          </Grid>
                          {ticketContext.isActionsAllowed &&
                          viewtype == 0 &&
                          !IsReadOnlyUser ? (
                            <Grid md={3} xs={5} className="send-to-insurer">
                              <SendToInsurerCheckbox
                                id={item.id}
                                IsInsurer={item.IsInsurer}
                                oldTicketDetails={oldTicketDetails}
                                IsReadOnlyUser={IsReadOnlyUser}
                              />
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {item.From &&
                          !IsReadOnlyUser &&
                          !pbReplyFilters.includes(
                            item.TicketSource
                              ? item.TicketSource.toLowerCase()
                              : ""
                          ) && (
                            <p>
                              <span className="from-to">
                                From: {item.DisplaySenderName} ({item.From})
                              </span>
                            </p>
                          )}
                        {item.ToReceipent &&
                          !IsReadOnlyUser &&
                          Array.isArray(item.ToReceipent) &&
                          FilteredEmailList(item.ToReceipent).length > 0 && (
                            <span className="from-to">
                              To:{" "}
                              {FilteredEmailList(item.ToReceipent).join(",")}
                            </span>
                          )}
                        {item.CCEmail &&
                          !IsReadOnlyUser &&
                          Array.isArray(item.CCEmail) &&
                          FilteredEmailList(item.CCEmail).length > 0 && (
                            <p>
                              <span className="from-to">
                                CC: {FilteredEmailList(item.CCEmail).join(",")}
                              </span>
                            </p>
                          )}
                      </Grid>
                    </Grid>
                    <AttachementList
                      attachments={item.MailAttachments}
                      isActionsAllowed={ticketContext.isActionsAllowed}
                      IsReadOnlyUser={IsReadOnlyUser}
                    />
                    <MailContent
                      ind={index}
                      Body={item.Body}
                      EventType={item.EventType}
                      TicketSource={item.TicketSource}
                    />

                    <ReplyMailContent
                      goToTemplate={goToTemplate}
                      setGoToTemplate={setGoToTemplate}
                      ticketData={item}
                      templateList={templateList}
                      oldTicketDetails={oldTicketDetails}
                      isValidForSME={isValidForSME}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </ListItem>
            )}
            {item.type == "log" && (
              <ListItem>
                <div className="sub-status">
                  <div className="status-txt">
                    <span className="status-date-time">
                      {convertToIsoFormateLogActivity(item.DisplayDate)}
                    </span>
                    <h5>
                      <strong>{item.FieldName}</strong> Updated to{" "}
                      <strong>{item.NewValue}</strong> by {item.CreatedByName}
                    </h5>
                    {item.OldValue && (
                      <p>
                        Previous Value : <strong>{item.OldValue}</strong>
                      </p>
                    )}
                  </div>
                </div>
              </ListItem>
            )}
          </List>
        );
      });
  };
  const getFilteredData = () => {
    const FilteredData = ticketContext.ticketCommData.filter(
      (data) =>
        data.ActionType !== 3 &&
        data.ActionType !== 1 &&
        data.ActionType !== 8 &&
        data.ActionType !== 9 &&
        data.ActionType !== 5
    );
    if (toggleFilterBtn.all) {
      return FilteredData;
    } else if (toggleFilterBtn.email) {
      return FilteredData.filter((item) =>
        emailFilters.includes(
          item.TicketSource ? item.TicketSource.toLowerCase() : ""
        )
      );
    } else if (toggleFilterBtn.esclation) {
      return FilteredData.filter((item) =>
        esclationFilters.includes(
          item.TicketSource ? item.TicketSource.toLowerCase() : ""
        )
      );
    } else if (toggleFilterBtn.customerReply) {
      return FilteredData.filter((item) =>
        customerReplyFilters.includes(
          item.TicketSource ? item.TicketSource.toLowerCase() : ""
        )
      );
    } else if (toggleFilterBtn.pbReply) {
      return FilteredData.filter((item) =>
        pbReplyFilters.includes(
          item.TicketSource ? item.TicketSource.toLowerCase() : ""
        )
      );
    } else if (toggleFilterBtn.attachments) {
      return FilteredData.filter(
        (item) =>
          (item.TicketSource ? item.TicketSource.toLowerCase() : "") !=
            "pbreply" &&
          item.MailAttachments &&
          Array.isArray(item.MailAttachments) &&
          item.MailAttachments.length > 0
      );
    } else if (toggleFilterBtn.IB) {
      return FilteredData.filter(
        (item) =>
          (item.TicketSource ? item.TicketSource.toLowerCase() : "") ===
          "inbound"
      );
    } else if (toggleFilterBtn.C2C) {
      return FilteredData.filter(
        (item) =>
          (item.TicketSource ? item.TicketSource.toLowerCase() : "") === "c2c"
      );
    } else {
      return [];
    }
  };

  return (
    <div className="email-chain scrool-toll">

      {isTicketTimelineLoading ? (
        <div className="spinner-loader">
          <Spinner />
        </div>
      ) :
        <>
          {Array.isArray(ticketContext.ticketCommData) &&
            ticketContext.ticketCommData.filter(
              (data) =>
                data.ActionType !== 3 &&
                data.ActionType !== 1 &&
                data.ActionType !== 8 &&
                data.ActionType !== 9 &&
                data.ActionType !== 5
            ).length === 0 && (
            <ChildErrorBoundary>
            {/* This Editor is opened if there are no logs in the ticket */}
              <ReplyMailContentOpen
                templateList={templateList}
                oldTicketDetails={oldTicketDetails}
                viewtype={viewtype}
                isValidForSME={isValidForSME}
              />
            </ChildErrorBoundary>
            )}
          {!showLogs ? (
            renderWithoutLogs()
          ) : (
            renderWithLogs()
          )}
        </>
      }

      <CreateTicketDialog
        isCreateDialogOpen={DialogOpen.CreateTicket}
        handleDialogs={handleDialogs}
        AccessTypeVal={AccessTypeVal}
      />
    </div>
  );
}
