import React from 'react';
import {ListItem,FormControlLabel,Radio} from '@material-ui/core';

const SelectAgentOption = ({value,label}) => {
    return (
        <ListItem>
        <FormControlLabel value={value} control={<Radio />} label={label} />
      </ListItem>
    )
}

export default SelectAgentOption;
