import React, { useState, useEffect } from "react";
import { GET, POST, old_ticket_base_url } from "../services/apiServices";
import Paper from "@material-ui/core/Paper";
import {
  GetAPIUIDMasterData,
  CreateUpdateDeleteAPIUIDMapping,
} from "../constants/apiUrls";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  TableFooter,
  TablePagination,
  TableRow,
  IconButton,
} from "@material-ui/core";

export default function NewAPIMaster({}){
    const dispatch = useDispatch();
  const [url, setUrl] = useState(window.location.href);
  const history = useHistory();
  let str;
  let landingpage = "landingpage/";
  if (url.toLowerCase().includes(landingpage)) {
    let index1 = url.toLowerCase().indexOf(landingpage);
    str = url.substring(index1, index1 + landingpage.length);
  }
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const encryptedParam = url.split(str)[1];
  const auth = useSelector((state) => state.auth.auth);
  const [APIMasterData, setAPIMasterData] = useState(null);
  const [sortingData, setsortingData] = useState(null);

  const [selectedAPI, setSelectedAPI] = useState({});
  const [createvar, setcreatevar] = useState({
    apiname: null,
    uid: null,
  });
  const [flag, setflag] = useState(false);

  const [updSno, setupdSno] = useState(null);
  const [deletevar, setdeletevar] = useState({
    apiname: null,
  });
  const [inputValue, setInputValue] = useState("");
  const [logData, setLogData] = useState(null);
  const [DefApi, setDefApi] = useState(null);
  const [DefUID, setDefUID] = useState(null);
  const [flag2, setflag2] = useState(null);

  useEffect(() => {
    loadAPIData();
  }, []);

  const loadAPIData = () => {
    GET(GetAPIUIDMasterData)
      .then((response) => {
        if (response.ErrorCode == 0) {
          // setAPIMasterData(response.Data);
          // console.log(APIMasterData)
          setAPIMasterData(response.Data.sort(compare));
        }
      })
      .catch((err) => console.log(err));
  };

  function compare(a, b) {
    if (a.APIName < b.APIName) {
      return -1;
    }
    if (a.APIName > b.APIName) {
      return 1;
    }
    return 0;
  }

  const handleChangeCreate = (event) => {
    if (event && event.persist) {
      event.persist();
    }

    setcreatevar((createvar) => ({
      ...createvar,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeUpdate = (event) => {
    if (event && event.persist) {
      event.persist();
    }

    if (event.target.name == "DefApi") {
      setDefApi(event.target.value);
    } else if (event.target.name == "DefUID") {
      setDefUID(event.target.value);
    }
  };

  const createfunc = (event) => {
    if (event && event.persist) {
      event.persist();
    }

    if (
      createvar.apiname == null ||
      !createvar.apiname.replace(/\s/g, "").length
    ) {
      enqueueSnackbar("Enter API Name", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      return;
    } else if (
      createvar.uid == null ||
      !createvar.uid.replace(/\s/g, "").length
    ) {
      enqueueSnackbar("Enter API UID", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      return;
    } else {
      // console.log("123");
      const payload = {
        APIName: createvar.apiname,
        UID: createvar.uid,
        SNo: 0,
        Type: 1,
      };

      POST(CreateUpdateDeleteAPIUIDMapping, payload)
        .then((response) => {
          if (response.ErrorCode == 0) {
            loadAPIData();
            //   setdata(response.Data);
            enqueueSnackbar("API Created Successfully", {
              variant: "success",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
        })
        .catch((err) => console.log(err));
      //   setDataLoading(false);
    }
  };

  const clickfunc = (sno, defapi, defuid) => {
    setupdSno(sno);
    setDefApi(defapi);
    setDefUID(defuid);
  };

  const updatefunc = () => {
    // if (event && event.persist) {
    //   event.persist();
    //

    // <input type="text" name="uid" onChange={handleChange}>UID</input>;
    // setDataLoading(true);
    if (DefApi == null || !DefApi.replace(/\s/g, "").length) {
      enqueueSnackbar("Enter API Name", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    } else if (DefUID == null || !DefUID.replace(/\s/g, "").length) {
      enqueueSnackbar("Enter API UID", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    } else {
      const payload = {
        APIName: DefApi,
        UID: DefUID,
        SNo: updSno,
        Type: 2,
      };

      POST(CreateUpdateDeleteAPIUIDMapping, payload)
        .then((response) => {
          loadAPIData();
          if (response.ErrorCode == 0) {
            setupdSno(null);
            // setDefApi(null);
            // setDefUID(null);
            enqueueSnackbar("API Updated Successfully", {
              variant: "success",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
        })
        .catch((err) => console.log(err));
      //   setDataLoading(false);
    }
  };

  const flagfunc = (event) => {
    setflag("123");
  };

  const cancel = (event) => {
    setupdSno(null);
    // return;
  };
  const cancelfunc1 = (event) => {
    setflag(null);
    // return;
  };

  const deletefunc = (apiname, uid, sno) => {
    // if (event && event.persist) {
    //   event.persist();
    // }
    // setDataLoading(true);

    const payload = {
      APIName: apiname,
      UID: uid,
      SNo: sno,
      Type: 3,
    };

    POST(CreateUpdateDeleteAPIUIDMapping, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          loadAPIData();
          enqueueSnackbar("API Deleted Successfully", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => console.log(err));
    //   setDataLoading(false);
  };
  return (
    <div className="api-logs">
      <h2>API Log Master</h2>{" "}
      <div className="search-option-list">
        {flag?.length ? (
          <ul className="listing">
            <li>
              <FormControl className="form-control">
                <TextField
                  label="New API Name"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="apiname"
                  onChange={handleChangeCreate}
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="New API UID"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="uid"
                  onChange={handleChangeCreate}
                />
              </FormControl>
            </li>
            <li>
              <Button
                className="api-btn"
                type="submit"
                onClick={(event) => createfunc(event)}
              >
                {" "}
                Create{" "}
              </Button>
              <Button
                className="api-btn cancel-btn"
                type="submit"
                onClick={(event) => cancelfunc1(event)}
              >
                {" "}
                Cancel{" "}
              </Button>
            </li>
          </ul>
        ) : (
          <Button
            className="api-btn"
            type="submit"
            onClick={(event) => flagfunc(event)}
          >
            Create New API
          </Button>
        )}
      </div>
      <br></br>
      <div>
        {APIMasterData?.length ? (
          <TableContainer component={Paper} className="api-log-table">
            <Table
              sx={{ minWidth: 1000 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>API Name</TableCell>
                  <TableCell>UID</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="api-log-table-body">
                {APIMasterData.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell name="apiname">{row.APIName} </TableCell>
                    <TableCell name="uid">{row.UID}</TableCell>
                    {/* <TableCell name="sno">{row.SNo}</TableCell> */}
                    <TableCell name="submit">
                      <Button
                        className="edit-delete-btn"
                        type="submit"
                        onClick={() => clickfunc(row.SNo, row.APIName, row.UID)}
                      >
                        Edit
                      </Button>{" "}
                      &nbsp;
                      <Button
                        className="edit-delete-btn"
                        type="submit"
                        onClick={(event) =>
                          deletefunc(row.APIName, row.UID, row.SNo)
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : null}
      </div>
      {updSno?.length ? (
        <ul className="listing">
          <h2>Edit Details</h2>
          <li>
            <FormControl className="form-control">
              <TextField
                label="Updated Name"
                value={DefApi}
                id="outlined-margin-normal"
                margin="normal"
                name="DefApi"
                onChange={handleChangeUpdate}
              />
            </FormControl>
          </li>
          <li>
            <FormControl className="form-control">
              <TextField
                label="Updated API UID"
                value={DefUID}
                id="outlined-margin-normal"
                margin="normal"
                name="DefUID"
                onChange={handleChangeUpdate}
              />
            </FormControl>
          </li>
          <li>
            <Button
              className="api-btn"
              type="submit"
              onClick={(event) => updatefunc(event)}
            >
              Update
            </Button>
            &nbsp;
            <Button
              className="api-btn cancel-btn"
              type="submit"
              onClick={(event) => cancel(event)}
            >
              Cancel
            </Button>
          </li>
        </ul>
      ) : null}
    </div>
  );
}
