import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@material-ui/core";

import DialogTitle from "../components/DialogTitle";

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ConfirmationDialog({
  isDialogOpen,
  setDialogOpen,
  Msg,
  setTicketCreatedMsg,
  closeParentDialog
}) {
  const handleClose = () => {
    setTicketCreatedMsg("");
    setDialogOpen(false);
    if(closeParentDialog){
      closeParentDialog();
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="xs"
        className="reply-popup confimation-box"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
      >
        <DialogTitle
          className="title-heading"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Ticket Created successfully
        </DialogTitle>
        <DialogContent>
          <p>{Msg}</p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus className="cancel-btn" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
