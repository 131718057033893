import React, { useState, useRef, useEffect } from 'react'
import { Button, Grid } from '@material-ui/core'
import { VERSION } from "../../../Config";
import { GET, POST } from '../../../services/apiServices';
import { handleCatch } from '../../../shared/methods/apiMethods';
import { useDispatch } from 'react-redux';
import { authSet, userInfo } from '../../../redux/actions/auth';
import { STORAGE } from '../../../shared/methods/methods';
import { Toast } from '../../../shared/components';
import Spinner from "../../../shared/components/Spinner";

function OTPComponent({ loginMessage, resetOptions, ...props }) {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState(Array(6).fill(""));
  const verifyBtnRef = useRef();
  const [countDownTime, setCountDownTime] = useState(30)
  const [verifying, setVerifying] = useState(false);
  const [message, setMessage] = useState({});
  const [resendingOTP, setResendingOTP] = useState(false)

  useEffect(() => {
    if (loginMessage) {
      setMessage({ m: loginMessage, t: 'success' })
    }
  }, [loginMessage])

  useEffect(() => {
    if (countDownTime === 30) {
      window.myInterval = setInterval(() => {
        if (countDownTime <= 0) { return clearInterval(window.myInterval) }
        setCountDownTime((prev) => {
          if (prev && prev - 1 == 0) {
            clearInterval(window.myInterval)
            return prev - 1
          }
          else return prev - 1
        })
      }, 1000)
    }

  }, [countDownTime])

  useEffect(() => {
    let tempOTPFields = document.getElementsByClassName('otp-field')[0].getElementsByTagName('input');
    tempOTPFields[0].focus();
  }, [])



  const handleChange = (index, value) => {
    const otpFields = document.getElementsByClassName('otp-field')[0].getElementsByTagName('input');

    // Check if value is numeric and within range 0-9
    if (/^\d$/.test(value) && parseInt(value) >= 0 && parseInt(value) <= 9) {
      const newOtp = [...otp];
      newOtp[index] = parseInt(value);
      setOtp(newOtp);
      // Move focus to the next input
      if (index < otpFields.length - 1) {
        otpFields[index + 1].focus();
      }
      else verifyBtnRef.current.focus()
    } else if (value === '') {
      // If input value is cleared, move focus to the previous input
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      if (index > 0 || !index) {
        otpFields[index - 1].focus();
      }
    } else {
      // If non-numeric or out-of-range value entered, clear the input
      const newOtp = [...otp];
      let lastVal = value > 9 ? value % 10 : ''
      newOtp[index] = lastVal;
      setOtp(newOtp);
      if (index >= 0 && index < otpFields.length - 1) {
        otpFields[index + 1].focus();
      }
    }
  };

  const resendOTP = () => {
    let endpoint = 'WeCare/ResendOtp';
    setResendingOTP(true);
    GET(endpoint, '', 'weCare').then(res => {
      setResendingOTP(false);
      if (res && res.ErrorCode === 0) {
        setMessage({ m: res.Message, t: 'success' })
        setCountDownTime(30)
      }
      else {
        setMessage({ m: res.Message || 'Failed to send OTP', t: 'error' })
      }
    }).catch(err => {
      setResendingOTP(false);
      setMessage({ m: handleCatch(err, true), t: 'error' })
    })
  }

  const verifyOTP = () => {
    let otpString = otp.join('');
    if (otpString.length < 6) {
      return
    }
    else {
      setVerifying(true);
      let endpoint = 'WeCare/VerifyWeCareOtp';
      let payload = {
        otp: otpString
      }
      POST(endpoint, payload, 'weCare').then(res => {
        setVerifying(false);
        if (res && res.ErrorCode === 0) {
          STORAGE.setAuthToken(res.Data.Token || "").then(() => {
            dispatch(userInfo(res.Data)) // sets the Logged User data in Redux Store
            dispatch(authSet(true));
            Toast.success(`Welcome back! ${res.Data.Name}`)
            props.history.push("/InsurerDashboard"); // navigates the user to the mentioned url
          })
        }
        else {
          setMessage({
            m: res.Message || 'Validation failed',
            t: 'error'
          })
        }
      }).catch(err => {
        setVerifying(false);
        setMessage({ m: handleCatch(err, true), t: 'error' })
      })
    }
  }

  return (
    <div className="login-box">
      <h2>Enter OTP</h2>
      <div className="otp-field">
        {[...Array(6)].map((_, idx) => (
          <input
            key={idx}
            className='input-no-spin'
            id={`input-${idx}`}
            type="number"
            min={0}
            value={otp[idx]}
            maxLength={1}
            onChange={(e) => handleChange(idx, e.target.value)}
          />
        ))}
      </div>
      <p className={message.t === 'success' ? 'success' :
        'error'}>{message.m}</p>
      <Button className="verify-btn" ref={verifyBtnRef} onClick={verifyOTP} id='otp-verify-btn'>
        {verifying ? <Spinner /> : 'Verify'}
      </Button>

      <p class="resend text-muted mb-0">
        <p id='resend-otp-btn' style={{ pointerEvents: countDownTime ? 'none' : "", cursor: countDownTime ? 'none' : "pointer" }} onClick={resendOTP} title='Click to re-send OTP on your registered device'><span><>{resendingOTP ? <div className='otp-loader'><Spinner/></div> : 'Resend OTP'} {countDownTime ? `in ${countDownTime} sec(s)` : ""}</></span></p>
      </p>
      <Button className="login-btn" onClick={resetOptions} id='otp-login-other-user-btn'>
        Login with other user
      </Button>
      <Grid className="text-center">
        Version : {VERSION}
      </Grid>
    </div>
  )
}

export default OTPComponent


