import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Menu, MenuItem } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { connect } from 'react-redux'
import { showTopbarTicketForInsurer, STORAGE } from '../../shared/methods/methods'
import { userLogout } from '../../redux/actions/auth'
import WeCareTicketCreate from '../Topbar/components/WeCareTicketCreate'
import { GET } from '../../services/apiServices'
import { handleCatch } from '../../shared/methods/apiMethods'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  pageHeading: {
    margin: '8px 10px 0',
    border: 'none',
    position: 'absolute',
    left: '0',
  },
}))

const TopbarHeader = ({ fetchedUserDetails, componentName }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const [userDetails, setUserDetails] = useState({})
  const [weCareTicketDialogOpen, setWeCareTicketDialogOpen] = useState(false)

  useEffect(() => {
    if (fetchedUserDetails && Object.keys(fetchedUserDetails).length > 0) {
      setUserDetails(fetchedUserDetails)
    }
  }, [fetchedUserDetails])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    GET('/Logout').then(res => {
      if (res) {
        dispatch(userLogout())
        STORAGE.deleteAuth().then(() => {
          history.push('/InsurerLogin')
        })
      }
    })
      .catch((error) => {
        console.log(error);
        handleCatch(error);
      })
  }

  const openWeCareCreateTicket = () => {
    setWeCareTicketDialogOpen(true)
  }

  const RenderButtons = React.useMemo(() => {
    return (
      <>
        <Button
          className="create-ticket-btn"
          onClick={() => history.push('/wecaremyticket')}
          id="topbarHeadMyTicket"
        >
          My Ticket
        </Button>
        <Button
          aria-controls="create ticket"
          aria-haspopup="true"
          className="create-ticket-btn"
          onClick={openWeCareCreateTicket}
          id="topbarHeadCreateTicket"
        >
          <img src="/images/create_ticket_btn.svg" alt="create ticket" /> Create
          Ticket
        </Button>
      </>
    )
  })

  return (
    <div className="profile-box">
      <h3 className={classes.pageHeading}>{componentName || ''}</h3>
      {fetchedUserDetails && showTopbarTicketForInsurer(fetchedUserDetails.UserID) && RenderButtons}
    
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        id="topbarHeadUser"
      >
        {userDetails?.Name} <i className="fa fa-user"></i>
      </Button>
      <Menu
        id="simple-menu topbarHeadUserToggle"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="user-toggle"
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        <MenuItem id="topbarHeadLogOut" onClick={logout}>Logout</MenuItem>
      </Menu>
      {weCareTicketDialogOpen && (
        <WeCareTicketCreate
          openTicketCreation={weCareTicketDialogOpen}
          closeTicketCreation={() => setWeCareTicketDialogOpen(false)}
          OpenedThroughSource="WeCare Dashboard"
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    fetchedUserDetails:
      state && state.auth && state.auth.userInfo ? state.auth.userInfo : {},
  }
}

export default connect(mapStateToProps, null)(TopbarHeader)
