import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useHistory } from "react-router-dom";
import { useDispatch  } from "react-redux";
import { userLogout } from "../../redux/actions/auth";
import { STORAGE } from "../../shared/methods/methods";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    background: "#2196f314",
  },
  container: {
    padding: "20px",
    width: "400px",
    margin: "0 auto",
    maxWidth: "100%",
  },
  formControl: {
    width: "100%",
  },

  image: {
    backgroundImage: "url(./images/Artwork.svg)",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#DEEBFF",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.9",
    height:"calc(100vh - 6px)",
    backgroundSize: "50%",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: "10px 8px",
    background: "#00458b",
    "&:hover": {
      background: "#00458b",
    },
  },
  logoImg: {
    width: "119px",
    textAlign: "center",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "50%",
    padding: "3px",
    height: "114px",
    "& img": {
      maxWidth: "70%",
      margin: "13px",
    },
  },
  root: {
    height: "100vh",
    background: "#DEEBFF",
    position: "relative",
  },
  bgColor: {
    background: "#DEEBFF",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogoutPage({name}) {
    const dispatch = useDispatch()
    const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  useEffect(() => {
    setOpen(true);
    document.title= name||"Unauthorized"
  }, []);

  const logout = () => {
    dispatch(userLogout())
    STORAGE.deleteAuth().then(()=>{
      history.push("/InsurerLogin");
    })
  };

  return (
    <div className={classes.root}>
      {/* <img src="./images/Artwork.svg"/> */}
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={12} md={12} className={classes.image}>
        <Dialog
             open={open}
             TransitionComponent={Transition}
             keepMounted
             onClose={handleClose}
             aria-labelledby="alert-dialog-slide-title"
             aria-describedby="alert-dialog-slide-description"
             className="logout-dialog"
           >
             {/* <DialogTitle id="alert-dialog-slide-title">
               {"Use Google's location service?"}
             </DialogTitle> */}
 
             <DialogContent>
               <DialogContentText id="alert-dialog-slide-description">
                 You are logged in as an Agent, logout to continue. Do you want to logout ?
               </DialogContentText>
             </DialogContent>
             <DialogActions>
               <Button onClick={handleClose} color="primary">
                 Stayon
               </Button>
               <Button onClick={logout} color="primary">
                 Logout
               </Button>
             </DialogActions>
           </Dialog>
        </Grid>
        {/* <Grid  item  xs={12}  sm={7} md={4}  elevation={6}  square  className={classes.bgColor}
        >
          
        </Grid> */}
      </Grid>
    </div>
  );
}

