import React, { useState, useContext } from "react";
import {
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    Table,
    Button
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import TicketContext from "../../../../TicketContext";
import { POST, GET } from "../../../../services/apiServices";
import { UpdateSMEDetails,GetTicketDetailsByTicketID, GetTicketSummary } from "../../../../constants/apiUrls"; 
import { useSnackbar } from "notistack";
import Spinner from '../../../../shared/components/Spinner';
import { useDispatch } from "react-redux";
import { setActiveTicketID } from "../../../../redux/actions/ticketDetails";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    logBtn: {
        margin: "0 5px 0",
    },
    formControl: {
        border: "1px solid #ccc",
        borderRadius: "5px",
        position: "relative",
        margin: "10px 0",
        width: "100%",
        "&:hover": {
            outline: "none",
            boxShadow: "none",
        },
        "& textArea": {
            fontSize: "12px",
            padding: "8px",
            border: "1px solid #ccc",
        },
        "& input": {
            padding: "8px",
        },
        input: {
            display: "none",
        },
    },
});


const ManualAddEmployee = ({ eName, eMobileNo, eEmailID, eCode, setAddEmployeeFieldsVisible, role, setSmeDetails, setState, setIsSMEApiHit, setIsEmployeeRawDataAdded, setEmployeeID }) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [isUpdating, setUpdating] = useState(false);
    const ticketContext = useContext(TicketContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const GetTicketDetailsByTicketIdApi = () => {
        GET(`${GetTicketDetailsByTicketID}/${ticketContext.RefObjectId}/${ticketContext.TicketDetailsID}`)
            .then((response) => {
                if (response.ErrorCode === 0) {
                    const { IsCallBackRequest, IsInsurerBucket } = response.Data;

                    let callbackVal;
                    if (IsCallBackRequest === "0") {
                        callbackVal = "No";
                    } else if (IsCallBackRequest === "1") {
                        callbackVal = "Yes";
                    } else if (IsCallBackRequest === "2") {
                        callbackVal = "Completed";
                    }

                    ticketContext.setFormValues((prevValue) => ({
                        ...prevValue,
                        ...response.Data,
                        IsCallBackRequest: +IsCallBackRequest,
                        CallbackVal: callbackVal,
                        SendToInsurer: IsInsurerBucket,
                        SendToInsurerMsg: "",
                    }));
                    ticketContext.setoldTicketDetails((prevValue) => ({
                        ...prevValue,
                        ...response.Data,
                        IsCallBackRequest: +IsCallBackRequest,
                        CallbackVal: callbackVal,
                        SendToInsurer: IsInsurerBucket,
                        SendToInsurerMsg: "",
                    }));
                } else {
                    enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            })
            .catch((err) => {
                enqueueSnackbar("Something went wrong!", {
                    variant: "error",
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                console.log(err);
            });
    };

    const getAllTicketSummary = () => {
        ticketContext.setAllTicketsLoading(true);
        GET(`${GetTicketSummary}/${ticketContext.RefObjectId}/${ticketContext.TicketDetailsID}`)
            .then((response) => {
                if (response.ErrorCode === 0) {
                    const sortedData1 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID != 3 && i.StatusID != 4).sort((a, b) => (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)) : [];
                    const sortedData2 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID == 3).sort((a, b) => (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)) : [];
                    const sortedData3 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID == 4).sort((a, b) => (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)) : [];
                    ticketContext.setAllTickets({ ...response.Data, "AllTicketList": [...sortedData1, ...sortedData2, ...sortedData3] });
                } else {
                    enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
                ticketContext.setAllTicketsLoading(false);
                dispatch(setActiveTicketID(response.Data.TicketId))
                ticketContext.setActiveTicketId(response.Data.TicketId);
                ticketContext.GetTicketLogsApi(response.Data.TicketId)
            })
            .catch((err) => {
                ticketContext.setAllTicketsLoading(false);
                enqueueSnackbar("Something went wrong!", {
                    variant: "error",
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
    };


    const UpdateSMEDetailsApi = () => {
        if (isUpdating) {
            return
        }

        const payload = {
            "TicketID": ticketContext.TicketId,
            "EmployeeID": eCode,
            "EmployeeName": eName,
            "EmailID": eEmailID,
            "TicketRaisedBy": role,
            "MobileNo": eMobileNo
        };
        POST(UpdateSMEDetails, payload)
            .then(res => {
                if (res.ErrorCode === 0) {
                    enqueueSnackbar("Success", {
                        variant: "success",
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    setState(
                        {
       
                            eEmailID : "",
                            eEmailIDError:"",
                            eMobileNo :"",
                            eMobileNoError : "",
                            eCode : "",
                            eCodeError : "",
                            eName: "",
                            eNameError : ""
                          }
                    )
                    setEmployeeID("")
                    setIsSMEApiHit(false)
                    setIsEmployeeRawDataAdded(false)
                    GetTicketDetailsByTicketIdApi();
                    getAllTicketSummary();
                    if (res.Data) {
                        setSmeDetails(res.Data);
                    }
                }
                else {
                    enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
                setUpdating(false);
            })
            .catch(err => {
                console.log(err);
                enqueueSnackbar("Something went wrong!", {
                    variant: "error",
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setUpdating(false);
            })
    }

    return (
        <div className="mannual-table-list">
            <TableContainer className="table">
                <Table
                    aria-label="simple table"
                    className="sme-table-list add-mannual"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">E Code</TableCell>
                            <TableCell align="left">Employee Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Mobile Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className="sme-body-list">
                            <TableCell align="left">{eCode}</TableCell>
                            <TableCell align="left">{eName}</TableCell>
                            <TableCell align="left">{eEmailID}</TableCell>
                            <TableCell align="left">{eMobileNo}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Button className="edit-btn" onClick={() => setAddEmployeeFieldsVisible(true)}>Edit</Button>
            <Button className="edit-btn" disabled={isUpdating} onClick={UpdateSMEDetailsApi}>{!isUpdating ? "Save" :
                <span className="mannual-loader">
                <Spinner />
            </span>
                    }
        </Button>
        </div>
    );
};

export default ManualAddEmployee;
