import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {Grid,List,ListItem} from '@material-ui/core';
import Spinner from '../../../../shared/components/Spinner';
import {convertToIsoFormate, convertToIsoFormateLogActivity} from '../../../../shared/methods/methods';
import {ASSIGNMENT,FOLLOWUP,INSURER_STATUS,ISSUE_SUBISSUE,LEADID,STATUS_SUBSTATUS} from '../../../../Config'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function CenteredGrid({ticketLogs,isLogsLoading,toggleFilterLogsBtn}) {
  const classes = useStyles();

  const getFilteredData = () => {
    if (toggleFilterLogsBtn.all) {
      return ticketLogs;
    } else if (toggleFilterLogsBtn.assignment) {
      return ticketLogs.filter((item) => item.FieldName.includes(ASSIGNMENT));
    } else if (toggleFilterLogsBtn.statusSubStatus) {
      return ticketLogs.filter((item) => STATUS_SUBSTATUS.includes(item.FieldName));
    } else if (toggleFilterLogsBtn.issueSubIssue) {
      return ticketLogs.filter((item) => ISSUE_SUBISSUE.includes(item.FieldName));
    } else if (toggleFilterLogsBtn.followUp) {
      return ticketLogs.filter((item) => item.FieldName === FOLLOWUP);
    } else if (toggleFilterLogsBtn.insurerStatus) {
      return ticketLogs.filter((item) => item.FieldName === INSURER_STATUS );
    } else if (toggleFilterLogsBtn.leadId) {
      return ticketLogs.filter((item) => item.FieldName === LEADID );
    } 
    else{
      return [];
    }
  };

  return (
    <div className="email-chain">
      <List className="logs-block">
        {
          isLogsLoading
          ?
          <div className="spinner-loader">
            <Spinner/>
         </div>
          :
          getFilteredData().map((log)=>{
           return <ListItem>
            <div className="sub-status">
              <div className="status-txt">
                <span className="status-date-time">{convertToIsoFormateLogActivity(log.CreatedOn)}</span>
                
                {
                  log.FieldName === "Refund"
                  ?
                  <h5>{log.NewValue}</h5>
                  :
                  log.FieldName === "Remarks"
                  ?
                  <h5><strong>{log.FieldName}</strong> Updated by {log.CreatedByName}</h5>
                  :
                  (
                    log.FieldName === "New Ticket"
                    ?
                    <h5><strong>{log.FieldName}</strong> Created by {log.CreatedByName}</h5>
                    :
                    <h5><strong>{log.FieldName}</strong> Updated to <strong>{log.FieldName && log.FieldName.toLowerCase()== "journey flag" ? (log.NewValue == 1 ? "Yes" : "No" ): log.NewValue}</strong> by {log.CreatedByName}</h5>
                  )
                  
                }
                
                {log.OldValue && <p>Previous Value : <strong>{log.FieldName && log.FieldName.toLowerCase()== "journey flag" ? (log.OldValue == 1 ? "Yes" : "No" ): log.OldValue}</strong></p>}</div></div>
            </ListItem>
          })
        }
       
      </List>
    </div>
  );
}
