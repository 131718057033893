import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function WecareSidebar({ setSelectedPage }) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [userType, setUserType] = useState(
    JSON.parse(localStorage.getItem("UserDetails"))?.UserType
  );
  function openpage(val) {
    // setSelectedPage(val);
    switch (val) {
      case 0:
        history.push("/InsurerDashboard");
        break;
      // case 1: setOpen(true);
      // break;
      case 2:
        history.push("/PolicyLink");
        break;
      case 3:
        history.push("/TicketSummary");
        break;
      case 4:
          history.push("/UserManagement")
      default:
        break;
    }
  }

  // const handleClose = () => {

  // }

  return (
    <ul>
      <li>
        <Link title="Home">
          <i className="fa fa-home" onClick={() => openpage(0)}></i>{" "}
        </Link>
      </li>
      {/* <li>
        <Link title="Create Ticket">
          <i className="fa fa fa-ticket" onClick={() => openpage(1)}></i>{" "}
        </Link>
      </li> */}
      <li>
        <Link title="link">
          <i className="fa  fa-link" onClick={() => openpage(2)}></i>{" "}
        </Link>
      </li>
      <li>
        <Link title="Summary">
          <i className="fa fa-list-alt" onClick={() => openpage(3)}></i>
        </Link>
      </li>
      {
        userType == 1 ?
        <li>
          <Link title="User Management">
            <i className="fa fa-user-o" onClick={() => openpage(4)}></i>
          </Link>
        </li>
        : null
      }
    </ul>
  );
}
