// This component is rendered if there are no prior communication in particular ticket
import React, { useRef, useState, useContext, useEffect } from 'react'
// import CKEditor from 'react-ckeditor-component'
import CKEditor from 'ckeditor4-react';
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core'
import {
  GetHTMLTemplate,
  UpdateTicketRemarks,
  GetTicketSummary,
  GetTicketDetailsByTicketID,
  GetHtmlTemplate_V2,
} from '../../../../../constants/apiUrls'
import { GET, POST } from '../../../../../services/apiServices'
import { useSnackbar } from 'notistack'
import Spinner from '../../../../../shared/components/Spinner'
import {
  From,
  DisplayName,
  isCommunicationEligible,
  isEligibleForTicketNewComm,
  HEALTH,
  DISABLE_DEFAULT_TEMPLATE_LOADING
} from '../../../../../Config'
import CloseIcon from '@material-ui/icons/Close'
import ConfirmationDialog from '../../../../../shared/components/ConfirmationDialog'
import TicketContext from '../../../../../TicketContext'
import FileUploadComponent from '../../../../../shared/components/FileUploadComponent'
import {
  handleDrag,
  handleDragIn,
  handleDragOut,
  handleDrop,
  openTriggerInputDialog,
} from '../../../../../shared/methods/methods'
import { useDispatch, useSelector } from 'react-redux'
import CommunicationDialogForTriggers from './CommunicationDialogForTriggers'
import CustomBackdrop from '../../../../../pages/Components/CustomBackdrop';
import { ClockLoader } from 'react-spinners';
import { handleCatch } from '../../../../../shared/methods/apiMethods';
import { setActiveTicketID } from '../../../../../redux/actions/ticketDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    minWidth: '100%',
  },
}))

export default function ReplyBoxContent({
  // templateList,
  oldTicketDetails,
  viewtype,
}) {
  const classes = useStyles()
  const dispatch = useDispatch();
  const ticketContext = useContext(TicketContext)
  const ticketDetails = useSelector(state => state && state.ticketdetails && state.ticketdetails.ticketDetails || null);
  const templateList = useSelector(state => state && state.ticketdetails && state.ticketdetails.templateList || []);
  const [inputMessage, setInputMessage] = useState('')
  const [canResponse, setCanResponse] = useState('')
  const [replyInProgress, setReplyInProgress] = useState(false)
  const [showConfirmationBox, setShowConfirmationBox] = useState(false)
  const [isCommunicationDialogOpen, setIsCommunicationDialogOpen] = useState(
    false,
  )
  const [triggerInput, setTriggerInput] = useState([])
  const [values, setValues] = useState([])
  const [requestId, setRequestId] = useState(null)
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
  const [triggerBoxOpenedFirstTime, setTriggerBoxOpenedFirstTime] = useState(false) // for setting the default payload in case communication box is opened which takes trigger
  const [templateLoading, setTemplateLoading] = useState(false);
  const [copiedTriggerInputs, setCopiedTriggerInputs] = useState([]);
  const [typeInParent, setTypeInParent] = useState("");
  const [ ckEditorReadOnly, setCKEditorReadOnly] = useState(false); //for dynamically changing the state of ckeditor
  const [isCKEDITORLoaded, setIsCKEDITORLoaded] = useState(false); // to check if instance is ready
  const [customerUnreachable, setCustomerUnreachable] = useState(false); // useful only for HEALTH case

  const config = {
    width: 'auto',
    toolbarGroups: [
      {
        name: 'editing',
        groups: ['find', 'selection', 'spellchecker', 'editing'],
      },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
      },

      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },

      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'others', groups: ['others'] },
    ],
    removeButtons:
      'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo',
    fontSize_sizes: '16/16px;24/24px;48/48px;',
    font_names:
      'Arial/Arial, Helvetica, sans-serif;' +
      'Times New Roman/Times New Roman, Times, serif;' +
      'Verdana',
    format_tags: 'p;h1;h2;h3;pre;div',
    removeDialogTabs: 'image:advanced;link:advanced',
    allowedContent: true,
  }

  //Common function for throwing error
  const showMessage = (message, varient) => {
    enqueueSnackbar(message, {
      variant: varient,
      onClick: () => {
        closeSnackbar();
      },
    });
  }


  const GetTicketDetailsByTicketIdApi = (RefObjectId, TicketDetailsID) => {
    GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest, IsInsurerBucket } = response.Data

          let callbackVal
          if (IsCallBackRequest === '0') {
            callbackVal = 'No'
          } else if (IsCallBackRequest === '1') {
            callbackVal = 'Yes'
          } else if (IsCallBackRequest === '2') {
            callbackVal = 'Completed'
          }

          ticketContext.setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: '',
          }))
          ticketContext.setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: '',
          }))
        } else {
          enqueueSnackbar('Something went wrong!', {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
        }
      })
      .catch((err) => {
        handleCatch(err, showMessage);
      })
  }

  //This side-effect changes the state of CKEDitor editability based on conditions mentioned
  useEffect(() => {
    if (oldTicketDetails && isEligibleForTicketNewComm(oldTicketDetails.ProductId)) {
      setCKEditorReadOnly(true);
    }
    else {
      if (canResponse === "select") {
        setCKEditorReadOnly(true)
      }
      else setCKEditorReadOnly(false);
    }
  }, [oldTicketDetails, canResponse])

  const changeTriggerInputs = () => {
    if (canResponse !== "select") {
      setTriggerInput(
        templateList && templateList.filter((i) => i.TriggerName == canResponse)[0]?.TriggerInput
      );
    }
    else setTriggerInput([])
  }

  useEffect(() => {
    if (templateList && templateList.length > 0) {
      changeTriggerInputs()
    }
    setCustomerUnreachable(false); // setting state of customerUnreachable if there is any change in option selected for template types
  }, [templateList, canResponse])

  const getAllTicketSummary = (RefObjectId, TicketDetailsID) => {
    ticketContext.setAllTicketsLoading(true)
    GET(`${GetTicketSummary}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter(
              (i) => i.StatusID != 3 && i.StatusID != 4,
            ).sort(
              (a, b) =>
                (b.LastUpdatedOn || b.CreatedOn) -
                (a.LastUpdatedOn || a.CreatedOn),
            )
            : []
          const sortedData2 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 3).sort(
              (a, b) =>
                (b.LastUpdatedOn || b.CreatedOn) -
                (a.LastUpdatedOn || a.CreatedOn),
            )
            : []
          const sortedData3 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 4).sort(
              (a, b) =>
                (b.LastUpdatedOn || b.CreatedOn) -
                (a.LastUpdatedOn || a.CreatedOn),
            )
            : []
          ticketContext.setAllTickets({
            ...response.Data,
            AllTicketList: [...sortedData1, ...sortedData2, ...sortedData3],
          })
        } else {
          enqueueSnackbar('Something went wrong!', {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
        }
        ticketContext.setAllTicketsLoading(false)
        dispatch(setActiveTicketID(response.Data.TicketId))
        ticketContext.setActiveTicketId(response.Data.TicketId)
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false)
        handleCatch(err, showMessage);
      })
  }

  const handleReplySend = () => {
    if (replyInProgress) {
      return
    }

    const payload = {
      PrevObjectID: oldTicketDetails.RefObjectId,
      TicketId: oldTicketDetails.TicketId,
      TicketDetailId: oldTicketDetails.TicketDetailId,
      Subject: oldTicketDetails.ConversationTopic,
      ConversationTopic: oldTicketDetails.ConversationTopic,
      LeadID: oldTicketDetails.LeadID,
      CustomerName: oldTicketDetails.CustomerName,
      CustomerID: oldTicketDetails.CustomerId,
      ProductID: oldTicketDetails.ProductId,
      ProductName: oldTicketDetails.ProductName,
      IsSpam: false,
      IsManualComment: false,
      From: From,
      CCEmail: '',
      ToReceipent: [oldTicketDetails.EmailId],
      Body: inputMessage,
      IsInsurer: false,
      IsMoved: false,
      MailAttachments:
        selectedFile.length > 0
          ? selectedFile.map((item) => ({
            FileName: item.FileName,
            AttachemntContent: item.AttachemntContent,
          }))
          : [],
      HasAttachment: selectedFile.length > 0 ? true : false,
      DisplaySenderName: DisplayName,
      TicketSource: oldTicketDetails.Source,
      ActionType: 0,
      StatusName: oldTicketDetails.Status,
      SubStatusName: oldTicketDetails.SubStatusName,
      InsurerID: oldTicketDetails.SupplierId,
      StatusID: oldTicketDetails.StatusID,
      SubStatusID: oldTicketDetails.SubStatusID,
      TicketSubSource: '',
      RequestId: requestId,
      EscalationType: oldTicketDetails.EscalationType,
      IssueTypeName: oldTicketDetails.IssueName,
      TicketDetailsID: oldTicketDetails.TicketDetailsID,
      IsJAG:
        oldTicketDetails && oldTicketDetails.IsJAG ? oldTicketDetails.IsJAG : 0,
      JAGEmployeeId:
        oldTicketDetails && oldTicketDetails.JAGEmployeeId
          ? oldTicketDetails.JAGEmployeeId
          : '',
      IssueTypeID:
        oldTicketDetails && oldTicketDetails.IssueTypeId
          ? oldTicketDetails.IssueTypeId
          : 0,
      CxUnreachable : customerUnreachable || false, // customer unreachable
      HCSSource: oldTicketDetails && oldTicketDetails.HCSSource
    }
    setReplyInProgress(true)
    POST(
      `${UpdateTicketRemarks}/${canResponse
        ? canResponse
        : templateList && templateList.length
          ? templateList[0].TriggerName
          : 'DefaultTemplate'
      }`,
      payload,
    )
      .then((res) => {
        if (res.ErrorCode === 0) {
          ticketContext.GetTicketCommApi(
            oldTicketDetails.RefObjectId,
            oldTicketDetails.TicketDetailsID,
          )
          getAllTicketSummary(
            oldTicketDetails.RefObjectId,
            oldTicketDetails.TicketDetailsID,
          )
          GetTicketDetailsByTicketIdApi(
            oldTicketDetails.RefObjectId,
            oldTicketDetails.TicketDetailsID,
          )
          enqueueSnackbar(res.Message || 'Success', {
            variant: 'success',
            onClick: () => {
              closeSnackbar()
            },
          })
          ticketContext.GetTicketLogsApi()
          ticketContext.GetFilterCountApi()
          handleDisCard()
        } else {
          enqueueSnackbar(res.Message || 'Something went wrong!', {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
        }
        setReplyInProgress(false)
      })
      .catch((err) => {
        console.log(err)
        setReplyInProgress(false)
      })
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const onEditorChange = (event) => {
    if (!isCommunicationEligible(oldTicketDetails.ProductId)) {
      setInputMessage(event.editor.getData())
    }
  }

  const [selectedFile, setselectedFile] = useState([])

  const hiddenFileInput = useRef(null)

  const getHtmlTemplateApi = (triggerName) => {
    let payload
    let apiEndPoint = GetHTMLTemplate
    setTemplateLoading(true); // for clock ticking backdrop
    if (isCommunicationEligible(oldTicketDetails.ProductId)) {
      apiEndPoint = GetHtmlTemplate_V2
      payload = {
        TriggerName: triggerName,
        LeadID: oldTicketDetails.LeadID,
        TicketId: oldTicketDetails.TicketId,
        CommunicationType: 1,
        ProductId: oldTicketDetails.ProductId,
        RefObjectId: oldTicketDetails.RefObjectId,
        CustomerId : oldTicketDetails?.CustomerId,
      }
      // setIsCommunicationDialogOpen(true)
    } else {
      payload = {
        ProductID: oldTicketDetails.ProductId,
        TriggerName: triggerName,
        RefObjectID: oldTicketDetails.RefObjectId,
        Type: 1,
        CustomerId : oldTicketDetails?.CustomerId,
      }
    }

    POST(apiEndPoint, payload)
      .then((res) => {
        setTemplateLoading(false);
        if (res.ErrorCode === 0) {
          setInputMessage(
            isCommunicationEligible(oldTicketDetails.ProductId)
              ? res.Data.HtmlBody
              : res.Data.EmailTemplate,
          )
          setselectedFile(res.Data.MailAttachments ? res.Data.MailAttachments.map((item, index)=>  ({...item, id : index })) : []); // for new template requirement
        } else {
          showMessage(res.Message || "Failed to fetch template","error");
          setCanResponse("select");
          setInputMessage("");
        }
      })
      .catch((err) => {
        setTemplateLoading(false);
        setCanResponse("select");
        handleCatch(err, showMessage);
      })
  }

  useEffect(()=>{
    if(values && values.length>0 && typeInParent){
      fetchEmailBody(canResponse);
    }
  },[values])

  const fetchEmailBody = (triggerName, CxUnreachable=customerUnreachable) => {
    setIsTemplateLoaded(true);
    setTemplateLoading(true);
    let tempFilteredTemplateList = templateList && templateList.filter((i) => i.TriggerName == canResponse)[0]?.TriggerInput || [];
    let RequiredPayloadTriggerNames =[];
    if(tempFilteredTemplateList && tempFilteredTemplateList.length>0){
      RequiredPayloadTriggerNames= tempFilteredTemplateList.map(item=> item.InputName)
    }
    let RequiredTriggerWithValues = {};
    RequiredPayloadTriggerNames && RequiredPayloadTriggerNames.length> 0 && RequiredPayloadTriggerNames.forEach(trigger=>{
      RequiredTriggerWithValues[trigger] = Array.isArray(values) && values.length > 0 ? values.map(item => item[trigger]).filter(item => item).join(",") : triggerBoxOpenedFirstTime ? `<${trigger}>` : "";
    })
    console.log('trigger values--->', RequiredTriggerWithValues)
    const payload = {
      triggerName: triggerName,
      leadID: oldTicketDetails.LeadID,
      ticketId: oldTicketDetails.TicketId,
      communicationType: 1,
      productId: oldTicketDetails.ProductId,
      refObjectId: oldTicketDetails.RefObjectId,
      IsMultipleInput: values && values.length>1 ? true : false,
      triggerInput: {
        ...RequiredTriggerWithValues,
        TATType: typeInParent ? typeInParent : "",
        CxUnreachable: CxUnreachable,
      },
      CustomerId : oldTicketDetails?.CustomerId,
    }
    POST(GetHtmlTemplate_V2, payload)
      .then((res) => {
        setCopiedTriggerInputs([...values])
        setTemplateLoading(false);
        if (res.ErrorCode === 0) {
          setInputMessage(res.Data.HtmlBody)
          setIsCommunicationDialogOpen(false)
          setRequestId(res.Data.RequestId)
          setValues([])
          setIsTemplateLoaded(false)
        } else {
          enqueueSnackbar(res.Message, {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
          setCanResponse("select")
          setIsCommunicationDialogOpen(false)
          setValues([])
          setIsTemplateLoaded(false)
        }
      })
      .catch((err) => {
        setTemplateLoading(false);
        setCanResponse("select")
        setIsCommunicationDialogOpen(false)
        handleCatch(err, showMessage);
      })
  }

  useEffect(() => {
    setTriggerBoxOpenedFirstTime(true);
    setCopiedTriggerInputs([]);
    setValues([]);
    setTypeInParent("");
    if (canResponse) {
      handleTemplateChange();
    }
  }, [canResponse])

  const handleTemplateChange = () => {
    if (canResponse !== 'select') {
      if (isCommunicationEligible(oldTicketDetails.ProductId)) {
        if (
          templateList.filter((i) => i.TriggerName === canResponse)[0]
            .TriggerInput.length > 0
        ) {
          fetchEmailBody(canResponse);
          // setIsCommunicationDialogOpen(true)
        } else {
          fetchEmailBody(canResponse)
        }
      } else {
        getHtmlTemplateApi(canResponse)
      }
    } else {
      setInputMessage('');
    }
  }



  const handleDisCard = () => {
    setInputMessage('')
    if (ticketDetails && isEligibleForTicketNewComm(ticketDetails.ProductId)) {
      setCanResponse('select')
    }
    else {
      setCanResponse(
        templateList && templateList.length > 0
          ? templateList[0].TriggerName
          : 'DefaultTemplate',
      )
      getHtmlTemplateApi(
        templateList && templateList.length > 0
          ? templateList[0].TriggerName
          : 'DefaultTemplate',
      )
    }
    setselectedFile([])
  }

  //This side effect selects and loads template on successful fetch of templateList, based on conditions
  useEffect(() => {
    if (
      Array.isArray(templateList) &&
      templateList.length > 1 &&
      templateList[0].TriggerName
    ) {
      if (ticketDetails && isEligibleForTicketNewComm(ticketDetails.ProductId) || DISABLE_DEFAULT_TEMPLATE_LOADING.includes(ticketDetails.ProductId)){
        return
      }
      else {
        setCanResponse(templateList[0].TriggerName)
      }

    }
  }, [templateList])

  const handleRemoveFile = (id) => {
    const fileList = selectedFile.filter((item) => item.id !== id)
    setselectedFile(fileList)
  }

  const sendClicked = () => {
    // for refund id trigger
    if (
      oldTicketDetails &&
      oldTicketDetails.RefundId &&
      oldTicketDetails.RefundId > 0 &&
      oldTicketDetails.StatusID != 3
    ) {
      let list = [
        'All_Ticket_Em_ResolvedRedirectMyaccountMail',
        'All_Ticket_Em_InboundResolved',
      ]
      if (list.indexOf(canResponse) > -1) {
        enqueueSnackbar(
          'You cannot send this trigger if the cancellation request is under process ',
          {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          },
        )
        return
      }
    }

    if (
      oldTicketDetails &&
      oldTicketDetails.Source &&
      !(['inbound', 'c2c','chat'].indexOf(oldTicketDetails.Source.toLowerCase()) > -1)
    ) {
      let list = [
        'All_Ticket_Em_ResolvedRedirectMyaccountMail',
        'All_Ticket_Em_InboundResolved',
      ]
      if (list.indexOf(canResponse) > -1) {
        enqueueSnackbar(
          'You can only send this trigger on IB,C2C and Chat source tickets',
          {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          },
        )
        return
      }
    }


    if (triggerInput && triggerInput.length > 0 && copiedTriggerInputs && !validateTriggersInputs()) {
      return showMessage("Please update all fields to continue", "error")
    }

    if (inputMessage.length > 0) {
      setShowConfirmationBox(true)
    } else {
      enqueueSnackbar('Mail body cannot be empty!', {
        variant: 'error',
        onClick: () => {
          closeSnackbar()
        },
      })
    }
  }

  //Validate triggers only for cases where response type has triggers  
  const validateTriggersInputs = () =>{
    let flag = true;
    let keys = triggerInput.map(item => item.InputName)
    if(copiedTriggerInputs && copiedTriggerInputs.length>0 && copiedTriggerInputs[0]){
      Object.keys(copiedTriggerInputs[0]).forEach(item=>{
        if(!copiedTriggerInputs[0][item]){
          flag = false;
        }
      })
      if(Object.keys(copiedTriggerInputs[0]).length !== keys.length){
        flag = false;
      }
    }
    else flag = false;

    if(flag){
      return true
    }
    else return false 
  }

  //handling checkbox selection for customer unreachable for non-editable templates
  const handleCheckboxSelection = (e) => {
    setValues([]);
    setCopiedTriggerInputs([]);
    setTypeInParent("");
    setCustomerUnreachable(e.target.checked);
    fetchEmailBody(canResponse, e.target.checked);
  } 


  return (
    <>
      {!ticketContext.isActionsAllowed ||
        oldTicketDetails.StatusID === 4 ||
        oldTicketDetails.StatusID === 3 ||
        oldTicketDetails.StatusID === 6 ||
        oldTicketDetails.ParentTicketID ? (
        ''
      ) : (
        <div
          className="reply-popup"
          onDragOver={handleDrag}
          onDrop={(e) => handleDrop(e, setselectedFile, hiddenFileInput)}
          onDragLeave={handleDragOut}
          onDragEnter={handleDragIn}
        >
          {templateLoading && <CustomBackdrop open={templateLoading}><ClockLoader color="white"/></CustomBackdrop>}
          <Grid container spacing={1}>
            <Grid item md={4} sm={4} xs={12}>
              {viewtype == 0 && (
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Can Response
                  </InputLabel>
                  <Select
                    native
                    value={canResponse}
                    // onChange={(e) => handleCanResponseChange(e)}
                    onChange={(e) => setCanResponse(e.target.value)}
                    label="Can Response"
                    inputProps={{
                      name: 'canResponse',
                      id: 'outlined-age-native-simple canResponse',
                    }}
                  >
                    <option value="select">select</option>
                    {templateList.map((item) => {
                      return (
                        <option id="TriggerName" value={item.TriggerName}>
                          {item.Description}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container className="mail-to-info">
            <Grid item md={12} sm={12} xs={12} className="border-bottom">
              <strong className="mail-to-input">
                To:
                <span id="mailInfoEmailId">{oldTicketDetails.EmailId}</span>
              </strong>
            </Grid>

            <Grid item md={12} sm={12} xs={12} className="border-bottom">
              <strong>
                Subject:{' '}
                <span id="mailInfoConversationTopic">
                  {oldTicketDetails.ConversationTopic
                    ? oldTicketDetails.ConversationTopic
                    : ''}{' '}
                </span>
              </strong>
            </Grid>
              <Grid item md={12} xs={12} className="reply-box">
                <div className="enter-variable-btn">
                  {canResponse && canResponse !== "select" && oldTicketDetails && isEligibleForTicketNewComm(oldTicketDetails.ProductId) &&
                    <FormGroup>
                      <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" checked={customerUnreachable} onChange={handleCheckboxSelection}/>}
                        label="Customer not reachable"
                        labelPlacement="Customer not reachable"
                      />
                    </FormGroup>}
                  {triggerInput && triggerInput.length > 0 ? <Button onClick={() => openTriggerInputDialog(triggerInput, setIsCommunicationDialogOpen)}>Update Field</Button> : null}
                </div>
            {/* {triggerInput && triggerInput.length > 0 ? <Button className="enter-variable-btn" onClick={()=>openTriggerInputDialog(triggerInput, setIsCommunicationDialogOpen )}>Update Field</Button> : null} */}
                <CKEditor
                  data={inputMessage ? inputMessage : ""}
                  config={config}
                  onChange={(event) => {
                    onEditorChange(event)

                  }}
                  onInstanceReady={(instance) => {
                    instance.data = inputMessage
                    setIsCKEDITORLoaded(true);
                  }}
                  name="Email-CK-EDITOR_SINGLE"
                  id="Email-CK-EDITOR_SINGLE"
                  readOnly={isCKEDITORLoaded ? ckEditorReadOnly : false}
                />
              {/* <CKEditor
                config={config}
                content={inputMessage}
                events={{
                  change: onEditorChange,
                  blur: onEditorChange,
                }}
              /> */}
            </Grid>
          </Grid>
          <div className="action-bar">
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={8} xs={6}>
                <p className="attach-btn" id="actionBarAttachement">
                  {viewtype == 0 && (
                    <FileUploadComponent
                      files={selectedFile}
                      isMultipleFileAllowed={true}
                      setFiles={setselectedFile}
                      fileRef={hiddenFileInput}
                    />
                  )}
                  {selectedFile?.map((item) => {
                    return (
                      <span className="file-detail" key={item.id}>
                        {item.FileName}{' '}
                        <button onClick={() => handleRemoveFile(item.id)}>
                          <CloseIcon />
                        </button>
                      </span>
                    )
                  })}
                </p>
              </Grid>
              <Grid item md={4} xs={6}>
                {viewtype == 0 && (
                  <Button
                    className="send-btn"
                    color="primary"
                    onClick={sendClicked}
                    id="actionBarSendBtn"
                  >
                    {replyInProgress ? <Spinner /> : 'Send'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      <ConfirmationDialog
        isConfirmDialogOpen={showConfirmationBox}
        setConfirmDialogOpen={setShowConfirmationBox}
        updateFn={handleReplySend}
      />
      {isCommunicationDialogOpen && triggerInput && triggerInput.length > 0 ? (
        <CommunicationDialogForTriggers
          isDialogOpen={isCommunicationDialogOpen}
          setDialogOpen={setIsCommunicationDialogOpen}
          triggerInput={triggerInput}
          setInputMessage={setInputMessage}
          oldTicketDetails={oldTicketDetails}
          canResponse={canResponse}
          fetchEmailBody={fetchEmailBody}
          valuesInParent={values}
          setValuesInParent={setValues}
          setCanResponse={setCanResponse}
          isTemplateLoaded={isTemplateLoaded}
          setIsTemplateLoaded={setIsTemplateLoaded}
          previousValues = {copiedTriggerInputs}
          setTypeInParent = { setTypeInParent}
          typeInParent = { typeInParent }
        />
      ) : (
        ''
      )}
    </>
  )
}
