import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

export default function DynamicConfirmationDialog({
    title,
    buttonWithFunction,
    open,
    handleClose,
}) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                className="confirmation-dialog-box"
            >
                <h4 dangerouslySetInnerHTML={{__html : title}}>
                </h4>
                <DialogActions>
                    <div className="raise-ticket-pop">
                        {buttonWithFunction &&
                            buttonWithFunction.length > 0 &&
                            buttonWithFunction.map((item, index) => {
                                return (
                                    <Button
                                        key={index + "Button"}
                                        className={item.className}
                                        onClick={item.onClick}
                                        autoFocus={item.submitButton ? true : false}
                                    >
                                        {item.Name}
                                    </Button>
                                );
                            })}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
