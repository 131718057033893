import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ListItem,
    List,
    Grid,
    ExpansionPanel,
    Button
  } from "@material-ui/core";
  import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from 'react-redux';
import MailContent from '../TicketTimeline/Components/TicketsTimelines/Components/MailContent';
import AttachementList from '../TicketTimeline/Components/TicketsTimelines/Components/AttachementList';
import { convertToIsoFormateLogActivity } from '../../shared/methods/methods';
import { MY_ACCOUNT, pbReplyFilters, TICKET_SOURCE_CARE, isCommunicationEligible } from '../../Config';
import DownloadMail from '../TicketTimeline/Components/TicketsTimelines/Components/DownloadMail';
import ForwardMailContent from '../TicketTimeline/Components/TicketsTimelines/Components/ForwardMailContent';
import { GetTemplates_V2 } from '../../constants/apiUrls';
import { fetchLatestTicketComm, fetchLatestTicketdetails, setTemplateList } from '../../redux/actions/ticketDetails';
import { POST } from '../../services/apiServices';
import { useSnackbar } from 'notistack'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function InsurerMailChain(props) {
  const { TicketCommunication,templateList,isValidForSME,OpenedTicketDetails,OnSetTemplateList,fetchLatestTicketDetails, fetchLatestTicketCommunication } = props
  const [goToTemplate, setGoToTemplate] = useState(false)
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (TicketCommunication && TicketCommunication.length > 0) {
      getFilteredData()
      GetTemplatesApi()
    }
  }, [TicketCommunication])

  const GetTemplatesApi = () => {
    if (isCommunicationEligible(OpenedTicketDetails.ProductId)) {
      const payload = {
        LeadID: OpenedTicketDetails.LeadID,
        TicketID: OpenedTicketDetails.TicketId.toString(),
        CommunicationType: 1,
        ProductId: OpenedTicketDetails.ProductId,
        SubProductId: 0,
        CorpClientId: OpenedTicketDetails.Source,
      };

      // POST(GetTemplates_V2, payload)
      //   .then((res) => {
      //     if (res.ErrorCode === 0) {
      //       OnSetTemplateList(res.Data.TemplateTriggerList);
      //     } else {
      //       enqueueSnackbar("Something went wrong!", {
      //         variant: "error",
      //         onClick: () => {
      //           closeSnackbar();
      //         },
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // return;
    }
  }

  const getFilteredData = () => {
    const FilteredData = TicketCommunication.filter(
      (data) =>
        data.ActionType !== 3 &&
        data.ActionType !== 1 &&
        data.ActionType !== 8 &&
        data.ActionType !== 9 &&
        data.ActionType !== 5
    );

    console.log('filtered data', FilteredData)

    // let sortedArray = FilteredData.sort((a,b)=> new Date(b.CreatedOn)- new Date(a.CreatedOn))
    // console.log('sorted data', sortedArray)
    // return sortedArray
    return FilteredData
    // if (toggleFilterBtn.all) {
    //   return FilteredData;
    // } else if (toggleFilterBtn.email) {
    //   return FilteredData.filter((item) =>
    //     emailFilters.includes(
    //       item.TicketSource ? item.TicketSource.toLowerCase() : ""
    //     )
    //   );
    // } else if (toggleFilterBtn.esclation) {
    //   return FilteredData.filter((item) =>
    //     esclationFilters.includes(
    //       item.TicketSource ? item.TicketSource.toLowerCase() : ""
    //     )
    //   );
    // } else if (toggleFilterBtn.customerReply) {
    //   return FilteredData.filter((item) =>
    //     customerReplyFilters.includes(
    //       item.TicketSource ? item.TicketSource.toLowerCase() : ""
    //     )
    //   );
    // } else if (toggleFilterBtn.pbReply) {
    //   return FilteredData.filter((item) =>
    //     pbReplyFilters.includes(
    //       item.TicketSource ? item.TicketSource.toLowerCase() : ""
    //     )
    //   );
    // } else if (toggleFilterBtn.attachments) {
    //   return FilteredData.filter(
    //     (item) =>
    //       (item.TicketSource ? item.TicketSource.toLowerCase() : "") !=
    //       "pbreply" &&
    //       item.MailAttachments &&
    //       Array.isArray(item.MailAttachments) &&
    //       item.MailAttachments.length > 0
    //   );
    // } else if (toggleFilterBtn.IB) {
    //   return FilteredData.filter(
    //     (item) =>
    //       (item.TicketSource ? item.TicketSource.toLowerCase() : "") ===
    //       "inbound"
    //   );
    // } else if (toggleFilterBtn.C2C) {
    //   return FilteredData.filter(
    //     (item) =>
    //       (item.TicketSource ? item.TicketSource.toLowerCase() : "") === "c2c"
    //   );
    // } else {
    //   return [];
    // }
  };

  const FilteredEmailList = (emails) => {
    const data = [];
    emails.forEach((email) => {
      if (email) {
        data.push(email);
      }
    });
    return data;
  };

  //Download mail button visible in below conditions

  const isDownloadMailVisible = (source = "", eventType = "") => {
    if (
      (source && source.toLowerCase().includes(TICKET_SOURCE_CARE)) ||
      (eventType && eventType.toLowerCase().includes(TICKET_SOURCE_CARE))
    ) {
      return true;
    } else if (
      (source && source.toLowerCase().includes(MY_ACCOUNT)) ||
      (eventType && eventType.toLowerCase().includes(MY_ACCOUNT))
    ) {
      return true;
    } else {
      return false;
    }
  };


  return(getFilteredData().map((item,index)=>{
    return(
    <div className="email-chain scrool-toll">
        <List >
          <ListItem
            className={
              "email-customer" +
              (index === 0 &&
                item.TicketSource &&
                item.TicketSource.toLowerCase() !== "pbreply"
                ? " pb-reply"
                : "")
            }
          >
            <span className="email-tag email">
              {item.TicketSource}{" "}
              {item.TicketSubSource ? " - " + item.TicketSubSource : ""}
            </span>

            <ExpansionPanel defaultExpanded={true} style={{ display: "block" }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon className="arrow" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item md={12} xs={12}>
                    <i className="fa fa-user-circle"></i>{" "}
                    
                    {item.EventType ? item.EventType : item.TicketSource}{" "}
                    {isDownloadMailVisible(
                      item.TicketSource,
                      item.EventType
                    ) ? (
                      <DownloadMail MailRefObjId={item.ObjectID} />
                    ) : (
                      ""
                    )}
                    <div className="mail-timing">
                      {/* {item && item.TicketSource && item.TicketSource.toLowerCase() === 'chat' && <Button variant="outlined" onClick={() => setOpenChatWindow(true)} className="chat-btn-history">
                        <i className="fa fa-comments" aria-hidden="true"></i>
                      </Button>} */}
                      <span>{convertToIsoFormateLogActivity(new Date(item.CreatedOn).getTime())}</span>
                    </div>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ display: "block" }}>
                <Grid container className="mail-heading">
                  <Grid item md={12} xs={12}>
                    <Grid container>
                      <Grid md={9} xs={7}>
                        <p>{item.Subject}</p>
                      </Grid>
                      {/* {ticketContext.isActionsAllowed && viewtype == 0 ? (
                        <Grid md={3} xs={5} className="send-to-insurer">
                          <SendToInsurerCheckbox
                            id={item.id}
                            IsInsurer={item.IsInsurer}
                            oldTicketDetails={oldTicketDetails}
                          />
                        </Grid>
                      ) : (
                        ""
                      )} */}
                    </Grid>
                    {item.From &&
                      // pbReplyFilters.includes(
                      //   item.TicketSource ? item.TicketSource.toLowerCase() : ""
                      // ) && (
                        <p>
                          <span className="from-to">
                            From: {item.DisplaySenderName || ""} ({item.From || ""})
                          </span>
                        </p>
                      // )
                      }
                    {item.ToReceipent &&
                      Array.isArray(item.ToReceipent) &&
                      FilteredEmailList(item.ToReceipent).length > 0 && (
                        <span className="from-to">
                          To: {FilteredEmailList(item.ToReceipent).join(",")}
                        </span>
                      )}
                    {item.CCEmail &&
                      Array.isArray(item.CCEmail) &&
                      FilteredEmailList(item.CCEmail).length > 0 && (
                        <p>
                          <span className="from-to">
                            CC: {FilteredEmailList(item.CCEmail).join(",")}
                          </span>
                        </p>
                      )}
                  </Grid>
                </Grid>
                <AttachementList attachments={item.MailAttachments} isActionsAllowed={true} />
                <MailContent
                  ind={index}
                  Body={item.Body}
                  EventType={item.EventType}
                  TicketSource={item.TicketSource}
                />
                <ForwardMailContent
                    goToTemplate={goToTemplate}
                    setGoToTemplate={setGoToTemplate}
                    ticketData={item}
                    templateList={templateList}
                    oldTicketDetails={OpenedTicketDetails}
                    isValidForSME={(OpenedTicketDetails && OpenedTicketDetails.ProductId == 131 && OpenedTicketDetails.SubStatusID && OpenedTicketDetails.IssueTypeId && OpenedTicketDetails.SubIssueID && OpenedTicketDetails.LeadID) ? true : false}
                    fetchLatestTicketDetails={fetchLatestTicketDetails}
                    index={index}
                    fetchLatestTicketCommunication={fetchLatestTicketCommunication}
                  />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </ListItem>
        </List>
    </div>
   )
  })
  )
}
const mapStateToProps = (state) => {
  return {
    OpenedTicketDetails: state && state.ticketdetails && state.ticketdetails.ticketDetails || {},
    TicketCommunication :  state && state.ticketdetails && state.ticketdetails.ticketComm || [],
    templateList :  state && state.ticketdetails && state.ticketdetails.templateList || []

  }
}

const mapDispatchToProps =(dispatch) =>{
  return{
    OnSetTemplateList: (payload) =>
    dispatch(setTemplateList(payload)),
    fetchLatestTicketDetails : (payload)=> dispatch(fetchLatestTicketdetails(payload)),
    fetchLatestTicketCommunication : (payload) =>dispatch(fetchLatestTicketComm(payload))
  }
} 

export default connect(mapStateToProps, mapDispatchToProps)(InsurerMailChain)

