import { ReduxActionGeneratorFunction } from '../../shared/methods/apiMethods';
import * as ActionType from '../types/types';


export const navigateToTab = (payload) =>{
    return ReduxActionGeneratorFunction(ActionType.NAVIGATE_TO_TAB, payload)
}

export const setConfirmationDialogControls = (payload) =>{
    return ReduxActionGeneratorFunction(ActionType.SET_CONFIRM_DIALOG_CONTROLS, payload)
}

export const refreshAnythingBasedOnKey = (payload) =>{
    return ReduxActionGeneratorFunction(ActionType.SET_REFRESH_ANYTHING_BASED_ON_KEY, payload)
}

export const setConditionalRenderNewCustomerPendency = (payload) =>{
    return ReduxActionGeneratorFunction(ActionType.CONDITIONALLY_RENDER_NEW_CUSTOMER_PENDENCY, payload)
}

export const setTempToken = (payload) =>{
    return ReduxActionGeneratorFunction(ActionType.SET_TEMP_TOKEN, payload)
}
