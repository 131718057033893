import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  RadioGroup,
  DialogActions,
} from "@material-ui/core";

import DialogTitle from "../../../shared/components/DialogTitle";
import CustomerEmail from "../../PGRefund/CustomerEmail";
import Spinner from "../../../shared/components/Spinner";
import { GET, POST } from "../../../services/apiServices";
import {
  InitiatePGRefundV2,
  GetTicketDetailsByTicketID,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import TicketContext from "../../../TicketContext";
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch } from "react-redux";
import { setTicketDetails } from "../../../redux/actions/ticketDetails";

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

export default function PGRefundModalV2({
  isDialogOpen,
  isTicketTimelineLoading,
  LeadID,
  ProductID,
  Source,
  TicketDetailsID,
  IssueTypeId,
  RefObjectId,
  SupplierId,
  communications,
  ticketId,
  ticketCreationDate,
  viewtype,
  cancellationResponseData,
  formValues,
  state,
  setState,
  CustomerId,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedRadioVal, setSelectedRadioVal] = useState(0);
  const [isInitiateRefundInProgress, setIsInitiateRefundInProgress] = useState(false);
  const dispatch = useDispatch();
  const ticketContext = useContext(TicketContext);

  const GetTicketDetailsByTicketIdApi = () => {
      // console.log('refobjectid',RefObjectId)
      // console.log('ticketdetailsid',TicketDetailsID)
    GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest, IsInsurerBucket } = response.Data;
          dispatch(setTicketDetails(response.Data));
          let callbackVal;
          if (IsCallBackRequest === "0") {
            callbackVal = "No";
          } else if (IsCallBackRequest === "1") {
            callbackVal = "Yes";
          } else if (IsCallBackRequest === "2") {
            callbackVal = "Completed";
          }

          ticketContext.setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
          ticketContext.setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        console.log('error in PGRefundModalV2 getTicketDetailsByIdApi')
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log(err);
      });
  };
//   initate PG Refund V2
  const handle_Confirm_Cancellation = () => {
    if (isInitiateRefundInProgress) {
              return;
            }
    setIsInitiateRefundInProgress(true)
    let body= `
    <div>From: ${communications.find((item, i) => i == selectedRadioVal)?.From} </div>
    <div>To: care@policybazaar.com </div>
    <div>Subject: ${communications.find((item, i) => i == selectedRadioVal)?.Subject}</div>
    <div>Date: ${communications.find((item, i) => i == selectedRadioVal) ? new Date(communications.find((item, i) => i == selectedRadioVal).CreatedOn) : communications.find((item, i) => i == selectedRadioVal).ReceivedOn}</div>
    <br/>
    <div>${communications.find((item, i) => i == selectedRadioVal)?.Body}</div>
    `
    
    const payload = {
      LeadID: cancellationResponseData.leadId,
      ProductID: cancellationResponseData.productId,
      Source: formValues.Source,
      TicketDetailsID: formValues.TicketDetailsID,
      IssueTypeId: formValues.IssueTypeId,
      CancellationReason: state.reasonForCancellation,
      InsurerID: cancellationResponseData.insurerId,
      ticketId: formValues.TicketId,
      ticketCreationDate: formValues.CreatedOn,
      Body:body,
      CustomerID:CustomerId,
      PrevObjectID:communications.find((item, i) => i == selectedRadioVal)?.id
    };
    // console.log('payload on confirm cancellation',payload)
    //  setTimeout(()=>{
    //   enqueueSnackbar("Success", {
    //             variant: "success",
    //             onClick: () => {
    //               closeSnackbar();
    //             }
    //           }
    //   )
    //   setIsInitiateRefundInProgress(false)
    //   setState({canclEmail_finalDialog:false,reasonForCancellation:"Select"})
    //   GetTicketDetailsByTicketIdApi();
    // },1000)

    POST(InitiatePGRefundV2, payload)
      .then((res) => {
        if (res.Data) {
          console.log('response',res)
          enqueueSnackbar("Success", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },

          });
         GetTicketDetailsByTicketIdApi();
         setState({canclEmail_finalDialog:false,reasonForCancellation:"Select"})
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          setIsInitiateRefundInProgress(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        setIsInitiateRefundInProgress(false);
        console.log(err);
      });
  }






//=====================Just for Reference==============================

//   const handleInitiatePGRefund = () => {
//     if (isInitiateRefundInProgress) {
//       return;
//     }
//     let body= `
//     <div>From: ${communications.find((item, i) => i == selectedRadioVal)?.From} </div>
//     <div>To: care@policybazaar.com </div>
//     <div>Subject: ${communications.find((item, i) => i == selectedRadioVal)?.Subject}</div>
//     <div>Date: ${new Date(ticketCreationDate)}</div>
//     <br/>
//     <div>${communications.find((item, i) => i == selectedRadioVal)?.Body}</div>
//     `
//     const payload = {
//       LeadID: LeadID,
//       ProductID: ProductID,
//       Source: ticketContext.ticketCommData && ticketContext.ticketCommData.length ? ticketContext.ticketCommData[0].TicketSource : Source ,
//       TicketDetailsID: TicketDetailsID,
//       IssueTypeId: IssueTypeId,
//       CustomerID: CustomerID,
//       // Body: communications.find((item, i) => i == selectedRadioVal)?.Body,
//       Body:body,
//       InsurerID: SupplierId,
//       ticketId,
//       ticketCreationDate
//     };
//     setIsInitiateRefundInProgress(true);
//     POST(InitiatePGRefund, payload)
//       .then((res) => {
//         if (res.ErrorCode === 0) {
//           enqueueSnackbar("Success", {
//             variant: "success",
//             onClick: () => {
//               closeSnackbar();
//             },
//           });
//           GetTicketDetailsByTicketIdApi();
//           setIsInitiateRefundInProgress(false);
//         } else {
//           enqueueSnackbar("Something went wrong!", {
//             variant: "error",
//             onClick: () => {
//               closeSnackbar();
//             },
//           });
//           setIsInitiateRefundInProgress(false);
//         }
//         // console.log(res);
//       })
//       .catch((err) => {
//         enqueueSnackbar("Something went wrong!", {
//           variant: "error",
//           onClick: () => {
//             closeSnackbar();
//           },
//         });
//         setIsInitiateRefundInProgress(false);
//         console.log(err);
//       });
//   };

// ===========================================================

  return (
    <div>
      <Dialog maxWidth="md" className="comm-mail-popup" open={state.canclEmail_finalDialog} onClose={()=>setState({canclEmail_finalDialog:false,reasonForCancellation:"Select"})}>
        <DialogTitle className="title-heading" id="customized-dialog-title">
          Cancellation Request Form
          <Button onClick={()=>setState({canclEmail_finalDialog:false,reasonForCancellation:"Select"})} className="cancel-button"><CancelIcon/></Button>
        </DialogTitle>
        {isTicketTimelineLoading ? (
          <div className="spinner-loader">
            <Spinner />
          </div>
        ) : (
          <>
            <DialogContent>
              <RadioGroup
                aria-label="communication"
                name="radio-buttons-group"
                onChange={(e) => {
                  setSelectedRadioVal(e.target.value);
                }}
              >
                {communications && communications.length>0 && communications.map((item, i) => (
                  <CustomerEmail
                    key={i}
                    ind={i}
                    selectedRadioVal={selectedRadioVal}
                    item={item}
                  />
                ))}
              </RadioGroup>
              {communications && communications.length>0 ?
              <Button className="submit-btn-confirm" onClick={handle_Confirm_Cancellation} >
                {" "}
                {isInitiateRefundInProgress ? <Spinner /> : "Confirm Cancellation"}{" "}
              </Button> : 
              <div className="cancellation-msg-box">
                You can proceed with cancellation only via request from Email or MyAccount
              </div>}
            </DialogContent>
           
            {/* <DialogActions
              className="title-heading"
              id="customized-dialog-title"
            >
              
            </DialogActions> */}
          </>
        )}
      </Dialog>
    </div>
  );
}
