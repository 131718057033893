import React, { useReducer, useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import {
  CheckRefundEligibility,
  UpdateSubIssueOnRefundReject,
  MergeTickets,
  GetRefundDetails,
} from "../../../constants/apiUrls";
import { POST, GET } from "../../../services/apiServices";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Dialog,
} from "@material-ui/core";
import CancelIconBtn from "@material-ui/icons/Cancel";
import { formatCurrency } from "../../../shared/methods/methods";
import Spinner from "../../../shared/components/Spinner";
import TicketContext from "../../../TicketContext";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import PGRefundModalV2 from "./PGRefundModalV2";

const RefundCard = ({
  isViewDetail,
  formValues,
  oldTicketDetails,
  setGoToTemplate,
  setIsTicketDetailEditable,
  communications,
  autoOpenRefundCard,
  IsReadOnlyUser,
}) => {
  const [open, setOpen] = useState(false);
  const [cancellationSpinner, setCancellationSpinner] = useState(false); // check eligiblity spinner when data fetching
  const [cancellationResponseData, setCancellationResponseData] = useState();
  const [isError, setIsError] = useState(false);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      confirmCancellationBtn: false,
      reasonForCancellation: "Select",
      ReasonRefund: "",
      isInitiateRefundInProgress: false,
      final_confirmCancellationDialog: false,
      cancellationReasonSelect: false,
      notEligibleCancellationReason: "",
      composeEmailBtn: false,
      cancellationNotEligible: false,
      succRespCfmCln: false,
      canclEmail_finalDialog: false,
    }
  );
  const [iseligible, setIsEligible] = useState(false);
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
    },
    selectEmpty: {},
    appBar: {
      position: "relative",
      backgroundColor: "#ffffff !important",
      color: "#414141",
    },
    title: {
      flex: 1,
      fontSize: "16px",
    },
    h6: {
      color: "#414141",
      fontSize: "14px",
    },

    spinerIcon: {
      position: "absolute",
      top: "9px",
      left: "39%",
      right: "39%",
      margin: "auto",
      "& svg": {
        color: "#0052cc",
      },
    },
  }));

  const ticketContext = useContext(TicketContext);
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (autoOpenRefundCard) {
      handleClickOpen();
    }
  }, [autoOpenRefundCard]);
  const handleMergeTicket = () => {
    const payload = {
      ChildTicketDetailsID: formValues.TicketDetailsID,
      ParentTicketDetailsID: cancellationResponseData.ticketDetailId,
    };
    // console.log('payload',payload)
    // setTimeout(()=>{
    //   enqueueSnackbar("Ticket has been merged", {
    //     variant: "success",
    //     onClick: () => {
    //       closeSnackbar();
    //     },
    //   });
    //   setOpen(false);
    //   GetTicketDetailsByTicketIdApi(
    //     formValues.RefObjectId,
    //     formValues.TicketDetailsID
    //   )
    // },1000)
    POST(MergeTickets, payload)
      .then((res) => {
        if (res && res.ErrorCode === 0 && res.Data) {
          enqueueSnackbar(res.Data.Message, {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
          window.location.reload();
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };

  const handleViewDetails = () => {
    setOpen(true);
    setCancellationSpinner(true);
    setState({ final_confirmCancellationDialog: false });
    setCancellationResponseData("");
    const payload = {
      encryptedRefundRequestId: oldTicketDetails.RefundId,
      userId: "",
    };
    POST(GetRefundDetails, payload)
      .then((response) => {
        setCancellationSpinner(false);
        if (response.ErrorCode == 0) {
          setCancellationResponseData(
            response && response.Data && response.Data.data
          );
        } else {
          setIsError(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleClickOpen = () => {
    setOpen(true);
    setCancellationSpinner(true);
    setState({ final_confirmCancellationDialog: false });
    setCancellationResponseData("");
    const payload = {
      LeadID: oldTicketDetails.LeadID,
    };
    POST(CheckRefundEligibility, payload)
      .then((response) => {
        setCancellationSpinner(false);
        // console.log('response for cancellation', response.Data)
        if (response.ErrorCode == 0) {
          setCancellationResponseData(response.Data);
          if (response.Data.eligible == true) {
            setIsEligible(true);
          }
        } else {
          setIsError(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleClickCompose = () => {
    setOpen(false);
    setGoToTemplate(false);
    const payload = {
      LeadID: cancellationResponseData.leadId,
      ticketId: oldTicketDetails.TicketId,
      RefundID: cancellationResponseData.id,
      RejectionReason: cancellationResponseData.userDisplayedInEligibleReason,
      CancellationReason: state.notEligibleCancellationReason,
      ticketCreationDate: oldTicketDetails.CreatedOn,
    };
    // console.log(payload);
    POST(UpdateSubIssueOnRefundReject, payload)
      .then((response) => {
        // console.log('response for cancellation', response.Data)
        if (response.ErrorCode == 0) {
          setGoToTemplate(true);
          setIsTicketDetailEditable(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => {
    if (state.cancellationReasonSelect && !state.confirmCancellationBtn) {
      window.location.reload();
    }
    setState({ reasonForCancellation: "Select" });
    setOpen(false);
  };

  const handleChangeCancellationReason = (event) => {
    if (event.target.value == 0) {
      setState({
        reasonForCancellation: event.target.value,
        confirmCancellationBtn: false,
      });
    } else {
      setState({
        reasonForCancellation: event.target.value,
        confirmCancellationBtn: true,
      });
    }
    setState({ refundReason: event.target.value });
  };

  const handleChangeCancellationReasonNotEligible = (event) => {
    if (event.target.value == 0) {
      setState({
        notEligibleCancellationReason: event.target.value,
        composeEmailBtn: false,
      });
    } else {
      setState({
        notEligibleCancellationReason: event.target.value,
        composeEmailBtn: true,
      });
    }
    setState({ refundReason: event.target.value });
  };
  return (
    <div>
      <div className="check-eligibility">
        {!isViewDetail && !IsReadOnlyUser ? (
          <Button className="check-eligibility-btn" onClick={handleClickOpen}>
            {formValues && formValues.ParentTicketID
              ? ""
              : "Check Refund eligibility"}{" "}
          </Button>
        ) : (
          !IsReadOnlyUser && (
            <Button
              className="check-eligibility-btn"
              onClick={handleViewDetails}
            >
              {formValues && formValues.ParentTicketID
                ? ""
                : "View Refund Details "}{" "}
            </Button>
          )
        )}
        {cancellationSpinner ? (
          <Dialog
            className="check-eligibility-dialog"
            open={cancellationSpinner}
          >
            <div className="spinner-box">
              <Spinner />
            </div>
          </Dialog>
        ) : (
          <Dialog
            className="check-eligibility-dialog"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            {(isViewDetail || iseligible) &&
            cancellationResponseData &&
            (cancellationResponseData.isFLC || cancellationResponseData.refundDetails) ? (
              <div className="eligible-yes">
                {cancellationResponseData &&
                cancellationResponseData.statusCode == 19 ? (
                  <div className="dialog-head ineligibility">
                    <p>
                      <img
                        style={{ height: "32px", width: "32px" }}
                        src="/images/cross_btn_new.svg"
                        alt="right"
                      />
                      Customer Ineligible for Refund
                    </p>
                    <Button
                      autoFocus
                      onClick={handleClose}
                      className="close-icon"
                      color="primary"
                    >
                      <CancelIconBtn />
                    </Button>
                  </div>
                ) : (
                  <div className="dialog-head" onClose={handleClose}>
                    {!isViewDetail ? (
                      <p>
                        <img src="/images/right_checkmark.svg" alt="right" />{" "}
                        Customer is eligible for refund
                      </p>
                    ) : (
                      <p>
                        <img src="/images/right_checkmark.svg" alt="right" />{" "}
                        Refund Details
                      </p>
                    )}

                    <Button
                      autoFocus
                      onClick={handleClose}
                      className="close-icon"
                      color="primary"
                    >
                      <CancelIconBtn />
                    </Button>
                  </div>
                )}
                <List
                  className={`refund-cards ${
                    cancellationResponseData &&
                    (cancellationResponseData.refundDetails || cancellationResponseData.isFLC) &&
                    cancellationResponseData.statusCode != 19
                      ? ``
                      : `pg-screen`
                  }`}
                >
                  <ListItem>
                    <div className="leftbar-card">
                      <div className="header-box">
                        <div className="img-block">
                          <img
                            src={
                              ticketContext && ticketContext.leadDetails
                                ? ticketContext.leadDetails.SupplierLogo
                                : null
                            }
                            alt="img"
                          />
                        </div>
                        <h4>{oldTicketDetails.SupplierName}</h4>
                      </div>
                      <List>
                        <ListItem>
                          <span>Ticket Detail ID</span>
                          <strong>{oldTicketDetails.TicketDetailsID}</strong>
                        </ListItem>
                        <ListItem>
                          <span>Booking ID</span>
                          <strong>{oldTicketDetails.LeadID}</strong>
                        </ListItem>
                      </List>
                    </div>
                    <div className="reason-box">
                      {
                        isViewDetail ? (
                          cancellationResponseData &&
                          cancellationResponseData.statusCode == 19 &&
                          cancellationResponseData.remarks ? (
                            <div>
                              <b>Reason for ineligibility</b> :{" "}
                              {cancellationResponseData.remarks}
                            </div>
                          ) : (
                            // =================================================================
                            <FormControl className="form-control">
                              <InputLabel id="demo-customized-select-label">
                                Reason For Cancellation
                              </InputLabel>
                              {cancellationResponseData ? (
                                <Select
                                  labelId="demo-customized-select-label"
                                  id="demo-customized-select"
                                  defaultValue={cancellationResponseData.reason}
                                  fullWidth
                                  disabled
                                >
                                  <MenuItem
                                    value={cancellationResponseData.reason}
                                  >
                                    {cancellationResponseData.reason}
                                  </MenuItem>
                                </Select>
                              ) : null}
                            </FormControl>
                          )
                        ) : (
                          <FormControl className="form-control">
                            <InputLabel id="demo-customized-select-label">
                              Reason For Cancellation
                            </InputLabel>
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              value={state.reasonForCancellation}
                              onChange={handleChangeCancellationReason}
                              fullWidth
                            >
                              <MenuItem value={"Select"}>Select</MenuItem>
                              {cancellationResponseData &&
                                cancellationResponseData.reasonsList?.map(
                                  (reason, index) => {
                                    return (
                                      <MenuItem
                                        key={reason.refundReason + index}
                                        value={reason.refundReason}
                                      >
                                        {reason.refundReason}
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </Select>
                          </FormControl>
                        )

                        // ====================================================================
                      }
                      {!isViewDetail ? (
                        <Button
                          disabled={
                            !state.confirmCancellationBtn ||
                            state.reasonForCancellation == "Select" ||
                            state.isInitiateRefundInProgress ||
                            state.succRespCfmCln
                          }
                          disclaimer-box
                          onClick={() => {
                            setState({ canclEmail_finalDialog: true });
                            setOpen(false);
                          }}
                          className="save-btn "
                        >
                          {state.isInitiateRefundInProgress ? (
                            <div className={classes.spinerIcon}>
                              <Spinner />
                            </div>
                          ) : (
                            "Save and Next"
                          )}
                        </Button>
                      ) : null}
                    </div>
                  </ListItem>

                  {cancellationResponseData.isFLC ? (
                    <ListItem>
                      <div className="flc-disclaimer">
                        <p>
                          <strong>Disclaimer :</strong>
                        </p>
                        <p>
                        This Policy is in Free Look Cancellation Period.
                        </p>
                        <p>
                        The refund amount will be based on expenses incurred by Insurer on medical examination and stamp duty charges, after which a proportionate premium will be deducted.
                        </p>
                      </div>
                    </ListItem>
                  ) :
                  cancellationResponseData.refundDetails &&
                  cancellationResponseData.statusCode != 19 ? (
                    <ListItem>
                      <List className="eligiblty-list">
                        <div className="category-box">
                          <ListItem>
                            <p>Category</p>
                          </ListItem>
                          <ListItem>
                            <p>Amount (₹)</p>
                          </ListItem>
                        </div>
                        <ListItem>
                          <p>Net Transaction Amount </p>
                        </ListItem>
                        <ListItem>
                          <p>
                            {cancellationResponseData.refundDetails
                              .netTransactionAmount
                              ? formatCurrency(
                                  cancellationResponseData.refundDetails
                                    .netTransactionAmount
                                )
                              : formatCurrency(0)}
                          </p>
                        </ListItem>
                        {formValues && formValues.SupplierId === 4 ? (
                          <>
                            <ListItem>
                              <p>
                                Gross Deductions
                                <b>{` - ${
                                  100 -
                                  cancellationResponseData.refundDetails
                                    .refundablePercentage
                                    ? cancellationResponseData.refundDetails
                                        .refundablePercentage
                                    : 0
                                }% (A)`}</b>
                              </p>
                            </ListItem>
                            <ListItem>
                              <p className="net-deduct">
                                -
                                {cancellationResponseData.refundDetails
                                  .grossDeductions
                                  ? formatCurrency(
                                      cancellationResponseData.refundDetails
                                        .grossDeductions
                                    )
                                  : formatCurrency(0)}
                              </p>
                            </ListItem>
                            <ListItem>
                              <p>Administrative Deductions </p>
                            </ListItem>
                            <ListItem>
                              <p className="net-deduct">
                                -
                                {cancellationResponseData.refundDetails
                                  .administrativeDeductions
                                  ? formatCurrency(
                                      cancellationResponseData.refundDetails
                                        .administrativeDeductions
                                    )
                                  : formatCurrency(0)}
                              </p>
                            </ListItem>
                            <ListItem>
                              <p>Other Deductions </p>
                            </ListItem>
                            <ListItem>
                              <p className="net-deduct">
                                -
                                {cancellationResponseData.refundDetails
                                  .otherDeductions
                                  ? formatCurrency(
                                      cancellationResponseData.refundDetails
                                        .otherDeductions
                                    )
                                  : formatCurrency(0)}
                              </p>
                            </ListItem>
                          </>
                        ) : null}
                        <ListItem>
                          <p>Net Deductions ( - ) </p>
                        </ListItem>
                        <ListItem>
                          <p className="net-deduct">
                            {cancellationResponseData.refundDetails
                              .netDeductions
                              ? formatCurrency(
                                  cancellationResponseData.refundDetails
                                    .netDeductions
                                )
                              : formatCurrency(0)}
                          </p>
                        </ListItem>
                        <ListItem>
                          <p>Net Refundable Amount </p>
                        </ListItem>
                        <ListItem>
                          <p className="net-refund">
                            {cancellationResponseData.refundDetails
                              .netRefundableAmount
                              ? formatCurrency(
                                  cancellationResponseData.refundDetails
                                    .netRefundableAmount
                                )
                              : formatCurrency(0)}
                          </p>
                        </ListItem>
                      </List>
                    </ListItem>
                  ) : null}
                </List>
              </div>
            ) : // checks if refund is present and data not found
            isViewDetail && !cancellationResponseData ? (
              <div className="no-data-found">
                <img src="/images/no_data_found.svg" alt="No Data Found" />
                <p>No Data Found</p>
              </div>
            ) : (
              //not eligible case default
              <div className="not-eligible">
                <div className="error-view">
                  <div className="text-right">
                    <Button
                      autoFocus
                      onClick={handleClose}
                      color="primary"
                      disabled={IsReadOnlyUser}
                    >
                      <CancelIconBtn />
                    </Button>
                  </div>
                  <div className="not-eligible-box">
                    <img
                      className="img-block"
                      src="/images/not_eligible.svg"
                      alt="not_eligible"
                    />
                    <h5>Not Eligible For Refund</h5>
                    <p className="not-eligible-txt">
                      <strong>Reason - </strong>{" "}
                      {cancellationResponseData?.inEligibleUserDisplayReason}
                    </p>
                    {cancellationResponseData &&
                      cancellationResponseData.reasonsList &&
                      cancellationResponseData.reasonsList.length > 0 && (
                        <FormControl className="form-control">
                          <InputLabel id="demo-customized-select-label">
                            Reason for Cancellation
                          </InputLabel>
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={state.notEligibleCancellationReason}
                            defaultValue={0}
                            onChange={handleChangeCancellationReasonNotEligible}
                            fullWidth
                            disabled={state.cancellationNotEligible}
                          >
                            <MenuItem value={0}>Select</MenuItem>
                            {cancellationResponseData &&
                              cancellationResponseData.reasonsList?.map(
                                (reason, index) => {
                                  return (
                                    <MenuItem
                                      key={reason.refundReason + index}
                                      value={reason.refundReason}
                                    >
                                      {reason.refundReason}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                        </FormControl>
                      )}
                    {cancellationResponseData &&
                    cancellationResponseData.ticketDetailId ? (
                      <Button
                        className="merge-ticket-btn"
                        onClick={handleMergeTicket}
                        disabled={IsReadOnlyUser}
                      >
                        {" "}
                        Do you want to merge this ticket with{" "}
                        {cancellationResponseData.ticketDetailId}
                      </Button>
                    ) : (
                      <Button
                        disabled={!state.composeEmailBtn || IsReadOnlyUser}
                        className="close-btn"
                        onClick={handleClickCompose}
                      >
                        <i className="fa fa-pencil"></i> Compose Email
                      </Button>
                    )}
                  </div>
                  {isError ? (
                    <div className="error-view">
                      <img src="/images/error_404.png" alt="error_404" />
                      <h5>OOPS! An error occurred</h5>
                      <Button className="close-btn">Try Again</Button>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </Dialog>
        )}
      </div>
      {/* ------------------------------------------------- */}
      {/* new Dialog for Confirm Cancellation Next Screen */}

      {state && state.canclEmail_finalDialog && (
        <PGRefundModalV2
          isDialogOpen={state.canclEmail_finalDialog}
          communications={communications}
          cancellationResponseData={cancellationResponseData}
          formValues={formValues}
          RefObjectId={formValues.RefObjectId}
          TicketDetailsID={formValues.TicketDetailsID}
          state={state}
          setState={setState}
          setOpen={setOpen}
          CustomerId={oldTicketDetails.CustomerId}
        />
      )}

      {/* -------------------------------------------- */}
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("state values", state);
  return {
    oldTicketDetails:
      state && state.ticketdetails && state.ticketdetails.ticketDetails
        ? state.ticketdetails.ticketDetails
        : 0,
    IsReadOnlyUser:
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser,
  };
};
export default connect(mapStateToProps, null)(RefundCard);
