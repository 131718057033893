import React, { useState, useReducer } from 'react';
import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { POST } from '../../../../../services/apiServices';
import { Toast } from '../../../../../shared/components';
import { handleCatch } from '../../../../../shared/methods/apiMethods';
import Spinner from '../../../../../shared/components/Spinner';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    formControl: {
        maxWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const ReportData = () => {
    const classes = useStyles();
    const [processType, setProcessType] = useState();
    const [processTypeTouched, setProcessTypeTouched] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [loader, setLoader] = useState(false);
    const fileName = 'OneLeadReport';

    const validate = () =>{
        setProcessTypeTouched(true);
        if(!processType || fromDate > new Date() || fromDate < new Date().setDate(new Date().getDate() - 7)){
            return false
        }
        else return true
    }

    const exportOneLeadData = () => {
        if(!validate()){
            return
        }
        setLoader(true);
        let endpoint = 'GetTicketPullDataExport';
        let payload = {
            ProcessType: processType,
            UnixFromDate: new Date(fromDate).getTime()
        }
        POST(endpoint, payload).then(res => {
            setLoader(false);
            if (res && res['ErrorCode'] === 0) {
                const linkSource = `data:application/xlsx;base64,${res['Data']}`;
                const downloadLink = document.createElement("a");
                const downloadFileName = fileName + ".xlsx";

                downloadLink.href = linkSource;
                downloadLink.download = downloadFileName;
                downloadLink.click();
                Toast.success("Successfully exported");
            }
            else {
                Toast.error(res['Message'] || 'Error occurred while exporting data')
            }
        }).catch(err => {
            setLoader(false);
            handleCatch(err)
        })
    }
    let options = ['MotorOneTicket', 'LifePostOneTicket'];

    return (
        <div>
            <ul>
                <h4>Report Data </h4>
                <li>
                    <FormControl variant="outlined" fullWidth className="form-control select-box" error={processTypeTouched && !processType}>
                        <InputLabel id="demo-simple-select-outlined-label">Process Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={processType}
                            onChange={(e) => {setProcessType(e.target.value); setProcessTypeTouched(true)}}
                            className={classes.selectEmpty}
                            label="Process Type"
                           
                        >
                            {
                                options && options.length > 0 && options.map((item) => {
                                    return <MenuItem disabled={loader} key={item} value={item}>{item}</MenuItem>
                                })
                            }
                        </Select>
                        {processTypeTouched && !processType && <FormHelperText id="my-helper-text">Please select value</FormHelperText>}
                    </FormControl>
                </li>
                <li>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl className="form-control calander-control">
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline-one-lead"
                            label="Date Range"
                            maxDate={new Date()}
                            minDate={new Date().setDate(new Date().getDate() - 7)}
                            value={fromDate}
                            onChange={(date) => setFromDate( date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </FormControl>
                </MuiPickersUtilsProvider>
                </li>
                <div>
                    <Button className="submit-btn" disabled={loader} onClick={exportOneLeadData}>Export {loader && <Spinner />}</Button>
                </div>
            </ul>
        </div>
    )
}

export default ReportData
