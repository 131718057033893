import React, { useState, useRef, useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid, Button, TextField, MenuItem, Link } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import { toBase64 } from '../../../../../shared/methods/methods';
import { POST } from '../../../../../services/apiServices';
import { handleCatch } from '../../../../../shared/methods/apiMethods';
import { Toast } from '../../../../../shared/components';
import Spinner from '../../../../../shared/components/Spinner';
import PublishIcon from "@material-ui/icons/Publish";


export default function InsertSubIssues() {
    const [fileUpload, setFileUpload] = useState();
    const fileUploadRef = useRef();
    const [loading, setLoading] = useState(false);
    
    

    const handleFileSelect = async (e) => {
        const base64 = await toBase64(e.target.files[0]);
        setFileUpload({
            FileName: e.target.files[0].name,
            AttachmentContent: base64.split("base64,")[1],
        });
        fileUploadRef.current.value = "";
    }

    const uploadTeamLeadPriority = () =>{
        setLoading(true);
        let endpoint = 'UpdateTLAssigned';
        let payload = {
            "FileData" : fileUpload.AttachmentContent
        }
        POST(endpoint, payload).then(res => {
            setLoading(false);
            if (res && res.ErrorCode === 0) {
                Toast.success(res['Message'] || 'One Ticket Priority has been updated');
                setFileUpload();
            }
            else {
                Toast.error(res['Message'] || 'Error occurred while uploading file');
            }
        }).catch(err => {
            setLoading(false);
            handleCatch(err);
        })
    }

    useEffect(()=>{
        setFileUpload();
    },[])

    return (
        <div className="one-ticke-tl" id="oneTickeTL" >
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <h4>One Ticket Priority</h4>
                </Grid>
                <input type='file' accept=".xlsx" style={{ display: 'none' }} ref={fileUploadRef} onChange={handleFileSelect} />
                <Grid item md={12} xs={12}>
                    <div className="upload-container">
                        <h5>Upload Your File </h5>
                        <Button className="download-sample-btn selected-btn" onClick={() => fileUploadRef.current.click()}>
                            <PublishIcon />
                            </Button>
                        <span className="uplaod-doc-view">
                            {fileUpload &&
                                <span className="file-detail">
                                    {fileUpload.FileName}{" "}
                                    <button onClick={() => setFileUpload(null)}>
                                        <CloseIcon />
                                    </button>
                                </span>}
                        </span>
                        <div>
                            <Button disabled={!fileUpload} className="download-sample-btn" onClick={uploadTeamLeadPriority}>
                                <i className="fa fa-upload"></i> Upload {loading && <Spinner />}
                            </Button>
                        </div>
                        <div>
                            <Link className="download-sample-btn" href="https://bmsservicebkt.policybazaar.com/Static/Documents/TLAssigned.xlsx" target='_blank'> Download Sample  </Link>
                        </div>
                    </div>
                   
                </Grid>
            </Grid>
        </div>
    );
}
