import React from 'react'
import InsurerTicketSidebar from './components/InsuereTicketSidebar'
import InsurerCommentHistory from '../../../../../src/Components/CommentsHistory/InsurerCommentHistory';
import InsurerActivityTab from '../../../../Components/InsurerRightSidebar/InsurerActivityTab';

const InsurerTicket = () =>{
    return(
        <div className="insurer-ticekt-view">
            <ul>
                <li>
                    <InsurerTicketSidebar/>
                </li>
                <li className="right-bar">
                    <InsurerCommentHistory/>
                    <InsurerActivityTab/>
                </li>
            </ul>
        </div>
    )
}

export default InsurerTicket