import React, { useState, useReducer } from 'react';
import {
    TextField,
    Button,
    FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { POST } from '../../../../../services/apiServices';
import { Toast } from '../../../../../shared/components';
import { handleCatch } from '../../../../../shared/methods/apiMethods';
import Spinner from '../../../../../shared/components/Spinner';

const useStyles = makeStyles((theme) => ({
    formControl: {
        maxWidth: "100%",
    },
}));


const SkipData = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            TicketDetailsId: "",
            Reason: "",
            Duration: ""
        }
    );

    const [formDataTouched, setFormDataTouched] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            TicketDetailsId: null,
            Reason: null,
            Duration: null
        }
    );


    const handleChange = (event) => {
        let { value, name } = event.target;
        setFormData({ [name]: value })
        setFormDataTouched({ [name]: true })
    }

    const hasError = (name, displayName) => {
        let message;
        switch (name) {
            case 'TicketDetailsId':
                message = !formData[name] ? `${displayName || name} cannot be empty` : ''
                break;
            case 'Duration':
                message = !formData[name] ? `${displayName || name} cannot be empty` :
                    formData[name] <=0 ? `${displayName || name} should be greater than 0` :
                        formData[name] > 72 ? `${displayName || name} cannot be greater than 72` : ''
                break;
        }
        return message
    }

    const validate = () => {
        Object.keys(formDataTouched).forEach(item => {
            setFormDataTouched({ [item]: true })
        })
        if (hasError['TicketDetailsId'] || hasError('Duration')) {
            return false
        }
        else return true
    }


    const handleSubmit = () => {
        console.log('validation-->', validate())
        if (!validate()) {
            return
        }
        setLoading(true);
        let endpoint = 'SkipOneTicket';
        let payload = {
            "Skip": true,
            "Reason": formData.Reason,
            "Duration": formData.Duration,
            "TicketDetailsId": formData.TicketDetailsId
        }
        POST(endpoint, payload).then(res => {
            setLoading(false);
            if (res && res['ErrorCode'] === 0) {
                Toast.success(res['Message'] || 'Ticket Skipped successfully.');
                resetValues();
            }
            else {
                Toast.error(res['Message'] || 'Some error occurred while processing');
            }
        }).catch(err => {
            setLoading(false);
            handleCatch(err)
        })

    }

    const resetValues = () => {
        setFormData({
            TicketDetailsId: "",
            Reason: "",
            Duration: ""
        })
        setFormDataTouched({
            TicketDetailsId: null,
            Reason: null,
            Duration: null
        })
    }

    return (
        <div><ul>
            <h4>Skip Data </h4>
            <li>
                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label="Ticket Id"
                        id="outlined-margin-normal"
                        margin="normal"
                        variant="outlined"
                        name='TicketDetailsId'
                        onChange={handleChange}
                        error={formDataTouched['TicketDetailsId'] && hasError('TicketDetailsId', 'TicketID') ? true : false}
                        helperText={formDataTouched['TicketDetailsId'] && hasError('TicketDetailsId', 'TicketID')}
                        value={formData.TicketDetailsId}
                    />
                </FormControl>
            </li>
            <li>
                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label="Skip Reason"
                        id="outlined-margin-normal"
                        margin="normal"
                        variant="outlined"
                        name='Reason'
                        onChange={handleChange}
                        value={formData.Reason}
                    />
                </FormControl>
            </li>
            <li>
                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label="Duration (Hours)"
                        id="outlined-margin-normal"
                        margin="normal"
                        variant="outlined"
                        name='Duration'
                        type='number'
                        onChange={handleChange}
                        error={formDataTouched['Duration'] && hasError('Duration', 'Duration') ? true : false}
                        helperText={formDataTouched['Duration'] && hasError('Duration', 'Duration')}
                        value={formData.Duration}
                    />
                </FormControl>
            </li>
            <div>
                <Button className="submit-btn" disabled={loading} onClick={handleSubmit}>Submit {loading && <Spinner />}</Button>
            </div>
        </ul></div>
    )
}

export default SkipData
