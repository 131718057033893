import { Button } from '@material-ui/core';
import React from 'react';
import { GetEmailPDF } from '../../../../../constants/apiUrls';
import { GET } from '../../../../../services/apiServices';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';


const DownloadMail = ({MailRefObjId, IsReadOnlyUser}) => {

    const downloadMail = (e)=> {
        e.stopPropagation()
        GET(`${GetEmailPDF}/${MailRefObjId}`)
        .then((res)=> {
         var element = document.createElement('a');
         element.setAttribute('href', 'data:text/plain;base64,' + res.Data);
         element.setAttribute('download', "Email.pdf");
       
         element.style.display = 'none';
         document.body.appendChild(element);
       
         element.click();
       
         document.body.removeChild(element);
        })
        .catch((err) => {
          console.log(err);
        })

 }
    return (
        <Button onClick={downloadMail} className="pdf-btn" style={{cursor : IsReadOnlyUser ? "not-allowed" : "pointer", pointerEvents : IsReadOnlyUser ? "none" : ""}} >
            <img src="/images/pdf_icon.png" alt="pdf_iocn"/> 
        </Button>
    )
}

export default DownloadMail;
