import React, { useEffect } from 'react';
import { Link  } from "react-router-dom";
import {Button } from '@material-ui/core';
import { GET } from '../../../../services/apiServices';
import CustomBackdrop from '../../../Components/CustomBackdrop';
import { HashLoader, PacmanLoader } from 'react-spinners';
import { Toast } from '../../../../shared/components';
import { handleCatch } from '../../../../shared/methods/apiMethods';
import { isEndorsementEligible } from '../../../../Config';
import AdminChildTicketData from './AdminChildTicketDataV2';
import { openNewView } from '../../../../shared/methods/methods';

export default function AdminListItem({ i }) {

    const [childTicketLoading, setChildTicketLoading] = React.useState(false);
    const [childTicketList, setChildTicketList] = React.useState([]);

    const fetchChildTickets = (referenceID) => {

        let url = `/GetChildTicketList/${referenceID}/1`;
        setChildTicketLoading(true);
        GET(url, null, 'Dashboard').then(res => {
            setChildTicketLoading(false);
            if (res && res.ErrorCode === 0) {
                setChildTicketList(res.Data);
            }
            else Toast.error(res["Message"] || "Failed to fetch child tickets");
        }).catch(err => {
            setChildTicketLoading(false);
            handleCatch(err);
        })
    }

    useEffect(()=>{
        // ***DO NOT REMOVE***
        // ensures if child tickets are opened, and page is changed then child ticket list is cleared
        // if not used then removed child tickets will get rendered for same position ticket in list in next page also
        setChildTicketList([])
    },[i])
    

    return (
        <div>
            {childTicketLoading ? <CustomBackdrop open={childTicketLoading}><HashLoader color='white' /></CustomBackdrop> : null}
            <ul>
                <li>
                    <strong>
                        {new Date(i.CreatedOn).toLocaleString('en-US', {timeZone: "Asia/Kolkata"})}
                    </strong>
                </li>
                <li>
                    <strong>
                        <Link
                            to={{pathname: isEndorsementEligible(i.ProductId) && openNewView(i.ProductId) ? `/ticketdetails/${i.RefObjectId}/${i.TicketDetailsID}/0` :
                                    `/alltickets/${i.RefObjectId}/${i.TicketDetailsID}/0`,
                                state: { data: {} },
                            }}
                        >
                            {i.TicketDetailsID}
                        </Link>
                        {i.EscLevel1 === "Yes" && <Button className="hcs-btn">HCS</Button>}
                        {i.EscLevel2 === "Yes" && <Button className="cgo-btn">CGO</Button>}
                        {i.IsParent && (
                            childTicketList.length === 0 ?  
                            <Button className="add-btn" onClick={() => fetchChildTickets(i.RefObjectId)}>{"+"}</Button>
                            : <Button className="add-btn" onClick={() => setChildTicketList([])}>{"-"}</Button>
                            )
                         }
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.LeadID}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.ProductName}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.Source}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.IssueName}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.SubIssueName}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.StatusCollection.StatusName}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.AssignedToName || "-"}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.Status}
                    </strong>
                </li>
                {/* <li>
                    <strong>
                        {Number(i.IsCallBackRequest) === 0 ? "No" : "Yes"}
                    </strong>
                </li> */}
                <li>
                    <strong>
                        {i.FollowUpOn || "-"}
                    </strong>
                </li>
                {/* <li>
                    <strong>
                        {i.CustCallCount}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.CustomerEmailCount}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.EmailId}
                    </strong>
                </li> */}
                {/* <li>
                    <strong>
                        {i.AGEING}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.PolicyNo}
                    </strong>
                </li>
                <li>
                    <strong>
                        {i.RegistrationNo}
                    </strong>
                </li> */}
                <li>
                    <strong>
                        {i.InsurerStatusDate || "-"}
                    </strong>
                </li>
                {/* <li>
                    <strong>
                        {i.ApplicationNo}
                    </strong>
                </li> */}
                <li>
                    {i.TATBurstDate}
                </li>
                {childTicketList && childTicketList.length > 0 && <AdminChildTicketData dataToRender={childTicketList} />}
            </ul>
        </div>
    )
}
