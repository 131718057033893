import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const CustomDialog = (props) => {
    const { open, handleClose, title, actionButtons } = props;

    return (
        <Dialog
            open={open}
            fullScreen
        className='dialog-user-details'
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            {actionButtons ? <DialogActions>
                {actionButtons.map((item, index) => {
                    return <Button key={index + 'button'} onClick={item.action}>{item.name}</Button>
                })}
            </DialogActions> : null
            }
        </Dialog >
    );
}

export default CustomDialog