import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function CustomPopup(props) {
    const { open, handleClose, title, children, actionButtons, className } = props;

    return (
        <div id='custom-popup'>
            <Dialog
                open={open}
                onClose={handleClose}
                className={className}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                {actionButtons ? <DialogActions>
                    {actionButtons.map((item, index) => {
                        return <Button key={index + 'button'} onClick={item.action}>{item.name}</Button>
                    })}
                </DialogActions> : null
                }
            </Dialog>
        </div>
    );
}