import { useEffect, useState, React } from "react";
import {
  Link,
  ListItem,
  Grid,
  List,
  Button,
  MenuItem,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";

import SearchRadio from "./Components/SearchRadio";
import { GET, POST } from "../services/apiServices";
import {
  GetTicketCount,
  GetTicketData,
  GetTicketDataExport,
  GetTicketDetails,
  GetDashboardForOtherData,
  GetDashboardForOtherDataExport,
} from "../constants/apiUrls";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import LoadDataList from "./Components/LoadDataList";
import FullScreenDialog from "../Components/Topbar/components/CreateTicketDialog";
import { TextField, FormControl, InputLabel, Select } from "@material-ui/core";
import { useSnackbar } from "notistack";
import moment from "moment";
import Spinner from "../shared/components/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    background: "#fff",
  },
}));

const Dashboard_2 = ({ isActionsAllowed, setAccessTypeVal }) => {
  const classes = useStyles();
  const [ticketCount, setTicketCount] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [isCreateTicketDialogOpen, setCreateTicketDialogOpen] = useState(null);
  const [sourceList, setSourceList] = useState(null);
  const [supplierList, setSupplierList] = useState([]);
  const [cbList, setCbList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [escList, setEscList] = useState([{ Value: 1, Name: "All" }]);
  const [productList, setProductList] = useState([]);
  const [insurerList, setInsurerList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [searchType, setSearchType] = useState(0);
  const todaysDate = moment().format("YYYY-MM-DD");
  const [isLoading, setIsLoading] = useState(false);

  const [filterValues, setFilterValues] = useState({
    filterString: "",
    fromDate: todaysDate,
    toDate: todaysDate,
    source: "",
    cbRequest: null,
    status: null,
    escalation: null,
    product: null,
    insurer: null,
  });

  // useEffect(() => {
  //   if (!ticketCount) {
  //     getTicketCount();
  //   }
  // }, [ticketCount]);

  useEffect(() => {
    setAccessTypeVal(3);
  }, [])

  useEffect(() => {
    GET(`${GetTicketDetails}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const {
            statusCollections,
            callbackTypeMasterResponses,
            ResponsibilityMasterData,
            ClassificationMasterData,
            RootCauseMasterData,
            suppliersList,
            ticketSources,
          } = response.Data;
          const statusOptions = [];
          statusCollections.forEach((item) => {
            if (
              item.StatusType === 1 &&
              item.StatusID !== 4 &&
              !isStatusOptionExist(statusOptions, item)
            ) {
              statusOptions.push(item);
            }
          });
          setStatusList(statusOptions);

          // setSourceList(ticketSources);
          setSourceList([
            { SourceID: 1, SourceName: "CustEscalation" },
            { SourceID: 2, SourceName: "Communication" },
            { SourceID: 3, SourceName: "CRTBike" },
            { SourceID: 4, SourceName: "CRTMotor" },
            { SourceID: 5, SourceName: "Life" },
            { SourceID: 6, SourceName: "Termlife" },
            { SourceID: 7, SourceName: "MyAccount" },
            { SourceID: 8, SourceName: "InBound" },
            { SourceID: 9, SourceName: "IVR" },
            { SourceID: 10, SourceName: "Travel" },
            { SourceID: 11, SourceName: "MotorJourney" },
            { SourceID: 12, SourceName: "AFFILIATES" },
            { SourceID: 13, SourceName: "TwoWheeler Journey" },
            { SourceID: 14, SourceName: "PB Manual" },
            { SourceID: 15, SourceName: "Insurer Manual" },
            { SourceID: 16, SourceName: "Endorsement_BMS" },
            { SourceID: 15, SourceName: "E2E Tickets" },
            { SourceID: 17, SourceName: "Renewal" },
            { SourceID: 18, SourceName: "Inbound Manual" },
            { SourceID: 19, SourceName: "ChatBot" },
            { SourceID: 20, SourceName: "Service" },
            { SourceID: 21, SourceName: "CRT" },
            { SourceID: 22, SourceName: "Sales" },
            { SourceID: 23, SourceName: "Promised Callback" },
            { SourceID: 24, SourceName: "App Review" },
            { SourceID: 25, SourceName: "Escalation" },
            { SourceID: 26, SourceName: "MOTORSUPPORT_AE" },
            { SourceID: 27, SourceName: "TRAVELSUPPORT_AE" },
            { SourceID: 28, SourceName: "COMMUNICATION_AE" },
            { SourceID: 29, SourceName: "CARE_AE" },
            { SourceID: 30, SourceName: "C2C" },
            { SourceID: 31, SourceName: "TrClaims" },
            { SourceID: 32, SourceName: "FLC" },
            { SourceID: 33, SourceName: "Matrix" },
          ]);

          const productList = [
            ...new Map(
              suppliersList.map((item) => [item["ProductID"], item])
            ).values(),
          ];
          setProductList(productList);

          setCbList(callbackTypeMasterResponses);

          setInsurerList(suppliersList);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log(err);
      });
  }, []);

  const clear = () => {
    setFilterValues((filterValues) => ({
      filterString: "",
      fromDate: todaysDate,
      toDate: todaysDate,
      source: "",
      cbRequest: null,
      status: null,
      escalation: null,
      product: null,
      insurer: null,
    }));
    setTicketData(null);
  };

  const isStatusOptionExist = (statusOptions, item) => {
    for (let i = 0; i < statusOptions.length; i++) {
      if (statusOptions[i].StatusID === item.StatusID) {
        return true;
      }
    }
    return false;
  };

  const handleFilterChange = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    const { value, name } = event.target;
    setFilterValues((filterValues) => ({
      ...filterValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!sourceList) {
      getSourceList();
    }
  }, [sourceList]);

  useEffect(() => {
    clear();
  }, [searchType]);

  const getSourceList = () => {
    GET(`${GetTicketDetails}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const { ticketSources, suppliersList } = response.Data;

          setSourceList([
            { SourceID: 1, SourceName: "CustEscalation" },
            { SourceID: 2, SourceName: "Communication" },
            { SourceID: 3, SourceName: "CRTBike" },
            { SourceID: 4, SourceName: "CRTMotor" },
            { SourceID: 5, SourceName: "Life" },
            { SourceID: 6, SourceName: "Termlife" },
            { SourceID: 7, SourceName: "MyAccount" },
            { SourceID: 8, SourceName: "InBound" },
            { SourceID: 9, SourceName: "IVR" },
            { SourceID: 10, SourceName: "Travel" },
            { SourceID: 11, SourceName: "MotorJourney" },
            { SourceID: 12, SourceName: "AFFILIATES" },
            { SourceID: 13, SourceName: "TwoWheeler Journey" },
            { SourceID: 14, SourceName: "PB Manual" },
            { SourceID: 15, SourceName: "Insurer Manual" },
            { SourceID: 16, SourceName: "Endorsement_BMS" },
            { SourceID: 15, SourceName: "E2E Tickets" },
            { SourceID: 17, SourceName: "Renewal" },
            { SourceID: 18, SourceName: "Inbound Manual" },
            { SourceID: 19, SourceName: "ChatBot" },
            { SourceID: 20, SourceName: "Service" },
            { SourceID: 21, SourceName: "CRT" },
            { SourceID: 22, SourceName: "Sales" },
            { SourceID: 23, SourceName: "Promised Callback" },
            { SourceID: 24, SourceName: "App Review" },
            { SourceID: 25, SourceName: "Escalation" },
            { SourceID: 26, SourceName: "MOTORSUPPORT_AE" },
            { SourceID: 27, SourceName: "TRAVELSUPPORT_AE" },
            { SourceID: 28, SourceName: "COMMUNICATION_AE" },
            { SourceID: 29, SourceName: "CARE_AE" },
            { SourceID: 30, SourceName: "C2C" },
            { SourceID: 31, SourceName: "TrClaims" },
            { SourceID: 32, SourceName: "FLC" },
            { SourceID: 33, SourceName: "Matrix" },
          ]);
          setSupplierList(suppliersList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hasError = (field) => {
    let error = "";
    if (!filterValues[field] && searchType == 1) {
      error = "Product is mandatory";
    }
    return error;
  };

  const getDashboardData = (event, type) => {
    // setTicketData(null);
    if (hasError("product")) {
      enqueueSnackbar("Product is mandatory", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      return;
    }
    setIsLoading(true);
    event.preventDefault();
    // console.log(123, filterValues);
    let payload;
    if (searchType == 0) {
      payload = {
        IsUpdatedOn: 0,
        FilterString: filterValues.filterString,
        FromDate: moment(filterValues.fromDate).valueOf(),
        ToDate: moment(filterValues.toDate).valueOf(),
        Source: "",
        SubSource: "",
        ProductID: 0,
        SupplierID: 0,
        CBRequest: 0,
        StatusID: 0,
        SubStatusID: 0,
        TATFilter: 0,
        InsurerStatusID: 0,
        STP: -1,
        IsEsc: 0,
        // UserId: "7623",
        Type: 12,
        UserType: 1,
        ProductIDs: "",
        SendToReport: "",
      };
    } else if (searchType == 1) {
      payload = {
        IsUpdatedOn: 0,
        FilterString: "",
        FromDate: moment(filterValues.fromDate).valueOf(),
        ToDate: moment(filterValues.toDate).valueOf(),
        Source: filterValues.source,
        SubSource: "",
        ProductID: filterValues.product || 0,
        SupplierID: filterValues.insurer || 0,
        CBRequest: filterValues.cbRequest || 0,
        StatusID: filterValues.status || 0,
        SubStatusID: 0,
        TATFilter: 0,
        InsurerStatusID: 0,
        STP: -1,
        IsEsc: filterValues.escalation || 0,
        //"UserId": "8496",
        Type: type,
        UserType: 1,
        ProductIDs: filterValues.product.toString(),
        SendToReport: "",
      };
    }
    POST(GetDashboardForOtherData, payload, 'Dashboard')
      .then((res) => {
        // console.log(456, res);
        if (res && res.Data && res.Data.length > 0) {
          setTicketData(res.Data);
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getDashboardDataExport = (event, type) => {
    if (hasError("product")) {
      enqueueSnackbar("Product is mandatory", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      return;
    }
    event.preventDefault();
    let payload;
    if (searchType == 0) {
      payload = {
        IsUpdatedOn: 0,
        FilterString: filterValues.filterString,
        FromDate: moment(filterValues.fromDate).valueOf(),
        ToDate: moment(filterValues.toDate).valueOf(),
        Source: "",
        SubSource: "",
        ProductID: 0,
        SupplierID: 0,
        CBRequest: 0,
        StatusID: 0,
        SubStatusID: 0,
        TATFilter: 0,
        InsurerStatusID: 0,
        STP: -1,
        IsEsc: 0,
        // UserId: "7623",
        Type: 12,
        UserType: 1,
        ProductIDs: "",
        SendToReport: "",
      };
    } else if (searchType == 1) {
      payload = {
        IsUpdatedOn: 0,
        FilterString: "",
        FromDate: moment(filterValues.fromDate).valueOf(),
        ToDate: moment(filterValues.toDate).valueOf(),
        Source: filterValues.source,
        SubSource: "",
        ProductID: filterValues.product || 0,
        SupplierID: filterValues.insurer || 0,
        CBRequest: filterValues.cbRequest || 0,
        StatusID: filterValues.status || 0,
        SubStatusID: 0,
        TATFilter: 0,
        InsurerStatusID: 0,
        STP: -1,
        IsEsc: filterValues.escalation || 0,
        //"UserId": "8496",
        Type: type,
        UserType: 1,
        ProductIDs: filterValues.product.toString(),
        SendToReport: "",
      };
    }
    POST(GetDashboardForOtherDataExport, payload, 'Dashboard')
      .then((res) => {
        console.log(res);
        if (res && res.Data) {
          const linkSource = `data:application/xlsx;base64,${res.Data}`;
          const downloadLink = document.createElement("a");
          const fileName = "ticket_data.xlsx";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      })
      .catch((err) => console.log(err));
  };

  const openCreateTicket = (event) => {
    if (event) {
      event.persist();
    }
    setCreateTicketDialogOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className="dashboard-summary">
        <div className="dashboard-topbar">
          <Grid container justify="left">
            <Grid item md={6} xs={12}>
              <h2>Ticket Summary</h2>
            </Grid>
          </Grid>
        </div>
        <SearchRadio
          // getDashboardData={getDashboardData}
          //getDashboardDataExport={getDashboardDataExport}
          isActionsAllowed={isActionsAllowed}
          searchType={searchType}
          setSearchType={setSearchType}
        />
        {searchType == 0 && (
          <div className="advance-search">
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <FormControl className="form-control">
                      <TextField
                        id="search"
                        placeholder="Search"
                        type="text"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="filterString"
                        className="advance-search-box"
                        value={filterValues.filterString}
                        onChange={handleFilterChange}
                      />
                      <Button
                        className="adv-search-btn"
                        onClick={(event) => getDashboardData(event, 12)}
                      >
                        <img src="./images/search_iocn_dash.svg" />
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>

                    <Button
                      className="export-btn"
                      onClick={(event) => getDashboardDataExport(event, 12)}
                    >
                      Export{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        {searchType == 1 && (
          <div className="advance-search">
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="datetime-local"
                    label="From Date"
                    type="date"
                    defaultValue={todaysDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="fromDate"
                    className="date-picker"
                    value={filterValues.fromDate}
                    onChange={handleFilterChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="datetime-local"
                    label="To Date"
                    type="date"
                    defaultValue={todaysDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="toDate"
                    className="date-picker"
                    value={filterValues.toDate}
                    onChange={handleFilterChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" className="form-control">
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Source
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Source"
                    inputProps={{
                      name: "source",
                      id: "outlined-age-native-simple",
                    }}
                    value={filterValues.source}
                    onChange={handleFilterChange}
                  >
                    <MenuItem aria-label="Select" value="">
                      Select
                    </MenuItem>
                    {sourceList &&
                      sourceList.length > 0 &&
                      sourceList.map((i) => {
                        return (
                          <MenuItem
                            aria-label={i.SourceName}
                            value={i.SourceName}
                          >
                            {i.SourceName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" className="form-control">
                  <InputLabel htmlFor="outlined-age-native-simple">
                    CB Request
                  </InputLabel>
                  <Select
                    fullWidth
                    label="CB Request"
                    inputProps={{
                      name: "cbRequest",
                      id: "outlined-age-native-simple",
                    }}
                    value={filterValues.cbRequest}
                    onChange={handleFilterChange}
                  >
                    <MenuItem aria-label="Select" value="">
                      Select
                    </MenuItem>
                    {cbList &&
                      cbList.length > 0 &&
                      cbList.map((i) => {
                        return (
                          <MenuItem aria-label={i.Text} value={i.Value}>
                            {i.Text}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" className="form-control">
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Status
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Status"
                    inputProps={{
                      name: "status",
                      id: "outlined-age-native-simple",
                    }}
                    value={filterValues.status}
                    onChange={handleFilterChange}
                  >
                    <MenuItem aria-label="Select" value="">
                      Select
                    </MenuItem>
                    {statusList &&
                      statusList.length > 0 &&
                      statusList.map((i) => {
                        return (
                          <MenuItem
                            aria-label={i.StatusName}
                            value={i.StatusID}
                          >
                            {i.StatusName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" className="form-control">
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Escalation
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Escalation"
                    inputProps={{
                      name: "escalation",
                      id: "outlined-age-native-simple",
                    }}
                    value={filterValues.escalation}
                    onChange={handleFilterChange}
                  >
                    <MenuItem aria-label="Select" value="">
                      Select
                    </MenuItem>
                    {escList &&
                      escList.length > 0 &&
                      escList.map((i) => {
                        return (
                          <MenuItem aria-label={i.Name} value={i.Value}>
                            {i.Name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" className="form-control">
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Product
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Product"
                    inputProps={{
                      name: "product",
                      id: "outlined-age-native-simple",
                    }}
                    value={filterValues.product}
                    onChange={handleFilterChange}
                  >
                    <MenuItem aria-label="Select" value="">
                      Select
                    </MenuItem>
                    {productList &&
                      productList.length > 0 &&
                      productList.map((i) => {
                        return (
                          <MenuItem
                            aria-label={i.ProductName}
                            value={i.ProductID}
                          >
                            {i.ProductName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" className="form-control">
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Insurer
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Insurer"
                    inputProps={{
                      name: "insurer",
                      id: "outlined-age-native-simple",
                    }}
                    value={filterValues.insurer}
                    onChange={handleFilterChange}
                  >
                    <MenuItem aria-label="Select" value="">
                      Select
                    </MenuItem>
                    {insurerList &&
                      insurerList.length > 0 &&
                      insurerList.map((i) => {
                        return (
                          <MenuItem
                            aria-label={i.SupplierName}
                            value={i.SupplierID}
                          >
                            {i.SupplierName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  className="btn-search"
                  onClick={(event) => getDashboardData(event, 13)}
                >
                  Search
                </Button>
                <Button
                  className="export-btn"
                  onClick={(event) => getDashboardDataExport(event, 13)}
                >
                  Export{" "}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        {isLoading ? (
          <div className="search-loader">
            <Spinner />
          </div>
        ) : (
          <LoadDataList ticketData={ticketData} />
        )}
      </div>
      <FullScreenDialog
        isCreateDialogOpen={isCreateTicketDialogOpen}
        setCreateDialog={setCreateTicketDialogOpen}
        sourceList={sourceList}
        TicketDetailsID={null}
        RefObjectId={null}
        supplierList={supplierList}
      />
    </div>
  );
};

export default Dashboard_2;
