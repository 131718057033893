import React from "react";
import { Link  } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { isEndorsementEligible } from "../../../../Config";
import { openNewView } from "../../../../shared/methods/methods";


export default function AdminChildTicketDataV2({ dataToRender }) {
    const history = useHistory();



    const redirectToAllTicket = (RefObjectId, TicketDetailsID, ProductId) => {
        if (isEndorsementEligible(ProductId)) {
            history.push(`/ticketdetails/${RefObjectId}/${TicketDetailsID}/0`);
        } else {
            history.push(`/alltickets/${RefObjectId}/${TicketDetailsID}/0`);
        }
    };

    return (
        <>
            {
                dataToRender && dataToRender.map((i, key) => {
                    return <div className="sub-table scrool-toll" key={'childTicketDashboard' + key}>
                        <div className="body-list">
                            <ul>
                                <li>
                                    <strong>
                                        {new Date(i.CreatedOn).toLocaleString('en-US', {timeZone: "Asia/Kolkata"})}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        <Link
                                            to={{
                                                pathname: isEndorsementEligible(i.ProductId) && openNewView(i.ProductId) ? `/ticketdetails/${i.RefObjectId}/${i.TicketDetailsID}/0` :
                                                    `/alltickets/${i.RefObjectId}/${i.TicketDetailsID}/0`,
                                                state: { data: {} },
                                            }}
                                        >
                                            {i.TicketDetailsID}
                                        </Link>
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.LeadID}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.ProductName}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.Source}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.IssueName}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.SubIssueName}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.StatusCollection.StatusName}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.AssignedToName || "-"}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.Status}
                                    </strong>
                                </li>
                                {/* <li>
                                    <strong>
                                        {Number(i.IsCallBackRequest) === 0 ? "No" : "Yes"}
                                    </strong>
                                </li> */}
                                <li>
                                    <strong>
                                        {i.FollowUpOn}
                                    </strong>
                                </li>
                                {/* <li>
                                    <strong>
                                        {i.CustCallCount}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.CustomerEmailCount}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.EmailId}
                                    </strong>
                                </li> */}
                                {/* <li>
                                    <strong>
                                        {i.AGEING}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.PolicyNo}
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        {i.RegistrationNo}
                                    </strong>
                                </li> */}
                                <li>
                                    <strong>
                                        {i.InsurerStatusDate}
                                    </strong>
                                </li>
                                {/* <li>
                                    <strong>
                                        {i.ApplicationNo}
                                    </strong>
                                </li> */}
                                <li>
                                    {i.TATBurstDate}
                                </li>
                            </ul>
                        </div>
                        {/* } */}
                    </div>
                })

            }
        </>
    );
}
