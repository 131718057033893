import Axios from "axios";
import { getCookie, STORAGE } from '../shared/methods/methods';

const getPrefix = (controller) => {
  switch (controller) {
    case 'TicketSystem':
      return 'api/TicketSystem'
    case 'Dashboard':
      return 'api/Dashboard';
    case 'weCare':
      return 'api/'
    default:
  }
}

export const base_url = function (controller) {
  let url = window.location.href;
  if (url.toLowerCase().includes("localhost")) {
    console.log('%c Beware! data can be exposed from here ', 'background: #222; color: #bada55; font-size: 24px; font-family:serif');
        return `https://ticketserviceqa.policybazaar.com/${getPrefix(controller)}`
    // return "https://ticketservicebuild.policybazaar.com/api/TicketSystem"
    //  return "https://ticketapi.policybazaar.com/api/TicketSystem"
      // return `https://ticketapi.policybazaar.com/${getPrefix(controller)}`
  }
  else if (url.toLowerCase().includes("ticketqa.policybazaar.com")) {
    console.log('%c Beware! data can be exposed from here ', 'background: #222; color: #bada55; font-size: 24px; font-family:serif');
    return `https://ticketserviceqa.policybazaar.com/${getPrefix(controller)}`
  }
  else if (url.toLowerCase().includes("ticketbuild.policybazaar.com")) {
    console.log('%c Beware! data can be exposed from here ', 'background: #222; color: #bada55; font-size: 24px; font-family:serif'); 
    return `https://ticketservicebuild.policybazaar.com/${getPrefix(controller)}`
  }
  else if (url.toLowerCase().includes("https://ticket.policybazaar.com/")) {
    console.log('%c Beware! data can be exposed from here ', 'background: #222; color: #bada55; font-size: 24px; font-family:serif');
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
    return `https://ticketapi.policybazaar.com/${getPrefix(controller)}`
  }
  else if (url.toLowerCase().includes("wecare.policybazaar.com")) {
    return `https://wecare.policybazaar.com/${getPrefix(controller)}`
  }
  return ""
};

export const old_ticket_base_url = function () {
  let url=window.location.href;
  if(url.toLowerCase().includes("localhost"))
  {
       return "https://ticketsqa.policybazaar.com"
  }
  else if(url.toLowerCase().includes("ticketqa.policybazaar.com"))
  {
    return "https://ticketsqa.policybazaar.com"
  }
  else if (url.toLowerCase().includes("ticketbuild.policybazaar.com"))
  {
      //return "https://ticketapi.policybazaar.com/beta/api/TicketSystem"
      return "https://ticketbuild.policybazaar.com"
  }
  else if(url.toLowerCase().includes("https://ticket.policybazaar.com/"))
  {
      return "https://ticketsmobile.policybazaar.com"
  }
  return ""
};

let axiosInstance = Axios.create({
  baseURL: '',
  headers: {},
});

axiosInstance.defaults.headers["Content-Type"] = "application/json";
axiosInstance.defaults.headers["app"] = "TicketSystem";

export const GET = (url, headerParam, controller) => {
  return STORAGE.getAuthToken().then((token) => {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (headerParam) {
        headers = { ...headerParam };
      }
      headers["token"] = token;
      if (getCookie("bmsv2clienttoken")) {
        headers["bmsv2Token"] = getCookie("bmsv2clienttoken");
      }
      if(localStorage.getItem('xTicketID')){
        headers["xTicketID"] = localStorage.getItem('xTicketID');
      }
      axiosInstance({
        method: "get",
        baseURL: controller ? base_url(controller) : base_url('TicketSystem'),
        url: url,
        headers,
      }).then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  )
}

export const POST = (url, data, controller) => {
  return STORAGE.getAuthToken().then((token) => {
    return new Promise((resolve, reject) => {
      let headers = {};
      headers["token"] = token;
      if(getCookie("bmsv2clienttoken")){
        headers["bmsv2Token"] = getCookie("bmsv2clienttoken");
      }
      if(localStorage.getItem('xTicketID')){
        headers["xTicketID"] = localStorage.getItem('xTicketID');
      }
      axiosInstance({
        method: "post",
        baseURL: controller ? base_url(controller) : base_url('TicketSystem'),
        url: url,
        data: data,
        headers,
      }).then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  )
}
export const LOGIN_POST = (url, data, headerIfAny) => {
    return new Promise((resolve, reject) => {
      let headers = {};
      if(getCookie("bmsv2clienttoken") && url=='Login'){
        headers["bmsv2Token"] = getCookie("bmsv2clienttoken");
        headers['token'] = headerIfAny
      }
      axiosInstance({
        method: "post",
        url: url,
        baseURL: base_url('TicketSystem'),
        data: data,
        headers,
      }).then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          reject(err.data);
        }
      );
    });
  }

