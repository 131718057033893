import React, { useState, useRef, useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid, Button, TextField, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import { getUniqueSetBasedOnKey, toBase64 } from '../../shared/methods/methods';
import { Autocomplete } from '@material-ui/lab';
import { fetchMasterList } from '../../redux/actions/common_action';
import { POST } from '../../services/apiServices';
import { handleCatch } from '../../shared/methods/apiMethods';
import { SampleUploadSubIssue } from './GenerateSample';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Toast } from '../../shared/components';
import Spinner from '../../shared/components/Spinner';
import CustomBackdrop from '../Components/CustomBackdrop';


export default function InsertSubIssues() {
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [productList, setProductList] = useState();
    const [masterList, setMasterList] = useState();
    const [issueList, setIssueList] = useState();
    const [statusList, setStatusList] = useState();
    const [subStatusList, setSubStatusList] = useState();
    const dispatch = useDispatch();
    const productSupplierMaster = useSelector(state => state && state.common && state.common.productSupplierMaster);
    const [fileUpload, setFileUpload] = useState();
    const fileUploadRef = useRef();
    const [loading, setLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(false);
    const [options, setOptions] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            productID: 0,
            IssueName: "",
            StatusName: "",
            SubStatusName: "",
        }
    )

    useEffect(() => {
        if (productSupplierMaster && productSupplierMaster.length === 0) {
            dispatch(fetchMasterList())
        }
    }, [productSupplierMaster]);

    useEffect(() => {
        if (productSupplierMaster) {
            getProductSet();
        }
    }, [productSupplierMaster])

    const getProductSet = () => {
        let arr = getUniqueSetBasedOnKey(productSupplierMaster, 'ProductID', ['ProductID', 'ProductName']);
        setProductList(arr);
    }

    useEffect(() => {
        if (options.productID) {
            fetchMasterBasedOnProduct(options.productID);
        }
    }, [options.productID])

    const fetchMasterBasedOnProduct = (id) => {
        setBackdropLoading(true);
        let endpoint = 'GetMasterDetails';
        let payload = { productID: id };
        POST(endpoint, payload).then(res => {
            setBackdropLoading(false);
            if (res && res.ErrorCode === 0) {
                setMasterList(res.Data);
            }
            else Toast.error(res['Message'] || 'Failed to fetch master list');
        }).catch(err => {
            setBackdropLoading(false);
            handleCatch(err);
        })
    }

    useEffect(() => {
        if (masterList) {
            getIssueSet();
        }
    }, [masterList])

    const getIssueSet = () => {
        let arr = [];
        masterList.forEach(item => {
            if (!arr.includes(item.IssueName)) {
                arr.push(item.IssueName);
            }
        })
        console.log('array-->', arr)
        setIssueList(arr);
    }

    useEffect(() => {
        if (options.IssueName) {
            getStatusSet();
        }
        else setStatusList();
    }, [options.IssueName])

    const getStatusSet = () => {
        let arr1 = [];
        masterList.forEach(item => {
            if (item.IssueName === options.IssueName && !arr1.includes(item.StatusName)) {
                arr1.push(item.StatusName);
            }
        })
        setStatusList(arr1)
    }
    const GetParentID = (StatusName) => {
        return masterList.filter(item => {
            if (item.IssueName === options.IssueName && item.StatusName === StatusName ) {
                return item
            }
        })[0].ParentID || ""
    }

    useEffect(() => {
        if (options.StatusName) {
            getSubStatusList();
        }
        else setSubStatusList();
    }, [options.StatusName])

    const getSubStatusList = () => {
        let arr2 = [];
        masterList.forEach(item => {
            if (item.IssueName === options.IssueName && item.StatusName === options.StatusName && !arr2.includes(item.SubStatusName)) {
                arr2.push(item.SubStatusName);
            }
        })
        setSubStatusList(arr2);
    }


    // Download all the records
    const DownloadSampleFile = () => {
        let csvData = [];
        let { productID, IssueName, StatusName, SubStatusName} = options
        if(productID && IssueName){
            csvData = statusList && statusList.map(status=> SampleUploadSubIssue(masterList[0].ProductName, '', status, "", GetParentID(status))[0])
        }
        if(productID && IssueName && StatusName){
            debugger
            csvData= SampleUploadSubIssue(masterList[0].ProductName, "", StatusName,"",GetParentID(StatusName))
        }
        if(productID && IssueName && StatusName && SubStatusName){
            csvData = SampleUploadSubIssue(masterList[0].ProductName, "", StatusName, SubStatusName,GetParentID(StatusName))
        }
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const FileName = `SampleFile_UploadSubIssue${selectedProduct}`;
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, FileName + fileExtension);
    };

    const handleFileSelect = async (e) => {
        const base64 = await toBase64(e.target.files[0]);
        setFileUpload({
            FileName: e.target.files[0].name,
            AttachmentContent: base64.split("base64,")[1],
        });
        fileUploadRef.current.value = "";
    }

    const uploadSubIssue = () =>{
        setLoading(true);
        let endpoint = 'InsertUpdateTicketSubIssue';
        let payload = {
            FileData: fileUpload && fileUpload.AttachmentContent,
            ProductID: options.productID,
            FileName: fileUpload.FileName,
        }
        POST(endpoint, payload).then(res => {
            setLoading(false);
            if (res && res.ErrorCode === 0) {
                Toast.success('Issue List uploaded successfully');
                setFileUpload();
            }
            else {
                Toast.error(res['Message'] || 'Error occurred while uploading issue list');
            }
        }).catch(err => {
            setLoading(false);
            handleCatch(err);
        })
    }

    useEffect(()=>{
        setFileUpload();
    },[options.productID, options.IssueName, options.StatusName, options.SubStatusName])

    return (
        <div>
            <Grid container spacing={2}>
                {backdropLoading && <CustomBackdrop open={backdropLoading} textToShow="Loading..."/>}
                <Grid item md={12} xs={12}>
                    <h3>Insert Ticket Sub Issues</h3>
                </Grid>
                <Grid item md={2} sm={3} xs={12}>
                    <FormControl variant="outlined" className='form-control autocomplete'>
                        <InputLabel htmlFor="outlined-age-native-simple">Product</InputLabel>
                        <Autocomplete
                            options={productList}
                            getOptionLabel={(option) => option.ProductName}
                            value={productList && productList.filter(item => item.ProductID === options.productID)[0]}
                            placeholder='Product'
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                />
                            }
                            onChange={(event, newValue) => setOptions({ productID: newValue ? newValue.ProductID : 0, IssueName: "", StatusName: "", SubStatusName: "" })}
                        />
                    </FormControl>
                </Grid>
                {
                    options.productID && Array.isArray(issueList) && issueList.length > 0 ?
                        <>
                            <Grid item md={2} sm={3} xs={12}>
                                <FormControl variant="outlined" className='form-control autocomplete'>
                                    <InputLabel htmlFor="outlined-age-native-simple">Issues</InputLabel>
                                    <Autocomplete
                                        options={issueList}
                                        getOptionLabel={(option) => option}
                                        value={options.IssueName}
                                        placeholder='Issue'
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        }
                                        onChange={(event, newValue) => setOptions({ IssueName: newValue || "", StatusName: "", SubStatusName: "" })}
                                    />
                                </FormControl>
                            </Grid>
                            {options.IssueName && statusList && statusList.length > 0 ?
                                <>
                                    <Grid item md={2} sm={3} xs={12}>
                                        <FormControl variant="outlined" className='form-control autocomplete'>
                                            <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                                            <Autocomplete
                                                options={statusList}
                                                getOptionLabel={(option) => option}
                                                value={options.StatusName}
                                                placeholder='Status'
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="normal"
                                                    />
                                                }
                                                onChange={(event, newValue) => setOptions({ StatusName: newValue || "", SubStatusName: "" })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {options.StatusName && subStatusList && subStatusList.length > 0 ? <Grid item md={2} sm={3} xs={12}>
                                        <FormControl variant="outlined" className='form-control autocomplete'>
                                            <InputLabel htmlFor="outlined-age-native-simple">Sub Status</InputLabel>
                                            <Autocomplete
                                                options={subStatusList}
                                                getOptionLabel={(option) => option}
                                                value={options.SubStatusName}
                                                placeholder='Status'
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="normal"
                                                    />
                                                }
                                                onChange={(event, newValue) => setOptions({ SubStatusName: newValue || "" })}
                                            />
                                        </FormControl>
                                    </Grid> : null}
                                </> : null
                            }
                        </>
                        : null

                }
                <input type='file' accept=".xlsx" style={{ display: 'none' }} ref={fileUploadRef} onChange={handleFileSelect} />
                {
                    options.productID && options.IssueName ?
                        <>
                            <><Grid item md={2} xs={12}>
                                <Button variant='outlined' className="download-sample-btn" onClick={DownloadSampleFile}>Download Sample</Button>
                            </Grid>
                                <Grid item md={12} xs={12}>
                                    {fileUpload ? <Button disabled={loading} className="download-sample-btn" onClick={uploadSubIssue}>
                                        Upload {loading && <Spinner />}
                                    </Button>
                                        : <Button className="download-sample-btn selected-btn" onClick={() => fileUploadRef.current.click()}>
                                            Select File
                                        </Button>}
                                    <div className="uplaod-doc-view">
                                        {fileUpload &&
                                            <span className="file-detail">
                                                {fileUpload.FileName}{" "}
                                                <button onClick={() => setFileUpload(null)}>
                                                    <CloseIcon />
                                                </button>
                                            </span>}
                                    </div>
                                </Grid>
                            </>
                        </> :
                        null
                }

            </Grid>
        </div>
    );
}
