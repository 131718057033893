import * as type from "../types/types";

const initialState = {
  productSupplierMaster : [],
  statusSubStatusMaster : [],
  sourceMaster : [],
  subSourceMaster : [],
  //for Admin DashboardV2
  productGroupMaster : [],
  screenName : null,
};

export default function Common(state = initialState, action) {
  switch (action.type) {
    case type.SET_PRODUCT_SUPPLIER_MASTER:
      return {
        ...state,
        productSupplierMaster: action.payload
      }
    case type.SET_STATUS_SUB_STATUS_MASTER:
      return {
        ...state,
        statusSubStatusMaster: action.payload
      };
    case type.SET_TICKET_SOURCE_MASTER:
      return {
        ...state,
        sourceMaster: action.payload,
      };
    case type.SET_TICKET_SUB_SOURCE_MASTER:
      return {
        ...state,
        subSourceMaster: action.payload,
      };
    case type.SET_PRODUCT_GROUP_MASTER:
      return {
        ...state,
        productGroupMaster: action.payload,
      };
    case type.SCREEN_NAME:
      return {
        ...state,
        screenName: action.payload,
      };
      
    default:
      return state;
  }
}