import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { GetEndorsementDetails, GetEndorsementSystemUrl } from "../../constants/apiUrls";

import {
  Grid,
  Card,
  TextareaAutosize,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  ListItem,
  List,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EndorsementDetails from "./Components/EndorsementDetail";
import { GET, POST } from "../../services/apiServices";
import { convertToIsoFormate } from "../../shared/methods/methods";
import { useSelector } from "react-redux";
import { OPEN_ENDORSEMENTWINDOW_DIRECTLY } from "../../Config";
import Toast from "../../shared/components/Toast";
import { fetchEndosementURL, fetchEndosementURLForExisting } from "../../shared/methods/apiMethods";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  headingTxt: {
    fontSize: "14px",
    fontWeight: "500",
  },
  expandIconSvg: {
    color: "#0065FF",
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
  },
  title: {
    flex: 1,
    fontSize: "16px",
  },
  h6: {
    color: "#414141",
    fontSize: "14px",
  },
  iframe:{
    height:"calc(85vh - 12px)",
  },
  txtBox:{
    textAlign:"center",
    padding:'10rem 0',
    fontSize:"14px",
    color:"#253858",
    height:'30vh',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Endorsement({
  endorsementDetails,
  tableDetails,
  endorsementHistory,
}) {
  const classes = useStyles();
  const iframeRef = useRef(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [openDetailsURL, setOpenDetailsURL] = useState("");
  const [loadingEndorsement, setLoadingEndorsement] = useState(false);
  const [fetchedEndorsementURL, setFetchedEndorsementURL] = useState('');

  const ticketDetails_store = useSelector(
    (state) =>
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails
  );
  const IsReadOnlyUser = useSelector(
    (state) =>
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser
  );

  const handleOpenIFrame = () => {
    setOpenDetails(true);
    setOpenDetailsURL(endorsementDetails.EndorsementDetailURL);
  };

  const closeDetails = () => {
    setOpenDetails(false);
    window.location.reload();
  };

  useEffect(() => {
    if (ticketDetails_store && OPEN_ENDORSEMENTWINDOW_DIRECTLY.includes(ticketDetails_store.ProductId)) {
      fetchEndorsement();
    }
  }, [ticketDetails_store])

  const fetchEndorsement = () => {
    setLoadingEndorsement(true); // for showing skeleton when endorsement is loading
    let callback = (data, error) => {
      setLoadingEndorsement(false);
      if (data) {
        setFetchedEndorsementURL(data);
      }
      else Toast.error(error);
    }
    let url = '/GetEndorsementSystemUrl';
    if (ticketDetails_store && ticketDetails_store.EndorsementID) {
      fetchEndosementURLForExisting(url, ticketDetails_store, callback)
    }
    else {
      fetchEndosementURL(url, ticketDetails_store, callback)
    }
  }

  return (
    <>
      {OPEN_ENDORSEMENTWINDOW_DIRECTLY.includes(ticketDetails_store.ProductId) ? (
        loadingEndorsement ?
          <Skeleton
            className={classes.txtBox}
            animation="wave"
            variant="rect"
          /> :
          fetchedEndorsementURL ?
            <iframe id="inlineFrameExample"
              title="Inline Frame Example"
              width="100%"
              src={fetchedEndorsementURL}
              className={classes.iframe}
              >
            </iframe> : 
            <div className={classes.txtBox}>
              <img src='/images/no_data_found.svg' loading="lazy" height={100} width={100}/>
              <p>
                Endorsement URL not found
              </p>
            </div>
      )
        :
        <div className="endorsement">
          <div className="endorsement-box">
            <h4>Endorsement details</h4>
            <div className="inner-box">
              <div className="endorsement-list">
                <List>
                  <ListItem className="left-side">
                    <List>
                      <ListItem>
                        <span>Endorsement Type</span>
                        <strong>{endorsementDetails?.EndorsementType}</strong>
                      </ListItem>
                      <ListItem>
                        <span>Endorsement id</span>
                        <strong>
                          {!IsReadOnlyUser ? (
                            <a
                              // onClick={(event) => handleChangeTab(event, 3)}
                              // href={endorsementDetails.EndorsementDetailURL}
                              onClick={handleOpenIFrame}
                            // target="_blank"
                            >
                              <Button
                                className="id-link-btn"
                                disabled={IsReadOnlyUser}
                              >
                                {endorsementDetails?.EndorsementID}
                              </Button>
                            </a>
                          ) : (
                            <span>{endorsementDetails?.EndorsementID}</span>
                          )}
                        </strong>
                      </ListItem>
                      <ListItem>
                        <span>Created By</span>
                        <strong>{endorsementDetails?.CreatedBy}</strong>
                      </ListItem>
                      <ListItem>
                        <span>Created date</span>
                        <strong>
                          {endorsementDetails && endorsementDetails.CreatedOn
                            ? convertToIsoFormate(endorsementDetails.CreatedOn)
                            : ""}
                        </strong>
                      </ListItem>
                      <ListItem>
                        <span>Current Status</span>
                        <Button
                          className={
                            endorsementDetails?.StatusID == 11
                              ? "current-btn green"
                              : endorsementDetails?.StatusID == 12
                                ? "current-btn red"
                                : "current-btn yellow"
                          }
                          disabled={IsReadOnlyUser}
                        >
                          {endorsementDetails?.StatusName}
                        </Button>
                      </ListItem>
                    </List>
                  </ListItem>
                  <ListItem className="right-side">
                    <Button
                      className="verifiy-btn"
                      disabled={IsReadOnlyUser}
                      onClick={handleOpenIFrame}
                    >
                      Verify Details
                    </Button>
                  </ListItem>
                </List>
              </div>
              <EndorsementDetails
                endorsementDetails={endorsementDetails}
                tableDetails={tableDetails}
              />
            </div>
          </div>
          <Dialog
            className="dialog-show-details"
            fullScreen
            open={openDetails}
            // onClose={() => {return leadId ? null : handleClose}}
            TransitionComponent={Transition}
            aria-labelledby="responsive-dialog-title"
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="right"
                  color="inherit"
                  onClick={closeDetails}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Endorsement Details
                </Typography>
              </Toolbar>
            </AppBar>
            <div className="endorsement-dialog-popup">
              <iframe
                style={{ width: "100%", height: "100vh" }}
                // id="followUpCalender-calender"
                ref={iframeRef}
                // className="iframe-box"
                src={openDetailsURL}
              ></iframe>
            </div>
          </Dialog>
        </div>
      }
    </>
  );
}
