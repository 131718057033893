import React, { useCallback, useEffect, useReducer, useState } from "react";
import { GET, POST } from "../../../services/apiServices";
import { GetTicketListForInsurerAdmin } from "../../../constants/apiUrls";
import {
  ExportTicketListForInsurerAdmin,
} from "../../../constants/apiUrls";
import {
  Grid,
  Button,
  Checkbox,
  TextField
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import SearchFilter from "./SearchFilter";
import { convertToIsoFormate, ValidProductSupplier } from "../../../shared/methods/methods";
import { useHistory } from "react-router-dom";
import Spinner from "../../../shared/components/Spinner";
import { download } from "../../../shared/methods/methods";
import { isEndorsementEligible } from "../../../Config";
import Tooltip from '@material-ui/core/Tooltip';
import { handleCatch } from "../../../shared/methods/apiMethods";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setWeCareAdminProductSelection } from "../../../redux/actions/weCareDashboard_action";


export default function InsurerAdminDashboard() {
  const dispatch = useDispatch()
  const user_store = useSelector(state => state.auth.userInfo)
  const Product_Access_store = useSelector(state => state.auth.ProductAccess)
  const SelectedProducts_store = useSelector(state => state.weCareDashboard.weCareAdminSelection.Products)
  const [isTicketsLoading, setTicketsLoading] = useState(false);
  var [data, setData] = React.useState(null);
  const [detail, setDetail] = React.useState(null);
  const [searchParamsParent, setSearchParamsParent] = useState(null);
  const [type, setType] = useState(1);
  const history = useHistory();
  const [showNoData, setShowNoData] = useState(false);
  const rowsPerPage = 10;
  const [pagination, setPagination] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [sortedData, setSortedData] = useState(null);
  const [state, setState] = useReducer(
    (a, b) => ({ ...a, ...b }), {
    renderEndorsementBucket: false,
    selectedProducts: SelectedProducts_store
  }
  )

  useEffect(() => {
    if (searchParamsParent) {
    }
  }, [searchParamsParent]);

  const handleSubmit = (event, type, searchParamsParent) => {
    if (event && event.persist) {
      event.persist();
    }
    if (isTicketsLoading) { return } // if Submit button already pressed then nothing happens
    setTicketsLoading(true);
    setType(type);
    const payload = {
      TicketDetailsID: searchParamsParent?.TicketDetailsID || "",
      LeadID: searchParamsParent?.LeadID || 0,
      FromDate: new Date(searchParamsParent?.FromDate).getTime(),
      ToDate: new Date(searchParamsParent?.ToDate).getTime(),
      StatusID: searchParamsParent?.StatusID || 0,
      ProductID: searchParamsParent?.ProductID,
      SupplierID: searchParamsParent?.SupplierID || 61,
      Source: searchParamsParent?.SourceId || "",
      QueueID: searchParamsParent?.QueueID || 0,
      TicketAssignmentType: searchParamsParent?.TicketAssignmentType || 1,
      SubStatusID: searchParamsParent?.SubStatusID || 0,
      Type: type,
      Ageing: searchParamsParent?.Ageing || 0,
      GroupID: searchParamsParent?.GroupID || 0,
      EmailId: searchParamsParent?.EmailId || "",
      CustomerName: searchParamsParent?.CustomerName || "",
      TicketsCount: searchParamsParent?.TicketsCount || 0,
      EmailsCount: searchParamsParent?.EmailsCount || 0,
      CallsCount: searchParamsParent?.CallsCount || 0,
      CBRequest: searchParamsParent?.CBRequest || 0,
      IssueID: searchParamsParent?.IssueID || 0,
      SubIssueID: searchParamsParent?.SubIssueID || 0,
      InsurerStatusID: searchParamsParent?.InsurerStatusID || 0,
      RegistrationNo: searchParamsParent?.RegistrationNo || "",
      PolicyNo: searchParamsParent?.PolicyNo || "",
      IsInsurer: searchParamsParent?.IsInsurer || 1,
      IsAttachment: searchParamsParent?.IsAttachment || -1,
      ApplicationNo: searchParamsParent?.ApplicationNo || "",
      TATFilter: searchParamsParent?.TATFilter || 0,
      IssueCode: searchParamsParent?.IssueList?.join() || '',
      SubIssueCode: searchParamsParent?.SubIssueList?.join() || '',
      ProductIDs: state.selectedProducts?.join(',')
    };
    setPageIndex(1);
    POST(GetTicketListForInsurerAdmin, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          if (response?.Data?.length) {
            setData(response.Data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        }
        setTicketsLoading(false);
      })
      .catch((err) => {
        handleCatch(err)
        setTicketsLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      let start = (rowsPerPage * (pageIndex - 1));
      let end = (rowsPerPage * pageIndex) > data.length ? data.length : rowsPerPage * pageIndex
      let a = data.slice(start, end);
      setPagination((pagination) => ({
        ...pagination,
        countBullet: Math.ceil(data.length / rowsPerPage),
        showingText: a.length < rowsPerPage ? a.length : rowsPerPage
      }));
      setSortedData(a);
    }
  }, [pageIndex, data])

  useEffect(() => {
    getDashboardBucketCount()
  }, [])

  const getDashboardBucketCount = () => {
      let endpoint = 'WeCare/GetDashboardCountForInsurerAdminV2';
      let payload = {
        Type: 4,
        ProductIDs: state.selectedProducts?.join(',')
      }
      POST(endpoint, payload, 'weCare').then(res => {
        if (res && res.ErrorCode === 0) {
          let Data = res?.Data ?? []
          setDetail(Data)
          dispatch(setWeCareAdminProductSelection(state.selectedProducts))
        }
      })
  }

  const ExportData = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    const payload = {
      TicketDetailsID: searchParamsParent?.TicketDetailsID || "",
      LeadID: searchParamsParent?.LeadID || 0,
      FromDate: new Date(searchParamsParent?.FromDate).getTime(),
      ToDate: new Date(searchParamsParent?.ToDate).getTime(),
      StatusID: searchParamsParent?.StatusID || 0,
      ProductID: searchParamsParent?.ProductID,
      SupplierID: searchParamsParent?.SupplierID || 61,
      Source: searchParamsParent?.SourceId || "",
      QueueID: searchParamsParent?.QueueID || 0,
      TicketAssignmentType: searchParamsParent?.TicketAssignmentType || 1,
      SubStatusID: searchParamsParent?.SubStatusID || 0,
      Type: type,
      Ageing: searchParamsParent?.Ageing || 0,
      GroupID: searchParamsParent?.GroupID || 0,
      EmailId: searchParamsParent?.EmailId || "",
      CustomerName: searchParamsParent?.CustomerName || "",
      TicketsCount: searchParamsParent?.TicketsCount || 0,
      EmailsCount: searchParamsParent?.EmailsCount || 0,
      CallsCount: searchParamsParent?.CallsCount || 0,
      CBRequest: searchParamsParent?.CBRequest || 0,
      IssueID: searchParamsParent?.IssueID || 0,
      SubIssueID: searchParamsParent?.SubIssueID || 0,
      InsurerStatusID: searchParamsParent?.InsurerStatusID || 0,
      RegistrationNo: searchParamsParent?.RegistrationNo || "",
      PolicyNo: searchParamsParent?.PolicyNo || "",
      IsInsurer: searchParamsParent?.IsInsurer || 1,
      IsAttachment: searchParamsParent?.IsAttachment || -1,
      ApplicationNo: searchParamsParent?.ApplicationNo || "",
      TATFilter: searchParamsParent?.TATFilter || 0,
      IssueCode: searchParamsParent?.IssueList?.join() || '',
      SubIssueCode: searchParamsParent?.SubIssueList?.join() || '',
      ProductIDs : state.selectedProducts?.join(',')
    };

    POST(ExportTicketListForInsurerAdmin, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          download("Ticket_Details.xlsx", response.Data);
          // console.log(response.Data);
        }
        setTicketsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handlePagination = (event, value) => {
    setPageIndex(value);
  };

  useEffect(() => {
    if (user_store) {
      checkIfEndorsementCasesToRender()
    }
  }, [user_store])

  const checkIfEndorsementCasesToRender = useCallback(() => {
    let tempArr = user_store?.SupplierDetails
    if (tempArr?.length) {
      for (let i = 0; i < tempArr?.length; i++) {
        let { ProductId, InsurerId } = tempArr[i]
        if (ValidProductSupplier(ProductId, InsurerId)) {
          setState({ renderEndorsementBucket: true })
          break
        }
      }
    }
  }, [])

  let header = [
    "S.No",
    "Ticket Date",
    "Ticket ID",
    "Assigned Date",
    "Lead ID",
    "Application No",
    "Policy No",
    "Issue",
    "Sub Issue",
    "Ticket Status",
    "Insurer Status",
    "Insurer Response Date",
    "PB Response Date",
    "Email",
    "Ageing",
    "Registration No"
  ];

  const ticketConfig = [
    {
      id: 8,
      image: "images/open_ticket.png",
      alt: "Open Ticket",
      detailKey: "OpenCase",
      textClass: "open-ticket",
      title: "Open"
    },
    {
      id: 2,
      image: "images/new_ticket.png",
      alt: "New Ticket",
      detailKey: "NewCase",
      textClass: "new-ticket",
      title: "New Ticket"
    },
    {
      id: 10,
      image: "images/pb_pendency.png",
      alt: "Pb Pendency",
      detailKey: "PendencyCompleted",
      textClass: "tat-brust",
      title: "Pendency Completed"
    },
    {
      id: 5,
      image: "images/tat_brust_ticket.png",
      alt: "Unassigned",
      detailKey: "UnAssignedCase",
      textClass: "pending-ticket",
      title: "Unassigned"
    },
    {
      id: 17,
      image: "images/endorsement_ticket.png",
      alt: "Endorsement",
      detailKey: "EndorsementTickets",
      textClass: "new-ticket",
      title: "Endorsement"
    },
    {
      id: 18,
      image: "images/pt_miss_ticket_new.png",
      alt: "PT Miss",
      detailKey: "PTMiss",
      textClass: "tat-brust",
      title: "PT Miss"
    },
    {
      id: 19,
      image: "images/reopen_ticket.png",
      alt: "ReOpen",
      detailKey: "ReOpen",
      textClass: "unassiged-ticket",
      title: "ReOpen"
    },
    {
      id: 20,
      image: "images/tat_brust_ticket.png",
      alt: "Greater Than 7 Days",
      detailKey: "GreaterThan7Days",
      textClass: "endoresment-ticket",
      title: ">7 days"
    },
    {
      id: 21,
      image: "images/pb_pendency.png",
      alt: "Resolved Today",
      detailKey: "ResolvedToday",
      textClass: "resolved-today",
      title: "Resolved Today"
    },
    {
      id: 22,
      image: "images/pb_pendency.png",
      alt: "Escalation Case",
      detailKey: "EscalationCase",
      textClass: "resolved-today",
      title: "Escalation Case",
      condition: state.renderEndorsementBucket ? true : false
    }
  ];

  const IDGenerator = (a) => 'InsurerAdminDashboard' + a

  const handleProductSelection  = (l) =>{
    if(l){
      setState({ selectedProducts : l.map(a=>a.ProductId)})
    }
    else{
      setState({selectedProducts : []})
    }
    setDetail()
    setData([])

  }


  return (
    <div className="insurer-admin-dash">
      {/* {JSON.stringify(state)} */}
      <div className="ticket-list-view">
        <>
          {state.renderEndorsementBucket && Product_Access_store?.length > 0 ?
            <div className="product-search">
              <Autocomplete
                options={Product_Access_store}
                fullWidth
                className="single-select"
                name="ProductID"
                id={IDGenerator('Products')}
                disableCloseOnSelect
                getOptionLabel={(item) => item.ProductName}
                value={Product_Access_store.filter(item => state.selectedProducts.includes(item.ProductId))}
                onChange={(e, newSelectedProducts) => handleProductSelection(newSelectedProducts)}
                renderOption={(option) => (
                  <React.Fragment>
                    <Checkbox
                      className='autocomplete-checkbox'
                      checked={state.selectedProducts && state.selectedProducts.includes(option.ProductId) ? true : false}
                    />
                    <span className='product-names'>{option.ProductName}</span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Products"
                  />
                )}
                multiple // Enable multi-select
              />
              <Button className="go-btn" variant="outline" onClick={getDashboardBucketCount}>Go</Button>
            </div> : null}
          <ul>
            {ticketConfig.map((ticket) => {
              if (typeof ticket.condition === 'boolean' && !ticket.condition) {
                return null;
              }

              return (
                <li key={ticket.id} onClick={(event) => handleSubmit(event, ticket.id)}>
                  <div className="ticket-box">
                    <div className={`ticket-haed ${ticket.textClass}`}>
                      <img src={ticket.image} alt={ticket.alt} />
                    </div>
                    <div className="ticket-text">
                      <p className={ticket.textClass}>
                        {detail?.[ticket.detailKey]}
                        <span>{ticket.title}</span>
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      </div>

      <SearchFilter
        setSearchParamsParent={setSearchParamsParent}
        handleSubmit={handleSubmit}
        setType={setType}
      />
      {isTicketsLoading ? (
        <div className="spinner-loader">
          <Spinner />
        </div>
      ) : (
        <Grid item md={12} xs={12}>
          <div className="summary-list">
            {Boolean(!showNoData && sortedData?.length) && (
              <Grid container spacing={1}>
                <Grid itme md={6} xs={12}>
                  <h3 className="pagination-data">
                    Showing &nbsp;
                    {pagination?.showingText} of{" "}
                    {data.length} Results
                  </h3>
                </Grid>
                <Grid itme md={6} xs={12}>
                  <div style={{ position: "relative", }}>
                    <span className="pagination-view">
                      {!showNoData && data?.length && (
                        <Tooltip title="export" placement="top">
                          <Button
                            className="export"
                            onClick={(event) => ExportData(event)}
                            color="primary"
                          >
                            <img src="images/export_icon.png" alt="export_icon.png" />
                          </Button>
                        </Tooltip>
                      )}
                      {!showNoData && (
                        // pagination.TotalNoOfPages != 1 &&
                        <Pagination
                          count={pagination?.countBullet}
                          page={pageIndex}
                          onChange={handlePagination}
                          shape="rounded"
                        />
                      )}
                    </span>
                  </div>
                </Grid>
              </Grid>
            )}
            {Boolean(!showNoData && sortedData?.length) ? (
              <div className="suammry-list-view">
                <ul>
                  {header.map(i => <li><span>{i}</span></li>)}
                </ul>
                <div className="table-body scrool-toll">
                  {sortedData?.map((row, index) => {
                    return (
                      <ul>
                        <li>
                          <strong>{(pageIndex - 1) * 10 + (index + 1)}</strong>
                        </li>
                        <li>
                          <strong>{convertToIsoFormate(row.CreatedOn)}</strong>
                        </li>
                        <li>
                          <strong>
                            <a
                              onClick={() => {
                                if (isEndorsementEligible(row.ProductID)) {
                                  history.push(
                                    `/insurerticket/${row.RefObjectId}/${row.TicketDetailsID}`
                                  );
                                }
                                else {
                                  history.push(
                                    `/insurerticket/${row.RefObjectId}/${row.TicketDetailsID}`
                                  );
                                }
                              }}
                            >
                              {row.TicketDetailsID}
                            </a>
                          </strong>
                        </li>
                        <li>
                          <strong>
                            {row.InsurerAssignDate ? convertToIsoFormate(row.InsurerAssignDate) : '-'}
                          </strong>
                        </li>
                        <li>
                          <strong>{row.LeadID}</strong>
                        </li>
                        <li>
                          <strong>{row.ApplicationNo}</strong>
                        </li>
                        <li>
                          <strong>{row.PolicyNo}</strong>
                        </li>
                        <li>
                          <strong>{row.IssueName}</strong>
                        </li>
                        <li>
                          <strong>{row.SubIssueName}</strong>
                        </li>
                        <li>
                          <strong>{row.Status}</strong>
                        </li>
                        <li>
                          <strong>{row.InsurerStatusName}</strong>
                        </li>
                        <li>
                          <strong>
                            {row.InsurerStatusDate ? convertToIsoFormate(
                              new Date(row.InsurerStatusDate)
                            ) : '-'}
                          </strong>
                        </li>
                        <li>
                          <strong>{row.Lastrepliedon}</strong>
                        </li>
                        <li>
                          <strong>{row.EmailId}</strong>
                        </li>
                        <li>
                          <strong>{row.AGEING}</strong>
                        </li>
                        <li>
                          <strong>{row.RegistrationNo}</strong>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </div>
            ) : (
              showNoData && (
                <div className="no-data-found">
                  <img src="images/no_data_found.svg" alt="no_data_found.svg" />
                  <p>No data found</p>
                </div>
              )
            )}
          </div>
        </Grid>
      )}
    </div>
  );
}
