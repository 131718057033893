import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import AllTicket from "./pages/AllTickets";
import TicketDetail_New from "./pages/TicketDetail_New";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import SME_Employee_Detail from "./pages/SME_employee_detail";
import Dashboard_2 from "./pages/Dashboard_2";
import CreateTicketFromMatrix from "./pages/CreateTicketFromMatrix";
import LogPage from "./pages/LogPage";
import InsurerDashboard from "./pages/InsurerDashboard/InsurerDashboard";
import PolicyLink from "./pages/InsurerDashboard/PolicyLink";
import TicketSummary from "./pages/InsurerDashboard/TicketSummary";
import UserManagement from "./pages/InsurerDashboard/UserManagement";
import APIMaster from "./pages/APIMaster";
import RMLogMaster from "./pages/RMLogMaster";
import NewAPIMaster from "./pages/NewAPIMaster";
import { connect } from 'react-redux'
import RouteWithLayout from "./Components/Layout/RouteWithLayout";
import MainLayout from "./Components/Layout/MainLayout";
import InsurerTicket from "./pages/InsurerDashboard/Components/InsurerTicketView/InsurerTicket";
import WeCareMyTicket from './pages/WeCareMyTicket';
import Unauthorized from "./Components/Unauthorized/Unauthorized";
import AddUserTicket from "./pages/AddUser/AddUser_ticket";
import BrokenPage from "./pages/BrokenPage";
import PBDashboardLayout from "./Components/Layout/PBDashboardLayout";
import PBDashboard from "./pages/PBDashboard";
import { envUrl, PB_ADMIN, USERID_ALLOWED_FOR_APP_LOG,USERID_ALLOWERD_TO_MANAGE_USERS } from "./Config";
import { PBAdminAsAgentDashboard } from "./pages/DashboardPB/Admin";
import { EXTERNAL_DASHBOARD } from "./pages/ExternalDashboard";
import EDLayout from "./Components/Layout/EDLayout";
import InsertIssueSubIssue from "./pages/InsertIssueSubIssue";
import { OneLead } from "./pages/DashboardPB/Admin/Components/OneLead";
import TicketCacheManagement from "./pages/TicketCacheManagement/TicketCacheManagement";
import PBDashboardV2 from "./pages/PBDashboardV2";
import InsurerSignIn from "./pages/InsurerDashboard/InsurerLogin/SignIn";

const RouteFile = (props) => {
  const { auth, userInfo } = props
  const [isActionsAllowed, setIsActionsAllowed] = useState(true);
  const [AccessTypeVal, setAccessTypeVal] = useState("");
  const [sourceForCreateTicket, setSourceForCreateTicket] = useState(null);
  useEffect(() => {
    if (userInfo && userInfo.AccessType === 2) {
      console.log('Value set to false AccessType')
      setIsActionsAllowed(false);
      setSourceForCreateTicket(userInfo.Source);
      setAccessTypeVal(userInfo.AccessType);
    }
    else {
      setIsActionsAllowed(true);
      console.log('Value set to true AccessType')
    }
  }, [userInfo]);

  if (!auth) {
    console.log("not authorizes", auth);
    return (
      <>
        <Switch>
          <Route path="/login">
            <InsurerSignIn history={props.history}/>
          </Route>
          <Route path="/LandingPage">
            <LandingPage
              setIsActionsAllowed={setIsActionsAllowed}
              setAccessTypeVal={setAccessTypeVal}
              setSourceForCreateTicket={setSourceForCreateTicket}
            />
          </Route>
          <Route exact path="/createTicket/:leadId">
            <CreateTicketFromMatrix name="Create Ticket" />
          </Route>
          <Route
            exact
            path="/alltickets/:ref_object_id/:ticket_details_id/:viewtype"
          >
            <AllTicket
              isActionsAllowed={isActionsAllowed}
              AccessTypeVal={AccessTypeVal}
              sourceForCreateTicket={sourceForCreateTicket}
              name="All Tickets"
            />
          </Route>
          <Route
            exact
            path="/ticketdetails/:ref_object_id/:ticket_details_id/:viewtype"
          >
            <TicketDetail_New
              isActionsAllowed={isActionsAllowed}
              AccessTypeVal={AccessTypeVal}
              sourceForCreateTicket={sourceForCreateTicket}
              name="Ticket Details"
            />
          </Route>
          <Redirect
            from="**"
            to={{
              //  pathname: "/InsurerLogin",
              pathname: "/login",
            }}
          />
        </Switch>
      </>
    );
  }
  else {
    return (userInfo && (userInfo["AccessType"] === 6)) ? // 6---------> ADMIN
      <Switch>
        <RouteWithLayout
          component={WeCareMyTicket}
          layout={MainLayout}
          componentName="WeCareMyTicket"
          path='/wecaremyticket'
          authorized={userInfo && userInfo["AccessType"] === 6 ? true : false}
        />
        <Route path="/LandingPage">
          <LandingPage
            setIsActionsAllowed={setIsActionsAllowed}
            setAccessTypeVal={setAccessTypeVal}
            setSourceForCreateTicket={setSourceForCreateTicket}
          />
        </Route>
        <RouteWithLayout
          component={InsurerDashboard}
          layout={MainLayout}
          componentName="Insurer Dashboard (ADMIN)"
          path='/InsurerAdminDashboard'
          authorized={userInfo && userInfo["AccessType"] === 6 ? true : false}
        />
        <RouteWithLayout
          component={InsurerTicket}
          layout={MainLayout}
          path='/insurerticket/:ref_object_id/:ticket_details_id'
          componentName="Ticket Detail (ADMIN)" 
          authorized={userInfo && userInfo["AccessType"] === 6 ? true : false}
          />

        <RouteWithLayout
          component={PolicyLink}
          layout={MainLayout}
          componentName="Policy Link (ADMIN)"
          path='/PolicyLink'
          authorized={userInfo && userInfo["AccessType"] === 6 ? true : false}
        />
        <RouteWithLayout
          component={TicketSummary}
          layout={MainLayout}
          componentName="Ticket Summary (ADMIN)"
          path='/TicketSummary'
          authorized={userInfo && userInfo["AccessType"] === 6 ? true : false}
        />

        <RouteWithLayout
          component={UserManagement}
          layout={MainLayout}
          componentName="User Management (ADMIN)"
          path='/UserManagement'
          authorized={userInfo && userInfo["AccessType"] == 6 ? true : false}
        />

        <RouteWithLayout
          component={AllTicket}
          layout={MainLayout}
          componentName="Ticket Detail (ADMIN)"
          path='/insurerticket/:ref_object_id/:ticket_details_id'
          authorized={userInfo && userInfo["AccessType"] == 6 ? true : false}
        />

        <RouteWithLayout
          component={Dashboard}
          layout={MainLayout}
          componentName="Dashboard"
          path='/weCare/dashboard'
          authorized={userInfo && userInfo["AccessType"] == 6 ? true : false}
        />

        <Redirect
          from="**"
          to="/InsurerAdminDashboard"
        />
      </Switch>

      :
      (userInfo && (userInfo["AccessType"] === 7)) ? // 7------> AGENT
        <Switch>
          {console.log('accseestype-->', userInfo)}
          <Route path="/LandingPage">
            <LandingPage
              setIsActionsAllowed={setIsActionsAllowed}
              setAccessTypeVal={setAccessTypeVal}
              setSourceForCreateTicket={setSourceForCreateTicket}
            />
          </Route>
          <RouteWithLayout
            component={WeCareMyTicket}
            layout={MainLayout}
            componentName="WeCareMyTicket"
            path='/wecaremyticket'
            authorized={userInfo && userInfo["AccessType"] === 7 ? true : false}
          />
          <RouteWithLayout
            component={InsurerTicket}
            layout={MainLayout}
            path='/insurerticket/:ref_object_id/:ticket_details_id'
            componentName="Ticket Detail" 
            authorized={userInfo && userInfo["AccessType"] === 7 ? true : false}
            />

          <RouteWithLayout
            component={PolicyLink}
            layout={MainLayout}
            componentName="Policy Link"
            path='/PolicyLink'
            authorized={userInfo && userInfo["AccessType"] === 7 ? true : false}
          />
          <RouteWithLayout
            component={TicketSummary}
            layout={MainLayout}
            componentName="Ticket Summary"
            path='/TicketSummary'
            authorized={userInfo && userInfo["AccessType"] === 7 ? true : false}
          />

          <RouteWithLayout
            component={AllTicket}
            layout={MainLayout}
            componentName="Ticket Detail"
            path='/insurerticket/:ref_object_id/:ticket_details_id'
            authorized={userInfo && userInfo["AccessType"] === 7 ? true : false}
          />

          <RouteWithLayout
            component={InsurerDashboard}
            layout={MainLayout}
            componentName="Insurer Dashboard (AGENT)"
            path='/insurerDashboard'
            authorized={userInfo && userInfo["AccessType"] === 7 ? true : false}
          />

          <Redirect
            from="**"
            to="/InsurerDashboard"
          />
        </Switch>
        :
        (userInfo && (userInfo['AccessType'] === 8)) ? // 8---> External Dashboard
          <Switch>
            <Route path="/LandingPage">
              <LandingPage
                setIsActionsAllowed={setIsActionsAllowed}
                setAccessTypeVal={setAccessTypeVal}
                setSourceForCreateTicket={setSourceForCreateTicket}
              />
            </Route>
            <Route
              exact
              path="/alltickets/:ref_object_id/:ticket_details_id/:viewtype"
            >
              <AllTicket
                isActionsAllowed={isActionsAllowed}
                AccessTypeVal={AccessTypeVal}
                sourceForCreateTicket={sourceForCreateTicket}
                name="All Tickets"
              />
            </Route>
            <Route
              exact
              path="/ticketdetails/:ref_object_id/:ticket_details_id/:viewtype"
            >
              <TicketDetail_New
                isActionsAllowed={isActionsAllowed}
                AccessTypeVal={AccessTypeVal}
                sourceForCreateTicket={sourceForCreateTicket}
                name="Ticket Details"
              />
            </Route>
            <Route
              exact
              path="/details/:ref_object_id/:ticket_details_id/:viewtype"
            >
              <BrokenPage name="Broken" />
            </Route>
            
            <Route exact path="/broken">
            <BrokenPage name="Broken" />
            </Route>
            
            <RouteWithLayout
              component={EXTERNAL_DASHBOARD}
              layout={EDLayout}
              componentName="External Dashboard"
              path='/PBDashboard'
              authorized={true}
            />
            <Redirect
              from="**"
              to="/PBDashboard"
            />
          </Switch>
        :
        <Switch>
          <Route path="/LandingPage">
            <LandingPage
              setIsActionsAllowed={setIsActionsAllowed}
              setAccessTypeVal={setAccessTypeVal}
              setSourceForCreateTicket={setSourceForCreateTicket}
            />
          </Route>
          <Route
            exact
            path="/alltickets/:ref_object_id/:ticket_details_id/:viewtype"
          >
            <AllTicket
              isActionsAllowed={isActionsAllowed}
              AccessTypeVal={AccessTypeVal}
              sourceForCreateTicket={sourceForCreateTicket}
              name="All Tickets"
            />
          </Route>
          <Route
            exact
            path="/ticketdetails/:ref_object_id/:ticket_details_id/:viewtype"
          >
            <TicketDetail_New
              isActionsAllowed={isActionsAllowed}
              AccessTypeVal={AccessTypeVal}
              sourceForCreateTicket={sourceForCreateTicket}
              name="Ticket Details"
            />
          </Route>
          {/* <Route exact path="/dashboard">
            <Dashboard isActionsAllowed={isActionsAllowed} />
          </Route> */}
          {/* Common Route for Dasbhaord */}
          <RouteWithLayout
            component={PBDashboard}
            layout={PBDashboardLayout}
            componentName="Dashboard"
            path='/dashboardV1'
            authorized={true}
          />
          <RouteWithLayout  
            component={PBDashboardV2}
            layout={PBDashboardLayout}
            componentName="Dashboard-V2"
            path='/dashboardV2'
            authorized={true}
          />
           <RouteWithLayout
            component={SME_Employee_Detail}
            layout={PBDashboardLayout}
            componentName="Corporate Search"
            path='/sme-employee-details'
            authorized={true}
          />
          <RouteWithLayout
            component={OneLead}
            layout={PBDashboardLayout}
            componentName="One Lead"
            path='/one-lead'
            authorized={userInfo && userInfo.AccessType === PB_ADMIN  ? true : false}
            // authorized={userInfo && userInfo.UserID && USERID_ALLOWED_FOR_SKIP_REPORT_ONELEAD(envUrl()).includes(userInfo.UserID) ? true : false}
          />
           <RouteWithLayout
            component={AddUserTicket}
            layout={PBDashboardLayout}
            componentName="Manage Users"
            path='/add-user'
            authorized={userInfo && userInfo.UserID && envUrl() && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl())?.includes(userInfo.UserID) ? true : false}
          />
           <RouteWithLayout
            component={LogPage}
            layout={PBDashboardLayout}
            componentName="Appliation Logs"
            path='/applicationLogs'
            authorized={userInfo && userInfo.UserID && envUrl() && USERID_ALLOWED_FOR_APP_LOG(envUrl())?.includes(userInfo.UserID) ? true : false}
          />
           <RouteWithLayout
            component={PBAdminAsAgentDashboard}
            layout={PBDashboardLayout}
            componentName="My Dashboard"
            path='/my-dashboard'
            authorized={userInfo && userInfo.AccessType === PB_ADMIN  ? true : false}
          />
          <RouteWithLayout
            component={InsertIssueSubIssue}
            layout={PBDashboardLayout}
            componentName="Insert Ticket Issues Sub Issues"
            path='/insert-issue-sub-issue'
            authorized={userInfo && userInfo.UserID && envUrl() && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl())?.includes(userInfo.UserID) ? true : false}
          />
          <Route exact path="/smeemployeedetail">
            <SME_Employee_Detail />
          </Route>
          <Route exact path="/createTicket/:leadId">
            <CreateTicketFromMatrix name="Create Ticket" />
          </Route>
          {/* <Route exact path="/applicationLogs">
            <LogPage name="Application Logs" />
          </Route> */}
           <RouteWithLayout
            component={APIMaster}
            layout={PBDashboardLayout}
            componentName="Application Master"
            path='/apiMaster'
            authorized={userInfo && userInfo.UserID && envUrl() && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl())?.includes(userInfo.UserID) ? true : false}
          />
          {/* <Route exact path="/apimaster">
            <APIMaster name="API Master" />
          </Route> */}
           <RouteWithLayout
            component={RMLogMaster}
            layout={PBDashboardLayout}
            componentName="RM Log Master"
            path='/rmLogMaster'
            authorized={userInfo && userInfo.UserID && envUrl() && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl())?.includes(userInfo.UserID) ? true : false}
          />
          <RouteWithLayout
            component={InsertIssueSubIssue}
            layout={PBDashboardLayout}
            componentName="Insert Ticket Issues Sub Issues"
            path='/insert-issue-sub-issue'
            authorized={userInfo && userInfo.UserID && envUrl() && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl())?.includes(userInfo.UserID) ? true : false}
          />
          <RouteWithLayout
            component={TicketCacheManagement}
            layout={PBDashboardLayout}
            componentName="Ticket Cache Management"
            path='/ticket-cache-management'
            // authorized={userInfo && userInfo.UserID && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl()).includes(userInfo.UserID) ? true : false}
            authorized={true}
          />
          {/* <Route exact path="/rmlogmaster">
            <RMLogMaster name="RM Log Master" />
          </Route> */}
           <RouteWithLayout
            component={NewAPIMaster}
            layout={PBDashboardLayout}
            componentName="New API Master"
            path='/newApiMaster'
            authorized={userInfo && userInfo.UserID && envUrl() && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl())?.includes(userInfo.UserID) ? true : false}
          />
          {/* <Route exact path="/newapimaster">
            <NewAPIMaster name="New API Master" />
          </Route> */}
          <Route exact path="/unauthorized">
            <Unauthorized name="Unauthorized User" />
          </Route>
          {/* <Route exact path="/add-user">
            <AddUserTicket name="Add User" />
          </Route> */}
          <Route exact path="/broken">
            <BrokenPage name="Broken" />
          </Route>
          <Redirect
            from="**"
            to={{
              pathname: "/dashboardV2",
            }}
          />
        </Switch>
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state && state.auth && state.auth.auth || false,
    userInfo: state && state.auth && state.auth.userInfo || {}
  }
}

export default connect(mapStateToProps)(RouteFile);