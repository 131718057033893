import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link } from "@material-ui/core";
import InsurerAdminDashboard from "./Components/InsurerAdminDashboard";
import InsurerAgentDashboard from "./Components/InsurerAgentDashboard";
import TicketSummary from "./TicketSummary";
import PolicyLink from "./PolicyLink";
import { useHistory } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import UserProfile from "./Components/UserProfile";
import { connect } from 'react-redux'
import Spinner from "../../shared/components/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function InsurerDashboard({ fetchedUserDetails }) {
  const classes = useStyles();
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState(0);
  const [userType, setUserType] = useState();
  const [userDetails, setUserDetails] = useState();

  useEffect(()=>{
    if(fetchedUserDetails && Object.keys(fetchedUserDetails).length>0){
      setUserType(fetchedUserDetails.AccessType)
      setUserDetails(fetchedUserDetails)
    }
    else{
      console.log('no userInfo found from redux')
    }
  },[fetchedUserDetails] )

  if(userDetails && Object.keys(userDetails).length>0){
  return (
    <div className="insurer-dashbaord">
      <div className="dashboard">
        <ul>
          <li className="rightbar">
            {userType ==  6? (
              <InsurerAdminDashboard
                userDetails={userDetails}
              />
            ) : (
              <InsurerAgentDashboard
                userDetails={userDetails}
              />
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
else{
 return <div>
    <Spinner/>
  </div>
}

}

const mapStateToProps = (state) =>{
  return{
    fetchedUserDetails : state && state.auth && state.auth.userInfo ? state.auth.userInfo : {}
  }
}

export default connect(mapStateToProps, null)(InsurerDashboard)
