import React, { useEffect, useState } from "react";
import { List, ListItem } from "@material-ui/core";
import TicketView from "../Components/TicketView/TicketView";
import TicketDetailBox from "../Components/TicketDetail/TicketDetail";
import TicketEsclationBox from "../Components/TicketEscalation/TicketEsclation";
import TicketTimelineBox from "../Components/TicketTimeline/TicketTimeline";
import Topbar from "../Components/Topbar/Topbar";
import {
  UpdateL0ToL1,
  getRedAmberResponse,
  GetTicketComm,
  GetTicketLog,
  GetTicketFilterCount,
  GetTicketDetailsByTicketID,
} from "../constants/apiUrls";
import { GET, POST } from "../services/apiServices";
import { useSnackbar } from "notistack";

import TicketContext from "../TicketContext";
import PGRefund from "../Components/PGRefund/PGRefundModal";
import { EMAIL_FROM_CUSTOMER, FETCH_TEMPLATE_ON_TICKET_CHANGE, MY_ACCOUNT, showPGRefundDialog } from "../Config";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CommentsHistory from "../Components/CommentsHistory/CommentsHistory";
import { connect } from "react-redux";
import * as actions from "../redux/actions/ticketDetails";
import ChildErrorBoundary from "../shared/components/ChildErrorBoundary";
import { useHistory } from "react-router-dom";
import { authLogin } from "../redux/actions/auth";
import CustomBackdrop from "./Components/CustomBackdrop";
import { setScreenName } from "../redux/actions/common_action";
import DynamicConfirmationDialog from "../shared/components/DynamicConfirmationBox";

function AllTickets(props) {
  const {
    isActionsAllowed,
    AccessTypeVal,
    sourceForCreateTicket,
    IsReadOnlyUser_store,
    TicketLoading_store,
    setTicketLoading_store,
    TicketDetails_store,
      } = props;
  const dispatch = useDispatch();
  const confirmDialogControls_store = useSelector(state => state && state.Utility && state.Utility.confirmDialogControls || null) 
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    SendToInsurer: false,
    SendToInsurerMsg: "",
    RootCause: [],
  });
  const [oldTicketDetails, setoldTicketDetails] = useState({
    SendToInsurer: false,
    SendToInsurerMsg: "",
  });
  const [sourceList, setSourceList] = useState([]);
  const [goToTemplate, setGoToTemplate] = useState(false);
  const [ticketCommData, setTicketCommData] = useState([]);
  const [ticketLogs, setTicketLogs] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [isTicketTimelineLoading, setIsTicketTimelineLoading] = useState(false);
  const [isLogsLoading, setIsLogsLoading] = useState(false);
  const [filterCounts, setFilterCounts] = useState({});
  const [isAllTicketsLoading, setAllTicketsLoading] = useState(false);
  const [allTickets, setAllTickets] = useState({});
  const [activeTicketId, setActiveTicketId] = useState("");
  const [isProductValueChanged, setIsProductValueChanged] = useState(false);
  const [selectFormOptions, setSelectFormOptions] = useState({
    statusOptions: [],
    subStatusOptions: [],
    allSubStatusOptions: [],
    callbackOptions: [],
    responsibilityOptions: [],
    classificationOptions: [],
    issueOptions: [],
    subIssueOptions: [],
    rootCauseOptions: [],
    followUpDate: [],
    allIssueOptions: [],
    suppliersList: [],
    productsList: [],
    insurerOptions: [],
  });
  const [RootCauses, setRootCauses] = useState([]);
  const [isTicketDetailEditable, setIsTicketDetailEditable] = useState(false);
  const [isEsclationLoading, setIsEsclationLoading] = useState(false);
  const [esclationInfo, setEsclationInfo] = useState({});
  const [EsclationLevelTime, setEsclationLevelTime] = useState({
    L0: 0,
    L1: 0,
    L2: 0,
  });
  const [EndorsementLink, setEndorsementLink] = useState("");
  const [errors, setErrors] = useState({
    issue: false,
    subIssue: false,
    followUpDate: false,
    product: false,
    suppllier: false,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [masters, setMasters] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isValidForSME, setIsValidForSME] = useState(false);
  const [leadDetails, setLeadDetails] = useState({});
  const [enablePGRefundDialog, setEnablePGRefundDialog] = useState(true);

  //-----Setting TICKET-ID FOR HEADERS-----
  let { ticket_details_id, viewtype } = useParams()
  useEffect(()=>{
    localStorage.setItem("xTicketID", ticket_details_id)
  },[ticket_details_id])
  //========xxxxxxxx=======================

  //For setting the screen name
  useEffect(()=>{
    dispatch(setScreenName(props.name || 'All Tickets'))
  },[])

  useEffect(() => {
    dispatch(authLogin());
    if (AccessTypeVal == 5) {
      setViewOnly(true);
    }
  }, [AccessTypeVal]);

  const GetTicketLogsApi = (ticketId = "") => {
    setIsLogsLoading(true);
    GET(`${GetTicketLog}/${ticketId ? ticketId : activeTicketId}/0`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setTicketLogs(response.Data);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setIsLogsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLogsLoading(false);
      });
  };

  const GetTicketCommApi = (RefObjectId, TicketDetailsID) => {
    setIsTicketTimelineLoading(true);
    GET(`${GetTicketComm}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const d = response.Data.sort(
            (a, b) =>
              new Date(b.CreatedOn || b.ReceivedOn) -
              new Date(a.CreatedOn || a.ReceivedOn)
          ).map((item) => ({
            ...item,
            DisplayDate: new Date(item.CreatedOn || item.ReceivedOn).getTime(),
            type: "data",
          }));
          setTicketCommData(d);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setIsTicketTimelineLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsTicketTimelineLoading(false);
      });
  };

  const GetFilterCountApi = (ticketId = "") => {
    GET(`${GetTicketFilterCount}/${ticketId ? ticketId : activeTicketId}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setFilterCounts(response.Data);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateL0ToL1Api = () => {
    const payload = {
      TicketId: oldTicketDetails.TicketId,
      AssignedById: 0,
    };

    POST(UpdateL0ToL1, payload)
      .then((res) => {
        if (res.ErrorCode === 0) {
          setIsEsclationLoading(true);
          GetTicketLogsApi();
          GET(`${getRedAmberResponse}/${oldTicketDetails.TicketId}`)
            .then((response) => {
              setEsclationInfo(response.Data);
              setEsclationLevelTime({
                L0: response.Data.L0_Green,
                L1: response.Data.L1_Amber,
                L2: response.Data.L2_Red,
              });
              setIsEsclationLoading(false);
            })
            .catch((err) => {
              setIsEsclationLoading(false);
              enqueueSnackbar("Something went wrong!", {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            });
          enqueueSnackbar(res.Message || "Responsbility shifted to L1", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log(err);
      });
  };

  const GetTicketDetailsByTicketIdApi = (RefObjectId, TicketDetailsID) => {
        setTicketLoading_store(true);
    GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        setTicketLoading_store(false);
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest, IsInsurerBucket } = response.Data;
          let callbackVal;
          if (IsCallBackRequest === "0") {
            callbackVal = "No";
          } else if (IsCallBackRequest === "1") {
            callbackVal = "Yes";
          } else if (IsCallBackRequest === "2") {
            callbackVal = "Completed";
          }
          setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            RefundId: response.Data.RefundId,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
          setRootCauses(response.Data.RootCause.split(","));
          setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log("error TicketDetails line 394");
        }
      })
      .catch((err) => {
        setTicketLoading_store(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log("error", err);
      });
  };

  const closePGRefundDialog = () => {
    setEnablePGRefundDialog(false);
  };

  //Resets the flag every time new lead is opened
  useEffect(() =>{
    if(TicketDetails_store?.TicketId){
      setEnablePGRefundDialog(true);
    }
    
  },[TicketDetails_store?.TicketId])

  //for fetching latest template list based on ticket product and ticket id
  useEffect(() => {
    if(TicketDetails_store && TicketDetails_store.ProductId){
      if (FETCH_TEMPLATE_ON_TICKET_CHANGE.includes(TicketDetails_store.ProductId)) {
        return dispatch(actions.fetchLastestTemplateList(TicketDetails_store))
      }
      else if (TicketDetails_store && TicketDetails_store.ProductId >= 0) {
        dispatch(actions.fetchLastestTemplateList(TicketDetails_store))
      }
      else {
        dispatch(actions.setTemplateList([]))
        console.log('no template loading')
      }
    }
  }, [TicketDetails_store.ProductId, TicketDetails_store.TicketId]);
  

  return (
    <TicketContext.Provider
      value={{
        GetTicketLogsApi: GetTicketLogsApi,
        setActiveTicketId: setActiveTicketId,
        GetFilterCountApi: GetFilterCountApi,
        setFormValues: setFormValues,
        setoldTicketDetails: setoldTicketDetails,
        setAllTicketsLoading: setAllTicketsLoading,
        setAllTickets: setAllTickets,
        activeTicketId: activeTicketId,
        allSubStatusOptions: selectFormOptions.allSubStatusOptions,
        GetTicketCommApi: GetTicketCommApi,
        ticketCommData: ticketCommData,
        suppliersList: selectFormOptions.suppliersList,
        isProductValueChanged: isProductValueChanged,
        setIsProductValueChanged: setIsProductValueChanged,
        isActionsAllowed: isActionsAllowed,
        TicketId: oldTicketDetails.TicketId,
        RefObjectId: oldTicketDetails.RefObjectId,
        TicketDetailsID: oldTicketDetails.TicketDetailsID,
        LeadID: oldTicketDetails.LeadID,
        AccessTypeVal: AccessTypeVal,
        sourceForCreateTicket: sourceForCreateTicket,
        allTickets: allTickets,
        leadDetails: leadDetails,
        GetTicketDetailsByTicketIdApi: GetTicketDetailsByTicketIdApi,
      }}
    >
      <div className="main-container">
        {TicketLoading_store && <CustomBackdrop open={TicketLoading_store} />}
        <List className="all-ticket-list">
          <ListItem>
            <div className="all-ticket scrool-toll">
              <TicketView
                setFormValues={setFormValues}
                setTicketLogs={setTicketLogs}
                setIsLogsLoading={setIsLogsLoading}
                setEsclationInfo={setEsclationInfo}
                setFilterCounts={setFilterCounts}
                allTickets={allTickets}
                isAllTicketsLoading={isAllTicketsLoading}
                setIsEsclationLoading={setIsEsclationLoading}
                setIsTicketDetailEditable={setIsTicketDetailEditable}
                setSelectFormOptions={setSelectFormOptions}
                selectFormOptions={selectFormOptions}
                setMasters={setMasters}
                setSourceList={setSourceList}
                setEsclationLevelTime={setEsclationLevelTime}
                oldTicketDetails={oldTicketDetails}
                Responsibility={esclationInfo.Responsibility}
                setEndorsementLink={setEndorsementLink}
                setErrors={setErrors}
                viewOnly={viewOnly}
                refresh={refresh}
                viewtype={viewtype}
                RootCauses={RootCauses}
                setRootCauses={setRootCauses}
                leadDetails={leadDetails}
                setLeadDetails={setLeadDetails}
              />
            </div>
          </ListItem>
          <ListItem>
            <Topbar
              oldTicketDetails={oldTicketDetails}
              TicketId={oldTicketDetails.TicketId}
              IsReopen={oldTicketDetails.IsReopen}
              sourceList={sourceList}
              IsEscalated={oldTicketDetails.IsEscalated}
              LeadId={oldTicketDetails.LeadID}
              setFormValues={setFormValues}
              TicketDetailsID={oldTicketDetails.TicketDetailsID}
              BlockAutoComm={oldTicketDetails.BlockAutoComm}
              TATEscalation={oldTicketDetails.TATEscalation}
              StatusID={oldTicketDetails.StatusID}
              masters={masters}
              RefObjectId={oldTicketDetails.RefObjectId}
              isBlockAutoCommVisible={oldTicketDetails.isBlockAutoCommVisible}
              EndorsementLink={EndorsementLink}
              Source={oldTicketDetails.Source}
              isTopMgmt={oldTicketDetails.HighComplaint > 0 ? true : false}
              isHCS={oldTicketDetails.EscLevel1 === "Yes" ? true : false}
              IsJAG={oldTicketDetails.IsJAG === 1 ? true : false}
              isSocial={
                oldTicketDetails.Source === "Social" ||
                oldTicketDetails.IsSocial
                  ? true
                  : false
              }
              isCGO={oldTicketDetails.EscLevel2 === "Yes" ? true : false}
              isIRDA={oldTicketDetails.Escalation == "Yes" ? true : false}
              ParentTicketID={oldTicketDetails.ParentTicketID}
              ProductID={oldTicketDetails.ProductId}
              SubSource={oldTicketDetails.SubSource}
              AccessTypeVal={AccessTypeVal}
              PartnerLevel={allTickets.PartnerLevel}
              viewOnly={viewOnly}
              viewtype={viewtype}
              isValidForSME={isValidForSME}
              isActionsAllowed={isActionsAllowed}
              IsJourney={oldTicketDetails.IsJourney ? true : false}
              RenewalLeadId={oldTicketDetails.RenewalLeadId ? true : false}
            />
            <ChildErrorBoundary componentName="TicketDetails">
              <TicketDetailBox
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                goToTemplate={goToTemplate}
                setGoToTemplate={setGoToTemplate}
                formValues={formValues}
                setFormValues={setFormValues}
                isTicketDetailEditable={isTicketDetailEditable}
                setIsTicketDetailEditable={setIsTicketDetailEditable}
                oldTicketDetails={oldTicketDetails}
                templateList={templateList}
                setTemplateList={setTemplateList}
                setSelectFormOptions={setSelectFormOptions}
                selectFormOptions={selectFormOptions}
                CurrentLevel={esclationInfo.CurrentLevel}
                errors={errors}
                setErrors={setErrors}
                setIsEsclationLoading={setIsEsclationLoading}
                setEsclationInfo={setEsclationInfo}
                setEsclationLevelTime={setEsclationLevelTime}
                IsEscalated={oldTicketDetails.IsEscalated}
                isTopMgmt={oldTicketDetails.HighComplaint > 0 ? true : false}
                isHCS={oldTicketDetails.EscLevel1 === "Yes" ? true : false}
                isSocial={
                  oldTicketDetails.Source === "Social" ||
                  oldTicketDetails.IsSocial
                    ? true
                    : false
                }
                isCGO={oldTicketDetails.EscLevel2 === "Yes" ? true : false}
                isIRDA={oldTicketDetails.Escalation == "Yes" ? true : false}
                IsReopen={oldTicketDetails.IsReopen}
                viewOnly={viewOnly}
                refresh={refresh}
                setRefresh={setRefresh}
                viewtype={viewtype}
                isValidForSME={isValidForSME}
                setIsValidForSME={setIsValidForSME}
                RootCauses={RootCauses}
                setRootCauses={setRootCauses}
                communications={ticketCommData.filter((comm) =>
                  (comm.EventType
                    ? comm.EventType.toLowerCase().includes(
                        EMAIL_FROM_CUSTOMER.toLowerCase()
                      )
                    : false) ||
                  [
                    "rmemails",
                    "care",
                    "communication",
                    "communications",
                    "social",
                    "myaccount",
                    "topmanagement",
                  ].indexOf(comm.TicketSource?.toLowerCase()) > -1
                    ? true
                    : false
                )} // pg refund v2 email communications
                IsJourney={oldTicketDetails.IsJourney ? true : false}
              />
            </ChildErrorBoundary>
            {viewtype == 1 && (
              <CommentsHistory
                oldTicketDetails={oldTicketDetails}
                templateList={templateList}
                setRefresh={setRefresh}
                viewtype={viewtype}
                IsReadOnlyUser={IsReadOnlyUser_store}
              />
            )}
            <TicketEsclationBox
              esclationInfo={esclationInfo}
              UpdateL0ToL1Api={UpdateL0ToL1Api}
              isEsclationLoading={isEsclationLoading}
              StatusID={oldTicketDetails.StatusID}
              IssueName={oldTicketDetails.IssueName}
              SubIssueName={oldTicketDetails.SubIssueName}
              AssignedTo={oldTicketDetails.AssignedTo}
              EsclationLevelTime={EsclationLevelTime}
              setEsclationLevelTime={setEsclationLevelTime}
              ParentTicketID={oldTicketDetails.ParentTicketID}
              viewOnly={viewOnly}
              viewtype={viewtype}
            />
            <TicketTimelineBox
              goToTemplate={goToTemplate}
              setGoToTemplate={setGoToTemplate}
              issueName={oldTicketDetails.IssueName}
              ticketLogs={ticketLogs}
              templateList={templateList}
              oldTicketDetails={oldTicketDetails}
              isTicketTimelineLoading={isTicketTimelineLoading}
              isLogsLoading={isLogsLoading}
              filterCounts={filterCounts}
              AccessTypeVal={AccessTypeVal}
              viewOnly={viewOnly}
              setRefresh={setRefresh}
              viewtype={viewtype}
              showNewTabs={false}
              isValidForSME={isValidForSME}
            />
          </ListItem>
        </List>
        {oldTicketDetails &&
        oldTicketDetails.Source &&
        oldTicketDetails.ISREFUNDED &&
        oldTicketDetails.ProductId &&
        showPGRefundDialog(
          oldTicketDetails.Source,
          oldTicketDetails.ISREFUNDED,
          oldTicketDetails.ProductId,
          ticketCommData
        ) &&
        enablePGRefundDialog &&
        !IsReadOnlyUser_store ? (
          <PGRefund
            isDialogOpen={enablePGRefundDialog ? true : false}
            closePGRefundDialog={closePGRefundDialog}
            isTicketTimelineLoading={isTicketTimelineLoading}
            LeadID={oldTicketDetails.LeadID}
            ProductID={oldTicketDetails.ProductId}
            Source={oldTicketDetails.Source}
            TicketDetailsID={oldTicketDetails.TicketDetailsID}
            IssueTypeId={oldTicketDetails.SubIssueID}
            CustomerID={oldTicketDetails.CustomerId}
            RefObjectId={oldTicketDetails.RefObjectId}
            SupplierId={oldTicketDetails.SupplierId}
            ticketId={oldTicketDetails.TicketId}
            ticketCreationDate={oldTicketDetails.CreatedOn}
            ApplicationNo={leadDetails.ApplicationNo}
            communications={ticketCommData.filter((comm) =>
              comm.EventType
                ? comm.EventType.toLowerCase().includes(
                    EMAIL_FROM_CUSTOMER.toLowerCase()
                  ) ||
                  [
                    "rmemails",
                    "care",
                    "communication",
                    "communications",
                    "social",
                    "topmanagement",
                    "whatsapp",
                    "myaccount"
                  ].indexOf(comm.TicketSource?.toLowerCase()) > -1
                  ? true
                  : false
                : false
            )}
            viewOnly={viewOnly}
            viewtype={viewtype}
          />
        ) : (
          ""
        )}
        {/* ConfirmationDialog */}
        {
          confirmDialogControls_store && confirmDialogControls_store.open && <DynamicConfirmationDialog
            open={confirmDialogControls_store.open}
            title={confirmDialogControls_store.title}
            buttonWithFunction={confirmDialogControls_store.actionButtons}
            handleClose={confirmDialogControls_store.handleClose}
          />
        }
      </div>
    </TicketContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return {
    IsReadOnlyUser_store:
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser,
    TicketLoading_store : state && state.ticketdetails && state.ticketdetails.isTicketLoading || false,
    TicketDetails_store : state && state.ticketdetails && state.ticketdetails.ticketDetails || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetOldTicketDetails: (payload) =>
      dispatch(actions.setTicketDetails(payload)),
    setTicketLoading_store: (payload) =>
      dispatch(actions.setTicketLoading(payload))
  };
  
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTickets);
