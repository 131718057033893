import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: theme.spacing(1),
  },
}));

export default ({color}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size="1.7rem" color={color || "primary"} />
    </div>
  );
};

