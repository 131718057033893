import React, { useEffect, useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CommunicationBox from '../../../../../Components/CommunicationBox/CommunicationBox'
import { getFormatedName, VERSION } from '../../../../../Config'
import { connect } from 'react-redux'
import * as actions from '../../../../../redux/actions/ticketDetails'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { GET } from '../../../../../services/apiServices'
import { convertToIsoFormate } from '../../../../../shared/methods/methods'
import { Tooltip, Button } from '@material-ui/core'
import Spinner from '../../../../../shared/components/Spinner'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

function InsurerTicketSidebar(props) {
  const classes = useStyles()
  const {
    OpenedTicketDetails,
    onSetOpenedTicketDetails,
    OnSetTicketComm,
  } = props
  const { ref_object_id, ticket_details_id } = useParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isLeadDetailsLoading, setIsLoadDetailsLoading] = useState(true)
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      leadDetails: {},
      AllTicketSummary: {},
      TicketComm: {},
    },
  )

  useEffect(() => {
    //API to be called on first render
    if ((ref_object_id, ticket_details_id)) {
      GetTicketsByTicketID(ref_object_id, ticket_details_id)
      // FetchAllTicketSummary(ref_object_id, ticket_details_id)
      //GetTicketCommAPI(ref_object_id, ticket_details_id)
      OnSetTicketComm({
        RefObjectId: ref_object_id,
        TicketDetailsID: ticket_details_id,
        Source : "WeCare"
      })
    }
  }, [])

  useEffect(() => {
    if (
      OpenedTicketDetails &&
      Object.keys(OpenedTicketDetails).length > 0 &&
      OpenedTicketDetails.LeadID
    ) {
      FetchLeadDetails(OpenedTicketDetails.LeadID)
    }
  }, [OpenedTicketDetails])

  const FetchLeadDetails = (LEADID) => {
    setIsLoadDetailsLoading(true)
    GET(`GetLeadDetails/${LEADID}/${ticket_details_id}/${ref_object_id}`)
      .then((res) => {
        if (res && res.ErrorCode == 0) {
          setState({ leadDetails: res.Data })
          setIsLoadDetailsLoading(false)
        } else {
          enqueueSnackbar("Oops! Something's not right", {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
          setIsLoadDetailsLoading(false)
          console.log('InsurerTicketSidebar line 60')
        }
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          onClick: () => {
            closeSnackbar()
          },
        })
        setIsLoadDetailsLoading(false)
        console.log('Catch InsurerTicketSidebar FetchLeadDetails API')
      })
  }

  const GetTicketsByTicketID = (objID, ticketID) => {
    GET(`GetTicketDetailsByTicketID/${objID}/${ticketID}`)
      .then((res) => {
        if (res && res.ErrorCode == 0) {
          onSetOpenedTicketDetails(res.Data)
          console.log('res')
        } else {
          enqueueSnackbar("Oops! Something's not right", {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
          console.log('error in InsurerTicketSidebar GetTicketDetailsByIdApi')
        }
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          onClick: () => {
            closeSnackbar()
          },
        })
        console.log(err)
      })
  }

  const FetchAllTicketSummary = (objID, ticketID) => {
    GET(`GetTicketSummary/${objID}/${ticketID}`)
      .then((res) => {
        if (res && res.ErrorCode == 0 && res.Data) {
          // Filtering tickets from API based on status id
          let { AllTicketList } = res.Data
          const sortedData1 = AllTicketList
            ? AllTicketList.filter(
                (i) => i.StatusID != 3 && i.StatusID != 4,
              ).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn),
              )
            : []
          const sortedData2 = AllTicketList
            ? AllTicketList.filter((i) => i.StatusID == 3).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn),
              )
            : []
          const sortedData3 = AllTicketList
            ? AllTicketList.filter((i) => i.StatusID == 4).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn),
              )
            : []
          setState({
            AllTicketSummary: [...sortedData1, ...sortedData2, ...sortedData3],
          })
        } else {
          enqueueSnackbar("Oops! Something's not right", {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
          console.log('FetchAllTicketSummary error 140')
        }
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          onClick: () => {
            closeSnackbar()
          },
        })
        console.log('catch FetchAllTicketSummary', err)
      })
  }

  const GetTicketCommAPI = (objID, ticketID) => {
    GET(`GetTicketComm/${objID}/${ticketID}`)
      .then((res) => {
        if (res && res.ErrorCode == 0) {
          setState({ TicketComm: res.Data })
        } else {
          enqueueSnackbar("Oops! Something's not right", {
            variant: 'error',
            onClick: () => {
              closeSnackbar()
            },
          })
          console.log('InsurerTicketSidebar line 171')
        }
        console.log('response ', res)
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          onClick: () => {
            closeSnackbar()
          },
        })
        console.log('Error', err)
      })
  }

  const getCutomerProfilePicWithLetters = (name) => {
    if (name) {
      const nameArr = name.split(' ')
      if (nameArr.length > 1 && nameArr[0] && nameArr[nameArr.length - 1]) {
        return (
          nameArr[0][0].toUpperCase() +
          nameArr[nameArr.length - 1][0].toUpperCase()
        )
      } else if (nameArr.length === 1 && nameArr[0]) {
        return nameArr[0][0].toUpperCase()
      } else {
        return ''
      }
    }
  }
  const {
    ParentTicketID,
    TATEscalation,
    IsJag,
    IsReopen,
    IsSocial,
    HighComplaint,
    EscLevel1,
    EscLevel2,
    Escalation,
    PartnerLevel,
    Source,
    SubSource,
    ProductId,
    IssueName,
  } = OpenedTicketDetails

  return (
    <div className="insurer-ticket-sidebar scroll-btn">
      {console.log('state', state)}
      <div className="left-bar">
        {isLeadDetailsLoading ? (
          <div className="text-center">
            <Skeleton
              animation="wave"
              variant="rect"
              width={'100%'}
              height={'550px'}
              style={{background:"#cccccc2e",}}
            />
          </div>
        ) : (
          <>
            <div className="agent-name">
              <span className="short-name">
                {state.leadDetails && state.leadDetails.CustomerName
                  ? getCutomerProfilePicWithLetters(
                      state.leadDetails.CustomerName,
                    )
                  : ''}
              </span>
              {state.leadDetails && state.leadDetails.CustomerName ? (
                <h5>{state.leadDetails.CustomerName}</h5>
              ) : (
                ''
              )}
              <h2>
                {' '}
                Booking ID :
                <span>
                  <strong>
                    {(state.leadDetails && state.leadDetails.LeadID) || 0}
                  </strong>
                </span>
              </h2>
            </div>
            <div className="all-ticket-header">
              <div className="user-info-detail">
                {state.leadDetails && state.leadDetails.SupplierLogo ? (
                  <span className="logo-box">
                    <img
                      src={state.leadDetails.SupplierLogo}
                      alt="supplierLogo"
                    />
                  </span>
                ) : null}
                <div className="title-box">
                  {/* <h5>{leadDetails?.SupplierName}</h5> */}
                  <h5>
                    {state.leadDetails && state.leadDetails.SupplierName
                      ? state.leadDetails.SupplierName
                      : null}
                  </h5>
                </div>
                <div className="booking-deatil-box">
                    <ul class="booking-list-view">
                    {state.leadDetails && state.leadDetails.ProductName && (
                      <li>
                        <span>Product</span>
                        <strong>{state.leadDetails.ProductName}</strong>
                      </li>
                    )}
                    {state.leadDetails && state.leadDetails.PolicyNo && (
                      <li>
                        <span>Policy No.</span>
                        <strong>{state.leadDetails.PolicyNo}</strong>
                      </li>
                    )}
                    {state.leadDetails && state.leadDetails.ApplicationNo && (
                      <li>
                        <span>Application No.</span>
                        <strong>{state.leadDetails.ApplicationNo}</strong>
                      </li>
                    )}
                    {state.leadDetails && state.leadDetails.RegistrationNo && (
                      <li>
                        <span>Registration No.</span>
                        <strong>{state.leadDetails.RegistrationNo}</strong>
                      </li>
                    )}
                  </ul>
                </div>
                <h2 className="card-heading">Ticket Detail</h2>
              </div>
            </div>
         
            {OpenedTicketDetails &&  
              <div className="card active">
                <div className="tag-btn">
                    {ParentTicketID && ParentTicketID != 0 ? (
                      <span>Child Ticket</span>
                    ) : (
                      ''
                    )}
                    {IsJag && IsJag == 1 ? <span>JAG</span> : ''}
                    {IsReopen ? <span>Reopen</span> : ''}
                    {HighComplaint > 0 ? <span>TopMgmt</span> : ''}
                    {EscLevel1 == 'Yes' ? <span>HCS</span> : ''}
                    {EscLevel2 == 'Yes' ? <span>CGO</span> : ''}
                    {Source == 'Social' || IsSocial ? (
                      <span>{SubSource === 'AskPB' ? 'Ask PB' : 'Social'}</span>
                    ) : (
                      ''
                    )}
                    {Escalation == 'Yes' ? <span>IRDA</span> : ''}
                    {PartnerLevel == 1 &&
                    [186, 187, 188, 189, 190, 194].includes(ProductId) ? (
                      <span className="krg-icon">KRG</span>
                    ) : (
                      ''
                    )}
                  </div>
                  <h4>
                    {IssueName}{' '}
                    <span>
                      {OpenedTicketDetails.IsAutoClosure &&
                      !OpenedTicketDetails.IsReopen &&
                      OpenedTicketDetails.EscalationType == 0 ? (
                        <Tooltip
                          title="Auto Closure"
                          aria-label="autoClosure"
                          placement="top-start"
                        >
                          <Button className="auto-closue-btn">A</Button>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </span>
                  </h4>
                  <img
                    className="tiked-icon"
                    src="/images/Ticked.svg"
                    alt="Ticked"
                  />
                  <div class="ticket-card-list">
                    <ul>
                      <li>
                        <span>Sub Issue</span>
                        <strong id="cardSubIssue">{OpenedTicketDetails.SubIssueName || ''}</strong>
                      </li>
                      {OpenedTicketDetails.TicketId && (
                        <li>
                          <span>Ticket ID</span>
                          <strong className="ticket-id-bold"  id="cardTicketDetailsID">
                            {OpenedTicketDetails.TicketDetailsID}
                            {ParentTicketID && ParentTicketID != 0 ? '(C)' : ''}
                          </strong>
                          {ParentTicketID && ParentTicketID != 0 ? (
                            <strong className="ticket-id-bold">
                              {OpenedTicketDetails.ParentTicketDetailsID}(P)
                            </strong>
                          ) : (
                            ''
                          )}
                        </li>
                      )}
                      <li>
                        <span>Source</span>
                        <strong id="cardSubSource">
                          {Source}{' '}
                          {OpenedTicketDetails.SubSource ? (
                            <span>({OpenedTicketDetails.SubSource})</span>
                          ) : (
                            ''
                          )}
                        </strong>
                      </li>
                      {OpenedTicketDetails.AssignedToName &&
                        (!OpenedTicketDetails.ParentTicketID || OpenedTicketDetails.ParentTicketID == 0) && (
                          <li>
                            <span>Assigned To </span>
                            <strong  id="cardAssignedToName">
                              {getFormatedName(OpenedTicketDetails.AssignedToName)}
                            </strong>
                          </li>
                        )}
                      {OpenedTicketDetails.CreatedOn && (
                        <li>
                          <span>Created on</span>
                          <strong id="cardCreatedOn">{convertToIsoFormate(OpenedTicketDetails.CreatedOn)}</strong>
                        </li>
                      )}
                      {OpenedTicketDetails.ResolvedDate !== 0 && (
                        <li>
                          <span>Resolved on</span>
                          <strong id="cardResolvedDate">
                            {convertToIsoFormate(OpenedTicketDetails.ResolvedDate)}
                          </strong>
                        </li>
                      )}
                      {OpenedTicketDetails.PartnerId &&
                      [186, 187, 188].includes(OpenedTicketDetails.ProductId) ? (
                        <li>
                          <span>Partner Id</span>
                          <strong id="cardPartnerId">{OpenedTicketDetails.PartnerId}</strong>
                        </li>
                      ) : null}
                      {[186, 187, 188].includes(OpenedTicketDetails.ProductId) ? (
                        <li>
                          <span>Partner level</span>
                          <strong className="ticket-id-bold" id="cardPartnerLevel">
                            {OpenedTicketDetails.PartnerLevel || 0}
                          </strong>
                        </li>
                      ) : null}
                    </ul>
                    {/* Footer */}
                    <div
                      className={
                        'ticket-card-footer' +
                        (OpenedTicketDetails.StatusID === 4 || OpenedTicketDetails.StatusID === 3
                          ? ' resolved'
                          : '')
                      }
                    >
                      {OpenedTicketDetails.StatusID && (
                        <>
                          {OpenedTicketDetails.StatusID === 2 && (
                            <img src="/images/hourglass.svg" alt="hourglass" />
                          )}
                          {(OpenedTicketDetails.StatusID === 4 || OpenedTicketDetails.StatusID === 3) && (
                            <img
                              src="/images/checkmark_circle.svg"
                              alt="checkmark"
                            />
                          )}
                        </>
                      )}
                      <div className="footer-data">
                        <h6 id="cardFooterStatus">{OpenedTicketDetails.Status || ''}</h6>
                        <strong id="cardFooterSubStatusName">{OpenedTicketDetails.SubStatusName || ''}</strong>
                        <span id="cardFooterLastUpdatedOn">
                          {OpenedTicketDetails.LastUpdatedOn
                            ? `Updated on : ${convertToIsoFormate(
                              OpenedTicketDetails.LastUpdatedOn,
                              )}`
                            : ''}
                        </span>
                      </div>
                    </div>
                    {/* ---------- */}
                  </div>
                </div>
            }
    </>
        )}
      </div>
      <div className="version-name">Version : {VERSION}</div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    OpenedTicketDetails:
      (state && state.ticketdetails && state.ticketdetails.ticketDetails) || {},
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetOpenedTicketDetails: (payload) =>
      dispatch(actions.setTicketDetails(payload)),
    OnSetTicketComm: (payload) =>
      dispatch(actions.fetchLatestTicketComm(payload)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsurerTicketSidebar)
