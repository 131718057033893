import React from 'react';
import {List,FormControlLabel,Checkbox,Button, ListItem} from '@material-ui/core';
import ViewComments from './ViewComments';
    

export default function ComplexGrid({
    toggleFilterLogsBtn,
    handleFilterLogs}) {

  return (
    <div className="activity-box">
    <div className="activity-filter logs-filter">
        <List className="scrool-toll">
            <ListItem>
                <Button id="toggleFilterLogsBtnAll" className={toggleFilterLogsBtn.all ? 'active': null} 
                 onClick={()=>handleFilterLogs("all")}>All</Button>
            </ListItem>
            <ListItem>
                <Button  
                className={toggleFilterLogsBtn.assignment ? 'active': null} 
                 onClick={()=>handleFilterLogs("assignment")}
                 id="toggleFilterLogsBtnAssignement" 
                 >
                 Assignment 
                 </Button>
            </ListItem>

            <ListItem>
                <Button
                 className={toggleFilterLogsBtn.statusSubStatus ? 'active': null} 
                 onClick={()=>handleFilterLogs("statusSubStatus")}
                 id="toggleFilterLogsBtnStatus" 
                >
                Status/SubStatus 
                </Button>
            </ListItem>
            
            <ListItem>
                <Button
                 className={toggleFilterLogsBtn.issueSubIssue ? 'active': null} 
                 onClick={()=>handleFilterLogs("issueSubIssue")}
                 id="toggleFilterLogsBtnIssue" 
                >
                Issue/SubIssue 
                </Button>
            </ListItem>
            <ListItem>
                <Button
                className={toggleFilterLogsBtn.followUp ? 'active': null} 
                onClick={()=>handleFilterLogs("followUp")}
                id="toggleFilterLogsBtnFollowUp" 
                >
                Follow Up 
                </Button>
            </ListItem>
            <ListItem>
                <Button
                className={toggleFilterLogsBtn.leadId ? 'active': null} 
                onClick={()=>handleFilterLogs("leadId")}
                id="toggleFilterLogsBtnLeadId" 
                >
                Lead ID
                </Button>
            </ListItem>

            <ListItem>
                <Button
                className={toggleFilterLogsBtn.insurerStatus ? 'active': null} 
                onClick={()=>handleFilterLogs("insurerStatus")}
                id="toggleFilterLogsBtnInsurerStatus" 
                >
                Insurer Status 
                </Button>
            </ListItem>
        </List>
    </div>
    </div>
  );
}
