import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Button,
  TextareaAutosize,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitle from "../../../../../shared/components/DialogTitle";
import { PropagateLoader } from 'react-spinners';
import CKEditor from 'ckeditor4-react';
import { COMMON_CK_EDITOR_CONFIG } from "../../../../../Config";

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default function CommunicationDialogForTriggers({
  isDialogOpen,
  setDialogOpen,
  triggerInput,
  setValuesInParent,
  setCanResponse,
  isTemplateLoaded,
  replyAppendMsg,
  previousValues,
  setTypeInParent,
  typeInParent
}) {
  const [touched, setTouched] = useState([]);
  const UserComment = "UserComment";
  const [multipleInputAllowed, setMultipleInputAllowed] = useState(false);
  const [ obj, setObj] = useState();
  const [ values, setValues ] = useState([]);
  const [multipleSets, setMultilpleSets] = useState({});
  const [type, setType] = useState("Days");
  const [loanProvider, setLoanProvider] = useState("");
  const [ editable, setEditable] = useState(false);
  const idPrefix = 'templateTrigger';

  const TextAreaCKEditorConfig = COMMON_CK_EDITOR_CONFIG

  //First render fetches all the fields
  useEffect(()=>{
    if(previousValues && previousValues.length>0){
      setValuesIfPreviousValuesAvailable();
    }
    if(typeInParent){
      setType(typeInParent);
    }
    else setEmptyValues();
    findIfMultipleEntries();
    setObjValue();
    
  },[])

  const setEmptyValues = () => {
    let obj = {}
    triggerInput && triggerInput.length > 0 && triggerInput.forEach(i => {
      obj[i.InputName] = ""
    });
    setValues([{ ...obj }]);
    setTouched([{ ...obj }]);
  }

  const setObjValue = () =>{
    let obj = {}
    triggerInput && triggerInput.length > 0 && triggerInput.forEach(i => {
      obj[i.InputName] = ""
    });
    let copyObj = Object.assign({}, obj)
    setObj(copyObj)
  }

  const findIfMultipleEntries = () => {
    let multipleObj = {};
    if (Array.isArray(triggerInput) && triggerInput.length > 0) {
      let flag = false;

      triggerInput.forEach(i => {
        if (i.IsMultiple) { flag = true }
        if(i.IsMultiple){
          multipleObj[i.InputName] = true;
        }
        else multipleObj[i.InputName] = false;
      })
      setMultilpleSets(multipleObj)
      return setMultipleInputAllowed(flag);
    }
  }

  const setValuesIfPreviousValuesAvailable =() =>{
      if(previousValues && previousValues.length>0){
      setValues([...values,...previousValues]); 
      let tempTouched = [];
      previousValues.map(obj=> {
        let tempObj = {};
        Object.keys(obj).forEach(key=>{
          tempObj[key]= true;
        })
        tempTouched.push(tempObj)
      }) 
      setTouched(tempTouched)
    }
  }

  const handleChange = (e, i) => {
    if (e && e.persist) {
      e.persist();
    }
    const { name, value } = e.target;
    let tempValues = [...values];
    tempValues[i][name] = value;
    setValues(tempValues);
    let tempTouched = [...touched];
    tempTouched[i][name]= true;
    setTouched(tempTouched)
  };

  const handleCKEditorEdit = ( value, name, i) =>{
    let tempValues = [...values];
    tempValues[i][name] = value;
    setValues(tempValues);
    let tempTouched = [...touched];
    tempTouched[i][name]= true;
    setTouched(tempTouched)
  }


  const findError = (fieldName, index2, value) =>{
    if(fieldName){
      let DataType, field, regex, length, ValidationMessage;
      triggerInput && triggerInput.length>0 && triggerInput.forEach(item=>{
        if(item.InputName === fieldName){
          DataType = item.DataType;
          field = item.InputName;
          regex = item.Regex;
          length = item.MaxLength;
          ValidationMessage = item.ValidationMessage;
        }
        return
      })
      return hasError(DataType, regex, length, value, fieldName, ValidationMessage)
    }
  }

  const hasError = (DataType, regex, length, value, fieldName, ValidationMessage) => {
    let error = "";
    if (!value) {
      error = "Field is required";
    }
    else {
      error = configErrorMessage(DataType, regex, value, length, fieldName, ValidationMessage);
    }
    return error;
  };

  const configErrorMessage = (DataType, regex, value, length, fieldName, ValidationMessage) => {
    // console.log('datatype, regex, value, length, fieldName', DataType, regex, JSON.stringify(value) , length, fieldName)
    let error = "";
    let re = new RegExp(regex);
    // console.log('error printing--->,', re.test(value))
    if(fieldName && fieldName.toLowerCase() !== "followuptat"){
    switch (DataType) {
      case "Int":
        error = re.test(value) ?  "" : ValidationMessage;
        break;
      case "Float":
        error = re.test(value)
          ? ""
          : ValidationMessage;
        break;
      case "String":
        if(fieldName==="AgentInput"){
          error =  value.length> length ? ValidationMessage : ``;
        }
        else{
          error = re.test(value) ? "" : ValidationMessage ;
        }
        break;
      default:
        break;
    }
  }
  else {
    switch(DataType){
      case "String":
        if(type === "Days"){
          // error = re.test(Number(value)) ? "" : ValidationMessage
          if(Number(value)<=17){
            error = ""
          }
          else error = "Numeric values upto 17 are allowed"
        }
        if(type === "Hours"){
          // error = re.test(Number(value)) ? "" : ValidationMessage
          if(Number(value)<=24){
            error = ""
          }
          else error = "Numeric values upto 24 are allowed"
        }
        break;
      case "Int":
        if(type === "Days"){
          // error = re.test(Number(value)) ? "" : ValidationMessage
          if(Number(value)<=17){
            error = ""
          }
          else error = "Numeric values upto 17 are allowed"
        }
        if(type === "Hours"){
          // error = re.test(Number(value)) ? "" : ValidationMessage
          if(Number(value)<=24){
            error = ""
          }
          else error = "Numberic values upto 24 are allowed"
        }
        break;
      default : break
    }
  }
    return error;
  };

  const touchAllEntriesForValidation = () =>{
    let tempTouched = [...touched];
    console.log("touched values: ",tempTouched);
    console.log("total values: ",values);
    values.forEach((obj,i)=>{
      Object.keys(obj).forEach((item,j)=>{
        tempTouched[i][item] = true
      })
    })
    setTouched(tempTouched)
  }

  const validate = () => {
    touchAllEntriesForValidation();
    let errors = [];
    values.forEach((obj,i)=>{
      Object.keys(obj).forEach((item)=>{
        if(findError(item, i, values[i][item])){
          errors.push(findError(item, i, values[i][item]))
        }
      })
    })
     console.log('errors-->', errors)
    if(errors.length>0){
      return false
    }
    else return true;
  };
 

  const getHTMLapi = (e) => {
    e.preventDefault();
    if (validate()) {
      
      setValuesInParent(values); // this sets values of triggers in Parent State 'Values' which calls api as as side-effect
      setTypeInParent(type); // stores the value of checkbox
    }
  };

  const closeDialog = (event, reason) => {
    if (event && event.persist) {
      event.persist();
      event.preventDefault();
      if (reason !== "backdropClick") {
        setDialogOpen(false);
      }
    }
  };

  const cancelButtonClicked = () => {
    setDialogOpen(false);
    setCanResponse("select");
  };

 

  const addFields = () =>{
    //This functions adds functions fields based on input flag
    let tempObj = {...obj};
    Object.keys(multipleSets).map(i=>{
      if(!multipleSets[i]){
        delete tempObj[i]
      }
    })
    setValues([...values, {...tempObj}]);
    setTouched([...touched, {...tempObj}]);
  }

  const removeFields = (index) =>{
    let tempValues = [...values];
    tempValues.splice(index, 1);
    let tempTouched = [...touched];
    tempTouched.splice(index,1);
    setValues(tempValues);
    setTouched(tempTouched);
  }

  //fetches inputtype and input options for array element
  const fetchInputType = (name) =>{
    let InputType, InputOptions;
      triggerInput && triggerInput.length>0 && triggerInput.forEach(item=>{
        if(item.InputName === name){
          InputType = item.InputType;
          InputOptions = item.InputOptions
        }
        return
      })
      return {'InputType' : InputType, 'InputOptions' : InputOptions}
  }


  return (
    <div>
      <Dialog
        maxWidth="md"
        className="communication-dailog communication-dailog-new"
        onClose={(event, reason) => closeDialog(event, reason)}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
        disableEnforceFocus
      >
        <DialogTitle
          className="title-heading"
          id="customized-dialog-title"
          // onClose={() => setDialogOpen(false)}
          closeIcon={<div></div>}
          disableEscapeKeyDown
        >
          Communication Form
        </DialogTitle>
        <DialogContent>
          <form onSubmit={()=> getHTMLapi()}>
            {values && values.length>0 && values.map((obj, index1)=>{
              console.log('values-->', values)
               return (
               <div>
                {obj &&
                Object.keys(obj).length > 0 &&
                Object.keys(obj).map((key, index2) => {
                  let tatTypeRendered = false;
                  let InputType = fetchInputType(key) && fetchInputType(key).InputType;
                  let InputOptions = fetchInputType(key) && fetchInputType(key).InputOptions || [];
                  // if (InputSource == "Manual") {
                    if(true){
                    return (
                      <ul className="list">
                        <li key={key+index2}>
                        <FormControl className="form-control">
                          { InputType === 'textfield' && (
                            <TextField
                              id="outlined-full-width"
                              label={key}
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              name={key}
                              value={values[index1][key]}
                              onChange={(e)=>handleChange(e, index1)}
                              disabled={isTemplateLoaded}
                              error={
                                touched && touched.length > 0 && touched[index1] && touched[index1][key] &&
                                findError(key, index1, values[index1][key])
                                //  &&
                                // hasError(i.DataType, i.InputName, i.Regex)
                              }
                              helperText={
                                touched && touched.length > 0 && touched[index1] && touched[index1][key] &&
                                findError(key, index1, values[index1][key])
                                // &&
                                // hasError(i.DataType, i.InputName, i.Regex, i.MaxLength)
                              }
                            />
                          )}
                          { InputType === 'textarea'? (
                            <>
                              <CKEditor
                                data={values && values[index1] && values[index1][key]}
                                config={TextAreaCKEditorConfig}
                                onChange={(event) => {
                                  handleCKEditorEdit(event.editor.getData(), key, index1)

                                }}
                                onInstanceReady={(instance) => {
                                  instance.data = values[index1][key]
                                }}
                                name="agentInputEditor"
                                id="agentInputEditor"
                                readOnly={false}
                              />
                              <span style={{color:"red", fontSize: '12px'}}> {touched && touched.length > 0 && touched[index1] && touched[index1][key] &&
                                findError(key, index1, values[index1][key])}</span>
                            </>

                          ) : null
                          }
                          { InputType === 'dropdown'  ? (
                            <ul className="list">
                              <li key={key}>
                              <FormControl className="form-control form-control-new">
                              <InputLabel id="demo-customized-select-label">{key}</InputLabel>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                name={key}
                                value={values[index1][key]}
                                onChange={(e)=>handleChange(e, index1)}
                              >
                                <MenuItem value={""} disabled={true}>
                                  Select Loan Provider
                                </MenuItem>
                                {
                                  InputOptions && InputOptions.map((option, key)=>{
                                    return <MenuItem key={'dropdownOption'+ key} disabled={isTemplateLoaded} value={option.Value}>{option.Key}</MenuItem>
                                  })
                                }
                              </Select>
                            </FormControl>
                            <span style={{color:"red", fontSize: '12px'}}> {touched && touched.length > 0 && touched[index1] && touched[index1][key] &&
                                findError(key, index1, values[index1][key])}</span>
                            </li> 
                            </ul>
                          ) : null}
                          {!tatTypeRendered && InputType === 'tatduration' &&
                            <>
                              {tatTypeRendered = true}
                              <TextField
                              id="outlined-full-width"
                              label={key}
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              name={key}
                              value={values[index1][key]}
                              onChange={(e)=>handleChange(e, index1)}
                              disabled={isTemplateLoaded}
                              error={
                                touched && touched.length > 0 && touched[index1] && touched[index1][key] &&
                                findError(key, index1, values[index1][key])
                              }
                              helperText={
                                touched && touched.length > 0 && touched[index1] && touched[index1][key] &&
                                findError(key, index1, values[index1][key])
                              }
                            />
                              <FormControl component="fieldset">
                                <FormLabel component="legend">Duration</FormLabel>
                                <RadioGroup  aria-label="duration" name="duration" aria-level="positon" row value={type} onChange={(e) => setType(e.target.value)}>
                                  <FormControlLabel disabled={isTemplateLoaded} value="Days" control={<Radio color="primary" />} label="Days" />
                                  <FormControlLabel disabled={isTemplateLoaded} value="Hours" control={<Radio color="primary" />} label="Hours" />
                                </RadioGroup>
                              </FormControl>
                            </>}
                        </FormControl>
                      </li>
                      </ul>
                    );
                  }
                })}
                {values.length> 1 && <Button id={idPrefix+'Delete'} className="delete-btn" onClick={()=>removeFields(index1)}>Delete Field</Button>}
                {values.length> 1 && <hr/>}
                </div>
               )
            })} 
             
            {isTemplateLoaded ? (
              // <Spinner />
              <div style={{textAlign : "center", height : "20px"}}>
                <PropagateLoader color="rgb(70 96 192 / 91%)"/>
                </div>
            ) : (
              <div className="text-right">
                { multipleInputAllowed && values && values.length<4 && <Button id={idPrefix+'Add'} className ="add-btn" onClick={()=> addFields()}>
                  + Add
                </Button>
                }
                <Button id={idPrefix+'Cancel'} className="cancle-btn" onClick={cancelButtonClicked}>
                  Cancel
                </Button>
                <Button id={idPrefix+'Save'} className="save-btn" type="submit" onClick={getHTMLapi}>
                  Save
                </Button>
              </div>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
