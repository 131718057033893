import React, { useState, useContext, useEffect, useReducer } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@material-ui/core";
import { Button, Dialog, Typography, Slide, Grid, FormControl, InputLabel, Select, TextField, FormHelperText, List, ListItem, MenuItem } from '@material-ui/core';
import DialogTitle from "../../../../shared/components/DialogTitle";
import { useSnackbar } from 'notistack'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EmployeeList from './EmpolyeeList';
import { POST, GET } from '../../../../services/apiServices';
import { GetSMEEmployees, GetSMEDetails } from '../../../../constants/apiUrls';
import TicketContext from '../../../../TicketContext';
import Spinner from '../../../../shared/components/Spinner';
import ManualAddEmployee from './ManualAddEmployee';
import { GetTicketDetailsByTicketID, GetTicketSummary, UpdateSMEDetails } from '../../../../constants/apiUrls';
import { useDispatch } from 'react-redux';
import { setActiveTicketID } from '../../../../redux/actions/ticketDetails';

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;

});
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: "#ffffff !important",
    color: "#414141",
  },
  title: {
    flex: 1,
  },
  formControl: {
    width: "100%",
  },
  h6: {
    color: "#414141",
  },
}));
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const SmeDetailForm = ({ isDialogOpen, setDialogOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [selectedEmployee, setSelectedEmployee] = useState(0);
  const [employeeID, setEmployeeID] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [smeDetails, setSmeDetails] = useState({});
  const [isEmployeesLoading, setEmployeesLoading] = useState(false);
  const [role, setRole] = useState("Select")
  const [fieldsVisible, setFieldsVisible] = useState(false)
  const [addEmployeeFieldsVisible, setAddEmployeeFieldsVisible] = useState(false)
  const [isSMEApiHit, setIsSMEApiHit] = useState(false)
  const [isEmployeeRawDataAdded, setIsEmployeeRawDataAdded] = useState(false)
  const [isUpdating, setUpdating] = useState(false);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {

      eEmailID: "",
      eEmailIDError: "",
      eMobileNo: "",
      eMobileNoError: "",
      eCode: "",
      eCodeError: "",
      eName: "",
      eNameError: ""
    }
  )

  const ticketContext = useContext(TicketContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showErrMsg = (msg) => {
    if (msg) {
      enqueueSnackbar(msg, {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    }
    else {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    }
  }

  const getEmployes = (empId) => {
    if (!empId) {
      showErrMsg("Enter employee ID to continue")
      return
    }
    else {
      setEmployeesLoading(true);
      GET(`${GetSMEEmployees}?empid=${empId}`)
        .then(res => {
          if (res.ErrorCode === 0) {
            if (res.Data && res.Data.Data && Array.isArray(res.Data.Data.Employees) && res.Data.Data.Employees.length > 0) {
              setEmployeeList(res.Data.Data.Employees);

            }
            else {
              setEmployeeList([])
              showErrMsg('No data found, please add manually')
            }

          }
          else {
            showErrMsg();
          }
          setEmployeesLoading(false)
        })
        .catch(err => {
          console.log(err);
          showErrMsg();
          setEmployeesLoading(false)
        })
      setIsSMEApiHit(true)
    }
  }

  const handleSearch = (empId) => {
    setAddEmployeeFieldsVisible(false)
    getEmployes(empId);
    setIsEmployeeRawDataAdded(false)
  }

  const GetSMEDetailsApi = () => {
    GET(`${GetSMEDetails}?ticketid=${ticketContext.TicketId}`)
      .then(res => {
        if (res.ErrorCode === 0) {
          if (res.Data) {
            setSmeDetails(res.Data);
            if (res.Data.TicketRaisedBy) {
              setRole(res.Data.TicketRaisedBy)
            }
          }
        }
        else {
          showErrMsg();
        }
      })
      .catch(err => {
        console.log(err);
        showErrMsg();
      })
  }

  useEffect(() => {
    GetSMEDetailsApi();
  }, [])

  const handleRoleChange = (role) => {
    setRole(role)
    setAddEmployeeFieldsVisible(false)
    setState({
      eCodeError: "",
      eMobileNoError: "",
      eEmailIDError: "",
      eEmailIDError: ""
    })
    if (role == 'Select') {
      setFieldsVisible(false)
    }
    else {
      setFieldsVisible(true)
    }
  }

  //validate email using regex

  const emailValidate = (mail) => {
    if (/^[a-zA-Z0-9_]+@[a-zA-Z0-9_]+\.[A-Za-z]+(\.[A-Za-z]+)?$/.test(mail)) {
      return true
    }
    return false;
  };


  const handleAddEmployee = (e) => {
    const { name, value } = e.target
    if (name == "eCode") {
      if (value) {
        setState({eCodeError: "" })
      }
      else {
        setState({eCodeError: "Please enter corrent ECode" })
      }
    }
    if (name == "eMobileNo") {
      if (!isNaN(value) && value.length <= 10) {
        setState({[name]: value })
      }
    }
    if (name == "eEmailID") {
      if (value) {
        if (emailValidate(value)) {
          setState({eEmailIDError: "",})
        }
        else {
          setState({eEmailIDError: "Enter email in proper format"})
        }
      }
      else {
        setState({eEmailIDError: ""})
      }
    }
    setState({[name]: value })
  }


  const addEmployeeDetails = () => {
    let { eCode, eCodeError, eEmailIDError, eNameError, eMobileNoError, eEmailID, eName, eMobileNo } = state
    if (role!="Select") {
      if (!eCode) {
        setState({...state,
          eCodeError: "Please enter ECode",
        })
      }
    }
    else {
      if (eCode) {
        setState({...state,
          eCodeError: "",
        })
      }
    }
    if (!eCodeError && !eEmailIDError && !eNameError && !eMobileNoError && eCode) {
      setAddEmployeeFieldsVisible(false)
      setIsEmployeeRawDataAdded(true)

    }
  }

  // GetTicketDetailsByTicketIDAPI
  const GetTicketDetailsByTicketIdApi = () => {
    GET(`${GetTicketDetailsByTicketID}/${ticketContext.RefObjectId}/${ticketContext.TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest, IsInsurerBucket } = response.Data;

          let callbackVal;
          if (IsCallBackRequest === "0") {
            callbackVal = "No";
          } else if (IsCallBackRequest === "1") {
            callbackVal = "Yes";
          } else if (IsCallBackRequest === "2") {
            callbackVal = "Completed";
          }

          ticketContext.setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
          ticketContext.setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log(err);
      });
  };

  // GetTicketSummary API

  const getAllTicketSummary = () => {
    ticketContext.setAllTicketsLoading(true);
    GET(`${GetTicketSummary}/${ticketContext.RefObjectId}/${ticketContext.TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID != 3 && i.StatusID != 4).sort((a, b) => (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)) : [];
          const sortedData2 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID == 3).sort((a, b) => (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)) : [];
          const sortedData3 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID == 4).sort((a, b) => (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)) : [];
          ticketContext.setAllTickets({ ...response.Data, "AllTicketList": [...sortedData1, ...sortedData2, ...sortedData3] });
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        ticketContext.setAllTicketsLoading(false);
        dispatch(setActiveTicketID(response.Data.TicketId))
        ticketContext.setActiveTicketId(response.Data.TicketId);
        ticketContext.GetTicketLogsApi(response.Data.TicketId)
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };



  // save SME ticket for HR--------
  const UpdateSMEDetailsApi = () => {
    if (isUpdating) {
      return
    }

    const payload = {
      "TicketID": ticketContext.TicketId,
      "EmployeeID": state.eCode,
      "EmployeeName": state.eName,
      "EmailID": state.eEmailID,
      "TicketRaisedBy": role,
      "MobileNo": state.eMobileNo
    };
    POST(UpdateSMEDetails, payload)
      .then(res => {
        if (res.ErrorCode === 0) {
          enqueueSnackbar("Success", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
          setEmployeeID("")
          setIsEmployeeRawDataAdded(false)
          GetTicketDetailsByTicketIdApi();
          getAllTicketSummary();
          if (res.Data) {
            setSmeDetails(res.Data);
          }
        }
        else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setUpdating(false);
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        setUpdating(false);
      })
  }

  // ------------------------------

  return (
    <div>
      <Dialog
        className="sme-detail"
        fullScreen open={isDialogOpen} onClose={() => {setDialogOpen(false)
        }} TransitionComponent={Transition} aria-labelledby="responsive-dialog-title">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => {setDialogOpen(false)
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
            <DialogTitle className="title-heading" id="customized-dialog-title">
              Employee Detail
            </DialogTitle>
          </Toolbar>
        </AppBar>
        <DialogContent className="sme-detail-data">
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <FormControl className="form-control">
                <InputLabel id="demo-simple-select-outlined-label">Select Role</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={role}
                  onChange={(e) => { handleRoleChange(e.target.value); setEmployeeList([]); setIsSMEApiHit(false); setIsEmployeeRawDataAdded(false) }}
                  label="Select Role"
                >
                  <MenuItem aria-label="Select" value="Select">Select</MenuItem>
                  <MenuItem value={"HR"}>HR</MenuItem>
                  <MenuItem value={"Employee"}>Employee</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {fieldsVisible && <Grid item md={4} xs={6}>
              <FormControl className="form-control-box">
                <TextField
                  label="Employee Code"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={employeeID}
                  onChange={(e) => setEmployeeID(e.target.value)}
                />
              </FormControl>
            </Grid>
            }
            {fieldsVisible && <Grid item md={5} xs={6}>
              <Button className="search-btn" onClick={() => handleSearch(employeeID)}>Search</Button>
              {/* {role && role == "HR" ? <Button className="search-btn" onClick={() => {
                setEmployeeList([])
                setAddEmployeeFieldsVisible(true)
              }}>Add Manually</Button> : null} */}
               
              {role && role == ["HR"] ? <Button disabled={isUpdating} className="search-btn" onClick={UpdateSMEDetailsApi} > {!isUpdating ? "Save without detail" :
                <span className="loader-spinner">
                  <Spinner />
                </span>}
              </Button> : null}
            </Grid>
            }
            {/* ------Add Employee------------- */}

            {addEmployeeFieldsVisible && <Grid container spacing={2} className="sme-form-data">
              <Grid item md={4} xs={6}>
                <FormControl className="form-control-box">
                  <TextField
                    label="Employee Name"
                    id="outlined-margin-normal"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    name={"eName"}
                    value={state.eName}
                    onChange={(e) => handleAddEmployee(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                {state.eNameError ? <FormHelperText>{state.eNameError}</FormHelperText> : null}
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl className="form-control-box">
                  <TextField
                    label="Mobile Number"
                    id="outlined-margin-normal"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    name="eMobileNo"
                    value={state.eMobileNo}
                    onChange={(e) => handleAddEmployee(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                {state.eMobileNoError ? <FormHelperText className="error-msg">{state.eMobileNoError}</FormHelperText> : null}
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl className="form-control-box">
                  <TextField
                    label="Employee Email"
                    id="outlined-margin-normal"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    name="eEmailID"
                    value={state.eEmailID}
                    onChange={(e) => handleAddEmployee(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                {state.eEmailIDError ? <FormHelperText className="errror-msg">{state.eEmailIDError}</FormHelperText> : null}
              </Grid>

              <Grid item md={4} xs={6}>
                <FormControl className="form-control-box">
                  <TextField
                    label="Employee Code"
                    id="outlined-margin-normal"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    name={"eCode"}
                    value={state.eCode}
                    onChange={(e) => handleAddEmployee(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                {state.eCodeError ? <FormHelperText className="errror-msg">{state.eCodeError}</FormHelperText> : null}
              </Grid>
              <Grid item md={4} xs={6}>
                <Button className="search-btn" onClick={addEmployeeDetails}>Add</Button>
              </Grid>
            </Grid>}
            {!addEmployeeFieldsVisible && fieldsVisible && isEmployeeRawDataAdded && <ManualAddEmployee eCode={state.eCode} eMobileNo={state.eMobileNo} eEmailID={state.eEmailID} eName={state.eName} setAddEmployeeFieldsVisible={setAddEmployeeFieldsVisible} role={role} setSmeDetails={setSmeDetails} setState={setState} setIsSMEApiHit={setIsSMEApiHit} setIsEmployeeRawDataAdded={setIsEmployeeRawDataAdded} setEmployeeID={setEmployeeID} />}
            {/* -------------------------------- */}
            {!isEmployeesLoading && employeeList && Array.isArray(employeeList) && employeeList.length > 0
              ?
              <>
              {isSMEApiHit && <div style={{textAlign:"left",margin:"9px 0 0",width:"100%",}}><Button className="search-btn" onClick={() => { setAddEmployeeFieldsVisible(true); setIsSMEApiHit(false) }}>Add Manually</Button></div>}
              <EmployeeList employeeList={employeeList} role={role} smeDetails={smeDetails} setSmeDetails={setSmeDetails} selectedEmployee={selectedEmployee} setSelectedEmployee={setSelectedEmployee} />
              </>
              
              :
              (
                isEmployeesLoading
                  ?
                  <div className="spinner-loader">
                    <Spinner />
                  </div>
                  :
                  ""
              )
            }
            {isSMEApiHit && employeeList.length == 0 && ["Employee","HR"].includes(role) > -1  && !isEmployeesLoading &&
              <div className="spinner-loader">
                <Button className="search-btn" onClick={() => { setAddEmployeeFieldsVisible(true); setIsSMEApiHit(false) }}>Add Manually</Button>
              </div>
            }
          </Grid>
          <Grid container spacing={2} className="sme-form-data">
            <Grid item md={4} xs={6}>
              <FormControl className="form-control-box">
                <TextField
                  label="Customer Name"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={smeDetails.CustomerName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl className="form-control-box">
                <TextField
                  label="Email ID"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={smeDetails.EmailId}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl className="form-control-box">
                <TextField
                  label="Mobile No"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  margin="normal"
                  type='number'
                  variant="outlined"
                  value={smeDetails.MobileNo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>

            <Grid item md={4} xs={6}>
              <FormControl className="form-control-box">
                <TextField
                  label="Company Name"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={smeDetails.CompanyName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl className="form-control-box">
                <TextField
                  label="Employee Code"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={smeDetails.EmployeeID}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl className="form-control-box">
                <TextField
                  label="Employee Name"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={smeDetails.EmployeeName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>

          </Grid>

        </DialogContent>

      </Dialog>
    </div>
  );
}

export default SmeDetailForm;