import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextareaAutosize,
} from "@material-ui/core";
import {
  convertToIsoFormate,
  getFileExtension,
} from "../../shared/methods/methods";
import CloseIcon from "@material-ui/icons/Close";
import { DisplayName, WECARE_ENDORSEMENT_PRODUCT_SUPPLIERS } from "../../Config";
import {
  UpdateTicketRemarks,
  UpdateTicketInsurerStatus,
  GetDocURL,
} from "../../constants/apiUrls";
import { GET, POST } from "../../services/apiServices";
import { useSnackbar } from "notistack";
import FileUploadComponent from "../../shared/components/FileUploadComponent";
import { connect } from "react-redux";
import {
  fetchLatestTicketComm,
  fetchLatestTicketdetails,
} from "../../redux/actions/ticketDetails";
import { fetchEndosementURLForExisting, handleCatch } from "../../shared/methods/apiMethods";
import { DialogFromRight, Toast } from "../../shared/components";
import ChildErrorBoundary from "../../shared/components/ChildErrorBoundary";
import Spinner from "../../shared/components/Spinner";



function CommentsHistory({
  OpenedTicketDetails,
  OnSetLatestTicketDetail,
  TicketCommunication, // fetches communication from redux store
  OnSetTicketComm, // sets communication in redux store
  oldTicketDetails,
  templateList,
}) {
  const { TicketDetailsID, RefObjectId, EndorsementID, ProductId, SupplierId } = OpenedTicketDetails;
  const [statusCollectionFromAPI, setStatusCollectionFromAPI] = useState([]);
  const insurerFileRef = useRef(null);
  const [selectedInsurerFile, setSelectedInsurerFiles] = useState([]);
  const [OldInsurerStatusID, setOldInsurerStatusID] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState({
    StatusID: "",
    StatusName: "",
  });
  const [insurerCommentInProgress, setInsurerCommentInProgress] =
    useState(false);
  const [insurerComment, setInsurerComment] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const InsurerStatusOption = [1, 5, 13, 7, 12];
  const [InsurerStatusList, setInsurerStatusList] = useState([]);
  const [dialogWithControls, setDialogWithControls] = useState();
  const [endorsementLoading, setEndorsementLoading] = useState(false);

  // ------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (OpenedTicketDetails && OpenedTicketDetails.ProductId) {
      GetSubStatusArrayFromAPI(OpenedTicketDetails.ProductId);
    }
  }, [OpenedTicketDetails]);

  const GetSubStatusArrayFromAPI = (productID) => {
    GET(`/GetTicketDetails/${productID}`)
      .then((res) => {
        console.log("response for status", res);
        if (res && res.ErrorCode == 0 && res.Data) {
          setStatusCollectionFromAPI(res.Data.statusCollections);
        } else {
          showMsg(res['Message'] || `Oops! Some error occurred while fetching ticket details`);
          console.log("error line 131");
        }
      })
      .catch((err) => {
        handleCatch(err)
        console.log("error", err);
      });
  };

  useEffect(() => {
    if (statusCollectionFromAPI.length > 0) {
      const options = statusCollectionFromAPI.filter(
        (item) => item.StatusType === 2
      );
      let enableStatusOptions = [];
      let disableStatusOptions = [];
      options.forEach((item) => {
        if (OpenedTicketDetails.InsurerStatusID == item.StatusID) {
          setSelectedStatus({
            StatusID: item.StatusID,
            StatusName: item.StatusName,
          });
          setOldInsurerStatusID(item.StatusID);
        }
        if (InsurerStatusOption.includes(item.StatusID)) {
          enableStatusOptions.push(item);
        } else {
          disableStatusOptions.push(item);
        }
      });
      setInsurerStatusList([...enableStatusOptions, ...disableStatusOptions]);
    }
  }, [statusCollectionFromAPI]);

  // ------------------------------------------------------------------------------------------------------

  const handleRemoveInsurerFile = (id) => {
    const fileList = selectedInsurerFile.filter((item) => item.id !== id);
    setSelectedInsurerFiles(fileList);
  };

  const showMsg = (msg, variant = "error") => {
    enqueueSnackbar(msg, {
      variant: variant,
      onClick: () => {
        closeSnackbar();
      },
    });
  };


  const getShortName = (name) => {
    if (name) {
      const nameArr = name.split(" ");
      if (nameArr.length > 1 && nameArr[0] && nameArr[nameArr.length - 1]) {
        return (
          nameArr[0][0].toUpperCase() +
          nameArr[nameArr.length - 1][0].toUpperCase()
        );
      } else if (nameArr.length === 1 && nameArr[0]) {
        return nameArr[0][0].toUpperCase();
      } else {
        return "";
      }
    }
  };

  const handleInsurerCommentSend = () => {
    if (!selectedStatus.StatusName) {
      showMsg("Please select the status first");
      return;
    }

    if (!insurerComment) {
      showMsg("Please enter the comment first");
      return;
    }

    const insurerStatusPayload = {
      TicketId:
        OpenedTicketDetails && OpenedTicketDetails.TicketId
          ? OpenedTicketDetails.TicketId
          : 0,
      InsurerStatusID: selectedStatus.StatusID,
      InsurerSubStatusID: 0,
      OldInsurerStatusID: OldInsurerStatusID,
      OldInsurerSubStatusID: 0,
    };
    setInsurerCommentInProgress(true);
    POST(UpdateTicketInsurerStatus, insurerStatusPayload)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const updateTicketRemarksPayload = {
            PrevObjectID: OpenedTicketDetails.RefObjectId,
            LeadID: OpenedTicketDetails.LeadID,
            InsurerID: OpenedTicketDetails.SupplierId,
            CustomerName: OpenedTicketDetails.CustomerName,
            ProductID: OpenedTicketDetails.ProductId,
            ProductName: OpenedTicketDetails.ProductName,
            Body: insurerComment,
            DisplaySenderName: DisplayName,
            MailAttachments:
              selectedInsurerFile.length > 0
                ? selectedInsurerFile.map((item) => ({
                    FileName: item.FileName,
                    AttachemntContent: item.AttachemntContent,
                  }))
                : [],
            ActionType: 3,
            StatusName: OpenedTicketDetails.StatusName,
            SubStatusName: OpenedTicketDetails.Status,
            TicketId: OpenedTicketDetails.TicketId,
            IsInsurer: false,
            TicketDetailId: OpenedTicketDetails.TicketDetailsID,
            StatusID: OpenedTicketDetails.StatusID,
            SubStatusID: OpenedTicketDetails.SubStatusID,
            HasAttachment: selectedInsurerFile.length > 0 ? true : false,
            IsJAG:
              OpenedTicketDetails && OpenedTicketDetails.IsJAG
                ? OpenedTicketDetails.IsJAG
                : 0,
            JAGEmployeeId:
              OpenedTicketDetails && OpenedTicketDetails.JAGEmployeeId
                ? OpenedTicketDetails.JAGEmployeeId
                : "",
            IssueTypeID:
              OpenedTicketDetails && OpenedTicketDetails.IssueTypeId
                ? OpenedTicketDetails.IssueTypeId
                : 0,
            TicketSource: (oldTicketDetails && oldTicketDetails.Source) || "",
            TicketSubSource:
              (oldTicketDetails && oldTicketDetails.SubSource) || "",
          };
          POST(
            `${UpdateTicketRemarks}/${
              templateList && templateList.length > 0
                ? templateList[0].TriggerName
                : "DefaultTemplate"
            }`,
            updateTicketRemarksPayload
          )
            .then((res) => {
              if (res.ErrorCode === 0) {
                showMsg(res.Message || "Success", "success");
                setSelectedInsurerFiles([]);
                setInsurerComment("");
                setInsurerCommentInProgress(false);
                setOldInsurerStatusID(selectedStatus.StatusID);
                OnSetLatestTicketDetail({
                  RefObjectId: RefObjectId,
                  TicketDetailsID: TicketDetailsID,
                });
                OnSetTicketComm({
                  RefObjectId: RefObjectId,
                  TicketDetailsID: TicketDetailsID,
                  Source: "WeCare",
                });
              } else {
                showMsg(res.Message || "Something went wrong");
                setInsurerCommentInProgress(false);
              }
            })
            .catch((err) => {
              console.log(err);
              showMsg("Something went wrong");
              setInsurerCommentInProgress(false);
            });
        } else {
          showMsg("Something went wrong");
          setInsurerCommentInProgress(false);
        }
      })
      .catch((err) => {
        console.log(err);
        showMsg("Something went wrong");
        setInsurerCommentInProgress(false);
      });
  };

  // Download attachemnt through secure link

  const downloadAttachment = (docID, fileName) => {
    if (docID) {
      GET(`${GetDocURL}/${docID}`)
        .then((res) => {
          if (res && res.ErrorCode == 0 && res.Data) {
            window.open(res.Data, "_blank");
          } else {
            if (res.Response) {
              enqueueSnackbar(res.Response, {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            } else {
              enqueueSnackbar("URL not found", {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            }
            console.log("something went wrong");
          }
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log(err);
        });
    } else {
      enqueueSnackbar("Document not found", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      console.log("Document ID not found");
    }
  };

  const showReviewEndorsementButton = () =>{
    if(EndorsementID && EndorsementID>0 && WECARE_ENDORSEMENT_PRODUCT_SUPPLIERS(ProductId, SupplierId)){
      return true
    }
    else return false
  }

  const openWeCareEndorsement = () => {
    let url = '/GetEndorsementSystemUrl';
    setEndorsementLoading(true);
    let cb = (data, error) =>{
      setEndorsementLoading(false); 
      if (data) {
        setDialogWithControls({
          open: true,
          title: 'We Care Endorsement',
          handleClose: () => {setDialogWithControls(); window.location.reload();},
          size: 80,
          children: 
            <ChildErrorBoundary>
               <iframe id="inlineFrameExample"
                  title="Inline Frame Example"
                  width="100% !important"
                  style={{height:'100vh'}}
                  src={data}
                  className="ifamre-box"
                >
               </iframe>
            </ChildErrorBoundary>
  })
      }
      else Toast.error(error);
    }
    fetchEndosementURLForExisting(url, OpenedTicketDetails, cb);
  }

  // ------------------------------------------
  return (
    <div className="comment-history">
      <h2>Ticket Comments</h2>
      <List>
        <ListItem>
          <h3>Update Ticket</h3>
          <div className="comment-pannel">
            <FormControl className="form-control">
              <InputLabel id="demo-customized-select-label">
                Ticket Status
              </InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedStatus.StatusName}
                // onChange={handleChange}
              >
                {InsurerStatusList.filter((item) => item.StatusType === 2).map(
                  (item, i) => {
                    return (
                      <MenuItem
                        value={item.StatusName}
                        onClick={() =>
                          setSelectedStatus({
                            StatusID: item.StatusID,
                            StatusName: item.StatusName,
                          })
                        }
                        key={i}
                        disabled={InsurerStatusOption.includes(item.StatusID)}
                      >
                        {item.StatusName}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
            <FormControl className="form-control">
                
                 <TextareaAutosize
                  className="txtarea"
                  placeholder="Enter Comments"
                  value={insurerComment}
                  onChange={(e) => setInsurerComment(e.target.value)}
                />
                <p className="attach-btn">
                  <FileUploadComponent
                    setFiles={setSelectedInsurerFiles}
                    fileRef={insurerFileRef}
                    isMultipleFileAllowed={true}
                    isAttachementIcon={true}
                    className="hello"
                  />
                </p>
                
            </FormControl>
            {selectedInsurerFile.map((item) => {
              return (
                <span className="file-detail name-file" key={item.id}>
                  {item.FileName}{" "}
                  <button
                    className="remove-file-icon"
                    onClick={() => handleRemoveInsurerFile(item.id)}
                  >
                    <CloseIcon />
                  </button>
                </span>
              );
            })}
            <div>
            <Button className={'submit-btn'} onClick={handleInsurerCommentSend}>
                    Submit
                  </Button>
             
              {
                showReviewEndorsementButton() &&
                  <Button className="endorsement-req-btn" onClick={openWeCareEndorsement} disabled={endorsementLoading}>
                    Review Endorsement Request {endorsementLoading && <Spinner />}
                  </Button>
              }
            </div>
          </div>
        </ListItem>
        <ListItem>
          <h3>Comment History</h3>
          <List className="comment-trail scrool-toll">
            {TicketCommunication &&
              Array.isArray(TicketCommunication) &&
              TicketCommunication.filter(
                (item) => item.ActionType == 3 || item.ActionType == 5
              ).map((commu, i) => {
                return (
                  <ListItem key={i}>
                    <div className="short-name">
                      {getShortName(commu.CreatedBy)}
                    </div>
                    <div className="timeline"></div>
                    <span className="comment-time">
                      {convertToIsoFormate(new Date(commu.CreatedOn).getTime())}
                    </span>
                    <div className="commnets-txt">
                      <p>{commu.CreatedBy}</p>
                      <strong>
                        {
                          <span
                            dangerouslySetInnerHTML={{
                              __html: commu.Body,
                            }}
                          ></span>
                        }
                      </strong>
                    </div>
                    {commu.MailAttachments &&
                      commu.MailAttachments.map((attachement, i) => {
                        return (
                          attachement.FileName && (
                            <span
                              className="attachemt-link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                downloadAttachment(
                                  attachement.DocId,
                                  attachement.FileName
                                )
                              }
                            >
                              {/* <a
                                href={attachement.AttachmentURL}
                                target="_blank"
                                key={i}
                              > */}
                              <div className="upload-item">
                                <span className="file-extn">
                                  {getFileExtension(attachement.FileName) ===
                                  "doc" ? (
                                    <img
                                      src="/images/doc_icon.png"
                                      alt="pdf_icon"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {getFileExtension(attachement.FileName) ===
                                    "jpg" ||
                                  getFileExtension(attachement.FileName) ===
                                    "png" ||
                                  getFileExtension(attachement.FileName) ===
                                    "jpeg" ? (
                                    <img
                                      src="/images/img_icon.png"
                                      alt="pdf_icon"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {getFileExtension(attachement.FileName) ===
                                  "pdf" ? (
                                    <img
                                      src="/images/pdf_icon.svg"
                                      alt="pdf_icon"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <span className="download-info">
                                  {attachement.FileName}
                                </span>
                              </div>
                              {/* </a> */}
                            </span>
                          )
                        );
                      })}
                  </ListItem>
                );
              })}{" "}
          </List>
        </ListItem>
      </List>
      {dialogWithControls && dialogWithControls.open &&
        <DialogFromRight
          open={dialogWithControls.open}
          handleClose={dialogWithControls.handleClose}
          title={dialogWithControls.title}
          children={dialogWithControls.children}
          size={dialogWithControls.size}
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    OpenedTicketDetails:
      (state && state.ticketdetails && state.ticketdetails.ticketDetails) || {},
    TicketCommunication:
      (state && state.ticketdetails && state.ticketdetails.ticketComm) || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    OnSetLatestTicketDetail: (payload) =>
      dispatch(fetchLatestTicketdetails(payload)),
    OnSetTicketComm: (payload) => dispatch(fetchLatestTicketComm(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsHistory);
