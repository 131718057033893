import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background:'#fff',
  },
  toolbarTop:{
    minHeight:'18px',
    height:'50px',
    marginLeft: 0,
    "& button":{
        padding:'5px',
        color:'#253858',
    },
    "& h6":{
        fontSize:'16px',
        marginLeft: 0,
        color:'#253858'
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const DialogFromRight =(props)  =>{
  const { open, handleClose, title, size, autoScrollBodyContent} = props 
  const classes = useStyles();
  const [className, setClassName ] = useState("dialog-30");

  useEffect(() => {
    if (size) {
        switch (size) {
            case 10: setClassName("dialog-zero");
                break;
            case 20: setClassName("dialog-20");
                break;
            case 30: setClassName("dialog-30");
                break;
            case 40: setClassName("dialog-40");
                break;
            case 50: setClassName("dialog-50");
                break;
            case 60: setClassName("dialog-60");
                break;  
            case 70: setClassName("dialog-70");
                break;
            case 80: setClassName("dialog-80");
                break;
            default: setClassName("dialog-30")
        }
    }
}, [size])

  return (
      <>
        <Dialog
                autoScrollBodyContent={autoScrollBodyContent || false}
                open={open}
                fullScreen
                fullWidth={true}
                className={className}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                {title && <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolbarTop}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon /> 
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                           {title}
                        </Typography>
                    </Toolbar>
                </AppBar>
}
                {props.children}
            </Dialog>
      </>
  );
}

export default DialogFromRight;
