import * as type from "../types/types";

const initialState = {
    weCareAdminSelection: {
        Products: [],
    },
    pageSelected: null,
};

export default function weCareDashboard_reducer(state = initialState, action) {
    switch (action.type) {
        case type.SET_WE_CARE_ADMIN_SELECTION:
            return {
                ...state,
                weCareAdminSelection: {
                    ...state.weCareAdminSelection,
                    Products: action.payload
                }
            }
        default: return state
    }
}