import React, { useState } from "react";
import { Button } from "@material-ui/core";

const MailContent = ({ Body, ind ,EventType, TicketSource}) => {
  const [hide, setHide] = useState(true);

  const styleObj = { height: "70px", overflow: "hidden" };

  return (
    <div>
      <div
        className="mail-data"
        style={(ind == 0 ? ((EventType === "Auto Reply from PB" || TicketSource === "Auto Reply from PB") ? hide : !hide) : hide) ? styleObj : {}}
      >
        <div id="Mail-body" style={{wordBreak: "break-word"}} dangerouslySetInnerHTML={{ __html: Body }}></div>
      </div>
      <div className="read-more-button">
        <Button onClick={() => setHide(!hide)}>
          {(ind == 0 ? ((EventType === "Auto Reply from PB" || TicketSource === "Auto Reply from PB") ? hide : !hide) : hide) ? "Read more" : "Show Less"}
        </Button>
      </div>
    </div>
  );
};

export default MailContent;
