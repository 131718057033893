import React, { useState, useRef, useEffect, useReducer } from 'react';
import { GET } from '../../services/apiServices';
import { Toast } from '../../shared/components';
import { handleCatch } from '../../shared/methods/apiMethods';
import Spinner from '../../shared/components/Spinner';

// '../../services/apiServices';

function ViewCache(props) {
    const { keyName } = props;
    const [data, setData] = useState('');
    
    const getViewCacheControl = () => {
        let url = `GetCachedValue?KeyName=${keyName}`;
        GET(url)
            .then((res) => {
                if (res && res.ErrorCode == 0 && res.Data) {
                   setData(JSON.parse(res.Data))
                } else {
                    Toast.error(res.Message)
                }
            })
            .catch((err) => {
                handleCatch(err)
            });
    };
    
    useEffect(() => {
        if (keyName) {
            getViewCacheControl(); // fetch gruop list and item list
        }

    }, [keyName]);
    return (
        <div className='view-cache'>
            
            {Boolean (data) ? <pre style={{textWrap : 'balance'}}>{JSON.stringify(data, null, 2)}</pre> : <div><Spinner/></div>}
        </div>
    )
}

export default ViewCache
