import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { TableBody, Grid, Button } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({}));

export default function CustomizedTables({ employeeList, setCreateTicketDialogOpen, setLeadId, setEmail, setEmployeeName }) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClick = (e, leadId, email, name) => {
    if(!email){
      enqueueSnackbar("Email id should not be blank....", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      return
    }
    e.preventDefault()
    setCreateTicketDialogOpen(true);
    setEmail(email);
    setEmployeeName(name);
    setLeadId(leadId)
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead className="table-head">
          <TableRow>
            <StyledTableCell className="t-first">Employee Code</StyledTableCell>
            <StyledTableCell>Email ID</StyledTableCell>
            <StyledTableCell>Booking ID</StyledTableCell>
            <StyledTableCell>Employee Name</StyledTableCell>
            <StyledTableCell>Company Name</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeList &&
            employeeList.map((i) => {
              return (
                <StyledTableRow className="table-body">
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className="t-first"
                  >
                    {i.EmployeeCode}
                  </StyledTableCell>
                  <StyledTableCell className="t-padding">
                    {i.EmployeeEmail}
                  </StyledTableCell>
                  <StyledTableCell className="t-padding">
                    {i.LeadID}
                  </StyledTableCell>
                  <StyledTableCell className="t-padding">
                    {i.EmployeeName}
                  </StyledTableCell>
                  <StyledTableCell className="t-padding">
                    {i.CompanyName}
                  </StyledTableCell>
                  <StyledTableCell className="t-padding">
                    <Button className="create-btn" id="createTicketFromEmpList" onClick={(e) => handleClick(e, i.LeadID, i.EmployeeEmail, i.EmployeeName)
                    }>Create Ticket</Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
