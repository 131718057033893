import React, { useEffect, useState } from 'react'
import {
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  ListItemText,
} from '@material-ui/core'
import { GET } from '../../../services/apiServices'
import { GetStatusMasterData } from '../../../constants/apiUrls'
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import { Toast } from '../../../shared/components'
import { handleCatch } from '../../../shared/methods/apiMethods'
import { Autocomplete } from '@material-ui/lab';
import { setInsurerProductAccess } from '../../../redux/actions/auth'
function SearchFilter({
  setSearchParamsParent,
  handleSubmit,
  setType,
  userDetails,
}) {
  const dispatch = useDispatch()
  const userInfo_store = useSelector(state => state.auth.userInfo)
  const [searchType, setSearchType] = useState('0')
  const [searchParams, setSearchParams] = useState({})
  const [masters, setMasters] = useState(null)

  useEffect(() => {
    getMasters();
    resetSearchParams();
    // resetMasters();
  }, [])

  useEffect(() => {
    if (userInfo_store) { getProductListForUser() }
  }, [])

  const getProductListForUser = () => {
    let endpoint = `WeCare/GetUserProductAccess/${userInfo_store.UserID}`
    GET(endpoint, null, 'weCare').then(res => {
      if (res && res.ErrorCode === 0) {
        setMasters(prev => ({ ...prev, ProductMaster: res?.Data?.Table }))
        dispatch(setInsurerProductAccess(res?.Data?.Table))
        if (res?.Data?.Table?.length === 1) {
          setSearchParams(prev => ({ ...prev, ProductID: res.Data.Table?.[0]?.ProductId }))
        }
      }
    })
  }

  useEffect(() => {
    if (searchParams.ProductID) {
      fetchIssueSubIssueMaster()
    }
  }, [searchParams.ProductID])

  const fetchIssueSubIssueMaster = () => {
    let endpoint = `WeCare/GetIssueSubIssueListByProductID/${searchParams.ProductID}`
    GET(endpoint, null, 'weCare').then(res => {
      let tempMaster = []
      if (res && res.ErrorCode === 0) {
        tempMaster = res.Data.Table

      }
      setMasters(prev => ({ ...prev, IssueSubIssueMaster: tempMaster }))
    }).catch(err => {
      handleCatch(err)
    })
  }

  const getMasters = () => {
    const isStatusOptionExist = (statusOptions, item) => {
      for (let i = 0; i < statusOptions.length; i++) {
        if (statusOptions[i].StatusID === item.StatusID) {
          return true
        }
      }
      return false
    }
    GET(GetStatusMasterData)
      .then((response) => {
        const statusCollections = response.Data
        const statusOptions = []
        statusCollections.forEach((item) => {
          if (
            item.StatusType === 2 &&
            // item.StatusID !== 4 &&
            !isStatusOptionExist(statusOptions, item)
          ) {
            statusOptions.push(item)
          }
        })
        setMasters((prev) => ({
          ...prev,
          StatusMaster: statusOptions,
          TATMaster: [
            {
              name: 'TAT Busted',
              value: 1,
            },
            {
              name: 'Busted Today',
              value: 2,
            },
            {
              name: 'Busting in 2 days',
              value: 3,
            },
            {
              name: 'Busting in 3 days',
              value: 4,
            },
            {
              name: 'Busting above 3 days',
              value: 5,
            },
          ],
        }))
      })
      .catch((e) => console.log(e))
  }

  const resetMasters = () => {
    setMasters({
      StatusMaster: [],
      TATMaster: [],
      IssueSubIssueMaster: null,
      ProductMaster: null
    })
  }

  const resetSearchParams = () => {
    setSearchParams(a => ({
      ...a,
      TicketID: 0,
      TicketDetailsID: '',
      LeadID: '',
      FromDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      ToDate: new Date(),
      StatusID: 0,
      ProductID: searchParams?.ProductID || 0,
      SupplierID: userDetails?.InsurerID,
      Source: '',
      QueueID: 0,
      TicketAssignmentType: 1,
      SubStatusID: 0,
      Ageing: 0,
      GroupID: 0,
      EmailId: '',
      CustomerName: '',
      TicketsCount: 0,
      EmailsCount: 0,
      CallsCount: 0,
      CBRequest: 0,
      SubIssueID: 0,
      InsurerStatusID: 0,
      RegistrationNo: '',
      PolicyNo: '',
      IsInsurer: 1,
      IsAttachment: -1,
      ApplicationNo: '',
      TATFilter: 0,
      IssueList: [],
      SubIssueList: [],
    }))
  }

  const validateAdvancedSearch = () => {
    const {
      TicketDetailsID,
      LeadID,
      EmailId,
      RegistrationNo,
      PolicyNo,
      ApplicationNo,
    } = searchParams

    if (
      searchType == 1 &&
      (TicketDetailsID != '' ||
        LeadID != '' ||
        EmailId != '' ||
        RegistrationNo != '' ||
        PolicyNo != '' ||
        ApplicationNo != '')
    ) {
      return true
    } else if (searchType == 1) {
      Toast.error('Atleast 1 value is required');
      return false
    } else return true
  }

  const handleSearchOptionChange = (event) => {
    if (event && event.persist) {
      event.persist()
    }
    setSearchType(event.target.value)
    resetSearchParams()
    switch (event.target.value) {
      case '0':
        setType(1)
        break
      case '1':
        setType(7)
        break
      default:
        break
    }
  }

  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist()
    }
    const { name, value } = event.target
    if (name === 'ProductID') {
      setSearchParams((a) => ({
        ...a,
        [name]: value,
        IssueList: [],
        IssueCode: '',
        SubIssueList: [],
        SubIssueCode: ''
      }))
    }
    else if (name === 'IssueCode') {
      setSearchParams((a) => ({
        ...a,
        [name]: value,
        SubIssueCode: ''
      }))
    }
    else setSearchParams((a) => ({
      ...a,
      [name]: value,
    }))

  }

  const handleSearchButton = (event) => {
    console.log('search type', searchType)
    if (event && event.persist) {
      event.persist()
    }
    let a = validateAdvancedSearch()
    if (searchType == '1' && a) {
      // setType(7);
      handleSubmit(event, 7, searchParams)
      setSearchParamsParent(searchParams) //set the values for export PDF
    } else if (searchType == '0') {
      setType(1)
      let payload = {
        TicketID: 0,
        TicketDetailsID: '',
        LeadID: '',
        FromDate: searchParams?.FromDate,
        ToDate: searchParams?.ToDate,
        StatusID: 0,
        ProductID: searchParams?.ProductID,
        SupplierID: userDetails?.InsurerID,
        Source: '',
        QueueID: 0,
        TicketAssignmentType: 1,
        SubStatusID: 0,
        Ageing: 0,
        GroupID: 0,
        EmailId: '',
        CustomerName: '',
        TicketsCount: 0,
        EmailsCount: 0,
        CallsCount: 0,
        CBRequest: 0,
        IssueID: 0,
        SubIssueID: 0,
        InsurerStatusID: searchParams?.InsurerStatusID,
        RegistrationNo: '',
        PolicyNo: '',
        IsInsurer: 1,
        IsAttachment: -1,
        ApplicationNo: '',
        TATFilter: 0,
        IssueList: searchParams?.IssueList,
        SubIssueList: searchParams?.SubIssueList,
      }
      handleSubmit(event, 1, payload)
      setSearchParamsParent(payload) // set the values for export PDF
    }
  }



  function getUniqueProductIssues(issues) {
    const uniqueIssues = new Set();
    const result = [];

    masters?.IssueSubIssueMaster?.forEach(issue => {
      if (!uniqueIssues.has(issue.IssueCode)) {
        uniqueIssues.add(issue.IssueCode);
        result.push(issue);
      }
    });

    return result || [];
  }

  const getUniqueProductSubIssues = () => {
    let tempSubIssueMaster = masters?.IssueSubIssueMaster?.filter(i => searchParams?.IssueList?.includes(i.IssueCode))
    const uniqueSubIssues = new Set();
    const result = [];

    tempSubIssueMaster.forEach(obj => {
      if (!uniqueSubIssues.has(obj.SubIssueCode)) {
        uniqueSubIssues.add(obj.SubIssueCode);
        result.push(obj);
      }
    });
    return result || [];
  };

  return (
    <>
      <div className="search-box">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={13} md={12}>
            <FormControl component="fieldset">
              <RadioGroup
                name="searchType"
                value={searchType}
                onClick={handleSearchOptionChange}
                row
              >
                <FormControlLabel
                  className="radio-box"
                  value="0"
                  control={<Radio color="primary" />}
                  label="Simple Search"
                />
                <FormControlLabel
                  className="radio-box"
                  value="1"
                  control={<Radio color="primary" />}
                  label="Advanced Search"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div className="search-option-list">
        <ul>
          {searchType == 0 ? (
            <>
              <li>
                <FormControl className="form-control">
                  <TextField
                    id="date"
                    type="date"
                    label="Start Date"
                    value={moment(new Date(searchParams?.FromDate)).format(
                      'YYYY-MM-DD',
                    )}
                    name="FromDate"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </li>
              <li>
                <FormControl className="form-control">
                  <TextField
                    id="date"
                    label="End Date"
                    type="date"
                    value={moment(new Date(searchParams?.ToDate)).format(
                      'YYYY-MM-DD',
                    )}
                    name="ToDate"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </li>
              <li className="insurer-status-list">
                <FormControl className="form-control">
                  <InputLabel htmlFor="demo-customized-select-native">
                    Insurer Status
                  </InputLabel>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    name="InsurerStatusID"
                    value={searchParams?.InsurerStatusID}
                    onChange={handleChange}
                    fullWidth
                  >
                    {masters?.StatusMaster?.map((i, index) => (
                      <MenuItem key={index} value={i.StatusID}>
                        {i.StatusName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li>
              {masters && masters.ProductMaster && masters.ProductMaster.length > 1 ? <li className="insurer-status-list">
                <FormControl className="form-control">
                  <InputLabel htmlFor="demo-customized-select-native">
                    Product
                  </InputLabel>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    name="ProductID"
                    value={searchParams?.ProductID}
                    onChange={handleChange}
                    fullWidth
                  >
                    {masters.ProductMaster.map((i, index) => (
                      <MenuItem key={index} value={i.ProductId}>
                        {i.ProductName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li> : ''}
              {masters?.IssueSubIssueMaster && masters.IssueSubIssueMaster.length > 0 ?
                <>
                  <li className="calander-list">
                    <FormControl className="form-control calander-control"
                    >
                      <Autocomplete
                        options={getUniqueProductIssues() || []}
                        fullWidth
                        limitTags={0}
                        renderTags={()=> <span>{searchParams?.IssueList?.length ? `${searchParams.IssueList?.length} Issue(s) selected` : ''}</span>}
                        className="single-select"
                        name="IssuesList"
                        id="Insurer-IssueList"
                        disableCloseOnSelect
                        getOptionLabel={(item) => item.IssueName}
                        value={getUniqueProductIssues()?.filter(item => searchParams.IssueList.includes(item.IssueCode))}
                        onChange={(e, arr) => setSearchParams(prev => ({ ...prev, IssueList: arr.map(i => i.IssueCode), SubIssueList: [] }))}
                        renderOption={(option) => (
                          <React.Fragment>
                            <Checkbox
                              className='autocomplete-checkbox'
                              checked={searchParams.IssueList && searchParams.IssueList.includes(option.IssueCode) ? true : false}
                            />
                            <span className='list-name'>{option.IssueName}</span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Select Issue(s)"
                                                      />
                        )}
                        multiple // Enable multi-select
                      />
                      {/* {searchParams.IssueList && searchParams.IssueList.length > 0 ? <span className='selected-item-info'>{searchParams.IssueList.length} Selected</span> : ''} */}
                    </FormControl>

                  </li>
                  <li className="calander-list">
                    <FormControl className="form-control calander-control">
                      <Autocomplete
                        options={getUniqueProductSubIssues() || []}
                        fullWidth
                        className="single-select"
                        limitTags={0}
                        renderTags={()=> <span>{searchParams?.SubIssueList?.length ? `${searchParams.SubIssueList?.length} Sub Issue(s) selected` : ''}</span>}
                        name="SubIssueList"
                        id="Insurer-SubIssueList"
                        disableCloseOnSelect
                        getOptionLabel={(item) => item.SubIssueName}
                        value={getUniqueProductSubIssues()?.filter(item => searchParams.SubIssueList.includes(item.SubIssueCode))}
                        onChange={(e, arr) => setSearchParams(prev => ({ ...prev, SubIssueList: arr.map(i => i.SubIssueCode) }))}
                        renderOption={(option) => (
                          <React.Fragment>
                            <Checkbox
                              className='autocomplete-checkbox'
                              checked={searchParams.SubIssueList && searchParams.SubIssueList.includes(option.SubIssueCode) ? true : false}
                            />
                            <span className='list-name'>{option.SubIssueName}</span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Select SubIssue(s)"
                          />
                        )}
                        multiple // Enable multi-select
                      />
                      {/* {searchParams.SubIssueList && searchParams.SubIssueList.length > 0 ? <span className='selected-item-info'>{searchParams.SubIssueList.length} Selected</span> : ''} */}
                    </FormControl>
                  </li>
                </> : ''}
              <li className='search-box-list'>
                <Button
                  className="search-btn"
                  onClick={handleSearchButton}
                >
                  Search
                </Button>
              </li>
            </>
          ) : (
            <>
              <li>
                <FormControl className="form-control">
                  <TextField
                    label="Ticket Id"
                    name="TicketDetailsID"
                    onChange={handleChange}
                    value={searchParams?.TicketDetailsID}
                    id="outlined-margin-normal"
                    margin="normal"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl className="form-control">
                  <TextField
                    label="Lead Id"
                    name="LeadID"
                    onChange={handleChange}
                    value={searchParams?.LeadID}
                    id="outlined-margin-normal"
                    margin="normal"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl className="form-control">
                  <TextField
                    label="Email Id"
                    name="EmailId"
                    onChange={handleChange}
                    value={searchParams?.EmailId}
                    id="outlined-margin-normal"
                    margin="normal"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl className="form-control">
                  <TextField
                    label="Registration No"
                    name="RegistrationNo"
                    onChange={handleChange}
                    value={searchParams?.RegistrationNo}
                    id="outlined-margin-normal"
                    margin="normal"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl className="form-control">
                  <TextField
                    label="Policy No"
                    name="PolicyNo"
                    onChange={handleChange}
                    value={searchParams?.PolicyNo}
                    id="outlined-margin-normal"
                    margin="normal"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl className="form-control">
                  <TextField
                    label="Application No"
                    name="ApplicationNo"
                    onChange={handleChange}
                    value={searchParams?.ApplicationNo}
                    id="outlined-margin-normal"
                    margin="normal"
                  />
                </FormControl>
              </li>
              <li>
                <Button
                  className="search-btn"
                  onClick={(event) => handleSearchButton(event)}
                >
                  Search
                </Button>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetails: state && state.auth && state.auth.userInfo, // userDetails from Redux Store
  }
}

export default connect(mapStateToProps, null)(SearchFilter)
