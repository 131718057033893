import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Typography,
} from "@material-ui/core";

import DialogTitle from "../../../shared/components/DialogTitle";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AssignDialog({
  isAssignDialogOpen,
  setAssignDialogOpen,
  UpdateL0ToL1Api,
}) {
  const handleUpdateL0ToL1 = () => {
    UpdateL0ToL1Api();
    setAssignDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        className="assign-confirm-box"
        onClose={() => setAssignDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isAssignDialogOpen}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setAssignDialogOpen(false)}
        >
          Assign
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Are you sure</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className="yes-btn"
            onClick={handleUpdateL0ToL1}
            color="primary"
          >
            Yes
          </Button>
          <Button
            autoFocus
            className="no-btn"
            onClick={() => setAssignDialogOpen(false)}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
