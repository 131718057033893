import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@material-ui/core";
import DialogTitle from "../components/DialogTitle";

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ConfirmationDialog({
  isConfirmDialogOpen,
  setConfirmDialogOpen,
  updateFn,
  claimsMsg = "",
}) {
  const handleContinue = () => {
    setConfirmDialogOpen(false);
    updateFn();
  };

  return (
    <div>
      <Dialog
        maxWidth="xs"
        className="reply-popup confimation-box"
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isConfirmDialogOpen}
      >
        <DialogTitle
          className="title-heading"
          id="customized-dialog-title"
          onClose={() => setConfirmDialogOpen(false)}
        >
          Confirm
        </DialogTitle>
        <DialogContent>
          <p>{claimsMsg ? claimsMsg : "Are you sure?"}</p>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className="continue-btn"
            onClick={handleContinue}
            color="primary"
          >
            Continue
          </Button>
          <Button
            autoFocus
            className="cancel-btn"
            onClick={() => setConfirmDialogOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
