import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, DialogActions } from "@material-ui/core";
import { useSnackbar } from "notistack";

import Slide from "@material-ui/core/Slide";
import { GET } from "../../services/apiServices";
import CustomBackdrop from "../Components/CustomBackdrop";
import AddUserDialog from "./Components/AddUserDialog";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
    height: "40px,",
  },
  title: {
    flex: 1,
    fontSize: "16px",
  },
  formControl: {
    minWidth: "100%",
  },
  h6: {
    color: "#414141",
    fontSize: "14px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddUserTicket(props) {
  const classes = useStyles();
  const { userDetails } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [adminUserList, setAdminUserList] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedUser: null,
      openEditUserDialog: false,
      sectionName: null,
    }
  );

  //For showing Snackbar messages
  const showMessage = (message, varient) => {
    return enqueueSnackbar(message, {
      variant: varient,
      onClick: () => {
        closeSnackbar();
      },
    });
  };

  const fetchWeCareAdminList = () => {
    setIsDataLoading(true);
    let url = "GetWeCareAdminUsers";
    GET(url)
      .then((res) => {
        setIsDataLoading(false);
        if (res && res.ErrorCode == 0 && res.Data) {
          setAdminUserList(res.Data.Table);
        } else
          showMessage(
            res["Message"] || "Couldn't fetch Admin user list",
            "error"
          );
      })
      .catch((err) => {
        setIsDataLoading(false);
        if (err && err.response) {
          return showMessage(
            err.response.data.message || "Some error occurred!",
            "error"
          );
        } else return showMessage("Something went wrong!", "error");
      });
  };

  useEffect(() => {
    fetchWeCareAdminList(); // fetches Admin user list to render on page mount
  }, []);

  const handleUpdateUser = (user, sectionName) => {
    setState({
      openEditUserDialog: true,
      selectedUser: user,
      sectionName: sectionName,
    });
  };

  const handleClose = () => {
    setState({
      openEditUserDialog: false,
      selectedUser: null,
      sectionName: null,
    });
  };

  return (
    <div className="user-details-view">
      {/* <h3>
        User Details ({userDetails?.Name} -{" "}
        <b>
          <u>{userDetails?.RoleName}</u>
        </b>
        )
      </h3> */}
      {state.openEditUserDialog ? (
        <AddUserDialog
          title={state.sectionName}
          userDetails={state.selectedUser}
          open={state.openEditUserDialog}
          handleClose={handleClose}
          showMessage={showMessage}
          fetchWeCareAdminList={fetchWeCareAdminList}
        />
      ) : null}
      <Grid container spacing={1} className="text-right">
        <Grid item md={12} xs={6}>
          <Button
            className="add-btn"
            onClick={() => handleUpdateUser(null, "Add New User")}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
      {isDataLoading ? (
        <CustomBackdrop open={isDataLoading} />
      ) : adminUserList && adminUserList.length > 0 ? (
        <div className="user-details-list scroll-btn">
          <div className="user-details-list-head">
            <ul>
              <li>
                <span>S.no</span>
              </li>
              <li>
                <span>User ID</span>
              </li>
              <li>
                <span>Name</span>
              </li>
              <li>
                <span>EmailID</span>
              </li>
              {/* <li>
                <span>Mobile No</span>
              </li>
              <li>
                <span>Login ID</span>
              </li> */}
              <li>
                <span>User Type</span>
              </li>
              <li>
                <span>User Role</span>
              </li>
              {/* 
              <li>
                <span>Application Access</span>
              </li> */}
              {/* <li>
                                        <span>Created On</span>
                                    </li> */}
              <li className="edit">
                <span>Edit</span>
              </li>
            </ul>
          </div>
          <div className="user-details-list-body">
            {adminUserList.map((user, index) => {
              let { UserID, USERNAME, EmailID, LoginID, UserType, CreatedOn } =
                user;
              return (
                <div>
                  <ul key={`index+${UserID}`}>
                    <li>
                      <span>S.no</span>
                      <strong>{index + 1}</strong>
                    </li>
                    <li>
                      <span>User ID</span>
                      <strong>{UserID}</strong>
                    </li>
                    <li>
                      <span>Name</span>
                      <strong>{USERNAME}</strong>
                    </li>
                    <li>
                      <span>EmailID</span>
                      <strong>{EmailID}</strong>
                    </li>
                    {/* <li>
                      <span>Mobile No</span>
                      <strong>-</strong>
                    </li>
                    <li>
                      <span>Login ID</span>
                      <strong>{LoginID}</strong>
                    </li> */}
                    <li>
                      <span>User Type</span>
                      <strong>{UserType}</strong>
                    </li>
                    <li>
                      <span>User Role</span>
                      <strong>{UserType === 6 ? "Admin" : "Agent"}</strong>
                    </li>
                    {/* <li>
                      <span>User Role</span>
                      <strong>-</strong>
                    </li>
                    <li>
                      <span>Application Access</span>
                      <strong>-</strong>
                    </li> */}
                    {/* <li>
                                                    <span>Created On</span>
                                                    <strong>{new Date(CreatedOn)}</strong>
                                                </li> */}
                    <li className="edit">
                      <span>Edit</span>
                      <strong>
                        <Button
                          className="edit-btn"
                          onClick={() => handleUpdateUser(user, "Edit User")}
                        >
                          <i className="fa fa-pencil"></i>
                        </Button>
                      </strong>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>No Data found.....</div>
      )}
      <DialogActions></DialogActions>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: (state && state.auth && state.auth.userInfo) || {},
  };
};

export default connect(mapStateToProps)(AddUserTicket);
