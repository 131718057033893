import React, { useReducer, useState } from "react";
import { Link, ListItem, Grid, List,Button,FormControl,TextField, FormHelperText } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CustomBackdrop from "../../../Components/CustomBackdrop";
import { HashLoader } from 'react-spinners';
import { fetchDashboardData_method } from "../../../../shared/methods/apiMethods";
import AgentDashboardData from "./AgentDashboardData";
import Toast from "../../../../shared/components/Toast";
import { AGENT_SIMPLE_SEARCH_TYPE } from "../../../../Config";
import { useEffect } from "react";
import { setAgentSimpleSearchData } from "../../../../redux/actions/pbDashboard_action";
import { GetDisabledEndPoints } from "../../../../shared/methods/GlobalSelector";
import { CheckIfEndPointIsDisabled } from "../../../../shared/methods/methods";


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
}));

const AgentSimpleSearch = ({showDataToRender, setShowDataToRender}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const values_store = useSelector(state=> state && state.pbDashboard && state.pbDashboard.agentSimpleSearch)
  const disabledEndPoints_store = GetDisabledEndPoints();
  const [dataToRender, setDataToRender] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [screenRendered, setScreenRendered] = useState(false);

  const [formValues, setFormValues] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      TicketID: values_store.TicketID,
      LeadID: values_store.LeadID,
      MobileNo: values_store.MobileNo,
      EmailId: values_store.EmailId,
    }
  )

  const handleChange = (event) =>{
    let { value, name } = event.target;
    setFormValues({[name] : value});
    setDataToRender(null)
  }


    const generatePayload = () => {
        return {
            // "TicketID": 0,
            "TicketDetailsID": formValues.TicketID,
            "LeadID": formValues.LeadID || 0,
            // "FromDate": 1673586657839,
            // "ToDate": 1673586657839,
            "Type": AGENT_SIMPLE_SEARCH_TYPE,
            "EmailId": formValues.EmailId,
            // "CustomerName": "",
            "MobileNo": formValues.MobileNo || 0,
        }
    }

    const hasError = (field) => {
        let message = ''
        switch (field) {
            case 'MobileNo':
                if (formValues[field].length > 0 && /^[1-9]{1}[0-9]{9}$/.test(formValues.MobileNo)) { message = '' }
                else if (formValues[field].length === 0) { message = '' }
                else message = 'Please enter valid mobile number';
                break;
            case 'EmailId':
                if (formValues[field].length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues[field])) {
                    message = '';
                }
                else if (formValues[field] === "") { message = '' }
                else { message = 'Please enter valid Email ID' }
                break;
            default:
                break;
        }
        return message
    }

    useEffect(() => {
        if (values_store && (values_store.TicketID || values_store.LeadID || values_store.MobileNo || values_store.EmailId) && !screenRendered) {
            handleSearch(null, true);
            setScreenRendered(true);
        }
    }, [values_store])

  const validate = () =>{
    if(!formValues.TicketID && !formValues.LeadID && !formValues.MobileNo && !formValues.EmailId){
       return Toast.error("Atleast 1 field value is required")
    }
    else if ( hasError('MobileNo') || hasError('EmailId')){
        return
    }
    else return true;
  }

  const handleSearch = (e, directSearch=false) =>{
      if(e){ e.preventDefault()}
      setShowDataToRender(false);
      console.log('directSearch-->', directSearch)
      if(!directSearch){
        if(!validate()){ return }
      }
      let url = '/GetAgentTicketsList';
      let payload = generatePayload();
      !directSearch && dispatch(setAgentSimpleSearchData(formValues))
      let callback = (value) =>{
        setDataToRender(value);
        setDataLoading(false);
      }
      setDataLoading(true);
      fetchDashboardData_method(url, payload, callback);
  }

  const clearFields = function(){
    let tempFields = {
      TicketID: "",
      LeadID: "",
      MobileNo: "",
      EmailId: "",
    };
    setFormValues(tempFields);
    dispatch(setAgentSimpleSearchData(tempFields));
    setDataToRender(null);
  }

  const checkIfSearchDisabled = CheckIfEndPointIsDisabled(disabledEndPoints_store, 'GetAgentTicketsList')

  return (
    <div className={classes.root}>
      {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white'/></CustomBackdrop> : null}
      <div className="pbdashboard-simple-seach agent-summary-list">
        <form onSubmit={handleSearch}>
        <ul>
          {checkIfSearchDisabled && <div className="access-info">You do not have Search Access</div>}
          <li>
            <FormControl className="form-control">
              <TextField
                  id="outlined-full-width"
                  placeholder="Ticket ID"
                  fullWidth
                  variant="outlined"
                  name = 'TicketID'
                  value={formValues.TicketID}
                  onChange={handleChange}
                  disabled={checkIfSearchDisabled}
              />
            </FormControl>
          </li>
          <li>
            <FormControl className="form-control">
              <TextField
                  id="outlined-full-width"
                  placeholder="Lead ID"
                  fullWidth
                  variant="outlined"
                  name='LeadID'
                  value={formValues.LeadID}
                  type="nnumber"
                  onChange={handleChange}
                  disabled={checkIfSearchDisabled}
              />
            </FormControl>
          </li>
          <li>
            <FormControl className="form-control">
              <TextField
                  id="outlined-full-width agentMobileNo"
                  placeholder="Mobile No"
                  fullWidth
                  variant="outlined"
                  name='MobileNo'
                  value={formValues.MobileNo}
                  onChange={handleChange}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max:9999999999 } }}
                  error={hasError("MobileNo") ? true : false}
                  disabled={checkIfSearchDisabled}
              />
              {hasError('MobileNo') && <FormHelperText className="error-msg">{hasError('MobileNo')}</FormHelperText>}
            </FormControl>
          </li>
          <li>
            <FormControl className="form-control">
              <TextField
                  id="outlined-full-width agnetEmailID"
                  placeholder="Email ID"
                  fullWidth
                  type='email'
                  variant="outlined"
                  name='EmailId'
                  value={formValues.EmailId}
                  onChange={handleChange}
                  error={hasError("EmailId") ? true : false}
                  disabled={checkIfSearchDisabled}
              />
            </FormControl>
            {hasError('EmailId') && <FormHelperText className="error-msg">{hasError('EmailId')}</FormHelperText>}
          </li>
          <li>
            <Button id="agentExportBtn" className="export-btn" type='submit' onClick={handleSearch} disabled={checkIfSearchDisabled}>Go</Button>
            <Button id="agentClearBtn" className="clear-btn" onClick={()=>clearFields()} disabled={checkIfSearchDisabled}>Clear All</Button>
          </li>
          </ul>
        </form>
        <div>
       </div>
          {!showDataToRender && dataToRender && Array.isArray(dataToRender) && <AgentDashboardData dataToRender={dataToRender} fileNameForExport="SimpleSearch_TicketList"/>}
       </div>
    </div>
  );
};

export default AgentSimpleSearch;
