import { sub } from 'date-fns'
import { ahcIssue, ahcSubissue, AHC_SUPPLIERS, INSURER_USERID_ALLOWED_FOR_TICKETS, isEndorsementEligible, ProductWiseEndorsementIssueSubIssueMapping, ENDORSEMENT_ELIGIBLE_PRODUCT_OPEN_TICKET_OLD_VIEW, ProductWiseDispatchRequestIssueSubIssueMapping, INSURER_ESCALATION_BUCKET_PRODUCT_SUPPLIER_MASTER } from '../../Config';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

export const getReadableDate = (time) => {
  const dateArr = new Date(time).toDateString().split(' ')
  const readableDate = `${dateArr[2]} ${dateArr[1]} ${dateArr[3]}`
  return readableDate
}

export const getTimeIn12HourFormate = (time) => {
  let [hr, min] = time.split(':').map((item) => parseInt(item))
  let newTime
  if (hr > 12) {
    hr = hr - 12
    hr = hr < 10 ? '0' + hr : hr
    newTime = hr + ':' + (min < 10 ? '0' + min : min) + 'PM'
  } else {
    hr = hr < 10 ? '0' + hr : hr
    newTime = hr + ':' + (min < 10 ? '0' + min : min) + 'AM'
  }
  return newTime
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const secondTodhm = (t) => {
  if (t === 0) return 0
  var cd = 24 * 60 * 60,
    ch = 60 * 60,
    d = Math.floor(t / cd),
    h = Math.floor((t - d * cd) / ch),
    m = Math.round((t - d * cd - h * ch) / 60),
    pad = function (n) {
      return n < 10 ? '0' + n : n
    }
  if (m === 60) {
    h++
    m = 0
  }
  if (h === 24) {
    d++
    h = 0
  }

  return [d + ' days', pad(h) + ' h', pad(m) + ' m'].join(':')
}

export const convertToIsoFormate = (time) => {
  const newVal = new Date(time).toLocaleString('en-US', {
    timeZone: 'Asia/Kolkata',
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
  const arr = newVal.split(',')
  return getReadableDate(arr[0] + arr[1]) + arr[2]
}
export const returnChatDate = (time) => {
  const date = new Date(time)
  const today = new Date()
  var prev_date = new Date()
  prev_date.setDate(prev_date.getDate() - 1)
  if (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth()
  ) {
    return 'Today'
  } else if (
    date.getDate() == prev_date.getDate() &&
    date.getMonth() == prev_date.getMonth()
  ) {
    return 'Yesterday'
  } else {
    const newVal = date.toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata',
      month: 'short',
      year: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    const arr = newVal.split(',')
    return getReadableDate(arr[0] + arr[1])
  }
}

export const convertToIsoFormateLogActivity = (time) => {
  const newVal = new Date(time).toLocaleString('en-US', {
    timeZone: 'Asia/Kolkata',
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
  const arr = newVal.split(',')
  return getReadableDate(arr[0] + arr[1]) + arr[2]
}

export const getFileExtension = (filename) => {
  return filename.split('.').pop()
}

// export const getToken = () => {
//   // return localStorage.getItem('Token') || localStorage.getItem('WecareToken');
//   let name = "TicketToken" + "=";
//   let decodedCookie = decodeURIComponent(document.cookie);
//   let ca = decodedCookie.split(';');
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

export const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const setCookie = (name, value, hours) => {
  name = name || 'TicketToken'
  if (document) {
    let expires = ''
    if (hours) {
      var date = new Date()
      date.setTime(date.getTime() + hours * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }

    if (
      document.URL.includes('localhost') ||
      document.URL.includes('0.0.0.0')
    ) {
      document.cookie = name + '=' + (value || '') + expires + '; path=/'
    } else
      document.cookie =
        name +
        '=' +
        (value || '') +
        expires +
        ';path=/; domain=.policybazaar.com'
  }
}

const eraseCookie = (name) => {
  if (document) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }
}

export const STORAGE = {
  setAuthToken: (token) => {
    setCookie('TicketToken', token, 8) // 8 hours
    return Promise.resolve(true)
  },
  getAuthToken: () => {
    return Promise.resolve(getCookie('TicketToken'))
  },
  deleteAuth: () => {
    eraseCookie('TicketToken')
    return Promise.resolve(true)
  },
}

export const validateLeadId = (leadId) => {
  if (leadId == '0') return ''

  if (!leadId) {
    return 'Required'
  } else if (!/^[0-9]+$/.test(leadId)) {
    return 'Lead Id must be number'
  } else if (leadId.length < 7 || leadId.length > 14) {
    return 'LeadId should be 7 to 14 digit number'
  } else {
    return ''
  }
}

export const validateEmail = (email) => {
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  return re.test(String(email).toLowerCase())
}

export const handleDrag = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

export const validatePolicyNumber = (policyNumber) => {
  if (policyNumber == '0') return ''

  if (!policyNumber) {
    return 'Required'
  } else if (policyNumber.length > 26) {
    return 'Policy/Application Number cannot be greater than 26 digits'
  } else {
    return ''
  }
}

export const handleDragIn = (e) => {
  e.preventDefault()
  e.stopPropagation()
}
export const handleDragOut = (e) => {
  e.preventDefault()
  e.stopPropagation()
}
export const handleDrop = (e, setFiles, fileRef) => {
  e.preventDefault()
  e.stopPropagation()
  setFiles([])
  for (let i = 0; i < e.dataTransfer.files.length; i++) {
    const fileName = e.dataTransfer.files[i].name
    toBase64(e.dataTransfer.files[i])
      .then((base64) => {
        setFiles((prev) => [
          ...prev,
          {
            id: i,
            FileName: fileName,
            AttachemntContent: base64.split('base64,')[1],
          },
        ])
        if (e.dataTransfer.files.length === i - 1) {
          fileRef.current.value = ''
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const download = (filename, data) => {
  var uri = 'data:application/vnd.ms-excel;base64,' + data
  var downloadLink = document.createElement('a')
  downloadLink.href = uri
  downloadLink.download = filename

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const formatCurrency = (number) => {
  let val = number
  return val.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR',
  })
}

// export const showRenewalLeadIDBox = (subIssue, renewalLeadID) => {
//   let issues = ["changes required in policy copy"];
//   if (!subIssue || renewalLeadID === 0) {
//     return false;
//   } else if (issues.includes(subIssue.toLowerCase())) {
//     return true;
//   } else return false;
// };

export const showEndorsementDialogInTicketCreation = (passedIssue) => {
  let issueNames = [
    'changes required in policy details',
    //'renewal related query',
  ]
  let passedIssueLowerCase = passedIssue.toLowerCase()
  try {
    if (issueNames.includes(passedIssueLowerCase)) {
      return true
    } else return false
  } catch {
    return false
  }
}

export const showEndorsementDialogForTicket = (
  passedIssue,
  ticketDetails,
  formValues,
) => {
  let issueNames = [
    'changes required in policy details',
    'renewal related query',
  ]
  let passedIssueLowerCase = passedIssue.toLowerCase()
  try {
    if (
      (issueNames.includes(passedIssueLowerCase) &&
        !ticketDetails.ParentTicketID &&
        ticketDetails.EndorsementID === 0) ||
      ticketDetails.EndorsementID === '0'
    ) {
      if (passedIssueLowerCase === 'renewal related query') {
        return true
      } else if (
        passedIssueLowerCase === 'changes required in policy details' &&
        formValues.SubStatusName !== 'Customer Pendency'
      ) {
        return true
      } else return false
    } else return false
  } catch {
    return false
  }
}

export const CheckIfObjectInArrayObject = (obj, excludeKeys, arr) => {
  if (arr && arr.length == 0) {
    return false
  } else {
    // console.log('eslse')
    let objPresent = false
    let objKeys = Object.keys(obj)
    arr.forEach((item) => {
      let flag = 0
      objKeys.forEach((k) => {
        // console.log('key', k)
        if (!excludeKeys.includes(k) && item[k] === obj[k]) {
          flag += 1
        }
      })
      // console.log('flag lenghth', flag, objKeys.length-excludeKeys.length)
      if (flag === objKeys.length - [excludeKeys.length]) {
        objPresent = true
      }
    })
    if (objPresent) {
      return true
    } else return false
  }
}

// mask email id based on flag
export const MaskEmailID = (EmailId, readOnlyFlag) => {
  try {
    if (readOnlyFlag) {
      let email = EmailId
      let split = email.split('@')
      return (
        email.substr(0, 1) +
        new Array(split[0].length - 1).fill('x').join('') +
        '@' +
        split[1]
      )
    } else {
      return EmailId
    }
  } catch {
    return EmailId
  }
}

export const mailAttachments = (
  replyType,
  mailAttachments,
  selectedAttachments,
) => {
  let attachments = []
  if (replyType === 'forward') {
    if (mailAttachments && mailAttachments.length > 0) {
      attachments = mailAttachments
      if (selectedAttachments && selectedAttachments.length > 0) {
        selectedAttachments.forEach((item) => {
          let obj = {
            FileName: item.FileName,
            AttachemntContent: item.AttachemntContent,
            DocId: item.DocId || null,
            AttachmentURL: item.FileUrl || null
          }
          attachments.push(obj)
        })
      }
    } else if (selectedAttachments && selectedAttachments.length > 0) {
      selectedAttachments.forEach((item) => {
        let obj = {
          FileName: item.FileName,
          AttachemntContent: item.AttachemntContent,
          DocId: item.DocId || null,
          AttachmentURL: item.FileUrl || null
        }
        attachments.push(obj)
      })
    }
  } else {
    if (selectedAttachments && selectedAttachments.length > 0) {
      selectedAttachments.forEach((item) => {
        let obj = {
          FileName: item.FileName,
          AttachemntContent: item.AttachemntContent,
          DocId: item.DocId || null,
          AttachmentURL: item.FileUrl || null
        }
        attachments.push(obj)
      })
    }
  }
  return attachments || []
}

export const customInputBoxErrorMessage = (
  fieldName,
  formValues,
  ticketDetails,
) => {
  switch (fieldName) {
    case 'IssueTypeId': {
      if (
        isEndorsementEligible(ticketDetails.ProductId, ticketDetails?.SupplierId, ticketDetails?.InvestmentTypeID) &&
        // (formValues.IssueName.toLowerCase() ===
        //   'changes required in policy details' || //Endorsement Dialog is shown if  condition matches IssueName
        //   (formValues.IssueName.toLowerCase() === 'renewal related query' &&
        //     formValues.SubIssueName.toLowerCase() ===
        //       'make changes to policy before renewal')) &&
        checkProductIssueSubIssue(ticketDetails?.ProductId, formValues?.IssueName, formValues?.SubIssueName) && 
        formValues.SubStatusName.toLowerCase() !== 'customer pendency' &&
        !ticketDetails.ParentTicketID &&
        (ticketDetails.EndorsementID === '0' ||
          Number(ticketDetails.EndorsementID) === 0) && formValues?.LeadID
      ) {
        return 'Either raise Endorsement or change Issue type'
      } else return 'Required'
    }
    case 'SubIssueID': {
      if (
        formValues.IssueName &&
        formValues.IssueName.toLowerCase() === ahcIssue &&
        formValues.SubIssueName &&
        formValues.SubIssueName.toLowerCase() === ahcSubissue &&
        !ticketDetails.AHCId && 
        AHC_SUPPLIERS.includes(formValues.SupplierId)
      ) {
        return 'Either raise AHC or change sub issue'
      } else return 'Required'
    }
    default:
      return
  }
}

export const openTriggerInputDialog = (triggerList, cb) =>{
  if(triggerList && triggerList.length>0){
    cb(true)
  }
  else cb(false)
}

export const showTopbarTicketForInsurer = (userID) =>{
  if(INSURER_USERID_ALLOWED_FOR_TICKETS.includes(userID)){
    return true;
  }
  else return false;
}

export const getUniqueSetBasedOnKey = ( array, key, objFields= []) => {
  let tempArray = [];
    array.length > 0 &&
    array.map((item) => {
        let flag = 0;
        let obj = {};
        if (tempArray && tempArray.length > 0) {
          tempArray.forEach((singleItem) => {
            if (singleItem[key] == item[key]) {
              return (flag = 1);
            }
          });
        }
        if (flag == 0) {
          objFields.forEach(i=>{
            return obj[i] = item[i]
          })
          if(Object.keys(obj)>0){
            tempArray.push(obj);
          }
          else tempArray.push(item)
          
        }
      });
    return tempArray
}

export const getSubStatusList = ( arrayFromParent, key, objFields= [], filter) => {
  if(!filter) { return []}
  else{
    let tempArray = [];
    let array = arrayFromParent.filter(item=> item.StatusID === filter);
    array.length > 0 &&
    array.map((item) => {
        let flag = 0;
        let obj = {};
        if (tempArray && tempArray.length > 0) {
          tempArray.forEach((singleItem) => {
            if (singleItem[key] == item[key] && singleItem['StatusID'] === filter) {
              return (flag = 1);
            }
          });
        }
        if (flag == 0) {
          objFields.forEach(i=>{
            return obj[i] = item[i]
          })
          if(Object.keys(obj)>0){
            tempArray.push(obj);
          }
          else tempArray.push(item)
          
        }
      });
    return tempArray
  }
  
}

export const exportDataToExcel = (dataArray, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const csvData = dataArray;
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const getProductListFromProductGroupMasterV2 = (list, group = null) =>{
  if(list && list.length > 0){
    let arr = [];
    list.filter(item1 => group ? item1.ProductGroupId === group : item1).forEach(item2=>{
      if(item2.Products){
        arr.push(...item2.Products)
      }
    })
    return arr;
  }
  else return []
} 

export const DiffDaysBtwDates = (date1, date2) => {
  const date1Time= new Date(date1).getTime()
  const date2Time=new Date(date2).getTime()
  var diff= Math.abs(date1Time-date2Time)
  return diff/(1000*60*60*24)
}

export const CheckIfEndPointIsDisabled = (arr, endpoint) =>{
  return arr && arr.includes(endpoint) ? true : false
}

export const checkProductIssueSubIssue = (productID, issue, subIssue) => {
  let mapping = ProductWiseEndorsementIssueSubIssueMapping();
  let flag = false;
  if (mapping[productID]) {
    let obj = mapping[productID];
    let issueLowerCase = issue ? issue.toLowerCase() : null;
    let subIssueLowerCase = subIssue ? subIssue.toLowerCase() : null
    if (issueLowerCase && obj[issueLowerCase]) {
      let subIssueArray = obj[issueLowerCase];
      if (subIssueArray?.includes(subIssueLowerCase)) {
        flag = true
      }
      else flag = false
    }
    else if (Object.keys(obj)?.includes(issueLowerCase)) {
      flag = true
    }
    else flag = false
  }
  else flag = false
  return flag
}

export const openNewView = (productID) =>{
  return ENDORSEMENT_ELIGIBLE_PRODUCT_OPEN_TICKET_OLD_VIEW.includes(productID) ? false : true
}

export const checkIfValidDate = (date) =>{
  if(date){
    return date instanceof Date && !isNaN(date) ? true : false;
  }
  else return false
}

export const sortDataKeyDateDesc =( array, key ) =>{
  let sortedArray;
  if(array && array.length>1){
      sortedArray=array.sort((a,b)=>new Date(b[key])- new Date(a[key]));
    }
  else sortedArray=array;
  return sortedArray;
}

export const checkIssueSubIssueForDispatchRequest = (productID, issue, subIssue) => {
  let mapping = ProductWiseDispatchRequestIssueSubIssueMapping();
  console.log('ProductWiseEndorsementIssueSubIssueMapping', mapping)
  let flag = false;
  if (mapping[productID]) {
    let obj = mapping[productID];
    let issueLowerCase = issue ? issue.toLowerCase() : null;
    let subIssueLowerCase = subIssue ? subIssue.toLowerCase() : null
    if (issueLowerCase && obj[issueLowerCase]) {
      let subIssueArray = obj[issueLowerCase];
      if (subIssueArray?.includes(subIssueLowerCase)) {
        flag = true
      }
      else flag = false
    }
    else if (Object.keys(obj)?.includes(issueLowerCase)) {
      flag = true
    }
    else flag = false
  }
  else flag = false
  return flag
}

export const ValidProductSupplier = (product, insurer, mapping)  => {
  let m = mapping || INSURER_ESCALATION_BUCKET_PRODUCT_SUPPLIER_MASTER
  if(m && m[product]){
    return m[product].includes(insurer) ? true : false
  }
  else return false
}
