import { Grid, InputLabel, Select, FormControl, TextField, Button, FormHelperText } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useReducer, useRef, useState } from 'react'
import CKEditor from 'ckeditor4-react';
import { COMMON_CK_EDITOR_CONFIG, DisplayName, From } from '../../../../Config';
import CustomBackdrop from '../../../../pages/Components/CustomBackdrop';
import { handleDrag, handleDragIn, handleDragOut, handleDrop, openTriggerInputDialog, validateEmail } from '../../../../shared/methods/methods';
import Spinner from '../../../../shared/components/Spinner';
import FileUploadComponent from '../../../../shared/components/FileUploadComponent';
import CloseIcon from '@material-ui/icons/Close'
import { Toast } from '../../../../shared/components';
import ConfirmationDialog from '../../../../shared/components/ConfirmationDialog';
import { useSelector } from 'react-redux';
import { GetHtmlTemplate_V2, UpdateTicketRemarks } from '../../../../constants/apiUrls';
import { POST } from '../../../../services/apiServices';
import { handleCatch } from '../../../../shared/methods/apiMethods';
import { ClockLoader } from 'react-spinners';
import CommunicationDialogForTriggers from '../TicketsTimelines/Components/CommunicationDialogForTriggers';
const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: "100%",
    },
}));


function ComposeEmail(props) {
    const { fetchLatestInsurerMails, handleCloseCompose } = props;
    const userInfo_store = useSelector(state => state.auth.userInfo || {})
    const ticketDetails_store = useSelector(state => state && state.ticketdetails && state.ticketdetails.ticketDetails || {})
    //for fetching templateList from redux store
    const templateListInsurer_store = useSelector(state => state && state.ticketdetails && state.ticketdetails.insurerTemplateList || [])
    const [isCommunicationDialogOpen, setIsCommunicationDialogOpen] = useState(false);
    const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
    const [triggerBoxOpenedFirstTime, setTriggerBoxOpenedFirstTime] = useState(false) // for setting the default payload in case communication box is opened which takes trigger
    const [triggerInput, setTriggerInput] = useState([])
    const [copiedTriggerInputs, setCopiedTriggerInputs] = useState([]);
    const [typeInParent, setTypeInParent] = useState("");
    const [values, setValues] = useState([]);

    const [state, setState] = useReducer(
        (data, newData) => ({ ...data, ...newData }), {
        selectedTemplate: 'select',
        templateLoading: false,
        emailContent: '',
        requestIDEmailTemplate: '',
        isCKEDITORLoaded: false,
        CKEditorReadOnly: false,
        submitLoader: false,
        showConfirmationBox: false,
        EmailSignature: '',
        ToEmailAddress: '',
        SubmitLoader: '',
        Subject: ''
    }
    )
    const [selectedFileToUpload, setSelectedFileToUpload] = useState([])

    const hiddenFileInput = useRef();
    const classes = useStyles();

    useEffect(() => {
        if (userInfo_store) {
            let name = `${userInfo_store.Name} (${userInfo_store.EmployeeID})`
            let signatureHTML = `<p>Thanks &amp; regards,<br />\n${name}<br />\nPolicyBazaar.com</p>\n`
            // setState({ EmailSignature: signatureHTML });
        }
    }, [userInfo_store])

    useEffect(() => {
        setState({ CKEditorReadOnly: false, emailContent: '', ToEmailAddress: '', ToEmailAddressError: '' })
        if (state.selectedTemplate && state.selectedTemplate !== 'select') {
            fetchTemplate();
            setTriggerInput(templateListInsurer_store && templateListInsurer_store.filter((i) => i.TriggerName == state?.selectedTemplate)[0]?.TriggerInput)
        }
        else {
            setTriggerInput([])
        }
        setTriggerBoxOpenedFirstTime(true);
        setCopiedTriggerInputs([]);
        setValues([]);
        setTypeInParent("");
    }, [state.selectedTemplate])

    useEffect(() => {
        if (values && values.length > 0 && typeInParent) {
            fetchTemplate();
        }
    }, [values])

    const fetchTemplate = () => {
        setIsTemplateLoaded(true);
        setState({ templateLoading: true })
        let tempFilteredTemplateList = templateListInsurer_store && templateListInsurer_store.filter((i) => i.TriggerName == state?.selectedTemplate)[0]?.TriggerInput || [];
        let RequiredPayloadTriggerNames = [];
        if (tempFilteredTemplateList && tempFilteredTemplateList.length > 0) {
            RequiredPayloadTriggerNames = tempFilteredTemplateList.map(item => item.InputName)
        }
        let RequiredTriggerWithValues = {};
        RequiredPayloadTriggerNames && RequiredPayloadTriggerNames.length > 0 && RequiredPayloadTriggerNames.forEach(trigger => {
            RequiredTriggerWithValues[trigger] = Array.isArray(values) && values.length > 0 ? values.map(item => item[trigger]).filter(item => item).join(",") : triggerBoxOpenedFirstTime ? `<${trigger}>` : "";
        })
        let endpoint = GetHtmlTemplate_V2
        let payload = {
            'TemplateCategory': 'TICKETINSURER',
            'triggerName': state.selectedTemplate,
            'leadID': ticketDetails_store?.LeadID,
            'ticketId': ticketDetails_store?.TicketId,
            'communicationType': 1,
            'productId': ticketDetails_store?.ProductId,
            'refObjectId': ticketDetails_store?.RefObjectId,
            "IsMultipleInput": values && values.length > 1 ? true : false,
            "triggerInput": {
                ...RequiredTriggerWithValues,
            },
            "TATType": typeInParent ? typeInParent : "",
        }
        POST(endpoint, payload).then(res => {
            setState({ templateLoading: false })
            setIsCommunicationDialogOpen(false)
            setCopiedTriggerInputs([...values]);
            setValues([])
            setIsTemplateLoaded(false)
            if (res && res.ErrorCode === 0) {
                setState({ emailContent: res.Data.HtmlBody, requestIDEmailTemplate: res.Data.RequestId, Subject:  res.Data.Subject || ticketDetails_store.ConversationTopic })
            }
            else {
                Toast.error(res['Message'] || 'Failed')
                setState({ selectedTemplate: 'select' })
            }
        }).catch(err => {
            handleCatch(err);
            setState({ selectedTemplate: 'select', templateLoading: false })
            setIsTemplateLoaded(false)
            setIsCommunicationDialogOpen(false)
        })
    }

    const onEditorChange = (e) => {
        console.log('event not==>', e.editor.getData())
        setState({ emailContent: e.editor.getData() })
    }

    const handleRemoveFile = (id) => {
        const fileList = selectedFileToUpload.filter((item) => item.id !== id)
        setSelectedFileToUpload(fileList)
    }

    const openConfirmation = () => {
        if (triggerInput && triggerInput.length > 0 && copiedTriggerInputs && !validateTriggersInputs()) {
            return Toast.error("Please update all fields to continue")
        }
        else setState({ showConfirmationBox: true })
    }

    const validateTriggersInputs = () => {
        let flag = true;
        let keys = triggerInput.map(item => item.InputName)
        if (copiedTriggerInputs && copiedTriggerInputs.length > 0 && copiedTriggerInputs[0]) {
            Object.keys(copiedTriggerInputs[0]).forEach(item => {
                if (!copiedTriggerInputs[0][item]) {
                    flag = false;
                }
            })
            if (Object.keys(copiedTriggerInputs[0]).length !== keys.length) {
                flag = false;
            }
        }
        else flag = false;

        if (flag) {
            return true
        }
        else return false
    }

    const sendMail = () => {
        if (state.submitLoader) {
            return
        }
        let endpoint = `${UpdateTicketRemarks}/${state.selectedTemplate ? state.selectedTemplate : templateListInsurer_store && templateListInsurer_store.length ? templateListInsurer_store[0]?.TriggerName : 'DefaultTemplate'}`
        const payload = {
            'IsInsurerEmail': 1,
            'PrevObjectID': ticketDetails_store.RefObjectId,
            'TicketId': ticketDetails_store.TicketId,
            'TicketDetailId': ticketDetails_store.TicketDetailId,
            'Subject': ticketDetails_store.ConversationTopic,
            'ConversationTopic': state.Subject,
            'LeadID': ticketDetails_store.LeadID,
            'CustomerName': ticketDetails_store.CustomerName,
            'CustomerID': ticketDetails_store.CustomerId,
            'ProductID': ticketDetails_store.ProductId,
            'ProductName': ticketDetails_store.ProductName,
            'IsSpam': false,
            'IsManualComment': false,
            'From': From,
            'CCEmail': '',
            'ToReceipent': [state.ToEmailAddress],
            'Body': state.emailContent,
            'IsMoved': false,
            'MailAttachments':
                selectedFileToUpload.length > 0
                    ? selectedFileToUpload.map((item) => ({
                        FileName: item.FileName,
                        AttachemntContent: item.AttachemntContent,
                    }))
                    : [],
            'HasAttachment': selectedFileToUpload.length > 0 ? true : false,
            'DisplaySenderName': DisplayName,
            'TicketSource': ticketDetails_store.Source,
            'ActionType': 0,
            'StatusName': ticketDetails_store.Status,
            'SubStatusName': ticketDetails_store.SubStatusName,
            'InsurerID': ticketDetails_store.SupplierId,
            'StatusID': ticketDetails_store.StatusID,
            'SubStatusID': ticketDetails_store.SubStatusID,
            'TicketSubSource': '',
            'RequestId': state.requestIDEmailTemplate,
            'EscalationType': ticketDetails_store.EscalationType,
            'IssueTypeName': ticketDetails_store.IssueName,
            'TicketDetailsID': ticketDetails_store.TicketDetailsID,
            'IsJAG': ticketDetails_store && ticketDetails_store.IsJAG ? ticketDetails_store.IsJAG : 0,
            'JAGEmployeeId': ticketDetails_store && ticketDetails_store.JAGEmployeeId
                ? ticketDetails_store.JAGEmployeeId
                : '',
            'IssueTypeID':
                ticketDetails_store && ticketDetails_store.IssueTypeId
                    ? ticketDetails_store.IssueTypeId
                    : 0,
            //   'CxUnreachable': customerUnreachable || false, 
        }
        setState({ submitLoader: true })
        POST(endpoint, payload).then((res) => {
            setState({ submitLoader: false })
            if (res.ErrorCode === 0) {
                Toast.success(res.Message || 'Success');
                fetchLatestInsurerMails();
                handleCloseCompose();

            } else {
                Toast.error(res.Message || 'Failed')
            }
        })
            .catch((err) => {
                setState({ submitLoader: false })
                handleCatch(err)
            })
    }

    const handleToEmailAddressChange = (e) => {
        const emails = e.target.value.split(",");
        let isErrMsg = false;
        emails.forEach((email) => {
            const trimmedEmail = email.trim();
            if (!validateEmail(trimmedEmail)) {
                setState({ ToEmailAddressError: 'Invalid email' })
                isErrMsg = true;
                return;
            } else {
                isErrMsg = false;
            }
        }
        )
        if (!isErrMsg || !e.target.value) {
            setState({ ToEmailAddressError: '' })
        }
        setState({ ToEmailAddress: e.target.value })
    }

    const checkIfButtonDisabled = () => {
        if (state.ToEmailAddressError || state.selectedTemplate === 'select' || !state.ToEmailAddress) {
            return true
        }
        else return false
    }

    return (
        <div>
            {state.templateLoading && <CustomBackdrop open={state.templateLoading}><ClockLoader color="white" /></CustomBackdrop>}
            <div
                className="reply-popup"
                onDragOver={handleDrag}
                onDrop={(e) => handleDrop(e, setSelectedFileToUpload, hiddenFileInput)}
                onDragLeave={handleDragIn}
                onDragEnter={handleDragOut}
            >
                <Grid container spacing={1}>
                    {!state.isCKEDITORLoaded && <CustomBackdrop open={!state.isCKEDITORLoaded} textToShow='Loading Editor...' />}
                    <Grid item md={4} sm={4} xs={12}>
                        <FormControl variant="outlined" className={classes.formControl} >
                            <InputLabel htmlFor="outlined-age-native-simple">
                                Can Response
                            </InputLabel>
                            <Select
                                native
                                value={state.selectedTemplate}
                                onChange={(e) => setState({ selectedTemplate: e.target.value })}
                                label="Can Response"
                                inputProps={{
                                    name: 'canResponse',
                                    id: 'outlined-age-native-simple canResponse',
                                }}
                            >
                                <option value="select">select</option>
                                {templateListInsurer_store.map((item) => {
                                    return (
                                        <option id="TriggerName" value={item.TriggerName}>
                                            {item.Description}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container className="mail-to-info compose-mail-box">
                        <Grid item md={12} sm={12} xs={12} className="border-bottom">
                            <strong className="mail-to-input">
                                <span id="mailInfoEmailId">
                                    <FormControl>
                                        <label className="label-to">To:</label>
                                        <TextField
                                            disabled={state.selectedTemplate === 'select'}
                                            value={state.ToEmailAddress}
                                            onChange={handleToEmailAddressChange}
                                        />
                                        {state.ToEmailAddressError && <FormHelperText className='error-msg'>{state.ToEmailAddressError}</FormHelperText>}
                                    </FormControl>
                                </span>
                            </strong>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12} className="border-bottom">
                            <strong className="mail-to-input mail-to-subject-input">

                                <span id="mailInfoConversationTopic">
                                    <FormControl>
                                        <label className="label-to">Subject:{' '}</label>
                                        <TextField
                                            disabled={state.selectedTemplate === 'select'}
                                            value={state.Subject}
                                            onChange={(e) => setState({ Subject: e.target.value })}
                                        />
                                    </FormControl>
                                </span>
                            </strong>
                        </Grid>
                        <Grid item md={12} xs={12} className="reply-box">
                            <div className='update-field'>
                                {triggerInput && triggerInput.length > 0 ? <Button onClick={() => openTriggerInputDialog(triggerInput, setIsCommunicationDialogOpen)} className='update-field-btn'>Update Field</Button> : null}
                            </div>
                            <CKEditor
                                data={state.emailContent ? state.emailContent : ""}
                                config={{
                                    ...COMMON_CK_EDITOR_CONFIG,
                                    allowedContent: true,
                                }}
                                onChange={(event) => {
                                    onEditorChange(event)

                                }}
                                onInstanceReady={(instance) => {
                                    instance.data = state.emailContent
                                    setState({ isCKEDITORLoaded: true })
                                }}
                                name="Email-CK-EDITOR_SINGLE_InsurerActivity"
                                id="Email-CK-EDITOR_SINGLE_InsurerActivity"
                                readOnly={state.isCKEDITORLoaded ? state.CKEditorReadOnly : false}
                            />
                        </Grid>
                    </Grid>
                    <div className="action-bar compose-box-action-bar">
                        <Grid container>
                            <Grid item md={8} xs={6}>
                                <p className="attach-btn" id="actionBarAttachement">

                                    <FileUploadComponent
                                        files={selectedFileToUpload}
                                        isMultipleFileAllowed={true}
                                        setFiles={setSelectedFileToUpload}
                                        fileRef={hiddenFileInput}
                                    />
                                    {selectedFileToUpload?.map((item) => {
                                        return (
                                            <span className="file-detail" key={item.id}>
                                                {item.FileName}{' '}
                                                <button onClick={() => handleRemoveFile(item.id)}>
                                                    <CloseIcon />
                                                </button>
                                            </span>
                                        )
                                    })}
                                </p>
                            </Grid>
                            {console.log(checkIfButtonDisabled())}
                            <Grid item md={4} xs={6}>
                                <Button
                                    className=""
                                    color="primary"
                                    disabled={checkIfButtonDisabled()}
                                    onClick={openConfirmation}
                                    id="actionBarSendBtn"
                                >
                                    {state.submitLoader ? <Spinner /> : 'Send'}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <ConfirmationDialog
                    isConfirmDialogOpen={state.showConfirmationBox}
                    setConfirmDialogOpen={(val) => setState({ showConfirmationBox: val })}
                    updateFn={sendMail}
                />
                {isCommunicationDialogOpen && triggerInput && triggerInput.length > 0 ? (
                    <CommunicationDialogForTriggers
                        isDialogOpen={isCommunicationDialogOpen}
                        setDialogOpen={setIsCommunicationDialogOpen}
                        triggerInput={triggerInput}
                        canResponse={state?.selectedTemplate}
                        valuesInParent={values}
                        setValuesInParent={setValues}
                        setCanResponse={(val) => setState({ selectedTemplate: val, Subject: "", requestIDEmailTemplate: "", Subject: "" })}
                        isTemplateLoaded={isTemplateLoaded}
                        setIsTemplateLoaded={setIsTemplateLoaded}
                        previousValues={copiedTriggerInputs}
                        setTypeInParent={setTypeInParent}
                        typeInParent={typeInParent}
                    />
                ) : (
                    ''
                )}
            </div>
        </div >
    )
}

export default ComposeEmail