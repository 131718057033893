import React from 'react';
import { useSelector } from 'react-redux';
import { PB_ADMIN } from '../Config';
import ChildErrorBoundary from '../shared/components/ChildErrorBoundary';
import PBAgentDashboard from './DashboardPB/Agent/PBAgentDashboard';
import PBAdminDashboardV2 from './DashboardPB/AdminV2/PBAdminDashboardV2';


export default function PBDashboardV2() {
    const AccessType_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.AccessType)
    return (
        AccessType_store === PB_ADMIN ? <ChildErrorBoundary>
            <PBAdminDashboardV2/>
        </ChildErrorBoundary>
            : <ChildErrorBoundary>
                <PBAgentDashboard />
            </ChildErrorBoundary>
    )
}
