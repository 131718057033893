import React, { useEffect, useState, useReducer } from 'react'
import { ListItem, List, Button,FormControl,InputLabel,Select,MenuItem, FormHelperText,Checkbox,ListItemText } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
import { GET } from '../../../../services/apiServices';
import { useDispatch, useSelector } from 'react-redux'
import { fetchMasterList } from '../../../../redux/actions/common_action';
import { useSnackbar } from "notistack";
import { exportDashboardData_method, handleCatch } from '../../../../shared/methods/apiMethods'
import { TAT_SUMMARY_DASHBOARD_TYPE } from '../../../../Config'
import CustomBackdrop from '../../../Components/CustomBackdrop';
import { HashLoader } from 'react-spinners';
import { getUniqueSetBasedOnKey } from '../../../../shared/methods/methods';
import { Toast } from '../../../../shared/components';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
  },
}))

const AdminTATSummary = ({ isActionsAllowed }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const productSupplierMaster = useSelector(state => state && state.common && state.common.productSupplierMaster);
  const [ticketCount, setTicketCount] = useState(null)
  const [productList, setProductList] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [formValues, setFormValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ProductID : [],
      IsEsc : 0,
    }
  )

  const [formValuesTouched, setFormValuesTouched] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ProductID : false,
      IsEsc : false,
    }
  )

  
  const EscalationArray = [
    { DisplayName : "Yes", value : 1},
    { DisplayName : "No", value : 0},
  ]
  
  const showMessage =(message, varient) =>{
    return enqueueSnackbar(message, {variant: varient, onClick: () => {
        closeSnackbar();
      },
    });
  }

  useEffect(() => {
    if(productSupplierMaster && productSupplierMaster.length===0){
      dispatch(fetchMasterList())
    }
    else if(productSupplierMaster){
      setProductListsFromAPI();
    }
  }, [productSupplierMaster]);

  const setProductListsFromAPI = () =>{
    let arr = getUniqueSetBasedOnKey(productSupplierMaster, 'ProductID', ['ProductID', 'ProductName']);
    arr.splice(0, 0, { ProductID: 0, ProductName: 'NO PRODUCT' })
    setProductList(arr);
    // let productList = [];
    //   let obj;
    //   productSupplierMaster.length > 0 &&
    //     productSupplierMaster.map((item) => {
    //       let flag = 0;
    //       if (productList && productList.length > 0) {
    //         productList.forEach((singleItem) => {
    //           if (singleItem.ProductID == item.ProductID) {
    //             return (flag = 1);
    //           }
    //         });
    //       }
    //       if (flag == 0) {
    //         obj = {
    //           ProductID: item.ProductID,
    //           ProductName: item.ProductName,
    //         };
    //         productList.push(obj);
    //       }
    //     });
    //   setProductList(productList);
  }

  const handleChange =(e) =>{
    let { name, value} = e.target;
    setFormValues({[name] : value});
    setFormValuesTouched({[name] : true});
  }

  const getDashboardData = (type, value, fileName) => {
    debugger
    if(!value){ return Toast.error('No Data Available!') };
    setDataLoading(true);
    const payload = {
      "IsUpdatedOn": 0,
      "FilterString": "",
      "FromDate": 1635928641637,
      "ToDate": 1635928641637,
      "Source": "",
      "SubSource": "",
      "ProductID": 0,
      "SupplierID": 0,
      "CBRequest": 0,
      "StatusID": 0,
      "SubStatusID": 0,
      "TATFilter": 0,
      "InsurerStatusID": 0,
      "STP": -1,
      "IsTAT" : 1,
      "IsEsc": formValues.IsEsc,
      "Type": type,
      "ProductIDs": formValues.ProductID.toString(),
      "SendToReport": ""
    }
    let url = '/GetDashboardDataExport';
    let callback = () =>{
      setDataLoading(false)
    }
    exportDashboardData_method(url, payload, callback, fileName)
  }

  const handleLoadData = () =>{
    setFormValuesTouched({
      ProductID : true,
      IsEsc : true
    })
    if(formValues.ProductID.length === 0){ return };
    
    let url = `GetDashboardCount/${TAT_SUMMARY_DASHBOARD_TYPE}/${(formValues.ProductID).toString()}/${formValues.IsEsc || 0}`;
    setDataLoading(true);
    GET(url, null, 'Dashboard').then(res=>{
      setDataLoading(false);
      if(res && res && res.ErrorCode===0){
        setTicketCount(res.Data)
      }
      else showMessage(res.Message || "Failed to fetch details", "error");
    }).catch(err=>{
      setDataLoading(false);
      handleCatch(err, showMessage);
    })
  }



  return (
    <div className={classes.root}>
      {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white'/></CustomBackdrop> : null}
      <div className="pbdashboard-summary ">
        <div className="ticket-summary-list">
          {/* <h4>TAT Summary</h4> */}
          <List>
            <ListItem  id="tatSummaryIssueSubIssueMissing" onClick={() => getDashboardData(1, ticketCount && ticketCount.IssueSubIssueMissing || 0, 'IssueSubIssueMissing')}>
              <div className="new-ticket">
                <span>Issue Sub Issue Missing</span>
                <p>{ticketCount ? ticketCount.IssueSubIssueMissing : null}</p>
              </div>
            </ListItem>
            <ListItem id="tatSummaryTATBurstin24Hrs" onClick={() => getDashboardData(2, ticketCount && ticketCount.TATBurstin24Hrs || 0, 'TAT MissIn24Hours')}>
              <div className="due-ticket">
                <span>TAT Miss in next 24 hrs</span>
                <p>{ticketCount ? ticketCount.TATBurstin24Hrs : null}</p>
              </div>
            </ListItem>
            <ListItem id="tatSummaryAgentTATBreached" onClick={() => getDashboardData(3, ticketCount && ticketCount.AgentTATBreached || 0,'AgentTAT Missed')}>
              <div className="duetoday-ticket">
                <span>Agent TAT Missed</span>
                <p>{ticketCount ? ticketCount.AgentTATBreached : null}</p>
              </div>
            </ListItem>
            <ListItem id="tatSummaryL1TATBreached" onClick={() => getDashboardData(4, ticketCount && ticketCount.L1TATBreached || 0,'L1 TAT Missed')}>
              <div className="Unassigned-ticket">
                <span>L1 TAT Missed</span>
                <p>{ticketCount ? ticketCount.L1TATBreached : null}</p>
              </div>
            </ListItem>
            <ListItem id="tatSummaryL2TATBreached" onClick={() => getDashboardData(5, ticketCount && ticketCount.L2TATBreached || 0,'L2 TAT Missed')}>
              <div className="open-ticket">
                <span>L2 TAT Missed</span>
                <p>{ticketCount ? ticketCount.L2TATBreached : null}</p>
              </div>
            </ListItem>
            <ListItem id="tatSummaryPTBreached" onClick={() => getDashboardData(6, ticketCount && ticketCount.PTBreached || 0,'PTMissed')}>
                <div className="revert-ticket">
                  <span>PT Missed</span>
                  <p>{ticketCount ? ticketCount.PTBreached : null}</p>
                </div>
              </ListItem>
            {/* {isActionsAllowed && (
              <ListItem onClick={() => getDashboardData(6, ticketCount && ticketCount.PTBreached || 0)}>
                <div className="revert-ticket">
                  <span>PT Missed</span>
                  <p>{ticketCount ? ticketCount.PTBreached : null}</p>
                </div>
              </ListItem>
            )} */}
          </List>
        </div>
        {/*list */}
        <div className='tat-summary-box'>
          <ul>
            <li>
            <FormControl className="form-control" error={formValuesTouched.ProductID && formValues.ProductID.length===0}>
                <InputLabel id="demo-customized-select-label">
                  Select Product 
                </InputLabel>
                <Select
                  labelId="adminTATSummarySelectProduct"
                  value={formValues.ProductID}
                  multiple
                  onChange={handleChange}
                  renderValue={(selected) => selected && selected.length>0 && selected.map(val=>{
                   return productList.filter(item=> {
                      return item.ProductID === val
                    })[0].ProductName
                  }).join(',')
                  }
                  name="ProductID"
                  >
                  {
                    productList && productList.map((item) => (
                      <MenuItem key={item.ProductID} value={item.ProductID}>

                      <Checkbox 
                        checked={formValues.ProductID.indexOf(item.ProductID) > -1} 
                        />
                        <ListItemText primary={item.ProductName} />
                      </MenuItem>
                    ))}
                 {/* {productList && productList.length > 0 && productList.map(item => {
                 return <MenuItem key={`product+${item.ProductID}`} value={item.ProductID}>{item.ProductName}</MenuItem>
                })} */}
                </Select>
                {formValuesTouched.ProductID && formValues.ProductID.length===0 && <FormHelperText className='error-msg'>Please select product(s)</FormHelperText>}
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <InputLabel id="demo-customized-select-label">
                  Select Escalation
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select tatSummarySelectEscalation"
                  onChange={handleChange}
                  name="IsEsc"
                >
                  <MenuItem value={"0"}>Select</MenuItem>
                  <MenuItem value={"1"}>ALL</MenuItem>
                  {/* {EscalationArray.map(item=>{
                    return <MenuItem value={item.value}>{item.DisplayName}</MenuItem>
                  })} */}
                </Select>
              </FormControl>
            </li>
            <li>
              <Button id="tatSummaryExportBtn"  className="export-btn" onClick={handleLoadData}>Load Data</Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AdminTATSummary
