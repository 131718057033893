import { GET, POST } from "../../services/apiServices";
import * as type from "../types/types";
import { useSnackbar } from 'notistack';
import ErrorMessages  from '../../shared/constants';
import { ReduxActionGeneratorFunction } from "../../shared/methods/apiMethods";


export function setAgentSimpleSearchData(data){
    console.log('setting simple search data')
    return {
      type: type.SET_AGENT_SIMPLE_SEARCH_DATA,
      payload: data
    }
}

export const setAgentAdvanceSearchData = (payload) =>{
  return {
    type : type.SET_AGENT_ADVANCED_SEARCH_DATA,
    payload
  }
}

export const setAdminSimpleSearchData = ( payload ) =>{
  return {
    type : type.SET_ADMIN_SIMPLE_SEARCH_VALUE,
    payload
  }
}

export const setAdminAdvanceSearchData = ( payload ) =>{
  return {
    type : type.SET_ADMIN_ADVANCED_SEARCH_VALUES,
    payload
  }
}

export const setPageNumber = (payload) =>{
  return{
    type : type.PAGE_SELECTED,
    payload
  }
}

export const setDashboardOption = (payload) =>{
  return {
    type : type.SET_DASHBOARD_OPTION,
    payload
  }
}

export const resetAllSearchOptions = (payload) =>{
  return{
    type : type.RESET_SEARCH_OPTIONS,
    payload,
  }
}

export const setZeroLeadSelection = (payload) =>{
  return ReduxActionGeneratorFunction(type.SET_ZERO_LEAD_SELECTED, payload)
}
