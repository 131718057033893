import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import SideBar from "./Sidebar";
import PBDashboardHeader from "../../pages/DashboardPB/PBdashboardHeader/PBDashboardHeader";
import { VERSION } from "../../Config";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  shiftContent: {
  },
}));

export const AppContext = createContext();

const PBDashboardLayout = (props) => {
  const { children, componentName } = props;
  const classes = useStyles();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [agentStatus, setAgentStatus] = useState("")

  useEffect(() => {
    document.title = componentName
  }, [componentName])


  return (
    <AppContext.Provider value={{ agentStatus, setAgentStatus }}>
      <div className="top-headbar" >
        <PBDashboardHeader componentName={componentName} />
      </div>
      <main className="content-body">
        {children}
        <div className='layout-version'>Version : {VERSION}</div>
      </main>
    </AppContext.Provider>
  );
};

PBDashboardLayout.propTypes = {
  children: PropTypes.node,
};


export default PBDashboardLayout;