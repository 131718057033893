import React, { useEffect} from "react";
import { useHistory } from "react-router-dom";
import TablePagination from '@material-ui/core/TablePagination';
import AdminListItem from "./AdminListItemV2";

export default function AdminDashboardData({ dataToRender }) {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // data is changes then Page selected is also changed to default 0
  useEffect(()=>{
    setPage(0);
  },[dataToRender])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };



  return (
    <div className="agent-dashboard-box">
      <div className="agent-dashboard-box-inner">
        {dataToRender && dataToRender.length > 0 && <TablePagination
          component="div"
          count={dataToRender.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
      </div>
      <div className="load-data-list admin-data-list scrool-toll ">
      <div>
        {dataToRender && dataToRender.length > 0 ? (
          <>
            <ul className="head-list">
              <li>Created On</li>
              <li>Ticket ID</li>
              <li>Lead ID</li>
              <li>Product</li>
              <li>Source</li>
              <li>IssueName</li>
              <li>Sub Issue</li>
              <li>Ticket Status</li>
              <li>Assigned To</li>
              <li>Lead Status</li>
              <li>Due On</li>
              {/* <li>Ageing</li>
              <li>PolicyNo</li>
              <li>RegistrationNo</li> */}
              <li>InsurerStatusDate</li>
              {/* <li>ApplicationNo</li> */}
              <li>TAT</li>
            </ul>
            <div className="body-list">
              {dataToRender
              .slice((page+1)*rowsPerPage-rowsPerPage, (page+1)*rowsPerPage)
              .sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))
                .map((i, key) => {
                  return (
                    <AdminListItem key={'adminListItem'+key} i={i}/>
                  );
                })}
            </div>
          </>
        ) : (
          <div className="load-data-head">
            <div className="no-data-found">
              <img src="images/no_data_found.svg" alt="no_data_found" />
              <p>No Data Available</p>
            </div>
          </div>
        )}
      </div>
      {/* } */}
    </div>
    </div>
  );
}


