import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VERSION } from "../../Config";
import ED_Header from "../../pages/ExternalDashboard/ED_Header/ED_Header";

export const AppContext = createContext();

const EDLayout = (props) => {
  const { children, componentName } = props;
  const [agentStatus, setAgentStatus] = useState("")

  useEffect(() => {
    document.title = componentName
  }, [componentName])


  return (
    <AppContext.Provider value={{ agentStatus, setAgentStatus }}>
      <div className="top-headbar" >
        <ED_Header componentName={componentName} />
      </div>
      <main className="content-body">
        {children}
        <div className='layout-version'>Version : {VERSION}</div>
      </main>
    </AppContext.Provider>
  );
};

EDLayout.propTypes = {
  children: PropTypes.node,
};


export default EDLayout;