import React, { useReducer } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Button, Input, InputLabel, FormHelperText, Card, CardContent } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Spinner from "../../../shared/components/Spinner";
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import InfoIcon from '@material-ui/icons/Info';
import { POST } from "../../../services/apiServices";
import { handleCatch } from "../../../shared/methods/apiMethods";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'transparent',
        color: 'inherit',
        boxShadow: 'none',
        fontSize: 14,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        background: "#2196f314",
    },
    container: {
        padding: "20px",
        width: "400px",
        margin: "0 auto",
        maxWidth: "100%",
    },
    formControl: {
        width: "100%",
    },

    image: {
        backgroundImage: "url(./images/Artwork.svg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#DEEBFF",
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: "0.9",
        backgroundSize: "80%",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: "10px 8px",
        background: "#00458b",
        "&:hover": {
            background: "#00458b",
        },
    },
    logoImg: {
        width: "119px",
        textAlign: "center",
        marginBottom: "10px",
        border: "1px solid #ccc",
        borderRadius: "50%",
        padding: "3px",
        height: "114px",
        "& img": {
            maxWidth: "70%",
            margin: "13px",
        },
    },
    root: {
        height: "100vh",
        background: "#DEEBFF",
        position: "relative",
    },
    bgColor: {
        background: "#DEEBFF",
    },
}));

function PasswordChange({ resetOptions }) {
    const classes = useStyles();
    const [form, setForm] = useReducer(
        (a, b) => ({ ...a, ...b }), {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        mobileNumber: '',
        showOldPassword: false,
        showNewPassword: false,
        showConfirmNewPassword: false,
        passwordChanged: false,
        submitting: false,
        errorMessage: ''
    }
    )
    const [formTouched, setFormTouched] = useReducer(
        (a, b) => ({ ...a, ...b }), {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        mobileNumber: '',
    }
    )
    const atLeastMinimumLength = (password) => new RegExp(/(?=.{8,})/).test(password);
    const atLeastOneUppercaseLetter = (password) => new RegExp(/(?=.*?[A-Z])/).test(password);
    const atLeastOneLowercaseLetter = (password) => new RegExp(/(?=.*?[a-z])/).test(password);
    const atLeastOneNumber = (password) => new RegExp(/(?=.*?[0-9])/).test(password);
    const atLeastOneSpecialChar = (password) => new RegExp(/(?=.*?[#?!@$ %^&*-])/).test(password);
    const IsNumberHavingIndianMobilePattern = (num) => new RegExp(/^[6-9]{1}[0-9]{9}$/).test(num);

    function testingPasswordStrength(password) {
        if (!password) return 'Weak';
        let points = 0;
        if (atLeastMinimumLength(password)) points += 1;
        if (atLeastOneUppercaseLetter(password)) points += 1;
        if (atLeastOneLowercaseLetter(password)) points += 1;
        if (atLeastOneNumber(password)) points += 1;
        if (atLeastOneSpecialChar(password)) points += 1;
        if (password === form.oldPassword) { points = 0 } // if entered and new password is same then it is considered weak password
        if (points >= 5) return "Strong";
        if (points >= 3) return "Medium";
        return "Weak";
    }

    const handleChange = (e) => {
        let { name, value } = e.target
        setForm({ [name]: value })
        setFormTouched({ [name]: true })
    }

    const hasError = (field) => {
        let err;
        switch (field) {
            case 'oldPassword': err = !form[field] ? 'Please enter your old password' : ''

                break;
            case 'newPassword': err = !form[field] ? 'Please enter your new password' : form[field] && form['oldPassword'] && form[field] === form['oldPassword'] ? 'Old and new password cannot be same' : testingPasswordStrength(form[field]) === 'Weak' ? '' : ''

                break;
            case 'confirmNewPassword': err = !form[field] ? 'Please confirm your new password' : form[field] && form['newPassword'] && form[field] !== form['newPassword'] ? 'Password does not match' : ''

                break;
            case 'mobileNumber': err = !form[field] ? 'Please enter your mobile number' : !IsNumberHavingIndianMobilePattern(form[field]) ? 'Invalid mobile number' : ''

                break;

            default:
                break;
        }
        return err
    }

    const renderBars = () => {
        let strength = testingPasswordStrength(form.newPassword)
        let result;
        switch (strength) {
            case 'Weak': result = <>
                <span className="weak" />
                <span className="no-color" />
                <span className="no-color" />
            </>
                break;
            case 'Medium': result = <>
                <span className="medium" />
                <span className="medium" />
                <span className="no-color" />
            </>

                break;
            case 'Strong': result = <>
                <span className="strong" />
                <span className="strong" />
                <span className="strong" />
            </>
                break;
            default:
                break;
        }
        return result
    }

    const validateValues = () => {
        if (hasError('oldPassword') || hasError('newPassword') || hasError('confirmNewPassword') || hasError('mobileNumber') || testingPasswordStrength(form.newPassword) != 'Strong') {
            return false
        }
        else return true
    }

    const resetPassword = () => {
        if (!validateValues()) { return }
        setForm({ submitting: true })
        let endpoint = '/WeCare/ResetPassword'
        let payload = {
            "OldPassword": form.oldPassword,
            "NewPassword": form.newPassword,
            "ReEnterNewPassword": form.confirmNewPassword,
            "MobileNo": form.mobileNumber
        }
        POST(endpoint, payload, 'weCare').then(res => {
            setForm({ submitting: true })
            if (res && res.ErrorCode === 0) {
                setForm({
                    oldPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                    mobileNumber: '',
                    showOldPassword: false,
                    showNewPassword: false,
                    showConfirmNewPassword: false,
                    passwordChanged: true,
                    errorMessage: ''
                })
            }
            else {
                setForm({
                    errorMessage: res.Message || 'Some error occurred'
                })
            }
        }).catch(err => {
            setForm({ submitting: false, errorMessage: handleCatch(err, true) })
        })
    }

    const passwordTitle = <>
        <div className="info-box-view">
            <h1>How to create strong password?</h1>
            <ul style={{ listStyle: 'circle' }}>
                <li>Minimum 1 Uppercase Character A-Z (Latin alphabet)</li>
                <li>Minimum 1 Lowercase Character a-z (Latin alphabet)</li>
                <li>Minimum 1 Numeric Character (0-9)</li>
                <li>Minimum 1 Special Character (!@#$%...)</li>
                <li>Minimum 8 Characters</li>
            </ul>
        </div>
    </>

    return (
        <div className="login-box">
            <h2>Reset Password</h2>
            {!form.passwordChanged ? <Grid container>
                <Grid item xs={12} sm={12}>
                    <FormControl className="form-control" error={hasError("oldPassword") && formTouched['oldPassword']}>
                        <InputLabel htmlFor="old-password">Old Password</InputLabel>
                        <Input
                            className={classes.margin}
                            id="oldPassword"
                            name="oldPassword"
                            type={form.showOldPassword ? 'text' : 'password'}
                            label="Old Password"
                            autoComplete="no"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setForm({ showOldPassword: !form.showOldPassword })}
                                        onMouseDown={() => setForm({ showOldPassword: !form.showOldPassword })}
                                    >
                                        {form.showOldPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={form.oldPassword}
                            onChange={handleChange}
                        // error={hasError("oldPassword") && formTouched['oldPassword']}
                        />
                        {hasError("oldPassword") && formTouched['oldPassword'] && <FormHelperText>{hasError("oldPassword")}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl className="form-control" error={hasError("newPassword") && formTouched['newPassword']}>
                        <LightTooltip TransitionComponent={Fade} title={passwordTitle}>
                            <span className="info-box"><InfoIcon /></span>
                        </LightTooltip>
                        <InputLabel htmlFor="new-password">Enter New Password</InputLabel>
                        <Input
                            className={classes.margin}
                            id="newPassword"
                            label="New Password"
                            autoComplete="no"
                            type={form.showNewPassword ? 'text' : 'password'}
                            name="newPassword"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setForm({ showNewPassword: !form.showNewPassword })}
                                        onMouseDown={() => setForm({ showNewPassword: !form.showNewPassword })}
                                    >
                                        {form.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={form.newPassword}
                            onChange={handleChange}
                        // error={hasError("newPassword") && formTouched['newPassword']}
                        />
                        {form["newPassword"] && form["newPassword"] !== form['oldPassword'] && <><div className="password-strength">
                            {renderBars()}
                        </div>
                            {form?.newPassword ? <div className="password-strength-text">Password Strength - {testingPasswordStrength(form.newPassword)}</div> : ''}
                        </>
                        }
                        {hasError("newPassword") && formTouched['newPassword'] && <FormHelperText>{hasError("newPassword")}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl className={`form-control ${form["newPassword"] && form["newPassword"] !== form['oldPassword'] ? 'confirm-new-password' : ''} `} error={hasError("confirmNewPassword") && formTouched['confirmNewPassword']}>
                        <InputLabel htmlFor="confirm-new-password">Confirm New Password</InputLabel>
                        <Input
                            className={classes.margin}
                            id="confirmNewPassword"
                            label="Confirm New Password"
                            type={form.showConfirmNewPassword ? 'text' : 'password'}
                            name="confirmNewPassword"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setForm({ showConfirmNewPassword: !form.showConfirmNewPassword })}
                                        onMouseDown={() => setForm({ showConfirmNewPassword: !form.showConfirmNewPassword })}
                                    >
                                        {form.showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={form.confirmNewPassword}
                            onChange={handleChange}
                        // error={hasError("confirmNewPassword") && formTouched['confirmNewPassword']}
                        />
                        {hasError("confirmNewPassword") && formTouched['confirmNewPassword'] && <FormHelperText>{hasError("confirmNewPassword")}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl className="form-control" error={hasError("mobileNumber") && formTouched['mobileNumber']}>
                        <InputLabel htmlFor="mobile-no">Mobile No</InputLabel>
                        <Input
                            className={classes.margin}
                            id="mobileNumber"
                            label="Mobile no"
                            type="number"
                            name="mobileNumber"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={form.mobileNumber}
                            onChange={handleChange}
                        />
                        {hasError("mobileNumber") && formTouched['mobileNumber'] ?
                            <FormHelperText>{hasError("mobileNumber")}</FormHelperText> :
                            !hasError("mobileNumber") && formTouched['mobileNumber'] ?
                                <span className="otp-sent-message"><b>Note: OTP will be sent on the above number for 2-Factor Authentication</b></span> : ''}
                    </FormControl>
                </Grid>

                <Grid item md={12} xs={12} className="text-center">
                    <Button
                        className="signin-btn"
                        type="submit"
                        onClick={resetPassword}
                        disabled={!validateValues()}
                    >
                        Confirm <span className="loader-icon">{form.submitting ? <div className="otp-loader"><Spinner /></div> : ""}</span>
                    </Button>
                    {form.errorMessage && <span style={{ color: "#ff0000", position: "relative", top: "-6px" }}>{form.errorMessage}</span>}
                </Grid>
                <Grid item md={12} xs={12} className="text-center">
                    <Button
                        className="signin-btn"
                        onClick={resetOptions}
                        id='password-change-login-other-user-btn'
                    >
                        Login with other credentials
                    </Button>
                </Grid>
            </Grid>
                :
                <Grid container spacing={3} className="relogin-box">
                    <Grid item md={12} xs={12} className="text-center">
                        <p>
                            <strong>Password changed successfully. Please re-login to continue</strong>
                        </p>
                    </Grid>
                    <Grid item md={12} xs={12} className="text-center">
                        <Button
                            className="signin-btn"
                            onClick={resetOptions}
                            id='password-change-login-btn'
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>}
        </div>
    )
}

export default PasswordChange