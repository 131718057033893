import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { connect } from "react-redux";
// import DialogFromRight from '../../../shared/components/DialogFromRight';
import { setDashboardOption } from '../../redux/actions/pbDashboard_action';
import ED_SIMPLE_SEARCH from './Components/ED_SimpleSearch';
import ED_ADVANCE_SEARCH from './Components/ED_AdvanceSearch';
import ED_SEARCH_OPTIONS from './Components/ED_SearchOptions';
import ED_SUMMARY from './Components/ED_Summary';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
  },
}))

const EXTERNAL_DASHBOARD = (props) => {
  const classes = useStyles();
  const { dashboardOption_store, setDashboardOptionSelected } = props;
//   const [dialogWithControls, setDialogWithControls] = useState();

  return (
    <>
      <div className={classes.root}>
        {/* -----to be added-------- */}
        {/* ---------------- */}
        <ED_SEARCH_OPTIONS option_store={dashboardOption_store} setDashboardOption_store={setDashboardOptionSelected} />
        {
          dashboardOption_store === "1" && <ED_SIMPLE_SEARCH />
        }

        {
          dashboardOption_store === "2" && <ED_ADVANCE_SEARCH />
        }
        {/* {
          dashboardOption_store === "3" && <ED_SUMMARY />
        } */}

      </div>
      {/* {dialogWithControls && dialogWithControls.open &&
        <DialogFromRight
          open={dialogWithControls.open}
          handleClose={dialogWithControls.handleClose}
          title={dialogWithControls.title}
          children={dialogWithControls.children}
          size={dialogWithControls.size}
        />
      } */}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboardOption_store: state && state.pbDashboard && state.pbDashboard.optionSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDashboardOptionSelected: (payload) => dispatch(setDashboardOption(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EXTERNAL_DASHBOARD)