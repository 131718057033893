import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  FormControl,
  TextField,
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import EmployeeList from "./Components/EmployeeList";
import { GET } from "../services/apiServices";
import { GetSMEDetail, GetTicketDetails } from "../constants/apiUrls";
import FullScreenDialog from "../Components/Topbar/components/CreateTicketDialog";
import Spinner from "../shared/components/Spinner";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
  },
  title: {
    flex: 1,
  },
  formControl: {
    width: "100%",
  },
  h6: {
    color: "#414141",
  },
}));

const SME_Employee_Detail = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [employeeList, setEmployeeList] = useState(null);
  const [smeDetails, setSmeDetails] = useState({});
  const [isEmployeesLoading, setEmployeesLoading] = useState(false);
  const [isCreateTicketDialogOpen, setCreateTicketDialogOpen] = useState(false);
  const [sourceList, setSourceList] = useState(null);
  const [supplierList, setSupplierList] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [email, setEmail] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);

  // const ticketContext = useContext(TicketContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    GET(`${GetTicketDetails}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const {
            suppliersList,
            ticketSources,
          } = response.Data;

          setSourceList(ticketSources);
          setSupplierList(suppliersList);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    if (e && e.persist) {
      e.persist();
    }
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    if (e && e.persist) {
      e.persist();
    }
    setEmployeesLoading(true);
    GET(`${GetSMEDetail}?searchParam=${search}`).then((response) => {
      if (
        response.ErrorCode == 0 &&
        response.Data &&
        response.Data.Data &&
        response.Data.Data.Employees &&
        response.Data.Data.Employees.length > 0
      ) {
        setEmployeeList(response.Data.Data.Employees);
      } else {
        setEmployeeList(null);
      }
      setEmployeesLoading(false);
    });
  };

  return (
    <div>
      <div className="sme-emplyee">
        {/* <h3>Corporate Search</h3> */}
        <Grid container spacing={2}>
          <Grid item md={5} xs={12}>
            <FormControl className="form-control-box">
              <TextField
                label="Customer Email ID, Phone No, Employee Code, Employee Name"
                id="outlined-margin-normal"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                value={search}
                onChange={handleChange}
              />
              <Button className="search-btn" onClick={handleSubmit}>
                <em>Go </em>
                <img
                  src="images/search_iocn_dash.svg"
                  alt="search_iocn_dash.svg"
                />
              </Button>
            </FormControl>
          </Grid>
          {isEmployeesLoading ? (
            <div className="spinner-loader">
              <Spinner />
            </div>
          ) : (
            <Grid item md={12} xs={12}>
              {employeeList && employeeList.length > 0 ? (
                <EmployeeList
                  employeeList={employeeList}
                  setCreateTicketDialogOpen={setCreateTicketDialogOpen}
                  setLeadId={setLeadId}
                  setEmail={setEmail}
                  setEmployeeName={setEmployeeName}
                />
              ) : (
                <div className="no-data-found">
                  <img src="images/no_data_found.svg" alt="no_data_found.svg" />
                  <p>No data found</p>
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </div>
      <FullScreenDialog
        isCreateDialogOpen={isCreateTicketDialogOpen}
        setCreateDialog={setCreateTicketDialogOpen}
        sourceList={sourceList}
        TicketDetailsID={null}
        RefObjectId={null}
        supplierList={supplierList}
        leadId={leadId}
        AccessTypeVal={4}
        email={email}
        employeeName={employeeName}
        OpenedThroughSource="SMEPage"
      />
    </div>
  );
};

export default SME_Employee_Detail;
