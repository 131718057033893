import React, { useState } from "react";
import { ListItem, Button } from "@material-ui/core";
import { GET, POST } from "../../../services/apiServices";
import { GetEncryptedUserData, GetWhatsappLinkForPOSP } from "../../../constants/apiUrls";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Slide,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { isCallingPopEligible } from "../../../Config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CommunicationItem = ({
  text,
  icon,
  className,
  handleClick = (open) => {},
  ticket_details_id,
  leadDetails,
  setOpenIframe,
  PartnerId,
  TicketId,
  ref_object_id,
  IsReadOnlyUser
}) => {
  const [openCall, setOpenCall] = useState(false);


  const connect = () => {
    switch (text) {
      case "Whatsapp":
        setOpenIframe(true);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpenCall(false);
  };
  // const classes = useStyles();
  const [number, setNumber] = React.useState("");
  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  return (
    <>
      <ListItem style={{pointerEvent : IsReadOnlyUser ? "none" : ""}} id="whatsApp">
        <Button id="whatsappBtn" onClick={() => connect(true)} disabled={IsReadOnlyUser} >
          <span className={className} >
            <i className={icon} aria-hidden="true"></i>
            <em>{text}</em>
          </span>
        </Button>
        
      </ListItem>
    </>
  );
};

export default CommunicationItem;
