import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  ExpansionPanel,
  Grid,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { download } from "../../../shared/methods/methods";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  headingTxt: {
    fontSize: "14px",
    fontWeight: "500",
  },
  expandIconSvg: {
    color: "#0065FF",
  },
});

export default function EndorsementDetail({
  tableDetails,
  endorsementDetails,
}) {
  const classes = useStyles();

  const downloadEndorsementLetter = () => {

        // var blob = new Blob([contents], {type: "text/plain"});
    var downloadLink = document.createElement("a");
    downloadLink.href = endorsementDetails?.EndorsedDocumentURL;
    downloadLink.target = "_blank";
    downloadLink.download = "";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <div className="nominee-detail">
        <List className="head">
          <ListItem>
            <h6>Category & Section</h6>
          </ListItem>
          <ListItem>
            <h6>Corrections required</h6>
          </ListItem>
          <ListItem>
            <h6>Existing Values</h6>
          </ListItem>
          <ListItem>
            <h6>New Values</h6>
          </ListItem>
          <ListItem>
            <h6>Documents</h6>
          </ListItem>
          <ListItem>
            <h6>Status</h6>
          </ListItem>
        </List>
        {tableDetails &&
          tableDetails.length &&
          tableDetails.map((i, index) => {
            return (
              <List key={index} className="first-row">
                <ListItem>
                  <strong className="light-txt">{i.CategoryName}</strong>
                </ListItem>
                <ListItem>
                  <strong className="light-txt">{i.FieldName}</strong>
                </ListItem>
                <ListItem>
                  <strong>{i.OldValue}</strong>
                </ListItem>
                <ListItem>
                  <strong>{i.NewValue}</strong>
                </ListItem>
                <ListItem>
                  {i.PendingDocuments && i.PendingDocuments.length > 0 ? (
                    <strong>Pending: {i.PendingDocuments}</strong>
                  ) : null}
                  {i.ReceivedDocuments && i.ReceivedDocuments.length > 0 ? (
                    <strong>Received: {i.ReceivedDocuments}</strong>
                  ) : null}
                </ListItem>
                <ListItem>
                  <strong
                    className={i.StatusID == 12 ? "rejected" : "completed"}
                  >
                    {i.StatusID != 12 ? (
                      <img
                        src="/images/checkmark-circle.svg"
                        alt="checkmark-circle"
                      />
                    ) : null}
                    {i.Status || "-"}
                  </strong>
                  {/* <Button className="add-copy">
                      Add Copy <i className="fa fa-angle-right"></i>
                    </Button> */}
                </ListItem>
              </List>
            );
          })}
        {endorsementDetails?.EndorsedDocumentURL && (
          <Grid>
            <Button
              className="download-letter"
              onClick={downloadEndorsementLetter}
            >
              Download Endorsement letter
            </Button>
          </Grid>
        )}
      </div>
    </div>
  );
}
