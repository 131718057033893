import React, { useState } from "react";
import { List, Button } from "@material-ui/core";
import CommunicationItem from "./components/CommunicationItem";
import DialogOpenIframe from "../Dialog/OpenIFrame";
import { GET } from "../../services/apiServices";
import { GetCustomerCallingDetails, GetDocURL } from "../../constants/apiUrls";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { handleCatch } from "../../shared/methods/apiMethods";
import { Toast } from "../../shared/components";

const CommunicationBox = (props) => {
  const {
    ticket_details_id,
    leadDetails,
    policyCopyDocId,
    PartnerId,
    TicketId,
    ref_object_id,
    IsReadOnlyUser,
  } = props;
  const [openIframe, setOpenIframe] = React.useState(null);
  const handleCloseIframe = () => {
    setOpenIframe(false);
  };

  const [response, setResponseData] = useState(null);
  const [selectedMobileNo, setSelectedMobileNo] = useState();


  const [isShowMore, setIsShowMore] = useState(true);

  const CallClicked = () => {
    GET(`${GetCustomerCallingDetails}/${PartnerId || 0}/${ticket_details_id}`)
      .then((res) => {
        let primaryNumber = res?.Data?.MobileNos.filter((i) => i.IsPrimaryNumber)?.[0];
        setSelectedMobileNo(primaryNumber?.CallingVariable);
        setResponseData({
          ...res?.Data,
          ...primaryNumber,
          TicketURL: localStorage.getItem("TicketURL"),
          // CountryID: res.Data.CountryID,
          LeadID: res.Data.LeadID,
          LeadIDEncrypted: res.Data.LeadIDEncrypted,
          // MaskedMobileNo: a?.MaskedMobileNo,
          // Mobile: a?.CallingVariable,
          TicketID: ticket_details_id,
        });
        setIsShowMore(!isShowMore);
      })
      .catch((e) => console.log(e));
  };

  const connectCall = () => {
    let selectedMobileNumberObject = response?.MobileNos?.filter(i=> i.CallingVariable === selectedMobileNo)?.[0]
    console.log('selectedMobileNumberObject', selectedMobileNumberObject)
    let callingParams = {
      ...response,
      ...selectedMobileNumberObject,
      Mobile : selectedMobileNumberObject?.CallingVariable,
      MaskedMobileNo: selectedMobileNumberObject?.MaskedMobileNo,
    }
    console.log(callingParams)
    if (!callingParams.MaskedMobileNo) {
      return Toast.error('Please select a number')
    } else {
      window.parent.postMessage({ type: "TicketCalling", data: callingParams }, "*");
    }
  };

  const downloadAttachment = (docID) => {
    if (docID) {
      GET(`${GetDocURL}/${docID}`)
        .then((res) => {
          if (res && res.ErrorCode == 0 && res.Data) {
            window.open(res.Data, "_blank");
          } 
          else Toast.error(res.Response || 'URL not found')
          
        })
        .catch((err) => {
          handleCatch(err)
        });
    } else {
      Toast.error('Document not found')
    }
  };

  return (
    <>
      <div className="communication-box">
        <List>
          <li>
            <Button
              onClick={CallClicked}
              PartnerId={PartnerId}
              TicketId={TicketId}
              leadDetails={leadDetails}
              ticket_details_id={ticket_details_id}
              text="Call"
              className="phone"
              disabled={IsReadOnlyUser}
              id="callButtonGetNumbers"
            >
              <span className="phone-icon">
                <i className="fa fa-phone"></i>Call
              </span>
            </Button>
          </li>
          <CommunicationItem
            setOpenIframe={setOpenIframe}
            PartnerId={PartnerId}
            TicketId={TicketId}
            leadDetails={leadDetails}
            ticket_details_id={ticket_details_id}
            text="Whatsapp"
            className="whatsapp"
            ref_object_id={ref_object_id}
            IsReadOnlyUser={IsReadOnlyUser}
            id="whatsappBtn"
          />
          {policyCopyDocId && (
            <li>
              <Button
                className="policy-copy"
                onClick={() => downloadAttachment(policyCopyDocId)}
                disabled={IsReadOnlyUser}
                id="policyCopyButton"
              >
                <img src="/images/doc_icon.png" alt="doc_icon" /> Policy copy
              </Button>
            </li>
          )}
        </List>
        {openIframe ? (
          <DialogOpenIframe
            handleCloseIframe={handleCloseIframe}
            setOpenIframe={setOpenIframe}
            leadDetails={leadDetails}
          />
        ) : null}
        {!isShowMore && (
          <>
            <div className="call-widget">
              <ul>
                {console.log('selectedMobile', selectedMobileNo)}
                <li className="select-phone-number">
                  <FormControl className="form-control">
                    <InputLabel id="demo-customized-select-label">
                      Select Number
                    </InputLabel>
                    <Select
                      onChange={(e) => setSelectedMobileNo(e.target.value)}
                      value={selectedMobileNo}
                      id="selectMobileNumber"
                    >
                      <MenuItem value={0} id="SelectMobileNumber">Select Number</MenuItem>
                      {response?.MobileNos?.map(i => {
                        return (
                          <MenuItem
                            value={i.CallingVariable}
                            key={i.CallingVariable}
                          >{`${i?.Name ? i?.Name : ""} ${i?.Name ? ":" : ""} ${
                            i?.MaskedMobileNo
                          }`}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </li>
                <li>
                  <Button
                    className="calling-btn"
                    onClick={connectCall}
                    disabled={IsReadOnlyUser}
                    id="callingBtn"
                  >
                    <img src="/images/call_widget.png" />
                  </Button>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    IsReadOnlyUser:
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser,
  };
};

export default connect(mapStateToProps)(CommunicationBox);
