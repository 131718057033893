import React, { useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

export const CustomCopyToClipboard = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (e) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
    document.body.removeChild(el);
  };

  return (
    // <></>
    <Tooltip title={copied ? 'Copied' : 'Copy to clipboard'} className="copy-btn">
      <IconButton aria-label="copy" size="small" onClick={handleCopy}>
        <FileCopyIcon color="black" style={{ height: '15px', width: '14px' }} />
      </IconButton>
    </Tooltip>
  );
};