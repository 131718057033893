import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  RadioGroup,
  DialogActions,
} from "@material-ui/core";

import DialogTitle from "../../shared/components/DialogTitle";
import CustomerEmail from "./CustomerEmail";
import Spinner from "../../shared/components/Spinner";
import { GET, POST } from "../../services/apiServices";
import {
  InitiatePGRefund,
  GetTicketDetailsByTicketID,
} from "../../constants/apiUrls";
import { useSnackbar } from "notistack";
import TicketContext from "../../TicketContext";
import { useDispatch } from 'react-redux';
import { setTicketDetails } from "../../redux/actions/ticketDetails";

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

export default function PGRefund({
  isDialogOpen,
  isTicketTimelineLoading,
  LeadID,
  ProductID,
  Source,
  TicketDetailsID,
  IssueTypeId,
  CustomerID,
  RefObjectId,
  SupplierId,
  communications = [],
  ticketId,
  ticketCreationDate,
  ApplicationNo,
  viewtype,
  setResendPDF,
  heading,
  closePGRefundDialog
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [selectedRadioVal, setSelectedRadioVal] = useState(0);
  const [isInitiateRefundInProgress, setIsInitiateRefundInProgress] =
    useState(false);
  const ticketContext = useContext(TicketContext);

  const GetTicketDetailsByTicketIdApi = () => {
    GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest, IsInsurerBucket } = response.Data;
          dispatch(setTicketDetails(response.Data));
          let callbackVal;
          if (IsCallBackRequest === "0") {
            callbackVal = "No";
          } else if (IsCallBackRequest === "1") {
            callbackVal = "Yes";
          } else if (IsCallBackRequest === "2") {
            callbackVal = "Completed";
          }

          ticketContext.setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
          ticketContext.setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log('error PGRfundModal GetTicketDetailsByIdAPI')
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log(err);
      });
  };

  const handleInitiatePGRefund = () => {
    if (isInitiateRefundInProgress) {
      return;
    }
    let body= `
    <div>From: ${communications.find((item, i) => i == selectedRadioVal)?.From} </div>
    <div>To: care@policybazaar.com </div>
    <div>Subject: ${communications.find((item, i) => i == selectedRadioVal)?.Subject}</div>
    <div>Date: ${new Date(ticketCreationDate)}</div>
    <div>ApplicationNo: ${ApplicationNo}</div>
    <br/>
    <div>${communications.find((item, i) => i == selectedRadioVal)?.Body}</div>
    `
    const payload = {
      LeadID: LeadID,
      ProductID: ProductID,
      Source: ticketContext.ticketCommData && ticketContext.ticketCommData.length ? ticketContext.ticketCommData[0].TicketSource : Source ,
      TicketDetailsID: TicketDetailsID,
      IssueTypeId: IssueTypeId,
      CustomerID: CustomerID,
      // Body: communications.find((item, i) => i == selectedRadioVal)?.Body,
      Body:body,
      InsurerID: SupplierId,
      ticketId,
      ticketCreationDate
    };
    setIsInitiateRefundInProgress(true);
    POST(InitiatePGRefund, payload)
      .then((res) => {
        if (res.ErrorCode === 0) {
          enqueueSnackbar("Success", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
          GetTicketDetailsByTicketIdApi();
          setIsInitiateRefundInProgress(false);
          if (setResendPDF){
            setResendPDF(false)
          }
        }
        else if(res.ErrorCode==1 && res.Response){
          enqueueSnackbar(res.Response, {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          setIsInitiateRefundInProgress(false);
        }
         else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          setIsInitiateRefundInProgress(false);
        }
        // console.log(res);
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        setIsInitiateRefundInProgress(false);
        console.log(err);
      });
  };

  return (
    <div>
      <Dialog maxWidth="md" className="comm-mail-popup" open={isDialogOpen} onClose={()=>closePGRefundDialog? closePGRefundDialog() : setResendPDF ? setResendPDF() : ""}>
        <DialogTitle className="title-heading" id="customized-dialog-title">
          {heading ? heading : "Cancellation Request Form"}
        </DialogTitle>
        {isTicketTimelineLoading ? (
          <div className="spinner-loader">
            <Spinner />
          </div>
        ) : (
          <>
            <DialogContent className="scroll-btn">
              <RadioGroup
                aria-label="communication"
                name="radio-buttons-group"
                onChange={(e) => {
                  setSelectedRadioVal(e.target.value);
                }}
              >
                {communications && communications.length > 0 && communications.map((item, i) => (
                  <CustomerEmail
                    key={i}
                    ind={i}
                    selectedRadioVal={selectedRadioVal}
                    item={item}
                  />
                ))}
              </RadioGroup>
            </DialogContent>
            <DialogActions
              className="title-heading"
              id="customized-dialog-title"
            >
              <Button className="submit-btn" onClick={handleInitiatePGRefund}>
                {" "}
                {isInitiateRefundInProgress ? <Spinner /> : "Submit"}{" "}
              </Button>
              <Button className="submit-btn cancel" onClick={()=>closePGRefundDialog? closePGRefundDialog() : setResendPDF ? setResendPDF() : ""}>
                  Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
