import React, {useEffect,useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions,
    TextField,Grid, FormControl, TextareaAutosize,
} from '@material-ui/core';

import DialogTitle from '../../../../shared/components/DialogTitle';
import {GetTicketTicketEscLog} from '../../../../constants/apiUrls';
import {GET} from '../../../../services/apiServices'
import { convertToIsoFormate } from "../../../../shared/methods/methods";
import Spinner from '../../../../shared/components/Spinner';

const DialogContent = withStyles((theme) => ({
  
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  
}))(MuiDialogActions);

export default function RecentRefundPdfDialog({isDialogOpen,setDialogOpen,TicketDetailsID}) {

  const [EsclationLog, setEsclationLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    GET(`${GetTicketTicketEscLog}/${TicketDetailsID}`)
    .then((res)=> {
          setEsclationLog(res.Data);
          setIsLoading(false);
    })
    .catch(err=>{
      console.log(err);
      setIsLoading(false);
    })
  }, [])

  return (
    <div>
      <Dialog maxWidth="md" className="reply-popup escalation-popup" onClose={()=> setDialogOpen(false)} aria-labelledby="customized-dialog-title" open={isDialogOpen}>
      <DialogTitle className="title-heading" id="customized-dialog-title" onClose={()=> setDialogOpen(false)}>
        Recent Refund PDF
      </DialogTitle>
      
          <DialogContent className="escalation-popup-box">
          
        </DialogContent>
        <DialogContent className="no-data">
          <img src="/images/no_data_found.svg" alt="No Data Found"/>
          <p>No Data Found</p>
        </DialogContent>
        
        <span className="page-loader"><Spinner/></span>
      </Dialog>
    </div>
  );
}
