import { useEffect, useState, React } from 'react';
import { Link, ListItem, Grid, List, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { connect, useSelector } from "react-redux";
import ChildErrorBoundary from '../../../shared/components/ChildErrorBoundary';
import Tooltip from '@material-ui/core/Tooltip';
import DashboardPB_CreateTicket from '../CommonComponents/PBDashboardCreateTicket';
import AdminSearchOptions from './Components/AdminSearchOptions';
import AdminSimpleSearch from './Components/AdminSimpleSearch';
import AdminAdvanceSearch from './Components/AdminAdvanceSearch';
import AdminSummary from './Components/AdminSummary';
import AdminTATSummary from './Components/AdminTatSummary';
import DialogFromRight from '../../../shared/components/DialogFromRight';
import { setDashboardOption } from '../../../redux/actions/pbDashboard_action';

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100vh',
    background: '#fff',
  },
}))

const PB_ADMIN_DASHBOARD = (props) => {
  const classes = useStyles();
  const { dashboardOption_store, setDashboardOptionSelected } = props;
  const [dialogWithControls, setDialogWithControls] = useState();

  return (
    <>
      <div className={classes.root}>
        {/* -----to be added-------- */}
        {/* ---------------- */}
        <AdminSearchOptions option_store={dashboardOption_store} setDashboardOption_store={setDashboardOptionSelected} />
        {
          dashboardOption_store === "1" && <AdminSimpleSearch />
        }

        {
          dashboardOption_store === "2" && <AdminAdvanceSearch />
        }
        {
          dashboardOption_store === "3" && <AdminSummary />
        }
        {
          dashboardOption_store === "4" && <AdminTATSummary />
        }

      </div>
      {dialogWithControls && dialogWithControls.open &&
        <DialogFromRight
          open={dialogWithControls.open}
          handleClose={dialogWithControls.handleClose}
          title={dialogWithControls.title}
          children={dialogWithControls.children}
          size={dialogWithControls.size}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboardOption_store: state && state.pbDashboard && state.pbDashboard.optionSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDashboardOptionSelected: (payload) => dispatch(setDashboardOption(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PB_ADMIN_DASHBOARD)