import React, { useState, useContext, useEffect, useReducer } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { POST } from "../../../services/apiServices";
import {
  UpdateAutoCommStatus,
  ToggleRefundStatusOfTicket,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import AssignToDialog from "./components/AssignToDialog";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import TicketContext from "../../../TicketContext";
import EsclationFormDialog from "./components/EsclationFormDialog";
import RecentRefundPDFDialog from "./components/recentRefundPdf";
import {
  SHOW_AHC_IN_MORE_OPTIONS,
  isAllowedBlockComm,
  isResetRefundEligible,
  SME,
} from "../../../Config";
import Topbar from "../Topbar";
import { connect, useSelector } from "react-redux";
import PGRefund from "../../PGRefund/PGRefundModal";
import { EMAIL_FROM_CUSTOMER, MY_ACCOUNT } from "../../../Config";
import PdfGenerater from "../../../../src/Components/TicketDetail/Components/PDFGenerater";
import UserDetailsDialogBox from "../../../../src/Components/TicketDetail/Components/UserDetailsDialog";
import { fetchAHCUrl } from "../../../shared/methods/apiMethods";
import AHCDetailsDialog from "../../TicketDetail/Components/AHCDetailsDialog";
import CustomBackdrop from "../../../pages/Components/CustomBackdrop";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const MoreOptionMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  TicketId,
  LeadId,
  BlockAutoComm,
  setFormValues,
  StatusID,
  formValues,
  masters,
  TicketDetailsID,
  RefObjectId,
  isBlockAutoCommVisible,
  EndorsementLink,
  ParentTicketID,
  AccessTypeVal,
  ProductID,
  isValidForSME,
  FetchedTicketDetails, // picking ticketdetails from redux
  oldTicketDetails,
  UserInfo,
}) => {
  const classes = useStyles();
  const ticketContext = useContext(TicketContext);
  const ticketDetails_store = useSelector(state => state && state.ticketdetails && state.ticketdetails.ticketDetails)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openAssignTo, setAssignTo] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isResetRefundClicked, setIsResetRefundClicked] = useState(false);
  const [showBlockComm, setShowBlockComm] = useState(BlockAutoComm);
  const [ticketDetails, setTicketDetails] = useState();
  const [resendPDF, setResendPDF] = useState(false); // to show the resend refund pdf
  const [isGenerateDialog, setGenerateDialog] = useState(false);
  const [customBackdropOptions, setCustomBackdropOptions] = useState();

  const [AHCDialog, setAHDDialog] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      show: false,
      url: null,
    }
  );

  const handleAssignTo = (open) => {
    setIsMenuOpen(null);
    setAssignTo(open);
  };

  useEffect(() => {
    setTicketDetails(FetchedTicketDetails);
  }, [FetchedTicketDetails]);
  // console.log(1234567,showBlockComm);
  const handleBlockAutoComm = () => {
    setIsMenuOpen(null);
    const payload = { LeadID: LeadId, TicketId: TicketId, BlockAutoComm: true };
    POST(UpdateAutoCommStatus, payload)
      .then((res) => {
        if (res.ErrorCode === 0) {
          setShowBlockComm(res.Data);

          enqueueSnackbar("Communication is successfully blocked", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
          setFormValues((prev) => ({ ...prev, BlockAutoComm: true }));
          ticketContext.setoldTicketDetails((prev) => ({
            ...prev,
            BlockAutoComm: true,
          }));
          ticketContext.GetTicketLogsApi();
        } else {
          enqueueSnackbar(res.message || "Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showConfirmationForRefund = () => {
    setShowConfirmationBox(true);
    setIsResetRefundClicked(true);
  };

  const handleResetRefund = () => {
    const payload = {
      TicketDetailId: TicketDetailsID,
    };
    POST(ToggleRefundStatusOfTicket, payload)
      .then((res) => {
        if (res.ErrorCode == 0) {
          enqueueSnackbar("Success", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
        } else {
          enqueueSnackbar(res.Message || "Some error occured", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const showMessage = (message, varient) => {
    return enqueueSnackbar(message, {
      variant: varient,
      onClick: () => {
        closeSnackbar();
      },
    });
  };

  const fetchAHCFromAPI = () => {
    let url = "/GetAHCDetails";
    setCustomBackdropOptions({
      open : true,
      textToShow : 'Loading AHC...'
    })
    let callback = (data, error) => {
      setCustomBackdropOptions()
      if (data) {
        setAHDDialog({ show: true, url: data });
      } else showMessage(error, "error");
    };
    fetchAHCUrl(url, FetchedTicketDetails, UserInfo, callback);
  };

  return (
    <div>
      {ticketDetails ? (
        <div>
          { customBackdropOptions && <CustomBackdrop open = {customBackdropOptions.open} textToShow={customBackdropOptions.textToShow}/> }
          <Backdrop
            className={classes.backdrop}
            open={Boolean(isMenuOpen)}
            onClick={() => setIsMenuOpen(null)}
          >
            <Menu
              id="topBarMoreOptions"
              anchorEl={isMenuOpen}
              keepMounted
              className="more-action-list"
              open={Boolean(isMenuOpen)}
              onClose={() => setIsMenuOpen(false)}
            >
              {
                // StatusID === 4 ||
                // StatusID === 3 ||
                // StatusID === 6 ||
                // ParentTicketID ||
                showBlockComm || BlockAutoComm ? null : localStorage.getItem(
                    "UserObjTicket"
                  ) &&
                  isAllowedBlockComm(
                    JSON.parse(localStorage.getItem("UserObjTicket")).UserID
                  ) ? (
                  <MenuItem onClick={() => setShowConfirmationBox(true)} id="topBarBlockAutoCommunication">
                    Block Auto Communication
                  </MenuItem>
                ) : (
                  ""
                )
              }
              {AccessTypeVal != 3 &&
                ticketDetails_store && ticketDetails_store.EndorsementLink && (
                  <a href={ticketDetails_store.EndorsementLink} target="_blank" rel="noreferrer" id="topBarEndorsementButton">
                    <MenuItem>Endorsement</MenuItem>
                  </a>
                )}
              {StatusID !== 4 &&
              StatusID !== 3 &&
              StatusID !== 6 &&
              !ParentTicketID &&
              ticketContext.isActionsAllowed &&
              ProductID != SME ? (
                // ||
                // (!isValidForSME && ProductID == SME)
                <MenuItem
                  isAssignToDialogOpen={openAssignTo}
                  onClick={() => handleAssignTo(true)}
                  UserProductID={ProductID}
                  id="topBarAssignmentButtonNonSME"
                >
                  Assignment
                </MenuItem>
              ) : (
                ""
              )}
              {ProductID == SME &&
              isValidForSME &&
              StatusID !== 4 &&
              StatusID !== 3 &&
              StatusID !== 6 &&
              !ParentTicketID &&
              ticketContext.isActionsAllowed ? (
                <MenuItem
                  isAssignToDialogOpen={openAssignTo}
                  onClick={() => handleAssignTo(true)}
                  id="topBarAssignmentButtonSME"
                >
                  Assignment
                </MenuItem>
              ) : (
                ""
              )}
              <MenuItem onClick={() => setDialogOpen(true)} id="topBarEscalationForm">
                Escalation Form
              </MenuItem>
              <MenuItem onClick={() => setGenerateDialog(true)} id="topBarGeneratePDFButton">
                Generate PDF
              </MenuItem>
              {ticketDetails && ticketDetails.ProductId && SHOW_AHC_IN_MORE_OPTIONS.includes(ticketDetails.ProductId) && (
                <MenuItem onClick={fetchAHCFromAPI} id="topBarAHCButton">AHC</MenuItem>
              )}
              {ticketDetails &&
                ticketDetails.RefundId &&
                ticketDetails.RefundId > 0 &&
                ([1, 2].indexOf(ticketDetails.StatusID) > -1
                  ? true
                  : false) && (
                  <MenuItem onClick={() => setResendPDF(true)} id="topBarResendRefundPDF">
                    Resend Refund PDF
                  </MenuItem>
                )}
              {isResetRefundEligible(ProductID) ? (
                <MenuItem onClick={showConfirmationForRefund} id="topBarResetRefundButton">
                  Reset Refund
                </MenuItem>
              ) : null}
            </Menu>
          </Backdrop>
          <AssignToDialog
            TicketDetailsID={TicketDetailsID}
            RefObjectId={RefObjectId}
            setFormValues={setFormValues}
            masters={masters}
            formValues={formValues}
            isAssignToDialogOpen={openAssignTo}
            setAssignToDialogOpen={handleAssignTo}
            UserProductID={ProductID}
          />
          <ConfirmationDialog
            isConfirmDialogOpen={showConfirmationBox}
            setConfirmDialogOpen={setShowConfirmationBox}
            updateFn={
              !isResetRefundClicked ? handleBlockAutoComm : handleResetRefund
            }
          />

          {isDialogOpen && (
            <EsclationFormDialog
              isDialogOpen={isDialogOpen}
              setDialogOpen={setDialogOpen}
              TicketDetailsID={TicketDetailsID}
            />
          )}
          {/* {openUserDetailsDialog && 
          <UserDetailsDialogBox
            open ={openUserDetailsDialog}
            close ={()=>setOpenUserDetailsDialog(false)}
          />} */}
          {isGenerateDialog && (
            <PdfGenerater
              isGenerateDialog={isGenerateDialog}
              setGenerateDialog={setGenerateDialog}
              communications={ticketContext.ticketCommData.filter((comm) =>
                (comm.EventType
                  ? comm.EventType.toLowerCase().includes(
                      EMAIL_FROM_CUSTOMER.toLowerCase()
                    )
                  : false) ||
                [
                  "rmemails",
                  "care",
                  "communications",
                  "myaccount",
                  "social",
                  "topmanagement",
                  "endorsementmyaccount",
                ].indexOf(comm.TicketSource?.toLowerCase()) > -1
                  ? true
                  : false
              )}
            />
          )}
          {resendPDF && (
            <PGRefund
              isDialogOpen={resendPDF}
              heading={"Resend Refund PDF"}
              setResendPDF={setResendPDF}
              LeadID={ticketDetails.LeadID}
              ProductID={ticketDetails.ProductId}
              TicketDetailsID={ticketDetails.TicketDetailsID}
              IssueTypeId={ticketDetails.IssueTypeId}
              CustomerID={ticketDetails.CustomerId}
              RefObjectId={ticketDetails.RefObjectId}
              SupplierId={ticketDetails.SupplierId}
              ticketId={ticketDetails.TicketId}
              ticketCreationDate={ticketDetails.CreatedOn}
              communications={ticketContext.ticketCommData.filter((comm) =>
                (comm.EventType
                  ? comm.EventType.toLowerCase().includes(
                      EMAIL_FROM_CUSTOMER.toLowerCase()
                    )
                  : false) ||
                [
                  "rmemails",
                  "care",
                  "communication",
                  "communications",
                  "myaccount",
                  "social",
                  "topmanagement",
                  "whatsapp",
                  "myaccount"
                ].indexOf(comm.TicketSource?.toLowerCase()) > -1
                  ? true
                  : false
              )}
            />
          )}
          <AHCDetailsDialog
            open={AHCDialog.show}
            close={() => setAHDDialog({ show: false })}
            url={AHCDialog.url}
          />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    FetchedTicketDetails:
      state && state.ticketdetails && state.ticketdetails.ticketDetails
        ? state.ticketdetails.ticketDetails
        : {},
    UserInfo: state && state.auth && state.auth.userInfo,
  };
};

export default connect(mapStateToProps, null)(MoreOptionMenu);
