import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  RadioGroup,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  FormControl,
  DialogActions,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";

import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
    height: "40px,",
  },
  title: {
    flex: 1,
    fontSize: "16px",
  },
  formControl: {
    minWidth: "100%",
  },
  h6: {
    color: "#414141",
    fontSize: "14px",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CustomizedDialogs(props) {
  const { open, close } = props;
  const classes = useStyles();
  const [product, setProduct] = React.useState("");
  const [isShowLess, setIsShowLess] = useState(true);

  const toggleShowDetail = () => {
    setIsShowLess(!isShowLess);
  };

  const handleClose = () => {
    close();
  };

  const handleChange = (event) => {
    setProduct(event.target.value);
  };

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen
        className="dialog-user-details"
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              User Details
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div>
          <div className="user-details-view">
            <Grid container spacing={1} className="text-right">
              <Grid item md={12} xs={6}>
                <Button
                  className="add-btn"
                  onClick={() => toggleShowDetail(!isShowLess)}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
            {!isShowLess && (
              <>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <h3>Add New Users</h3>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <FormControl className="form-control">
                      <TextField
                        fullwidth
                        id="outlined-basic"
                        label="Name"
                        placeholder="Name"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <FormControl className="form-control">
                      <TextField
                        fullwidth
                        id="outlined-basic"
                        label="Email ID"
                        placeholder="Email ID"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <FormControl className="form-control">
                      <TextField
                        fullwidth
                        id="outlined-basic"
                        label="Login ID"
                        placeholder="Login ID"
                        variant="outlined"
                      />
                      {/* <span>Active</span> */}
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <FormControl className="form-control">
                      <TextField
                        fullwidth
                        id="outlined-basic"
                        label="Password"
                        placeholder="Password"
                        type="Password"
                        variant="outlined"
                      />
                      {/* <span>Show Password</span> */}
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Product
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={product}
                        onChange={handleChange}
                        label="Product"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Health</MenuItem>
                        <MenuItem value={2}>Car</MenuItem>
                        <MenuItem value={3}>Term</MenuItem>
                        <MenuItem value={4}>SME</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Insrure
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={product}
                        onChange={handleChange}
                        label="Product"
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Health</MenuItem>
                        <MenuItem value={2}>Car</MenuItem>
                        <MenuItem value={3}>Term</MenuItem>
                        <MenuItem value={4}>SME</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <DialogActions>
                      <Button className="add-btn">Add </Button>
                      <Button className="reset-btn">Reset Login Attampt</Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </>
            )}
            <div className="user-details-list">
              <div className="user-details-list-head">
                <ul>
                  <li>
                    <span>User ID</span>
                  </li>
                  <li>
                    <span>Name</span>
                  </li>
                  <li>
                    <span>EmailID</span>
                  </li>
                  <li>
                    <span>Mobile No</span>
                  </li>
                  <li>
                    <span>Login ID</span>
                  </li>
                  <li>
                    <span>User Type</span>
                  </li>
                  <li>
                    <span>User Roll</span>
                  </li>
                  <li>
                    <span>Application Access</span>
                  </li>
                  <li className="edit">
                    <span>Edit</span>
                  </li>
                </ul>
              </div>
              <div className="user-details-list-body">
                <ul>
                  <li>
                    <span>User ID</span>
                    <strong>81</strong>
                  </li>
                  <li>
                    <span>Name</span>
                    <strong>Gaurav Bahuguna</strong>
                  </li>
                  <li>
                    <span>EmailID</span>
                    <strong>gauravbahuguna@policybazaar.com</strong>
                  </li>
                  <li>
                    <span>Mobile No</span>
                    <strong>8979464484</strong>
                  </li>
                  <li>
                    <span>Login ID</span>
                    <strong>3232198</strong>
                  </li>
                  <li>
                    <span>User Type</span>
                    <strong>Insurer</strong>
                  </li>
                  <li>
                    <span>User Roll</span>
                    <strong></strong>
                  </li>
                  <li>
                    <span>Application Access</span>
                    <strong>Insurer View</strong>
                  </li>
                  <li className="edit">
                    <span>Edit</span>
                    <strong>
                      <Button
                        className="edit-btn"
                        onClick={() => toggleShowDetail(!isShowLess)}
                      >
                        <i className="fa fa-pencil"></i>
                      </Button>
                    </strong>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span>User ID</span>
                    <strong>1021</strong>
                  </li>
                  <li>
                    <span>Name</span>
                    <strong>Hitesh Singh</strong>
                  </li>
                  <li>
                    <span>EmailID</span>
                    <strong>hietshsingh@policybazaar.com</strong>
                  </li>
                  <li>
                    <span>Mobile No</span>
                    <strong>846448979</strong>
                  </li>
                  <li>
                    <span>Login ID</span>
                    <strong>19832212</strong>
                  </li>
                  <li>
                    <span>User Type</span>
                    <strong>Insurer</strong>
                  </li>
                  <li>
                    <span>User Roll</span>
                    <strong></strong>
                  </li>
                  <li>
                    <span>Application Access</span>
                    <strong>Insurer View</strong>
                  </li>
                  <li className="edit">
                    <span>Edit</span>
                    <strong>
                      <Button
                        className="edit-btn"
                        onClick={() => toggleShowDetail(!isShowLess)}
                      >
                        <i className="fa fa-pencil"></i>
                      </Button>
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
            <DialogActions>
              {/* <Button autoFocus className="add-btn text" onClick={close} color="primary" >
              save
            </Button> */}
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
