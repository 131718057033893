import React, { useState } from "react";
import { getFileExtension } from "../../../../../shared/methods/methods";
import PublishIcon from "@material-ui/icons/Publish";
import { Button, Tooltip } from "@material-ui/core";
import { DownloadAll, GetDocURL } from "../../../../../constants/apiUrls";
import { POST, GET } from "../../../../../services/apiServices";
import { useSnackbar } from "notistack";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const AttachementList = ({ attachments, isActionsAllowed, IsReadOnlyUser }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [downloadSuccessMsg, setDownloadSuccessMsg] = useState(false)
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const downloadAll = ()=> {
    POST(DownloadAll,attachments)
    .then((res)=> {
     var element = document.createElement('a');
     element.setAttribute('href', 'data:text/plain;base64,' + res);
     element.setAttribute('download', "AllDocuments.zip");
   
     element.style.display = 'none';
     document.body.appendChild(element);
   
     element.click();
   
     document.body.removeChild(element);
    })
    .catch((err) => {
      console.log(err);
    })

}
  const downloadAttachment = (docID, fileUrl) => {
    if (docID) {
      GET(`${GetDocURL}/${docID}`)
        .then((res) => {
          if (res && res.ErrorCode == 0 && res.Data) {
            window.open(res.Data, "_blank");
          } else {
            if (res.Response) {
              enqueueSnackbar(res.Response, {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            } else {
              enqueueSnackbar("URL not found", {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            }
            console.log("something went wrong");
          }
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log(err);
        });
    }
    else if(fileUrl){
      window.open(fileUrl, "_blank");
    } 
    else {
      enqueueSnackbar("Document not found", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      console.log("Document ID not found");
    }
  };

  return (
    <>
      <div className="attach-box email-attach-download">
      {
        isActionsAllowed && attachments && Array.isArray(attachments) && attachments.length > 0 && !IsReadOnlyUser
        ?
        <h5>
        
        <Tooltip
        title="Download All"
        aria-label="DownloadAll"
        placement="bottom-start" 
        >
          <Button className="download-btn" onClick={downloadAll} > Download All <PublishIcon/></Button>
        </Tooltip>
        </h5>
        :
        ""
      }
        {isActionsAllowed &&
          attachments &&
          Array.isArray(attachments) &&
          attachments.length > 0 &&
          attachments.map((attachement) => {
            return (
              attachement.FileName && (
                <span
                  className="attachemt-link"
                  style={{
                    cursor: IsReadOnlyUser ? "not-allowed" : "pointer",
                    pointerEvents: IsReadOnlyUser ? "none" : "",
                  }}
                  onClick={() =>
                    IsReadOnlyUser
                      ? ""
                      : downloadAttachment(
                          attachement.DocId,
                          //attachement.FileName,
                          attachement.AttachmentURL
                        )
                  }
                >
                  <div className="downlaod-item">
                    <span className="file-extn">
                      {getFileExtension(attachement.FileName) === "doc" ? (
                        <img src="/images/doc_icon.png" alt="doc_icon" />
                      ) : (
                        ""
                      )}
                      {getFileExtension(attachement.FileName) === "jpg" ||
                      getFileExtension(attachement.FileName) === "png" ||
                      getFileExtension(attachement.FileName) === "jpeg" ? (
                        <img src="/images/img_icon.png" alt="img_icon" />
                      ) : (
                        ""
                      )}
                      {getFileExtension(attachement.FileName) === "pdf" ? (
                        <img src="/images/pdf_icon.svg" alt="pdf_icon" />
                      ) : (
                        ""
                      )}
                      {getFileExtension(attachement.FileName) === "xlsx" ||
                      getFileExtension(attachement.FileName) === "xls" ||
                      getFileExtension(attachement.FileName) === "csv" ? (
                        <img src="/images/excel_icon.png" alt="excel_icon" />
                      ) : (
                        ""
                      )}
                    </span>
                    <p className="download-info">{attachement.FileName}</p>
                  </div>
                </span>
              )
            );
          })}
      </div>
    </>
  );
};

export default AttachementList;
