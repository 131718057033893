import { useEffect, useState, React } from "react";
import { Button,FormControl,TextField, FormHelperText } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { exportDashboardData_method, fetchDashboardData_method } from "../../../../shared/methods/apiMethods";
import { useSnackbar } from "notistack";
import CustomBackdrop from "../../../Components/CustomBackdrop";
import { HashLoader } from 'react-spinners';
import AdminDashboardDataV2 from "./AdminDashboardDataV2";
import { setAdminSimpleSearchData } from "../../../../redux/actions/pbDashboard_action";
import { quickSearchFieldLength } from "../../../../Config";


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
}));

const AdminSimpleSearchV2 = () => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const simpleSearchValue = useSelector(state=> state && state.pbDashboard && state.pbDashboard.adminSimpleSearch && state.pbDashboard.adminSimpleSearch.FilterString);
  const dispatch = useDispatch()
  const [textValue, setTextValue] = useState(simpleSearchValue);
  const [textValueTouched, setTextValueTouched] = useState(false);
  const [dataToRender, setDataToRender] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const handleChange = (event, enforceMinimumLength) =>{
    let { value } = event.target;
    setTextValue(value);
  }

  useEffect(()=>{
    if (simpleSearchValue) {
      setTextValueTouched(true);
      handleSearch(null, true);
    }
  }, []);


  const generatePayload = () =>{
    return {
      "IsUpdatedOn": "0",
      "FilterString": textValue,
      "FromDate": Date.now(),
      "ToDate": Date.now(),
      "Source": "",
      "SubSource": "",
      "ProductID": 0,
      "SupplierID": 0,
      "CBRequest": 0,
      "StatusID": 0,
      "SubStatusID": 0,
      "TATFilter": 0,
      "InsurerStatusID": 0,
      "STP": -1,
      "IsEsc": 0,
      "UserId": "90393",
      "Type": 'SIMPLESEARCH',
      "UserType": 1,
      "ProductIDs": ""
    }
  }

  const validate = () =>{
    setTextValueTouched(true);
    if(getError()){
      return false;
    }
    else return true;
  }

  const getError = () =>{
    let error;
    if(!textValue){
      error = 'Please enter value to contine'
    }
    else if(textValue && textValue.length < quickSearchFieldLength.min){
      error = `Min ${quickSearchFieldLength.min} characters required`
    }
    else if(textValue && textValue.length > quickSearchFieldLength.max){
      error = `Max ${quickSearchFieldLength.max} characters allowed`
    }
    else error = ''
    return error
  }

  const handleSearch = (e, directSearch = false) => {
    if(e){
      e.preventDefault();
    }
    if (!directSearch) {
      if (!validate()) { return }
    }
    console.log('direct search', directSearch)
    let url = '/GetDashboardDataV2';
    let payload = generatePayload();
      if(!directSearch){dispatch(setAdminSimpleSearchData(textValue))};
      let callback = (value) =>{
      setDataToRender(value);
      setDataLoading(false);
    }
    setDataLoading(true);
    fetchDashboardData_method(url, payload, callback);
  }


  const exportData =() =>{
    if(!validate()){ return };
    let url = '/GetDashboardDataExportV2';
    let payload = generatePayload();
    setDataLoading(true);
    let cb= () =>{
      console.log('here')
      return setDataLoading(false);
    }
    return exportDashboardData_method(url, payload, cb, 'SimpleSearch_TicketList');
  }

  const clearField = function(){
    setTextValue("");
    dispatch(setAdminSimpleSearchData(null));
    setDataToRender(null);
  }

  return (
    <div className={classes.root}>
      {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white'/></CustomBackdrop> : null}
      <div className="pbdashboard-simple-seach">
        <ul>
          <form onSubmit={handleSearch}>
            <li className="search-box search-box-v2">
              <FormControl className="form-control">
                <TextField
                  id="outlined-full-width simpleSearch"
                  placeholder="Search.."
                  fullWidth
                  variant="outlined"
                  value={textValue}
                  onChange={handleChange }
                  error={textValueTouched && Boolean(getError())}
                  />
                  {textValueTouched && Boolean(getError()) && <FormHelperText className="error-msg">{getError()}</FormHelperText>}
                <Button id="simpleSearchBtn" className="search-btn" type='submit' onClick={(e)=>handleSearch(e, false)}>Search</Button>
              </FormControl>
            </li>
            <li>
              <Button id="simpleExportBtn" className="export-btn" onClick={exportData}>Export</Button>
              <Button id="simpleClearBtn" className="clear-btn" onClick={clearField}>Clear</Button>
            </li>
          </form>
        </ul>
        <div>
        </div>
        {dataToRender && Array.isArray(dataToRender) && <AdminDashboardDataV2 dataToRender={dataToRender} />}
      </div>
    </div>
  );
};

export default AdminSimpleSearchV2;
