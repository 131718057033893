import React, { useState, useEffect, useReducer } from "react";
import CustomDialog from "../../../shared/components/CustomDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  Grid,
  FormControl,
  TextField,
  Select,
  InputLabel,
  Button,
  DialogActions,
} from "@material-ui/core";
import { GET, POST } from "../../../services/apiServices";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  Checkbox,
  IconButton,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import { CheckIfObjectInArrayObject } from "../../../shared/methods/methods";
import ErrorMessages from "../../../shared/constants";
import Spinner from "../../../shared/components/Spinner";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
    height: "40px,",
  },
  title: {
    flex: 1,
    fontSize: "16px",
  },
  formControl: {
    minWidth: "100%",
  },
  h6: {
    color: "#414141",
    fontSize: "14px",
  },
}));

const AddUserDialog = (props) => {
  const {
    title,
    userDetails,
    open,
    handleClose,
    showMessage,
    fetchWeCareAdminList,
  } = props;
  const classes = useStyles();
  const [productSupplierMasterList, setProductSupplierMasterList] = useState(
    []
  );
  const [productList, setProductList] = useState();
  const [insurerList, setInsurerList] = useState();
  const [formValues, setFormValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      UserName: "",
      EmailID: "",
      LoginID: "",
      Password: "",
      showPassword: false,
      selectedProduct: "",
      selectedInsurer: "",
      IsActive: false,
      userAccess: [],
      userAccessAdded: [],
    }
  );

  const [formValuesTouched, setFormValuesTouched] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      UserName: "",
      EmailID: "",
      LoginID: "",
      Password: "",
    }
  );

  const initialProductInsurer = {
    selectedProduct: null,
    selectedInsurer: null,
  };

  const [productInsurerError, setProductInsurerError] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      product: false,
      Insurer: false,
    }
  );
  const [addProductInsurer, setAddProductInsurer] = useState(
    initialProductInsurer
  );

  const [isLoading, setIsLoading] = useState(false);

  const productSupplierMasterAPI = () => {
    if (productSupplierMasterList.length == 0) {
      GET("GetProductSupplierMasterData")
        .then((res) => {
          if (
            res &&
            res.ErrorCode === 0 &&
            Array.isArray(res.Data) &&
            productSupplierMasterList.length == 0
          ) {
            setProductSupplierMasterList(res.Data);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const fetchUserDetailsFromAPI = (userID) => {
    let url = `GetWeCareAdminDetails/${userID}`;
    GET(url)
      .then((res) => {
        if (res && res.ErrorCode === 0 && res.Data) {
          let { USERNAME, EmailID, LoginID, IsActive, Password } =
            res.Data.Table[0];
          setFormValues({
            UserName: USERNAME,
            EmailID: EmailID,
            LoginID: LoginID,
            IsActive: IsActive,
            Password: Password,
            userAccessAdded: res.Data.Table1 || [],
            userAccess: res.Data.Table1 || [],
          });
        } else
          showMessage(
            res["Message"] || "Couldn't fetch details for the user",
            "error"
          );
      })
      .catch((err) => {
        if (err && err.response) {
          return showMessage(err.response.data.message, "error");
        } else showMessage("Something went wrong!", "error");
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (userDetails && title === "Edit User") {
      fetchUserDetailsFromAPI(userDetails.UserID);
    }
    productSupplierMasterAPI();
  }, []);

  useEffect(() => {
    if (productSupplierMasterList) {
      let productList = [];
      let obj;
      productSupplierMasterList.length > 0 &&
        productSupplierMasterList.map((item) => {
          let flag = 0;
          if (productList && productList.length > 0) {
            productList.forEach((singleItem) => {
              if (singleItem.ProductID == item.ProductID) {
                return (flag = 1);
              }
            });
          }
          if (flag == 0) {
            obj = {
              ProductID: item.ProductID,
              ProductName: item.ProductName,
            };
            productList.push(obj);
          }
        });
      setProductList(productList);
    }
  }, [productSupplierMasterList]);

  const handleSupplierProductChange = (product) => {
    let insurerList = [];
    let obj;
    if (
      productSupplierMasterList &&
      Array.isArray(productSupplierMasterList) &&
      productSupplierMasterList.length > 0
    ) {
      productSupplierMasterList.map((item) => {
        let flag = 0;
        if (item.ProductID == product) {
          if (insurerList && insurerList.length > 0) {
            insurerList.forEach((singleItem) => {
              if (singleItem.SupplierID == item.SupplierID) {
                return (flag = 1);
              }
            });
          }
          if (flag == 0) {
            obj = {
              SupplierID: item.SupplierID,
              SupplierName: item.SupplierName,
            };
            insurerList.push(obj);
          }
        }
      });
      setInsurerList(insurerList);
    } else setInsurerList([]);
  };

  const addInList = () => {
    if (
      (addProductInsurer && !addProductInsurer.selectedProduct) ||
      !addProductInsurer.selectedInsurer
    ) {
      return;
    }
    let obj = {
      ProductId: addProductInsurer.selectedProduct.ProductID,
      InsurerId: addProductInsurer.selectedInsurer.SupplierID,
      SupplierName: addProductInsurer.selectedInsurer.SupplierName,
      ProductName: addProductInsurer.selectedProduct.ProductName,
      IsActive: true,
      SNO: 0,
    };
    !CheckIfObjectInArrayObject(obj, ["IsActive","SNO"], formValues.userAccessAdded)
      ? setFormValues({
          userAccessAdded: [...formValues.userAccessAdded, obj],
          addProductInsurer: initialProductInsurer,
        })
      : showMessage("Access already added ", "error");
  };

  const deleteRecord = (record) => {
    let filteredArray = formValues.userAccessAdded.filter((item) => {
      let { InsurerId, InsurerName, ProductId, ProductName } = item;
      return (
        record.InsurerId !== InsurerId &&
        record.InsurerName !== InsurerName &&
        record.ProductId !== ProductId &&
        record.ProductName !== ProductName
      );
    });
    setFormValues({ userAccessAdded: filteredArray });
  };

  const changeActiveStatus = (record, event) => {
    let updatedArray = [];
    formValues.userAccessAdded.forEach((item) => {
      let { InsurerId, InsurerName, ProductId, ProductName } = item;
      let obj = item;
      if (
        record.InsurerId === InsurerId &&
        record.InsurerName === InsurerName &&
        record.ProductId === ProductId &&
        record.ProductName === ProductName
      ) {
        obj["IsActive"] = event.target.checked;
      }
      updatedArray.push(obj);
    });
    setFormValues({ userAccessAdded: updatedArray });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setFormValues({
      [name]: value,
    });
    setFormValuesTouched({ [name]: true });
  };

  const hasError = (field) => {
    let error;
    switch (field) {
      case "UserName":
        if (!formValues.UserName) {
          error = "Field cannot be empty";
        }
        break;
      case "EmailID":
        if (!formValues.EmailID) {
          error = "Field cannot be empty";
        } else if (
          formValues.EmailID &&
          !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(formValues.EmailID)
        ) {
          error = "Please enter correct email Address";
        }
        break;
      case "LoginID":
        if (!formValues.LoginID) {
          error = "Field cannot be empty";
        }
        break;
      case "Password":
        if (!formValues.Password) {
          error = "Field cannot be empty";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const validateInput = () => {
    //if any of the following fields have any error then this function returns false
    if (
      hasError("UserName") ||
      hasError("EmailID") ||
      hasError("LoginID" || hasError("Password"))
    ) {
      return false;
    } else return true;
  };

  //Same function for add/update user
  const addUpdateUser = () => {
    setFormValuesTouched({
      UserName: true,
      EmailID: true,
      LoginID: true,
      Password: true,
    });
    if (!validateInput()) {
      return;
    }
    if (
      formValues.userAccessAdded.length === 0 ||
      !formValues.userAccessAdded
    ) {
      setProductInsurerError({
        product: true,
        Insurer: true,
      });
      return showMessage("Please add access to proceed", "error");
    }
    if (isLoading) {
      return;
    }
    let payload = {
      UserId: userDetails && userDetails.UserID ? userDetails.UserID : 0,
      Name: formValues.UserName,
      MobileNo: null,
      EmailID: formValues.EmailID,
      LoginID: formValues.LoginID,
      Password: formValues.Password,
      IsActive: true,
      InsurerUserMapping: formValues.userAccessAdded || [],
    };
    let url = "AddUpdateWecareInsurer";
    setIsLoading(true);
    POST(url, payload)
      .then((res) => {
        setIsLoading(false);
        if (res && res.ErrorCode === 0) {
          showMessage(res.Message || "User successfully added!", "success");
          fetchWeCareAdminList();
          handleClose();
        } else showMessage(res.Message || "Operation Failed", "error");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.response) {
          return showMessage(
            err.response.data.message || ErrorMessages[err.response.status],
            "error"
          );
        } else showMessage("Something went wrong!", "error");
      });
  };

  const handleClickCancel = () => {
    fetchUserDetailsFromAPI(userDetails.UserID);
    setAddProductInsurer({ selectedProduct: null, selectedProduct : null });
    handleSupplierProductChange();
  };

  return (
    <div className="dialog-user-details">
      <CustomDialog open={open} title={title} handleClose={handleClose}>
        <div className="add-edit-box">
          <Grid container spacing={2}>
            <Grid item md={4} xs={6}>
              <FormControl className="form-control">
                <TextField
                  fullwidth
                  id="outlined-basic"
                  label="Name"
                  placeholder="Name"
                  name="UserName"
                  value={formValues.UserName}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
              {hasError("UserName") && formValuesTouched.UserName ? (
                <FormHelperText className="error-msg">
                  {hasError("UserName") && formValuesTouched.UserName
                    ? hasError("UserName")
                    : null}
                </FormHelperText>
              ) : null}
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl className="form-control">
                <TextField
                  fullwidth
                  id="outlined-basic"
                  label="Email ID"
                  placeholder="Email ID"
                  name="EmailID"
                  value={formValues.EmailID}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
              {hasError("EmailID") && formValuesTouched.EmailID ? (
                <FormHelperText className="error-msg">
                  {hasError("EmailID") && formValuesTouched.EmailID
                    ? hasError("EmailID")
                    : null}
                </FormHelperText>
              ) : null}
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl className="form-control">
                <TextField
                  fullwidth
                  id="outlined-basic"
                  label="Login ID"
                  placeholder="Login ID"
                  name="LoginID"
                  value={formValues.LoginID}
                  onChange={handleInputChange}
                  variant="outlined"
                  disabled={userDetails}
                />
              </FormControl>
              {hasError("LoginID") && formValuesTouched.LoginID ? (
                <FormHelperText className="error-msg">
                  {hasError("LoginID") && formValuesTouched.LoginID
                    ? hasError("LoginID")
                    : null}
                </FormHelperText>
              ) : null}
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl className="form-control password">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={formValues.showPassword ? "text" : "password"}
                  value={formValues.Password}
                  name="Password"
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setFormValues({
                            showPassword: !formValues.showPassword,
                          })
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {formValues.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              {hasError("Password") && formValuesTouched.Password ? (
                <FormHelperText className="error-msg">
                  {hasError("Password") && formValuesTouched.Password
                    ? hasError("Password")
                    : null}
                </FormHelperText>
              ) : null}
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Product
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={addProductInsurer.selectedProduct && addProductInsurer.selectedProduct.ProductID || addProductInsurer.selectedProduct}
                  label="Product"
                  error={
                    addProductInsurer &&
                    !addProductInsurer.selectedProduct &&
                    productInsurerError.product &&  formValues.userAccessAdded && formValues.userAccessAdded.length===0
                  }
                >
                  <MenuItem
                    value={0}
                    onClick={() => {
                      setAddProductInsurer({ selectedProduct: null });
                      handleSupplierProductChange();
                    }}
                  >
                    <em>Select Product</em>
                  </MenuItem>
                  {productList &&
                    productList.length > 0 &&
                    productList.map((item) => {
                      return (
                        <MenuItem
                          value={item.ProductID}
                          onClick={() => {
                            setAddProductInsurer({
                              ...addProductInsurer,
                              selectedProduct: item,
                            });
                            handleSupplierProductChange(item.ProductID);
                          }}
                        >
                          {item.ProductName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                // error={true}
                // error={
                //   addProductInsurer &&
                //   !addProductInsurer.selectedInsurer &&
                //   productInsurerError.Insurer
                // }
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Insurer
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={addProductInsurer.selectedInsurer && addProductInsurer.selectedInsurer.SupplierID || addProductInsurer.selectedInsurer} 
                  label="Product"
                  fullWidth
                  error={
                    addProductInsurer &&
                    !addProductInsurer.selectedInsurer &&
                    productInsurerError.Insurer && formValues.userAccessAdded && formValues.userAccessAdded.length===0
                  }
                >
                  <MenuItem
                    value={0}
                    onClick={() =>
                      setAddProductInsurer({ selectedInsurer: 0 })
                    }
                  >
                    <em>Select</em>
                  </MenuItem>
                  {insurerList &&
                    insurerList.length > 0 &&
                    insurerList.map((item) => {
                      return (
                        <MenuItem
                          value={item.SupplierID}
                          onClick={(e) => {
                            setAddProductInsurer({
                              ...addProductInsurer,
                              selectedInsurer: item,
                            });
                          }}
                        >
                          {item.SupplierName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        {/* <Grid container spacing={2}></Grid> */}
        <div className="add-btn-box">
          <Button className="add-btn" onClick={addInList}>
            Add Access{" "}
          </Button>
        </div>
        <Grid item md={12} sm={12}>
          {formValues &&
            formValues.userAccessAdded &&
            formValues.userAccessAdded.length > 0 && (
              <Grid item md={12} xs={12}>
                <div className="user-edit-add-list">
                  <div className="user-edit-add-list-head">
                    <ul>
                      <li>
                        <span>Product</span>
                      </li>
                      <li>
                        <span>Insurer</span>
                      </li>
                      <li>
                        <span>Active</span>
                      </li>
                      {/* <li>
                        <span>Delete</span>
                      </li> */}
                    </ul>
                  </div>
                  <div className="user-edit-add-list-body">
                    {formValues.userAccessAdded.map((item, index) => {
                      return (
                        <ul key={`access+${index}`}>
                          <li>
                            <span className="heading">Product</span>
                            <strong>{item.ProductName}</strong>
                          </li>
                          <li>
                            <span className="heading">Insurer</span>
                            <strong>{item.SupplierName}</strong>
                          </li>
                          <li>
                            <span className="heading">Product</span>
                            <Checkbox
                              checked={item.IsActive}
                              onChange={(e) => changeActiveStatus(item, e)}
                              name="checkedB"
                              color="primary"
                              size={20}
                            />
                          </li>
                          {/* {console.log(
                            "value of delete",
                            !CheckIfObjectInArrayObject(
                              item,
                              "IsActive",
                              formValues.userAccess
                            )
                          )}
                          {!CheckIfObjectInArrayObject(
                            item,
                            "IsActive",
                            formValues.userAccess
                          ) && (
                            <li>
                              <span>Delete</span>
                              <Button onClick={() => deleteRecord(item)}>
                                Delete this record
                              </Button>
                            </li>
                          )} */}
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </Grid>
            )}

          <Grid item md={12} xs={12}>
            <DialogActions>
              {title === "Edit User" && (
                <Button
                  onClick={handleClickCancel}
                  className="add-btn cancel-btn"
                >
                  Cancel
                </Button>
              )}
              <Button className="add-btn" onClick={addUpdateUser}>
                {isLoading ? (
                  <Spinner />
                ) : title === "Edit User" ? (
                  "Update"
                ) : (
                  "Save"
                )}{" "}
              </Button>
              {/* <Button className="reset-btn">Reset Login Attampt</Button> */}
            </DialogActions>
          </Grid>
        </Grid>
      </CustomDialog>
    </div>
  );
};

export default AddUserDialog;
