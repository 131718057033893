// this component is rendered if there is communication chain or multiple email communications for particular ticket
import React, { useRef, useState, useContext, useEffect } from "react";
// import CKEditor from "react-ckeditor-component";
import CKEditor from 'ckeditor4-react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  FormHelperText,
  InputBase,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import {
  GetHTMLTemplate,
  UpdateTicketRemarks,
  GetTicketSummary,
  GetTicketDetailsByTicketID,
  GetHtmlTemplate_V2,
} from "../../../../../constants/apiUrls";
import { GET, POST } from "../../../../../services/apiServices";
import { useSnackbar } from "notistack";
import {
  validateEmail,
  convertToIsoFormate,
  handleDrag,
  handleDragIn,
  handleDragOut,
  handleDrop,
  mailAttachments,
  openTriggerInputDialog,
} from "../../../../../shared/methods/methods";
import Spinner from "../../../../../shared/components/Spinner";
import {
  From,
  DisplayName,
  isCommunicationEligible,
  isReplyThreadEligible,
  SME,
  isEligibleForTicketNewComm,
  HEALTH,
  DISABLE_DEFAULT_TEMPLATE_LOADING,
  BLACKLIST_EMAILS
} from "../../../../../Config";
import CloseIcon from "@material-ui/icons/Close";
import CreateTicketDialog from "../../../../Topbar/components/CreateTicketDialog";
import ConfirmationDialog from "../../../../../shared/components/ConfirmationDialog";
import TicketContext from "../../../../../TicketContext";
import FileUploadComponent from "../../../../../shared/components/FileUploadComponent";
import { useSelector } from "react-redux";
import CommunicationDialogForTriggers from "./CommunicationDialogForTriggers";
import CustomBackdrop from "../../../../../pages/Components/CustomBackdrop";
import { ClockLoader } from 'react-spinners'; 
import { handleCatch } from "../../../../../shared/methods/apiMethods";
import Toast from "../../../../../shared/components/Toast";



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    minWidth: "100%",
  },
}));

export default function ReplyBoxContent({
  ticketData,
  oldTicketDetails,
  AccessTypeVal,
  viewtype,
  goToTemplate,
  setGoToTemplate,
  index,
  openedThroughSource,
}) {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  //for fetching templateList from redux store
  const templateList_store = useSelector(state => state && state.ticketdetails && state.ticketdetails.templateList || []);
  const templateListInsurer_store = useSelector(state => state && state.ticketdetails && state.ticketdetails.insurerTemplateList || [])
  // // for handling the single validation of subject

  useEffect(()=>{
    if(openedThroughSource === 'BMSInsurerActivity'){
      setTemplateList(templateListInsurer_store)
    }
    else setTemplateList(templateList_store)
  },[openedThroughSource, templateListInsurer_store, templateList_store])

  useEffect(()=>{
    if(ticketData){
      setSubject(ticketData && ticketData.Subject ? ticketData.Subject : "")
    }
    else setSubject("")
  },[ticketData])

  //reads from Store if the user is ReadOnlyUser
  const IsReadOnlyUser = useSelector(
    (state) =>
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser
  );


  const classes = useStyles();
  const [templateList, setTemplateList] = useState([])
  const ticketContext = useContext(TicketContext);
  const ReplyButtRef = React.useRef(null);
  const [inputMessage, setInputMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [canResponse, setCanResponse] = useState("");
  const [earlyCanResopnse, setEarlyCanResopnse] = useState("");
  const [replyInProgress, setReplyInProgress] = useState(false);
  const [replyType, setReplyType] = useState("");
  const [isCreateTicketDialogOpen, setIsCreateTicketDialogOpen] =
    useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [CCEmails, setCCEmails] = useState("");
  const [CCForwardEmails, setCCForwardEmails] = useState("");
  const [CCErrMsg, setCCErrMsg] = useState("");
  const [ToEmails, setToEmails] = useState("");
  const [ToErrMsg, setToErrMsg] = useState("");
  const [isCommunicationDialogOpen, setIsCommunicationDialogOpen] = useState(false);
  const [triggerInput, setTriggerInput] = useState([]);
  const [values, setValues] = useState([]);
  const [requestId, setRequestId] = useState(null);
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
  const [replyAppendMsg, setReplyAppendMsg] = useState("");
  const [subject, setSubject] = useState("");
  const [triggerBoxOpenedFirstTime, setTriggerBoxOpenedFirstTime] = useState(false) // for setting the default payload in case communication box is opened which takes trigger
  const [templateLoading, setTemplateLoading] = useState(false); 
  const [copiedTriggerInputs, setCopiedTriggerInputs] = useState([]);
  const [typeInParent, setTypeInParent] = useState("");
  const [ ckEditorReadOnly, setCKEditorReadOnly] = useState();
  const [isCKEDITORLoaded, setIsCKEDITORLoaded] = useState(false); // DO NOT DELETE
  const [customerUnreachable, setCustomerUnreachable] = useState(false); // useful only for HEALTH case

  const config = {
    width: "auto",
    toolbarGroups: [
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },

      { name: "links", groups: ["links"] },
      { name: "insert", groups: ["insert"] },

      { name: "colors", groups: ["colors"] },
      { name: "tools", groups: ["tools"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    format_tags: "p;h1;h2;h3;pre;div",
    removeDialogTabs: "image:advanced;link:advanced",
    allowedContent: true,
  };
  
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);

  useEffect(() => {

    if (replyType == "replyAll") {
      let data = [];
      //for filtering cc email
      if (ticketData.CCEmail && Array.isArray(ticketData.CCEmail)) {
        ticketData.CCEmail.forEach((email) => {
          if (email) {
            data.push(email);
          }
        });
      }
      if (oldTicketDetails && oldTicketDetails.ProductId === SME) {
        //for filtering to email
        if (ticketData.ToReceipent && Array.isArray(ticketData.ToReceipent)) {
          ticketData.ToReceipent.forEach((email) => {
            if (email && email !== oldTicketDetails.EmailId) {
              data.push(email);
            }
          })
        }
        //for filtering from email
        if (ticketData.From && ticketData.From !== oldTicketDetails.EmailId) {
          data.push(ticketData.From)
        }
      }
      let filteredUniqueMails = data.filter(item=> !BLACKLIST_EMAILS.includes(item));
      let unique = filteredUniqueMails.filter(onlyUnique).join(",");

      setCCEmails(unique);
    }
    else setCCEmails("");
    
  }, [replyType]);

  //for changing the read only flag based on type reply, replyall, forward
  useEffect(()=>{
    if(replyType && oldTicketDetails && isEligibleForTicketNewComm(oldTicketDetails.ProductId)){
      if (replyType === "forward") {
        setCKEditorReadOnly(false);
      } else {
        setCKEditorReadOnly(true);
      }
    }
    else setCKEditorReadOnly(false);
    setCustomerUnreachable(false); // default state setting if reply type changes from reply/reply all/forward --> reply/reply all/forward
  },[oldTicketDetails, replyType])

  const changeTriggerInputs = () => {
    if (canResponse != "select") {
      setTriggerInput(
        templateList && templateList.filter((i) => i.TriggerName == canResponse)[0]?.TriggerInput
      );
    }
    else setTriggerInput([]);
  }


  useEffect(()=>{
    if(templateList && templateList.length>0){
      changeTriggerInputs()
    }
    setCustomerUnreachable(false); // setting state of customerUnreachable if there is any change in option selected for template types
  }, [templateList, canResponse])


  const GetEmailList = (emails) => {
    const emailsArr = emails.split(",");
    const newArr = [];
    emailsArr.forEach((email) => {
      newArr.push(email.trim());
    });
    return newArr;
  };

  const getAllTicketSummary = (RefObjectId, TicketDetailsID) => {
    ticketContext.setAllTicketsLoading(true);
    GET(`${GetTicketSummary}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter(
                (i) => i.StatusID != 3 && i.StatusID != 4
              ).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData2 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 3).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData3 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 4).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          ticketContext.setAllTickets({
            ...response.Data,
            AllTicketList: [...sortedData1, ...sortedData2, ...sortedData3],
          });
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        ticketContext.setAllTicketsLoading(false);
        ticketContext.setActiveTicketId(response.Data.TicketId);
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false);
        handleCatch(err, showMessage);
      });
  };

  const GetTicketDetailsByTicketIdApi = (RefObjectId, TicketDetailsID) => {
    GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest, IsInsurerBucket } = response.Data;

          let callbackVal;
          if (IsCallBackRequest === "0") {
            callbackVal = "No";
          } else if (IsCallBackRequest === "1") {
            callbackVal = "Yes";
          } else if (IsCallBackRequest === "2") {
            callbackVal = "Completed";
          }

          ticketContext.setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
          ticketContext.setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        handleCatch(err, showMessage);
        console.log(err);
      });
  };

  const handleReplySend = () => {
    if (replyInProgress || CCErrMsg || ToErrMsg) {
      return;
    }
    const payload = {
      PrevObjectID: oldTicketDetails.RefObjectId,
      TicketId: oldTicketDetails.TicketId,
      TicketDetailId: oldTicketDetails.TicketDetailId,
      // Subject: subject,
      Subject: subject,
      ConversationTopic: ticketData.ConversationTopic,
      LeadID: oldTicketDetails.LeadID,
      CustomerName: oldTicketDetails.CustomerName,
      CustomerID: oldTicketDetails.CustomerId,
      ProductID: oldTicketDetails.ProductId,
      ProductName: oldTicketDetails.ProductName,
      IsSpam: ticketData.IsSpam,
      IsManualComment: ticketData.IsManualComment,
      From: From,
      CCEmail:
        (replyType === "replyAll" || replyType === "forward") && CCEmails
          ? GetEmailList(CCEmails)
          : replyType === "forward" && CCForwardEmails
          ? GetEmailList(CCForwardEmails)
          : "",
      ToReceipent:
        replyType === "reply" || replyType === "replyAll"
          ? [oldTicketDetails.EmailId]
          : GetEmailList(ToEmails),
      BccEmail: ticketData.BccEmail,
      Body: inputMessage,
      IsInsurer: false,
      IsMoved: ticketData.IsMoved,
      MailAttachments: mailAttachments(replyType, ticketData.MailAttachments || [], selectedFile),
      HasAttachment: selectedFile.length > 0 ? true : false,
      DisplaySenderName: DisplayName,
      TicketSource: oldTicketDetails.Source,
      ActionType: ticketData.ActionType,
      StatusName: oldTicketDetails.Status,
      SubStatusName: oldTicketDetails.SubStatusName,
      InsurerID: oldTicketDetails.SupplierId,
      ParentID: ticketData.ParentID,
      StatusID: oldTicketDetails.StatusID,
      SubStatusID: oldTicketDetails.SubStatusID,
      GroupId: ticketData.GroupId,
      TicketSubSource: ticketData.TicketSubSource,
      RequestId: requestId,
      EscalationType: oldTicketDetails.EscalationType,
      IssueTypeName: oldTicketDetails.IssueName,
      TicketDetailsID: oldTicketDetails.TicketDetailsID,
      IsJAG:
        oldTicketDetails && oldTicketDetails.IsJAG ? oldTicketDetails.IsJAG : 0,
      JAGEmployeeId:
        oldTicketDetails && oldTicketDetails.JAGEmployeeId
          ? oldTicketDetails.JAGEmployeeId
          : "",
      IssueTypeID:
        oldTicketDetails && oldTicketDetails.IssueTypeId
          ? oldTicketDetails.IssueTypeId
          : 0,
      CxUnreachable : customerUnreachable || false, // customer unreachable
      HCSSource: oldTicketDetails && oldTicketDetails.HCSSource
    };
    debugger
    
    setReplyInProgress(true);
    POST(
      `${UpdateTicketRemarks}/${
        canResponse
          ? canResponse
          : templateList && templateList.length > 0
          ? templateList[0].TriggerName
          : "DefaultTemplate"
      }`,
      payload
    )
      .then((res) => {
        if (res.ErrorCode === 0) {
          ticketContext.GetTicketCommApi(
            oldTicketDetails.RefObjectId,
            oldTicketDetails.TicketDetailsID
          );

          if (replyType === "reply" || replyType === "replyAll") {
            getAllTicketSummary(
              oldTicketDetails.RefObjectId,
              oldTicketDetails.TicketDetailsID
            );
            GetTicketDetailsByTicketIdApi(
              oldTicketDetails.RefObjectId,
              oldTicketDetails.TicketDetailsID
            );
          }

          enqueueSnackbar(res.Message || "Success", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
          ticketContext.GetTicketLogsApi();
          ticketContext.GetFilterCountApi();
          handleDisCard();
        } else {
          enqueueSnackbar(res.Message || "Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setReplyInProgress(false);
      })
      .catch((err) => {
        handleCatch(err, showMessage);
        setReplyInProgress(false);
        window.location.reload(false);
      });
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onEditorChange = (event) => {
    setInputMessage(event.editor.getData());
  };

  const [selectedFile, setselectedFile] = useState([]);

  const hiddenFileInput = useRef(null);

  const getHtmlTemplateApi = (triggerName, msg = "") => {
    let payload;
    let apiEndPoint = GetHTMLTemplate;
    setTemplateLoading(true);
    if (isCommunicationEligible(oldTicketDetails.ProductId)) {
      apiEndPoint = GetHtmlTemplate_V2;
      payload = {
        TriggerName: triggerName,
        LeadID: oldTicketDetails.LeadID,
        TicketId: oldTicketDetails.TicketId,
        CommunicationType: 1,
        ProductId: oldTicketDetails.ProductId,
        RefObjectId: oldTicketDetails.RefObjectId,
        CustomerId : oldTicketDetails?.CustomerId
      };
    } else {
      payload = {
        ProductID: oldTicketDetails.ProductId,
        TriggerName: triggerName,
        RefObjectID: oldTicketDetails.RefObjectId,
        Type: 1,
        CustomerId : oldTicketDetails?.CustomerId
      };
    }

    POST(apiEndPoint, payload)
      .then((res) => {
        setTemplateLoading(false);
        if (res.ErrorCode === 0) {
          setInputMessage(
            isCommunicationEligible(oldTicketDetails.ProductId)
              ? res.Data.HtmlBody + msg
              : res.Data.EmailTemplate + msg
          );
          setSubject(
            res.Data.Subject || res.Data.SubjectTemplate || ticketData.Subject || ""
          );
          setselectedFile(res.Data.MailAttachments ? res.Data.MailAttachments.map((item, index)=>  ({...item, id : index })) : []); // for new template requirement
          setSendBtnDisabled(false);
        } else {
          enqueueSnackbar(res['Message'] || "Failed to fetch HTML body!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          setSendBtnDisabled(false);
          setCanResponse("select");
        }
      })
      .catch((err) => {
        setTemplateLoading(false);
        setCanResponse("select");
        handleCatch(err, showMessage);
      });
  };

  useEffect(()=>{
    if(values && values.length>0 && typeInParent){
      fetchEmailBody(canResponse, replyAppendMsg);
    }
  },[values])


  const fetchEmailBody = (triggerName, _msg = "", CxUnreachable=customerUnreachable) => {
    setIsTemplateLoaded(true);
    setTemplateLoading(true);
    let tempFilteredTemplateList = templateList && templateList.filter((i) => i.TriggerName == canResponse)[0]?.TriggerInput || [];
    let RequiredPayloadTriggerNames =[];
    if(tempFilteredTemplateList && tempFilteredTemplateList.length>0){
      RequiredPayloadTriggerNames= tempFilteredTemplateList.map(item=> item.InputName)
    }
    let RequiredTriggerWithValues = {};
    RequiredPayloadTriggerNames && RequiredPayloadTriggerNames.length> 0 && RequiredPayloadTriggerNames.forEach(trigger=>{
      RequiredTriggerWithValues[trigger] = Array.isArray(values) && values.length > 0 ? values.map(item => item[trigger]).filter(item => item).join(",") : triggerBoxOpenedFirstTime ? `<${trigger}>` : "";
    })
    console.log('trigger values--->', RequiredTriggerWithValues)
    const payload = {
      triggerName: triggerName,
      leadID: oldTicketDetails.LeadID,
      ticketId: oldTicketDetails.TicketId,
      communicationType: 1,
      productId: oldTicketDetails.ProductId,
      refObjectId: oldTicketDetails.RefObjectId,
      IsMultipleInput: values && values.length>1 ? true : false,
      triggerInput: {
        ...RequiredTriggerWithValues,
        TATType: typeInParent ? typeInParent : "",
        CxUnreachable: CxUnreachable,
        },
      CustomerId : oldTicketDetails?.CustomerId
    };
    POST(GetHtmlTemplate_V2, payload)
      .then((res) => {
        setCopiedTriggerInputs([...values]); // copies the trigger input values for the validation purpose- Hitesh
        setTemplateLoading(false);
        setSendBtnDisabled(false);
        let msg = `
        <div>---------- Forwarded message ----------</div>
        <div>From: ${ticketData.From}</div>
        <div>Sent: ${
          ticketData.DisplayDate
            ? convertToIsoFormate(ticketData.DisplayDate)
            : ""
        }</div>
        <div>To: ${
          ticketData.ToReceipent &&
          Array.isArray(ticketData.ToReceipent) &&
          FilteredEmailList(ticketData.ToReceipent).length > 0
            ? FilteredEmailList(ticketData.ToReceipent).join(",")
            : ""
        }</div>
        <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""}</div>
        <div>${ticketData.Body}</div>
        `;
        if (res.ErrorCode === 0) {
          if (replyType == "forward") {
            setInputMessage(res.Data.HtmlBody + msg);
            // setSubject(res.Data.Subject);
          } else {
            setInputMessage(res.Data.HtmlBody +_msg);
          }
          setselectedFile(res.Data.MailAttachments ? res.Data.MailAttachments.map((item, index)=>  ({...item, id : index })) : []); // for new template requirement
          setSubject(res.Data.Subject);
          setIsCommunicationDialogOpen(false);
          setValues([]);
          setRequestId(res.Data.RequestId);
          setIsTemplateLoaded(false);
        } else {
          enqueueSnackbar(res.Message, {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          setCanResponse(canResponse);
          setIsCommunicationDialogOpen(false);
          setValues([]);
          setIsTemplateLoaded(false);
          setCanResponse("select");
          // setInputMessage("")
        }
      })
      .catch((err) => {
        setTemplateLoading(false);
        setCanResponse("select");
        setTriggerBoxOpenedFirstTime(false);
        handleCatch(err, showMessage);
        setIsCommunicationDialogOpen(false);
        console.log(err);
      });
  };

  useEffect(() =>{
    setTriggerBoxOpenedFirstTime(true);
    setCopiedTriggerInputs([]);
    setValues([]);
    setTypeInParent("");
    if(canResponse){
      handleTemplateChange();
    }
  }, [canResponse])

  const handleTemplateChange = () => {
    if (canResponse != "select") {
      if (oldTicketDetails && isCommunicationEligible(oldTicketDetails.ProductId)) {
        if (
          templateList && templateList.length>0 && 
          templateList.filter((i) => i.TriggerName == canResponse).length > 0 &&
          templateList.filter((i) => i.TriggerName == canResponse)[0]
            ?.TriggerInput?.length > 0
        ) {
          fetchEmailBody(canResponse, replyAppendMsg);
        } else {
          fetchEmailBody(canResponse, replyAppendMsg);
        }
      } else {
        getHtmlTemplateApi(canResponse, replyAppendMsg);
      }
    } else if (canResponse == "select") {
      setInputMessage("");
    }
  };

  const handleDisCard = () => {
    setInputMessage("");
    setCanResponse("");
    setselectedFile([]);
    setIsOpen(false);
    setReplyType("");
  };

  const FilteredEmailList = (emails) => {
    const data = [];
    emails.forEach((email) => {
      if (email) {
        data.push(email);
      }
    });
    return data;
  };
  useEffect(() => {
    if (goToTemplate && index == 0) {
      ReplyButtRef.current.click(); // focusing on the latest mail replyall button
      handleReplyFromCompose();
    }
  }, [goToTemplate]);

  const handleReplyFromCompose = () => {
    setGoToTemplate(false);
    let msg = "";
    if (isReplyThreadEligible(ticketData.TicketSource)) {
      msg = `
      <html>
      <head></head>
      <body>
      <div style="text-align:left; border-top:1px dashed #ccc;padding:5px;">
      <div>From: ${ticketData.From}</div>
      <div>Sent: ${ticketData.DisplayDate
          ? convertToIsoFormate(ticketData.DisplayDate)
          : ""
        }</div>
      <div>To: ${ticketData.ToReceipent &&
          Array.isArray(ticketData.ToReceipent) &&
          FilteredEmailList(ticketData.ToReceipent).length > 0
          ? FilteredEmailList(ticketData.ToReceipent).join(",")
          : ""
        }</div>
      <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""}</div>
      <div>${ticketData.Body}</div>
      </div>
      </body>
      <html>
      `;
    }
    setReplyAppendMsg(msg);
    setCanResponse("HI_Refund_Em_CancellationCxIneligible"); // this inturn fetches the html template because of side effect
    // getHtmlTemplateApi("HI_Refund_Em_CancellationCxIneligible", msg);
  };

  const handleReplyClick = (replyTypeVal) => {
    if (oldTicketDetails.ProductId === SME &&
      (!oldTicketDetails.SubStatusID ||
        !oldTicketDetails.IssueTypeId ||
        !oldTicketDetails.SubIssueID)) {
      return Toast.error("Sub-Status, Issue, Sub-Issue  needs to be there for the Ticket")
    } 
    else {
      let msg = "";
      switch (replyTypeVal) {
        case "reply":
          if (isReplyThreadEligible(ticketData.TicketSource)) {
            msg = `
            <html>
            <head>
            </head>
            <body>
            <div style="text-align:left; border-top:1px dashed #ccc;padding:5px;">
              <div>From: ${ticketData.From}</div>
              <div>Sent: ${ticketData.DisplayDate
                ? convertToIsoFormate(ticketData.DisplayDate)
                : ""
              }</div>
              <div>To: ${ticketData.ToReceipent &&
                Array.isArray(ticketData.ToReceipent) &&
                FilteredEmailList(ticketData.ToReceipent).length > 0
                ? FilteredEmailList(ticketData.ToReceipent).join(",")
                : ""
              }</div>
              <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""
              }</div>
              <div>${ticketData.Body}</div>
            </div>
            </body>
            </html>
            `;
          }
          setReplyAppendMsg(msg);
          if (isCommunicationEligible(oldTicketDetails.ProductId)) {
            let b = templateList
              .map((i) => i.TriggerName)
              .indexOf("All_Ticket_Em_DefaultTemplate");
            if (b > -1) {
              // for the case of default loading of template
              setCanResponse(
              DISABLE_DEFAULT_TEMPLATE_LOADING.includes(oldTicketDetails.ProductId) ?
              "select" : templateList.filter(i => i.TriggerName == "All_Ticket_Em_DefaultTemplate")[0].TriggerName);

            }
            else{
              setCanResponse("select");
            }
          } else {
            setCanResponse(
              templateList && templateList.length > 0
                ? templateList[0].TriggerName
                : "DefaultTemplate"
            );
          }
          break;
        case "replyAll":
          // only for the case of SME products and not source of ticket
          if (ticketData.ProductID == 131) {
            msg = `
            <html>
            <head></head>
            <body>
            <div style="text-align:left; border-top:1px dashed #ccc;padding:5px;">
            <div>From: ${ticketData.From}</div>
            <div>Sent: ${ticketData.DisplayDate
                ? convertToIsoFormate(ticketData.DisplayDate)
                : ""
              }</div>
            <div>To: ${ticketData.ToReceipent &&
                Array.isArray(ticketData.ToReceipent) &&
                FilteredEmailList(ticketData.ToReceipent).length > 0
                ? FilteredEmailList(ticketData.ToReceipent).join(",")
                : ""
              }</div>
            <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""}</div>
            <div>${ticketData.Body}</div>
            </div>
            </body>
            </html>
            `;
          }
          setReplyAppendMsg(msg); // for api call 2nd paramter
          //--------------------------------------------------------

          if (isCommunicationEligible(oldTicketDetails.ProductId)) {
            let b = templateList
              .map((i) => i.TriggerName)
              .indexOf("All_Ticket_Em_DefaultTemplate");

            if (b > -1) {
              setCanResponse(
                DISABLE_DEFAULT_TEMPLATE_LOADING.includes(oldTicketDetails.ProductId) ?
                  "select" : templateList.filter(i => i.TriggerName == "All_Ticket_Em_DefaultTemplate")[0].TriggerName);
            } else {
              setCanResponse("select");
            }
          } else {
            setCanResponse(
              templateList && templateList.length > 0
                ? templateList[0].TriggerName
                : "DefaultTemplate"
            );
          }
          // for all other cases
          if (isReplyThreadEligible(ticketData.TicketSource)) {
            msg = `
            <html>
            <head></head>
            <body>
            <div style="text-align:left; border-top:1px dashed #ccc;padding:5px;">
            <div>From: ${ticketData.From}</div>
            <div>Sent: ${ticketData.DisplayDate
                ? convertToIsoFormate(ticketData.DisplayDate)
                : ""
              }</div>
            <div>To: ${ticketData.ToReceipent &&
                Array.isArray(ticketData.ToReceipent) &&
                FilteredEmailList(ticketData.ToReceipent).length > 0
                ? FilteredEmailList(ticketData.ToReceipent).join(",")
                : ""
              }</div>
            <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""}</div>
            <div>${ticketData.Body}</div>
            </div>
            </body>
            </html>
            `;
          }
          setReplyAppendMsg(msg); // for api call 2nd paramter
          break;
        case "forward":
          msg = `
          <div>---------- Forwarded message ----------</div>
          <div>From: ${ticketData.From}</div>
          <div>Sent: ${
            ticketData.DisplayDate
              ? convertToIsoFormate(ticketData.DisplayDate)
              : ""
          }</div>
          <div>To: ${
            ticketData.ToReceipent &&
            Array.isArray(ticketData.ToReceipent) &&
            FilteredEmailList(ticketData.ToReceipent).length > 0
              ? FilteredEmailList(ticketData.ToReceipent).join(",")
              : ""
          }</div>
          <div>Subject: ${ticketData.Subject ? ticketData.Subject : ""}</div>
          <div>${ticketData.Body}</div>
          `;
          setInputMessage(msg);
          break;

        default:
          break;
      }

      if (!isOpen) {
        setIsOpen(true);
        // ** DO NOT CHANGE **
        setIsCKEDITORLoaded(false); // for re-setting the instance of CK-EDITOR
      }
      if (replyType === replyTypeVal && isOpen) {
        handleDisCard();
      } else {
        setReplyType(replyTypeVal);
      }
    }
  };

  const handleRemoveFile = (id) => {
    const fileList = selectedFile.filter((item) => item.id !== id);
    setselectedFile(fileList);
  };

  const sourceArr = ["PB Manual", "PBReply"];

  const showMessage = (message, varient) =>{
    enqueueSnackbar( message, {
      variant: varient,
      onClick: () => {
        closeSnackbar();
      },
    });
  }

  const showErrMsg = (message) => showMessage(message, "error");

  const handleCCAndTo = (e, type) => {
    const emails = e.target.value.split(",");
    let isErrMsg = false;
    emails.forEach((email) => {
      const trimmedEmail = email.trim();
      if (!validateEmail(trimmedEmail)) {
        type === "cc"
          ? setCCErrMsg("Invalid email")
          : setToErrMsg("Invalid email");
        isErrMsg = true;
        return;
      } else {
        isErrMsg = false;
      }
    });

    if (!isErrMsg || !e.target.value) {
      type === "cc" ? setCCErrMsg("") : setToErrMsg("");
    }

    type === "cc" && replyType != "forward"
      ? setCCEmails(e.target.value)
      : type === "cc" && replyType == "forward"
      ? setCCForwardEmails(e.target.value)
      : setToEmails(e.target.value);
  };

  const sendClicked = () => {
    if (canResponse == "select" && replyType!=="forward") {
      return showErrMsg("Please select a template")
    }
    
    if(replyType === "forward" ){
      if(!ToEmails){
        return showErrMsg("Enter To email address");
      }
      if(ToErrMsg){
        return showErrMsg("Enter correct To address");
      }
      if(CCForwardEmails && CCErrMsg ){
        return showErrMsg("Enter correct Cc address")
      }
    }

    // //===related to subject=========
    console.log('ticketdata-->', ticketData, subject)
    if(subject && /^\s*[a-zA-Z0-9@-]+.*$/.test(subject)){
      console.log('Subject is present');
    }
    else return showErrMsg("Subject is blank/not in format")
    // ============================

    if (!oldTicketDetails.SubIssueName && !oldTicketDetails.IssueName) {
      let list = [
        "All_Ticket_Em_ResolvedRedirectMyaccountMail",
        "All_Ticket_Em_InboundResolved",
      ];
      if (list.indexOf(canResponse) > -1) {
        enqueueSnackbar(
          "You cannot send this trigger if Issue/SubIssue is  not mapped",
          {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          }
        );
        return;
      }
    }
    if (
      oldTicketDetails &&
      oldTicketDetails.EndorsementID &&
      oldTicketDetails.EndorsementID > 0
    ) {
      let list = [
        "All_Ticket_Em_ResolvedRedirectMyaccountMail",
        "All_Ticket_Em_InboundResolved",
      ];
      if (list.indexOf(canResponse) > -1) {
        enqueueSnackbar(
          "You cannot send this trigger if endorsement is mapped",
          {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          }
        );
        return;
      }
    }
    if (
      oldTicketDetails &&
      oldTicketDetails.RefundId &&
      oldTicketDetails.RefundId > 0 &&
      oldTicketDetails.StatusID != 3
    ) {
      let list = [
        "All_Ticket_Em_ResolvedRedirectMyaccountMail",
        "All_Ticket_Em_InboundResolved",
      ];
      if (list.indexOf(canResponse) > -1) {
        enqueueSnackbar(
          "You cannot send this trigger if the cancellation request is under process ",
          {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          }
        );
        return;
      }
    }
    if (
      oldTicketDetails &&
      oldTicketDetails.Source &&
      !(["inbound", "c2c","chat"].indexOf(oldTicketDetails.Source.toLowerCase()) > -1)
    ) {
      let list = [
        "All_Ticket_Em_ResolvedRedirectMyaccountMail",
        "All_Ticket_Em_InboundResolved",
      ];
      if (list.indexOf(canResponse) > -1) {
        enqueueSnackbar(
          "You can only send this trigger on IB,C2C and Chat source tickets",
          {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          }
        );
        return;
      }
    }

    if(triggerInput && triggerInput.length>0 && copiedTriggerInputs && !validateTriggersInputs()){
      return showMessage("Please update all fields to continue","error")
    }

    if (inputMessage.length > 0) {
      setShowConfirmationBox(true);
      // window.location.reload(false);
    } else {
      enqueueSnackbar("Mail body cannot be empty!", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      return;
    }
  };

  const validateTriggersInputs = () =>{
    let flag = true;
    let keys = triggerInput.map(item => item.InputName)
    if(copiedTriggerInputs && copiedTriggerInputs.length>0 && copiedTriggerInputs[0]){
      Object.keys(copiedTriggerInputs[0]).forEach(item=>{
        if(!copiedTriggerInputs[0][item]){
          flag = false;
        }
      })
      if(Object.keys(copiedTriggerInputs[0]).length !== keys.length){
        flag = false;
      }
    }
    else flag = false;

    if(flag){
      return true
    }
    else return false 
  }

  //handling checkbox selection for customer unreachable for non-editable templates
  const handleCheckboxSelection = (e) =>{
    setValues([]);
    setCopiedTriggerInputs([]);
    setTypeInParent("");
    setCustomerUnreachable(e.target.checked);
    fetchEmailBody(canResponse, replyAppendMsg, e.target.checked);
  } 

  return (
    <>
      <Grid container>
        {templateLoading && <CustomBackdrop open = {templateLoading}><ClockLoader color="white"/></CustomBackdrop>}
        {oldTicketDetails.ParentTicketID || !ticketContext.isActionsAllowed
          ? ""
          : viewtype == 0 &&
            !IsReadOnlyUser && (
              <Grid item md={8} xs={12}>
                <Button
                  className={
                    "response-chip" + (replyType == "reply" ? " active" : "")
                  }
                  onClick={() => handleReplyClick("reply")}
                  id="replyBtn"
                  // disabled={!isValidForSME}
                >
                  <img src="/images/reply_black.svg" alt="Reply" /> Reply
                </Button>
                <Button
                  className={
                    "response-chip" + (replyType == "replyAll" ? " active" : "")
                  }
                  onClick={() => handleReplyClick("replyAll")}
                  ref={index == 0 ? ReplyButtRef : null}
                  id="replyAllBtn"

                  // disabled={!isValidForSME}
                >
                  <img src="/images/reply_all.svg" alt="reply all" /> reply all
                </Button>
                {ticketData.TicketSource !== "PB Manual" ? (
                  <Button
                    className={
                      "response-chip" +
                      (replyType == "forward" ? " active" : "")
                    }
                    onClick={() => handleReplyClick("forward")}
                    id="forwardBtn"
                    // disabled={!isValidForSME}
                  >
                    <img src="/images/forward.svg" alt="forward " /> forward
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            )}
        {sourceArr.includes(ticketData.TicketSource) ||
        oldTicketDetails.ParentTicketID ||
        oldTicketDetails.StatusID === 4 ||
        oldTicketDetails.StatusID === 3 ||
        oldTicketDetails.StatusID === 6 ||
        !ticketContext.isActionsAllowed
          ? ""
          : viewtype == 0 &&
            !IsReadOnlyUser && (
              <Grid item md={4} xs={12} className="create-update">
                <Button
                  className="response-chip"
                  onClick={() => setIsCreateTicketDialogOpen(true)}
                  id="createTicketFromMail"
                >
                  <img
                    src="/images/confirmation_number.svg"
                    alt="Create Ticket"
                  />
                  Create Ticket
                </Button>
              </Grid>
            )}
      </Grid>

      {isOpen && (
        <div
          className="reply-popup"
          onDragOver={handleDrag}
          onDrop={(e) => handleDrop(e, setselectedFile, hiddenFileInput)}
          onDragLeave={handleDragOut}
          onDragEnter={handleDragIn}
        >
          <Grid container spacing={1}>
            {replyType !== "forward" && (
              <Grid item md={4} sm={4} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Can Response
                  </InputLabel>
                  <Select
                    native
                    value={canResponse}
                    // onChange={(e) => handleCanResponseChange(e)}
                    onChange={(e)=> setCanResponse(e.target.value)}
                    label="Can Response"
                    inputProps={{
                      name: "canResponse",
                      id: "outlined-age-native-simple canResponse",
                    }}
                  >
                    {isCommunicationEligible(oldTicketDetails.ProductId) ? (
                      <option value="select">Select</option>
                    ) : null}
                    {templateList.map((item) => {
                      return (
                        <option value={item.TriggerName}>
                          {item.Description}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container className="mail-to-info">
            <Grid item md={12} sm={12} xs={12} className="border-bottom">
              <strong className="mail-to-input">
                {replyType !== "forward" && "To: "}
                <span id="mailInfoEmailId">
                  {replyType === "reply" || replyType === "replyAll" ? (
                    oldTicketDetails.EmailId
                  ) : (
                    <FormControl variant="outlined" error={ToErrMsg}>
                      <label className="lable-to">To:</label>
                      <TextField
                        fullWidth
                        id={`${replyType}-to-address`}
                        value={ToEmails}
                        onChange={(e) => handleCCAndTo(e, "to")}
                      />
                      {ToErrMsg && <FormHelperText>{ToErrMsg}</FormHelperText>}
                    </FormControl>
                  )}
                </span>
              </strong>
            </Grid>
            <span
              style={{
                fontSize: "11px",
                color: "#253858",
                background: "#f7f7f6cc",
                padding: "3px 6px",
                borderRadius: "4px",
                margin: "2px 0 0",
              }}
            >
              {replyType === 'forward' ? `(Add multiple emails with ',' separated.)` : ""}
            </span>
            {(replyType === "replyAll" || replyType === "forward") && (
              <Grid item md={12} sm={12} xs={12} className="border-bottom">
                <strong className="mail-to-input">
                  <FormControl variant="outlined" error={CCErrMsg}>
                    <label className="lable-to">CC:</label>
                    <TextField
                      fullWidth
                      id="mailInfoCCMail"
                      value={
                        replyType != "forward" ? CCEmails : CCForwardEmails
                      }
                      onChange={(e) => handleCCAndTo(e, "cc")}
                    />
                    {CCErrMsg && <FormHelperText>{CCErrMsg}</FormHelperText>}
                  </FormControl>
                </strong>
              </Grid>
            )}

            <Grid item md={12} sm={12} xs={12} className="border-bottom">
              <strong>
                Subject:{" "}
                <InputBase
                  className={classes.margin}
                  // defaultValue={ticketData.Subject}
                  inputProps={{ "aria-label": "Subject" }}
                  id="mailInfoSubjectValue"
                  value={subject}
                  // value={subject ? subject : ticketData.Subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </strong>
            </Grid>
            {replyType == "forward" ? (
              <Grid item md={12} sm={12} xs={12}>
                <div className="view-attachements">
                  <strong>Attachments: </strong>
                  {ticketData.MailAttachments &&
                  Array.isArray(ticketData.MailAttachments)
                    ? ticketData.MailAttachments.map((item) => {
                        return <span id="mailInfoFileName">{item.FileName} </span>;
                      })
                    : ""}
                </div>
              </Grid>
            ) : (
              ""
            )}
            <Grid item md={12} xs={12} className="reply-box" >
              <div className="enter-variable-btn">
                {canResponse && canResponse !== "select" && oldTicketDetails && isEligibleForTicketNewComm(oldTicketDetails.ProductId) && <FormGroup>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" checked={customerUnreachable} onChange={handleCheckboxSelection} />}
                    label="Customer not reachable"
                    labelPlacement="Customer not reachable"
                    id="custumerNotReachable"
                  />
                </FormGroup>}
                {triggerInput && triggerInput.length > 0 ? <Button onClick={() => openTriggerInputDialog(triggerInput, setIsCommunicationDialogOpen)}>Update Field</Button> : null}
              </div>
              <CKEditor
                data={inputMessage ? inputMessage : ""}
                config={config}
                onChange={(event) => {
                  onEditorChange(event)

                }}
                onInstanceReady={(instance) => {
                  instance.data = inputMessage
                  setIsCKEDITORLoaded(true); //** DO NOT DELETE */
                }}
                name="Email-CK-EDITOR"
                id="Email-CK-EDITOR"
                readOnly={isCKEDITORLoaded ? ckEditorReadOnly : false}
              />
              {/* <CKEditor
                config={config}
                content={inputMessage}
                events={{
                  change: onEditorChange,
                  blur: onEditorChange,
                }}
                name="main-ckEditor"
                id="main-ckEditor"
                readOnly={true}
              /> */}
            </Grid>
          </Grid>
          <div className="action-bar">
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={8} xs={6}>
                <div className="attach-btn" id="actionBarAttachement">
                  <FileUploadComponent
                    files={selectedFile}
                    isMultipleFileAllowed={true}
                    setFiles={setselectedFile}
                    fileRef={hiddenFileInput}
                  />
                  {selectedFile.map((item, index) => {
                    return (
                      <span className="file-detail" key={`template-attachment-${index}`}>
                        {item.FileName}{" "}
                        <button onClick={() => handleRemoveFile(item.id)}>
                          <CloseIcon />
                        </button>
                      </span>
                    );
                  })}
                </div>
              </Grid>
        
              <Grid item md={4} xs={6}>
                {!replyInProgress && (
                  <Button
                    autoFocus
                    className="discard-btn"
                    color="primary"
                    onClick={handleDisCard}
                    disabled={replyInProgress}
                    id="actionBarDisCard"
                  >
                    Discard
                  </Button>
                )}
                {viewtype == 0 && (
                  <Button
                    className="send-btn"
                    color="primary"
                    onClick={sendClicked}
                    disabled={sendBtnDisabled}
                    id="actionBarSendBtn"
                  >
                    {replyInProgress ? <Spinner /> : "Send"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <CreateTicketDialog
        isCreateDialogOpen={isCreateTicketDialogOpen}
        setCreateDialog={setIsCreateTicketDialogOpen}
        subSource={ticketData.TicketSource}
        LeadID={ticketData.LeadID}
        Body={ticketData.Body}
        Attachments={ticketData.MailAttachments}
        From={ticketData.From}
        To={ticketData.ToReceipent}
        CC={ticketData.CCEmail}
        RefObjectId={oldTicketDetails.RefObjectId}
        TicketDetailsID={oldTicketDetails.TicketDetailsID}
        AccessTypeVal={AccessTypeVal}
        OpenedThroughSource="Email"
      />

      <ConfirmationDialog
        isConfirmDialogOpen={showConfirmationBox}
        setConfirmDialogOpen={setShowConfirmationBox}
        updateFn={handleReplySend}
      />

      {isCommunicationDialogOpen ? (
        <CommunicationDialogForTriggers
          isDialogOpen={isCommunicationDialogOpen}
          setDialogOpen={setIsCommunicationDialogOpen}
          triggerInput={triggerInput}
          setInputMessage={setInputMessage}
          oldTicketDetails={oldTicketDetails}
          canResponse={canResponse}
          fetchEmailBody={fetchEmailBody}
          valuesInParent={values}
          setValuesInParent={setValues}
          setCanResponse={setCanResponse}
          isTemplateLoaded={isTemplateLoaded}
          setIsTemplateLoaded={setIsTemplateLoaded}
          replyAppendMsg={replyAppendMsg}
          previousValues = {copiedTriggerInputs}
          setTypeInParent = { setTypeInParent}
          typeInParent = { typeInParent }
        />
      ) : (
        ""
      )}
    </>
  );
}
