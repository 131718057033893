import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  MenuItem,
  Button,
  Select,
  FormControl,
  TextField,
  searchParams,
  handleChange,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import { GET, POST } from "../../../services/apiServices";
import {
  GetAllUserForInsurerSummary,
  CreateUpdateInsurerUserDetails,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import Spinner from "../../../shared/components/Spinner";
import { TrendingUpOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function CreateUserManagement({
  editDetails,
  clearEdit,
  userDetails,
  action,
  getUserList,
}) {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    password: "",
    emailId: "",
    logId: "",
    showPassword: false,
    isAutoAssign: false,
    agent: 0,
  });
  const [touched, setTouched] = useState({
    name: false,
    password: false,
    emailId: false,
    logId: false,
    showPassword: false,
    isAutoAssign: false,
    // agent: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (editDetails) {
      setValues({
        name: editDetails.Name,
        password: editDetails.Password,
        emailId: editDetails.EmailID,
        logId: editDetails.LoginID,
        showPassword: false,
        isAutoAssign: editDetails.AutoAssign,
        agent: 0,
      });
      setTouched({
        name: true,
        password: true,
        emailId: true,
        logId: true,
        showPassword: true,
        isAutoAssign: true,
      });
    } else {
      setValues({
        name: "",
        password: "",
        emailId: "",
        logId: "",
        showPassword: false,
        isAutoAssign: false,
        agent: 0,
      });
      setTouched({
        name: false,
        password: false,
        emailId: false,
        logId: false,
        showPassword: false,
        isAutoAssign: false,
      });
    }
  }, [editDetails, action]);

  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist();
      if (event.target.name == "isAutoAssign") {
        setValues((values) => ({
          ...values,
          [event.target.name]: Boolean(event.target.value),
        }));
      } else {
        setValues((values) => ({
          ...values,
          [event.target.name]: event.target.value,
        }));
      }
      setTouched((touched) => ({
        ...touched,
        [event.target.name]: true,
      }));
    }
  };

  const hasError = (field) => {
    let error = "";
    if (!values[field]) {
      error = "required";
    }
    else{
      switch(field){
        case "emailId": error = !values[field].toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? "invalid format" : "";
          break;
          case "name": error = !values[field].toLowerCase().match(/^[a-z0-9 ]+$/i) ? "invalid" : "";
          break;
          case "logId": error = !values[field].toLowerCase().match(/^[a-z0-9]+$/i) ? "invalid" : "";
          break;
          default: break;
      }
    }
    // if(field == "emailId"){
    //   if(String(values[field])
    //   .toLowerCase()
    //   .match(
    //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //   )){
    //     error = "invalid format"
    //   }
    // }
    return error;
  };

  const validate = () => {
    if (
      hasError("name") ||
      hasError("password") ||
      hasError("emailId") ||
      hasError("logId")
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    setTouched({
      name: true,
      password: true,
      emailId: true,
      logId: true,
      showPassword: true,
      isAutoAssign: true,
    });
    if (validate()) {
      setIsLoading(TrendingUpOutlined);
      const payload = {
        userID: editDetails?.UserID || 0,
        emailID: values.emailId,
        isActive: editDetails?.IsActive || true,
        loginID: values.logId,
        name: values.name,
        password: values.password,
        autoAssign: values.isAutoAssign,
      };
      POST(CreateUpdateInsurerUserDetails, payload)
        .then((res) => {
          if (res.ErrorCode == 0 && res.Data) {
            enqueueSnackbar(
              action == 0
                ? "User created"
                : action == 1
                ? "User updated"
                : "Invalid action",
              {
                variant: "success",
                onClick: () => {
                  closeSnackbar();
                },
              }
            );
            getUserList();
            clearEdit();
          } else {
            enqueueSnackbar(res.Message || "Some error occured", {
              variant: "error",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
    }
  };

  return (
    <div className="create-user-box">
      <ul>
        <li>
          <FormControl className="form-control">
            <TextField
              id="outlined-full-width"
              label="Name"
              placeholder="Name"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="name"
              onChange={handleChange}
              value={values?.name}
              error={hasError("name") && touched["name"]}
              helperText={
                hasError("name") && touched["name"] ? hasError("name") : null
              }
            />
          </FormControl>
        </li>
        <li>
          <FormControl className="form-control">
            <TextField
              id="outlined-full-width"
              label="Email Id"
              placeholder="Email Id"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="emailId"
              onChange={handleChange}
              value={values?.emailId}
              error={hasError("emailId") && touched["emailId"]}
              helperText={
                hasError("emailId") && touched["emailId"]
                  ? hasError("emailId")
                  : null
              }
            />
          </FormControl>
        </li>
        <li>
          <FormControl className="form-control">
            <TextField
              id="outlined-full-width"
              label="Login Id"
              placeholder="Login Id"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="logId"
              onChange={handleChange}
              value={values?.logId}
              error={hasError("logId") && touched["logId"]}
              helperText={
                hasError("logId") && touched["logId"] ? hasError("logId") : null
              }
            />
          </FormControl>
        </li>
        <li>
          <FormControl className="form-control">
            <TextField
              id="outlined-full-width"
              label="Password"
              placeholder="Password"
              fullWidth
              type={values.showPassword ? "text" : "password"}
              margin="normal"
              onChange={handleChange("password")}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="password"
              onChange={handleChange}
              value={values.password}
              error={hasError("password") && touched["password"]}
              helperText={
                hasError("password") && touched["password"]
                  ? hasError("password")
                  : null
              }
            />
          </FormControl>
        </li>
        <li>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Is Auto Assign"
            labelPlacement="end"
            className="check-box"
            name="isAutoAssign"
            onChange={handleChange}
            value={values?.isAutoAssign}
            // error={hasError("isAutoAssign") && touched["isAutoAssign"]}
            //   helperText={
            //     hasError("isAutoAssign") && touched["isAutoAssign"]
            //       ? hasError("isAutoAssign")
            //       : null
            //   }
          />
        </li>
        {/* <li>
          <FormControl className="form-control select-agent">
            <InputLabel htmlFor="demo-customized-select-native">
              Agent
            </InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              name="InsurerStatusID"
              fullWidth
              disabled
            >
              <MenuItem value={0}>Agent</MenuItem>
            </Select>
          </FormControl>
        </li> */}
        {isLoading ? (
          <div className="spinner-loader">
            <Spinner />
          </div>
        ) : (
          <li>
            <Button className="save-btn" onClick={handleSubmit}>
              Save
            </Button>
            <Button className="cancel-btn" onClick={clearEdit}>
              Cancel
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
}
