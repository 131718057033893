import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { List, TextareaAutosize } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { acronym } from "../../Config";
import { convertToIsoFormate, returnChatDate } from '../../shared/methods/methods';
import { GET } from "../../services/apiServices";
import { GetChatDetails,GetChatDetailsV2 } from "../../constants/apiUrls";
import { SampleData } from './ChatSampleData'


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        background: "#fff",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ChatHistoryView = ({ oldTicketDetails, setOpenChatWindow, openChatWindow }) => {
    const [open, setOpen] = React.useState(false);
    const [customerID, setCustomerID] = useState()
    const [ticketID, setTicketID] = useState()
    const [chatHistoryData, setChatHistoryData] = useState(null)

    const handleClickOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setTicketID(oldTicketDetails.TicketDetailsID)
        setCustomerID(oldTicketDetails.CustomerId)
        // setChatHistoryData(SampleData[0].Data)

    }, [oldTicketDetails])

    // useEffect(() => {
    //     if (customerID && ticketID && openChatWindow) {
    //         GET(`${GetChatDetails}/${customerID}/${ticketID}`).then(res => {
    //             setChatHistoryData(res.Data)
    //         }).catch((err)=>{
    //             console.log(err)
    //         })
    //     }
    // }, [openChatWindow])
    
    useEffect(() => {
        if (ticketID && openChatWindow) {
            GET(`${GetChatDetailsV2}/${ticketID}`).then(res => {
                setChatHistoryData(res.Data)
            }).catch((err)=>{
                console.log(err)
            })
        }
    }, [openChatWindow])


    return (
        <>
            <Dialog fullScreen open={openChatWindow} onClose={()=>setOpenChatWindow(false)} className="dialog-box-assign chat-history" TransitionComponent={Transition} >
                <div className="chat-view">
                    <h5 className="text-center">
                        Read Conversation <Button autoFocus color="inherit" className="close-btn" onClick={()=>setOpenChatWindow(false)}>
                            <CloseIcon />
                        </Button>
                    </h5>
                    <div className="chating-list">
                        

                            {/* {chatHistoryData && chatHistoryData.data ? chatHistoryData.data.map((chatItem, index) => {

                                return (<div>
                                     <div className="chat-timing"><span className="date-time">{returnChatDate(chatItem.ts)} </span></div>
                                    {chatItem && chatItem.chat && chatItem.chat.length > 0 &&
                                        chatItem.chat.map((item, i) => {
                                            if (!item.inbound) {
                                                return (
                                                    <li className="left-chat">
                                                        <div className="chat">
                                                            <span className="user-name">{acronym(item.username)}</span>
                                                            <div className="chat-txt">
                                                                <h2>{item.username}</h2>
                                                                <p>
                                                                    {item.msg}
                                                                </p>
                                                                <span className="msg-time">{convertToIsoFormate(item.ts)}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            else {
                                                return (
                                                    <li className="right-chat">
                                                        <div className="chat">
                                                            <span className="user-name">{acronym(item.username)}</span>
                                                            <div className="chat-txt">
                                                                <h2>{item.username}</h2>
                                                                <p>
                                                                    {item.msg}
                                                                </p>
                                                                <span className="msg-time">{convertToIsoFormate(item.ts)}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                      <div className="clr"></div>
                                </div>
                                )
                            }) : null} */}
                            {chatHistoryData ?
                                <div className="endorsement-dialog-popup">
                                    <iframe
                                        style={{ width: "100%", height: "100vh" }}
                                        
                                        src={chatHistoryData}
                                    ></iframe>
                                    <div className="clr"></div>
                                </div>
                                : null
                            }
                        

                        
                    </div>
                    <div className="clr"></div>
                    {/* <div className="txtarea-box">
                <TextareaAutosize placeholder="text here..."></TextareaAutosize>
            </div> */}
                </div>
            </Dialog>
        </>
    );
}

export default ChatHistoryView
