import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function WecareSidebar({ AccessType }) {
  const history = useHistory();

  // const [AccessType, setAccessType] = useState(
  //   JSON.parse(localStorage.getItem("UserDetails"))?.UserType
  // );
  function openpage(val) {
    // setSelectedPage(val);
    switch (val) {
      case 0:
        history.push("/InsurerDashboard");
        break;
      // case 1: setOpen(true);
      // break;
      case 2:
        history.push("/PolicyLink");
        break;
      case 3:
        history.push("/TicketSummary");
        break;
      case 4:
          history.push("/UserManagement")
      default:
        break;
    }
  }

  // const handleClose = () => {

  // }

  return (
    <div className="sidebar">
        <ul>
        <li>
            <Link title="Home">
            <i className="fa fa-home" onClick={() => openpage(0)}></i>
            </Link>
        </li>
        <li>
            <Link title="Policy link">
            <i className="fa  fa-link" onClick={() => openpage(2)}></i>
            </Link>
        </li>
        <li>
            <Link title="Summary">
            <i className="fa fa-list-alt" onClick={() => openpage(3)}></i>
            </Link>
        </li>
        {
            AccessType == 6 ?
            <li>
            <Link title="User Management">
                <i className="fa fa-user-o" onClick={() => openpage(4)}></i>
            </Link>
            </li>
            : null
        }
        </ul>
    </div>
  );
}

const mapStateToProps = (state)=>{
  return{
    AccessType: state && state.auth && state.auth.userInfo.AccessType || 0
  }
}
export default connect(mapStateToProps, null)(WecareSidebar);

