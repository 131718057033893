import { useEffect, useState, React, useReducer } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { fetchDashboardData_method } from "../../../../shared/methods/apiMethods";
import CustomBackdrop from '../../../Components/CustomBackdrop';
import { HashLoader } from 'react-spinners';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { fetchMasterList } from '../../../../redux/actions/common_action';
import { CheckIfEndPointIsDisabled, getSubStatusList, getUniqueSetBasedOnKey } from '../../../../shared/methods/methods';
import AgentDashboardData from './AgentDashboardData';
import { AGENT_ADVANCE_SEARCH_TYPE } from '../../../../Config';
import { GetDisabledEndPoints } from '../../../../shared/methods/GlobalSelector';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
  },
}))

const AgentAdvanceSearch = ({showDataToRender, setShowDataToRender}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const userID_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.UserID);
  const userType_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.UserType);
  const agentProduct_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.ProductID)
  const productSupplierMaster = useSelector(state => state && state.common && state.common.productSupplierMaster);
  const sourceMaster_store = useSelector(state => state && state.common && state.common.sourceMaster);
  const statusSubStatusMaster_store = useSelector(state => state && state.common && state.common.statusSubStatusMaster);
  const [productList, setProductList] = useState();
  const [insurerList, setInsurerList] = useState();
  const [sourceList, setSourceList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [insurerStatusList, setInsurerStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [subSourceList, setSubSourceList] = useState([]);
  const [dataToRender, setDataToRender] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const disabledEndPoints_store = GetDisabledEndPoints();
  const [advanceSearchValues, setAdvanceSearchValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      FromDate: new Date(),
      ToDate: new Date(),
      Source: "",
      ProductID: agentProduct_store,
      SupplierID: 0,
      CustomerName:'',
      TicketNotReply: 0,
      StatusID: 0,
      SubStatusID:0,
      TATFilter: 0,
      IsEsc: 0,   
    }
  );

  const [advanceSearchValuesTouched, setAdvanceSearchValuesTouched] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {

      FromDate: true,
      ToDate: true,
      Source: false,
      ProductID : true,
      SupplierID: false,
      CustomerName:false,
      TicketNotReply: false,
      StatusID: false,
      SubStatusID:false,
      TATFilter: false,
      IsEsc: false,  
    }
  );

  const showMessage = (message, varient) => {
    return enqueueSnackbar(message, {
      variant: varient, onClick: () => {
        closeSnackbar();
      },
    });
  }

  const TatFilterArray = [
    { DisplayName: "TAT Busted", value: 1 },
    { DisplayName: "Busting Today", value: 2 },
    { DisplayName: "Busting in 2 days", value: 3 },
    { DisplayName: "Busting in 3 days", value: 4 },
    { DisplayName: "Busting above 3 days", value: 5 },
  ]

  const EscalationArray = [
    { DisplayName: "Yes", value: -1 },
    { DisplayName: "No", value: 1 },
  ]

  useEffect(() => {
    if (productSupplierMaster && productSupplierMaster.length === 0) {
      dispatch(fetchMasterList())
    }
  }, [productSupplierMaster]);

  // For Filtering ProductList
  useEffect(() => {
    if (productSupplierMaster) {
      getProductSet();
    }
    if (sourceMaster_store) {
      getSourceSet();
    }
    if (statusSubStatusMaster_store) {
      getTicketStatusSet();
      getInsurerStatusSet();
    }
  }, [productSupplierMaster, sourceMaster_store, statusSubStatusMaster_store]);

  const getProductSet = () => {
    let arr = getUniqueSetBasedOnKey(productSupplierMaster, 'ProductID', ['ProductID', 'ProductName']);
    setProductList(arr);
  }

  //For Filtering Source List
  const getSourceSet = () => {
    let tempSources = getUniqueSetBasedOnKey(sourceMaster_store, 'SourceID', ['SourceID', 'SourceName']);
    setSourceList(tempSources);
  }

  //for filtering status 
  const getTicketStatusSet = () => {
    let tempTicketStatus = [];
    let obj;
    statusSubStatusMaster_store.length > 0 &&
      statusSubStatusMaster_store.map((item) => {
        let flag = 0;
        if (tempTicketStatus && tempTicketStatus.length > 0) {
          tempTicketStatus.forEach((singleItem) => {
            if (singleItem.StatusID == item.StatusID) {
              return (flag = 1);
            }
          });
        }
        if (flag == 0 && item.StatusType === 1) {
          obj = {
            StatusID: item.StatusID,
            StatusName: item.StatusName,
          };
          tempTicketStatus.push(obj);
        }
      });
    setStatusList(tempTicketStatus);
    
  }

  //for filtering Insurer Status from StatusSubStatus Master
  const getInsurerStatusSet = () => {
    let tempTicketStatus = [];
    let obj;
    statusSubStatusMaster_store.length > 0 &&
      statusSubStatusMaster_store.map((item) => {
        let flag = 0;
        if (tempTicketStatus && tempTicketStatus.length > 0) {
          tempTicketStatus.forEach((singleItem) => {
            if (singleItem.StatusID == item.StatusID) {
              return (flag = 1);
            }
          });
        }
        if (flag === 0 && item.StatusType === 2) {
          obj = {
            StatusID: item.StatusID,
            StatusName: item.StatusName,
          };
          tempTicketStatus.push(obj);
        }
      });
    setInsurerStatusList(tempTicketStatus);
  }

  //for filtering status 
  const getTicketSubStatusSet = () => {
    let tempSubStatus =getSubStatusList(statusSubStatusMaster_store, 'SubStatusID', ['SubStatusID', 'SubStatusName'], advanceSearchValues.StatusID);
    setSubStatusList(tempSubStatus);
  }

  useEffect(()=>{
    if(advanceSearchValues.StatusID){
      getTicketSubStatusSet();
    }
  },[advanceSearchValues.StatusID])


  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist()
    }
    const { name, value } = event.target;
    if(name === 'StatusID'){
      setAdvanceSearchValues({
        [name]: value,
        SubStatusID : 0,
      })
    }
    else{
      setAdvanceSearchValues({
        [name]: value
      })
    }
    setAdvanceSearchValuesTouched({
      [name]: true
    })
  }

  useEffect(()=>{
    if(agentProduct_store && productSupplierMaster && productSupplierMaster.length>0){
        handleSupplierProductChange(agentProduct_store);
    }
  },[agentProduct_store, productSupplierMaster])

  const handleSupplierProductChange = (product) => {
    let insurerList = [];
    let obj;
    if (
      productSupplierMaster &&
      Array.isArray(productSupplierMaster) &&
      productSupplierMaster.length > 0
    ) {
      productSupplierMaster.map((item) => {
        let flag = 0;
        if (item.ProductID == product) {
          if (insurerList && insurerList.length > 0) {
            insurerList.forEach((singleItem) => {
              if (singleItem.SupplierID == item.SupplierID) {
                return (flag = 1);
              }
            });
          }
          if (flag == 0) {
            obj = {
              SupplierID: item.SupplierID,
              SupplierName: item.SupplierName,
            };
            insurerList.push(obj);
          }
        }
      });
      setInsurerList(insurerList);
    }
  };

  const handleTouched = () => {
    setAdvanceSearchValuesTouched({
      FromDate: true,
      ToDate: true,
      Source: false,
      CustomerName:false,
      TicketNotReply: false,
      StatusID: false,
      SubStatusID: false,
      TATFilter: false,
      IsEsc: false, 
    })
  }

  const generatePayload = () => {
    return {
      "IsUpdatedOn": advanceSearchValues.IsUpdatedOn,
      "FilterString": "",
      "FromDate": advanceSearchValues.FromDate.getTime(),
      "ToDate": advanceSearchValues.ToDate.getTime(),
      "Source": advanceSearchValues.Source,
      "ProductID": advanceSearchValues.ProductID,
      "SupplierID": advanceSearchValues.SupplierID,
      "StatusID": advanceSearchValues.StatusID,
      "SubStatusID": advanceSearchValues.SubStatusID,
      "TATFilter": advanceSearchValues.TATFilter,
      "IsEsc": advanceSearchValues.IsEsc,
      "Type": AGENT_ADVANCE_SEARCH_TYPE,
      CustomerName:advanceSearchValues.CustomerName,
    }
  }

  const validate = () => {
    handleTouched();
    if ((advanceSearchValues.ToDate < advanceSearchValues.FromDate) || advanceSearchValues.FromDate > new Date() || advanceSearchValues.ToDate > new Date()
     || advanceSearchValues.FromDate < new Date().setFullYear(new Date().getFullYear() - 2)
     || new Date(advanceSearchValues.ToDate) > new Date(advanceSearchValues.FromDate).setDate(new Date(advanceSearchValues.FromDate).getDate()+30)) {
      return false
    }
    else return true
  }


  const handleSearch = () => {
    setShowDataToRender(false);
    if (!validate()) { return }
    let url = '/GetAgentTicketsList';
    let payload = generatePayload();
    setDataLoading(true);
    let callback = (value) => {
      setDataToRender(value);
      setDataLoading(false);
    }
    fetchDashboardData_method(url, payload, callback, showMessage);
  }

  const clearFields = function(){
    let tempObj = {
      FromDate: new Date(),
      ToDate: new Date(),
      Source: "",
      ProductID: agentProduct_store,
      SupplierID: 0,
      CustomerName:'',
      TicketNotReply: 0,
      StatusID: 0,
      SubStatusID:0,
      TATFilter: 0,
      IsEsc: 0,   
    }

    setAdvanceSearchValues(tempObj);
    setDataToRender(null);

  }

  const getMinDateForToDate =() =>{
    //if selected From Date +30 is more than current date then by default the max date is current date
   return new Date(advanceSearchValues.FromDate).setDate(new Date(advanceSearchValues.FromDate).getDate()+30) > new Date() ? new Date() :
   new Date(advanceSearchValues.FromDate).setDate(new Date(advanceSearchValues.FromDate).getDate()+30)
  }

  const checkIfSearchDisabled = CheckIfEndPointIsDisabled(disabledEndPoints_store, 'GetAgentTicketsList')

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white' /></CustomBackdrop> : null}
        <div className="pbdashboard-simple-seach advance-seach">
          <ul>
          {checkIfSearchDisabled && <div className="access-info">You do not have Search Access</div>}
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <KeyboardDatePicker
                  disableToolbar
                  disabled={checkIfSearchDisabled}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline agentStartDate"
                  label="Start Date"
                  maxDate={new Date()}
                  minDate={new Date().setFullYear(new Date().getFullYear()-2)} //only max 2 years is allowed
                  value={advanceSearchValues.FromDate}
                  onChange={(date) => setAdvanceSearchValues({ FromDate: date, ToDate: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <KeyboardDatePicker
                  disableToolbar
                  disabled={checkIfSearchDisabled}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline agentEndDate"
                  label="End Date"
                  minDate={advanceSearchValues.FromDate}
                  maxDate={getMinDateForToDate()}
                  value={advanceSearchValues.ToDate}
                  onChange={(date) => setAdvanceSearchValues({ ToDate: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Select Source
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select agentSource"
                  value={advanceSearchValues.Source}
                  onChange={handleChange}
                  name="Source"
                  disabled={checkIfSearchDisabled}
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {sourceList.map(item => {
                    return <MenuItem value={item.SourceName}>{item.SourceName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
           
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Ticket Not Reply
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select agentTicketNotReply"
                  value={advanceSearchValues.TicketNotReply}
                  onChange={handleChange}
                  name="TicketNotReply"
                  disabled={checkIfSearchDisabled}
                >
                  <MenuItem value={0}>Select</MenuItem>
                  <MenuItem value={1}>1 Day(s)</MenuItem>
                  <MenuItem value={2}>2 Day(s)</MenuItem>
                  <MenuItem value={3}>3 Day(s)</MenuItem>
                  <MenuItem value={4}>3+ Day(s)</MenuItem>
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control"
                // error={!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID}
                >
                <InputLabel id="demo-customized-select-label">
                  Select Status
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select agentStatusID"
                  value={advanceSearchValues.StatusID}
                  onChange={handleChange}
                  name="StatusID"
                  disabled={checkIfSearchDisabled}
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {statusList && statusList.length > 0 && statusList.map(item => {
                    return <MenuItem value={item.StatusID}>{item.StatusName}</MenuItem>
                  })}
                </Select>
                {/* {!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID && <FormHelperText className='error-msg'>Please select Status</FormHelperText>} */}
              </FormControl>
            </li>
              <li className="calander-list">
                  <FormControl className="form-control calander-control"
                    // error={!advanceSearchValues.SubStatusID && advanceSearchValuesTouched.SubStatusID}
                    >
                    <InputLabel id="demo-customized-select-label">
                      Select Sub Status
                    </InputLabel>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select agentSubStatusID"
                      value={advanceSearchValues.SubStatusID}
                      onChange={handleChange}
                      name="SubStatusID"
                      disabled={checkIfSearchDisabled}
                    >
                      <MenuItem value={0}>Select</MenuItem>
                      {subStatusList && subStatusList.length > 0 && subStatusList.map(item => {
                        return item.SubStatusName && <MenuItem value={item.SubStatusID}>{item.SubStatusName}</MenuItem>
                      })}
                    </Select>
                    {/* {!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID && <FormHelperText className='error-msg'>Please select Status</FormHelperText>} */}
                  </FormControl>
              </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  TAT Filter
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select agentTATFilter"
                  value={advanceSearchValues.TATFilter}
                  onChange={handleChange}
                  name="TATFilter"
                  disabled={checkIfSearchDisabled}
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {TatFilterArray && TatFilterArray.map(item => {
                    return <MenuItem value={item.value}>{item.DisplayName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Escalation
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select agentIsEsc"
                  value={advanceSearchValues.IsEsc}
                  onChange={handleChange}
                  name="IsEsc"
                  disabled={checkIfSearchDisabled}
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {EscalationArray.map(item => {
                    return <MenuItem value={item.value}>{item.DisplayName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li>
                <Button id="agentSearchBtn" className="export-btn" onClick={handleSearch} disabled={CheckIfEndPointIsDisabled(disabledEndPoints_store, 'GetAgentTicketsList')}>Search</Button>
                <Button id="agentClearBtn" className="clear-btn" onClick={clearFields} disabled={checkIfSearchDisabled}>Clear All</Button>
            </li>
          </ul>
          {!showDataToRender && dataToRender && Array.isArray(dataToRender) && <AgentDashboardData dataToRender={dataToRender} fileNameForExport="AdvanceSearch_TicketList"/>}
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default AgentAdvanceSearch
