import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import {Grid,Button,FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { GET, POST } from "../../services/apiServices";
import {
    GetTicketCount,
    GetTicketData,
    GetTicketDataExport
  } from "../../constants/apiUrls";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "0 8px",
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const {getDashboardData, isActionsAllowed} = props;
  const classes = useStyles();
  const [insurer, setInsurer] = useState('');

  const HandleInsurer = (event) => {
    setInsurer(event.target.value);
  };

  return (
    <div>
        <Grid container spacing={2}>
          {/* <Grid item md={2} xs={6}>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Select</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={insurer}
                onChange={HandleInsurer}
                label="Age"
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Cancer Insurance</MenuItem>
                    <MenuItem value={2}>Car Insurance</MenuItem>
                    <MenuItem value={3}>Commercial Insurance</MenuItem>
                    <MenuItem value={3}>Critical Illness Insurance</MenuItem>
                    <MenuItem value={3}>Cyber Safe</MenuItem>
                </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item md={2} xs={6}>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Select</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={insurer}
                onChange={HandleInsurer}
                label="Age"
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Cancer Insurance</MenuItem>
                    <MenuItem value={2}>Car Insurance</MenuItem>
                    <MenuItem value={3}>Commercial Insurance</MenuItem>
                    <MenuItem value={3}>Critical Illness Insurance</MenuItem>
                    <MenuItem value={3}>Cyber Safe</MenuItem>
                </Select>
            </FormControl>
          </Grid> */}
          {/* {
          isActionsAllowed
          &&
          <Grid item md={3} xs={12}>
            <FormControl variant="outlined"><Button className="load-data-btn" onClick={() => {}}>Export Data</Button></FormControl>
          </Grid>
          } */}
        </Grid>
  </div>
  );
}
