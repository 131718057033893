import React, {
    useState,
    useEffect,
    useRef,
    useReducer
  } from "react";
  import { makeStyles } from "@material-ui/core/styles";
  import { useSnackbar } from "notistack";
  import {
    CreateTicketForCare,
  } from "../../../constants/apiUrls";
  import {
    InputLabel,
    Select,
    Grid,
    FormControl,
    Button,
    TextareaAutosize,
    FormHelperText,
    TextField,
    MenuItem,
    Slide,
    Typography,
    AppBar,
    Toolbar,
  } from "@material-ui/core";
  import {
    validateLeadId,
    handleDrag,
    handleDragOut,
    handleDragIn,
    handleDrop,
    checkProductIssueSubIssue,
    checkIssueSubIssueForDispatchRequest,
  } from "../../../shared/methods/methods";
  import Spinner from "../../../shared/components/Spinner";
  import CloseIcon from "@material-ui/icons/Close";
  import CopyTicketIdDialog from "../../../shared/components/CopyTicketIdDialog";
  import FileUploadComponent from "../../../shared/components/FileUploadComponent";
  // import { useParams } from "react-router-dom";
  import CircularProgress from "@material-ui/core/CircularProgress";
  import { AllowedToRaiseEndorsementAtLogin, UsersAllowedForTicketCreation } from "../../../Config";
  import * as actions from "../../../redux/actions/auth";
  import { connect } from "react-redux";
import { GET, POST } from "../../../services/apiServices";
import { DialogFromRight, Toast } from "../../../shared/components";
import { fetchDispatcherURL, fetchEndosementURL, handleCatch } from "../../../shared/methods/apiMethods";
import CustomRaiseRequestModal from "../../../Components/Dialog/CustomRaiseRequestModal";
import RaiseNewEndorsement from "../../../Components/TicketDetail/Components/RaiseNewEndorsement";
import CustomBackdrop from "../../Components/CustomBackdrop";
  
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
    },
    selectEmpty: {},
    appBar: {
      position: "relative",
      backgroundColor: "#fff !important",
      color: "#414141",
      
    },
    title: {
      flex: 1,
      fontSize: "16px",
    },
    formControl: {
      minWidth: "100%",
    },
    h6: {
      color: "#414141",
      fontSize: "14px",
    },
    CircularProgressBar: {
      position: "absolute",
      margin: "auto",
      top: "50%",
      left: "50%",
    },
    source:{
      fontSize: '7px',
      color: '#b99494,'
    }
  }));
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });
  
  const DashboardPB_CreateTicket = ({
    TicketDetailsID,
    RefObjectId,
    auth,
    authorized,
    closeParentDialog,
  }) => {
    const classes = useStyles();
  
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isShowLess, setIsShowLess] = useState(true);
    const fileRef = useRef("");
    const [fetchDetailFormValues, setfetchDetailFormValues] = useState({
      Source: "PB Manual",
      leadId: "",
    });
    // const [incomingLeadId, setIncomingLeadId] = useState();
    const [createTicketFormValues, setCreateTicketFormValues] = useState({
      issue: "",
      issueId: "",
      subIssueId: "",
      subIssue: "",
      initiatedby: "",
      comment: "",
    });
  
    const [isCreateTicketInProgress, setIsCreateTicketInProgress] =
      useState(false);
  
    const [fetchDetailFormErrors, setfetchDetailFormErrors] = useState({
      Source: false,
      leadId: false,
    });
  
    const [createTicketFormErrors, setCreateTicketFormErrors] = useState({
      issue: false,
      subIssue: false,
      initiatedby: false,
    });
  
    const [errMessages, setErrMessages] = useState({ leadId: "" });
    const [isFetchDetail, setIsFetchDetail] = useState({});
    const [fetchingDetail, setFetchingDetail] = useState(false);
    const [issueOptions, setIssueOptions] = useState([]);
    const [subIssueOptions, setSubIssueOptions] = useState([]);
    const [fileUpload, setFileUpload] = useState([]);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [TicketCreatedMsg, setTicketCreatedMsg] = useState("");
    const [productSupplierMasterList, setProductSupplierMasterList] = useState(
      []
    );
    const [productList, setProductList] = useState();
    const [insurerList, setInsurerList] = useState();
    const initialValuesForZeroLead = {
      customerName: "",
      customerEmail: "",
      selectedInsurer: {},
      selectedProduct: {},
    };
    const [formValues, setFormValues] = useState(initialValuesForZeroLead);
    const [formErrors, setFormErrors] = useState();
    const [isSubmit, setIsSubmit] = useState(false);

    const customDialogInitialState = {
      title: false,
      actionButtons: [],
      open: false,
      handleClose: null,
    };
    const [customDialogControl, setCustomDialogControl] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      customDialogInitialState
    );
    const [endorsementURL, setEndorsementURL] = useState("");
    const [showLoaderWithText, setShowLoaderWithText] = useState("")
    const [dialogWithControls, setDialogWithControls] = useState();
  
    const MasterDataFromAPI = () => {
      if (productSupplierMasterList.length == 0) {
        GET("GetProductSupplierMasterData")
          .then((res) => {
            if (
              res &&
              res.ErrorCode === 0 &&
              Array.isArray(res.Data) &&
              productSupplierMasterList.length == 0
            ) {
              setProductSupplierMasterList(res.Data);
            }
          })
          .catch((err) => {
            console.log("error", err);
          });
      }
    };
  
    const InitiatedByList = ["Customer", "Insurer"];
  
    useEffect(() => {
      MasterDataFromAPI();
      console.log("auth api called on render");
    }, []);
  
    const validateForm = (values, setErr) => {
      let errObj = {};
      Object.keys(values).forEach((field) => {
        !values[field] && (errObj[field] = true);
        if (!fetchDetailFormValues.leadId) {
          setErrMessages({
            leadId: validateLeadId(fetchDetailFormValues.leadId),
          });
        }
      });
  
      setErr(errObj);
    };
  
    const handleIssueSubIssue = (id, val, name = "subIssue") => {
      if (name === "issue") {
        setCreateTicketFormValues((prev) => ({
          ...prev,
          issueId: id,
          issue: val,
          subIssueId: null,
          subIssue: null,
        }));
      } else if (name === "initiatedby") {
        setCreateTicketFormValues((prev) => ({
          ...prev,
          initiatedby: val,
        }));
      } else {
        setCreateTicketFormValues((prev) => ({
          ...prev,
          subIssueId: id,
          subIssue: val,
        }));
      }
    };
  
    const GetIssueSubIssueAndLeadDetailsapi = (leadId) => {
      setFetchingDetail(true);
      let url = `GetIssueSubIssueAndLeadDetails/${
        leadId
          ? leadId
          : fetchDetailFormValues && fetchDetailFormValues.leadId
          ? fetchDetailFormValues.leadId
          : 0
      }/1`;
      GET(url)
        .then((response) => {
          if (response.ErrorCode === 0) {
            setIsFetchDetail(response.Data);
            const issueList = response.Data.issueTypeMasters.filter(
              (item) => item.ParentID === 0
            );
            setIssueOptions(issueList);
          } else {
           Toast.error(response['Message'] || 'Failed to Load Issues')
          }
          setFetchingDetail(false);
        })
        .catch((err) => {
          setFetchingDetail(false);
          handleCatch(err)
          console.log("GetIssueSubIssue CTFM- catch");
        });
    };
  
    useEffect(() => {
      const subIssueOptionsList = [];
      if (createTicketFormValues.issue) {
        isFetchDetail.issueTypeMasters.map((item) => {
          if (item.ParentID === createTicketFormValues.issueId) {
            subIssueOptionsList.push(item);
          }
        });
        setSubIssueOptions(subIssueOptionsList);
      }
    }, [createTicketFormValues.issue]);
  
    useEffect(() => {
      if (productSupplierMasterList) {
        let productList = [];
        let obj;
        productSupplierMasterList.length > 0 &&
          productSupplierMasterList.map((item) => {
            let flag = 0;
            if (productList && productList.length > 0) {
              productList.forEach((singleItem) => {
                if (singleItem.ProductID == item.ProductID) {
                  return (flag = 1);
                }
              });
            }
            if (flag == 0) {
              obj = {
                ProductID: item.ProductID,
                ProductName: item.ProductName,
              };
              productList.push(obj);
            }
          });
        setProductList(productList);
      }
    }, [productSupplierMasterList]);
  
    // Handle change of Supplier on Product Change
    useEffect(
      () => {
        if (
          isFetchDetail &&
          isFetchDetail.leadDetailResForUI &&
          isFetchDetail.leadDetailResForUI.productID
        ) {
          handleSupplierProductChange(isFetchDetail.leadDetailResForUI.productID);
        }
      },
      { isFetchDetail }
    );
  
    const handleSupplierProductChange = (product) => {
      let insurerList = [];
      let obj;
      if (
        productSupplierMasterList &&
        Array.isArray(productSupplierMasterList) &&
        productSupplierMasterList.length > 0
      ) {
        productSupplierMasterList.map((item) => {
          let flag = 0;
          if (item.ProductID == product) {
            if (insurerList && insurerList.length > 0) {
              insurerList.forEach((singleItem) => {
                if (singleItem.SupplierID == item.SupplierID) {
                  return (flag = 1);
                }
              });
            }
            if (flag == 0) {
              obj = {
                SupplierID: item.SupplierID,
                SupplierName: item.SupplierName,
              };
              insurerList.push(obj);
            }
          }
        });
        setInsurerList(insurerList);
      }
    };
  
    const handleFetchDetail = (e, leadId = "") => {
      setCreateTicketFormValues({
        issue: "",
        issueId: "",
        subIssueId: "",
        subIssue: "",
        // comment: "",
      });
      setFormValues(initialValuesForZeroLead);
      setFormErrors({});
      setSubIssueOptions([]);
      if (!fetchDetailFormValues) {
        validateForm(fetchDetailFormValues, setfetchDetailFormErrors);
        if (
          !fetchDetailFormValues.Source ||
          !fetchDetailFormValues.leadId ||
          errMessages.leadId
        ) {
          return;
        }
      }
      GetIssueSubIssueAndLeadDetailsapi(leadId);
      setIsShowLess(false);
    };
  
    const showErrMsg = (msg) => {
      enqueueSnackbar(msg, {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    };
  
    const getSupplierName = () => {
      return "";
    };
  
    const handleCreateTicket = () => {
      setIsSubmit(true);
      setFormErrors(ValidateAllAdditionalFields(formValues));
      console.log("formErrors on submit button click", formErrors);
      if (
        fetchDetailFormValues &&
        fetchDetailFormValues.leadId == 0 &&
        Object.keys(formErrors).length != 0
      ) {
        console.log("inside object if errors and lead==0");
        return;
      }
  
      if (
        fetchDetailFormValues.Source === "PB Manual" &&
        isFetchDetail.leadDetailResForUI &&
        [115, 7].includes(isFetchDetail.leadDetailResForUI.ProductID) &&
        auth &&
        auth.Source &&
        auth.Source.toLowerCase() != "matrix" &&
        auth &&
        auth.UserID &&
        !UsersAllowedForTicketCreation(auth.UserID)
      ) {
        showErrMsg(
          "PB Manual ticket can't be created for Term & Investment Products"
        );
        return;
      }
      
      if (
        fetchDetailFormValues.Source === "PB Manual" &&
        isFetchDetail.leadDetailResForUI &&
        [131].includes(isFetchDetail.leadDetailResForUI.ProductID) &&
        isFetchDetail.leadDetailResForUI.InvestmentTypeID == 1 &&
        auth &&
        auth.Source &&
        auth.Source.toLowerCase() != "matrix" && createTicketFormValues && createTicketFormValues.issue && !['endorsement request', 'cd account issue'].includes(createTicketFormValues.issue.toLowerCase()) 
      ) {
        showErrMsg(
          "For GHI, manual ticket can only be created from Corporate Search panel."
        );
        return;
      }
  
      !createTicketFormValues.issue &&
        setCreateTicketFormErrors((prev) => ({ ...prev, issue: true }));
      !createTicketFormValues.subIssue &&
        setCreateTicketFormErrors((prev) => ({ ...prev, subIssue: true }));
      if (!createTicketFormValues.issue || !createTicketFormValues.subIssue) {
        return;
      }
      if (
        !createTicketFormValues.initiatedby &&
        [2].includes(isFetchDetail.leadDetailResForUI.ProductID)
      ) {
        setCreateTicketFormErrors((prev) => ({ ...prev, initiatedby: true }));
        return;
      }
      if (!createTicketFormValues.comment) {
        showErrMsg("Please enter the comment");
        return;
      }
  
      if (isCreateTicketInProgress) {
        return;
      }
  
      const {
        PolicyNo,
        ProductID,
        ProductName,
        SupplierID,
        SupplierName,
        EmailID,
        LeadID,
        RegistrationNo,
        ApplicationNo,
        CustomerName,
      } = isFetchDetail.leadDetailResForUI;
      
      const payload = {
        Name: CustomerName || formValues.customerName,
        PolicyNumber: PolicyNo,
        ClaimNumber: null,
        Source: "PB Manual",
        ProductID:
          ProductID ||
          (formValues && formValues.selectedProduct
            ? formValues.selectedProduct.ProductID
            : ""),
        ProductName:
          ProductName ||
          (formValues && formValues.selectedProduct
            ? formValues.selectedProduct.ProductName
            : ""),
        InsurerID:
          SupplierID ||
          (formValues && formValues.selectedInsurer
            ? formValues.selectedInsurer.SupplierID
            : ""),
        InsurerName:
          SupplierName ||
          getSupplierName() ||
          (formValues && formValues.selectedInsurer
            ? formValues.selectedInsurer.SupplierName
            : ""),
        EmailID: EmailID || formValues.customerEmail,
        Comments: createTicketFormValues.comment,
        DialerUniqueID: null,
        Subject: null,
        LeadID: LeadID,
        CustomerID: 0,
        Issue: createTicketFormValues.issueId,
        IsCallBackRequest: 0,
        AutoClosure: 0,
        Attachments:
          fileUpload.length > 0
            ? fileUpload.map((item) => ({
                FileName: item.FileName,
                AttachemntContent: item.AttachemntContent,
              }))
            : [],
        RefObjectId: null,
        FollowUpDate: null,
        AddressDetails: null,
        Pincode: 0,
        RegistrationNo: RegistrationNo,
        ApplicationNo: ApplicationNo,
        EndorsementID: null,
        IsAutoResolved: null,
        QueryTypeID: 0,
        IssueIn: 0,
        SubIssueIn: 0,
        SubIssueId: createTicketFormValues.subIssueId,
        SubSource: auth && auth.Source ? auth.Source : "BMS",
        IsAttachment: fileUpload.length > 0 ? true : false,
        PrevRefObjectId: null,
        SubIssueIDList: null,
        IsMultipleEndorsement: false,
        InitiatedBy: createTicketFormValues.initiatedby,
      };
      // ----------------------------------------------------------
      setIsCreateTicketInProgress(true);
      POST(CreateTicketForCare, payload)
        .then((response) => {
          if (response.ErrorCode === 0) {
            setTicketCreatedMsg(`Ticket ID: ${response.Data.TicketDetailsId}`);
            setDialogOpen(true);
            if (TicketDetailsID && RefObjectId) {
              // getAllTicketSummary(RefObjectId, TicketDetailsID);
              // ticketContext.GetTicketCommApi(RefObjectId, TicketDetailsID);
            }
            //   handleClose();
            //   ticketContext.GetTicketLogsApi();
            //   ticketContext.GetFilterCountApi();
          } else {
            if (response.Response) {
              showErrMsg(response.Response);
            } else {
              showErrMsg("Something went wrong!");
            }
            console.log("CTFM submit- else");
          }
          setIsCreateTicketInProgress(false);
        })
        .catch((err) => {
          console.log(err);
          setIsCreateTicketInProgress(false);
          showErrMsg("Something went wrong!");
          console.log("CTFM submit- catch");
        });
      // --------------------------------------------------------------
    };
  
    const handleChange = (event, setValue) => {
      const { name, value } = event.target;
      setValue((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
      if(name ==='leadId'){
        setIsShowLess(true); // to avoid any conflicts
      }
    };
  
    const handleRemoveFile = (id) => {
      const fileList = fileUpload.filter((item) => item.id !== id);
      setFileUpload(fileList);
    };
  
    // Validate Email- Address
  
    const emailValidate = (mail) => {
      if (/^[a-zA-Z0-9_]+@[a-zA-Z0-9_]+\.[A-Za-z]+(\.[A-Za-z]+)?$/.test(mail)) {
        return true;
      }
      return false;
    };
  
    const handleAddedFieldChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    };
  
    const ValidateAllAdditionalFields = (values) => {
      if (values) {
        const errors = {};
        const { customerName, customerEmail, selectedInsurer, selectedProduct } =
          values;
        if (!customerName) {
          errors.customerName = "Required";
        }
        if (!customerEmail) {
          errors.customerEmail = "Required";
        }
        if (customerEmail) {
          if (!emailValidate(customerEmail)) {
            errors.customerEmail = "You have entered an invalid email address!";
          }
        }
        if (Object.keys(selectedInsurer).length == 0) {
          errors.selectedInsurer = "Required";
        }
        if (Object.keys(selectedProduct).length == 0) {
          errors.selectedProduct = "Required";
        }
        return errors;
      }
    };
  
    useEffect(() => {
      if (formValues != initialValuesForZeroLead) {
        setFormErrors(ValidateAllAdditionalFields(formValues));
      }
    }, [formValues]);
  
    useEffect(() => {
      if (
        Array.isArray(formErrors) &&
        Object.keys(formErrors).length == 0 &&
        isSubmit
      ) {
        console.log("isSbutmit pressed and no form errors", formErrors, isSubmit);
      }
    }, [formErrors]);

    const OpenDispatchDialog = () => {
      let dispatchPayload = {
        "TicketId": "",
        "BookingId": isFetchDetail?.leadDetailResForUI?.LeadID,
        "DispatcherId": isFetchDetail?.leadDetailResForUI?.DispatcherId,
        "ProductId": isFetchDetail?.leadDetailResForUI?.ProductID
      }
      let cb = (url) => {
        if (url) {
          setDialogWithControls({
            open: true,
            title: 'Dispatch Request',
            handleClose: () => { setDialogWithControls(); setCustomDialogControl(customDialogInitialState); resetIssueSubIssue() },
            size: 80,
            children: <div className="iframe-box"><iframe src={url} referrerPolicy="no-referrer" /></div>
          })
        }
      }
      fetchDispatcherURL(dispatchPayload, cb)
    }

    let resetIssueSubIssue = () => setCreateTicketFormValues({
      issue : "",
      issueId : "", 
      subIssue : "",
      subIssueId : ""
    })


    useEffect(() => {
      if (isFetchDetail.leadDetailResForUI && formValues) {
        if (
          AllowedToRaiseEndorsementAtLogin(isFetchDetail.leadDetailResForUI?.ProductID, isFetchDetail.leadDetailResForUI?.SupplierID, isFetchDetail.leadDetailResForUI?.InvestmentTypeID) &&
          checkProductIssueSubIssue(isFetchDetail?.leadDetailResForUI?.ProductID, createTicketFormValues.issue, createTicketFormValues.subIssue) && isFetchDetail.leadDetailResForUI?.LeadID
        ) {
          let cbForEndorsement = (data, error) => {
            setShowLoaderWithText("")
            if (data) {
              setEndorsementURL(data);
            } else showErrMsg(error);
          };
          
          let fetchEndorsementURLFunc = () => {
            let ticketDetails = {
              LeadID : isFetchDetail.leadDetailResForUI?.LeadID,
              ProductId :isFetchDetail.leadDetailResForUI?.ProductID,
              TicketId : 0,
              CustomerId : isFetchDetail.leadDetailResForUI?.CustomerID,
              LeadID : isFetchDetail.leadDetailResForUI?.LeadID,
              TicketDetailsID : "",
            }
            setShowLoaderWithText({ open : true, text : 'Please wait...'})
            fetchEndosementURL(
              "/GetEndorsementSystemUrl",
              ticketDetails,
              cbForEndorsement
            );
          };
  
          setCustomDialogControl({
            title: "Raise new endorsement?",
            open: true,
            actionButtons: [
              {
                Name: "Cancel",
                className: "raise-cencel",
                onClick: () => {
                  setCustomDialogControl(customDialogInitialState);
                  resetIssueSubIssue()
                },
              },
              {
                Name: "Raise Endorsement",
                className: "raise-btn",
                onClick: ()=>{
                  fetchEndorsementURLFunc();
                  setCustomDialogControl(customDialogInitialState);
                },
              },
            ],
            handleClose: () => setCustomDialogControl(customDialogInitialState),
          });
        }
        else if(checkIssueSubIssueForDispatchRequest(isFetchDetail?.leadDetailResForUI?.ProductID, createTicketFormValues.issue, createTicketFormValues.subIssue)){
          setCustomDialogControl({
            title: "Raise Dispatch Request ?",
            open: true,
            actionButtons: [
              {
                Name: "Cancel",
                className: "raise-cencel",
                onClick: () => {setCustomDialogControl(customDialogInitialState); resetIssueSubIssue()},
              },
              {
                Name: "Raise Request",
                className: "raise-btn",
                onClick: OpenDispatchDialog,
              },
            ],
            handleClose: () => {setCustomDialogControl(customDialogInitialState);  resetIssueSubIssue()},
          });
        }
      }
    }, [createTicketFormValues.issue, createTicketFormValues.subIssue]);
  
    return (
      <div>
        {authorized ? (
          <>
          {showLoaderWithText && showLoaderWithText.open && <CustomBackdrop open={showLoaderWithText.open} textToShow={showLoaderWithText.text || ""}/>}
            <div className="all-comments matrix-ticket">
              <div className="create-new-ticket">
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      error={
                        (!fetchDetailFormValues.leadId &&
                          fetchDetailFormErrors &&
                          fetchDetailFormErrors.leadId) ||
                        (errMessages && true)
                      }
                    >
                      <TextField
                        label="Enter Lead ID"
                        type="number"
                        id="outlined-margin-normal"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        name="leadId"
                        value={
                          fetchDetailFormValues && fetchDetailFormValues.leadId
                        }
                        onChange={(e) => {
                          handleChange(e, setfetchDetailFormValues);
                        }}
                      />
                      {errMessages && errMessages.leadId && (
                        <FormHelperText>{errMessages.leadId}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {console.log('fetchDetailFormValues--->', fetchDetailFormValues)}
                  <Grid item md={4} xs={12}>
                    <Button
                      className="fetch-details-btn"
                      disabled={fetchDetailFormValues && fetchDetailFormValues.leadId===""}
                      onClick={(e) => {
                        handleFetchDetail(e);
                      }}
                    >
                      <span>Fetch Details</span>
                    </Button>
                  </Grid>
                </Grid>
                {!isShowLess &&
                  (fetchingDetail ? (
                    <div className="spinner-loader">
                      <Spinner />
                    </div>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      onDragOver={handleDrag}
                      onDrop={(e) => handleDrop(e, setFileUpload, fileRef)}
                      onDragLeave={handleDragOut}
                      onDragEnter={handleDragIn}
                      // justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item md={6} xs={12} className="select-box">
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          error={
                            !createTicketFormValues.issue &&
                            createTicketFormErrors.issue
                          }
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Issue
                          </InputLabel>
                          <Select
                            value={createTicketFormValues.issue}
                            label="Issue"
                            inputProps={{
                              name: "issue",
                              id: "outlined-age-native-simple",
                            }}
                          >
                            {issueOptions.map((item) => {
                              return (
                                <MenuItem
                                  value={item.IssueName}
                                  onClick={() => {
                                    handleIssueSubIssue(
                                      item.IssueTypeId,
                                      item.IssueName,
                                      "issue"
                                    )
                                  }
                                  }
                                >
                                  {" "}
                                  {item.IssueName}{" "}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {!createTicketFormValues.issue &&
                            createTicketFormErrors.issue && (
                              <FormHelperText className="inline-eroro-msg" style={{bottom:"-20px",left:"-7px"}}>Required</FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12} className="select-box">
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          error={
                            !createTicketFormValues.subIssue &&
                            createTicketFormErrors.subIssue
                          }
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Sub Issue
                          </InputLabel>
                          <Select
                            value={createTicketFormValues.subIssue}
                            label="Sub Issue"
                            inputProps={{
                              name: "subIssue",
                              id: "outlined-age-native-simple",
                            }}
                          >
                            {subIssueOptions.map((item) => {
                              return (
                                <MenuItem
                                  value={item.IssueName}
                                  onClick={() =>
                                    handleIssueSubIssue(
                                      item.IssueTypeId,
                                      item.IssueName
                                    )
                                  }
                                >
                                  {" "}
                                  {item.IssueName}{" "}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {!createTicketFormValues.subIssue &&
                            createTicketFormErrors.subIssue && (
                              <FormHelperText className="inline-eroro-msg"style={{bottom:"-20px",left:"-7px"}}>Required</FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            label="customerName"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            name="customerName"
                            error={
                              fetchDetailFormValues &&
                              fetchDetailFormValues.leadId == 0 &&
                              formErrors &&
                              formErrors.customerName
                            }
                            value={
                              isFetchDetail &&
                              isFetchDetail.leadDetailResForUI &&
                              isFetchDetail.leadDetailResForUI.CustomerName
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => handleAddedFieldChange(e)}
                            disabled={
                              fetchDetailFormValues &&
                              fetchDetailFormValues.leadId != 0
                            }
                          />
                        </FormControl>
                        {fetchDetailFormValues &&
                          fetchDetailFormValues.leadId == 0 &&
                          formErrors &&
                          formErrors.customerName && (
                            <FormHelperText
                              className="inline-eroro-msg"
                            >
                              {formErrors.customerName}
                            </FormHelperText>
                          )}
                      </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            label="Email"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            name="customerEmail"
                            error={
                              fetchDetailFormValues &&
                              fetchDetailFormValues.leadId == 0 &&
                              formErrors.customerEmail
                            }
                            value={
                              isFetchDetail &&
                              isFetchDetail.leadDetailResForUI &&
                              isFetchDetail.leadDetailResForUI.EmailID
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => {
                              handleAddedFieldChange(e);
                            }}
                            disabled={
                              fetchDetailFormValues &&
                              fetchDetailFormValues.leadId != 0
                            }
                          />
                        </FormControl>
                        {fetchDetailFormValues &&
                          fetchDetailFormValues.leadId == 0 &&
                          formErrors &&
                          formErrors.customerEmail && (
                            <FormHelperText
                            className="inline-eroro-msg"
                            >
                              {formErrors.customerEmail}
                            </FormHelperText>
                          )}
                      </Grid>
                      {
                      // leadId != 0 ||
                      (fetchDetailFormValues &&
                        fetchDetailFormValues.leadId != 0) ? (
                          <Grid item md={6} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <TextField
                              label="product"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              value={
                                isFetchDetail &&
                                isFetchDetail.leadDetailResForUI &&
                                isFetchDetail.leadDetailResForUI.ProductName
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid item md={6} xs={12} className="select-box">
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            error={formErrors && formErrors.selectedProduct}
                          >
                            <InputLabel htmlFor="outlined-age-native-simple">
                              Product
                            </InputLabel>
                            <Select
                              value={
                                formValues.selectedProduct &&
                                formValues.selectedProduct.ProductID
                                  ? formValues.selectedProduct.ProductID
                                  : ""
                              }
                              label="Product"
                              inputProps={{
                                name: "selectedProduct",
                                id: "outlined-age-native-simple",
                              }}
                            >
                              {productList.map((item) => {
                                return (
                                  <MenuItem
                                    value={item.ProductID}
                                    onClick={(e) => {
                                      //handleAddedFieldChange(e)
                                      setFormValues({
                                        ...formValues,
                                        selectedProduct: item,
                                      });
                                      handleSupplierProductChange(item.ProductID);
                                    }}
                                  >
                                    {" "}
                                    {item.ProductName}{" "}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {formErrors && formErrors.selectedProduct && (
                              <FormHelperText  className="inline-eroro-msg" style={{bottom:"-20px",left:"-7px"}}>
                                {formErrors.selectedProduct}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )}
  
                      {
                      // leadId != 0 ||
                      (fetchDetailFormValues &&
                        fetchDetailFormValues.leadId != 0) ? (
                        <Grid item md={6} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <TextField
                              label="insurer"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              value={
                                (isFetchDetail &&
                                  isFetchDetail.leadDetailResForUI &&
                                  isFetchDetail.leadDetailResForUI
                                    .SupplierName) ||
                                getSupplierName()
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled="true"
                            />
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid item md={6} xs={12} className="select-box">
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            error={formErrors && formErrors.selectedInsurer}
                          >
                            <InputLabel htmlFor="outlined-age-native-simple">
                              Insurer
                            </InputLabel>
                            <Select
                              value={
                                formValues.selectedInsurer &&
                                formValues.selectedInsurer.SupplierID
                                  ? formValues.selectedInsurer.SupplierID
                                  : ""
                              }
                              label="Product"
                              inputProps={{
                                name: "selectedInsurer",
                                id: "outlined-age-native-simple",
                              }}
                            >
                              {insurerList &&
                                insurerList.length > 0 &&
                                insurerList.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item.SupplierID}
                                      onClick={(e) => {
                                        // handleAddedFieldChange(e)
                                        setFormValues({
                                          ...formValues,
                                          selectedInsurer: item,
                                        });
                                      }}
                                    >
                                      {" "}
                                      {item.SupplierName}{" "}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            {formErrors && formErrors.selectedInsurer && (
                              <FormHelperText  className="inline-eroro-msg" style={{bottom:"-20px",left:"-7px"}}>
                                {formErrors.selectedInsurer}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )}
  
                   <Grid item md={6} xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            label="Application No"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={
                              isFetchDetail &&
                              isFetchDetail.leadDetailResForUI &&
                              isFetchDetail.leadDetailResForUI.ApplicationNo
                            }
                            disabled="true"
                          />
                        </FormControl>
                      </Grid>
  
                      {isFetchDetail.leadDetailResForUI &&
                        (isFetchDetail.leadDetailResForUI.ProductID == 117 ||
                          isFetchDetail.leadDetailResForUI.ProductID === 114) && (
                       <Grid item md={4} xs={12}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <TextField
                                label="Registraion No"
                                id="outlined-margin-normal"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                value={
                                  isFetchDetail &&
                                  isFetchDetail.leadDetailResForUI &&
                                  isFetchDetail.leadDetailResForUI.RegistrationNo
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled="true"
                              />
                            </FormControl>
                          </Grid>
                        )}
                      {isFetchDetail.leadDetailResForUI &&
                        isFetchDetail.leadDetailResForUI.ProductID == 2 && (
                          <Grid item md={6} xs={12} className="select-box">
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              error={
                                !createTicketFormValues.initiatedby &&
                                createTicketFormErrors.initiatedby
                              }
                            >
                              <InputLabel htmlFor="outlined-age-native-simple">
                                Initiated By
                              </InputLabel>
                              <Select
                                value={createTicketFormValues.initiatedby}
                                label="Initiated By"
                                inputProps={{
                                  name: "initiatedby",
                                  id: "outlined-age-native-simple",
                                }}
                              >
                                {InitiatedByList.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item}
                                      onClick={() =>
                                        handleIssueSubIssue(
                                          0,
                                          item,
                                          "initiatedby"
                                        )
                                      }
                                    >
                                      {" "}
                                      {item}{" "}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {!createTicketFormValues.initiatedby &&
                                createTicketFormErrors.initiatedby && (
                                  <FormHelperText className="inline-eroro-msg" style={{bottom:"-20px",left:"-7px"}}>Required</FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        )}
                      <Grid item md={12} xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextareaAutosize
                            placeholder="Enter Comments"
                            name="comment"
                            value={createTicketFormValues.comment}
                            onChange={(e) =>
                              handleChange(e, setCreateTicketFormValues)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <FileUploadComponent
                          setFiles={setFileUpload}
                          fileRef={fileRef}
                        />
                        <div className="uplaod-doc-view">
                          {fileUpload.map((item) => {
                            return (
                              <span className="file-detail" key={item.id}>
                                {item.FileName}{" "}
                                <button onClick={() => handleRemoveFile(item.id)}>
                                  <CloseIcon />
                                </button>
                              </span>
                            );
                          })}
                        </div>
                      </Grid>
                      <Grid item md={6} xs={6} className="text-right">
                        <Button
                          style={{display:'inline-block'}}
                          className="submit-btn"
                          onClick={handleCreateTicket}
                          disabled={isCreateTicketInProgress}
                        >
                          {isCreateTicketInProgress ? (
                            <Spinner classaName="spinner-btn" />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </div>
            </div>
            {/* </Dialog> */}
            <CopyTicketIdDialog
              isDialogOpen={isDialogOpen}
              setDialogOpen={setDialogOpen}
              Msg={TicketCreatedMsg}
              setTicketCreatedMsg={setTicketCreatedMsg}
              closeParentDialog={closeParentDialog}
            />
          </>
        ) : (
          <>
            <div className={classes.CircularProgressBar}>
              <CircularProgress />
            </div>
          </>
        )}
        <CustomRaiseRequestModal
          open={customDialogControl.open}
          title={customDialogControl.title}
          buttonWithFunction={customDialogControl.actionButtons}
          handleClose={customDialogControl.handleClose}
        />
        <RaiseNewEndorsement
          open={Boolean(endorsementURL)}
          raiseEndorsementURL={endorsementURL}
          closeRaiseEndorsement={()=> {setEndorsementURL(""); window.location.reload()}}
        />
        {dialogWithControls && dialogWithControls.open &&
          <DialogFromRight
            open={dialogWithControls.open}
            handleClose={dialogWithControls.handleClose}
            title={dialogWithControls.title}
            children={dialogWithControls.children}
            size={dialogWithControls.size}
          />
        }
      </div>
    );
  };
  
  const mapStateToProps = (state) => {
    return {
      auth: state && state.auth && state.auth.userInfo ? state.auth.userInfo : {},
      authorized:
        state && state.auth && state.auth.auth ? state.auth.auth : false,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      OnAuthDispatch: (payload) => {
        dispatch(actions.authLogin(payload));
      },
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardPB_CreateTicket);
  