import * as type from "../types/types";

const initialState = {
  navigateToTab : null,
  confirmDialogControls: null,
  refreshAnyThing : {},
  conditionalRenderNewCustomerPendency : false,
  tempToken: ""
};

const refreshAnythingBasedOnKey = (state, action) =>{
  return{
      ...state,
      refreshAnyThing : {
          ...state.refreshAnyThing,
          [action.payload] : (state.refreshAnyThing[action.payload] || 0) + 1
      }
  }
}

export default function Utility(state = initialState, action) {
  switch (action.type) {
    case type.NAVIGATE_TO_TAB:
      return {
        ...state,
        navigateToTab: action.payload
      }
    case type.SET_CONFIRM_DIALOG_CONTROLS:
      return {
        ...state,
        confirmDialogControls: action.payload
      }
    case type.SET_REFRESH_ANYTHING_BASED_ON_KEY:
      return refreshAnythingBasedOnKey(state, action)
    case type.CONDITIONALLY_RENDER_NEW_CUSTOMER_PENDENCY:
      return {
        ...state,
        conditionalRenderNewCustomerPendency: action.payload
      }
    case type.SET_TEMP_TOKEN:
      return {
        ...state,
        tempToken: action.payload
      }
    default:
      return state;
  }
}