export const AUTH_LOGIN_REQUESTED = 'AUTH_LOGIN_REQUESTED';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_INFO = 'USER_INFO';
export const AUTH_SET = 'AUTH_SET';

export const GET_TICKET_LOGS_REQUESTED = 'GET_TICKET_LOGS_REQUESTED'; 
export const GET_TICKET_LOGS_SUCCESS = 'GET_TICKET_LOGS_SUCCESS'; 
export const GET_TICKET_LOGS_FAILED = 'GET_TICKET_LOGS_FAILED'; 

export const GET_FILTER_COUNT_REQUESTED = 'GET_FILTER_COUNT_REQUESTED';
export const GET_FILTER_COUNT_SUCCESS = 'GET_FILTER_COUNT_SUCCESS';
export const GET_FILTER_COUNT_FAILED = 'GET_FILTER_COUNT_FAILED';

export const GET_TICKET_COMM_REQUESTED = 'GET_TICKET_COMM_REQUESTED'; 
export const GET_TICKET_COMM_SUCCESS = 'GET_TICKET_COMM_SUCCESS'; 
export const GET_TICKET_COMM_FAILED = 'GET_TICKET_COMM_FAILED'; 

export const GET_TICKET_DETAILS_BY_TICKET_REQUESTED = 'GET_TICKET_DETAILS_BY_TICKET_REQUESTED'; 
export const GET_TICKET_DETAILS_BY_TICKET_SUCCESS = 'GET_TICKET_DETAILS_BY_TICKET_SUCCESS'; 
export const GET_TICKET_DETAILS_BY_TICKET_FAILED = 'GET_TICKET_DETAILS_BY_TICKET_FAILED'; 

export const SET_TICKET_DETAILS ='SET_TICKET_DETAILS';
export const SET_TICKET_COMM_DATA = 'SET_TICKET_COMM_DATA';
export const SET_TEMPLATE_LIST = 'SET_TEMPLATE_LIST';
export const SET_TICKET_LOADING = 'SET_TICKET_LOADING';
export const SET_INSURER_TEMPLATE_LIST = 'SET_INSURER_TEMPLATE_LIST';
export const SET_ACTIVE_TICKET_ID = 'SET_ACTIVE_TICKET_ID';

//Related to isOneTicket
export const SET_BUCKET_DETAILS = 'SET_BUCKET_DETAILS';

export const SET_INSURER_PRODUCT_ACCESS = 'SET_INSURER_PRODUCT_ACCESS';

//Related to PB Agent TickeDashboard

export const SET_AGENT_SIMPLE_SEARCH_DATA = "SET_AGENT_SIMPLE_SEARCH_DATA";
export const SET_ADMIN_SIMPLE_SEARCH_VALUE = "SET_ADMIN_SIMPLE_SEARCH_VALUE";
export const SET_AGENT_ADVANCED_SEARCH_DATA = "SET_AGENT_ADVANCED_SEARCH_DATA";
export const SET_ADMIN_ADVANCED_SEARCH_VALUES = "SET_ADMIN_ADVANCED_SEARCH_VALUES"; 
export const SET_DASHBOARD_OPTION = 'SET_DASHBOARD_OPTION';
export const RESET_SEARCH_OPTIONS = 'RESET_SEARCH_OPTIONS';
export const DATA_TO_RENDER = 'DATA_TO_RENDER';
export const PAGE_SELECTED = 'PAGE_SELECTED';
export const DATA_LOADING = 'DATA_LOADING';
export const SET_PRODUCT_SUPPLIER_MASTER = 'SET_PRODUCT_SUPPLIER_MASTER';
export const SET_STATUS_SUB_STATUS_MASTER = 'SET_STATUS_SUB_STATUS_MASTER';
export const SET_TICKET_SOURCE_MASTER = 'SET_TICKET_SOURCE_MASTER';
export const SET_TICKET_SUB_SOURCE_MASTER = 'SET_TICKET_SUB_SOURCE_MASTER';

export const SET_PRODUCT_GROUP_MASTER = 'SET_PRODUCT_GROUP_MASTER';
export const SET_ZERO_LEAD_SELECTED = 'SET_ZERO_LEAD_SELECTED';

export const SCREEN_NAME = 'SCREEN_NAME'

export const NAVIGATE_TO_TAB = 'NAVIGATE_TO_TAB';
export const SET_CONFIRM_DIALOG_CONTROLS = 'SET_CONFIRM_DIALOG_CONTROLS';
export const SET_REFRESH_ANYTHING_BASED_ON_KEY = 'SET_REFRESH_ANYTHING_BASED_ON_KEY';
export const CONDITIONALLY_RENDER_NEW_CUSTOMER_PENDENCY = 'CONDITIONALLY_RENDER_NEW_CUSTOMER_PENDENCY'
export const SET_TEMP_TOKEN = 'SET_TEMP_TOKEN';

//FOR WE CARE
export const SET_WE_CARE_ADMIN_SELECTION = 'SET_WE_CARE_ADMIN_SELECTION'