import React from "react";
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from "history";
import RouteFile from "./Routes";
import ParentErrorBoundary from "./ParentErrorBoundary";
import { Router, BrowserRouter } from "react-router-dom";


const browserHistory = createBrowserHistory();
function App() {

  return (
    <BrowserRouter>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        maxSnack={3}>
        <Router history={browserHistory}>
          <ParentErrorBoundary>
            <div id='snackbarhelper'></div>
            <RouteFile history={browserHistory}/>
          </ParentErrorBoundary>
        </Router>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
