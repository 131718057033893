import React, { useState, Fragment, useEffect, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
// import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// import Skeleton from "@material-ui/lab/Skeleton";
import {history, useHistory} from "react-router-dom";
// import * as services from '../../../services'
import Spinner from "../../shared/components/Spinner";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    appBar: {
        background: "#fff",
        position: "relative",
    },
    toolbarTop: {
        minHeight: "10px",
        padding: "0 3px",
    },
    closeBtn:{
        color:" #414141",
        margin: "0 10px",
        background: "#f5f5f5",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="left"
            ref={ref}
            {...props}
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 500, exit: true ? 1 : 700 }}
        />
    );
});

export default (props) => {
    const { handleCloseIframe, setOpenIframe, leadDetails } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory()

    // console.log('iframe opened');
    // const connectCall = (params) => {
    //     let url = `calling/ConnectCall`;
    //     let payload = {
    //       ...params,
    //     };
    //     setIsLoading(true);
    //     services
    //       .API_POST(url, payload)
    //       .then((res) => {
    //         setIsLoading(false)
    //         if (res && res["IsSuccess"]) {
    //           enqueueSnackbar("Call connected successfully", {
    //             variant: "success",onClick:()=>{closeSnackbar();}
    //           });
    //         } else {
    //           let msg=(res && res['Message']) || 'Failed';
    //           enqueueSnackbar(msg, { variant: "error",onClick:()=>{closeSnackbar();} });
    //         }
    //       })
    //       .catch((err) => {
    //         setIsLoading(false);
    //         console.log("Error", err);
    //       });
    //   };

    // const handleCallConnectionWindow = useCallback(event => {
    //     console.log('event.data.type: ', event);
    //     switch (event.data.type) {
    //       case "TicketCalling":
    //         localStorage.setItem("temp-call-lead", `${event.data.data.LeadID}`);
    //         localStorage.setItem("temp-call-phone-number", `${event.data.data.MaskedMobileNo}`);
    //         const payload = {
    //           MobileNo: event.data.data.Mobile,
    //           CountryCode: event.data.data.CountryID,
    //           LeadId: event.data.data.LeadIDEncrypted
    //         };
    //         connectCall(payload);
    //         break;
    //       case "openBookingDetails":
    //             handleClose()
    //         break;
    //       default:
    //         break;
    //     }
    // }, []);
    
    // useEffect(() => {
    //     window.addEventListener("message", handleCallConnectionWindow, false);
    //     return () => {
    //         window.removeEventListener("message", handleCallConnectionWindow, false);
    //     };
    // }, []);

    return (
        <div>
            <Dialog
                // open={open}
                open={true}
                fullScreen
                fullWidth={true}
                // className={className?'dialog-inner-box': "dialog-box"}
                className="dialog-box-assign"
                onClose={handleCloseIframe}
                TransitionComponent={Transition}
            >
                <Fragment>
                    <AppBar className={classes.appBar}>
                        <Toolbar className={classes.toolbarTop}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseIframe}
                                aria-label="close"
                                className={classes.closeBtn}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {isLoading ? (
                        <div className="spinner-loader">
                            <Spinner />
                        </div>
                    ) : null}
                    <div className="whatsapp-dialog-popup">
                        <iframe
                            onLoad={() => setIsLoading(false)}
                            src={leadDetails.WhatsappLink}
                            frameBorder="0"
                            style={{ width: "100%", height: isLoading ? '0%' : '90vh' }}
                            align="center"
                            referrerPolicy="no-referrer"
                        ></iframe>
                    </div>
                </Fragment>
            </Dialog>
        </div>
    );
};
