import { combineReducers } from "redux";
import { USER_LOGOUT } from "../types/types";
import auth from "./auth";
import Common from "./common_reducer.js";
import pbDashboard_reducer from "./pbDashboard_reducer";
import ticketDetails from "./ticketDetails";
import ticketLogs from "./ticketlogs";
import Utility from "./utility_reducers";
import weCareDashboard_reducer from "./weCareDashboard_reducer.js";

const rootReducer = combineReducers({
  auth: auth,
  common: Common,
  ticketLogs: ticketLogs,
  ticketdetails: ticketDetails,
  pbDashboard : pbDashboard_reducer,
  weCareDashboard: weCareDashboard_reducer,
  Utility : Utility,
});

export default (state, action) => {
  if (action.type === USER_LOGOUT) {
    localStorage.removeItem('persist:root')
    state = undefined;
  }
  return rootReducer(state, action);
};
