import { call, put, takeEvery } from "redux-saga/effects";
import * as type from "../types/types";


function* ticketDetails() {
   
    yield put({ type: type.SET_TICKET_DETAILS });
}

export default ticketDetails;
