
export const SampleUploadIssue = (productID, productName) => {
  let newObj ={
      "ProductName" : productName,
      "ProductId" : productID,
      "IssueName" : "",
      "IsMyAccount" : 0,
      "SequenceID" : 0,
      "TopIssue" : 0,
      "IssueCode" : "",
      "CategoryType" : 0,
      "IsChat" : 0,
      "IsAutoClosure" : 0,
      "TAT" : 0,
      "Response" : "",
      "DisplayName" : "",
      "StatusID" : "Documents",
      "SubStatus" : "Documents Received by insurer",
      "MedicalType" : 0,
      "StatusAgeing" : 0,
      "DefaultClassification" : 0,
      "NextClassification" : 0,
      "IssueIconURL" : "",
      "AdditionalFilter" : 0,
      "CancelationId" : 0,
      "CancelationReason" : "",
      "IsWA" : 0,
      "WADisplayName":  "",
      "IsIvr" : 0,
      "IvrDisplayname" : "",
      "IVRHindiName" : "",
      "ChatDisplayName": "",
      "IsBms": "0",
      "CommDisplayName" : "",
      "WADisplayName72" : "",
      "IsComm" : 0,
      "SequenceChangeOn" : "",
      "IsWeCare" : "",
      "NSTPSequenceId" : 0,
      "MergeIssueCode" : "",
  }
return [newObj]
}

export const SampleUploadSubIssue =(productName, IssueName, StatusName, SubStatusName, ParentID) =>{
  let obj = {
    "ProductName": productName,
    "IssueName": IssueName,
    "ParentID": ParentID || 0,
    "IsMyAccount": 0,                                                                         
    "SequenceID": 0,
    "TopIssue": 0,
    "IssueCode": "",
    "CategoryType": "",
    "IsChat": 0,
    "IsAutoClosure": 0,
    "Response": "",
    "DisplayName": "",
    "MedicalType": 0,
    "StatusAgeing":0,
    "DefaultClassification": 0,
    "NextClassification": 0,
    "IssueIconURL": "",
    "AdditionalFilter": 0,
    "StatusName": StatusName,
    "SubStatusName": SubStatusName,
    //"CreatedBy": 124,
    "IsWA": 0,
    "WADisplayName": "",
    "IsIvr": 0,
    "IvrDisplayname": "",
    "IVRHindiName": "",
    "ChatDisplayName": "",
    "IsBms": 1,
    "CommDisplayName": "",
    "WADisplayName72": "",
    "IsComm": 0,
    "SequenceChangeOn": "",
    "IsWeCare": 0,
    "NSTPSequenceId": 0,
    "MergeIssueCode": "",
    "SIFlag":-1,
    "VisitType":-1,
    "ScheduleFlag":-1,
    "PIVCFlag":-1,
    "FLCFlag":-1,
    "IsPFFilled":-1,
    "IsSTP":-1,
    "Periodicity":-1,
    "MedicalScheduledFlag":-1,
    "Within60daysofexpiry":-1,
    "Expired":-1,
    "Expired90Days":-1,
    "ClosingStatusID":38,
    "ClosingSubStatusID":0,
    "ChangeInPolicyFlag":0,
    "DoubleDecutionFlag":0,
    "RejectReasonFlag":0,
    "IsEndorsementFlag":0,
  }
  return [obj]
}

export const SampleUploadIssueFilter =() =>{
  let obj = {
    "IssueId": 10468,
    "FilterType": "healthRenewalSalesAssignment",
  }
  return [obj]
}


export const SampleUploadIRDATAT =() =>{
  let obj = {
    "ProductID":2,
    "IssueId": 90910,
    "SubIssueId": 91901,
    "PromiseTime": 1,
    "L0_Green":1,
    "L1_Amber":1,
    "L2_Red":1,
    "PromiseTimeLB":1,
    "PromiseTimeUB":3,
  }
  return [obj]
}

export const SampleUploadSubIssueCancellationReason =() =>{
  let obj = {
    "CancellationReason": "Amount Auto Return",
    "SubIssueName":" Not Recevied The refund amount",
    "ProductId":117,
  }
  return [obj]
}

export const SampleUploadIssueField =() =>{
  let obj = {
    "SubIssueId": 92126,
    "FieldID": 27,
    "StatusID": 13,
    "SubStatusID": 0,
    "ProductID":2,
  }
  return [obj]
}
  
