import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  TableRow,
  TableCell,
} from "@material-ui/core";

const Employee = ({ employee, ind, selectedEmployee, setSelectedEmployee }) => {
  return (
    <TableRow className="sme-body-list">
      <TableCell align="left">
        <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
          <FormControlLabel
            value="female"
            control={
              <Radio
                onClick={() => setSelectedEmployee(ind)}
                checked={ind == selectedEmployee}
              />
            }
          />
        </RadioGroup>
        {employee.EmployeeName +
          (employee.EmployeeCode ? ` (${employee.EmployeeCode})` : "")}
      </TableCell>
      <TableCell align="left">{employee.LeadID}</TableCell>
      <TableCell align="left">{employee.CompanyName}</TableCell>
    </TableRow>
  );
};

export default Employee;
