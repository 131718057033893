import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  TextField,
  Grid,
  FormControl,
  TextareaAutosize,
} from "@material-ui/core";

import DialogTitle from "../../../../shared/components/DialogTitle";
import {
  GetDocURL,
  GetTicketTicketEscLog,
} from "../../../../constants/apiUrls";
import { GET } from "../../../../services/apiServices";
import { convertToIsoFormate } from "../../../../shared/methods/methods";
import Spinner from "../../../../shared/components/Spinner";
import { useSnackbar } from "notistack";

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default function EsclationFormDialog({
  isDialogOpen,
  setDialogOpen,
  TicketDetailsID,
}) {
  const [EsclationLog, setEsclationLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setIsLoading(true);
    GET(`${GetTicketTicketEscLog}/${TicketDetailsID}`)
      .then((res) => {
        setEsclationLog(res.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  // Download Attachment through secure link

  const downloadAttachment = (docID) => {
    if (docID) {
      GET(`${GetDocURL}/${docID}`)
        .then((res) => {
          if (res && res.ErrorCode == 0 && res.Data) {
            window.open(res.Data, "_blank");
          } else {
            if (res.Response) {
              enqueueSnackbar(res.Response, {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            } else {
              enqueueSnackbar("URL not found", {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            }
            console.log("something went wrong");
          }
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log(err);
        });
    } else {
      enqueueSnackbar("Document not found", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      console.log("Document ID not found");
    }
  };
  // -------------------------------------

  return (
    <div>
      <Dialog
        maxWidth="md"
        className="reply-popup escalation-popup"
        onClose={() => setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
      >
        <DialogTitle
          className="title-heading"
          id="customized-dialog-title"
          onClose={() => setDialogOpen(false)}
        >
          Escalation Form{" "}
          {EsclationLog &&
          Array.isArray(EsclationLog) &&
          EsclationLog.length > 0 &&
          EsclationLog[0].CreatedOn ? (
            <span className="escalation-timing">
              ({convertToIsoFormate(EsclationLog[0].CreatedOn)})
            </span>
          ) : (
            ""
          )}
        </DialogTitle>
        {!isLoading ? (
          EsclationLog &&
          Array.isArray(EsclationLog) &&
          EsclationLog.length > 0 ? (
            EsclationLog.map((item, i) => {
              return (
                <div className="escalation-inner-data">
                  {item.CreatedOn && i !== 0 ? (
                    <DialogTitle
                      className="title-heading"
                      id="customized-dialog-title"
                    >
                      Escalation Form{" "}
                      <span className="escalation-timing">
                        ({convertToIsoFormate(item.CreatedOn)})
                      </span>
                    </DialogTitle>
                  ) : (
                    ""
                  )}
                  <DialogContent className="escalation-popup-box">
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <FormControl className="form-control">
                          <TextField
                            label="Full Name"
                            variant="outlined"
                            id="validation-outlined-input"
                            value={item.Name}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl className="form-control">
                          <TextField
                            fullWidth
                            label="Email ID"
                            variant="outlined"
                            id="validation-outlined-input"
                            value={item.EmailID}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl className="form-control">
                          <TextField
                            fullWidth
                            label="Mobile No"
                            variant="outlined"
                            id="validation-outlined-input"
                            value={item.MobileNo}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl className="form-control">
                          <TextField
                            fullWidth
                            label="Product"
                            variant="outlined"
                            value={item.Product}
                            id="validation-outlined-input"
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl className="form-control">
                          <TextField
                            fullWidth
                            label="Escalation To"
                            variant="outlined"
                            value={
                              item.EscType === 1
                                ? "HCS"
                                : item.EscType === 2
                                ? "CGO"
                                : ""
                            }
                            id="validation-outlined-input"
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormControl className="form-control">
                          <div className="comment-box">
                            <label>Customer Comments</label>
                            <TextareaAutosize
                              placeholder="Customer Comments"
                              disabled
                            >
                              {item.Remarks}
                            </TextareaAutosize>
                          </div>
                        </FormControl>
                      </Grid>
                      {item.LogAttachment &&
                        Array.isArray(item.LogAttachment) &&
                        item.LogAttachment.length > 0 &&
                        item.LogAttachment[0].AttachmentURL && (
                          <Grid item md={12} xs={12} className="text-right">
                            {/* <a target="_blank" rel="noreferrer"
               href={item.LogAttachment && Array.isArray(item.LogAttachment) && item.LogAttachment.length > 0
                ? item.LogAttachment[0].AttachmentURL : ""}> */}
                            <span
                              className="attachemt-link"
                              onClick={() =>
                                downloadAttachment(
                                  item.LogAttachment &&
                                    Array.isArray(item.LogAttachment) &&
                                    item.LogAttachment.length > 0
                                    ? item.LogAttachment[0].DocId
                                    : ""
                                )
                              }
                            >
                              <Button className="save-btn">
                                View Attachments
                              </Button>
                            </span>
                            {/* </a> */}
                          </Grid>
                        )}
                    </Grid>
                  </DialogContent>
                </div>
              );
            })
          ) : (
            <DialogContent className="no-data">
              <img src="/images/no_data_found.svg" alt="No Data Found" />
              <p>No Data Found</p>
            </DialogContent>
          )
        ) : (
          <span className="page-loader">
            <Spinner />
          </span>
        )}
      </Dialog>
    </div>
  );
}
