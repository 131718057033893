import { useEffect, useState, React } from 'react';
import { Link, ListItem, Grid, List, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setDashboardOption } from '../../../redux/actions/pbDashboard_action';
import ChildErrorBoundary from '../../../shared/components/ChildErrorBoundary';

import DialogFromRight from '../../../shared/components/DialogFromRight';
import AgentSearchOptions from './Components/AgentSearchOptions';
import AgentSimpleSearch from './Components/AgentSimpleSearch';
import AgentAdvanceSearch from './Components/AgentAdvanceSearch';
import PBDashboardCreateTicket from '../CommonComponents/PBDashboardCreateTicket';
import { useSelector } from 'react-redux';
import { GET } from '../../../services/apiServices';
import AgentDashboardCount from './Components/AgentDashboardCount';
import AgentDashboardData from './Components/AgentDashboardData';
import SystemUpdateAltSharpIcon from '@material-ui/icons/SystemUpdateAltSharp';
import PBDashboardHeader from '../PBdashboardHeader/PBDashboardHeader';



const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
    },
}))

const PB_AGENT_DASHBOARD = (props) => {
    const classes = useStyles();
    const { dashboardOption_store, setDashboardOptionSelected } = props;
    const [optionSelected, setOptionSelected] = useState(null);
    const userInfo = useSelector(state=> state && state.auth && state.auth.userInfo);
    const [dialogWithControls, setDialogWithControls] = useState();
    const [typeDataToRender, setTypeDataToRender] = useState([]);
    const [showDataToRender, setShowDataToRender] = useState(false);

    useEffect(()=>{
        setShowDataToRender(false);// if data is rendered for agent dashboard default data and later search type changes then the data shown is hidden
    },[dashboardOption_store])


    return (
        <>
            <div className={classes.root}>
                {console.log('option', optionSelected)}
                <div className="pbdashboard-summary ">
                    {/* <PBDashboardHeader/> */}
                    <AgentDashboardCount setTypeDataToRender={setTypeDataToRender} setShowDataToRender={setShowDataToRender}/>
                </div>
                {/* -----to be added-------- */}
                {/* ---------------- */}
                <AgentSearchOptions option_store={dashboardOption_store} setDashboardOption_store={setDashboardOptionSelected} />
                {
                    dashboardOption_store === "1" && <AgentSimpleSearch showDataToRender={showDataToRender} setShowDataToRender={setShowDataToRender}/>
                }

                {
                    dashboardOption_store === "2" && <AgentAdvanceSearch showDataToRender={showDataToRender} setShowDataToRender={setShowDataToRender}/>
                }
                {showDataToRender && <AgentDashboardData dataToRender={typeDataToRender}/>}
                {/* <ChildErrorBoundary><DataLoaded/></ChildErrorBoundary> */}


            </div>
            {dialogWithControls && dialogWithControls.open &&
                <DialogFromRight
                    open={dialogWithControls.open}
                    handleClose={dialogWithControls.handleClose}
                    title={dialogWithControls.title}
                    children={dialogWithControls.children}
                    size={dialogWithControls.size}
                />
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        dashboardOption_store: state && state.pbDashboard && state.pbDashboard.optionSelected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDashboardOptionSelected: (payload) => dispatch(setDashboardOption(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PB_AGENT_DASHBOARD)