import React, { useState, useContext } from "react";
import { List, Button, Grid, Tooltip } from "@material-ui/core";
import FullScreenDialog from "./components/CreateTicketDialog";
import MoreOptionMenu from "./components/MoreOptionMenu";
import SmeDetailForm from "./components/components/SmeDetailForm";
import TicketContext from "../../TicketContext";
import Escalate from "./components/Escalate";
import { isUserAllowedToEscalate, ShowPartnerLevel } from "../../Config";
import { useSelector, connect } from "react-redux";
import InfoIcon from "@material-ui/icons/Info";

function TicketDetails({
  TicketId,
  IsReopen,
  sourceList,
  IsEscalated,
  LeadId,
  setFormValues,
  TicketDetailsID,
  BlockAutoComm,
  TATEscalation,
  StatusID,
  formValues,
  masters,
  RefObjectId,
  isBlockAutoCommVisible,
  EndorsementLink,
  Source,
  isTopMgmt,
  isHCS,
  isSocial,
  isCGO,
  isIRDA,
  ParentTicketID,
  ProductID,
  SubSource,
  AccessTypeVal,
  PartnerLevel,
  viewtype,
  showNewTabs,
  isValidForSME,
  IsJAG, // JAG value from props and oldticketdetails
  FetchedTicketDetails,
  userInfo,
  IsJourney,
  bucketDetails_store,
  RenewalLeadId,
  IsReadOnlyUser_store,
}) {
  const [isCreateTicketDialogOpen, setCreateTicketDialogOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSMEDetailDialogOpen, setIsSMEDetailDialogOpen] = useState(false);
  const [isEscalateDialogOpen, setIsEscalateDialogOpen] = useState(false);
  const auth = useSelector((state) => state.auth.auth);
  const ticketdetails = useSelector((state) => state.ticketdetails);

  const ticketContext = useContext(TicketContext);

  return (
    viewtype == 0 && (
      <>
        <div className={!showNewTabs ? "topbar-data" : "topbar-data-new"}>
          <div
            className={!showNewTabs ? "ticket-id-flag" : "ticket-id-flag-new"}
          >
            {/* {!showNewTabs ? <h5>Ticket Details </h5> : null} */}
            <h5 id="topbarHeading">Ticket Details </h5>

            {TicketDetailsID && (
              <Button id="topbarSubSource" className="action-btn">
                <span id="topbarTicketDetailsID">{TicketDetailsID} -</span>
                <strong id="topbarSubSource" className="ticket-source">
                  {Source
                    ? `(${Source} ${SubSource ? "-" + SubSource : ""})`
                    : ""}
                </strong>
              </Button>
            )}
            {/* Shows Bucket Name of the Employee if configured in IsOneTicket */}
            {bucketDetails_store && bucketDetails_store.BkName && (
              <Button className="bucket-name-btn">
                {" "}
                {`BucketName : ${bucketDetails_store.BkName}`}{" "}
                <Tooltip title={bucketDetails_store.Action} placement="top">
                  <InfoIcon />
                </Tooltip>
              </Button>
            )}
            {ParentTicketID ? (
              <Button className="action-btn reopen">
                <strong id="ParentTicketID">Child Ticket</strong>
              </Button>
            ) : (
              ""
            )}
            {BlockAutoComm &&  !FetchedTicketDetails.AHCId && (
              <Button className="block-com">
                <span id="BlockAutoComm" className="block-communication">
                  {" "}
                  <img
                    width="22px"
                    src="/images/communication_block.png"
                    alt="block-communication"
                  />
                </span>
              </Button>
            )}
            {TATEscalation ? (
              <Button className="action-btn tat-esc">
                <strong id="TATEscalation">TAT ESC</strong>
              </Button>
            ) : (
              ""
            )}
            {IsReopen && (
              <Button className="action-btn reopen">
                <strong id="IsReopen">Reopen</strong>
              </Button>
            )}
            {IsEscalated && (
              <Button className="action-btn escalated">
                <strong id="IsEscalated">Escalated</strong>
              </Button>
            )}

            {isTopMgmt ? (
              <Button className="action-btn topmgnt">
                <strong id="isTopMgmt">TopMgmt</strong>
              </Button>
            ) : (
              ""
            )}

            {isHCS ? (
              <Button className="action-btn hcs">
                <strong id="isHCS">HCS</strong>
              </Button>
            ) : (
              ""
            )}

            {isSocial ? (
              <Button className="action-btn social">
                <strong id="isSocial">{SubSource === "AskPB" ? "Ask PB" : "Social"}</strong>
              </Button>
            ) : (
              ""
            )}

            {isCGO ? (
              <Button className="action-btn cgo">
                <strong id="isCGO">CGO</strong>
              </Button>
            ) : (
              ""
            )}

            {isIRDA ? (
              <Button className="action-btn Irda">
                <strong id="isIRDA">IRDA</strong>
              </Button>
            ) : (
              ""
            )}
            {IsJAG && (
              <Button className="action-btn escalated">
                <strong id="IsJAG">JAG</strong>
              </Button>
            )}
            {IsJourney ? (
              <Button className="action-btn Irda">
                <strong id="IsJourney">Journey</strong>
              </Button>
            ) : (
              ""
            )}
            {RenewalLeadId ? (
              <Button className="action-btn Irda">
                <strong>Renewal</strong>
              </Button>
            ) : (
              ""
            )}
            {PartnerLevel != null &&
            PartnerLevel != "" &&
            formValues &&
            ShowPartnerLevel(formValues.productID) ? (
              <Button className="action-btn krg">
                <strong>{PartnerLevel}</strong>
              </Button>
            ) : (
              ""
            )}
          </div>
          {viewtype == 0 && (
            <List className="ticket-btn-box">
              <Grid container>
                <Grid item md={12} xs={12} className="footer-ticket-data">
                  {FetchedTicketDetails &&
                  FetchedTicketDetails.ProductId === 131 &&
                  FetchedTicketDetails.InvestmentTypeID == 1 &&
                  ticketContext.isActionsAllowed ? (
                    // {ProductID === 131 && ticketContext.isActionsAllowed ? (
                    <Button
                      className="action-btn"
                      onClick={() => setIsSMEDetailDialogOpen(true)}
                    >
                      Employee Detail
                    </Button>
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("UserObjTicket") &&
                  isUserAllowedToEscalate(
                    JSON.parse(localStorage.getItem("UserObjTicket")).UserID
                  ) &&
                  isSocial != 1 &&
                  StatusID != 4 ? (
                    <Button
                      className="action-btn"
                      onClick={() => setIsEscalateDialogOpen(true)}
                      id="topbarEscalate"
                    >
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      Escalate
                    </Button>
                  ) : null}
                  {AccessTypeVal != 3  ? (
                    <Button
                      className="action-btn"
                      onClick={() => setCreateTicketDialogOpen(true)}
                      id="topBarCreateTicket"

                    >
                      <img
                        src="/images/create_ticket_btn.svg"
                        alt="create ticket"
                      />
                      Create Ticket
                    </Button>
                  ) : null}
                  {userInfo &&
                  userInfo.AccessType != 2 &&
                  !IsReadOnlyUser_store ? (
                    <Button
                      className="action-btn"
                      onClick={(e) => setIsMenuOpen(e.currentTarget)}
                      id="topbarMoreOption"
                    >
                      <img src="/images/more_btn.svg" alt="more button" />
                      More Options
                    </Button>
                  ) : (
                    ""
                  )}
                  {IsReadOnlyUser_store ? (
                    <Button className="read-only-user">
                      <i className="fa fa-book"></i>
                      Read only
                    </Button>
                  ) : null}
                  <MoreOptionMenu
                    isMenuOpen={isMenuOpen}
                    setIsMenuOpen={setIsMenuOpen}
                    TicketId={TicketId}
                    LeadId={LeadId}
                    setFormValues={setFormValues}
                    BlockAutoComm={BlockAutoComm}
                    StatusID={StatusID}
                    formValues={formValues}
                    masters={masters}
                    TicketDetailsID={TicketDetailsID}
                    RefObjectId={RefObjectId}
                    isBlockAutoCommVisible={isBlockAutoCommVisible}
                    EndorsementLink={EndorsementLink}
                    ParentTicketID={ParentTicketID}
                    AccessTypeVal={AccessTypeVal}
                    ProductID={ProductID}
                    isValidForSME={isValidForSME}
                  />
                </Grid>
              </Grid>
            </List>
          )}
        </div>
        <FullScreenDialog
          isCreateDialogOpen={isCreateTicketDialogOpen}
          setCreateDialog={setCreateTicketDialogOpen}
          sourceList={sourceList}
          TicketDetailsID={TicketDetailsID}
          RefObjectId={RefObjectId}
          OpenedThroughSource="TicketPage"
        />
        <Escalate
          isEscalateDialogOpen={isEscalateDialogOpen}
          setIsEscalateDialogOpen={setIsEscalateDialogOpen}
          TicketId={TicketId}
          TicketDetailsID={TicketDetailsID}
          RefObjectId={RefObjectId}
          LeadId={LeadId}
          ProductID={ProductID}
          StatusID={StatusID}
        />
        {isSMEDetailDialogOpen ? (
          <SmeDetailForm
            isDialogOpen={isSMEDetailDialogOpen}
            setDialogOpen={setIsSMEDetailDialogOpen}
          />
        ) : (
          ""
        )}
      </>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    FetchedTicketDetails:
      state && state.ticketdetails && state.ticketdetails.ticketDetails
        ? state.ticketdetails.ticketDetails
        : {},
    userInfo: (state && state.auth && state.auth.userInfo) || {},
    bucketDetails_store:
      state &&
      state.auth &&
      state.auth.userInfo &&
      state &&
      state.auth &&
      state.auth.userInfo.BucketDetails, // Bucket details for NextTicketPull
    IsReadOnlyUser_store:
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser,
  };
};

export default connect(mapStateToProps, null)(TicketDetails);
