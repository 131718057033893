import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  TableFooter,
  TablePagination,
  TableRow,
  Checkbox,
  IconButton,
  FormLabel,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { GET, POST } from "../../services/apiServices";
import {
  GetInsurerDataForSummaryTab,
  GetTicketListForInsurerSummary,
  UpdateInsurerTicketAssignment,
  GetAllUserForInsurerSummary,
  ExportTicketListForInsurerSummary,
} from "../../constants/apiUrls";
import Sidebar from "./Components/Sidebar";
import UserProfile from "./Components/UserProfile";
import Spinner from "../../shared/components/Spinner";
import { convertToIsoFormate, openNewView } from "../../shared/methods/methods";
import { useHistory } from "react-router-dom";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import { download } from "../../shared/methods/methods";
import { isEndorsementEligible } from "../../Config";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TicketSummary({}) {
  const classes = useStyles();
  const [isTicektSummary, setIsTicektSummary] = useState(true);
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //   const [isSimpleSearch, setIsSimpleSearch] = useState(true);
  const [isDataLoading, setDataLoading] = useState(false);
  const [insurerStatus, setInsurerStatus] = useState("");
  // const handleChange = (event) => {
  //   setInsurerStatus(event.target.value);
  // };
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("UserDetails"))
  );
  
  const [data, setdata] = useState(null);

  const [table, setTable] = useState(null);

  const [ticketList, setTicketList] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [userList, setUserList] = useState(null);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [checkedTicket, setCheckedTicket] = useState([]);
  const [assignTo, setAssignTo] = useState(null);
  const [assignToName, setAssignToName] = useState(null);
  const [showNoData, setShowNoData] = useState(false);
  const [typeRes, setTypeRes] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [rowIndex, setRowIndex] = useState(null);

  useEffect(() => {
    if (trigger) {
      setTrigger(false);
    }
  }, [trigger]);

  const handleClose = () => {
    setOpen(false);
  };

  const resetCheckList = () => {
    setCheckedTicket([]);
    setSelectedTickets([]);
  };

  const update = () => {
    if (assignTo) {
      const payload = {
        TicketIDs: selectedTickets,
        // UserID: 1234,
        AssignTo: assignTo,
      };
      POST(UpdateInsurerTicketAssignment, payload)
        .then((res) => {
          if (res.ErrorCode == 0) {
            setTicketList(null);
            getSummary();
            handleClose();
            enqueueSnackbar("Success", {
              variant: "success",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
        })
        .catch((e) => console.log(e));
    } else {
      enqueueSnackbar("Select an Agent", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    }
  };

  const getSummary = () => {
    setDataLoading(true);
    GET(`${GetInsurerDataForSummaryTab}`)
      .then((response) => {
        if (response.ErrorCode == 0) {
          setdata(response.Data);
        }
      })
      .catch((err) => console.log(err));
    setDataLoading(false);
  };

  useEffect(() => {
    getSummary();
  }, []);

  const getTicketList = (event, assigned, type, index) => {
    // setDataLoading(true);
    setTypeRes(type);
    if (event && event.persist) {
      event.persist();
    }
    resetCheckList();
    setRowIndex(index);
    GET(`${GetTicketListForInsurerSummary}/${type}/${assigned}`)
      .then((response) => {
        // setDataLoading(false);
        if (response.ErrorCode == 0) {
          response.Data.map((i) => {
            i.checked = false;
            return;
          });
          setTicketList(response.Data);
          setAssignToName(assigned);
        }
      })
      .catch((e) => console.log(e));
  };

  const updateTicket = () => {
    if (!checkedTicket.length) {
      enqueueSnackbar("Choose at least 1 ticket", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    } else {
      setAssignTo(null);
      setUserList(null);
      setOpen(true);
      getAgentList();
    }
  };

  const getAgentList = () => {
    let payload = [];
    checkedTicket.map((i) => {
      payload.push({ productID: i.ProductId, supplierID: i.SupplierId });
      return;
    }); //.filter((i, index, self) => self.indexOf(i) === index);
    if (!payload.length) {
      enqueueSnackbar("Success", {
        variant: "success",
        onClick: () => {
          closeSnackbar();
        },
      });
    } else {
      POST(`${GetAllUserForInsurerSummary}`, payload)
        .then((res) => {
          setUserList(res.Data);
        })
        .catch((e) => console.log(e));
    }
  };

  const handleCheck = (event, index) => {
    // setTrigger(false);
    if (event && event.persist) {
      event.persist();
    }
    const { value } = event.target;
    if (value == "all") {
      if (event.target.checked) {
        setSelectedTickets(ticketList.map((i) => ({ TicketId: i.TicketId })));
        setCheckedTicket(ticketList);
        let arr1 = ticketList;
        arr1.map((i) => {
          i.checked = true;
          return;
        });
        setTicketList(arr1);
      } else {
        setSelectedTickets([]);
        setCheckedTicket([]);
        let arr1 = ticketList;
        arr1.map((i) => {
          i.checked = false;
          return;
        });
        setTicketList(arr1);
      }
    } else {
      if (event.target.checked) {
        let arr3 = ticketList;
        // arr3[arr3.map((i) => i.TicketId).indexOf(value)].checked = true;
        arr3[index].checked = true;
        setTicketList(arr3);
        setTrigger(true);
        let arr1 = selectedTickets;
        arr1.push({ TicketId: value });
        setSelectedTickets(arr1);
        let arr2 = checkedTicket;
        arr2.push(ticketList[index]);
        setCheckedTicket(arr2);
      } else {
        let arr3 = ticketList;
        // arr3[arr3.map((i) => i.TicketId).indexOf(value)].checked = true;
        arr3[index].checked = false;
        setTicketList(arr3);
        setTrigger(true);
        let arr1 = selectedTickets.splice(
          selectedTickets.indexOf(event.target.value),
          1
        );
        setSelectedTickets(arr1);
        let arr2 = checkedTicket.filter((i) => i.TicketId != value);
        setCheckedTicket(arr2);
      }
    }
  };
 
  const selectAgent = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    setAssignTo(Number(event.target.value));
  };

  const ExportData = (event) => {
    if (event && event.persist) {
      event.persist();
    }

    GET(`${ExportTicketListForInsurerSummary}/${typeRes}/${assignToName}`)
      .then((response) => {
        if (response.ErrorCode == 0) {
          download("Ticket_Details.xlsx", response.Data);
          // console.log(response.Data);
        }
        // setTicketsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  
  return (
    <div className="insurer-dashbaord">
      <div className="dashboard">
        <ul>
          <li className="rightbar">
            <div className="insurer-admin-dash">
              {isDataLoading ? (
                <div className="spinner-loader">
                  <Spinner />
                </div>
              ) : (
                <Grid item md={12} xs={12}>
                  <div className="summary-list">
                    <div className="ticket-summary-list scrool-toll">
                      <ul>
                        <li className="name">
                          <span>Name</span>
                        </li>
                        <li>
                          <span>New</span>
                        </li>
                        <li>
                          <span>In Progress</span>
                        </li>
                        <li>
                          <span>Pushed to PB</span>
                        </li>
                        <li>
                          <span>Pendency Complete</span>
                        </li>
                        {/* <li>
                          <span>TAT Burst</span>
                        </li> */}
                        {/* <li>
                          <span>Severity</span>
                        </li> */}
                        <li>
                          <span>Worked on Today</span>
                        </li>
                        <li>
                          <span>Resolved Today</span>
                        </li>
                        <li>
                          <span>Resolved Till Date</span>
                        </li>
                      </ul>
                      {data?.map((row, index, self) => {
                        return (
                          <>
                            <ul>
                              <li className="name">
                                <strong>{row.AssignToName}</strong>
                              </li>
                              <li
                                onClick={(event) =>
                                  getTicketList(
                                    event,
                                    row.AssignUserID,
                                    1,
                                    index
                                  )
                                }
                              >
                                <strong>
                                  <Button>{row.New}</Button>
                                </strong>
                              </li>
                              <li
                                onClick={(event) =>
                                  getTicketList(
                                    event,
                                    row.AssignUserID,
                                    2,
                                    index
                                  )
                                }
                              >
                                <strong>
                                  <Button>{row.Pending}</Button>
                                </strong>
                              </li>
                              <li
                                onClick={(event) =>
                                  getTicketList(
                                    event,
                                    row.AssignUserID,
                                    3,
                                    index
                                  )
                                }
                              >
                                <strong>
                                  <Button>{row.PushedToPB}</Button>
                                </strong>
                              </li>
                              <li
                                onClick={(event) =>
                                  getTicketList(
                                    event,
                                    row.AssignUserID,
                                    4,
                                    index
                                  )
                                }
                              >
                                <strong>
                                  <Button>{row.PendancyComplete}</Button>
                                </strong>
                              </li>
                              {/* <li
                                onClick={(event) =>
                                  getTicketList(event, row.AssignUserID, 5)
                                }
                              >
                                <strong>
                                  <Button>{row.TAT}</Button>
                                </strong>
                              </li> */}
                              {/* <li
                                onClick={(event) =>
                                  getTicketList(
                                    event,
                                    row.AssignUserID,
                                    6,
                                    index
                                  )
                                }
                              >
                                <strong>
                                  <Button>{row.Severity}</Button>
                                </strong>
                              </li> */}
                              <li
                                onClick={(event) =>
                                  getTicketList(
                                    event,
                                    row.AssignUserID,
                                    7,
                                    index
                                  )
                                }
                              >
                                <strong>
                                  <Button>{row.WorkedOnToday}</Button>
                                </strong>
                              </li>
                              <li
                                onClick={(event) =>
                                  getTicketList(
                                    event,
                                    row.AssignUserID,
                                    8,
                                    index
                                  )
                                }
                              >
                                <strong>
                                  <Button>{row.ResolvedToday}</Button>
                                </strong>
                              </li>

                              <li
                                onClick={(event) =>
                                  getTicketList(
                                    event,
                                    row.AssignUserID,
                                    9,
                                    index
                                  )
                                }
                              >
                                <strong>
                                  <Button>{row.ResolvedTillDate}</Button>
                                </strong>
                              </li>
                            </ul>
                            {
                              // console.log(123, self, rowIndex, index) &&
                              // // row.AssignUserID == assignToName &&
                              // rowIndex == index
                              rowIndex == index && ticketList?.length && (
                                <div className="summary-list">
                                  <div className="export-data">
                                 <Tooltip title="Export" placement="top">
                                    <Button
                                      className="export-btn"
                                      onClick={(event) => ExportData(event)}
                                      color="primary"
                                    >
                                      <img src="images/export_icon.png" alt="export_icon.png"/>
                                    </Button>
                                    </Tooltip>
                                    <Tooltip title="assign" placement="top">
                                    <Button
                                      onClick={updateTicket}
                                      className="export-btn"
                                    >

                                      {/* <AssignmentTurnedInIcon /> */}
                                     <img src="images/assign.png" alt="assign"/>
                                    </Button>
                                  </Tooltip>
                                  </div>
                                  <div className="suammry-list-view scrool-toll">
                                    <ul>
                                      <li>
                                        <span>S.No</span>
                                      </li>
                                      <li className="checkbox-list">
                                        <Button>
                                          <FormControlLabel
                                            value="all"
                                            control={
                                              <Checkbox color="primary" />
                                            }
                                            labelPlacement="end"
                                            className="checkbox"
                                            onChange={(e) => handleCheck(e, "")}
                                          />
                                        </Button>
                                      </li>
                                      <li>
                                        <span>Ticket ID</span>
                                      </li>               
                                      <li>
                                        <span>Ticket Date</span>
                                      </li>
                                      <li>
                                        <span>Assigned Date</span>
                                      </li>
                                      <li>
                                        <span>Lead ID</span>
                                      </li>
                                      <li>
                                        <span>Application No</span>
                                      </li>
                                      <li>
                                        <span>Policy No</span>
                                      </li>
                                      <li>
                                        <span>Issue</span>
                                      </li>
                                      <li>
                                        <span>Sub Issue</span>
                                      </li>
                                      <li>
                                        <span>Ticket Status</span>
                                      </li>
                                      <li>
                                        <span>Insurer Status</span>
                                      </li>
                                      <li>
                                        <span>Insurer Response Date</span>
                                      </li>
                                      <li>
                                        <span>PB Response Date</span>
                                      </li>
                                      <li>
                                        <span>Email</span>
                                      </li>
                                      <li>
                                        <span>Ageing</span>
                                      </li>
                                      <li>
                                        <span>Registration No</span>
                                      </li>
                                      {/* <li>
                                        <span>TAT</span>
                                      </li> */}
                                    </ul>
                                    <div className="table-body">
                                      {ticketList?.length &&
                                        ticketList.map((i, index) => {
                                          // console.log(456, i);
                                          return (
                                            <ul>
                                              <li>
                                                <strong>{index + 1}</strong>
                                              </li>
                                              <li className="checkbox-list">
                                                <Button>
                                                  <FormControlLabel
                                                    value={i.TicketId}
                                                    checked={i.checked}
                                                    control={
                                                      <Checkbox color="primary" />
                                                    }
                                                    labelPlacement="end"
                                                    className="checkbox"
                                                    onChange={(e) =>
                                                      handleCheck(e, index)
                                                    }
                                                  />
                                                </Button>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    if (
                                                      isEndorsementEligible(
                                                        i.ProductId,
                                                        i.SupplierId,
                                                        i.InvestmentTypeID
                                                      ) && openNewView(i.ProductId)
                                                    ) {
                                                      history.push(
                                                        `/insurerticket/${i.RefObjectId}/${i.TicketDetailsID}`
                                                      );
                                                    } else {
                                                      history.push(
                                                        `/insurerticket/${i.RefObjectId}/${i.TicketDetailsID}`
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <strong>
                                                    {i.TicketDetailsID}
                                                  </strong>
                                                </a>
                                              </li>
                                               <li>
                                                <strong>
                                                  {convertToIsoFormate(
                                                    i.CreatedOn
                                                  )}
                                                </strong>
                                              </li>
                                              
                                              <li>
                                                <strong>
                                                  {convertToIsoFormate(
                                                    i.InsurerAssignDate
                                                  )}
                                                </strong>
                                              </li>
                                              <li>
                                                <strong>{i.LeadID}</strong>
                                              </li>
                                              {/* <li>
                                              <strong>
                                                {i.IsCallBackRequest == "1"
                                                  ? "Yes"
                                                  : "No"}
                                              </strong>
                                            </li> */}
                                              <li>
                                                <strong>
                                                  {i.ApplicationNo}
                                                </strong>
                                              </li>
                                              <li>
                                                <strong>{i.PolicyNo}</strong>
                                              </li>

                                              {/* <li>
                                              <strong>{i.Source}</strong>
                                            </li> */}
                                              <li>
                                                <strong>{i.IssueName}</strong>
                                              </li>
                                              <li>
                                                <strong>
                                                  {i.SubIssueName}
                                                </strong>
                                              </li>
                                              <li>
                                                <strong>
                                                  {i.TicketStatusName}
                                                </strong>
                                              </li>
                                              <li>
                                                <strong>{i.Status}</strong>
                                              </li>
                                              <li>
                                                <strong>
                                                  {convertToIsoFormate(
                                                    i.InsurerStatusDate
                                                  )}
                                                </strong>
                                              </li>
                                              <li>
                                                <strong>
                                                  {i.LastUpdatedOn}
                                                </strong>
                                              </li>
                                              {/* <li>
                                              <strong>{i.ProductName}</strong>
                                            </li>
                                            <li>
                                              <strong>
                                                {i.InsurerAgentName}
                                              </strong>
                                            </li>
                                            
                                            <li>
                                              <strong>
                                                {i.InsurerStatusName}
                                              </strong>
                                            </li>
                                            <li>
                                              <strong>
                                                {i.AssignedToName}
                                              </strong>
                                            </li>
                                            <li>
                                              <strong></strong>
                                            </li>
                                            <li>
                                              <strong></strong>
                                            </li>
                                            <li>
                                              <strong></strong>
                                            </li> */}
                                              <li>
                                                <strong>{i.EmailId}</strong>
                                              </li>
                                              <li>
                                                <strong>{i.AGEING}</strong>
                                              </li>

                                              <li>
                                                <strong>
                                                  {i.RegistrationNo}
                                                </strong>
                                              </li>

                                              {/* <li>
                                                <strong>
                                                  {i.TATBurstDate}
                                                </strong>
                                              </li> */}
                                            </ul>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              )
                              // : null
                            }
                          </>
                        );
                      })}
                    </div>
                  </div>
                </Grid>
              )}
            </div>
          </li>
        </ul>
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          className="assign-ticket-dialog"
        >
          <h5>
            Assign Ticket
            <Button className="close-icon" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </h5>
          <ul>
            {userList?.map((i, index) => {
              return (
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      onChange={selectAgent}
                      value={assignTo}
                    >
                      <FormControlLabel
                        value={i.UserID}
                        control={<Radio color="primary" />}
                        label={i.Name}
                      />
                    </RadioGroup>
                  </FormControl>
                </li>
              );
            })}
            <div>
              <Button className="submit-btn" color="primary" onClick={update}>
                Submit
              </Button>
            </div>
          </ul>
        </Dialog>
      </div>
    </div>
  );
}
