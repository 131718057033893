import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  CRITICAL_ILLNESS,
  HEALTH,
  OPEN_ENDORSEMENT_IN_CUSTOMER_PENDENCY_SUBSTATUS,
  POSPCAR,
  POSPCOMMERCIAL,
  POSPHEALTH,
  POSPTW,
  STU,
  endorsementIssue,
  isEndorsementEligible,
} from "../../../Config";
import { handleCancellationSelection } from "../../../Config";
import { checkProductIssueSubIssue, customInputBoxErrorMessage } from "../../../shared/methods/methods";
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
  },
  title: {
    flex: 1,
    fontSize: "16px",
  },
  formControl: {
    minWidth: "100%",
  },
  h6: {
    color: "#414141",
    fontSize: "14px",
  },
}));

const SelectInput = ({
  inputValue,
  labelVal,
  inputFieldName,
  options,
  handleFormValues = () => {},
  isTicketDetailEditable,
  setFormValues,
  inputFieldId = "",
  inputValueId,
  isError = "",
  setErrors = () => {},
  setSelectFormOptions,
  endorsementDetails,
  raiseEndorsementURL,
  GetTicketDetailsByTicketIdApi,
  RefObjectId,
  TicketDetailsID,
  oldTicketDetails,
  setEndorsementTabClosed,
  popup,
  formValues,
  handleClickOpen,
  viewButton
}) => {
  const [field, setField] = useState(inputFieldId);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (popup) {
      // raiseEndorsementFunc();
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [popup]);

  useEffect(() => {
    if (inputFieldId == "IssueTypeId") {
      let issues = options;
      if (issues.map((i) => i.IssueTypeId).indexOf(inputValueId) == -1) {
        issues.push({
          IssueTypeId: inputValueId,
          IssueName: inputValue,
        });
        setSelectFormOptions((options) => ({
          ...options,
          issueOptions: issues,
        }));
        // setFormValues((prevValue) => {
        //   return { ...prevValue, [inputFieldId]: inputValueId, [inputFieldName]: inputValue };
        // });
      }
    }
    // if(inputFieldId == "SubIssueID"){
    //   let subIssues = options;
    //   if (
    //     subIssues.map((i) => i.SubIssueID).indexOf(inputValueId) ==
    //     -1
    //   ) {
    //     subIssues.push({
    //       SubIssueID: inputValueId,
    //       SubIssueName: inputValue,
    //     });
    //     setSelectFormOptions((options) => ({
    //       ...options,
    //       subIssueOptions: subIssues
    //     }))
    //   }
    // }
  }, [isTicketDetailEditable]);

  useEffect(() => {
    if (isError) {
      setField(inputFieldId);
    }
  }, [isError]);

  const handleOptionClick = (id, val, IssueCode) => {
    let a = true;
    let b = oldTicketDetails.IssueName;
    let c = oldTicketDetails.IssueID;
    const checkIfCancellationIssue = handleCancellationSelection(b);
    if (inputFieldId === "IssueTypeId" && id) {
      console.log("id value", id, val, formValues, IssueCode);
      if (
        // isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails?.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
        // val.toLowerCase() == "changes required in policy details" &&
        // (oldTicketDetails.EndorsementID === "0" ||
        //   Number(oldTicketDetails.EndorsementID) === 0) &&
        // !oldTicketDetails.ParentTicketID &&
        // formValues.SubStatusName !== "Customer Pendency"
        isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
        (
          // ((val.toLowerCase() ===
          //   "changes required in policy details" || //Endorsement Dialog is shown if  condition matches IssueName
          //   (val.toLowerCase() === "renewal related query" && //Endorsement Dialog is shown if either condition matches IssueName and subIssue name, in condition
          //     formValues.SubIssueName.toLowerCase() ===
          //     "make changes to policy before renewal")) &&
          //   formValues.SubStatusName.toLowerCase() !== "customer pendency" &&
          //   !oldTicketDetails.ParentTicketID &&
          //   (oldTicketDetails.EndorsementID === "0" ||
          //     Number(oldTicketDetails.EndorsementID) === 0)
          // ) ||
          (
            checkProductIssueSubIssue(formValues?.ProductId, val, formValues?.SubIssueName) &&
            (formValues.SubStatusName.toLowerCase() === "customer pendency" && 
            !OPEN_ENDORSEMENT_IN_CUSTOMER_PENDENCY_SUBSTATUS.includes(formValues?.ProductId) ? false : true) &&
            !oldTicketDetails.ParentTicketID &&
            (oldTicketDetails.EndorsementID === "0" ||
              Number(oldTicketDetails.EndorsementID) === 0) && oldTicketDetails?.LeadID
          )
        )
      ) {
        setOpen(true);
        setEndorsementTabClosed(false);
      } else setOpen(false);
      // if (val.toLowerCase() == "help in cancellation of the policy") {
      //   handleClickOpen();
      // }
      if (IssueCode && IssueCode === "HELPINCANCELLATIONOFTHEPOLICY") {
        handleClickOpen();
      }


      setErrors((prev) => ({ ...prev, issue: false }));
    }

    //All conditions related to SubIssue Value changes are written here
    if (inputFieldId === "SubIssueID" && id) {
      if (
        // isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails?.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
        // formValues.IssueName.toLowerCase() === "renewal related query" && //Endorsement Dialog is shown if either condition matches IssueName and subIssue name, in condition
        // val.toLowerCase() === "make changes to policy before renewal" &&
        // !oldTicketDetails.ParentTicketID &&
        // (oldTicketDetails.EndorsementID === "0" ||
        //   Number(oldTicketDetails.EndorsementID) === 0)
        isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
        (
          // ((formValues.IssueName.toLowerCase() ===
          //   "changes required in policy details" || //Endorsement Dialog is shown if  condition matches IssueName
          //   (formValues.IssueName.toLowerCase() === "renewal related query" && //Endorsement Dialog is shown if either condition matches IssueName and subIssue name, in condition
          //     val?.toLowerCase() ===
          //     "make changes to policy before renewal")) &&
          //   formValues.SubStatusName.toLowerCase() !== "customer pendency" &&
          //   !oldTicketDetails.ParentTicketID &&
          //   (oldTicketDetails.EndorsementID === "0" ||
          //     Number(oldTicketDetails.EndorsementID) === 0)
          // ) ||
          (
            checkProductIssueSubIssue(formValues?.ProductId, formValues?.IssueName, val) &&
            (formValues.SubStatusName.toLowerCase() === "customer pendency" && 
            !OPEN_ENDORSEMENT_IN_CUSTOMER_PENDENCY_SUBSTATUS.includes(formValues?.ProductId) ? false : true) &&
            !oldTicketDetails.ParentTicketID &&
            (oldTicketDetails.EndorsementID === "0" ||
              Number(oldTicketDetails.EndorsementID) === 0) && oldTicketDetails?.LeadID
          )
        )
      ) {
        setOpen(true);
        setEndorsementTabClosed(false);
      }
      
      setErrors((prev) => ({ ...prev, subIssue: false }));
    }
    if (inputFieldId === "StatusID" && id) {
      setFormValues((prevValue) => {
        return {
          ...prevValue,
          [inputFieldId]: id,
          [inputFieldName]: val,
          CustomerPendencyDetails : "",
          SubStatusID: null,
          SubStatusName: "",
        };
      });
    }
  
    // ===============================
    //   else {
    //     setFormValues((prevValue) => {
    //       return {
    //         ...prevValue,
    //         [inputFieldId]: id,
    //         [inputFieldName]: val,
    //         SubStatusID: null,
    //         SubStatusName: "",
    //       };
    //     });
    //   }
    // }
    else if (inputFieldId == "IssueTypeId" && id) {
      setFormValues((prevValue) => {
        return {
          ...prevValue,
          [inputFieldId]: id,
          [inputFieldName]: val,
          SubIssueID: null,
          SubIssueName: "",
          IssueCode: IssueCode,
        };
      });
    } else if (inputFieldId == "ProductId" && formValues.LeadID == 0) {
      setFormValues((prevValue) => {
        return {
          ...prevValue,
          [inputFieldId]: id,
          [inputFieldName]: val,
          SupplierId: 0,
          SupplierName: "",
        };
      });
    } else {
      if (a) {
        setFormValues((prevValue) => {
          return { ...prevValue, [inputFieldId]: id, [inputFieldName]: val, CustomerPendencyDetails : inputFieldId === 'SubStatusID' ? "" : prevValue["CustomerPendencyDetails"] };
        });
      } else {
        setFormValues((prevValue) => {
          return { ...prevValue, IssueTypeId: c, IssueName: b };
        });
      }
    }
  };

  const getValueFromId = () => {
    if (inputFieldName == "RootCause") {
      // console.log('rootcause here', formValues && formValues.RootCause)
      return formValues.RootCause;
    } else {
      if (
        inputFieldId &&
        options &&
        Array.isArray(options) &&
        options.length > 0
      ) {
        const obj = options.find((item) => item[inputFieldId] === inputValueId);
        if (obj && obj[inputFieldName]) {
          return obj[inputFieldName];
        }
      }
    }
    return inputValue;
  };


  return (
    <>
      <ListItem>
        {isTicketDetailEditable ? (
          <FormControl variant="outlined" className="select-box">
            <InputLabel id="demo-simple-select-outlined-label">
              {labelVal}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id= {`ticketDetail${labelVal.replaceAll(" ","")}`}
              value={inputValueId}
              label={labelVal}
              multiple={inputFieldName == "RootCause" && formValues && formValues.ProductId !== 131 ? true : false }
              onChange={
                inputFieldId
                  ? () => {}
                  : (e) => handleFormValues(e, inputFieldName)
              }
              disabled={
                (inputFieldId === "SubIssueID" &&
                (formValues.IssueTypeId == "" ||
                  formValues.IssueTypeId == undefined)
                  ? true
                  : false) ||
                ((inputFieldId === "SubIssueID" ||
                  inputFieldId === "IssueTypeId") &&
                  formValues.RefundId > 0) ||
                ((inputFieldId === "SubIssueID" ||
                  inputFieldId === "IssueTypeId") &&
                  formValues.DispatcherId > 0) ||
                ((inputFieldId === "SubIssueID" ||
                  inputFieldId === "IssueTypeId") &&
                  isEndorsementEligible(oldTicketDetails.ProductId, oldTicketDetails.SupplierId, oldTicketDetails?.InvestmentTypeID) &&
                  (
                    (
                      checkProductIssueSubIssue(formValues?.ProductId, formValues?.IssueName, formValues?.SubIssueName) &&
                      ([POSPCAR, POSPTW, POSPCOMMERCIAL, HEALTH, STU, CRITICAL_ILLNESS, POSPHEALTH].includes(formValues?.ProductId) ? true : 
                      (formValues.SubStatusName.toLowerCase() === "customer pendency" && 
                      !OPEN_ENDORSEMENT_IN_CUSTOMER_PENDENCY_SUBSTATUS.includes(formValues?.ProductId) ? false : true))
                      &&
                      !oldTicketDetails.ParentTicketID &&
                      formValues.EndorsementID != 0
                  ))) || 
                  ((inputFieldId === "SubIssueID" ||
                   inputFieldId === "IssueTypeId") && oldTicketDetails.AHCId
                  )
                  ? true
                  : false && !oldTicketDetails.ParentTicketID
              }
            >
              {(inputFieldId === "ProductId" ||
                inputFieldId === "SupplierId" ||
                inputFieldId === "ClassificationID") && (
                <MenuItem
                  value={0}
                  onClick={() => {
                    handleOptionClick(0, "");
                  }}
                >
                  Select
                </MenuItem>
              )}
              {options.map((opt, i) =>
                typeof opt === "object" ? (
                  <MenuItem
                    disabled={
                      opt.ResolvedStatusId === 3 &&
                      inputFieldId === "SubStatusID" &&
                      opt.EnabledSubStatus == "NO"
                    }
                    key={`${inputValueId}${i}`}
                    value={opt[inputFieldId]}
                    onClick={() => {
                      handleOptionClick(opt[inputFieldId], opt[inputFieldName], opt['IssueCode']);
                    }}
                  >
                    {opt[inputFieldName]}
                  </MenuItem>
                ) : (
                  <MenuItem key={`${inputValueId}$i}`} value={opt}>
                    {opt}
                  </MenuItem>
                )
              )}
            </Select>
            <span className="visibility-icon">
              {viewButton && viewButton.show && <IconButton aria-label="visibility" size="small">
                <VisibilityIcon onClick={viewButton.onClick} />
              </IconButton>
              }
            </span>
            {(inputFieldId === "IssueTypeId" ||
              inputFieldId === "SubIssueID" ||
              inputFieldId === "ProductId" ||
              inputFieldId === "SupplierId") &&
            isError && isTicketDetailEditable ? (
                <FormHelperText className="error-msg">
                  {/* Custom message for Endorsement  */}
                    {customInputBoxErrorMessage(inputFieldId, formValues, oldTicketDetails) || "Required"}
                  </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        ) : (
          <>
            <div className="classification-box">
              <label>{labelVal}</label>
              <p>{getValueFromId()}</p>
            </div>
            {field &&
            (inputFieldId === "IssueTypeId" ||
              inputFieldId === "SubIssueID" ||
              inputFieldId === "SupplierId" ||
              inputFieldId === "ProductId") &&
            isError && isTicketDetailEditable ? (
              <FormHelperText className="error-msg">Required</FormHelperText>
            ) : (
              ""
            )}
          </>
        )}
      </ListItem>
    </>
  );
};

export default SelectInput;
