import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  TextField,
  Grid,
  FormControl,
  TextareaAutosize,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  Link,
} from "@material-ui/core";

import DialogTitle from "../../../../shared/components/DialogTitle";
import {
  GetDocURL,
  GetTicketTicketEscLog,
} from "../../../../constants/apiUrls";
import { GET, POST } from "../../../../services/apiServices";
import { convertToIsoFormate, toBase64 } from "../../../../shared/methods/methods";
import Spinner from "../../../../shared/components/Spinner";
import { useSnackbar } from "notistack";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { connect } from "react-redux";
import {getDocUrl } from "../../../../shared/methods/apiMethods";

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  inputHidden: {
    display: "none",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  selectIcon: {
    top: "8px !important",
  },
  uploadIcon: {
    padding: "8px",
  },
  fileNameText: {
    fontSize: "12px",
    paddingLeft: "25px",
    "& a": {
      color: "#0052cc",
    },
  },
  errorMsg:{
    display:"block",
    color:"#f44336",
    padding:"0 10px",
    fontSize:"0.75rem",
  },
  edDate:{
    "& button":{
     padding: '0 !important',
     minWidth: '0 !important',
     marginRight: '0px !important',
    },
  },
}));

function EndorsementFormDialog({
  isEndoresmentDialogOpen,
  setEndoresmentDialogOpen,
  OpenedTicketDetails
}) {
  const [EsclationLog, setEsclationLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const [fileData, setfileData] = useState();
  const [formState, setFormState] = useState({
    isValid: false,

    values: {},
    touched: {
      'ActiveLive': false,
      'TotalAddition': false,
      'TotalDeletion': false,
      'TotalModification': false,
      'EndorsementTicketId': false,
      'EndorsementPremium': false,
      'ActiveLiveFile': false,
      'EndorsementFile': false,
      'ClientDataFile': false,
      'ECardFile': false
    },
    errors: {},
    clicked: false,
  });

  const [FetchedEndorsementDetails, setFetchedEndorsementDetails] = useState()

  const handleFormChanges = event => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === "radio" ? event.target.value === 'true' : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleDateChange =(val)=>{
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        EndorsementRequestDate : val
      },
      touched: {
        ...formState.touched,
        EndorsementRequestDate : true
      }
    }));
    console.log("EndorsementRequestDate",val,formState.values.EndorsementRequestDate);
  }
  
  useEffect(() => {
    if(OpenedTicketDetails && OpenedTicketDetails.TicketDetailsID ){
      setFormState((preState) => ({
        ...preState,
        values: {
          ...preState.values,
          EndorsementRequestDate: new Date(),
          EndorsmentTicketId: OpenedTicketDetails.TicketDetailsID ,
          ActiveLive : null,
          IsActive: true,
        }
      }))
    }
  }, []);

  

  const handlefileUpload = async (file, fieldName) => {
    try {
        const fileData = await toBase64(file);
      if (fileData) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [fieldName]: {
              FileData: fileData.split(",")[1],
              FileName: file.name,
            },
          },
          touched: {
            ...formState.touched,
            [fieldName]: true,
          },
        }));
      }
    } catch (error) {
      console.log("Error :", error);
    }
  };

  useEffect(()=>{
    if(OpenedTicketDetails && OpenedTicketDetails.LeadID && OpenedTicketDetails.TicketDetailsID){
      let { LeadID, TicketDetailsID } = OpenedTicketDetails
      let payload={
          "LeadId":  LeadID, 
          "EndorsmentTicketId": TicketDetailsID
      }
      POST('/GetEndorsementDetailsSME', payload).then(res=>{
        if(res && res.ErrorCode==0){
          setFetchedEndorsementDetails(res.Data)
        }
        else{
          console.log('no data found')
        }
      }).catch(err=>{
        console.log('Catch error in FetchEndorsementTicketDetails')
      })
    }
  },[])

  useEffect(()=>{
    if(FetchedEndorsementDetails && Object.keys(FetchedEndorsementDetails).length>0){
      let { ActiveLive,
         ActiveLiveFile,
        CDStatementBalance,
        CDStatementFile,
        ClientDataFile,
        CustomerId,
        ECardFile,
        EndorsementFile,
        EndorsementId,
        EndorsementPremium,
        EndorsementRequestDate,
        EndorsmentTicketId,
        TotalAddition,
        TotalDeletion,
        TotalModification
      } = FetchedEndorsementDetails
      setFormState((preState) => ({
        ...preState,
        values: {
          ...preState.values,
          ActiveLive : ActiveLive,
          ActiveLiveFile : ActiveLiveFile,
          CDStatementBalance :  CDStatementBalance,
          CDStatementFile : CDStatementFile,
          ClientDataFile : ClientDataFile,
          CustomerId : CustomerId,
          ECardFile : ECardFile,
          EndorsementFile : EndorsementFile,
          EndorsementId : EndorsementId,
          EndorsementPremium : EndorsementPremium,
          EndorsementRequestDate : EndorsementRequestDate,
          EndorsmentTicketId : EndorsmentTicketId,
          TotalAddition : TotalAddition,
          TotalDeletion : TotalDeletion,
          TotalModification : TotalModification
        }
      }))
    }
  },[FetchedEndorsementDetails])

  const setTouched=()=>{
    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        'ActiveLive': true,
        'TotalAddition' : true,
        'TotalDeletion' : true,
        'TotalModification' : true,
        'EndorsementTicketId' : true,
        'EndorsementPremium' : true,
        'ActiveLiveFile' : true,
        'EndorsementFile' : true,
        'ClientDataFile' : true,
        'ECardFile' : true
      }
    }))
  }

  const handleSaveEndorsementChanges =() =>{
    setTouched()
    // if( !formState.values.ActiveLiveFile || !formState.values.ClientDataFile || !formState.values.EndorsementFile || !formState.values.EndorsementPremium || !formState.values.TotalAddition || !formState.values.TotalDeletion || !formState.values.TotalModification || !formState.values.EndorsementRequestDate
    // )
    // {
    //   return; // If any mentioned fields are empty then nothing happens
    // }
    if(isLoading){
      return // if loading button is already pressed then return 
    }
    let { LeadID, TicketDetailsID, CustomerId} = OpenedTicketDetails
    let payload = {
      "LeadId": LeadID,
      "EndorsmentTicketId": TicketDetailsID,
      "EndorsementId": FetchedEndorsementDetails && FetchedEndorsementDetails.EndorsementId,
      "EndorsementRequestDate": formState.values.EndorsementRequestDate || new Date(),
      "TotalAddition": formState.values.TotalAddition,
      "TotalModification": formState.values.TotalModification,
      "TotalDeletion": formState.values.TotalDeletion,
      "ActiveLive": formState.values.ActiveLive,
      "ActiveLiveFile": formState.values.ActiveLiveFile,
      "EndorsementFile": formState.values.EndorsementFile,
      "CDStatementBalance": formState.values.CDStatementBalance,
      "CDStatementFile": formState.values.CDStatementFile || {},
      "ClientDataFile": formState.values.ClientDataFile,
      "ECardFile": formState.values.ECardFile,
      "EndorsementPremium": formState.values.EndorsementPremium,
      "ProductId": 131,
      "CustomerId": CustomerId,
      "IsActive": true,
      "IsDay1Endorsement": false


    }
    setIsLoading(true) // setting loader value true
    POST(`/UpdateEndorsementSME`,payload).then(res=>{
      if(res && res.ErrorCode==0){
        enqueueSnackbar("Saved successfully", {
          variant: "success",
          onClick: () => {
            closeSnackbar();
          },
        });
        setIsLoading(false) // setting loader value false
        setEndoresmentDialogOpen(false)
       }
       else{
        setIsLoading(false) // setting loader value false
        enqueueSnackbar("Oops! Something's not right", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      }
    }).catch(err=>{
      setIsLoading(false) // setting loader value false
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      console.log(err)
    })
    
  }

  const showMessage =( message, varient) =>{
    return enqueueSnackbar(message, {
      variant: varient,
      onClick: () => {
        closeSnackbar();
      },
    });
  }


  return (
    <div>
      {console.log('formstate', formState, typeof formState.values.EndorsementRequestDate)}
      <Dialog
        maxWidth="md"
        className="endorsement-popup scroll-btn"
        onClose={() => isLoading ? null : setEndoresmentDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isEndoresmentDialogOpen}
      >
        <DialogTitle
          className="title-heading"
          id="customized-dialog-title"
          onClose={() => isLoading ? null : setEndoresmentDialogOpen(false)}
        >
          Endorsement Details
        </DialogTitle>
        <div className="endorsement-popup-inner scroll-btn">
          <DialogContent>
            <Grid container spacing={0}>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth className="form-control">
                <MuiPickersUtilsProvider utils={DateFnsUtils} className="form-control">
            <KeyboardDatePicker
              placeholder="DD-MM-YYYY"
              label="Endorsement Date (DD-MM-YYYY)"
              name="EndorsementRequestDate"
              format="dd-MM-yyyy"
              value={(formState
                && formState.values.EndorsementRequestDate
                && (new Date(formState.values.EndorsementRequestDate) != 'Invalid Date'
                  && formState.values.EndorsementRequestDate != "0001-01-01T00:00:00"
                  ? new Date(formState.values.EndorsementRequestDate) : null
                )) || new Date()}
              onChange={handleDateChange}
              className={classes.edDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              fullWidth
              inputVariant="outlined"
              InputAdornmentProps={{ position: "end" }}
            />
          </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="outlined-full-width"
                    label="Active Lives"
                    placeholder="Active Lives"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // error={formState.values && formState.touched.ActiveLive && formState.values.ActiveLive<0}
                    // helperText={formState.values && formState.values.ActiveLive<0 && formState.touched.ActiveLive ? "*Mandatory" : ""}
                    type="number"
                    value={formState.values && formState.values.ActiveLive}
                    name="ActiveLive"
                    onChange={handleFormChanges}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="outlined-full-width"
                    label="Total Addition"
                    placeholder="Total Addition"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    //error={formState.values && formState.touched.TotalAddition && !formState.values.TotalAddition}
                    //helperText={formState.values && formState.touched.TotalAddition && !formState.values.TotalAddition ? "*Mandatory" : ""}
                    type="number"
                    value={formState.values && formState.values.TotalAddition}
                    name="TotalAddition"
                    onChange={handleFormChanges}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="outlined-full-width"
                    label="Total Deletion"
                    placeholder="Total Deletion"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="number"
                    value={formState.values && formState.values.TotalDeletion}
                    name="TotalDeletion"
                    // error={formState.values && !formState.values.TotalDeletion &&  formState.touched.TotalDeletion }
                    // helperText={formState.values && !formState.values.TotalDeletion && formState.touched.TotalDeletion  ? "*Mandatory" : ""}
                    onChange={handleFormChanges}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="outlined-full-width"
                    label="Total Modification"
                    placeholder="Total Modification"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="number"
                    value={formState.values && formState.values.TotalModification}
                    // error={formState.values && !formState.values.TotalModification &&  formState.touched.TotalModification }
                    // helperText={formState.values && !formState.values.TotalModification && formState.touched.TotalModification  ? "*Mandatory" : ""}
                    name="TotalModification"
                    onChange={handleFormChanges}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="outlined-full-width"
                    label="CD Statement Balance"
                    placeholder="CD Statement Balance"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formState.values && formState.values.CDStatementBalance}
                    type="number"
                    name="CDStatementBalance"
                    onChange={handleFormChanges}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="outlined-full-width"
                    label="Endorsement Ticket Number"
                    placeholder="Endorsement Ticket Number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    value={formState.values && formState.values.EndorsmentTicketId}
                    name="EndorsementTicketId"
                    onChange={handleFormChanges}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="outlined-full-width"
                    label="Endorsement Premium"
                    placeholder="Endorsement Premium"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="number"
                    name="EndorsementPremium"
                    value={formState.values && formState.values.EndorsementPremium}
                    // error={formState.values && !formState.values.EndorsementPremium &&  formState.touched.EndorsementPremium }
                    // helperText={formState.values && !formState.values.EndorsementPremium && formState.touched.EndorsementPremium  ? "*Mandatory" : ""}
                    onChange={handleFormChanges}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl>
                  <input
                    accept="application/pdf,application/vnd.ms-excel "
                    className={classes.inputHidden}
                    id="ActiveLiveFile"
                    name="ActiveLiveFile"
                    onChange={({ target }) =>
                      handlefileUpload(target.files[0], "ActiveLiveFile")
                    }
                    type="file"
                  />
                  <label htmlFor="ActiveLiveFile">
                    <IconButton
                      color="primary"
                      component="span"
                      className={classes.uploadIcon}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                    Upload Final Active Live
                    <span className={classes.fileNameText}>
                      {formState && formState.values && formState.values.ActiveLiveFile &&
                        formState.values.ActiveLiveFile.FileName && !formState.values.ActiveLiveFile.FileLink ?
                        formState.values.ActiveLiveFile.FileName :
                        (FetchedEndorsementDetails && FetchedEndorsementDetails.ActiveLiveFile && FetchedEndorsementDetails.ActiveLiveFile.DocId ?
                          <button className="link-btn"
                          onClick={()=>getDocUrl(FetchedEndorsementDetails.ActiveLiveFile.DocId )}
                            // href={FetchedEndorsementDetails.ActiveLiveFile.FileLink}
                            // download={true}
                            // target={"_blank"}
                          >
                            {FetchedEndorsementDetails.ActiveLiveFile.FileName || 'Link'} 
                          </button>
                          : null)}
                    </span>
                  </label>
                  {/* {formState && formState.values && !formState.values.ActiveLiveFile && formState.touched && formState.touched.ActiveLiveFile ? 
                  <span className={classes.errorMsg}>*Mandatory</span> :
                  FetchedEndorsementDetails && !FetchedEndorsementDetails.ActiveLiveFile ? <span className={classes.errorMsg}>*Mandatory</span>  : 
                  ""
                  } */}
                  {/* {(formState && formState.values && formState.values.ActiveLiveFile &&
                    formState.values.ActiveLiveFile.FileName) || (FetchedEndorsementDetails && FetchedEndorsementDetails.ActiveLiveFile && FetchedEndorsementDetails.ActiveLiveFile.FileName) ? null
                    : <span className={classes.errorMsg}>*Mandatory</span>
                  } */}
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl>
                  <input
                    accept="application/pdf,application/vnd.ms-excel "
                    className={classes.inputHidden}
                    id="EndorsementFile"
                    name="EndorsementFile"
                    onChange={({ target }) =>
                      handlefileUpload(target.files[0], "EndorsementFile")
                    }
                    type="file"
                  />
                  <label htmlFor="EndorsementFile">
                    <IconButton
                      color="primary"
                      component="span"
                      className={classes.uploadIcon}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                    Endorsement Policy Copy Upload
                    <span className={classes.fileNameText}>
                    {formState && formState.values && formState.values.EndorsementFile &&
                        formState.values.EndorsementFile.FileName && !formState.values.EndorsementFile.FileLink ?
                        formState.values.EndorsementFile.FileName :
                        (FetchedEndorsementDetails && FetchedEndorsementDetails.EndorsementFile && FetchedEndorsementDetails.EndorsementFile.DocId ?
                          <button className="link-btn"
                           onClick={()=>getDocUrl(FetchedEndorsementDetails.EndorsementFile.DocId )}
                            // href={FetchedEndorsementDetails.EndorsementFile.FileLink}
                            // download={true}
                            // target={"_blank"}
                          >
                            {FetchedEndorsementDetails.EndorsementFile.FileName || "Link"}
                          </button>
                          : null)}
                    </span>
                  </label>
                  {/* {formState && formState.values && !formState.values.EndorsementFile && formState.touched && formState.touched.EndorsementFile ? 
                  <span className={classes.errorMsg}>*Mandatory</span> :
                  FetchedEndorsementDetails && !FetchedEndorsementDetails.EndorsementFile ? <span className={classes.errorMsg}>*Mandatory</span>  : 
                  ""
                  } */}
                  {/* {(formState && formState.values && formState.values.EndorsementFile &&
                    formState.values.EndorsementFile.FileName) || (FetchedEndorsementDetails && FetchedEndorsementDetails.EndorsementFile && FetchedEndorsementDetails.EndorsementFile.FileName) ? null
                    : <span className={classes.errorMsg}>*Mandatory</span>
                  } */}
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl>
                  <input
                    accept="application/pdf,application/vnd.ms-excel "
                    className={classes.inputHidden}
                    id="CDStatementFile"
                    name="CDStatementFile"
                    onChange={({ target }) =>
                      handlefileUpload(target.files[0], "CDStatementFile")
                    }
                    type="file"
                  />
                  <label htmlFor="CDStatementFile">
                    <IconButton
                      color="primary"
                      component="span"
                      className={classes.uploadIcon}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                    CD Statement Copy Upload
                    <span className={classes.fileNameText}>
                    {formState && formState.values && formState.values.CDStatementFile &&
                        formState.values.CDStatementFile.FileName && !formState.values.CDStatementFile.FileLink ?
                        formState.values.CDStatementFile.FileName :
                        (FetchedEndorsementDetails && FetchedEndorsementDetails.CDStatementFile && FetchedEndorsementDetails.CDStatementFile.DocId ?
                          <button className="link-btn"
                          onClick={()=>getDocUrl(FetchedEndorsementDetails.CDStatementFile.DocId )}
                            // href={FetchedEndorsementDetails.CDStatementFile.FileLink}
                            // download={true}
                            // target={"_blank"}
                          >
                            {FetchedEndorsementDetails.CDStatementFile.FileName || "Link"}
                          </button>
                          : null)}
                    </span>
                  </label>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl>
                  <input
                    accept="application/pdf,application/vnd.ms-excel "
                    className={classes.inputHidden}
                    id="ClientDataFile"
                    name="ClientDataFile"
                    onChange={({ target }) =>
                      handlefileUpload(target.files[0], "ClientDataFile")
                    }
                    type="file"
                  />
                  <label htmlFor="ClientDataFile">
                    <IconButton
                      color="primary"
                      component="span"
                      className={classes.uploadIcon}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                    Upload Client Data
                    <span className={classes.fileNameText}>
                    {formState && formState.values && formState.values.ClientDataFile &&
                        formState.values.ClientDataFile.FileName && !formState.values.ClientDataFile.FileLink ?
                        formState.values.ClientDataFile.FileName :
                        (FetchedEndorsementDetails && FetchedEndorsementDetails.ClientDataFile && FetchedEndorsementDetails.ClientDataFile.DocId ?
                          <button className="link-btn"
                          onClick={()=>getDocUrl(FetchedEndorsementDetails.ClientDataFile.DocId )}
                            // href={FetchedEndorsementDetails.ClientDataFile.FileLink}
                            // download={true}
                            // target={"_blank"}
                          >
                            {FetchedEndorsementDetails.ClientDataFile.FileName || "Link"}
                          </button>
                          : null)}
                    </span>
                  </label>
                  {/* {formState && formState.values && !formState.values.ClientDataFile && formState.touched && formState.touched.ClientDataFile ? 
                  <span className={classes.errorMsg}>*Mandatory</span> :
                  FetchedEndorsementDetails && !FetchedEndorsementDetails.ClientDataFile ? <span className={classes.errorMsg}>*Mandatory</span>  : 
                  ""
                  } */}
                  {/* {(formState && formState.values && formState.values.ClientDataFile &&
                    formState.values.ClientDataFile.FileName) || (FetchedEndorsementDetails && FetchedEndorsementDetails.ClientDataFile && FetchedEndorsementDetails.ClientDataFile.FileName) ? null
                    : <span className={classes.errorMsg}>*Mandatory</span>
                  } */}
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl>
                  <input
                    accept="application/pdf,application/vnd.ms-excel "
                    className={classes.inputHidden}
                    id="ECardFile"
                    name="ECardFile"
                    onChange={({ target }) =>
                      handlefileUpload(target.files[0], "ECardFile")
                    }
                    type="file"
                  />
                  <label htmlFor="ECardFile">
                    <IconButton
                      color="primary"
                      component="span"
                      className={classes.uploadIcon}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                    Upload E Card
                    <span className={classes.fileNameText}>
                    {formState && formState.values && formState.values.ECardFile &&
                        formState.values.ECardFile.FileName && !formState.values.ECardFile.FileLink ?
                        formState.values.ECardFile.FileName :
                        (FetchedEndorsementDetails && FetchedEndorsementDetails.ECardFile && FetchedEndorsementDetails.ECardFile.DocId ?
                          <button className="link-btn"
                          onClick={()=>getDocUrl(FetchedEndorsementDetails.ECardFile.DocId )}
                            // href={FetchedEndorsementDetails.ECardFile.FileLink}
                            // download={true}
                            // target={"_blank"}
                          >
                            {FetchedEndorsementDetails.ECardFile.FileName || "Link"}
                          </button>
                          : null)}
                    </span>
                    
                  </label>
                  {/* {formState && formState.values && !formState.values.ECardFile && formState.touched && formState.touched.ECardFile ? 
                  <span className={classes.errorMsg}>*Mandatory</span> :
                  FetchedEndorsementDetails && !FetchedEndorsementDetails.ECardFile ? <span className={classes.errorMsg}>*Mandatory</span>  : 
                  ""
                  } */}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
              <Button className="save-btn" disabled={isLoading} onClick={handleSaveEndorsementChanges}>Save {isLoading ? <Spinner/> : ""} </Button>
              <Button className="cancel-btn" disabled={isLoading} onClick={() => setEndoresmentDialogOpen(false)}>Cancel</Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}


const mapStateToProps =(state) =>{
  return{
    OpenedTicketDetails : state && state.ticketdetails && state.ticketdetails.ticketDetails ? state.ticketdetails.ticketDetails :{},
  }
   
}

export default connect(mapStateToProps, null)(EndorsementFormDialog)