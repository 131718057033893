import React, { useEffect, useState, useReducer } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { HashLoader } from 'react-spinners';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { fetchMasterList } from '../../../redux/actions/common_action';
// import { ADMIN_ADVANCE_SEARCH_TYPE } from '../../../../Config';
import { Toast } from '../../../shared/components';
import CustomBackdrop from '../../Components/CustomBackdrop';
import ED_DATA from './ED_Data';
import { exportDashboardData_method, fetchDashboardData_method } from '../../../shared/methods/apiMethods';
import { getSubStatusList, getUniqueSetBasedOnKey } from '../../../shared/methods/methods';
import { ED_ADVANCE_SEARCH_TYPE, RESTRICT_ED_PRODUCTS } from '../../../Config';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
  },
}))

const ED_ADVANCE_SEARCH = ({
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const userID_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.UserID);
  const userType_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.UserType);
  const productSupplierMaster = useSelector(state => state && state.common && state.common.productSupplierMaster);
  const sourceMaster_store = useSelector(state => state && state.common && state.common.sourceMaster);
  const statusSubStatusMaster_store = useSelector(state => state && state.common && state.common.statusSubStatusMaster);
  const [productList, setProductList] = useState();
  const [insurerList, setInsurerList] = useState();
  const [sourceList, setSourceList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [insurerStatusList, setInsurerStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [subSourceList, setSubSourceList] = useState([]);
  const [dataToRender, setDataToRender] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [advanceSearchValues, setAdvanceSearchValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      IsUpdatedOn: "0",
      FromDate: new Date(),
      ToDate: new Date(),
      Source: "",
      SubSource: "",
      CBRequest: 0,
      STP: 0,
      StatusID: 0,
      SubStatusID: 0,
      InsurerStatusID: 0,
      TATFilter: 0,
      TicketNotReply: 0,
      IsEsc: 0,
      ProductID: [],
      SupplierID: 0,

    }
  );
  const [advanceSearchValuesTouched, setAdvanceSearchValuesTouched] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {

      FromDate: true,
      ToDate: true,
      Source: false,
      SubSource: false,
      CBRequest: false,
      STP: false,
      StatusID: false,
      SubStatusID: false,
      InsurerStatusID: false,
      TATFilter: false,
      TicketNotReply: false,
      IsEsc: false,
      ProductID: false,
      SupplierID: false,
    }
  );

  const showMessage = (message, varient) => {
    return enqueueSnackbar(message, {
      variant: varient, onClick: () => {
        closeSnackbar();
      },
    });
  }


  const TatFilterArray = [
    { DisplayName: "TAT Busted", value: 1 },
    { DisplayName: "Busting Today", value: 2 },
    { DisplayName: "Busting in 2 days", value: 3 },
    { DisplayName: "Busting in 3 days", value: 4 },
    { DisplayName: "Busting above 3 days", value: 5 },
  ]

  const EscalationArray = [
    { DisplayName: "ALL", value: 1 },
  ]

  useEffect(() => {
    if (productSupplierMaster && productSupplierMaster.length === 0) {
      dispatch(fetchMasterList())
    }
  }, [productSupplierMaster]);

  // For Filtering ProductList
  useEffect(() => {
    if (productSupplierMaster) {
      getProductSet();
    }
    if (sourceMaster_store) {
      getSourceSet();
    }
    if (statusSubStatusMaster_store) {
      getTicketStatusSet();
      getInsurerStatusSet();
    }
  }, [productSupplierMaster, sourceMaster_store, statusSubStatusMaster_store]);

  const getProductSet = () => {
    let arr = getUniqueSetBasedOnKey(productSupplierMaster, 'ProductID', ['ProductID', 'ProductName']);
    let arr1 = arr.filter(i=> RESTRICT_ED_PRODUCTS.includes(i.ProductID))
    arr1.splice(0, 0, { ProductID: 0, ProductName: 'NO PRODUCT' })
    setProductList(arr1);
  }

  //For Filtering Source List
  const getSourceSet = () => {
    let tempSources = getUniqueSetBasedOnKey(sourceMaster_store, 'SourceID', ['SourceID', 'SourceName']);
    setSourceList(tempSources);
  }

  //for filtering status 
  const getTicketStatusSet = () => {
    let tempTicketStatus = [];
    let obj;
    statusSubStatusMaster_store.length > 0 &&
      statusSubStatusMaster_store.map((item) => {
        let flag = 0;
        if (tempTicketStatus && tempTicketStatus.length > 0) {
          tempTicketStatus.forEach((singleItem) => {
            if (singleItem.StatusID == item.StatusID) {
              return (flag = 1);
            }
          });
        }
        if (flag == 0 && item.StatusType === 1) {
          obj = {
            StatusID: item.StatusID,
            StatusName: item.StatusName,
          };
          tempTicketStatus.push(obj);
        }
      });
    setStatusList(tempTicketStatus);
  }

  //for filtering Insurer Status from StatusSubStatus Master
  const getInsurerStatusSet = () => {
    let tempTicketStatus = [];
    let obj;
    statusSubStatusMaster_store.length > 0 &&
      statusSubStatusMaster_store.map((item) => {
        let flag = 0;
        if (tempTicketStatus && tempTicketStatus.length > 0) {
          tempTicketStatus.forEach((singleItem) => {
            if (singleItem.StatusID == item.StatusID) {
              return (flag = 1);
            }
          });
        }
        if (flag === 0 && item.StatusType === 2) {
          obj = {
            StatusID: item.StatusID,
            StatusName: item.StatusName,
          };
          tempTicketStatus.push(obj);
        }
      });
    setInsurerStatusList(tempTicketStatus);
  }


  //for filtering sub status 
  const getTicketSubStatusSet = () => {
    let tempSubStatus = getSubStatusList(statusSubStatusMaster_store, 'SubStatusID', ['SubStatusID', 'SubStatusName'], advanceSearchValues.StatusID);
    setSubStatusList(tempSubStatus);
  }

  useEffect(() => {
    if (advanceSearchValues.StatusID) {
      setAdvanceSearchValues({ SubStatusID: 0 })
      getTicketSubStatusSet();
    }
  }, [advanceSearchValues.StatusID])

  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist()
    }
    const { name, value } = event.target;
    if(name === 'ProductID' && value.length > 3){
        return Toast.error('Only 3 products can be selected')
    }
    setAdvanceSearchValues({
      [name]: value
    })
    setAdvanceSearchValuesTouched({
      [name]: true
    })
  }

  useEffect(() => {
    if (advanceSearchValues.ProductID && advanceSearchValues.ProductID.length === 1) {
      handleSupplierProductChange(advanceSearchValues.ProductID[0]);
    }
    else {
      setInsurerList([]);
    }
    setAdvanceSearchValues({ SupplierID: 0 });

  }, [advanceSearchValues.ProductID])

  const handleSupplierProductChange = (product) => {
    let insurerList = [];
    let obj;
    if (
      productSupplierMaster &&
      Array.isArray(productSupplierMaster) &&
      productSupplierMaster.length > 0
    ) {
      productSupplierMaster.map((item) => {
        let flag = 0;
        if (item.ProductID == product) {
          if (insurerList && insurerList.length > 0) {
            insurerList.forEach((singleItem) => {
              if (singleItem.SupplierID == item.SupplierID) {
                return (flag = 1);
              }
            });
          }
          if (flag === 0) {
            obj = {
              SupplierID: item.SupplierID,
              SupplierName: item.SupplierName,
            };
            insurerList.push(obj);
          }
        }
      });
      setInsurerList(insurerList);
    }
  };


  //For handling SubSource
  useEffect(() => {
    if (advanceSearchValues.Source) {
      handleSubSourceFilter(advanceSearchValues.Source);
    }
    else {
      setSubSourceList([]);
    }
    setAdvanceSearchValues({ SubSource: "" }); // on Change of Source, the value of SubSource goes blank
  }, [advanceSearchValues.Source])

  const handleSubSourceFilter = (source) => {
    let tempSubSouceList = [];
    let obj;
    if (
      sourceMaster_store &&
      Array.isArray(sourceMaster_store) &&
      sourceMaster_store.length > 0
    ) {
      sourceMaster_store.map((item) => {
        let flag = 0;
        if (item.SourceName == source) {
          if (tempSubSouceList && tempSubSouceList.length > 0) {
            tempSubSouceList.forEach((singleItem) => {
              if (singleItem.SubSourceID == item.SubSourceID) {
                return (flag = 1);
              }
            });
          }
          if (flag == 0) {
            obj = {
              SubSourceID: item.SubSourceID,
              SubSourceName: item.SubSourceName,
            };
            tempSubSouceList.push(obj);
          }
        }
      });
      setSubSourceList(tempSubSouceList);
    }
  }

  const handleTouched = (skipStatusCheck) => {
    setAdvanceSearchValuesTouched({
      FromDate: true,
      ToDate: true,
      Source: true,
      SubSource: true,
      CBRequest: true,
      STP: true,
      StatusID: skipStatusCheck ? false : true,
      SubStatusID: true,
      InsurerStatusID: true,
      TATFilter: true,
      TicketNotReply: true,
      IsEsc: true,
      ProductID: true,
      SupplierID: true,
    })
  }

  const generatePayload = () => {
    return {
      "IsUpdatedOn": advanceSearchValues.IsUpdatedOn,
      "FilterString": "",
      "FromDate": advanceSearchValues.FromDate.getTime(),
      "ToDate": advanceSearchValues.ToDate.getTime(),
      "Source": advanceSearchValues.Source,
      "SubSource": advanceSearchValues.SubSource,
      "ProductID": 0,
      "ProductIDs": (advanceSearchValues.ProductID).toString(),
      "SupplierID": advanceSearchValues.SupplierID,
      "CBRequest": advanceSearchValues.CBRequest,
      "StatusID": advanceSearchValues.StatusID,
      "SubStatusID": advanceSearchValues.SubStatusID,
      "TATFilter": advanceSearchValues.TATFilter,
      "InsurerStatusID": advanceSearchValues.InsurerStatusID,
      "STP": advanceSearchValues.STP === -1 ? 0 : advanceSearchValues.STP === 0 ? -1 : advanceSearchValues.STP,
      "IsEsc": advanceSearchValues.IsEsc,
      "UserId": userID_store,
      "Type": ED_ADVANCE_SEARCH_TYPE,
      "UserType": userType_store,
    }
  }

  const validate = (skipStatusCheck) => {
    //when skipStatusCheck is passed it skips validation of StatusID
    handleTouched(skipStatusCheck);
    if(skipStatusCheck){
      if(advanceSearchValues.ProductID.length === 0 ||
        advanceSearchValues.ToDate < advanceSearchValues.FromDate || advanceSearchValues.ToDate > new Date() || advanceSearchValues.ToDate > new Date()
      ){
        return false
      }
      else return true
    }
    else if (!advanceSearchValues.StatusID || advanceSearchValues.ProductID.length === 0 ||
      advanceSearchValues.ToDate < advanceSearchValues.FromDate || advanceSearchValues.ToDate > new Date() || advanceSearchValues.ToDate > new Date()
    ) {
      return false
    }
    else return true
  }


  const handleSearch = () => {
    if (!validate()) { return }
    let url = '/GetDashboardData';
    let payload = generatePayload();
    setDataLoading(true);
    let callback = (value) => {
      setDataToRender(value);
      setDataLoading(false);
    }
    fetchDashboardData_method(url, payload, callback, showMessage);
  }

  const exportData = () => {
    if (!validate(true)) { return }
    let url = '/GetDashboardDataExport';
    let payload = generatePayload();
    setDataLoading(true);
    let cb = () => {
      console.log('here')
      return setDataLoading(false);
    }
    return exportDashboardData_method(url, payload, cb, 'AdvanceSeach_TicketList');

  }

  const clearFields = function(){
    setAdvanceSearchValues({
      IsUpdatedOn: "0",
      FromDate: new Date(),
      ToDate: new Date(),
      Source: "",
      SubSource: "",
      CBRequest: 0,
      STP: 0,
      StatusID: 0,
      SubStatusID: 0,
      InsurerStatusID: 0,
      TATFilter: 0,
      TicketNotReply: 0,
      IsEsc: 0,
      ProductID: [],
      SupplierID: 0,
    })
  }


  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white' /></CustomBackdrop> : null}
        <div className="pbdashboard-simple-seach advance-seach">
          {/* <h4>Advance Search</h4> */}
          <div className='create-on-update-on'>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="IsUpdatedOn" defaultValue={advanceSearchValues.IsUpdatedOn} onChange={handleChange} >
                <FormControlLabel value="createdOn" control={<Radio color="primary" value={"0"} />} label="Created On" />
                <FormControlLabel value="updatedOn" control={<Radio color="primary" value={"1"} />} label="Updated On" />
                <FormControlLabel value="escalatedOn" control={<Radio color="primary" value={"2"} />} label="Escalated On" />
              </RadioGroup>
            </FormControl>
          </div>
          <ul>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  maxDate={new Date()}
                  value={advanceSearchValues.FromDate}
                  onChange={(date) => setAdvanceSearchValues({ FromDate: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="End Date"
                  minDate={advanceSearchValues.FromDate}
                  maxDate={new Date()}
                  value={advanceSearchValues.ToDate}
                  onChange={(date) => setAdvanceSearchValues({ ToDate: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Select Source
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.Source}
                  onChange={handleChange}
                  name="Source"
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {sourceList.map(item => {
                    return <MenuItem value={item.SourceName} style={{textTransform: 'capitalize'}}>{item.SourceName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Select Sub Source
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.SubSource}
                  onChange={handleChange}
                  name="SubSource"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {subSourceList.map(item => {
                    return <MenuItem value={item.SubSourceName}>{item.SubSourceName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  CB Request
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.CBRequest}
                  onChange={handleChange}
                  name="CBRequest"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  <MenuItem value={"1"}>Yes</MenuItem>
                  <MenuItem value={"2"}>No</MenuItem>
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  STP Filter
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.STP}
                  onChange={handleChange}
                  name="STP"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  <MenuItem value={1}>STP</MenuItem>
                  <MenuItem value={-1}>NSTP</MenuItem>
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control"
                error={!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID}>
                <InputLabel id="demo-customized-select-label">
                  Select Status
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.StatusID}
                  onChange={handleChange}
                  name="StatusID"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {statusList && statusList.length > 0 && statusList.map(item => {
                    return <MenuItem value={item.StatusID}>{item.StatusName}</MenuItem>
                  })}
                </Select>
                {!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID && <FormHelperText className='error-msg'>Please select Status</FormHelperText>}
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control"
                error={!advanceSearchValues.SubStatusID && advanceSearchValuesTouched.SubStatusID}>
                <InputLabel id="demo-customized-select-label">
                  Select Sub Status
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.SubStatusID}
                  onChange={handleChange}
                  name="SubStatusID"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {subStatusList && subStatusList.length > 0 && subStatusList.map(item => {
                    return item.SubStatusName && <MenuItem value={item.SubStatusID}>{item.SubStatusName}</MenuItem>
                  })}
                </Select>
                {/* {!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID && <FormHelperText className='error-msg'>Please select Status</FormHelperText>} */}
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Insurer Status
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.InsurerStatusID}
                  onChange={handleChange}
                  name="InsurerStatusID"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {insurerStatusList.map(item => {
                    return <MenuItem value={item.StatusID}>{item.StatusName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  TAT Filter
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.TATFilter}
                  onChange={handleChange}
                  name="TATFilter"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {TatFilterArray && TatFilterArray.map(item => {
                    return <MenuItem value={item.value}>{item.DisplayName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Escalation
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.IsEsc}
                  onChange={handleChange}
                  name="IsEsc"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {EscalationArray.map(item => {
                    return <MenuItem value={item.value}>{item.DisplayName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Ticket Not Reply
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.TicketNotReply}
                  onChange={handleChange}
                  name="TicketNotReply"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  <MenuItem value={1}>1 Day(s)</MenuItem>
                  <MenuItem value={2}>2 Day(s)</MenuItem>
                  <MenuItem value={3}>3 Day(s)</MenuItem>
                  <MenuItem value={4}>3+ Day(s)</MenuItem>
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control"
                error={advanceSearchValues.ProductID.length === 0 && advanceSearchValuesTouched.ProductID}>
                <InputLabel id="demo-customized-select-label">
                  Select Product
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  multiple
                  value={advanceSearchValues.ProductID}
                  onChange={handleChange}
                  renderValue={(selected) => selected && selected.length <= 2 ? selected.map(val => {
                    return productList.filter(item => {
                      return item.ProductID === val
                    })[0].ProductName
                  }).join(',') : `${selected.length} Selected`
                  }

                  name="ProductID"
                >
                  {
                    productList && productList.map((item) => (
                      <MenuItem key={item.ProductID} value={item.ProductID}
                      >

                        <Checkbox className='checkbox-icon'
                          checked={advanceSearchValues.ProductID.indexOf(item.ProductID) > -1}
                          
                        />
                        <ListItemText primary={item.ProductName} />
                      </MenuItem>
                    ))}
                </Select>
                {advanceSearchValues.ProductID.length === 0 && advanceSearchValuesTouched.ProductID && <FormHelperText className='error-msg'>Please select product(s)</FormHelperText>}
              </FormControl>
            </li>
            {/* <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Select Insurer
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={advanceSearchValues.SupplierID}
                  onChange={handleChange}
                  name="SupplierID"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {insurerList && insurerList.length > 0 && insurerList.map(item => {
                    return <MenuItem key={`product+${item.SupplierID}`} value={item.SupplierID}>{item.SupplierName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li> */}
            <li>
              <Button className="export-btn" onClick={handleSearch}>Search</Button>
            </li>
            <li>
              <Button className="export-btn" onClick={exportData}>Export</Button>
            </li>
            <li>
              <Button className="clear-btn" onClick={clearFields}>Clear All</Button>
            </li>
          </ul>
          <div></div>
          {dataToRender && Array.isArray(dataToRender) && <ED_DATA dataToRender={dataToRender} />}
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default ED_ADVANCE_SEARCH
