import React, {createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
// import Notifier from './../../components/Notifier';
import { connect } from "react-redux";
import TopbarHeader from "./TopbarHead";
import SideBar from "./Sidebar";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  shiftContent: {
    //paddingLeft: 215
  },
  content: {
    height: "100%",
    flexGrow: 1,
  },
}));

export const AppContext = createContext();

const MainLayout = (props) => {
  const { children, user, onLogout, notifications, componentName } = props;
  const classes = useStyles();
  const theme = useTheme();
//   const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
//     defaultMatches: true,
//   });

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [agentStatus, setAgentStatus] = useState("")

  useEffect(()=>{
    document.title = componentName
  },[componentName])

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = openSidebar;

  return (
    <AppContext.Provider value = {{agentStatus, setAgentStatus}}>
    <div className="top-headbar" >
    <TopbarHeader componentName={componentName}/>
      </div>
    <main className={classes.content}>
        
    {children}
      </main>
    <SideBar/>
    </AppContext.Provider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};


export default MainLayout;