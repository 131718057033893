import React from 'react'
import { Tooltip } from '@material-ui/core';
import { convertToIsoFormateLogActivity, sortDataKeyDateDesc } from '../../../shared/methods/methods';


function PendencyLogs({ logs }) {

	let Headers = {
		PendencyType: 'Pendency Type',
		// Remarks: 'Remarks',
		RaisedOn: 'Raised On',
		RaisedBy: 'Raised By',
		ResolvedOn: 'Resolved On',
		// ResolvedRemark: 'Resolved Remark',
		ResolvedBy: 'Resolved By'
	}

	let sortedLogs = logs
	// logs.sort((a,b)=>{
	// 	let dateA = a.ResolvedOn || a.CreatedOn;
	// 	let dateB = b.ResolvedOn || b.CreatedOn
	// 	return (Date.parse(dateB) - Date.parse(dateA))
	// })

	return (
		<div className="pendency-logs-view">
			{
				<>
					<div className='body-list'>
						{
							sortedLogs.map((item, index) => {
								return <>
									<ul key={`newCustomerPendencyLogs${index} `}>
										{item.PendencyType ? <h5>
											{item.PendencyType}
											{item.ResolvedOn && <span className='raised-on'>
												<strong> Resolved On</strong> - {convertToIsoFormateLogActivity(item.ResolvedOn) || 'NA'}
											</span>}
										</h5> : null}
										<ul>
											<li>
												<p>
													<strong>Raised By </strong>- {item.RaisedBy || '-'}
												</p>
											</li>
											<li>
												<p>
													<strong>Raised On</strong> - {item.RaisedOn ? convertToIsoFormateLogActivity(item.RaisedOn) : 'NA'}
												</p>
											</li>
											<li>
												{item.ResolvedBy ? <p>
													<strong>Resolved By</strong> - {item.ResolvedBy}
												</p> : null}
											</li>
										</ul>
									</ul>
								</>
							})
						}
					</div>
				</>
			}
		</div >
	)
}

export default PendencyLogs
