import React, { useState, useRef, useEffect, useReducer } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Button, Grid, TextField } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMasterList } from '../../redux/actions/common_action';
import { getUniqueSetBasedOnKey, toBase64 } from '../../shared/methods/methods';


import { useSnackbar } from "notistack";
import { GET } from '../../services/apiServices';
import { DialogFromRight, Toast } from '../../shared/components';
import { handleCatch } from '../../shared/methods/apiMethods';
import ViewCache from './ViewCache';
import ChildErrorBoundary from '../../shared/components/ChildErrorBoundary';
import CustomBackdrop from '../Components/CustomBackdrop';


const TicketCacheManagement = () => {
    const [apiResponseOriginal, setApiResponseOriginal] = useState([])
    const [apiResponse, setApiResponse] = useState([]);
    const [groups, setGroups] = useState(null);
    const [dialogWithControls, setDialogWithControls] = useState();
    const [LoadingData, setLoadingData] = useState(false);
    const [cacheFilterText, setCahceFilterText] = useState('');

    useEffect(() => {
        fetchAPIResponse()
    }, [])

    //fetchAPIResponse
    const fetchAPIResponse = () => {
        setLoadingData(true);
        let url = "GetAllCachedKeys";
        GET(url)
            .then((res) => {
                setLoadingData(false);
                if (res && res.ErrorCode == 0 && res.Data) {
                    setApiResponseOriginal(res.Data)
                    setApiResponse(res.Data);
                } else {
                    Toast.error(res.Message)
                }
            })
            .catch((err) => {
                setLoadingData(false);
                handleCatch(err)
            });
    };


    const getGroupAndItemList = () => {
        let groupList = [];
        apiResponse.forEach(item => {
            let groupName = item.GroupName;
            if (!groupList.includes(groupName)) {
                groupList.push(groupName)
            }
        })
        setGroups(groupList)
    }

    const viewCacheControl = (keyName) => {
        setDialogWithControls({
            open: true,
            title: 'Cache View',
            handleClose: () => setDialogWithControls(),
            size: 70,
            children: <ChildErrorBoundary className="view-cache"><ViewCache closeParentDialog={() => setDialogWithControls()} keyName={keyName} /></ChildErrorBoundary>
        })
    }

    const deleteCacheApi = (keyName) => {
        let url = `ClearCache?KeyName=${keyName}`;
        GET(url)
            .then((res) => {
                if (res && res.ErrorCode == 0) {
                    fetchAPIResponse()
                    Toast.success(`${keyName} successfully deleted`)
                } else {
                    Toast.error(res.Message)
                }
            })
            .catch((err) => {
                handleCatch(err)
            });
    };

    useEffect(() => {
        if (cacheFilterText) {
            let filteredData = apiResponse.filter(item => {
                if (JSON.stringify(item).toLowerCase().includes(String(cacheFilterText).toLowerCase())) { return item }
            })
            console.log('filtered data:', (filteredData))
            setApiResponse(filteredData)
        }
        else setApiResponse(apiResponseOriginal)
    }, [cacheFilterText])


    useEffect(() => {

        if (apiResponse && apiResponse.length) {
            getGroupAndItemList(); // fetch gruop list and item list
        }

    }, [apiResponse]);




    return (
        <div className='ticket-chace-management'>
            <h2>Ticket Cache Management</h2>
            <div className='ticket-chace-management-box'>
                <div className='cache-serch-filter'>
                    <FormControl className='form-control'>
                        <TextField
                            placeholder="Search Cache Management"
                            value={cacheFilterText}
                            onChange={(e) => setCahceFilterText(e.target.value)}
                        />
                    </FormControl>
                </div>
                {/* {JSON.stringify(uniqueKeyValuePairs)} */}
                <ul className='main-list scroll-btn'>
                    {LoadingData ? (
                        <CustomBackdrop open={LoadingData} />
                    ) : groups && Array.isArray(groups) && groups.length > 0 && groups.map((groupName) => {
                        return (
                            <li>
                                <h4>{groupName}</h4>
                                <ul className='inner-list'>
                                    {apiResponse.filter(item => item.GroupName === groupName).length && apiResponse.filter(item => item.GroupName === groupName)[0]['KeyList'].map(item2 => {
                                        return <li>
                                            <Button onClick={() => viewCacheControl(item2)}>
                                                {item2}
                                            </Button>
                                            <Button className='delete-btn' onClick={() => deleteCacheApi(item2)}><i className='fa fa-close'></i></Button>
                                        </li>
                                    })}
                                    {/* <li>{group.KeyList}</li> */}
                                </ul>
                            </li>
                        )
                    })
                    }
                </ul>
                {dialogWithControls && dialogWithControls.open &&
                    <DialogFromRight
                        open={dialogWithControls.open}
                        handleClose={dialogWithControls.handleClose}
                        title={dialogWithControls.title}
                        children={dialogWithControls.children}
                        size={dialogWithControls.size}
                    />
                }
            </div>
        </div>
    );
}

export default TicketCacheManagement;
