import React, { useState, useRef, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  List,
  ListItem,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  InputBase,
  TextareaAutosize,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import {
  convertToIsoFormate,
  toBase64,
  getFileExtension,
  handleDrag,
  handleDragIn,
  handleDrop,
  handleDragOut,
} from "../../shared/methods/methods";
import CloseIcon from "@material-ui/icons/Close";
import { DisplayName } from "../../Config";
import Spinner from "../../shared/components/Spinner";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import {
  UpdateTicketRemarks,
  UpdateTicketInsurerStatus,
  GetDocURL,
} from "../../constants/apiUrls";
import { GET, POST } from "../../services/apiServices";
import { useSnackbar } from "notistack";
import TicketContext from "../../TicketContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FileUploadComponent from "../../shared/components/FileUploadComponent";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CommentsHistory({
  oldTicketDetails,
  templateList,
  setRefresh,
  viewtypeComment,
  IsReadOnlyUser,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const ticketContext = useContext(TicketContext);

  const fileRef = useRef(null);
  const insurerFileRef = useRef(null);

  const [selectedFile, setselectedFile] = useState([]);
  const [selectedInsurerFile, setSelectedInsurerFiles] = useState([]);
  const [comment, setComment] = useState("");
  const [commentInProgress, setCommentInProgress] = useState("");
  const [insurerCommentInProgress, setInsurerCommentInProgress] =
    useState(false);
  const [insurerComment, setInsurerComment] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const InsurerStatusOption = [10, 12, 14];
  const [InsurerStatusList, setInsurerStatusList] = useState([]);

  const [OldInsurerStatusID, setOldInsurerStatusID] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState({
    StatusID: "",
    StatusName: "",
  });

  useEffect(() => {
    if (
      ticketContext.allSubStatusOptions &&
      ticketContext.allSubStatusOptions.length > 0
    ) {
      const options = ticketContext.allSubStatusOptions.filter(
        (item) => item.StatusType === 2
      );
      let enableStatusOptions = [];
      let disableStatusOptions = [];
      for (let i = 0; i < options.length; i++) {
        if (oldTicketDetails.InsurerStatusID === options[i].StatusID) {
          setSelectedStatus({
            StatusID: options[i].StatusID,
            StatusName: options[i].StatusName,
          });
          setOldInsurerStatusID(options[i].StatusID);
        }
        if (InsurerStatusOption.includes(options[i].StatusID)) {
          enableStatusOptions.push(options[i]);
        } else {
          disableStatusOptions.push(options[i]);
        }
      }

      setInsurerStatusList([...enableStatusOptions, ...disableStatusOptions]);
    }
  }, [ticketContext.allSubStatusOptions, oldTicketDetails.InsurerStatusID]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setselectedFile([]);
    setComment("");
    setInsurerComment("");
    setSelectedInsurerFiles([]);
  };

  const handleRemoveFile = (id) => {
    const fileList = selectedFile.filter((item) => item.id !== id);
    setselectedFile(fileList);
  };

  const handleRemoveInsurerFile = (id) => {
    const fileList = selectedInsurerFile.filter((item) => item.id !== id);
    setSelectedInsurerFiles(fileList);
  };

  const showMsg = (msg, variant = "error") => {
    enqueueSnackbar(msg, {
      variant: variant,
      onClick: () => {
        closeSnackbar();
      },
    });
  };

  const handleCommentSend = () => {
    if (!comment) {
      showMsg("Please enter the comment first");
      return;
    }

    const payload = {
      PrevObjectID: oldTicketDetails.RefObjectId,
      TicketId: oldTicketDetails.TicketId,
      TicketDetailId: oldTicketDetails.TicketDetailsID,
      LeadID: oldTicketDetails.LeadID,
      CustomerName: oldTicketDetails.CustomerName,
      CustomerID: oldTicketDetails.CustomerId,
      ProductID: oldTicketDetails.ProductId,
      ProductName: oldTicketDetails.ProductName,
      Body: comment,
      MailAttachments:
        selectedFile.length > 0
          ? selectedFile.map((item) => ({
              FileName: item.FileName,
              AttachemntContent: item.AttachemntContent,
            }))
          : [],
      HasAttachment: selectedFile.length > 0 ? true : false,
      DisplaySenderName: DisplayName,
      ActionType: 1,
      StatusName: oldTicketDetails.StatusName,
      SubStatusName: oldTicketDetails.Status,
      InsurerID: oldTicketDetails.SupplierId,
      StatusID: oldTicketDetails.StatusID,
      IsJAG:
        oldTicketDetails && oldTicketDetails.IsJAG ? oldTicketDetails.IsJAG : 0,
      JAGEmployeeId:
        oldTicketDetails && oldTicketDetails.JAGEmployeeId
          ? oldTicketDetails.JAGEmployeeId
          : "",
      IssueTypeID:
        oldTicketDetails && oldTicketDetails.IssueTypeId
          ? oldTicketDetails.IssueTypeId
          : 0,
      TicketSource: (oldTicketDetails && oldTicketDetails.Source) || "",
      TicketSubSource: (oldTicketDetails && oldTicketDetails.SubSource) || "",
    };
    setCommentInProgress(true);
    POST(
      `${UpdateTicketRemarks}/${
        templateList && templateList.length > 0
          ? templateList[0].TriggerName
          : "DefaultTemplate"
      }`,
      payload
    )
      .then((res) => {
        if (res.ErrorCode === 0) {
          ticketContext.GetTicketCommApi(
            oldTicketDetails.RefObjectId,
            oldTicketDetails.TicketDetailsID
          );
          showMsg(res.Message || "Success", "success");
          setselectedFile([]);
          setComment("");
          ticketContext.GetTicketLogsApi();
        } else {
          showMsg(res.Message || "Something went wrong!");
        }
        setCommentInProgress(false);
      })
      .catch((err) => {
        console.log(err);
        setCommentInProgress(false);
      });
  };

  const getShortName = (name) => {
    if (name) {
      const nameArr = name.split(" ");
      if (nameArr.length > 1 && nameArr[0] && nameArr[nameArr.length - 1]) {
        return (
          nameArr[0][0].toUpperCase() +
          nameArr[nameArr.length - 1][0].toUpperCase()
        );
      } else if (nameArr.length === 1 && nameArr[0]) {
        return nameArr[0][0].toUpperCase();
      } else {
        return "";
      }
    }
  };

  const handleInsurerCommentSend = () => {
    if (!selectedStatus.StatusName) {
      showMsg("Please select the status first");
      return;
    }

    if (!insurerComment) {
      showMsg("Please enter the comment first");
      return;
    }

    const insurerStatusPayload = {
      TicketId: oldTicketDetails.TicketId,
      InsurerStatusID: selectedStatus.StatusID,
      InsurerSubStatusID: 0,
      OldInsurerStatusID: OldInsurerStatusID,
      OldInsurerSubStatusID: 0,
    };

    setInsurerCommentInProgress(true);

    POST(UpdateTicketInsurerStatus, insurerStatusPayload)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setRefresh(true);
          const updateTicketRemarksPayload = {
            PrevObjectID: oldTicketDetails.RefObjectId,
            LeadID: oldTicketDetails.LeadID,
            InsurerID: oldTicketDetails.SupplierId,
            CustomerName: oldTicketDetails.CustomerName,
            ProductID: oldTicketDetails.ProductId,
            ProductName: oldTicketDetails.ProductName,
            Body: insurerComment,
            DisplaySenderName: DisplayName,
            MailAttachments:
              selectedInsurerFile.length > 0
                ? selectedInsurerFile.map((item) => ({
                    FileName: item.FileName,
                    AttachemntContent: item.AttachemntContent,
                  }))
                : [],
            ActionType: 3,
            StatusName: oldTicketDetails.StatusName,
            SubStatusName: oldTicketDetails.Status,
            TicketId: oldTicketDetails.TicketId,
            IsInsurer: false,
            TicketDetailId: oldTicketDetails.TicketDetailsID,
            StatusID: oldTicketDetails.StatusID,
            SubStatusID: oldTicketDetails.SubStatusID,
            HasAttachment: selectedInsurerFile.length > 0 ? true : false,
            IsJAG:
              oldTicketDetails && oldTicketDetails.IsJAG
                ? oldTicketDetails.IsJAG
                : 0,
            JAGEmployeeId:
              oldTicketDetails && oldTicketDetails.JAGEmployeeId
                ? oldTicketDetails.JAGEmployeeId
                : "",
            IssueTypeID:
              oldTicketDetails && oldTicketDetails.IssueTypeId
                ? oldTicketDetails.IssueTypeId
                : 0,
            Source: (oldTicketDetails && oldTicketDetails.Source) || "",
            SubSource: (oldTicketDetails && oldTicketDetails.SubSource) || "",
            HCSSource: oldTicketDetails && oldTicketDetails.HCSSource
          };
          POST(
            `${UpdateTicketRemarks}/${
              templateList && templateList.length > 0
                ? templateList[0].TriggerName
                : "DefaultTemplate"
            }`,
            updateTicketRemarksPayload
          )
            .then((res) => {
              if (res.ErrorCode === 0) {
                ticketContext.GetTicketCommApi(
                  oldTicketDetails.RefObjectId,
                  oldTicketDetails.TicketDetailsID
                );
                showMsg(res.Message || "Success", "success");
                setSelectedInsurerFiles([]);
                setInsurerComment("");
                setInsurerCommentInProgress(false);
                setOldInsurerStatusID(selectedStatus.StatusID);
                ticketContext.setoldTicketDetails((prev) => {
                  return { ...prev, InsurerStatusID: selectedStatus.StatusID };
                });
                ticketContext.GetTicketLogsApi();
              } else {
                showMsg(res.Message || "Something went wrong");
                setInsurerCommentInProgress(false);
              }
            })
            .catch((err) => {
              console.log(err);
              showMsg("Something went wrong");
              setInsurerCommentInProgress(false);
            });
        } else {
          showMsg("Something went wrong");
          setInsurerCommentInProgress(false);
        }
      })
      .catch((err) => {
        console.log(err);
        showMsg("Something went wrong");
        setInsurerCommentInProgress(false);
      });
  };

  // Download attachemnt through secure link

  const downloadAttachment = (docID, fileName) => {
    if (docID) {
      GET(`${GetDocURL}/${docID}`)
        .then((res) => {
          if (res && res.ErrorCode == 0 && res.Data) {
            window.open(res.Data, "_blank");
          } else {
            if (res.Response) {
              enqueueSnackbar(res.Response, {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            } else {
              enqueueSnackbar("URL not found", {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            }
            console.log("something went wrong");
          }
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log(err);
        });
    } else {
      enqueueSnackbar("Document not found", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      console.log("Document ID not found");
    }
  };

  // ------------------------------------------
  return (
    <div className="comment-history">
      <h2>Ticket Comments</h2>
      <List>
        <ListItem>
          <h3>Update Ticket</h3>
          <div className="comment-pannel">
            <FormControl className="form-control">
              <InputLabel id="demo-customized-select-label">New</InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedStatus.StatusName}
                // onChange={handleChange}
              >
                {InsurerStatusList.filter((item) => item.StatusType === 2).map(
                  (item, i) => {
                    return (
                      <MenuItem
                        value={item.StatusName}
                        onClick={() =>
                          setSelectedStatus({
                            StatusID: item.StatusID,
                            StatusName: item.StatusName,
                          })
                        }
                        key={i}
                        disabled={!InsurerStatusOption.includes(item.StatusID)}
                      >
                        {item.StatusName}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
            {!IsReadOnlyUser && (
              <FormControl className="form-control">
                <TextareaAutosize
                  className="txtarea"
                  placeholder="Enter Comments"
                  value={insurerComment}
                  onChange={(e) => setInsurerComment(e.target.value)}
                />
                <p className="attach-btn">
                  <FileUploadComponent
                    setFiles={setSelectedInsurerFiles}
                    fileRef={insurerFileRef}
                    isMultipleFileAllowed={true}
                    isAttachementIcon={true}
                    className="hello"
                  />
                </p>
              </FormControl>
            )}
            {/* <span className="upload-file">
              <span className="upload-file-name">abc.doc</span>
              <span className="upload-file-name">abc.doc</span>
              <span className="upload-file-name">abc.doc</span>
            </span> */}
            {selectedInsurerFile.map((item) => {
              return (
                <span className="file-detail name-file" key={item.id}>
                  {item.FileName}{" "}
                  <button
                    className="remove-file-icon"
                    onClick={() => handleRemoveInsurerFile(item.id)}
                  >
                    <CloseIcon />
                  </button>
                </span>
              );
            })}
            <div>
              <Button className="submit-btn" onClick={handleInsurerCommentSend}>
                Submit
              </Button>
            </div>
          </div>
        </ListItem>
        <ListItem>
          <h3>Comment History</h3>
          {console.log(
            "data from comments history",
            ticketContext.ticketCommData
          )}
          <List className="comment-trail scrool-toll">
            {ticketContext.ticketCommData &&
              Array.isArray(ticketContext.ticketCommData) &&
              ticketContext.ticketCommData
                .filter((item) => item.ActionType == 3 || item.ActionType == 5)
                .map((commu, i) => {
                  return (
                    <ListItem key={i}>
                      <div className="short-name">
                        {getShortName(commu.CreatedBy)}
                      </div>
                      <div className="timeline"></div>
                      <span className="comment-time">
                        {convertToIsoFormate(commu.DisplayDate)}
                      </span>
                      <div className="commnets-txt">
                        <p>{commu.CreatedBy}</p>
                        <strong>
                          {
                            <span
                              dangerouslySetInnerHTML={{
                                __html: commu.Body,
                              }}
                            ></span>
                          }
                        </strong>
                      </div>
                      {commu.MailAttachments &&
                        commu.MailAttachments.map((attachement, i) => {
                          return (
                            attachement.FileName && (
                              <span
                                className="attachemt-link"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  downloadAttachment(
                                    attachement.DocId,
                                    attachement.FileName
                                  )
                                }
                              >
                                {/* <a
                                href={attachement.AttachmentURL}
                                target="_blank"
                                key={i}
                              > */}
                                <div className="upload-item">
                                  <span className="file-extn">
                                    {getFileExtension(attachement.FileName) ===
                                    "doc" ? (
                                      <img
                                        src="/images/doc_icon.png"
                                        alt="pdf_icon"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {getFileExtension(attachement.FileName) ===
                                      "jpg" ||
                                    getFileExtension(attachement.FileName) ===
                                      "png" ||
                                    getFileExtension(attachement.FileName) ===
                                      "jpeg" ? (
                                      <img
                                        src="/images/img_icon.png"
                                        alt="pdf_icon"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {getFileExtension(attachement.FileName) ===
                                    "pdf" ? (
                                      <img
                                        src="/images/pdf_icon.svg"
                                        alt="pdf_icon"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  <span className="download-info">
                                    {attachement.FileName}
                                  </span>
                                </div>
                                {/* </a> */}
                              </span>
                            )
                          );
                        })}
                    </ListItem>
                  );
                })}{" "}
          </List>
        </ListItem>
      </List>
    </div>
  );
}
