import React, { useEffect, useState, useReducer } from 'react'
import {
  Button,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux';
import AdminDashboardData from './AdminDashboardDataV2';
import { GetUserGroupMaster, exportDashboardData_method, fetchDashboardData_method } from '../../../../shared/methods/apiMethods';
import CustomBackdrop from '../../../Components/CustomBackdrop';
import { HashLoader } from 'react-spinners';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { fetchProductGroupMaster, fetchSourceSubSourceMasterV2, fetchStatusSubStatusMasterV2, fetchSupplierMasterV2 } from '../../../../redux/actions/common_action';
import { DiffDaysBtwDates, getProductListFromProductGroupMasterV2, getSubStatusList, getUniqueSetBasedOnKey } from '../../../../shared/methods/methods';
import { ADMIN_ADVANCE_SEARCH_TYPE } from '../../../../Config';
import { Toast } from '../../../../shared/components';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
  },
}))

const AdminAdvanceSearchV2 = ({
}) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const userID_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.UserID);
  const userType_store = useSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.UserType);
  const supplierMaster_store = useSelector(state => state.common.productSupplierMaster || [])
  const productGroupMaster = useSelector(state => state && state.common && state.common.productGroupMaster);
  const zeroLeadSelection = useSelector(state => state.pbDashboard.zeroLeadSelected || false);
  const [productList, setProductList] = useState();
  const [productListToRender, setProductListToRender] = useState([]);
  const [userGroupsMaster, setUserGroupsMaster] = useState([])

  const productSupplierMaster = useSelector(state => state && state.common && state.common.productSupplierMaster);
  const sourceMaster_store = useSelector(state => state && state.common && state.common.sourceMaster);
  const statusSubStatusMaster_store = useSelector(state => state && state.common && state.common.statusSubStatusMaster);

  const [insurerList, setInsurerList] = useState();
  const [sourceList, setSourceList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [insurerStatusList, setInsurerStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [subSourceList, setSubSourceList] = useState([]);
  const [dataToRender, setDataToRender] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
    const [advanceSearchValues, setAdvanceSearchValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      IsUpdatedOn: "0",
      FromDate: new Date(),
      ToDate: new Date(),
      Source: "",
      SubSource: "",
      CBRequest: 0,
      STP: 0,
      StatusID: 0,
      SubStatusID: 0,
      InsurerStatusID: 0,
      TATFilter: 0,
      TicketNotReply: 0,
      IsEsc: 0,
      ProductID: [],
      SupplierID: 0,
      ProductGroupID: null,
      UserGroupsSelected: [],

    }
  );
  const [advanceSearchValuesTouched, setAdvanceSearchValuesTouched] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {

      FromDate: true,
      ToDate: true,
      Source: false,
      SubSource: false,
      CBRequest: false,
      STP: false,
      StatusID: false,
      SubStatusID: false,
      InsurerStatusID: false,
      TATFilter: false,
      TicketNotReply: false,
      IsEsc: false,
      ProductID: false,
      SupplierID: false,
      ProductGroupID: null,
      UserGroupsSelected: [],
    }
  );

  useEffect(() => {
    // if (productGroupMaster && productGroupMaster.length === 0) {
    //   dispatch(fetchProductGroupMaster())
    // }
    if (productGroupMaster) {
      setProductListsFromAPI();
    }

  }, [productGroupMaster]);

  // useEffect(() => {
  //   if (supplierMaster_store && supplierMaster_store.length === 0) {
  //     dispatch(fetchSupplierMasterV2())
  //   }
  // }, [supplierMaster_store])

  useEffect(()=>{
    dispatch(fetchProductGroupMaster())
    dispatch(fetchSupplierMasterV2())
  },[])
  
  const setProductListsFromAPI = () => {
    let arr = getProductListFromProductGroupMasterV2(productGroupMaster);
    setProductList(arr);
    setProductListToRender(arr);
  }

  useEffect(() => {
    if (advanceSearchValues.ProductID.length > 0 && !advanceSearchValues.ProductID.includes(-1) && !advanceSearchValues.ProductID.includes(999)) {
      if (advanceSearchValues.ProductID.length >=0 && productList && !zeroLeadSelection) {
        let productGroupID = null;
        productGroupID = productList.filter(item => item.ProductId === advanceSearchValues.ProductID[0])[0]['ProductGroupId']
        let updatedArr = getProductListFromProductGroupMasterV2(productGroupMaster, productGroupID);
        setAdvanceSearchValues({ ProductGroupID: productGroupID })
        setProductListToRender(updatedArr)
      }
      else {
        let arrWithoutNoProduct = getProductListFromProductGroupMasterV2(productGroupMaster)
        arrWithoutNoProduct.splice(0, 0, { ProductName: 'NO PRODUCT', ProductId: -1, ProductGroupId: 0 })
        arrWithoutNoProduct.splice(0, 0, { ProductName: 'ALL PRODUCTS', ProductId: 999, ProductGroupId: 0 })
        setProductListToRender(arrWithoutNoProduct);
        setAdvanceSearchValues({ ProductGroupID: "" })
      }
    }
    else if (advanceSearchValues.ProductID.length > 0 && zeroLeadSelection) {
      let arrWithoutNoProduct = getProductListFromProductGroupMasterV2(productGroupMaster)
      arrWithoutNoProduct.splice(0, 0, { ProductName: 'NO PRODUCT', ProductId: -1, ProductGroupId: 0 })
      arrWithoutNoProduct.splice(0, 0, { ProductName: 'ALL PRODUCTS', ProductId: 999, ProductGroupId: 0 })
      setProductListToRender(arrWithoutNoProduct);
      setAdvanceSearchValues({ ProductGroupID: "" })
    }
    else {
      if(zeroLeadSelection){
        let arrWithoutNoProduct = getProductListFromProductGroupMasterV2(productGroupMaster)
        arrWithoutNoProduct.splice(0, 0, { ProductName: 'NO PRODUCT', ProductId: -1, ProductGroupId: 0 })
        arrWithoutNoProduct.splice(0, 0, { ProductName: 'ALL PRODUCTS', ProductId: 999, ProductGroupId: 0 })
        setProductListToRender(arrWithoutNoProduct);
      }
      else{
        setProductListToRender(getProductListFromProductGroupMasterV2(productGroupMaster))
      }
      setAdvanceSearchValues({ ProductGroupID: "" })
    }

    //for the user Group
    setAdvanceSearchValues({ UserGroupsSelected: [] });
    setUserGroupsMaster([]);
    if (advanceSearchValues.ProductID.length > 0 && !advanceSearchValues.ProductID.includes(-1) && !advanceSearchValues.ProductID.includes(999) && !zeroLeadSelection) {
      let products = advanceSearchValues.ProductID.join(',');
      let cb = (response) => {
        setUserGroupsMaster(response);
      }
      GetUserGroupMaster(products, cb)
    }
  }, [advanceSearchValues.ProductID, zeroLeadSelection, productList])


  useEffect(() => {
    // if (zeroLeadSelection) {
    //   setAdvanceSearchValues({ ProductID: [-1], ProductGroupID: 0 });
    // }
    // else {
    //   setAdvanceSearchValues({ ProductID: [], ProductGroupID: null });
    // }
    setAdvanceSearchValues({ ProductID: [], ProductGroupID: null });
  }, [zeroLeadSelection])

  useEffect(() => {
    setAdvanceSearchValues({
      STP: 0,
      TATFilter: 0,
      InsurerStatusID: 0
    })
    if (advanceSearchValues.ProductID && advanceSearchValues.ProductID.length === 1) {
      GetInsurerList(advanceSearchValues.ProductID[0]);
    }
    else {
      setInsurerList([]);
    }
  }, [advanceSearchValues.ProductGroupID])

  const GetInsurerList = (product) => {
    if (
      supplierMaster_store &&
      Array.isArray(supplierMaster_store) &&
      supplierMaster_store.length > 0
    ) {
      supplierMaster_store.map((item) => {
        let flag = 0;
        if (item.ProductID == product) {
          if (insurerList && insurerList.length > 0) {
            insurerList.forEach((singleItem) => {
              if (singleItem.SupplierID == item.SupplierID) {
                return (flag = 1);
              }
            });
          }
          if (flag === 0) {
            let obj = {
              SupplierID: item.SupplierID,
              SupplierName: item.SupplierName,
            };
            insurerList.push(obj);
          }
        }
      });
      setInsurerList(insurerList);
    }
  }


  const TatFilterArray = [
    { DisplayName: "TAT Busted", value: 1 },
    { DisplayName: "Busting Today", value: 2 },
    { DisplayName: "Busting in 2 days", value: 3 },
    { DisplayName: "Busting in 3 days", value: 4 },
    { DisplayName: "Busting above 3 days", value: 5 },
  ]

  const EscalationArray = [
    { DisplayName: "ALL", value: 1 },
  ]

  useEffect(() => {
    if (sourceMaster_store && sourceMaster_store.length === 0) {
      dispatch(fetchSourceSubSourceMasterV2())
    }

  }, [sourceMaster_store]);

  useEffect(() => {
    if (statusSubStatusMaster_store && statusSubStatusMaster_store.length === 0) {
      dispatch(fetchStatusSubStatusMasterV2())
    }
  }, [statusSubStatusMaster_store])

  // For Filtering ProductList
  useEffect(() => {
    if (sourceMaster_store) {
      getSourceSet();
    }
  }, [sourceMaster_store]);

  useEffect(() => {
    if (statusSubStatusMaster_store) {
      getTicketStatusSet();
      getInsurerStatusSet();
    }
  }, [statusSubStatusMaster_store])

  //For Filtering Source List
  const getSourceSet = () => {
    let tempSources = getUniqueSetBasedOnKey(sourceMaster_store, 'SourceID', ['SourceID', 'SourceName']);
    setSourceList(tempSources);
  }

  //for filtering status 
  const getTicketStatusSet = () => {
    let tempTicketStatus = [];
    let obj;
    statusSubStatusMaster_store.length > 0 &&
      statusSubStatusMaster_store.map((item) => {
        let flag = 0;
        if (tempTicketStatus && tempTicketStatus.length > 0) {
          tempTicketStatus.forEach((singleItem) => {
            if (singleItem.StatusID == item.StatusID) {
              return (flag = 1);
            }
          });
        }
        if (flag == 0 && item.StatusType === 1) {
          obj = {
            StatusID: item.StatusID,
            StatusName: item.StatusName,
          };
          tempTicketStatus.push(obj);
        }
      });
    setStatusList(tempTicketStatus);
  }

  //for filtering Insurer Status from StatusSubStatus Master
  const getInsurerStatusSet = () => {
    let tempTicketStatus = [];
    let obj;
    statusSubStatusMaster_store.length > 0 &&
      statusSubStatusMaster_store.map((item) => {
        let flag = 0;
        if (tempTicketStatus && tempTicketStatus.length > 0) {
          tempTicketStatus.forEach((singleItem) => {
            if (singleItem.StatusID == item.StatusID) {
              return (flag = 1);
            }
          });
        }
        if (flag === 0 && item.StatusType === 2) {
          obj = {
            StatusID: item.StatusID,
            StatusName: item.StatusName,
          };
          tempTicketStatus.push(obj);
        }
      });
    setInsurerStatusList(tempTicketStatus);
  }


  //for filtering sub status 
  const getTicketSubStatusSet = () => {
    let tempSubStatus = getSubStatusList(statusSubStatusMaster_store, 'SubStatusID', ['SubStatusID', 'SubStatusName'], advanceSearchValues.StatusID);
    setSubStatusList(tempSubStatus);
  }

  useEffect(() => {
    if (advanceSearchValues.StatusID) {
      setAdvanceSearchValues({ SubStatusID: 0 })
      getTicketSubStatusSet();
    }
  }, [advanceSearchValues.StatusID])

  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist()
    }
    const { name, value } = event.target;
    if (name === 'ProductID' && value.length > 3) {
      return Toast.error('Only 3 products can be selected')
    }
    setAdvanceSearchValues({
      [name]: value
    })
    setAdvanceSearchValuesTouched({
      [name]: true
    })
  }

  useEffect(() => {
    if (advanceSearchValues.ProductID && advanceSearchValues.ProductID.length === 1) {
      handleSupplierProductChange(advanceSearchValues.ProductID[0]);
    }
    else {
      setInsurerList([]);
    }
    setAdvanceSearchValues({ SupplierID: 0 });

  }, [advanceSearchValues.ProductID])

  const handleSupplierProductChange = (product) => {
    let insurerList = [];
    let obj;
    if (
      productSupplierMaster &&
      Array.isArray(productSupplierMaster) &&
      productSupplierMaster.length > 0
    ) {
      productSupplierMaster.map((item) => {
        let flag = 0;
        if (item.ProductID == product) {
          if (insurerList && insurerList.length > 0) {
            insurerList.forEach((singleItem) => {
              if (singleItem.SupplierID == item.SupplierID) {
                return (flag = 1);
              }
            });
          }
          if (flag === 0) {
            obj = {
              SupplierID: item.SupplierID,
              SupplierName: item.SupplierName,
            };
            insurerList.push(obj);
          }
        }
      });
      setInsurerList(insurerList);
    }
  };


  //For handling SubSource
  useEffect(() => {
    if (advanceSearchValues.Source) {
      handleSubSourceFilter(advanceSearchValues.Source);
    }
    else {
      setSubSourceList([]);
    }
    setAdvanceSearchValues({ SubSource: "" }); // on Change of Source, the value of SubSource goes blank
  }, [advanceSearchValues.Source])

  const handleSubSourceFilter = (source) => {
    let tempSubSourceList = [];
    let obj;
    if (
      sourceMaster_store &&
      Array.isArray(sourceMaster_store) &&
      sourceMaster_store.length > 0
    ) {
      sourceMaster_store.map((item) => {
        let flag = 0;
        if (item.SourceName == source) {
          if (tempSubSourceList && tempSubSourceList.length > 0) {
            tempSubSourceList.forEach((singleItem) => {
              if (singleItem.SubSourceID == item.SubSourceID) {
                return (flag = 1);
              }
            });
          }
          if (flag == 0) {
            obj = {
              SubSourceID: item.SubSourceID,
              SubSourceName: item.SubSourceName,
            };
            tempSubSourceList.push(obj);
          }
        }
      });
      setSubSourceList(tempSubSourceList);
    }
  }

  const handleTouched = (skipStatusCheck) => {
    setAdvanceSearchValuesTouched({
      FromDate: true,
      ToDate: true,
      Source: true,
      SubSource: true,
      CBRequest: true,
      STP: true,
      StatusID: skipStatusCheck ? false : true,
      SubStatusID: true,
      InsurerStatusID: true,
      TATFilter: true,
      TicketNotReply: true,
      IsEsc: true,
      ProductID: true,
      SupplierID: true,
    })
  }

  const generatePayload = () => {
    return {
      "IsUpdatedOn": advanceSearchValues.IsUpdatedOn,
      "FilterString": "",
      "FromDate": advanceSearchValues.FromDate.getTime(),
      "ToDate": advanceSearchValues.ToDate.getTime(),
      "Source": advanceSearchValues.Source,
      "SubSource": advanceSearchValues.SubSource,
      "ProductID": 0,
      "ProductIDs": (advanceSearchValues.ProductID).toString(),
      "SupplierID": advanceSearchValues.SupplierID,
      "CBRequest": advanceSearchValues.CBRequest,
      "StatusID": advanceSearchValues.StatusID,
      "SubStatusID": advanceSearchValues.SubStatusID,
      "TATFilter": advanceSearchValues.TATFilter,
      "InsurerStatusID": advanceSearchValues.InsurerStatusID,
      "STP": advanceSearchValues.STP === -1 ? 0 : advanceSearchValues.STP === 0 ? -1 : advanceSearchValues.STP,
      "IsEsc": advanceSearchValues.IsEsc,
      "UserId": userID_store,
      "Type": 'ADVANCESEARCH',
      "UserType": userType_store,
      "UserGroupID": advanceSearchValues.UserGroupsSelected.join(','),
      "ProductGroupId": advanceSearchValues.ProductGroupID || 0,
      "IsZeroLead": zeroLeadSelection ? 1 : 0,
    }
  }

  const validate = (skipStatusCheck) => {
    //when skipStatusCheck is passed it skips validation of StatusID
    handleTouched(skipStatusCheck);
    if (skipStatusCheck) {
      if (advanceSearchValues.ProductID.length === 0 ||
        advanceSearchValues.FromDate < new Date().setFullYear(new Date().getFullYear() - 2) ||
        advanceSearchValues.ToDate < advanceSearchValues.FromDate || advanceSearchValues.ToDate > new Date() || advanceSearchValues.ToDate > new Date()
        || new Date(advanceSearchValues.ToDate) > new Date(advanceSearchValues.FromDate).setDate(new Date(advanceSearchValues.FromDate).getDate() + 30)
      ) {
        return false
      }
      else return true
    }
    else if (!advanceSearchValues.StatusID || advanceSearchValues.ProductID.length === 0 ||
      advanceSearchValues.FromDate < new Date().setFullYear(new Date().getFullYear() - 2) ||
      advanceSearchValues.ToDate < advanceSearchValues.FromDate || advanceSearchValues.ToDate > new Date() || advanceSearchValues.ToDate > new Date()
      || new Date(advanceSearchValues.ToDate) > new Date(advanceSearchValues.FromDate).setDate(new Date(advanceSearchValues.FromDate).getDate() + 30)
    ) {
      return false
    }
    else return true
  }


  const handleSearch = () => {
    if (!validate()) { return }
    let url = '/GetDashboardDataV2';
    let payload = generatePayload();
    setDataLoading(true);
    let callback = (value) => {
      setDataToRender(value);
      setDataLoading(false);
    }
    fetchDashboardData_method(url, payload, callback);
  }

  const exportData = () => {
    if (!validate(true)) { return }
    let url = '/GetDashboardDataExportV2';
    let payload = generatePayload();
    setDataLoading(true);
    let cb = () => {
      console.log('here')
      return setDataLoading(false);
    }
    return exportDashboardData_method(url, payload, cb, 'AdvanceSearch_TicketList');

  }

  const clearFields = function () {
    setAdvanceSearchValues({
      IsUpdatedOn: "0",
      FromDate: new Date(),
      ToDate: new Date(),
      Source: "",
      SubSource: "",
      CBRequest: 0,
      STP: 0,
      StatusID: 0,
      SubStatusID: 0,
      InsurerStatusID: 0,
      TATFilter: 0,
      TicketNotReply: 0,
      IsEsc: 0,
      ProductID: [],
      SupplierID: 0,
      UserGroupsSelected: []
    })
  }

  const getMinDateForToDate = () => {
    //if selected From Date +30 is more than current date then by default the max date is current date
    return new Date(advanceSearchValues.FromDate).setDate(new Date(advanceSearchValues.FromDate).getDate() + 30) > new Date() ? new Date() :
      new Date(advanceSearchValues.FromDate).setDate(new Date(advanceSearchValues.FromDate).getDate() + 30)
  }

  const renderAdvanceFilterComp = (comp) => {
    let filterItems = productGroupMaster && advanceSearchValues.ProductGroupID && productGroupMaster.filter(item => item.ProductGroupId === advanceSearchValues.ProductGroupID);
    if (filterItems && filterItems.length) {
      let obj = filterItems[0]['AdvanceFilter']
      console.log('ojb-===>', filterItems)
      return obj && obj[comp] ? true : false
    }
    else return false
  }

  const handleProductSelection = (newSelectedProducts) =>{
    let noProductSelected = false;
    let allProductSelected = false;

    newSelectedProducts.forEach(item=> {
      if(item.ProductId === -1){
        noProductSelected = true;
      }
      else if(item.ProductId === 999){
        allProductSelected = true
      }
    })
    if(noProductSelected){
      setAdvanceSearchValues({ ProductID : [-1]})
    }
    else if (allProductSelected){
      setAdvanceSearchValues({ ProductID : [999]})
    }
    else setAdvanceSearchValues({ ProductID: newSelectedProducts.map(product => product.ProductId) })
  }


  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white' /></CustomBackdrop> : null}
        <div className="pbdashboard-simple-seach advance-seach pbdashboard-simple-seach-new">
          {/* <h4>Advance Search</h4> */}
          <Grid container spacing={1} className='advance-head-list'>
            <Grid item md={5} sm={6} xs={12}>
              <div classame='create-on-update-on'>
                <div className='createdon-radio'>
                  <span className='by-date'>By Date</span>
                  <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="IsUpdatedOn" defaultValue={advanceSearchValues.IsUpdatedOn} onChange={handleChange} >
                      <FormControlLabel value="createdOn" control={<Radio color="primary" value={"0"} />} label="Created On" />
                      <FormControlLabel value="updatedOn" control={<Radio color="primary" value={"1"} />} label="Updated On" />
                      <FormControlLabel value="escalatedOn" control={<Radio color="primary" value={"2"} />} label="Escalated On" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </Grid>
            <Grid item md={2} sm={3} xs={6} className='date-container'>
              <label>Start Date</label>
              <FormControl className="form-control calander-control">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline advanceSearchStartDate"
                  // label="Start Date"
                  maxDate={new Date()}
                  minDate={new Date().setFullYear(new Date().getFullYear() - 2)} //only max 2 years is allowed
                  value={advanceSearchValues.FromDate}
                  onChange={(date) => { DiffDaysBtwDates(new Date(advanceSearchValues.ToDate), date) < 30 ? setAdvanceSearchValues({ FromDate: date }) : setAdvanceSearchValues({ FromDate: date, ToDate: date }) }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2} sm={3} xs={6} className='date-container'>
              <label>End Date</label>
              <FormControl className="form-control calander-control">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline advanceSearchEndDate"
                  // label="End Date"
                  minDate={advanceSearchValues.FromDate}
                  maxDate={getMinDateForToDate()}
                  value={advanceSearchValues.ToDate}
                  onChange={(date) => setAdvanceSearchValues({ ToDate: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <ul className='advance-bottom-list advance-bottom-list-v2'>
            <div className='first-row'>
              <li className="calander-list">
                <FormControl className="form-control calander-control"
                  error={advanceSearchValues.ProductID.length === 0 && advanceSearchValuesTouched.ProductID}>
                  <Autocomplete
                    options={productListToRender}
                    fullWidth
                    className="single-select"
                    name="ProductID"
                    id="admin-tat-summary_v2"
                    disableCloseOnSelect
                    getOptionLabel={(item) => item.ProductName}
                    value={productListToRender.filter(item => advanceSearchValues.ProductID.includes(item.ProductId))}
                    onChange={(e, newSelectedProducts) => handleProductSelection(newSelectedProducts)}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          className='autocomplete-checkbox'
                          checked={advanceSearchValues.ProductID && advanceSearchValues.ProductID.includes(option.ProductId) ? true : false}
                        />
                        <span className='product-names'>{option.ProductName}</span>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Products"
                      />
                    )}
                    multiple // Enable multi-select
                  />
                  {advanceSearchValues.ProductID.length === 0 && advanceSearchValuesTouched.ProductID ? <FormHelperText className='error-msg'>Please select product(s)</FormHelperText> :
                  advanceSearchValues.ProductID && advanceSearchValues.ProductID.length > 0 ? <span className='info-select-product'>{advanceSearchValues.ProductID.length} Selected</span> : ''}
                </FormControl>
              </li>
              {!zeroLeadSelection && <li className='calander-list'>
                <FormControl className="form-control" >
                  <Autocomplete
                    options={userGroupsMaster}
                    fullWidth
                    className="single-select"
                    name="UserGroup"
                    id="admin-tat-summary_v2"
                    disableCloseOnSelect
                    disabled={zeroLeadSelection}
                    getOptionLabel={(item) => item.GroupName}
                    value={userGroupsMaster.filter(item => advanceSearchValues.UserGroupsSelected.includes(item.GroupId))}
                    onChange={(e, newSelectedProducts) => setAdvanceSearchValues({ UserGroupsSelected: newSelectedProducts.map(UG => UG.GroupId) })}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={advanceSearchValues.UserGroupsSelected.includes(option.GroupId) ? true : false}
                        />
                        {option.GroupName}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select User Group"
                        disabled={zeroLeadSelection}
                      />
                    )}
                    multiple // Enable multi-select
                  />
                  {advanceSearchValues.UserGroupsSelected && advanceSearchValues.UserGroupsSelected.length > 0 ? <FormHelperText className='info-select-product'>{advanceSearchValues.UserGroupsSelected.length} User group(s) selected</FormHelperText> : ''}
                </FormControl>
              </li>
              } 
            </div>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Select Source
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchSource"
                  value={advanceSearchValues.Source}
                  onChange={handleChange}
                  name="Source"
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {sourceList.map(item => {
                    return <MenuItem value={item.SourceName} style={{ textTransform: 'capitalize' }}>{item.SourceName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Select Sub Source
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchSubSource"
                  value={advanceSearchValues.SubSource}
                  onChange={handleChange}
                  name="SubSource"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {subSourceList.map(item => {
                    return <MenuItem value={item.SubSourceName}>{item.SubSourceName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control"
                error={!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID}>
                <InputLabel id="demo-customized-select-label">
                  Select Status
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchStatusID"
                  value={advanceSearchValues.StatusID}
                  onChange={handleChange}
                  name="StatusID"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {statusList && statusList.length > 0 && statusList.map(item => {
                    return <MenuItem value={item.StatusID}>{item.StatusName}</MenuItem>
                  })}
                </Select>
                {!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID && <FormHelperText className='error-msg'>Please select Status</FormHelperText>}
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control"
                error={!advanceSearchValues.SubStatusID && advanceSearchValuesTouched.SubStatusID}>
                <InputLabel id="demo-customized-select-label">
                  Select Sub Status
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchSubStatusID"
                  value={advanceSearchValues.SubStatusID}
                  onChange={handleChange}
                  name="SubStatusID"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {subStatusList && subStatusList.length > 0 && subStatusList.map(item => {
                    return item.SubStatusName && <MenuItem value={item.SubStatusID}>{item.SubStatusName}</MenuItem>
                  })}
                </Select>
                {/* {!advanceSearchValues.StatusID && advanceSearchValuesTouched.StatusID && <FormHelperText className='error-msg'>Please select Status</FormHelperText>} */}
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Select Insurer
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchSupplierID"
                  value={advanceSearchValues.SupplierID}
                  onChange={handleChange}
                  name="SupplierID"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {insurerList && insurerList.length > 0 && insurerList.map(item => {
                    return <MenuItem key={`product+${item.SupplierID}`} value={item.SupplierID}>{item.SupplierName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Escalation
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchIsEsc"
                  value={advanceSearchValues.IsEsc}
                  onChange={handleChange}
                  name="IsEsc"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {EscalationArray.map(item => {
                    return <MenuItem value={item.value}>{item.DisplayName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            {renderAdvanceFilterComp('TATFilter') && <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  TAT Filter
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchTATFilter"
                  value={advanceSearchValues.TATFilter}
                  onChange={handleChange}
                  name="TATFilter"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {TatFilterArray && TatFilterArray.map(item => {
                    return <MenuItem value={item.value}>{item.DisplayName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            }
            {renderAdvanceFilterComp('STPFilter') && <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  STP Filter
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchSTP"
                  value={advanceSearchValues.STP}
                  onChange={handleChange}
                  name="STP"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  <MenuItem value={1}>STP</MenuItem>
                  <MenuItem value={-1}>NSTP</MenuItem>
                </Select>
              </FormControl>
            </li>
            }

            {renderAdvanceFilterComp('InsurerStatusFilter') && <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Insurer Status
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchInsurerStatusID"
                  value={advanceSearchValues.InsurerStatusID}
                  onChange={handleChange}
                  name="InsurerStatusID"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {insurerStatusList.map(item => {
                    return <MenuItem value={item.StatusID}>{item.StatusName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </li>
            }


            {/* <li className="calander-list">
              <FormControl className="form-control calander-control">
                <InputLabel id="demo-customized-select-label">
                  Ticket Not Reply
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select advanceSearchTicketNotReply"
                  value={advanceSearchValues.TicketNotReply}
                  onChange={handleChange}
                  name="TicketNotReply"
                >
                  <MenuItem value={0}>Select</MenuItem>
                  <MenuItem value={1}>1 Day(s)</MenuItem>
                  <MenuItem value={2}>2 Day(s)</MenuItem>
                  <MenuItem value={3}>3 Day(s)</MenuItem>
                  <MenuItem value={4}>3+ Day(s)</MenuItem>
                </Select>
              </FormControl>
            </li> */}
            <div className='new-dashboard-btn'>
              <li>
                <Button id="advanceSearchBtn" className="export-btn" onClick={handleSearch}>Search</Button>
                <Button id="advanceSearchExportBtn" className="export-btn" onClick={exportData}>Export</Button>
                <Button id="advanceSearchClearAll" className="clear-btn" onClick={clearFields}>Clear All</Button>
              </li>
            </div>
          </ul>
          {dataToRender && Array.isArray(dataToRender) && <AdminDashboardData dataToRender={dataToRender} />}
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default AdminAdvanceSearchV2
