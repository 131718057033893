import React from 'react';
import * as Grammarly from "@grammarly/editor-sdk";
import { GRAMMARLY_CLIENT_ID } from '../../Config';

function WrapWithGrammarly(props) {
    Grammarly.init(GRAMMARLY_CLIENT_ID);

    return (
        <grammarly-editor-plugin>
            {props.children}
        </grammarly-editor-plugin>
    )
}

export default WrapWithGrammarly