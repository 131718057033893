import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';

export default {
  success: function (msg) {
    this.toast(msg, 'success');
  },
  error: function (msg) {
    this.toast(msg, 'error');
  },
  warning: function (msg) {
    this.toast(msg, 'warning');
  },
  toast: function (msg, variant) {
    const Display = withSnackbar(({ message, enqueueSnackbar, closeSnackbar }) => {
      document.addEventListener('mousedown', closeSnackbarOnClick);
      document.addEventListener('touchstart', closeSnackbarOnClick);
  
      function closeSnackbarOnClick() {
        closeSnackbar();
        document.removeEventListener('mousedown', closeSnackbarOnClick);
        document.removeEventListener('touchstart', closeSnackbarOnClick);
      }
      
      enqueueSnackbar(message, { variant, onClick: () => {closeSnackbar()}});
      return null;
    });
    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
      <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'right', vertical: 'top'}}>
        <Display message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint)
  }
}