import { useState, React } from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { connect, useDispatch, useSelector } from "react-redux";
import DialogFromRight from '../../../shared/components/DialogFromRight';
import { setDashboardOption, setZeroLeadSelection } from '../../../redux/actions/pbDashboard_action';
import AdminSummaryV2 from './Components/AdminSummaryV2';
import AdminAdvanceSearchV2 from './Components/AdminAdvanceSearchV2';
import AdminSearchOptionsV2 from './Components/AdminSearchOptionsV2'
import AdminTATSummaryV2 from './Components/AdminTatSummaryV2';
import AdminSimpleSearchV2 from './Components/AdminSimpleSearchV2';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
  },
}))

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 27,
    margin: "8px",
    padding: "2px 0 2px 1px",
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const PB_ADMIN_DASHBOARD_V2 = (props) => {
  const dispatch = useDispatch();
  const { dashboardOption_store, setDashboardOptionSelected } = props;
  const [dialogWithControls, setDialogWithControls] = useState();
  const zeroLeadSelection = useSelector(state => state.pbDashboard.zeroLeadSelected || false);

  return (
    <>
      <div className="dashboard-box-main">
        <div className="dashboard-box-inner">
          <div className='no-booking-box'>
            <ul>
              <li>
                <p>0 LeadID</p>
              </li>
              <li>
                <FormControlLabel
                  control={<IOSSwitch disabled={["1", "4"].includes(dashboardOption_store)} name="checkedB" checked={!["1", "4"].includes(dashboardOption_store) && zeroLeadSelection} onChange={(e) => dispatch(setZeroLeadSelection(e.target.checked))} />}
                />
              </li>
            </ul>
          </div>
          {/* -----to be added-------- */}
          {/* ---------------- */}
          <AdminSearchOptionsV2 option_store={dashboardOption_store} setDashboardOption_store={setDashboardOptionSelected} />
          {
            dashboardOption_store === "1" && <AdminSimpleSearchV2 />
          }

          {
            dashboardOption_store === "2" && <AdminAdvanceSearchV2 />
          }
          {
            dashboardOption_store === "3" && <AdminSummaryV2 />
          }
          {
            dashboardOption_store === "4" && <AdminTATSummaryV2 />
          }

        </div>
      </div>
      {dialogWithControls && dialogWithControls.open &&
        <DialogFromRight
          open={dialogWithControls.open}
          handleClose={dialogWithControls.handleClose}
          title={dialogWithControls.title}
          children={dialogWithControls.children}
          size={dialogWithControls.size}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboardOption_store: state && state.pbDashboard && state.pbDashboard.optionSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDashboardOptionSelected: (payload) => dispatch(setDashboardOption(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PB_ADMIN_DASHBOARD_V2)