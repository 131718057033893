import React, { useState, useEffect } from "react";
import { GET, POST, old_ticket_base_url } from "../services/apiServices";
import { GetAPIUIDMasterData, GetLogsByUID } from "../constants/apiUrls";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { authLogin } from "../redux/actions/auth";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {
  TextareaAutosize,
  Grid,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Button,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core";

export default function LogPage({}) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(window.location.href);
  const history = useHistory();
  let str;
  let landingpage = "landingpage/";
  if (url.toLowerCase().includes(landingpage)) {
    let index1 = url.toLowerCase().indexOf(landingpage);
    str = url.substring(index1, index1 + landingpage.length);
  }

  const encryptedParam = url.split(str)[1];
  const auth = useSelector((state) => state.auth.auth);
  const [APIMasterData, setAPIMasterData] = useState(null);
  const [selectedAPI, setSelectedAPI] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [logData, setLogData] = useState(null);

  useEffect(() => {
    GET(GetAPIUIDMasterData)
      .then((response) => {
        if (response.ErrorCode == 0) {
          setAPIMasterData(response.Data.sort((a, b) => a.APIName.localeCompare(b.APIName)));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    setSelectedAPI(event.target.value);
  };

  const handleInputChange = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    GET(`${GetLogsByUID}/${selectedAPI.APIName}/${inputValue}`)
      .then((response) => {
        if (response.ErrorCode == 0) {
          setLogData(response.Data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="app-logs">
      <h2>Application Logs</h2>
      <>
        <Grid container spacing={4}>
          <Grid item md={3} sm={4} xs={12}>
            {APIMasterData?.length ? (
              <FormControl className="form-control">
                <InputLabel id="demo-customized-select-label">
                  Select Api
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  placeholder="Select"
                  value={selectedAPI}
                  fullWidth
                  label="Choose API Name"
                  onChange={handleChange}
                >
                  {APIMasterData.map((item, i) => {
                    return (
                      <MenuItem value={item} key={i}>
                        {item.APIName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          {selectedAPI?.UID ? (
            <>
              <Grid item md={3} sm={4} xs={12}>
                <FormControl className="form-control">
                  <TextField
                    id="input-with-icon-textfield"
                    label={selectedAPI.UID}
                    name={selectedAPI.UID}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={3} xs={12}>
                <Button className="go-btn" type="submit" onClick={handleSubmit}>
                  Go
                </Button>
              </Grid>
            </>
          ) : null}
        </Grid>
        <div>
          {logData && logData.length > 0 ? (
            <div className="app-log-list scrool-toll">
              <div className="app-log-listing">
                {logData
                  .sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))
                  .map((i, index) => {
                    return (
                      <ul>
                        <li>
                          <span>UID</span>
                          <strong>{i.UID}</strong>
                        </li>
                        <li>
                          <span>API Name</span>
                          <strong>{i.APIName}</strong>
                        </li>
                        <li>
                          <span>Date Time</span>
                          <strong>{i.DateTime}</strong>
                      </li>
                      <li>
                          <span>Start Time</span>
                          <strong>{i.startTime}</strong>
                      </li>
                      <li>
                          <span>End Time</span>
                          <strong>{i.endTime}</strong>
                      </li>
                      <li>
                        <span>Error</span>
                        <strong>{i.Error}</strong>
                      </li>
                      <div>
                        <li>
                          <span>Request</span>
                          <strong>{JSON.stringify(i.Request)}</strong>
                        </li>
                        <li>
                          <span>Response</span>
                          <strong>{JSON.stringify(i.Response)}</strong>
                        </li> 
                      </div>
                      {/* <li>
                        <span>Logs</span>
                        <strong>{i.Log}</strong>
                      </li> */}
                      {/* <li>
                        <span>Request</span>
                        <strong>{i.Request}</strong>
                      </li>
                      <li>
                        <span>Response</span>
                        <strong>{i.Response}</strong>
                      </li> */}
										</ul>
                  );
                })}
                </div>
            </div>
          ) : null}
        </div>
      </>
    </div>
  );
}
