import React from 'react'
import CachedIcon from '@material-ui/icons/Cached';
import ErrorIcon from '@material-ui/icons/Error';
import { Grid, Tooltip } from '@material-ui/core';
import { VERSION } from '../../Config';

class ChildErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            error: { message: '', stack: '' },
            info: { componentStack: '' },
            componentName: this.props.componentName

        };
    }


    static getDerivedStateFromError = error => {
        return { hasError: true };
    };

    componentDidCatch = (error, info) => {
        this.setState({ error, info });
        console.log('info', info)
    };

    render() {
        const { hasError, error, info } = this.state;
        const { children } = this.props;

        return hasError ?
            <div className="child-error-msg">
                <Grid container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Grid item md={2} xs={3} alignItems="center">
                        <Tooltip title={this.state.error} placement="top-start">
                            <ErrorIcon />
                        </Tooltip>
                    </Grid>
                    <Grid item md={10} xs={9}>
                        <p>
                            Whoops! <span style={{fontSize: '8px', color: 'rgb(0, 119, 255'}}>Version : {VERSION}</span>
                        </p>
                        <p>
                            Something went wrong for <b><i>{this.state.componentName}</i></b> component
                        </p>
                        <span
                            style={{ cursor: 'pointer', color: '#0077FF' }}
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Reload Page <CachedIcon />
                        </span>{' '}
                    </Grid>
                </Grid>
            </div> : children;
    }
}

export default ChildErrorBoundary