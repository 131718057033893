import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link,Button,Menu,MenuItem} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { STORAGE } from "../../../shared/methods/methods";
import { useDispatch  } from "react-redux";
import { userLogout } from "../../../redux/actions/auth";
import { connect } from 'react-redux'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function UserProfile({fetchedUserDetails}) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const history = useHistory();
  const [userDetails, setUserDetails] = useState({});

  useEffect(()=>{
    if(fetchedUserDetails && Object.keys(fetchedUserDetails).length>0){
      setUserDetails(fetchedUserDetails)
    }
  },[fetchedUserDetails])

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   useEffect(() => {
  //     setUserDetails(JSON.parse(localStorage.getItem("UserDetails")))
  //   }, [])

  const logout = () => {
    dispatch(userLogout())
    STORAGE.deleteAuth().then(()=>{
      history.push("/InsurerLogin");
    })
  };


  return (
    <div className="profile-box">
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {userDetails?.Name} <i className="fa fa-user"></i>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="user-toggle"
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) =>{
  return{
    fetchedUserDetails : state && state.auth && state.auth.userInfo ? state.auth.userInfo : {}
  }
}

export default connect(mapStateToProps, null)(UserProfile)
