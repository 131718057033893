import React, {useEffect, useState,useContext} from 'react';
import { List, RadioGroup,Grid,Button} from '@material-ui/core';
import SelectAgentOption from './SelectAgentOption';
import { GET, POST } from '../../../../services/apiServices';
import {
  UpdateTicketAssignment,GetTicketDetailsByTicketID
} from '../../../../constants/apiUrls';
import { useSnackbar } from 'notistack';
import TicketContext from '../../../../TicketContext';
import { useSelector } from 'react-redux';

const SelectAgentList = ({
  agentsList,
  setAssignToDialogOpen,
  setState,
  setTouched,
  setAgentsList,
  RefObjectId,
  TicketDetailsID,
  setFormValues,
  isAssignToDialogOpen
}) => {
  const ActiveTicket_store = useSelector(state => state.ticketdetails?.selectedTicketID)
  const [value, setValue] = useState(null);
  const [touchedAssign, setTouchedAssign] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ticketContext = useContext(TicketContext);

  useEffect(() => {
    if(!isAssignToDialogOpen){
      clearAll();
    }
  }, [isAssignToDialogOpen])

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const clearAll = () => {
    setState({
      agentType: 0,
      group: 0,
      product: 0,
      empId: null,
    })
    setTouched({
      agentType: false,
      group: false,
      product: false
    })
    setAgentsList([])
    setAssignToDialogOpen(false)
  }

  const validate = () => {
    if(hasError('value')){
      return false
    }
    return true
  }

  const hasError = (field) => {
    let error;
    if(field == 'value' && !value){
      error = 'Choose any 1 option'
    }
    return error
  }

  const GetTicketDetailsByTicketIdApi = (RefObjectId, TicketDetailsID) => {
    GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest ,  IsInsurerBucket} = response.Data;

          let callbackVal;
          if (IsCallBackRequest === "0") {
            callbackVal = "No";
          } else if (IsCallBackRequest === "1") {
            callbackVal = "Yes";
          } else if (IsCallBackRequest === "2") {
            callbackVal = "Completed";
          }

          setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            originalLeadID: response.Data.LeadID,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: ''
          }));
          ticketContext.setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: ''
          }));
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log(err);
      });
  };
  const assignTicket = () => {
    setTouchedAssign(true)
    if(!validate()) return
    const payload = {
      TicketIDs: [{TicketId : ActiveTicket_store}],
      AssignTo : value.split('-')[0],
      AssignToEmp : value.split('-')[1]
    }
    POST(UpdateTicketAssignment, payload)
      .then((response) => {
        setTouchedAssign(false)
        if(response.Data == 1){
          clearAll()
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        if(response.ErrorCode == 0){
          // GetTicketDetailsByTicketIdApi(RefObjectId,TicketDetailsID);
          // ticketContext.GetTicketLogsApi();
          enqueueSnackbar(response.Message, {
            variant: "success",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        else if(response.ErrorCode==1){
          enqueueSnackbar(response.Message, {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        else{
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
      })
      .catch(err=>{
        console.log(err);
      })
  }  

  return (
    agentsList && agentsList.length ?
    <>
     <h2>SELECT AGENT</h2>
     <Grid container spacing={1} className="border-bottom">
        <Grid item md={12} xs={12}>
          <form>
            <List>
              <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                {
                  agentsList && agentsList.length && agentsList.map((i) => <SelectAgentOption value={`${i.AgentID}-${i.AgentEmpID}`}  label={i.AgentName}/>)
                }
              </RadioGroup>
            </List>
          </form>
          <Button onClick={assignTicket} className="search-btn">Assign Ticket</Button>
          {
            hasError('value') && touchedAssign ?
            <div style={{color: "red"}}>
              {
                hasError('value')
              }
            </div>
            : null
          }
        </Grid>
      </Grid>
    </>
    : <h2>NO DATA AVAILABLE</h2>
  );
}

export default SelectAgentList;