import React, { createContext } from "react";

const TicketContext = createContext({
  GetTicketLogsApi: () => {},
  setActiveTicketId: () => {},
  GetFilterCountApi: () => {},
  setFormValues: () => {},
  setoldTicketDetails: () => {},
  setAllTicketsLoading:()=> {},
  setAllTickets:()=>{},
  activeTicketId: "",
  allSubStatusOptions: [],
  GetTicketCommApi: () => {},
  ticketCommData: [],
  suppliersList: [],
  setIsProductValueChanged: ()=>{},
  isActionsAllowed: true,
  AccessTypeVal: '',
  sourceForCreateTicket: null,
  allTickets: null,
  leadDetails: null,
  GetTicketDetailsByTicketIdApi:() =>{}
});

export default TicketContext;
