export const GetTicketSummary = "GetTicketSummary";
export const GetLeadDetails = "GetLeadDetails";
export const GetTicketDetails = "GetTicketDetails";
export const GetIssueTypeByLeadId = "GetIssueTypeByLeadId";
export const GetTicketDetailsByTicketID = "GetTicketDetailsByTicketID";
export const UpdateTicketDetails = "UpdateTicketDetails";
export const UpdateTicketInsurerStatus = "UpdateTicketInsurerStatus";
export const GetTemplates = "GetTemplates";
export const UpdateTicketRemarks = "UpdateTicketRemarks";
export const UpdateAutoCommStatus = "UpdateAutoCommStatus";
export const GetTicketComm = "GetTicketComm";
export const GetTicketLog = "GetTicketLog";
export const GetHTMLTemplate = "GetHTMLTemplate";
export const GetIssueSubIssueAndLeadDetails = "GetIssueSubIssueAndLeadDetails";
export const getRedAmberResponse = "getRedAmberResponse";
export const CreateTicketForCare = "CreateTicketForCare";
export const GetTicketFilterCount = "GetTicketFilterCount";
export const UpdateL0ToL1 = "UpdateL0ToL1";
export const GetGroupByProduct_AgentType = "GetGroupList";
export const GetDialerAgentList = "GetDialerAgentList";
export const UpdateInsurerTicketAssignment = "UpdateInsurerTicketAssignment";
export const Login = "Login";
export const EncryptDecryptCipherTextToPlainText =
  "EncryptDecryptCipherTextToPlainText";
export const GetEncryptedUserData = "GetEncryptedUserData";
export const UpdateInsurerView = "UpdateInsurerView";
export const DownloadAll = "DownloadAll";
export const GetTicketTicketEscLog = "GetTicketTicketEscLog";
export const InitiatePGRefund = "InitiatePGRefund";
export const InitiatePGRefundV2="InitiatePGRefundV2";
export const GetSMEEmployees = "GetSMEEmployees";
export const GetSMEDetails = "GetSMEDetails";
export const UpdateSMEDetails = "UpdateSMEDetails";
export const GetTicketCount = "GetMyDashboardCount";
export const GetTicketData = "GetMyDashboardData";
export const GetTicketDataExport = "GetMyDashboardDataExport";
export const GetEmailPDF = "GetEmailPDF";
export const AuthLogin = "AuthLogin";
export const GetTemplates_V2 = "GetTemplates_V2";
export const GetHtmlTemplate_V2 = "GetHtmlTemplate_V2";
export const GetDashboardForOtherData = "GetDashboardForOtherData";
export const GetDashboardForOtherDataExport = "GetDashboardForOtherDataExport";
export const GetSMEDetail = "GetSMEDetail";
export const GetAPIUIDMasterData = "GetAPIUIDMasterData";
export const GetLogsByUID = "GetLogsByUID";
export const GetPolicyLink = "GetPolicyLink";
export const GetInsurerDataForSummaryTab = "GetInsurerDataForSummaryTab";
export const GetTicketListForInsurerAdmin = "GetTicketListForInsurerAdmin";
export const GetTicketListForInsurerAgent = "GetTicketListForInsurerAgent";
export const GetDashboardCountForInsurerAdmin =
  "GetDashboardCountForInsurerAdmin";
export const GetDashboardCountForInsurerAgent =
  "GetDashboardCountForInsurerAgent";
export const ExportTicketListForInsurerAgent =
  "ExportTicketListForInsurerAgent";
export const ExportTicketListForInsurerAdmin =
  "ExportTicketListForInsurerAdmin";
export const LoginForWecare = "LoginForWecare";
export const GetTicketListForInsurerSummary = "GetTicketListForInsurerSummary";
export const GetAllUserForInsurerSummary = "GetAllUserForInsurerSummary";
export const GetAllAgentsForWeCare = "GetAllAgentsForWeCare";
export const CreateUpdateInsurerUserDetails = "CreateUpdateInsurerUserDetails";
export const GetStatusMasterData = "GetStatusMasterData";
export const ExportTicketListForInsurerSummary =
  "ExportTicketListForInsurerSummary";
export const GetCustomerCallingDetails = "GetCustomerCallingDetails";
export const GetWhatsappLinkForPOSP = "GetWhatsappLinkForPOSP"
export const UpdateTicketAssignment = "UpdateTicketAssignment"
export const UpdateInsurerUserDetails = "UpdateInsurerUserDetails";
// export const GetStatusMasterData = "GetStatusMasterData";
export const CreateUpdateDeleteAPIUIDMapping = "CreateUpdateDeleteAPIUIDMapping";
export const CreateUpdateDeleteRMMapping = "CreateUpdateDeleteRMMapping";
export const GetRMMappingMasterData = "GetRMMappingMasterData";
export const ToggleRefundStatusOfTicket = "ToggleRefundStatusOfTicket"
// export const GetEndorsementDetailsByEndorsementID = "GetEndorsementDetailsByEndorsementID";
// export const GetEndorsementFieldData = "GetEndorsementFieldData";
export const GetEndorsementDetails = "GetEndorsementDetails";
export const CheckSMEEmployeeDetails = "CheckSMEEmployeeDetails";
export const CreateTicketEscalation = "CreateTicketEscalation";
export const CheckRefundEligibility = "CheckRefundEligibility";
export const UpdateSubIssueOnRefundReject = "UpdateSubIssueOnRefundReject";
export const GetRefundDetails = "GetRefundDetails";
export const GetChatDetails = "GetChatDetails";
export const GetChatDetailsV2 = "GetChatDetailsV2";
export const MergeTickets="MergeTickets"
export const GetDocURL = "GetDocURLEnc";
export const GetIssueSubIssueAndLeadDetailsForWeCare = "GetIssueSubIssueAndLeadDetailsForWeCare";
export const CreateTicketForWeCare = "CreateTicketForWeCare";
export const GetEndorsementSystemUrl = "GetEndorsementSystemUrl";