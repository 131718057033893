import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../redux/actions/auth';
import { STORAGE } from '../methods/methods';
// import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
    closeButton: {
        position: 'absolute',
        right: 0,
    },
})
)

export default function LogoutDialog(props) {
    const { open, handleClose } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    // const history = useHistory();

    const handleLogout = () =>{
        dispatch(userLogout());
        STORAGE.deleteAuth().then(()=>{
            localStorage.clear();
            window.location.reload();
        })
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='logout-box'
            >
                <DialogTitle id="alert-dialog-title">{"Logout Confirmation"}</DialogTitle>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel-btn' autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleLogout} className='submit-btn' >
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}