import React, { useState, useEffect, useContext } from "react";
import { Button, Link } from "@material-ui/core";
import Stepper from "./Components/SteperBar";
import CommunicationBox from "../CommunicationBox/CommunicationBox";
import TicketContext from "../../TicketContext";
import { Link as RouterLink } from "react-router-dom";
import { isEndorsementEligible, VERSION } from "../../Config";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/ticketDetails";

import { GET, POST } from "../../services/apiServices";
import {
  GetTicketSummary,
  GetLeadDetails,
  GetTicketDetailsByTicketID,
  GetTicketLog,
  getRedAmberResponse,
  GetTicketFilterCount,
  GetIssueTypeByLeadId,
  GetTicketDetails,
} from "../../constants/apiUrls";
import TicketCard from "./Components/TicketCard";
import Spinner from "../../shared/components/Spinner";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory } from "react-router-dom";
import { SME } from "../../Config";
import ChildErrorBoundary from "../../shared/components/ChildErrorBoundary";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { setBucketDetails } from "../../redux/actions/auth";
import { CustomCopyToClipboard } from "../../shared/components/CustomCopyToClipboard";
import { openNewView } from "../../shared/methods/methods";
import { Toast } from "../../shared/components";
import { handleCatch } from "../../shared/methods/apiMethods";

const TicketView = ({
  setFormValues,
  setTicketLogs,
  setIsLogsLoading,
  setEsclationInfo,
  setFilterCounts,
  allTickets,
  isAllTicketsLoading,
  setIsEsclationLoading,
  setIsTicketDetailEditable,
  selectFormOptions,
  setSelectFormOptions,
  setSourceList,
  setMasters,
  setEsclationLevelTime,
  oldTicketDetails,
  Responsibility,
  setEndorsementLink,
  setErrors,
  viewOnly,
  refresh,
  viewtype,
  setResetTab,
  ProductID,
  RootCauses,
  setRootCauses,
  leadDetails,
  setLeadDetails,
  onSetOldTicketDetails,
  IsReadOnlyUser,
  ...props
}) => {
  let { ref_object_id, ticket_details_id } = useParams();
  const {
    isOneTicket_store,
    bucketDetails_store,
    setBucketDetails_store,
    productID_store,
    groupID_store,
    userID_store,
    currentTicketID_store,
    setTicketLoading_store,
  } = props;
  const dispatch = useDispatch();
  const forceRefreshAllTickets = useSelector(state=> state && state.Utility && state.Utility.refreshAnyThing && state.Utility.refreshAnyThing.AllTickets || 0)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isShowLess, setIsShowLess] = useState(true);
  const [isLeadDetailsLoading, setIsLeadDetailsLoading] = useState(false);

  const ticketContext = useContext(TicketContext);

  const history = useHistory();

  const toggleShowDetail = () => {
    setIsShowLess(!isShowLess);
  };

  const showErrMsg = (msg) => {
    enqueueSnackbar(msg, {
      variant: "error",
      onClick: () => {
        closeSnackbar();
      },
    });
  };

  useEffect(() => {
    if (oldTicketDetails && leadDetails && oldTicketDetails.LeadID !== leadDetails.LeadID) {
      getLeadDeatils(oldTicketDetails.LeadID);
    }
  }, [oldTicketDetails.LeadID]);

  //Function call on Next Button click if user is configured for IsOneTicket
  const PullNextTicket = () => {
    let url = "/GetNextTicket";
    let payload = {
      UserId: userID_store,
      GroupId: groupID_store,
      CurrentTicketId: currentTicketID_store,
      BucketCode: bucketDetails_store?.BkCode || "",
    };
    POST(url, payload)
      .then((res) => {
        if (res && res["ErrorCode"] == 0 && res["Data"]) {
          console.log("response--->", res);
          let { TicketDetailsId, RefObjectId, BucketDetails, ProductID, SupplierId, InvestmentTypeID } =
            res.Data;
          let storeBucketDetails = Promise.resolve(
            setBucketDetails_store(BucketDetails)
          );
          let baseURL;
          isEndorsementEligible(ProductID, SupplierId, InvestmentTypeID) && openNewView(ProductID)
            ? (baseURL = "ticketdetails")
            : (baseURL = "alltickets");
          let historyPush = Promise.resolve(
            history.push(`/${baseURL}/${RefObjectId}/${TicketDetailsId}/0`)
          );
          Promise.all([storeBucketDetails, historyPush]).then(() => {
            window.location.reload();
          });
        } else {
          showErrMsg(res["Response"] || "Some error occured!");
        }
      })
      .catch((err) => {
        if (err && err.response) {
          showErrMsg(err.response.data.message, "error");
        } else showErrMsg(err.message || "Some error occured!");
      });
  };

  const GetTicketLogsApi = (ticketId) => {
    setIsLogsLoading(true);
    GET(`${GetTicketLog}/${ticketId}/0`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const d = response.Data.sort(
            (a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn)
          ).map((item) => ({
            ...item,
            DisplayDate: new Date(item.CreatedOn).getTime(),
            type: "log",
          }));
          setTicketLogs(d);
        } else {
          Toast.error(response['Message'] || 'Some error occurred while processing!')
        }
        setIsLogsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLogsLoading(false);
      });
  };

  const GetFilterCountApi = (ticketId) => {
    GET(`${GetTicketFilterCount}/${ticketId}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setFilterCounts(response.Data);
        } else {
          Toast.error(response['Message'] || 'Some error occurred while processing!')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRedAmberResponseapi = (ticketId) => {
    setIsEsclationLoading(true);
    GET(`${getRedAmberResponse}/${ticketId}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setEsclationInfo(response.Data);
          setEsclationLevelTime({
            L0: response.Data.L0_Green,
            L1: response.Data.L1_Amber,
            L2: response.Data.L2_Red,
          });
        } else {
         Toast.error(response['Message'] || 'Some error occurred!')
        }
        setIsEsclationLoading(false);
      })
      .catch((err) => {
        setIsEsclationLoading(false);
        handleCatch(err)
      });
  };

  const GetIssueTypeByLeadIdApi = (leadId, productId, ticketSource) => {
    let param = 1;
    if(ticketSource === "PB Insurer")
    {
      param = 6
    }
    GET(`${GetIssueTypeByLeadId}/${leadId}/${param}/${productId}`)
      .then((res) => {
        if (res.ErrorCode === 0) {
          const issueList = res.Data.filter((item) => item.ParentID === 0);
          //new
          const isStatusOptionExist = (statusOptions, item) => {
            for (let i = 0; i < statusOptions.length; i++) {
              if (statusOptions[i].StatusID === item.StatusID) {
                return true;
              }
            }
            return false;
          };
          GET(`${GetTicketDetails}/${productId}`)
            .then((response) => {
              if (response.ErrorCode === 0) {
                const {
                  statusCollections,
                  callbackTypeMasterResponses,
                  ResponsibilityMasterData,
                  ClassificationMasterData,
                  RootCauseMasterData,
                  suppliersList,
                  ticketSources,
                } = response.Data;
                let statusOptions = [];
                statusCollections.forEach((item) => {
                  if (
                    item.StatusType === 1 &&
                    item.StatusID !== 4 &&
                    !isStatusOptionExist(statusOptions, item)
                  ) {
                    statusOptions.push(item);
                  }
                });

                let subStatusOptions = statusCollections;
                if (productId != SME) {
                  subStatusOptions = statusCollections.filter(
                    (i) => ![20, 24].includes(i.SubStatusID)
                  );
                }

                setSourceList(ticketSources);
                const productList = [
                  ...new Map(
                    suppliersList.map((item) => [item["ProductID"], item])
                  ).values(),
                ];
                setMasters({
                  agentTypeList: ResponsibilityMasterData,
                  productList,
                });
                setSelectFormOptions({
                  ...selectFormOptions,
                  statusOptions: statusOptions,
                  allSubStatusOptions: subStatusOptions,
                  callbackOptions: callbackTypeMasterResponses,
                  responsibilityOptions: ResponsibilityMasterData,
                  classificationOptions: ClassificationMasterData,
                  rootCauseOptions: RootCauseMasterData,
                  issueOptions: issueList,
                  allIssueOptions: res.Data,
                  suppliersList: suppliersList,
                  productsList: productList,
                });
              }
            else Toast.error(response['Message'] || 'Some error occurred!')
            })
            .catch((err) => {
              handleCatch(err)
              console.log(err);
            });
          //end
        } else {
          showErrMsg("Something went wrong!");
        }
      })
      .catch((err) => {
        showErrMsg("Something went wrong!");
        console.log(err);
      });
  };

  const GetTicketDetailsByTicketIdApi = (RefObjectId, TicketDetailsID) => {
    setTicketLoading_store(true);
    if (RefObjectId && TicketDetailsID) {
       GET(`${GetTicketDetailsByTicketID}/${RefObjectId}/${TicketDetailsID}`)
        .then((response) => {
          setTicketLoading_store(false);
          if (response.ErrorCode === 0) {
            onSetOldTicketDetails(response.Data);
            const {
              IsCallBackRequest,
              IsInsurerBucket,
              bookingLink,
              EndorsementLink,
            } = response.Data;

            let callbackVal;
            if (IsCallBackRequest === "0") {
              callbackVal = "No";
            } else if (IsCallBackRequest === "1") {
              callbackVal = "Yes";
            } else if (IsCallBackRequest === "2") {
              callbackVal = "Completed";
            }
            setFormValues((prevValue) => ({
              ...prevValue,
              ...response.Data,
              originalLeadID: response.Data.LeadID,
              IsCallBackRequest: +IsCallBackRequest,
              BookingLink: bookingLink,
              EndorsementLink: EndorsementLink,
              CallbackVal: callbackVal,
              SendToInsurer: IsInsurerBucket,
              SendToInsurerMsg: "",
            }));
            setRootCauses(response.Data.RootCause.split(","));
            ticketContext.setoldTicketDetails((prevValue) => ({
              ...prevValue,
              ...response.Data,
              originalLeadID: response.Data.LeadID,
              IsCallBackRequest: +IsCallBackRequest,
              BookingLink: bookingLink,
              EndorsementLink: EndorsementLink,
              CallbackVal: callbackVal,
              SendToInsurer: IsInsurerBucket,
              SendToInsurerMsg: "",
            }));
            setEndorsementLink(EndorsementLink);
            GetIssueTypeByLeadIdApi(
              response.Data.LeadID,
              response.Data.ProductId,
              response.Data.Source
            );
          } else {
            Toast.error(response['Message'] || 'Some error occurred');
            console.log("error in TicketView GetTicketDetailsByIdApi");
          }
        })
        .catch((err) => {
          setTicketLoading_store(false);
          handleCatch(err)
        });
    }
  };

  const getAllTicketSummary = (fetchOnlyTicketSummary) => {
    ticketContext.setAllTicketsLoading(true);
    GET(`${GetTicketSummary}/${ref_object_id}/${ticket_details_id}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter(
                (i) => i.StatusID != 3 && i.StatusID != 4
              ).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData2 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 3).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData3 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 4).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          ticketContext.setAllTickets({
            ...response.Data,
            AllTicketList: [...sortedData1, ...sortedData2, ...sortedData3],
          });
          if (!fetchOnlyTicketSummary) {
            const { RefObjectId, TicketDetailsID, TicketId, LeadID } = response.Data;
            dispatch(actions.setActiveTicketID(TicketId));
            // ticketContext.setActiveTicketId(TicketId);
            getLeadDeatils(LeadID);
            GetTicketDetailsByTicketIdApi(RefObjectId, TicketDetailsID);
            ticketContext.GetTicketCommApi(RefObjectId, TicketDetailsID);
            GetTicketLogsApi(TicketId);
            getRedAmberResponseapi(TicketId);
            GetFilterCountApi(TicketId);
          }
        } 
        else { Toast.err(response['Message'] || 'Some error occurred')}
        ticketContext.setAllTicketsLoading(false);
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false);
        handleCatch(err);
      });
  };

  const getLeadDeatils = (LeadId) => {
    setIsLeadDetailsLoading(true);
    GET(`${GetLeadDetails}/${LeadId}/${ticket_details_id}/${ref_object_id}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setLeadDetails(response.Data);
        } else {
          Toast.error(response['Message'] || 'Some error occurred while processing')
        }
        setIsLeadDetailsLoading(false);
      })
      .catch((err) => {
        setIsLeadDetailsLoading(false);
        handleCatch(err)
      });
  };

  useEffect(() => {
    if(!forceRefreshAllTickets){
      getAllTicketSummary();
    }
    else{ 
      getAllTicketSummary(true);
    }
  }, [forceRefreshAllTickets]);

  const applyCustomerProfilePic = (name) => {
    if (name) {
      const nameArr = name.split(" ");
      if (nameArr.length > 1 && nameArr[0] && nameArr[nameArr.length - 1]) {
        return (
          nameArr[0][0].toUpperCase() +
          nameArr[nameArr.length - 1][0].toUpperCase()
        );
      } else if (nameArr.length === 1 && nameArr[0]) {
        return nameArr[0][0].toUpperCase();
      } else {
        return "";
      }
    }
  };

  const redirect = () => {
    window.parent.postMessage(
      { type: "openBookingDetails", value: leadDetails?.LeadID, "EncrypytedLeadID" : leadDetails && leadDetails.EncrypytedLeadID },
      "*"
    );
  };



  return (
    <ChildErrorBoundary componentName="TicketView">
      <div className="">
          <RouterLink to="/dashboardV2" className="home-link" id="HomeButton">
              <HomeIcon /> Home
          </RouterLink>
        {!IsReadOnlyUser && isOneTicket_store ? (
          <Button className="next-btn" color="primary" onClick={PullNextTicket} id="pullNextTicket">
            {" "}
            Next <i className="fa fa-angle-right"></i>
          </Button>
        ) : null}
        <div className="agent-name">
          <span className="short-name">
            {leadDetails?.CustomerName
              ? applyCustomerProfilePic(leadDetails?.CustomerName)
              : ""}
          </span>
          {leadDetails?.CustomerName ? (
            <h5 id="cutumerLeadId">{leadDetails?.CustomerName}</h5>
          ) : null}
        </div>
        <h2>
          Booking ID :&nbsp;
          {Boolean(leadDetails?.LeadID || leadDetails?.LeadID === 0) && (
            <span className="lead-id">
              {viewtype == 1 ? (
                <strong>{leadDetails?.LeadID}</strong>
              ) : leadDetails?.LeadID == 0 ||
                !ticketContext.isActionsAllowed ? (
                <strong>{leadDetails?.LeadID}</strong>
              ) : (
                <strong>
                  {!viewOnly ? (
                    <a onClick={redirect} id="bookingID">{leadDetails?.LeadID}</a>
                  ) : (
                    <span>{leadDetails?.LeadID}</span>
                  )}
                </strong>
              )}
            </span>
          )}
          &nbsp;{Boolean(leadDetails && leadDetails.LeadID) && <CustomCopyToClipboard text={leadDetails.LeadID}/>}
        </h2>
        <div className="all-ticket-header">
          <div className="user-info-detail">
            {leadDetails && leadDetails?.SupplierLogo && (
              <span className="logo-box">
                <img src={leadDetails?.SupplierLogo} alt="supplierLogo" />
              </span>
            )}

            <div className="title-box">
              <h5 id="titleHeading">{leadDetails?.SupplierName}</h5>
            </div>
          </div>
          <div className="booking-deatil-box">
            {!isLeadDetailsLoading && leadDetails && (
              <ul className="booking-list-view">
                {leadDetails?.ProductName && (
                  <li>
                    <span>Product</span>
                    <strong id="leadProductName">{leadDetails?.ProductName}</strong>
                  </li>
                )}
                {leadDetails?.PolicyNo && (
                  <li>
                    <span>Policy No.</span>
                    <strong id="leadPolicyName">{leadDetails?.PolicyNo}</strong>
                  </li>
                )}
                {leadDetails?.ApplicationNo && (
                  <li>
                    <span>Application No.</span>
                    <strong id="leadApplicationName">{leadDetails?.ApplicationNo}</strong>
                  </li>
                )}
                {(leadDetails?.ProductID === 117 ||
                  leadDetails?.ProductID === 114 ||
                  leadDetails?.ProductID === 186 ||
                  leadDetails?.ProductID === 187 ||
                  leadDetails?.ProductID === 188) &&
                  leadDetails?.RegistrationNo && (
                    <li>
                      <span>Reg. No</span>
                      <strong id="leadRegistrationNo">{leadDetails?.RegistrationNo}</strong>
                    </li>
                  )}
                {(leadDetails?.ProductID === 117 ||
                  leadDetails?.ProductID === 114) &&
                  leadDetails?.PreviousPolicyNo && (
                    <li>
                      <span> PYP Number </span>
                      <strong id="leadPreviousPolicyNo">{leadDetails?.PreviousPolicyNo}</strong>
                    </li>
                  )}
              </ul>
            )}
            {!isShowLess && (
              <>
                {isLeadDetailsLoading && (
                  <div className="spinner-loader">
                    <Spinner />
                  </div>
                )}
                {leadDetails?.GetBookingTimeRes &&
                  Array.isArray(leadDetails?.GetBookingTimeRes) &&
                  leadDetails?.GetBookingTimeRes.length > 0 && (
                    <div className="booking-status-bar">
                      <h2>Status</h2>
                      <Stepper
                        GetBookingTimeRes={leadDetails?.GetBookingTimeRes}
                      />
                    </div>
                  )}
              </>
            )}
            {leadDetails?.GetBookingTimeRes &&
              Array.isArray(leadDetails?.GetBookingTimeRes) &&
              leadDetails?.GetBookingTimeRes.length > 0 && (
                <Button
                  className="show-detail-btn"
                  onClick={() => toggleShowDetail(!isShowLess)}
                >
                  <span>{isShowLess ? "Show Detail" : "Hide detail"}</span>
                </Button>
              )}
          </div>
          {/* ticketContext.isActionsAllowed */}
          {!IsReadOnlyUser && viewtype == 0 && (
            <CommunicationBox
              ticket_details_id={ticket_details_id}
              leadDetails={leadDetails}
              policyCopyDocId={oldTicketDetails.DocId}
              PartnerId={allTickets.PartnerId}
              TicketId={allTickets.TicketId}
              ref_object_id={ref_object_id}
            />
          )}
          {ticketContext.AccessTypeVal != 3 && viewtype == 0 && (
            <h2 className="card-heading">
              All tickets (
              {allTickets
                ? allTickets.AllTicketList &&
                  Array.isArray(allTickets.AllTicketList) &&
                  allTickets.AllTicketList.length > 0
                  ? ticketContext.isActionsAllowed
                    ? allTickets.AllTicketList.length
                    : allTickets.AllTicketList.filter(
                        (ticket) => ticket.StatusID !== 4
                      ).length
                  : "0"
                : "0"}
              )
            </h2>
          )}
          {isAllTicketsLoading ? (
            <div className="spinner-loader">
              <Spinner />
            </div>
          ) : Object.keys(allTickets).length > 0 ? (
            <div id='ticketListCards'>
              <TicketCard
                ticket={allTickets}
                GetTicketDetailsByTicketIdApi={GetTicketDetailsByTicketIdApi}
                GetTicketLogsApi={GetTicketLogsApi}
                getRedAmberResponseapi={getRedAmberResponseapi}
                GetFilterCountApi={GetFilterCountApi}
                setIsTicketDetailEditable={setIsTicketDetailEditable}
                Responsibility={Responsibility}
                setErrors={setErrors}
                viewtype={viewtype}
                setResetTab={setResetTab}
                ProductID={leadDetails?.ProductID}
                SupplierID={leadDetails?.SupplierID}
                InvestmentTypeID={leadDetails?.InvestmentTypeID}
                componentID={`ticketCard_0`}
              />
              {ticketContext.AccessTypeVal != 3 &&
                viewtype == 0 &&
                allTickets.AllTicketList &&
                Array.isArray(allTickets.AllTicketList) &&
                allTickets.AllTicketList.length > 0 &&
                (ticketContext.isActionsAllowed
                  ? allTickets.AllTicketList
                  : allTickets.AllTicketList.filter(
                      (ticket) => ticket.StatusID !== 4
                    )
                ).filter(item=> item.TicketId !== allTickets.TicketId).map((ticket, index) => {
                    return (
                      <TicketCard
                        getRedAmberResponseapi={getRedAmberResponseapi}
                        key={ticket.TicketId}
                        ticket={ticket}
                        setFormValues={setFormValues}
                        GetTicketDetailsByTicketIdApi={
                          GetTicketDetailsByTicketIdApi
                        }
                        GetTicketLogsApi={GetTicketLogsApi}
                        GetFilterCountApi={GetFilterCountApi}
                        setIsTicketDetailEditable={setIsTicketDetailEditable}
                        Responsibility={Responsibility}
                        setErrors={setErrors}
                        viewtype={viewtype}
                        setResetTab={setResetTab}
                        ProductID={leadDetails?.ProductID}
                        SupplierID={leadDetails?.SupplierID}
                        InvestmentTypeID={leadDetails?.InvestmentTypeID}
                        componentID={`ticketCard_${index+1}`}
                      />
                    );
                })}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="version-name">Version : {VERSION}</div>
      </div>
    </ChildErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  // console.log("isOneTicket", state && state.auth && state.auth.userInfo);
  return {
    isOneTicket_store:
      state &&
      state.auth &&
      state.auth.userInfo &&
      state.auth.userInfo.IsOneTicket, // isOneTicket is fetched from login object
    bucketDetails_store:
      state &&
      state.auth &&
      state.auth.userInfo &&
      state &&
      state.auth &&
      state.auth.userInfo.BucketDetails, // Bucket details for NextTicketPull
    productID_store:
      state &&
      state.auth &&
      state &&
      state.auth &&
      state.auth.userInfo &&
      state.auth.userInfo.ProductID,
    groupID_store:
      state &&
      state.auth &&
      state &&
      state.auth &&
      state.auth.userInfo &&
      state.auth.userInfo.GroupID,
    userID_store:
      state &&
      state.auth &&
      state &&
      state.auth &&
      state.auth.userInfo &&
      state.auth.userInfo.UserID,
    currentTicketID_store:
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.TicketId,
    IsReadOnlyUser:
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetOldTicketDetails: (payload) =>
      dispatch(actions.setTicketDetails(payload)),
    setBucketDetails_store: (payload) => dispatch(setBucketDetails(payload)),
    setTicketLoading_store: (payload) => dispatch(actions.setTicketLoading(payload)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketView);
