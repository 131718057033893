import React, {useState,useContext} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {
    Button,
} from "@material-ui/core";
import Employee from "./Employee";
import { POST , GET} from "../../../../services/apiServices";
import { UpdateSMEDetails,GetTicketDetailsByTicketID, GetTicketSummary } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import TicketContext from "../../../../TicketContext";
import Spinner from "../../../../shared/components/Spinner";
import { useDispatch } from "react-redux";
import { setActiveTicketID } from "../../../../redux/actions/ticketDetails";


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  logBtn: {
    margin: "0 5px 0",
  },
  formControl: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    position: "relative",
    margin: "10px 0",
    width: "100%",
    "&:hover": {
      outline: "none",
      boxShadow: "none",
    },
    "& textArea": {
      fontSize: "12px",
      padding: "8px",
      border: "1px solid #ccc",
    },
    "& input": {
      padding: "8px",
    },
    input: {
      display: "none",
    },
  },
});


export default function EmployeeList({employeeList,setSmeDetails,selectedEmployee, setSelectedEmployee, role}) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [isUpdating, setUpdating] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const ticketContext = useContext(TicketContext);

  const getAllTicketSummary = () => {
    ticketContext.setAllTicketsLoading(true);
    GET(`${GetTicketSummary}/${ticketContext.RefObjectId}/${ticketContext.TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID != 3 && i.StatusID != 4).sort((a,b)=> (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)): [];
          const sortedData2 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID == 3).sort((a,b)=> (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)): [];
          const sortedData3 = response.Data.AllTicketList ? response.Data.AllTicketList.filter(i => i.StatusID == 4).sort((a,b)=> (b.LastUpdatedOn || b.CreatedOn) - (a.LastUpdatedOn || a.CreatedOn)): [];
          ticketContext.setAllTickets({...response.Data, "AllTicketList": [...sortedData1, ...sortedData2, ...sortedData3]});
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        ticketContext.setAllTicketsLoading(false);
        dispatch(setActiveTicketID(response.Data.TicketId))
        ticketContext.setActiveTicketId(response.Data.TicketId);    
        ticketContext.GetTicketLogsApi(response.Data.TicketId)
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };
  
  const GetTicketDetailsByTicketIdApi = () => {
    GET(`${GetTicketDetailsByTicketID}/${ticketContext.RefObjectId}/${ticketContext.TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const { IsCallBackRequest, IsInsurerBucket } = response.Data;

          let callbackVal;
          if (IsCallBackRequest === "0") {
            callbackVal = "No";
          } else if (IsCallBackRequest === "1") {
            callbackVal = "Yes";
          } else if (IsCallBackRequest === "2") {
            callbackVal = "Completed";
          }

          ticketContext.setFormValues((prevValue) => ({
            ...prevValue,
            ...response.Data,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
          ticketContext.setoldTicketDetails((prevValue) => ({
            ...prevValue,
            ...response.Data,
            IsCallBackRequest: +IsCallBackRequest,
            CallbackVal: callbackVal,
            SendToInsurer: IsInsurerBucket,
            SendToInsurerMsg: "",
          }));
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        console.log('error in EmployeeList GetTicketDetailsById line 132')
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        console.log(err);
      });
  };
  
  
  const UpdateSMEDetailsApi = ()=> {

    if(isUpdating){
      return;
    }

    const emp = employeeList[selectedEmployee];
    const payload = {
      "TicketID": ticketContext.TicketId,
      "BookingId": emp.LeadID,
      "CompanyName": emp.CompanyName,
      "EmployeeID": emp.EmployeeCode,
      "EmployeeName": emp.EmployeeName,
      "MembershipNumber": null,
      "ReimbursementClaims": null,
      "HospitalName": null,
      "DateOfRegistration": null,
      "DateOfDischarge": null,
      "Diagnoses": null,
      "AmountCharged": null,
      "EmailID": emp.EmployeeEmail,
      "PolicyNo": emp.PolicyNo,
      "TicketRaisedBy": role
    };
    setUpdating(true);

    POST(UpdateSMEDetails,payload)
    .then(res=>{
          if(res.ErrorCode === 0){
            enqueueSnackbar("Success", {
              variant: "success",
              onClick: () => {
                closeSnackbar();
              },
            });
            GetTicketDetailsByTicketIdApi();
            getAllTicketSummary();
            if(res.Data){
              setSmeDetails(res.Data);
            }
          }
          else{
            enqueueSnackbar("Something went wrong!", {
              variant: "error",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
          setUpdating(false);
    })
    .catch(err=> {
      console.log(err);
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      setUpdating(false);
    })
  }

  return (
    <TableContainer className="table">
      <Table
        aria-label="simple table"
        className="sme-table-list"
      >
        <TableHead>
          <TableRow>
            <TableCell>Employee Name (Employee Code)</TableCell>
            <TableCell>Booking ID</TableCell>
            <TableCell>Company Name</TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
          {employeeList.map((employee, index) => (
            <Employee key = {index} employee = {employee} ind={index} selectedEmployee={selectedEmployee} setSelectedEmployee={setSelectedEmployee}/>
          ))}
        </TableBody>
      </Table>
      <div className="text-right">
          <Button className="save-btn" onClick={UpdateSMEDetailsApi}>{isUpdating ? <Spinner/> : "Save"}</Button>
      </div>
    </TableContainer>
  );
}
