import { call, put, takeEvery } from "redux-saga/effects";
import { AuthLogin, Login } from "../../constants/apiUrls";
import { GET, POST } from "../../services/apiServices";
import * as type from "../types/types";
import { GetTicketLog } from "../../constants/apiUrls";

function GetTicketLogsApi(ticketId, activeTicketId) {
    return GET(`${GetTicketLog}/${ticketId ? ticketId : activeTicketId}/0`)
    .then((response) => {
      if (response.ErrorCode === 0) {
        // setTicketLogs(response.Data);
        return response.Data
      } else {
        // enqueueSnackbar("Something went wrong!", {
        //   variant: "error",
        //   onClick: () => {
        //     closeSnackbar();
        //   },
        // });
        alert("Something went wrong!")
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

function* fetchTicketLogs(action) {
  try {
    const ticketLogs = yield call(GetTicketLogsApi());
    yield put({ type: type.GET_TICKET_LOGS_SUCCESS, ticketLogs: ticketLogs });
  } catch (e) {
    yield put({ type: type.GET_TICKET_LOGS_FAILED, message: e.message });
  }
}

function* ticketLogsSaga() {
  yield takeEvery(type.GET_TICKET_LOGS_REQUESTED, fetchTicketLogs);
}

export default ticketLogsSaga;
