import React, { useState } from "react";
import {
  Link,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { exportDashboardData_method } from "../../shared/methods/apiMethods";
import Spinner from "../../shared/components/Spinner";

export default function SearchBox(props) {
  const { ticketData, passedPayload } = props;
  const [insurer, setInsurer] = React.useState("");
  const history = useHistory();
  const [downloadingData, setDownloadingData] = useState(false)

  const handleChange = (event) => {
    setInsurer(event.target.value);
  };

  const redirectToAllTicket = (RefObjectId, TicketDetailsID) => {
      history.push(`/insurerticket/${RefObjectId}/${TicketDetailsID}`);
  };

  const downloadDashboardData = () =>{
    let endpoint = 'GetMyDashboardDataExport';
    let cb = () =>{
      setDownloadingData(false)
    }
    setDownloadingData(true);
    return exportDashboardData_method(endpoint, passedPayload, cb, 'DashboardData', 'TicketSystem')
  }

  return (

    <>
      <div className="export-bar">
        <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="export-btn"
            id="topbarHeadUser"
            disabled={downloadingData}
            onClick={downloadDashboardData}
          >
          {downloadingData ? <Spinner/> : <img src="images/export_icon.png" alt="export_icon.png"/>}
        </Button>
      </div>
      <div className="load-data-list scrool-toll ">
      {ticketData && ticketData.length > 0 ? (
        <div className="load-data-head">
          <ul className="scrool-toll head">
            <li>Ticket ID</li>
            <li>Product Name</li>
            <li>Issue Name</li>
            <li>Supplier Name</li>
            <li>Booking ID</li>
            <li>Created On</li>
            <li>Status</li>
            <li>Assigned To</li>
          </ul>
          <div className="export-data-list scrool-toll">
            {ticketData
              .sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))
              .map((i) => {
                return (
                  <ul className="body-list">
                    <li>
                      <Link
                        onClick={() =>
                          redirectToAllTicket(i.RefObjectId, i.TicketDetailsID, i.ProductId)
                        }
                        id="loadDataListTicketDetailsID"
                      >
                        {i.TicketDetailsID}
                      </Link>
                    </li>
                    <li id="loadDataListProductName">{i.ProductName}</li>
                    <li id="loadDataListIssueName">{i.IssueName}</li>
                    <li id="loadDataListSupplierName">{i.SupplierName}</li>
                    <li id="loadDataListLeadID">{i.LeadID}</li>
                    <li id="loadDataListCreatedOn">{moment(i.CreatedOn).format("DD MMM YYYY")}</li>
                    <li id="loadDataListStatus">{i.Status}</li>
                    <li id="loadDataListAssignedToName">{i.AssignedToName}</li>
                  </ul>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="load-data-head">
          <div className="no-data-found">
            <img src="images/no_data_found.svg" alt="no_data_found" />
            <p>No data available for the selected ticket</p>
          </div>
        </div>
      )}
    </div>
    </>
    
  );
}
