import React, { useState, useEffect } from "react";
import PublishIcon from "@material-ui/icons/Publish";
import { toBase64 } from "../methods/methods";

const FileUploadComponent = ({
  setFiles,
  files=null,
  fileRef,
  isMultipleFileAllowed = true,
  isAttachementIcon = false,
  acceptValue = "",
}) => {

  const [availableFiles, setAvailableFiles] = useState([]);

  useEffect(()=>{
    let arr = []
    if(files && files.length > 0){
      files.forEach((item, i)=>{
        let obj = {
          ...item,
          id : i
        }
        arr.push(obj);
      })
      setAvailableFiles(arr)
    }
    else setAvailableFiles([]);
  },[files])

  const handleFileChange = async (e) => {
    const filesArr = [...availableFiles];
    for (let i =0; i < e.target.files.length; i++) {
      const base64 = await toBase64(e.target.files[i]);
      filesArr.push({
        id: i+ availableFiles.length,
        FileName: e.target.files[i].name,
        AttachemntContent: base64.split("base64,")[1],
      });
    }
    setFiles(filesArr);
    fileRef.current.value = "";
  };

  //for single file upload
  const handleFileUpload = async (e) => {
    const base64 = await toBase64(e.target.files[0]);
    setFiles({
      FileName: e.target.files[0].name,
      AttachementContent: base64.split("base64,")[1],
    });
    fileRef.current.value = "";
  };

  return (
    <span className="attach-box">
      {isAttachementIcon ? (
        <i
          className="fa fa-paperclip upload-btn"
          onClick={() => fileRef.current.click()}
          title='Attach files'
        ></i>
      ) : (
        
        <button
          type="button"
          className="upload-btn"
          onClick={() => fileRef.current.click()}
          title='Attach files'
        >
          <PublishIcon />
        </button>
      )}

      <input
        className="upload"
        type="file"
        ref={fileRef}
        onChange={isMultipleFileAllowed ? handleFileChange : handleFileUpload}
        title=""
        accept={acceptValue}
        multiple={isMultipleFileAllowed}
      />
    </span>
  );
};

export default FileUploadComponent;
