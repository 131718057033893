import * as CONFIG from './Config'

export const PAYMENT_PENDENCY_REMARKS_MASTER = (productID) => {
    let master1 = ['Premium Mismatch',
        'CC Mismatch',
        'NCB Discrepancy',
        'Variant Update',
        'Manufacture Year Update',
        'Registration Date Update',
        'Plan Update',
        'Fuel Type Update',
        'RTO Update',
    ]
    let master2 = [
        'DOB Change - Age increase',
        'Permanent address change',
        'Pin Code change',
        'Balance premium to be collected',
        'Difference in premium to be collected',
        'Counteroffer  Increase in premium',
        'Rider amount',
        'Pre-Existing Disease',
        'High Body Mass Index',
    ]
    let productMasterConfig = {
        [CONFIG.TWO_WHEELER] : master1,
        [CONFIG.CAR] : master1,
        [CONFIG.HEALTH] : master2
    }
    return productMasterConfig[productID] || []
}