//This components appears from right side when user clicks on View All Comments on Right Side
import React, { useState, useRef, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { TextareaAutosize, Grid, MenuItem, Select } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import {
  convertToIsoFormate,
  toBase64,
  getFileExtension,
  handleDrag,
  handleDragIn,
  handleDrop,
  handleDragOut,
} from "../../../../shared/methods/methods";
import CloseIcon from "@material-ui/icons/Close";
import { DisplayName } from "../../../../Config";
import Spinner from "../../../../shared/components/Spinner";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import {
  UpdateTicketRemarks,
  UpdateTicketInsurerStatus,
  GetDocURL,
} from "../../../../constants/apiUrls";
import { POST, GET } from "../../../../services/apiServices";
import { useSnackbar } from "notistack";
import TicketContext from "../../../../TicketContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FileUploadComponent from "../../../../shared/components/FileUploadComponent";
import { useSelector } from "react-redux";
import { handleCatch } from "../../../../shared/methods/apiMethods";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
    margin: "0 !important",
    boxShadow: "5px 6px 6px #4c4c4c29 !important",
    padding: "10px",
  },
  Toolbar: {
    paddin: "0",
  },
  title: {
    flex: 1,
  },
  formControl: {
    minWidth: "100%",
  },
  h6: {
    color: "#414141",
  },
  image: {
    width: 36,
    height: 36,
    border: "1px sodlid #65AEDF",
  },
  img: {
    width: 36,
    height: 36,
    border: "1px sodlid #65AEDF",
  },
  formControl: {
    width: "100%",
  },
  title: {
    fontSize: "16px",
    color: "#414141",
  },
  formControl: {
    minWidth: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ViewComments({
  oldTicketDetails,
  templateList,
  setRefresh,
  viewtypeComment,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const IsReadOnlyUser = useSelector(
    (state) =>
      state &&
      state.ticketdetails &&
      state.ticketdetails.ticketDetails &&
      state.ticketdetails.ticketDetails.IsReadOnlyUser
  );
  const ticketContext = useContext(TicketContext);

  const fileRef = useRef(null);
  const insurerFileRef = useRef(null);

  const [selectedFile, setselectedFile] = useState([]);
  const [selectedInsurerFile, setSelectedInsurerFiles] = useState([]);
  const [comment, setComment] = useState("");
  const [commentInProgress, setCommentInProgress] = useState("");
  const [insurerCommentInProgress, setInsurerCommentInProgress] =
    useState(false);
  const [insurerComment, setInsurerComment] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const InsurerStatusOption = [10, 12, 14];
  const [InsurerStatusList, setInsurerStatusList] = useState([]);

  const [OldInsurerStatusID, setOldInsurerStatusID] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState({
    StatusID: "",
    StatusName: "",
  });

  useEffect(() => {
    if (
      ticketContext.allSubStatusOptions &&
      ticketContext.allSubStatusOptions.length > 0
    ) {
      const options = ticketContext.allSubStatusOptions.filter(
        (item) => item.StatusType === 2
      );
      let enableStatusOptions = [];
      let disableStatusOptions = [];
      for (let i = 0; i < options.length; i++) {
        if (oldTicketDetails.InsurerStatusID === options[i].StatusID) {
          setSelectedStatus({
            StatusID: options[i].StatusID,
            StatusName: options[i].StatusName,
          });
          setOldInsurerStatusID(options[i].StatusID);
        }
        if (InsurerStatusOption.includes(options[i].StatusID)) {
          enableStatusOptions.push(options[i]);
        } else {
          disableStatusOptions.push(options[i]);
        }
      }

      setInsurerStatusList([...enableStatusOptions, ...disableStatusOptions]);
    }
  }, [ticketContext.allSubStatusOptions, oldTicketDetails.InsurerStatusID]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setselectedFile([]);
    setComment("");
    setInsurerComment("");
    setSelectedInsurerFiles([]);
  };

  const handleRemoveFile = (id) => {
    const fileList = selectedFile.filter((item) => item.id !== id);
    setselectedFile(fileList);
  };

  const handleRemoveInsurerFile = (id) => {
    const fileList = selectedInsurerFile.filter((item) => item.id !== id);
    setSelectedInsurerFiles(fileList);
  };

  const showMsg = (msg, variant = "error") => {
    enqueueSnackbar(msg, {
      variant: variant,
      onClick: () => {
        closeSnackbar();
      },
    });
  };

  const handleCommentSend = () => {
    if (!comment) {
      showMsg("Please enter the comment first");
      return;
    }
    const payload = {
      PrevObjectID: oldTicketDetails.RefObjectId,
      TicketId: oldTicketDetails.TicketId,
      TicketDetailId: oldTicketDetails.TicketDetailsID,
      LeadID: oldTicketDetails.LeadID,
      CustomerName: oldTicketDetails.CustomerName,
      CustomerID: oldTicketDetails.CustomerId,
      ProductID: oldTicketDetails.ProductId,
      ProductName: oldTicketDetails.ProductName,
      Body: comment,
      MailAttachments:
        selectedFile.length > 0
          ? selectedFile.map((item) => ({
              FileName: item.FileName,
              AttachemntContent: item.AttachemntContent,
            }))
          : [],
      HasAttachment: selectedFile.length > 0 ? true : false,
      DisplaySenderName: DisplayName,
      ActionType: 1,
      StatusName: oldTicketDetails.StatusName,
      SubStatusName: oldTicketDetails.Status,
      InsurerID: oldTicketDetails.SupplierId,
      StatusID: oldTicketDetails.StatusID,
      SubStatusID: oldTicketDetails.SubStatusID,
      IsJAG:
        oldTicketDetails && oldTicketDetails.IsJAG ? oldTicketDetails.IsJAG : 0,
      JAGEmployeeId:
        oldTicketDetails && oldTicketDetails.JAGEmployeeId
          ? oldTicketDetails.JAGEmployeeId
          : "",
      IssueTypeID:
        oldTicketDetails && oldTicketDetails.IssueTypeId
          ? oldTicketDetails.IssueTypeId
          : 0,
      TicketSource: (oldTicketDetails && oldTicketDetails.Source) || "",
      TicketSubSource: (oldTicketDetails && oldTicketDetails.SubSource) || "",
      HCSSource: oldTicketDetails && oldTicketDetails.HCSSource
    };
    setCommentInProgress(true);
    POST(
      `${UpdateTicketRemarks}/${
        templateList && templateList.length > 0
          ? templateList[0].TriggerName
          : "DefaultTemplate"
      }`,
      payload
    )
      .then((res) => {
        if (res.ErrorCode === 0) {
          ticketContext.GetTicketCommApi(
            oldTicketDetails.RefObjectId,
            oldTicketDetails.TicketDetailsID
          ); // Updated ticket Communications opened in Comment section
          showMsg(res.Message || "Success", "success");
          setselectedFile([]);
          setComment("");
          ticketContext.GetTicketLogsApi();
        } else {
          showMsg(res.Message || "Something went wrong!");
        }
        setCommentInProgress(false);
      })
      .catch((err) => {
        console.log(err);
        handleCatch(err)
        setCommentInProgress(false);
      });
  };

  const getShortName = (name) => {
    if (name) {
      const nameArr = name.split(" ");
      if (nameArr.length > 1 && nameArr[0] && nameArr[nameArr.length - 1]) {
        return (
          nameArr[0][0].toUpperCase() +
          nameArr[nameArr.length - 1][0].toUpperCase()
        );
      } else if (nameArr.length === 1 && nameArr[0]) {
        return nameArr[0][0].toUpperCase();
      } else {
        return "";
      }
    }
  };

  const handleInsurerCommentSend = () => {
    if (!selectedStatus.StatusName) {
      showMsg("Please select the status first");
      return;
    }

    if (!insurerComment) {
      showMsg("Please enter the comment first");
      return;
    }

    const insurerStatusPayload = {
      TicketId: oldTicketDetails.TicketId,
      InsurerStatusID: selectedStatus.StatusID,
      InsurerSubStatusID: 0,
      OldInsurerStatusID: OldInsurerStatusID,
      OldInsurerSubStatusID: 0,
    };

    setInsurerCommentInProgress(true);

    POST(UpdateTicketInsurerStatus, insurerStatusPayload)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setRefresh(true);
          const updateTicketRemarksPayload = {
            PrevObjectID: oldTicketDetails.RefObjectId,
            LeadID: oldTicketDetails.LeadID,
            InsurerID: oldTicketDetails.SupplierId,
            CustomerName: oldTicketDetails.CustomerName,
            ProductID: oldTicketDetails.ProductId,
            ProductName: oldTicketDetails.ProductName,
            Body: insurerComment,
            DisplaySenderName: DisplayName,
            MailAttachments:
              selectedInsurerFile.length > 0
                ? selectedInsurerFile.map((item) => ({
                    FileName: item.FileName,
                    AttachemntContent: item.AttachemntContent,
                  }))
                : [],
            ActionType: 3,
            StatusName: oldTicketDetails.StatusName,
            SubStatusName: oldTicketDetails.Status,
            TicketId: oldTicketDetails.TicketId,
            IsInsurer: false,
            TicketDetailId: oldTicketDetails.TicketDetailsID,
            StatusID: oldTicketDetails.StatusID,
            SubStatusID: oldTicketDetails.SubStatusID,
            HasAttachment: selectedInsurerFile.length > 0 ? true : false,
            IsJAG:
              oldTicketDetails && oldTicketDetails.IsJAG
                ? oldTicketDetails.IsJAG
                : 0,
            JAGEmployeeId:
              oldTicketDetails && oldTicketDetails.JAGEmployeeId
                ? oldTicketDetails.JAGEmployeeId
                : "",
            IssueTypeID:
              oldTicketDetails && oldTicketDetails.IssueTypeId
                ? oldTicketDetails.IssueTypeId
                : 0,
            HCSSource: oldTicketDetails && oldTicketDetails.HCSSource
          };
          POST(
            `${UpdateTicketRemarks}/${
              templateList && templateList.length > 0
                ? templateList[0].TriggerName
                : "DefaultTemplate"
            }`,
            updateTicketRemarksPayload
          )
            .then((res) => {
              if (res.ErrorCode === 0) {
                ticketContext.GetTicketCommApi(
                  oldTicketDetails.RefObjectId,
                  oldTicketDetails.TicketDetailsID
                );
                showMsg(res.Message || "Success", "success");
                setSelectedInsurerFiles([]);
                setInsurerComment("");
                setInsurerCommentInProgress(false);
                setOldInsurerStatusID(selectedStatus.StatusID);
                ticketContext.setoldTicketDetails((prev) => {
                  return { ...prev, InsurerStatusID: selectedStatus.StatusID };
                });
                ticketContext.GetTicketLogsApi(); // Updates Ticket Logs
                ticketContext.GetTicketDetailsByTicketIdApi(
                  oldTicketDetails.RefObjectId,
                  oldTicketDetails.TicketDetailsID
                ); // Updates Ticket Details currently opened
              } else {
                showMsg(res.Message || "Something went wrong");
                setInsurerCommentInProgress(false);
              }
            })
            .catch((err) => {
              console.log(err);
              showMsg("Something went wrong");
              setInsurerCommentInProgress(false);
            });
        } else {
          showMsg("Something went wrong");
          setInsurerCommentInProgress(false);
        }
      })
      .catch((err) => {
        console.log(err);
        showMsg("Something went wrong");
        setInsurerCommentInProgress(false);
      });
  };
  const downloadAttachment = (docID, fileName) => {
    if (docID) {
      GET(`${GetDocURL}/${docID}`)
        .then((res) => {
          if (res && res.ErrorCode == 0 && res.Data) {
            window.open(res.Data, "_blank");
          } else {
            if (res.Response) {
              enqueueSnackbar(res.Response, {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            } else {
              enqueueSnackbar("URL not found", {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            }
            console.log("something went wrong");
          }
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          console.log(err);
        });
    } else {
      enqueueSnackbar("Document not found", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      console.log("Document ID not found");
    }
  };
  const disabledEndorsement = () =>{
    if(oldTicketDetails && oldTicketDetails.EndorsementID && Number(oldTicketDetails.EndorsementID)>0){
      return true
    }
    else return false
  }

  return (
    <span className="view-comments">
      <Button
        variant="outlined"
        className="comment-btn"
        id="commentButton_MainPage"
        onClick={handleClickOpen}
      >
        <QuestionAnswerIcon className="comment-icon" /> View All Comments
      </Button>
      {open ? (
        <div className="create-dialog-box">
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                className="close-btn"
                edge="right"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Grid variant="h6" className={classes.title}>
                All Comments
              </Grid>
            </Toolbar>
          </AppBar>
          <div className="all-comments">
            <Grid container spacing={0}>
              <Grid item md={12} xs={12}>
                {commentInProgress ? (
                  <div className="spinner-loader">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div
                      className="all-comment-view"
                      onDragOver={handleDrag}
                      onDrop={(e) => handleDrop(e, setselectedFile, fileRef)}
                      onDragLeave={handleDragOut}
                      onDragEnter={handleDragIn}
                    >
                      {viewtypeComment == 0 || oldTicketDetails.ParentTicketID
                        ? ""
                        : (
                            <List className="comment-submit-data">
                              <ListItem>
                                <TextareaAutosize
                                  className="txtarea"
                                  placeholder="Enter Comments"
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                />
                              </ListItem>
                              <ListItem className="attach-submit">
                                <FileUploadComponent
                                  setFiles={setselectedFile}
                                  fileRef={fileRef}
                                  isMultipleFileAllowed={true}
                                  isAttachementIcon={true}
                                 />
                                <Button onClick={handleCommentSend}>
                                  Submit
                                </Button>
                              </ListItem>
                            </List>
                          )}
                      <List>
                        {selectedFile.map((item) => {
                          return (
                            <span
                              className="file-detail name-file"
                              key={item.id}
                            >
                              {item.FileName}{" "}
                              <button
                                className="remove-file-icon"
                                onClick={() => handleRemoveFile(item.id)}
                              >
                                <CloseIcon />
                              </button>
                            </span>
                          );
                        })}
                      </List>
                      <List
                        className={
                          "comments scrool-toll" +
                          (!oldTicketDetails.SendToInsurer
                            ? " only-comments-list"
                            : "")
                        }
                      >
                        <h3>Comments</h3>
                        {ticketContext.ticketCommData &&
                          Array.isArray(ticketContext.ticketCommData) &&
                          ticketContext.ticketCommData
                            .filter(
                              (item) =>
                                item.ActionType == 1 ||
                                item.ActionType == 8 ||
                                item.ActionType == 9
                            )
                            .map((commu, i) => {
                              return (
                                <ListItem key={i}>
                                  <div className="short-name comm-one">
                                    {getShortName(commu.CreatedBy)}
                                  </div>
                                  <div className="timeline"></div>
                                  <span className="comment-time">
                                    {convertToIsoFormate(commu.DisplayDate)}
                                  </span>
                                  <div className="comment-txt">
                                    <p>{commu.CreatedBy}</p>
                                    <strong>
                                      {
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: commu.Body,
                                          }}
                                        ></span>
                                      }
                                    </strong>
                                  </div>
                                  {commu.MailAttachments &&
                                    commu.MailAttachments.map(
                                      (attachement, i) => {
                                        return (
                                          attachement.FileName && (
                                            <span
                                              className="attachemt-link"
                                              // style={{
                                              //   cursor: IsReadOnlyUser
                                              //     ? "not-allowed"
                                              //     : "pointer",
                                              //   pointerEvents: IsReadOnlyUser
                                              //     ? "none"
                                              //     : "",
                                              // }}
                                              onClick={() =>
                                                downloadAttachment(
                                                  attachement.DocId,
                                                  attachement.FileName
                                                )
                                              }
                                            >
                                              {console.log(
                                                "view comments",
                                                attachement
                                              )}
                                              <div className="upload-item">
                                                <span className="file-extn">
                                                  {getFileExtension(
                                                    attachement.FileName
                                                  ) === "doc" ? (
                                                    <img
                                                      src="/images/doc_icon.png"
                                                      alt="pdf_icon"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  {getFileExtension(
                                                    attachement.FileName
                                                  ) === "jpg" ||
                                                  getFileExtension(
                                                    attachement.FileName
                                                  ) === "png" ||
                                                  getFileExtension(
                                                    attachement.FileName
                                                  ) === "jpeg" ? (
                                                    <img
                                                      src="/images/img_icon.png"
                                                      alt="pdf_icon"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  {getFileExtension(
                                                    attachement.FileName
                                                  ) === "pdf" ? (
                                                    <img
                                                      src="/images/pdf_icon.svg"
                                                      alt="pdf_icon"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                                <p className="download-info">
                                                  {attachement.FileName}
                                                </p>
                                              </div>
                                            </span>
                                          )
                                        );
                                      }
                                    )}
                                </ListItem>
                              );
                            })}
                      </List>
                    </div>
                  </>
                )}
              </Grid>

              {oldTicketDetails.SendToInsurer ? (
                <Grid item md={12} xs={12}>
                  {insurerCommentInProgress ? (
                    <div className="spinner-loader">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <div
                        className="all-insurer-view scrool-toll"
                        onDragOver={handleDrag}
                        onDrop={(e) =>
                          ticketContext.isActionsAllowed
                            ? handleDrop(
                                e,
                                setSelectedInsurerFiles,
                                insurerFileRef
                              )
                            : ""
                        }
                        onDragLeave={handleDragOut}
                        onDragEnter={handleDragIn}
                      >
                        <List className="comments scrool-toll">
                          <h3>Insurer Comments</h3>
                          {ticketContext.ticketCommData &&
                            Array.isArray(ticketContext.ticketCommData) &&
                            ticketContext.ticketCommData
                              .filter(
                                (item) =>
                                  item.ActionType == 3 || item.ActionType == 5
                              )
                              .map((commu, i) => {
                                return (
                                  <ListItem key={i}>
                                    <div className="short-name comm-one">
                                      {getShortName(commu.CreatedBy)}
                                    </div>
                                    <div className="timeline"></div>
                                    <span className="comment-time">
                                      {convertToIsoFormate(commu.DisplayDate)}
                                    </span>
                                    <div className="comment-txt">
                                      <p>{commu.CreatedBy}</p>
                                      <strong>
                                        {
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: commu.Body,
                                            }}
                                          ></span>
                                        }
                                      </strong>
                                    </div>
                                    {commu.MailAttachments &&
                                      commu.MailAttachments.map(
                                        (attachement, i) => {
                                          return (
                                            attachement.FileName && (
                                              <span
                                                className="attachemt-link"
                                                style={{
                                                  cursor: IsReadOnlyUser
                                                    ? "not-allowed"
                                                    : "pointer",
                                                  pointerEvents: IsReadOnlyUser
                                                    ? "none"
                                                    : "",
                                                }}
                                                onClick={() =>
                                                  downloadAttachment(
                                                    attachement.DocId,
                                                    attachement.FileName
                                                  )
                                                }
                                              >
                                                {/* <a
                                                href={attachement.AttachmentURL}
                                                target="_blank"
                                                key={i}
                                              > */}
                                                <div className="upload-item">
                                                  <span className="file-extn">
                                                    {getFileExtension(
                                                      attachement.FileName
                                                    ) === "doc" ? (
                                                      <img
                                                        src="/images/doc_icon.png"
                                                        alt="pdf_icon"
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    {getFileExtension(
                                                      attachement.FileName
                                                    ) === "jpg" ||
                                                    getFileExtension(
                                                      attachement.FileName
                                                    ) === "png" ||
                                                    getFileExtension(
                                                      attachement.FileName
                                                    ) === "jpeg" ? (
                                                      <img
                                                        src="/images/img_icon.png"
                                                        alt="pdf_icon"
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    {getFileExtension(
                                                      attachement.FileName
                                                    ) === "pdf" ? (
                                                      <img
                                                        src="/images/pdf_icon.svg"
                                                        alt="pdf_icon"
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                  <p className="download-info">
                                                    {attachement.FileName}
                                                  </p>
                                                </div>
                                                {/* </a> */}
                                              </span>
                                            )
                                          );
                                        }
                                      )}
                                  </ListItem>
                                );
                              })}
                        </List>

                        {oldTicketDetails.ParentTicketID ||
                        !ticketContext.isActionsAllowed ? (
                          ""
                        ) : (
                          <>
                            <List className="comment-submit-data">
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedStatus.StatusName}
                                fullWidth
                                disabled={IsReadOnlyUser}
                                style={{
                                  cursor: IsReadOnlyUser
                                    ? "not-allowed"
                                    : "pointer",
                                  pointerEvents: IsReadOnlyUser ? "none" : "",
                                }}
                              >
                                {InsurerStatusList.filter(
                                  (item) => item.StatusType === 2
                                ).map((item, i) => {
                                  return (
                                    <MenuItem
                                      value={item.StatusName}
                                      onClick={() =>
                                        setSelectedStatus({
                                          StatusID: item.StatusID,
                                          StatusName: item.StatusName,
                                        })
                                      }
                                      key={i}
                                      disabled={
                                        !InsurerStatusOption.includes(
                                          item.StatusID
                                        )
                                      }
                                    >
                                      {item.StatusName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </List>
                            <List className="comment-submit-data">
                              <ListItem>
                                <TextareaAutosize
                                  className="txtarea"
                                  placeholder="Enter Comments"
                                  value={insurerComment}
                                  onChange={(e) =>
                                    setInsurerComment(e.target.value)
                                  }
                                  disabled={IsReadOnlyUser}
                                />
                              </ListItem>
                              <ListItem className="attach-submit">
                                {!IsReadOnlyUser && (
                                  <FileUploadComponent
                                    setFiles={setSelectedInsurerFiles}
                                    fileRef={insurerFileRef}
                                    isMultipleFileAllowed={true}
                                    isAttachementIcon={true}
                                  />
                                )}
                                <Button
                                  onClick={handleInsurerCommentSend}
                                  disabled={IsReadOnlyUser}
                                >
                                  Submit
                                </Button>
                              </ListItem>
                            </List>
                          </>
                        )}
                        {selectedInsurerFile.map((item) => {
                          return (
                            <span
                              className="file-detail name-file "
                              key={item.id}
                            >
                              {item.FileName}{" "}
                              <button
                                className="remove-file-icon"
                                onClick={() => handleRemoveInsurerFile(item.id)}
                              >
                                <CloseIcon />
                              </button>
                            </span>
                          );
                        })}
                      </div>
                    </>
                  )}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </div>
        </div>
      ) : (
        ""
      )}
    </span>
  );
}
