import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  MenuItem,
  Button,
  Select,
  FormControl,
  TextField,
  searchParams,
  handleChange,
  FormControlLabel,
  Checkbox,
  InputLabel,
  List,
  ListItem,
} from "@material-ui/core";

import Sidebar from "./Components/Sidebar";
import UserProfile from "./Components/UserProfile";
import CreateUserManagement from "./Components/CreateUserManagement";
import { GET, POST } from "../../services/apiServices";
import {
  GetAllAgentsForWeCare,
  UpdateInsurerUserDetails,
} from "../../constants/apiUrls";
import Spinner from "../../shared/components/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function UserManagement() {
  const classes = useStyles();
  const [isCreateUser, setIsCreateUser] = useState(true);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("UserDetails"))
  );
  const [userList, setUserList] = useState(null);
  const [editDetails, setEditDetails] = useState(null);
  const [action, setAction] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    setIsLoading(true)
    GET(`${GetAllAgentsForWeCare}`)
      .then((res) => {
        setIsLoading(false)
        setUserList(res.Data);
      })
      .catch((e) => {
        setIsLoading(false)
        // console.log(e)
      });
  };

  const clikedEdit = (event, details) => {
    if (event && event.persist) {
      event.persist();
    }
    setEditDetails(details);
    if(isCreateUser){
      setIsCreateUser(false);
    }
    setAction(1);
  };

  const clearEdit = () => {
    setEditDetails(null);
    setIsCreateUser(!isCreateUser);
  };

  const createUser = () => {
    setEditDetails(null);
    setAction(0);
    setIsCreateUser(false);
  };

  return (
    <div className="insurer-dashbaord">
      <div className="dashboard">
        <ul>
          <li className="rightbar">
            <div className="user-manage">
              <Button onClick={createUser} className="create-btn">
                Create New User
              </Button>
              {!isCreateUser && (
                <>
                  <CreateUserManagement
                    editDetails={editDetails}
                    clearEdit={clearEdit}
                    userDetails={userDetails}
                    action={action}
                    getUserList={getUserList}
                  />
                </>
              )}
              {isLoading ? (
                <div className="spinner-loader">
                  <Spinner />
                </div>
              ) : (
                <div className="user-detail">
                  <div className="user-detail-head">
                    <ul>
                      <li>
                        <span>Name</span>
                      </li>
                      <li>
                        <span>Email ID</span>
                      </li>
                      <li>
                        <span>Login ID</span>
                      </li>
                      <li>
                        <span>Auto Assign</span>
                      </li>
                      <li>
                        <span>Active</span>
                      </li>
                      <li>
                        <span>Action</span>
                      </li>
                    </ul>
                  </div>
                  <div className="user-detail-body scroll-btn">
                    {userList?.map((i, index) => {
                      return (
                        <ul>
                          <li>
                            <span>Name</span>
                            <strong>{i?.Name}</strong>
                          </li>
                          <li>
                            <span>Email ID</span>
                            <strong>{i?.EmailID}</strong>
                          </li>
                          <li>
                            <span>Login ID</span>
                            <strong>{i.LoginID}</strong>
                          </li>
                          <li>
                            <span>Auto Assign</span>
                            <strong>{i.AutoAssign ? "Yes" : "No"}</strong>
                          </li>
                          <li>
                            <span>Active</span>
                            <strong>{i.IsActive ? "Yes" : "No"}</strong>
                          </li>
                          <li>
                            <span>Action</span>
                            <Button
                              className="edit-btn"
                              onClick={(event) => clikedEdit(event, i)}
                            >
                              <i className="fa fa-pencil"></i>
                            </Button>
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
