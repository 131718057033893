import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AuthLoginCommonMethod } from '../../redux/actions/auth';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, onLogout,user, authorized, componentName, ...rest } = props;
  const dispatch = useDispatch();
  const userInfo = useSelector((state)=> state && state.auth && state.auth.userInfo)

  useEffect(()=>{
    // for security reason method is called on every path change or page refresh
    if(rest.path && userInfo && [6,7].includes(userInfo.AccessType)){
      dispatch(AuthLoginCommonMethod())
    }
  },[rest.path])

  return (
    <Route
      {...rest}
      render={matchProps => true && authorized ? (<Layout user={user} onLogout={onLogout} componentName={componentName} >
        <Component {...matchProps} />
      </Layout>) : <Redirect to={{pathname: '/'}}/>
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;