import { useEffect, useState, React } from 'react';
import { Link, ListItem, Grid, List, Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setDashboardOption } from '../../../redux/actions/pbDashboard_action';
import ChildErrorBoundary from '../../../shared/components/ChildErrorBoundary';
import Tooltip from '@material-ui/core/Tooltip';
import DialogFromRight from '../../../shared/components/DialogFromRight';


import PBDashboardCreateTicket from '../CommonComponents/PBDashboardCreateTicket';
import { useSelector } from 'react-redux';
import { GET, POST, old_ticket_base_url } from '../../../services/apiServices';
import indiaFlag from '../../../shared/india-india-flag.gif';
import Confetti from 'react-confetti';
import { Link as LinkFromRouter } from 'react-router-dom';
import { envUrl, isEndorsementEligible, PB_ADMIN, USERID_ALLOWED_FOR_APP_LOG, USERID_ALLOWED_FOR_SKIP_REPORT_ONELEAD,  USERID_ALLOWERD_TO_MANAGE_USERS ,PB_AGENT } from '../../../Config';
import { useDispatch } from 'react-redux';
import { setBucketDetails, userLogout } from '../../../redux/actions/auth';
import { STORAGE, openNewView } from '../../../shared/methods/methods';
import LogoutDialog from '../../../shared/components/LogoutDialog';
import { useSnackbar } from "notistack";




const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
    },
}))

const PBDashboardHeader = (props) => {
    const classes = useStyles();
    const { componentName } = props;
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state && state.auth && state.auth.userInfo);
    const [dialogWithControls, setDialogWithControls] = useState();
    const [showConfetti, setShowConfetti] = useState(false);
    const [showLogout, setShowLogout] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const history = useHistory();

    const handleCreateTicket = () => {
        setDialogWithControls({
            open: true,
            title: 'Create Ticket',
            handleClose: () => setDialogWithControls(),
            size: 50,
            children: <ChildErrorBoundary><PBDashboardCreateTicket closeParentDialog={() => setDialogWithControls()} /></ChildErrorBoundary>
        })
    }

    useEffect(()=>{
        localStorage.removeItem('xTicketID');
    },[])

    useEffect(() => {
        var tempInterval;
        if (showConfetti) {
            tempInterval = setTimeout(() => {
                setShowConfetti(false)
            }, 6000)
        }
        return () => clearInterval(tempInterval)
    }, [showConfetti])


    let logoutFnc = function () {
        dispatch(userLogout());
        STORAGE.deleteAuth().then(() => {
            history.push("/");
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const MoreOptions = [
        {
            Name: 'Manage Users',
            title: 'View and Manage We-Care Users',
            onClick: () => history.push('/add-user'),
            show: userInfo.AccessType === PB_ADMIN && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl()).includes(userInfo.UserID) ? true : false,
        },
        {
            Name: 'Application Logs',
            title: 'View application logs',
            onClick: () => history.push('/applicationLogs'),
            show: userInfo.AccessType === PB_ADMIN && USERID_ALLOWED_FOR_APP_LOG(envUrl()).includes(userInfo.UserID) ? true : false,
        },
        {
            Name : 'RM Log Master',
            title : 'View RM Log Master Lists',
            onClick : () => history.push('/rmLogMaster'),
            show : userInfo.AccessType === PB_ADMIN && USERID_ALLOWED_FOR_APP_LOG(envUrl()).includes(userInfo.UserID) ? true : false,
        },
        {
            Name : 'API Master',
            title : 'View API Master Log Lists',
            onClick : ()=> history.push('/apiMaster'),
            show : userInfo.AccessType === PB_ADMIN && USERID_ALLOWED_FOR_APP_LOG(envUrl()).includes(userInfo.UserID) ? true : false,
        },
        {
            Name : 'New API Master',
            title : 'View New API Master Log Lists',
            onClick : () => history.push('/newApiMaster'),
            show : userInfo.AccessType === PB_ADMIN && USERID_ALLOWED_FOR_APP_LOG(envUrl()).includes(userInfo.UserID) ? true : false,
        },
        {
            Name : 'Ticket Cache Management',
            title : 'View Ticket Cache Management',
            onClick : () => history.push('/ticket-cache-management'),
            show : userInfo.AccessType === PB_ADMIN && USERID_ALLOWED_FOR_APP_LOG(envUrl()).includes(userInfo.UserID) ? true : false,
        },
        {
            Name : 'Open BMS',
            title: 'Open BMS dialog',
            onClick: () =>{
                setDialogWithControls({
                    open: true,
                    title: 'BMS Policy Dispatch',
                    handleClose: () => setDialogWithControls(),
                    size: 50,
                    children: <div className="iframe-box">
                        <iframe  src='https://bmsv2test.policybazaar.com/bookingdetails/tRGdxUaPVDhAM-1jz975Rw=='/>
                    </div>
                })
            },
            show: true
        }

    ];

    const renderMoreOptions = () => {
        let filteredOptions = MoreOptions.filter(item => item.show);
        if (filteredOptions && filteredOptions.length > 0) {
            return true
        }
        else return false
    } 

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const showErrMsg = (msg) => {
        enqueueSnackbar(msg, {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      };
    
    //Function call on Next Button click if user is configured for IsOneTicket
    const {
        isOneTicket_store,
        bucketDetails_store,
        setBucketDetails_store,
        productID_store,
        groupID_store,
        userID_store,
        currentTicketID_store,
        setTicketLoading_store,
    } = props;
    const PullNextTicket = () => {
    let url = "/GetNextTicket";
    let payload = {
      UserId: userID_store,
      GroupId: groupID_store,
      CurrentTicketId: 0,
      BucketCode: bucketDetails_store?.BkCode || "",
    };
    POST(url, payload)
      .then((res) => {
        if (res && res["ErrorCode"] == 0 && res["Data"]) {
          let { TicketDetailsId, RefObjectId, BucketDetails, ProductID, SupplierId, InvestmentTypeId } =
            res.Data;
          let storeBucketDetails = Promise.resolve(
            setBucketDetails_store(BucketDetails)
          );
          let baseURL;
            isEndorsementEligible(ProductID, SupplierId, InvestmentTypeId) && openNewView(ProductID)
            ? (baseURL = "ticketdetails")
            : (baseURL = "alltickets");
          let historyPush = Promise.resolve(
            history.push(`/${baseURL}/${RefObjectId}/${TicketDetailsId}/0`)
          );
          Promise.all([storeBucketDetails, historyPush]).then(() => {
            window.location.reload();
          });
        } else {
          showErrMsg(res["Response"] || "Some error occured!");
        }
      })
      .catch((err) => {
        if (err && err.response) {
          showErrMsg(err.response.data.message, "error");
        } else showErrMsg(err.message || "Some error occured!");
      });
  };

    const handleOldDashboard = (userInfo) => {
        if (userInfo && userInfo.OldDashboardAccess) {
         history.push("/dashboardV1")
        }else showErrMsg("you don't have permission")
    }   

    return (
        <>
            <div className={classes.root}>
                <div className="pbdashboard-summary ">

                    <div className="pbdashboard-topbar">
                        <Grid container>
                            <Grid item md={4} sm={4} xs={12}>
                                <h2>Hi! {userInfo && userInfo.Name} ({userInfo && userInfo.EmployeeID}) - {userInfo.AccessType === PB_ADMIN ?
                                    <>ADMIN <i class="fa fa-user" aria-hidden="true"></i> </> :
                                    <>Agent <i class="fa fa-user-circle" aria-hidden="true"></i> </>
                                }
                                    {/* <span style={{ margin: '1px 12px 0px', cursor: 'pointer' }} onClick={() => setShowConfetti(true)} title="Click to Celebrate"><img src={indiaFlag} height="auto" width={40} /></span> */}
                                </h2>
                            </Grid>
                            <Grid item md={8} xs={12} className="text-right">
                                <List className="top-head-list">
                                    <ListItem id="homePage">
                                        <Tooltip title="Home" placement="top" onClick={() => { userInfo.RoleName === "Agent" ? history.push("/agent-dashboard") : history.push("/admin-dashboard") }}>
                                            <Link className='home'>
                                                <i className="fa fa-home"></i>
                                            </Link>
                                        </Tooltip>
                                    </ListItem>
                                    {
                                        userInfo.AccessType === PB_ADMIN && <ListItem id="homePageOld">
                                            <Tooltip title="Old Dashboard" placement="top">
                                                <Link style={{ width: "100px"}} onClick={() => handleOldDashboard(userInfo)}>
                                                    Old Dashboard
                                                </Link>
                                            </Tooltip>
                                        </ListItem>
                                    }
                                    {userInfo.AccessType === PB_ADMIN &&
                                        <ListItem>
                                            <Tooltip title="Open Load" placement="top" onClick={() => { history.push("/one-lead") }}>
                                                <Button className="create-ticket">One Ticket Dashboard</Button>
                                            </Tooltip>
                                        </ListItem>
                                     }
                                    {userInfo.AccessType === PB_ADMIN && <ListItem id="myDashboard">
                                        <Tooltip title="My Dashboard" placement="top" >
                                            <LinkFromRouter className='home' to={{
                                                pathname: `my-dashboard`,
                                                state: { data: {} },
                                            }}>
                                                <i className="fa fa-tachometer"></i>
                                            </LinkFromRouter>
                                        </Tooltip>
                                    </ListItem>
                                    }
                                    {/* {userInfo.AccessType === PB_ADMIN && USERID_ALLOWERD_TO_MANAGE_USERS(envUrl()).includes(userInfo.UserID) && <ListItem>
                                        <Tooltip title="Manage Users" placement="top">
                                            <LinkFromRouter to={{
                                                pathname: `/add-user`,
                                                state: { data: {} },
                                            }}>
                                                <i class="fa fa-users" aria-hidden="true"></i>
                                            </LinkFromRouter>
                                        </Tooltip>
                                    </ListItem>
                                    } */}
                                    {/* {userInfo.AccessType === PB_ADMIN && USERID_ALLOWED_FOR_APP_LOG(envUrl()).includes(userInfo.UserID) && <ListItem>
                                        <Tooltip title="Application Logs" placement="top">
                                            <LinkFromRouter to={{
                                                pathname: `/applicationLogs`,
                                                state: { data: {} },
                                            }}>
                                                <i class="fa fa-stack-overflow" aria-hidden="true"></i>
                                            </LinkFromRouter>
                                        </Tooltip>
                                    </ListItem>
                                    } */}
                                    <ListItem>
                                        <Tooltip title="SME" placement="top">
                                            <Link className="create-ticket" onClick={() => history.push("./sme-employee-details")}>
                                                <i class="fa fa-building" aria-hidden="true"></i>
                                            </Link>
                                        </Tooltip>
                                    </ListItem>
                                    <ListItem>
                                        <Tooltip title="Create Ticket" placement="top">
                                            <Button className="create-ticket" onClick={handleCreateTicket}>
                                                {' '}
                                                <img
                                                    src="/images/create_ticket_btn.svg"
                                                    alt="create ticket"
                                                />
                                                Create Ticket
                                            </Button>
                                        </Tooltip>
                                    </ListItem>
                                    {renderMoreOptions() && <ListItem>
                                        <>
                                            <Tooltip title="More Options" placement="top">
                                                <Link className="create-ticket" onClick={handleClick}>
                                                    <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>

                                                </Link>
                                            </Tooltip>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                {MoreOptions.map(item => {
                                                    return item.show && <MenuItem title={item.title} onClick={() => { item.onClick(); handleClose() }}>{item.Name}</MenuItem>
                                                })}
                                            </Menu>
                                        </>
                                    </ListItem>
                                    }
                                    
                                    {/* {userInfo && userInfo.AccessType && userInfo.AccessType === PB_AGENT && (groupID_store === 1607 || groupID_store === 1359 ) ? <ListItem id="pullTicket">
                                        <Button className="next-btn" color="primary" onClick={PullNextTicket}>
                                            {" "}
                                            Pull Ticket <i className="fa fa-hdd-o"></i>
                                        </Button>
                                    </ListItem> : null
                                    } */}

                                    {isOneTicket_store ? ( <ListItem id="pullTicket">
                                        <Button className="next-btn" color="primary" onClick={PullNextTicket}>
                                            {" "}
                                            Pull Ticket <i className="fa fa-hdd-o"></i>
                                        </Button>
                                        </ListItem> ) : null
                                    }
                                    {USERID_ALLOWED_FOR_APP_LOG(envUrl()).includes(userInfo.UserID) && <ListItem id="logOut">
                                        <Tooltip title="Log out" placement="top">
                                            <Link onClick={() => setShowLogout(true)}>
                                                <i className="fa fa-sign-out"></i>
                                            </Link>
                                        </Tooltip>
                                    </ListItem>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            {showConfetti && <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                tweenDuration={6000}
            />}
            {dialogWithControls && dialogWithControls.open &&
                <DialogFromRight
                    open={dialogWithControls.open}
                    handleClose={dialogWithControls.handleClose}
                    title={dialogWithControls.title}
                    children={dialogWithControls.children}
                    size={dialogWithControls.size}
                />
            }
            {
                <LogoutDialog
                    open={showLogout}
                    handleClose={() => setShowLogout(false)}
                    // handleLogout={logoutFnc}
                />
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        dashboardOption_store: state && state.pbAgentDashboard && state.pbAgentDashboard.optionSelected,
        isOneTicket_store:
            state &&
            state.auth &&
            state.auth.userInfo &&
            state.auth.userInfo.IsOneTicket, // isOneTicket is fetched from login object
        userID_store: 
            state && 
            state.auth && 
            state && state.auth && 
            state.auth.userInfo && 
            state.auth.userInfo.UserID,
        bucketDetails_store:
            state &&
            state.auth &&
            state.auth.userInfo &&
            state &&
            state.auth &&
            state.auth.userInfo.BucketDetails, // Bucket details for NextTicketPull
        productID_store:
            state &&
            state.auth &&
            state &&
            state.auth &&
            state.auth.userInfo &&
            state.auth.userInfo.ProductID,
        groupID_store:
            state &&
            state.auth &&
            state &&
            state.auth &&
            state.auth.userInfo &&
            state.auth.userInfo.GroupID,
        currentTicketID_store:
            state &&
            state.ticketdetails &&
            state.ticketdetails.ticketDetails &&
            state.ticketdetails.ticketDetails.TicketId,
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDashboardOptionSelected: (payload) => dispatch(setDashboardOption(payload)),
        setBucketDetails_store: (payload) => dispatch(setBucketDetails(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PBDashboardHeader)