import React from "react";
import { useHistory } from "react-router-dom";
import { TablePagination, Button, Grid } from '@material-ui/core';
import { exportDataToExcel } from "../../../../shared/methods/methods";
import Toast from "../../../../shared/components/Toast";
import AgentListItem from "./AgentListItem";
import { useEffect } from "react";


export default function AgentDashboardData({ dataToRender, fileNameForExport }) {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  // data is changes then Page selected is also changed to default 0
  useEffect(()=>{
    setPage(0);
  },[dataToRender])

  const exportToExcel = () => {
    try {
      let tempData = [...dataToRender];
      let data = tempData.map(item=>{
        return{
          'TicketID' : item.TicketDetailsID,
          'Email-ID': item.EmailId,
          'CreatedOn': new Date(item.CreatedOn).toLocaleString('en-US', {timeZone: "Asia/Kolkata"}),
          'LeadID': item.LeadID,
          "LeadStatus": item.Status,
          'TicketSource'	: item.TicketSource,
          'Issue'	: item.IssueName,
          'SubIssue'	: item.SubIssueName,
          'ProductName'	: item.ProductName,
          'SupplierName'	: item.SupplierName,
          'AssignedToName'	: item.AssignedToName,
          'FollowUpOn'	: item.FollowUpOn,
          'LastPBRepliedOn'	: item.LastUpdatedOn,
          'LastCustomerRepliedOn'	: item.LastRepliedOn,
          'Responsibility'	: item.ResponsibilityName,
          'TicketStatusName'	: item.TicketStatusName,
          'SubStatusName'	: item.SubStatusName || "",
          'RootCause'	: item.RootCause,
          'EsclationType'	: item.EscalationType,
          'EsclationDate'	: item.EscalationDate,
          'IsJAG'	: item.IsJAG,
          'JAGDate' :	item.JAGDate,
          'PriorityFlag' :	item.PriorityFlag,
          'CorporateName' : item.CorporateName
        }
      })

      exportDataToExcel(data, fileNameForExport || 'TicketList');
      Toast.success("Successfully exported!");
    }
    catch {
      Toast.error("Failed to export");
      console.log('Failed to export');
    }
  }


  return (
    <div className="agent-dashboard-box">
      {dataToRender && dataToRender.length > 0 &&
        <>
          <div className="agent-dashboard-box-inner">
            <Grid container spacing={0} alignItems="center">
              <Grid item md={12} sm={12} xs={12}>
                {/* <h4>Showing {(page+1)*rowsPerPage-rowsPerPage+1}-{(page+1)*rowsPerPage < dataToRender.length ? (page+1)*rowsPerPage : dataToRender.length} of {dataToRender.length} data</h4> */}
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Button className='export-btn' onClick={exportToExcel}>Export to Excel</Button>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <TablePagination
                  component="div"
                  count={dataToRender.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </div>
        </>
      }
      <div className="load-data-list scrool-toll ">
        <div>
          {dataToRender && dataToRender.length > 0 ? (
            <>
              <ul className="head-list">
                <li>Created On</li>
                <li>Ticket ID</li>
                <li>Email ID</li>
                <li>Lead ID</li>
                <li>CB Request</li>
                <li>Lead Status</li>
                <li>Source</li>
                <li>Issue Name</li>
                <li>Sub Issue</li>
                <li>Product</li>
                <li>Insurer</li>
                <li>Ticket Status</li>
                <li>Assigned To</li>
                <li>Due On</li>
                <li>#Call</li>
                <li>#Email</li>
                <li>Ageing</li>
                <li>PolicyNo</li>
                <li>RegistrationNo</li>
                <li>InsurerStatusDate</li>
                <li>ApplicationNo</li>
                <li>Last Customer Replied</li>
                <li>Last PB Replied</li>
                <li>TAT</li>
              </ul>
              
              <div className="body-list">
                {dataToRender
                  .slice((page + 1) * rowsPerPage - rowsPerPage, (page + 1) * rowsPerPage)
                  .sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))
                  .map((i, key) => {
                    return (
                     <AgentListItem key={'agentListItem'+key} i={i}/>
                    );
                  })}
              </div>
            </>
          ) : (
            <div className="load-data-head">
              <div className="no-data-found">
                <img src="images/no_data_found.svg" alt="no_data_found" />
                <p>No Data Available</p>
              </div>
            </div>
          )}
        </div>
        {/* } */}
      </div>
    </div>
  );
}
