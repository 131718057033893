import React, { useState, useEffect } from "react";
import { GET, POST, old_ticket_base_url, LOGIN_POST } from "../services/apiServices";
import {
  Login,
  EncryptDecryptCipherTextToPlainText,
  AuthLogin,
} from "../constants/apiUrls";
import { useHistory } from "react-router-dom";

import { useSelector, useDispatch,  } from "react-redux";
import { userInfo, authSet } from "../redux/actions/auth";
import { isEndorsementEligible } from "../Config";
import { STORAGE, openNewView } from "../shared/methods/methods";
import { HashLoader } from 'react-spinners';
import { resetAllSearchOptions } from "../redux/actions/pbDashboard_action";


export default function LandingPage({
  setIsActionsAllowed,
  setAccessTypeVal,
  setSourceForCreateTicket,
}) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(window.location.href);
  const history = useHistory();
  let str;
  let landingpage = "landingpage/";
  if (url.toLowerCase().includes(landingpage)) {
    let index1 = url.toLowerCase().indexOf(landingpage);
    str = url.substring(index1, index1 + landingpage.length);
  }

  let  encryptedParam ;
  if(url.split(str)[1] && !url.split(str)[1].includes("?id=")){
    encryptedParam = url.split(str)[1];
  }
  else{
    let string = "id="
    let index2 = url.toLowerCase().indexOf(string);
    let str2 = url.substring(index2, index2 + string.length);
    if(url.split(str2)[1]){
      encryptedParam = url.split(str2)[1]
    }
    else window.alert("Oops! please use correct path")
  }

  useEffect(() => {
    localStorage.clear('');
    const payload = {
      userInfo: encryptedParam,
    };
    LOGIN_POST(Login, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          dispatch(authSet(true))
          dispatch(userInfo(response.Data))
          localStorage.setItem("UserObjTicket", JSON.stringify(response.Data));
          localStorage.setItem("TicketURL", window.location.href);
          // for resetting the choice and stored query in dashboard
          dispatch(resetAllSearchOptions(true));
          STORAGE.deleteAuth()
          STORAGE.setAuthToken(response.Data.Token).then(()=>{
              console.log('token set')
              //For Dashboard Redirection
              if(response.Data.OpenDashboard){
                  return history.push('/dashboardV2');
              }
              if (response.Data.AccessType === 2) {
                setIsActionsAllowed(false);
                setAccessTypeVal(response.Data.AccessType);
                if (!response.Data.RefObjID && !response.Data.TicketDetailsID) {
                  history.push(
                    `/createTicket/${JSON.parse(atob(encryptedParam)).LeadId}`
                  );
                } else {
                  if (isEndorsementEligible(response.Data.ProductID) && openNewView(response?.Data?.ProductID)) {
                    history.push(
                      `/ticketdetails/${response.Data.RefObjID}/${response.Data.TicketDetailsID}/0`
                    );
                  } else {
                    history.push(
                      `/alltickets/${response.Data.RefObjID}/${response.Data.TicketDetailsID}/0`
                    );
                  }
                }
              }
              setSourceForCreateTicket(response.Data.Source);
              if (response.Data.AccessType != 4 && response.Data.AccessType != 2) {
                if (response.Data.RefObjID && response.Data.TicketDetailsID) {
                  if (isEndorsementEligible(response.Data.ProductID, response.Data?.SupplierId, response.Data?.InvestmentTypeID) && openNewView(response?.Data?.ProductID)) {
                    history.push(
                      `/ticketdetails/${response.Data.RefObjID}/${response.Data.TicketDetailsID}/0`
                    );
                  } else {
                    history.push(
                      `/alltickets/${response.Data.RefObjID}/${response.Data.TicketDetailsID}/0`
                    );
                  }
                } else {
                  history.push("/dashboard");
                }
              } else if (response.Data.AccessType == 4) {
                localStorage.setItem(
                  "OldTicketUrl",
                  `${old_ticket_base_url()}/home.html#/TicketSystem/LandingPage/${
                    response.Data.UserID
                  }/${response.Data.Name}/${response.Data.RoleName}/0/0/${
                    JSON.parse(atob(encryptedParam)).EId
                  }/0`
                );
                history.push("/smeemployeedetail");
              }
          })      
        }
        else{
          window.alert(response["Message"] || "Incorrent Path");
          history.push("/broken");
        } 
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="landing-page-style">
      <HashLoader color="green" />
      <div >We'll let you in soon...</div>
    </div>
  );
}