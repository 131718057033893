import React from 'react';
import { getReadableDate,getTimeIn12HourFormate } from "../../../shared/methods/methods";

const Stepper = ({GetBookingTimeRes}) => {
    
  
    return (
      
      <div className="stepper-wrap">
        {
        GetBookingTimeRes && GetBookingTimeRes.length>0
        &&
        GetBookingTimeRes.map((item)=>{
          return (
            <div className=
            {'stepper-item flex items-center' + 
            (item.CompleteDtStr && !item.IsActive ? ' success': 
            (item.IsActive ? ' current' : ' pending'))}
            >
                  <div className="icon-wrap w-10 tc">
                    <span className="icon"></span>
                  </div>
                  <div className="card-wrap w-90">
                    <div className="content">
                      <p>{item.Name}</p>
                      <span>{item.CompleteDt && getReadableDate(item.CompleteDt) + ' @ ' + getTimeIn12HourFormate(item?.CompleteDt?.split('T')[1])}</span>
                    </div>
                    
                  </div>
              </div>
          )
        })
        
      }
      </div>
      
    )
  }

  export default Stepper;