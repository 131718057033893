import * as type from "../types/types";

const initialState = {
  auth: null,
  authReport: {},
  error: null,
  userInfo: null,
  ProductAccess: null,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case type.AUTH_SET:
      return {
        ...state,
        auth: action.payload
      }
    case type.AUTH_LOGIN_REQUESTED:
      return {
        ...state,
      };
    case type.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        authReport: action.auth,
      };
    case type.AUTH_LOGIN_FAILED:
      return {
        ...state,
        error: action.message,
      };
    case type.USER_INFO:
      return{
        ...state,
        userInfo : action.payload
      };
    case type.USER_LOGOUT:
      console.log('Successfully logged out!!')
      return initialState;
    case type.SET_INSURER_PRODUCT_ACCESS:
      return {...state, ProductAccess : action.payload}
    case type.SET_BUCKET_DETAILS:
      return{
        ...state,
        userInfo:{
          ...state.userInfo,
          BucketDetails : action.payload
        }
      }
    default:
      return state;
  }
}