import { useEffect, useState, React } from "react";
import { ListItem, Grid, List, Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { GET, POST } from "../services/apiServices";
import { GetTicketCount, GetTicketData} from "../constants/apiUrls";
import LoadDataList from "./Components/LoadDataList";
import FullScreenDialog from "../Components/Topbar/components/CreateTicketDialog";
import { useSelector } from "react-redux";
import { showTopbarTicketForInsurer } from "../shared/methods/methods";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        background: "#fff",
    },
}));

const Dashboard = ({ isActionsAllowed = true }) => {
    const classes = useStyles();
    const userID_store = useSelector(state=> state && state.auth && state.auth.userInfo)
    const [ticketCount, setTicketCount] = useState(null);
    const [ticketData, setTicketData] = useState(null);
    const [isCreateTicketDialogOpen, setCreateTicketDialogOpen] = useState(null);
    const [downloadDataPayload, setDownloadDataPayload] = useState(null)
    const [sourceList, setSourceList] = useState(null);
    const [supplierList, setSupplierList] = useState([]);

    useEffect(() => {
        if (!ticketCount) {
            getTicketCount();
        }
    }, [ticketCount]);

    const getTicketCount = () => {
        const params = {
            DashboardType: 1,
            ProductId: -1,
            IsEsc: 0,
        };
        GET(
            `${GetTicketCount}/${params.DashboardType}/${params.ProductId}/${params.IsEsc}`
        )
            .then((res) => {
                // console.log(res);
                if (res && res.Data) {
                    setTicketCount(res.Data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDashboardData = (type) => {
        setTicketData(null);
        const payload = {
            "IsUpdatedOn": 0,
            "FilterString": "",
            "FromDate": 1635928641637,
            "ToDate": 1635928641637,
            "Source": "",
            "SubSource": "",
            "ProductID": 0,
            "SupplierID": 0,
            "CBRequest": 0,
            "StatusID": 0,
            "SubStatusID": 0,
            "TATFilter": 0,
            "InsurerStatusID": 0,
            "STP": -1,
            "IsEsc": 0,
            //"UserId": "8496",
            "Type": type,
            "UserType": 1,
            "ProductIDs": "-1",
            "SendToReport": ""
        }
        setDownloadDataPayload(payload)
        POST(GetTicketData, payload)
            .then((res) => {
                console.log(res);
                if (res && res.Data && res.Data.length > 0) {
                    setTicketData(res.Data);
                }
            })
            .catch(err => console.log(err))
    }

    const openCreateTicket = (event) => {
        if (event) {
            event.persist();
        }
        setCreateTicketDialogOpen(true)
    }

    const UserCheck = () =>{
        return showTopbarTicketForInsurer(userID_store?.UserID) ? true : false
    }

    return (
        <div className={classes.root}>
            <div className="dashboard-summary we-care-ticket-dashboard">
                <div className="dashboard-topbar">
                    <Grid container justify="center">
                        <Grid item md={6} xs={12}>
                            <h2>Ticket Summary</h2>
                        </Grid>
                        <Grid item md={6} xs={12} className="text-right">
                            <Button className="create-ticket" onClick={openCreateTicket}> <img src="/images/create_ticket_btn.svg" alt="create ticket" /> Create Tickets</Button>
                        </Grid>
                    </Grid>
                </div>
                <div className="ticket-summary-list">
                    <List>
                        <ListItem onClick={() => getDashboardData(1)}>
                            <div className="new-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>New Ticket</span>
                                <p>{ticketCount ? ticketCount.NewCase : null}</p>
                            </div>
                        </ListItem>
                        <ListItem onClick={() => getDashboardData(2)}>
                            <div className="due-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>Over Due</span>
                                <p>{ticketCount ? ticketCount.OverDue : null}</p>
                            </div>
                        </ListItem>
                        <ListItem onClick={() => getDashboardData(3)}>
                            <div className="duetoday-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>Due Today</span>
                                <p>{ticketCount ? ticketCount.DueToday : null}</p>
                            </div>
                        </ListItem>
                        <ListItem onClick={() => getDashboardData(4)}>
                            <div className="Unassigned-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>Unassigned</span>
                                <p>{ticketCount ? ticketCount.UnAssignedCase : null}</p>
                            </div>
                        </ListItem>
                        <ListItem onClick={() => getDashboardData(5)}>
                            <div className="open-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>Open</span>
                                <p>{ticketCount ? ticketCount.OpenCase : null}</p>
                            </div>
                        </ListItem>
                        {
                            isActionsAllowed
                            &&
                            <ListItem onClick={() => getDashboardData(6)}>
                                <div className="revert-ticket">
                                    <em>
                                        <i className="fa fa-ticket" aria-hidden="true"></i>
                                    </em>
                                    <span>Insurer Reverts</span>
                                    <p>{ticketCount ? ticketCount.InsurerRevert : null}</p>
                                </div>
                            </ListItem>
                        }
                        <ListItem onClick={() => getDashboardData(7)}>
                            <div className="cust-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>Cust Reply</span>
                                <p>{ticketCount ? ticketCount.CustReply : null}</p>
                            </div>
                        </ListItem>
                        <ListItem onClick={() => getDashboardData(8)}>
                            <div className="reopen-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>ReOpen</span>
                                <p>{ticketCount ? ticketCount.ReOpen : null}</p>
                            </div>
                        </ListItem>
                        <ListItem onClick={() => getDashboardData(9)}>
                            <div className="resolved-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>Resolved Today</span>
                                <p>{ticketCount ? ticketCount.ResolvedToday : null}</p>
                            </div>
                        </ListItem>
                        <ListItem onClick={() => getDashboardData(10)}>
                            <div className="escalation-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>Escalation</span>
                                <p>{ticketCount ? ticketCount.Escalation : null}</p>
                            </div>
                        </ListItem>
                        {UserCheck() && <ListItem onClick={() => getDashboardData(14)}>
                            <div className="escalation-ticket">
                                <em>
                                    <i className="fa fa-ticket" aria-hidden="true"></i>
                                </em>
                                <span>All Tickets</span>
                                <p>{ticketCount?.AllTickets || 0}</p>
                            </div>
                        </ListItem>
                            }
                    </List>
                </div>
                {/* <SearchRadio 
            getDashboardData={getDashboardData}
            //getDashboardDataExport={getDashboardDataExport}
            isActionsAllowed = {isActionsAllowed}
        /> */}
                {ticketData && Array.isArray(ticketData)&& <LoadDataList 
                    ticketData={ticketData}
                    passedPayload={downloadDataPayload}
                />}
            </div>
            <FullScreenDialog
                isCreateDialogOpen={isCreateTicketDialogOpen}
                setCreateDialog={setCreateTicketDialogOpen}
                sourceList={sourceList}
                TicketDetailsID={null}
                RefObjectId={null}
                supplierList={supplierList}
            />
        </div>
    );
};

export default Dashboard;
