import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { BeatLoader, HashLoader } from 'react-spinners';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        flexDirection:"column"
    },
}));

export default function CustomBackdrop(props) {
    const { open, textToShow} = props;
    const classes = useStyles();

    return (
        <div id='backdrop-with-loader'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                className={classes.backdrop} open={open}>
                {props.children ? props.children :
                    <>
                        <HashLoader color="white" />
                        <div style={{padding:"10px 0 0"}}>{textToShow}</div>
                    </>}

            </Backdrop>
        </div>
    )
}