import { useEffect, useState, React } from "react";
import { ListItem, List } from '@material-ui/core'

import { makeStyles } from "@material-ui/core/styles";
import { GET } from "../../../../services/apiServices";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { fetchDashboardData_method, handleCatch } from "../../../../shared/methods/apiMethods";
import CustomBackdrop from "../../../Components/CustomBackdrop";
import { HashLoader } from 'react-spinners';
import { Toast } from "../../../../shared/components";
import { GetDisabledEndPoints } from "../../../../shared/methods/GlobalSelector";
import { CheckIfEndPointIsDisabled } from "../../../../shared/methods/methods";

const useStyles = makeStyles((theme) => ({
    root: {
        margin:'30px 0 0',
    },
}));

const AgentDashboardCount = (props) => {
    const classes = useStyles();
    const { setTypeDataToRender, setShowDataToRender } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const agent_ProductID = useSelector(state=> state && state.auth && state.auth.userInfo && state.auth.userInfo.ProductID)
    const [ticketCount, setTicketCount] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const disabledEndPoints_store = GetDisabledEndPoints()

    
    useEffect(()=>{
        fetchAgentDashboardCount();
    },[])

    const fetchAgentDashboardCount = () =>{
        let url = `/GetTicketDashboardCounts/1/${agent_ProductID}/0`;
        GET(url, null, 'Dashboard').then(res=>{
            if(res && res.ErrorCode === 0){
                setTicketCount(res.Data)
            }
            else showMessage(res["Message"] || "Failed to fetch dashboard counts","error")
        }).catch(err=>{
            handleCatch(err, showMessage);
        })
    }


    const showMessage = (message, varient) => {
        return enqueueSnackbar(message, {
            variant: varient, onClick: () => {
                closeSnackbar();
            },
        });
    }
    
    const checkDisabled = CheckIfEndPointIsDisabled(disabledEndPoints_store, 'GetAgentTicketsList')

    const getDashboardData = (type, value, fileName) => {
        if(checkDisabled){ return}
        if (!value) { return Toast.error(`No Data Available For - ${fileName}`) };
        setDataLoading(true);
        const payload = {
            "IsUpdatedOn": 0,
            "FilterString": "",
            "FromDate": 1635928641637,
            "ToDate": 1635928641637,
            "Source": "",
            "SubSource": "",
            "ProductID": 0,
            "SupplierID": 0,
            "CBRequest": 0,
            "StatusID": 0,
            "SubStatusID": 0,
            "TATFilter": 0,
            "InsurerStatusID": 0,
            "STP": -1,
            "IsEsc":  0,
            "IsTAT": 0,
            //"UserId": "8496",
            "Type": type,
            "ProductIDs": agent_ProductID,
            "SendToReport": ""
        }
        let url = '/GetAgentTicketsList';
        let callback = (data) => {
            setDataLoading(false)
            console.log('data-->', data);
            setTypeDataToRender(data);
            setShowDataToRender(true)
        }
        fetchDashboardData_method(url, payload, callback, showMessage)
    }

    return (
        <div className={classes.root}>
            {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color="white"/></CustomBackdrop> : ""}
            <div className="pbdashboard-summary ">
                <div className="ticket-summary-list">
                <List>
                    <ListItem id="agentNewCase" disabled={checkDisabled} onClick={() => getDashboardData(2, ticketCount && ticketCount.NewCase || 0, 'New Ticket')}>
                    <div className="new-ticket">
                        <span>New Ticket</span>
                        <p>{ticketCount ? ticketCount.NewCase : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentOverDue" disabled={checkDisabled} onClick={() => getDashboardData(3, ticketCount && ticketCount.OverDue || 0,'Over Due')}>
                    <div className="due-ticket">
                        <span>Over Due</span>
                        <p>{ticketCount ? ticketCount.OverDue : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentDueToday" disabled={checkDisabled} onClick={() => getDashboardData(4, ticketCount && ticketCount.DueToday || 0,'Due Today')}>
                    <div className="duetoday-ticket">
                        <span>Due Today</span>
                        <p>{ticketCount ? ticketCount.DueToday : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentUnAssignedCase" disabled={checkDisabled} onClick={() => getDashboardData(5, ticketCount && ticketCount.UnAssignedCase || 0,'Total Assigned')}>
                    <div className="Unassigned-ticket">
                        <span>Total Assigned</span>
                        <p>{ticketCount ? ticketCount.UnAssignedCase : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentPriorityCase" disabled={checkDisabled} onClick={() => getDashboardData(6, ticketCount && ticketCount.PriorityCase || 0,'OB Call')}>
                    <div className="open-ticket">
                        <span>OB Call</span>
                        <p>{ticketCount ? ticketCount.PriorityCase : null}</p>
                    </div>
                    </ListItem>
                    {
                    <ListItem id="agentPendencyCompleted" disabled={checkDisabled} onClick={() => getDashboardData(10, ticketCount && ticketCount.PendencyCompleted || 0,'Insurer Reverts')}>
                    <div className="revert-ticket">
                        <span>Insurer Reverts</span>
                        <p>{ticketCount ? ticketCount.PendencyCompleted : null}</p>
                    </div>
                    </ListItem>
                    }
                    <ListItem id="agentAbondon" disabled={checkDisabled} onClick={() => getDashboardData(11, ticketCount && ticketCount.Abandon || 0,'Cust Reply')}>
                    <div className="cust-ticket">
                        <span>Cust Reply</span>
                        <p>{ticketCount ? ticketCount.Abandon : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentTatBrust" disabled={checkDisabled} onClick={() => getDashboardData(12, ticketCount && ticketCount.TatBurst || 0,'TAT Burst')}>
                    <div className="reopen-ticket">
                        <span>Tat Burst</span>
                        <p>{ticketCount ? ticketCount.TatBurst : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentReOpen" disabled={checkDisabled} onClick={() => getDashboardData(16, ticketCount && ticketCount.ReOpen || 0, 'Reopen')}>
                    <div className="resolved-ticket">
                        <span>ReOpen</span>
                        <p>{ticketCount ? ticketCount.ReOpen : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentEscalation" disabled={checkDisabled} onClick={() => getDashboardData(15, ticketCount && ticketCount.Escalation || 0,'Escalation')}>
                    <div className="escalation-ticket">
                        <span>Escalation</span>
                        <p>{ticketCount ? ticketCount.Escalation : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentGreaterThan180" disabled={checkDisabled} onClick={() => getDashboardData(17, ticketCount && ticketCount.GreaterThan180 || 0,'Open > 180 Days')}>
                    <div className="green-ticket">
                        <span>{"Open > 180"}</span>
                        <p>{ticketCount ? ticketCount.GreaterThan180: null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentInsurerPendency" disabled={checkDisabled} onClick={() => getDashboardData(18, ticketCount && ticketCount.InsurerPendency || 0,'Iusurer Pendency')}>
                    <div className="renewal-ticket">
                        <span>Insurer Pendency</span>
                        <p>{ticketCount ? ticketCount.InsurerPendency : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentJAG" disabled={checkDisabled} onClick={() => getDashboardData(19, ticketCount && ticketCount && ticketCount.JAG || 0,'JAG')}>
                    <div className="jag-ticket">
                        <span>JAG</span>
                        <p>{ticketCount ? ticketCount.JAG : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentNilEndo" disabled={checkDisabled} onClick={() => getDashboardData(20, ticketCount && ticketCount.NilEndo || 0,'Nil Endorsement')}>
                    <div className="nil-endorsement-ticket">
                        <span>Nil Endorsement</span>
                        <p>{ticketCount ? ticketCount.NilEndo : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentInsurerInitiated" disabled={checkDisabled} onClick={() => getDashboardData(21, ticketCount && ticketCount.InsurerInitiated || 0,'Insurer Initiated')}>
                    <div className="insurer-initiated-ticket">
                        <span>Insurer Initiated</span>
                        <p>{ticketCount ? ticketCount.InsurerInitiated : null}</p>
                    </div>
                    </ListItem>
                    <ListItem id="agentSelfEndorsement" disabled={checkDisabled} onClick={() => getDashboardData(22, ticketCount && ticketCount.SelfEndorsement || 0,'SelfEndorsement')}>
                        <div className="revert-ticket" id="SelfEndorsement">
                        <span>Self Endorsement</span>
                        <p>{ticketCount ? ticketCount.SelfEndorsement : null}</p>
                        </div>
                    </ListItem>
                    <ListItem id="agentCustomerPendency" disabled={checkDisabled} onClick={() => getDashboardData(23, ticketCount && ticketCount.CustomerPendency || 0,'CustomerPendency')}>
                        <div className="renewal-ticket" id="CustomerPendency">
                        <span>Customer Pendency</span>
                        <p>{ticketCount ? ticketCount.CustomerPendency : null}</p>
                        </div>
                    </ListItem>
                </List>
                </div>
            </div>
        </div>
    );
};

export default AgentDashboardCount;
