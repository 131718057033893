import { GET } from '../../services/apiServices';
import { ReduxActionGeneratorFunction, handleCatch } from '../../shared/methods/apiMethods';
import * as ActionType from '../types/types';

export const setProductSupplierMaster = (payload) => {
    return {
        type: ActionType.SET_PRODUCT_SUPPLIER_MASTER,
        payload
    }
}

export const setStatusSubStatusMaster = (payload) => {
    return {
        type: ActionType.SET_STATUS_SUB_STATUS_MASTER,
        payload
    }
}

export const setTicketSourceMaster = (payload) => {
    return {
        type: ActionType.SET_TICKET_SOURCE_MASTER,
        payload
    }
}

export const setTicketSubSourceMaster = (payload) => {
    return {
        type: ActionType.SET_TICKET_SUB_SOURCE_MASTER,
        payload
    }
}


export const fetchMasterList = () => dispatch => {
    GET("GetTicketDetails")
        .then((res) => {
            if (
                res &&
                res.ErrorCode === 0
            ) {
                dispatch(setProductSupplierMaster(res.Data.suppliersList));
                dispatch(setStatusSubStatusMaster(res.Data.statusCollections));
                dispatch(setTicketSourceMaster(res.Data.ticketSources));

            }
        })
        .catch((err) => {
            console.log("error", err);
        });
}

export const setProductGroupMaster = (payload) =>{
    return ReduxActionGeneratorFunction(ActionType.SET_PRODUCT_GROUP_MASTER, payload)
}
export const fetchProductGroupMaster = () => dispatch => {
    GET("GetProductGroupMaster", null, 'Dashboard').then(res => {
        if (res && res.ErrorCode === 0) {
            dispatch(setProductGroupMaster(res['Data']));
        }
    }).catch(err=>{
        handleCatch(err)
    })
}


export const fetchSourceSubSourceMasterV2 = () => dispatch =>{
    GET('GetTicketMaster/TicketSourceMaster/0').then(res=>{
        if(res && res.ErrorCode === 0){
            dispatch(setTicketSourceMaster(res['Data']['ticketSources'] || []))
        }
    }).catch(err=>{
        handleCatch(err)
    })
}

export const fetchStatusSubStatusMasterV2 = () => dispatch =>{
    GET('GetTicketMaster/TicketStatusMaster/0').then(res=>{
        if(res && res.ErrorCode === 0){
            dispatch(setStatusSubStatusMaster(res['Data']['statusCollections'] || []))
        }
    }).catch(err=>{
        handleCatch(err)
    })
}

export const fetchSupplierMasterV2 = () => dispatch =>{
    GET('GetTicketMaster/SupplierMaster').then(res=>{
        if(res && res['ErrorCode'] ===0){
            dispatch(setProductSupplierMaster(res['Data']['suppliersList']))
        }
    }).catch(err=>{
        handleCatch(err);
    })
}

export const setScreenName = (payload) => ReduxActionGeneratorFunction(ActionType.SCREEN_NAME, payload)