import { POST } from "../../services/apiServices";
import { handleCatch } from "../../shared/methods/apiMethods";
import { STORAGE } from "../../shared/methods/methods";
import * as type from "../types/types";

export function authLogin() {
  console.log('auth login ')
  return {
    type: type.AUTH_LOGIN_REQUESTED,
  };
}

export function userLogout(){
  return {
    type: type.USER_LOGOUT
  }
}

export function userInfo(data){
  return {
    type: type.USER_INFO,
    payload: data
  }
} 

export function authSet(payload){
  return {
    type: type.AUTH_SET,
    payload
  }
}

export const setBucketDetails = ( payload )=>{
  return {
    type : type.SET_BUCKET_DETAILS,
    payload
  }
}

export const setInsurerProductAccess = ( payload )=>{
  return {
    type : type.SET_INSURER_PRODUCT_ACCESS,
    payload
  }
}


export const AuthLoginCommonMethod = () => dispatch =>{
  STORAGE.getAuthToken().then(token=>{
    const payload1 = {
      token: token,
    };
    return POST('/AuthLogin', payload1).then(res=>{
      if(res && res.ErrorCode === 0){
        dispatch(userInfo(res.Data))
      }
      else{
        dispatch(authSet(false));
        dispatch(userInfo(null));
      }
    })
    .catch((error) => {
      handleCatch(error)
    });
  })
}
