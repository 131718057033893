import * as type from "../types/types";

const initialState = {
  optionSelected: "1",
  zeroLeadSelected : false,
  adminSimpleSearch:{
    FilterString : ""
  },
  adminAdvanceSearch:{
    IsUpdatedOn: "0",
    FromDate: new Date(),
    ToDate: new Date(),
    Source: "",
    SubSource: "",
    CBRequest: 0,
    STP: 0,
    StatusID: 0,
    SubStatusID: 0,
    InsurerStatusID: 0,
    TATFilter: 0,
    TicketNotReply: 0,
    IsEsc: 0,
    ProductID: [],
    SupplierID: 0,
  },
  agentSimpleSearch :{
    TicketID: "",
    LeadID: "",
    MobileNo: "",
    EmailId: "",
  },
  agentAdvanceSearch:{
    FromDate: new Date(),
      ToDate: new Date(),
      Source: "",
      ProductID: 0,
      SupplierID: 0,
      CustomerName:'',
      TicketNotReply: 0,
      StatusID: 0,
      TATFilter: 0,
      IsEsc: 0, 
  },
  pageSelected: null,
  // dataToRender : null,
  // dataLoading : false,
  // productSupplierMaster: []
};

const resetStateToInitial = (state, payload) => {
  if (payload) {
    return initialState
  }
  else {
    return {
      ...state,
      adminSimpleSearch: {
        FilterString: ""
      },
      adminAdvanceSearch: {
        IsUpdatedOn: "0",
        FromDate: new Date(),
        ToDate: new Date(),
        Source: "",
        SubSource: "",
        CBRequest: 0,
        STP: 0,
        StatusID: 0,
        SubStatusID: 0,
        InsurerStatusID: 0,
        TATFilter: 0,
        TicketNotReply: 0,
        IsEsc: 0,
        ProductID: [],
        SupplierID: 0,
      },
      agentSimpleSearch: {
        TicketID: "",
        LeadID: "",
        MobileNo: "",
        EmailId: "",
      },
      agentAdvanceSearch: {
        FromDate: new Date(),
        ToDate: new Date(),
        Source: "",
        ProductID: 0,
        SupplierID: 0,
        CustomerName: '',
        TicketNotReply: 0,
        StatusID: 0,
        TATFilter: 0,
        IsEsc: 0,
      },
    }
  }
}

export default function pbDashboard_reducer(state = initialState, action) {
    switch (action.type) {
      case type.SET_ADMIN_SIMPLE_SEARCH_VALUE:
        return {
          ...state,
          adminSimpleSearch: { 'FilterString' : action.payload },
        };
      case type.SET_ADMIN_ADVANCED_SEARCH_VALUES:
        return {
          ...state,
          adminAdvanceSearch: action.payload,
        };
      case type.SET_AGENT_SIMPLE_SEARCH_DATA:
          return {
            ...state,
            agentSimpleSearch: action.payload,
        };
      case type.SET_AGENT_ADVANCED_SEARCH_DATA:
        return {
          ...state,
          agentAdvanceSearch: action.payload,
        };
      case type.SET_DASHBOARD_OPTION:
        return {
          ...state,
          optionSelected: action.payload
        }
      case type.PAGE_SELECTED:
        return{
          ...state,
          pageSelected: action.payload
        }
      case type.SET_ZERO_LEAD_SELECTED:
        return{
          ...state,
          zeroLeadSelected: action.payload
        }
      case type.RESET_SEARCH_OPTIONS:
        return resetStateToInitial(state, action.payload);
    default : return state
    }
}