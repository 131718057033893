import React, { useCallback, useEffect, useState, useReducer } from "react";
import { GET, POST } from "../../../services/apiServices";
import { GetTicketListForInsurerAgent } from "../../../constants/apiUrls";
import { ExportTicketListForInsurerAgent } from "../../../constants/apiUrls";
import {
  Grid,
  Button,
} from "@material-ui/core";
import SearchFilter from "./SearchFilter";
import { convertToIsoFormate, ValidProductSupplier } from "../../../shared/methods/methods";
import { useHistory } from "react-router-dom";
import Spinner from "../../../shared/components/Spinner";
import { download } from "../../../shared/methods/methods";
import Pagination from "@material-ui/lab/Pagination";
import { isEndorsementEligible } from "../../../Config";
import { connect, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip';
import { handleCatch } from "../../../shared/methods/apiMethods";


const InsurerAgentDashboard = ({ userInfo }) => {
  const user_store = useSelector(state=> state.auth.userInfo)
  var [data, setData] = React.useState(null);
  const [detail, setDetail] = React.useState(null);
  const [searchParamsParent, setSearchParamsParent] = useState(null);
  const [type, setType] = useState(1);
  const history = useHistory();
  const [showNoData, setShowNoData] = useState(false);
  const [isTicketsLoading, setTicketsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [sortedData, setSortedData] = useState(null);
  const [state, setState] = useReducer(
    (a,b) =>({...a, ...b}),{
      renderEndorsementBucket : false
    }
  )
 
  useEffect(() => {
    if (searchParamsParent) {
      // handleSubmit(null, type);
    }
  }, [searchParamsParent]);

  useEffect(() => {
    if (data) {
      let start = rowsPerPage * (pageIndex - 1);
      let end =
        rowsPerPage * pageIndex > data.length
          ? data.length
          : rowsPerPage * pageIndex;
      let a = data.slice(start, end);
      setPagination((pagination) => ({
        ...pagination,
        countBullet: Math.ceil(data.length / rowsPerPage),
        showingText: a.length < rowsPerPage ? a.length : rowsPerPage,
      }));
      setSortedData(a);
    }
  }, [pageIndex, data]);

  const handleSubmit = (event, type = 1, searchParamsParent) => {
    if (event && event.persist) {
      event.persist();
    }
    if(isTicketsLoading){ return } // if Submit button already pressed then nothing happens
    setTicketsLoading(true);
    setType(type);
    const payload = {
      TicketDetailsID: searchParamsParent?.TicketDetailsID || "",
      LeadID: searchParamsParent?.LeadID || 0,
      FromDate: new Date(searchParamsParent?.FromDate).getTime(),
      ToDate: new Date(searchParamsParent?.ToDate).getTime(),
      StatusID: searchParamsParent?.StatusID || 0,
      ProductID: searchParamsParent?.ProductID,
      SupplierID: searchParamsParent?.SupplierID || 61,
      Source: searchParamsParent?.SourceId || "",
      QueueID: searchParamsParent?.QueueID || 0,
      TicketAssignmentType: searchParamsParent?.TicketAssignmentType || 1,
      SubStatusID: searchParamsParent?.SubStatusID || 0,
      Type: type,
      Ageing: searchParamsParent?.Ageing || 0,
      GroupID: searchParamsParent?.GroupID || 0,
      EmailId: searchParamsParent?.EmailId || "",
      CustomerName: searchParamsParent?.CustomerName || "",
      TicketsCount: searchParamsParent?.TicketsCount || 0,
      EmailsCount: searchParamsParent?.EmailsCount || 0,
      CallsCount: searchParamsParent?.CallsCount || 0,
      CBRequest: searchParamsParent?.CBRequest || 0,
      IssueID: searchParamsParent?.IssueID || 0,
      SubIssueID: searchParamsParent?.SubIssueID || 0,
      InsurerStatusID: searchParamsParent?.InsurerStatusID || 0,
      RegistrationNo: searchParamsParent?.RegistrationNo || "",
      PolicyNo: searchParamsParent?.PolicyNo || "",
      IsInsurer: searchParamsParent?.IsInsurer || 1,
      IsAttachment: searchParamsParent?.IsAttachment || -1,
      ApplicationNo: searchParamsParent?.ApplicationNo || "",
      TATFilter: searchParamsParent?.TATFilter || 0,
      IssueCode: searchParamsParent?.IssueList?.join() || '',
      SubIssueCode: searchParamsParent?.SubIssueList?.join() || '',
    };
    setPageIndex(1);
    POST(GetTicketListForInsurerAgent, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          if (response?.Data?.length) {
            setData(response.Data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        }
        setTicketsLoading(false);
      })
      .catch((err) => {
        setTicketsLoading(false);
        handleCatch(err)
      })
  };


  const handlePagination = (event, value) => {
    setPageIndex(value);
  };

  const getDetails = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    const DashboardType = 5;
    GET(
      `GetDashboardCountForInsurerAgent/${DashboardType}/${userInfo.ProductID}/0/${userInfo.InsurerID}/0/0`
    )
      .then((response) => {
        if (response.ErrorCode == 0) {
          let Data = response?.Data??[]
          setDetail((detail) => ({
            ...detail,
            ...Data
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const ExportData = (event) => {
    if (event && event.persist) {
      event.persist();
    }

    const payload = {
      TicketDetailsID: searchParamsParent?.TicketDetailsID || "",
      LeadID: searchParamsParent?.LeadID || 0,
      FromDate: new Date(searchParamsParent?.FromDate).getTime(),
      ToDate: new Date(searchParamsParent?.ToDate).getTime(),
      StatusID: searchParamsParent?.StatusID || 0,
      ProductID: searchParamsParent?.ProductID,
      SupplierID: searchParamsParent?.SupplierID || 61,
      Source: searchParamsParent?.SourceId || "",
      QueueID: searchParamsParent?.QueueID || 0,
      TicketAssignmentType: searchParamsParent?.TicketAssignmentType || 1,
      SubStatusID: searchParamsParent?.SubStatusID || 0,
      Type: type,
      Ageing: searchParamsParent?.Ageing || 0,
      GroupID: searchParamsParent?.GroupID || 0,
      EmailId: searchParamsParent?.EmailId || "",
      CustomerName: searchParamsParent?.CustomerName || "",
      TicketsCount: searchParamsParent?.TicketsCount || 0,
      EmailsCount: searchParamsParent?.EmailsCount || 0,
      CallsCount: searchParamsParent?.CallsCount || 0,
      CBRequest: searchParamsParent?.CBRequest || 0,
      IssueID: searchParamsParent?.IssueID || 0,
      SubIssueID: searchParamsParent?.SubIssueID || 0,
      InsurerStatusID: searchParamsParent?.InsurerStatusID || 0,
      RegistrationNo: searchParamsParent?.RegistrationNo || "",
      PolicyNo: searchParamsParent?.PolicyNo || "",
      IsInsurer: searchParamsParent?.IsInsurer || 1,
      IsAttachment: searchParamsParent?.IsAttachment || -1,
      ApplicationNo: searchParamsParent?.ApplicationNo || "",
      TATFilter: searchParamsParent?.TATFilter || 0,
      IssueCode: searchParamsParent?.IssueList?.join() || '',
      SubIssueCode: searchParamsParent?.SubIssueList?.join() || '',
    };

    POST(ExportTicketListForInsurerAgent, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          download("Ticket_Details.xlsx", response.Data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() =>{
    if(detail && user_store){
      checkIfEndorsementCasesToRender()
    }
  },[detail])

  const checkIfEndorsementCasesToRender = useCallback(()=>{
    let tempArr = user_store?.SupplierDetails
    if(tempArr?.length){
      for(let i=0; i<tempArr?.length; i++){
        let { ProductId, InsurerId } = tempArr[i]
        if(ValidProductSupplier(ProductId, InsurerId)){
          setState({ renderEndorsementBucket : true })
          break
        }
      }
    }
  },[detail])

  let header = [
    "S.No",
    "Ticket Date",
    "Ticket ID",
    "Assigned Date",
    "Lead ID",
    "Application No",
    "Policy No",
    "Issue",
    "Sub Issue",
    "Ticket Status",
    "Insurer Status",
    "Insurer Response Date",
    "PB Response Date",
    "Email",
    "Ageing",
    "Registration No"
  ];

  return (
    <div className="insurer-admin-dash">
      <div className="ticket-list-view">
        <ul>
          <li onClick={(event) => handleSubmit(event, 8)}>
            <div className="ticket-box">
              <div className="ticket-haed open-ticket">
                <img src="images/open_ticket.png" />
              </div>
              <div className="ticket-text">
                <p className="open-ticket">
                  {detail?.OpenCase}
                  <span>Open</span>
                </p>
              </div>
            </div>
          </li>
          <li onClick={(event) => handleSubmit(event, 2)}>
            <div className="ticket-box">
              <div className="ticket-haed  new-ticket">
                {/* <i className="fa fa-ticket"></i> */}
                <img src="images/new_ticket.png" />
                <em className="new-ticket"></em>
              </div>
              <div className="ticket-text">
                <p className="new-ticket">
                  {detail?.NewCase}
                  <span>New Ticket</span>
                </p>
              </div>
            </div>
          </li>

          <li onClick={(event) => handleSubmit(event, 10)}>
            <div className="ticket-box">
              <div className="ticket-haed pb-pendency">
                <img src="images/pb_pendency.png" alt="Pb Pendency" />
              </div>
              <div className="ticket-text">
                <p className="pb-pendency">
                  {detail?.PendencyCompleted}
                  <span>Pendency Completed</span>
                </p>
              </div>
            </div>
          </li>
          <li onClick={(event) => handleSubmit(event, 17)}>
            <div className="ticket-box">
              <div className="ticket-haed endoresment-ticket">
                <img src="images/endorsement_ticket.png" alt="endorsement" />
              </div>
              <div className="ticket-text">
                <p className="endoresment-ticket">
                  {detail?.EndorsementTickets}
                  <span>Endorsement</span>
                </p>
              </div>
            </div>
          </li>
          <li onClick={(event) => handleSubmit(event, 18)}>
            <div className="ticket-box">
              <div className="ticket-haed  pt-miss">
                <img src="images/pt_miss_ticket_new.png" alt="Pt MIss" />
              </div>
              <div className="ticket-text">
                <p className="pt-miss">
                  {detail?.PTMiss}
                  <span>PT Miss</span>
                </p>
              </div>
            </div>
          </li>
          <li onClick={(event) => handleSubmit(event, 19)}>
            <div className="ticket-box">
              <div className="ticket-haed reopen-ticket">
                <img src="images/reopen_ticket.png" alt="reopen_ticket" />
              </div>
              <div className="ticket-text">
                <p className="reopen-ticket">
                  {detail?.ReOpen}
                  <span>ReOpen</span>
                </p>
              </div>
            </div>
          </li>
          <li onClick={(event) => handleSubmit(event, 20)}>
            <div className="ticket-box">
              <div className="ticket-haed tat-brust">
                <img src="images/tat_brust_ticket.png" alt="tat_brust" />
              </div>
              <div className="ticket-text">
                <p className="tat-brust">
                  {detail?.GreaterThan7Days}
                  <span>&gt;7 Days</span>
                </p>
              </div>
            </div>
          </li>
          <li onClick={(event) => handleSubmit(event, 21)}>
            <div className="ticket-box">
              <div className="ticket-haed resolved-today">
              <img src="images/pb_pendency.png" alt="Resolved_today" />
              </div>
              <div className="ticket-text">
                <p className="resolved-today">
                  {detail?.ResolvedToday}
                  <span>Resolved Today</span>
                </p>
              </div>
            </div>
          </li>
          {state?.renderEndorsementBucket && <li onClick={(event) => handleSubmit(event, 22)}>
            <div className="ticket-box">
              <div className="ticket-haed resolved-today">
              <img src="images/pb_pendency.png" alt="Resolved_today" />
              </div>
              <div className="ticket-text">
                <p className="resolved-today">
                  {detail?.EscalationCase??0}
                  <span>Escalation Case</span>
                </p>
              </div>
            </div>
          </li>}
        </ul>
      </div>
      <SearchFilter
        setSearchParamsParent={setSearchParamsParent}
        handleSubmit={handleSubmit}
        setType={setType}
      />
      {isTicketsLoading ? (
        <div className="spinner-loader">
          <Spinner />
        </div>
      ) : !showNoData && data?.length ? (
        <div className="summary-list">
          {/* {!showNoData && sortedData?.length && (
            <strong>
              <span>Count: {sortedData.length}</span>
            </strong>
          )} */}
          {!showNoData && sortedData?.length && (
             <Grid container spacing={1}>
              <Grid itme md={6} xs={12}>
                <h3 className="pagination-data">
                Showing &nbsp; {pagination?.showingText} of {data.length} Results
              </h3>
              </Grid>
              <Grid itme md={6} xs={12}>
              <div style={{position:"relative",}}>
                <span className="pagination-view">
                  <Tooltip title="export" placement="top">
                    <Button className="export" onClick={(event) => ExportData(event)}>  
                    <img src="images/export_icon.png" alt="export_icon.png"/> 
                     </Button>
                     </Tooltip>
                    {!showNoData && (
                      <Pagination
                        count={pagination?.countBullet}
                        page={pageIndex}
                        onChange={handlePagination}
                        shape="rounded"
                      />
                    )}
                  </span>
                  </div>
              </Grid>
             </Grid>
          )}
          {!showNoData && sortedData?.length ? (
            <div className="suammry-list-view scrool-toll">
              <ul>
              {header.map(i=> <li><span>{i}</span></li>)}
              </ul>

              {sortedData?.map((row, index) => {
                return (
                  <ul key={index}>
                    <li>
                      <strong>{(pageIndex-1)*10 + (index + 1)}</strong>
                    </li>
                    <li>
                      <strong>{convertToIsoFormate(new Date())}</strong>
                    </li>
                    <li>
                      <strong>
                        <a
                          onClick={() => {
                            if (isEndorsementEligible(row.ProductID)) {
                              history.push(
                                `/insurerticket/${row.RefObjectId}/${row.TicketDetailsID}/1`
                              );
                            } else {
                              history.push(
                                `/insurerticket/${row.RefObjectId}/${row.TicketDetailsID}/1`
                              );
                            }
                          }}
                        >
                          {row.TicketDetailsID}
                        </a>
                      </strong>
                    </li>
                    <li>
                      <strong>{row.InsurerAssignDate ? convertToIsoFormate(row.InsurerAssignDate) : '-'}</strong>
                    </li>
                    <li>
                      <strong> {row.LeadID}</strong>
                    </li>
                    <li>
                      <strong>{row.ApplicationNo}</strong>
                    </li>
                    <li>
                      <strong>{row.PolicyNo || "-"}</strong>
                    </li>
                    <li>
                      <strong>{row.IssueName}</strong>
                    </li>
                    <li>
                      <strong>{row.SubIssueName}</strong>
                    </li>
                    <li>
                      <strong>{row.Status}</strong>
                    </li>
                    <li>
                      <strong>{row.InsurerStatusName}</strong>
                    </li>
                    <li>
                      <strong>{convertToIsoFormate(new Date())}</strong>
                    </li>
                    <li>
                      <strong> {convertToIsoFormate(new Date())}</strong>
                    </li>
                    <li>
                      <strong>{row.EmailId}</strong>
                    </li>
                    <li>
                      <strong>{row.Ageing}</strong>
                    </li>
                    <li>
                      <strong>{row.RegistrationNo}</strong>
                    </li>
                  </ul>
                );
              })}
            </div>
          ) : (
            // showNoData &&
            // (
            <div className="no-data-found">
              <img src="images/no_data_found.svg" alt="no_data_found.svg" />
              <p>No data found</p>
            </div>
            // )
          )}
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state && state.auth && state.auth.userInfo || {}
  }
}

export default connect(mapStateToProps)(InsurerAgentDashboard);
