import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import {
  ListItem,
  List,
} from '@material-ui/core';
import { useDispatch } from 'react-redux'
import { resetAllSearchOptions } from '../../../../redux/actions/pbDashboard_action'

export default function AdminSearchOptionsV2(props) {
  const { option_store, setDashboardOption_store  } = props; // from the parent component
  const dispatch = useDispatch();

  const handleChangeSearch = (event) => {
      setDashboardOption_store(event.target.value);
      dispatch(resetAllSearchOptions());
  }

  return (
    <div className="search-box-new">
      <List>
        <ListItem>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="position"
              value={option_store}
              defaultChecked={option_store}
              onChange={handleChangeSearch}
            >
              <FormControlLabel
                value={"1"}
                control={<Radio color="primary" />}
                label="Simple Search"
              />
              <FormControlLabel
                value={"2"}
                control={<Radio color="primary" />}
                label="Advance Search"
              />
              <FormControlLabel
                value={"3"}
                control={<Radio color="primary" />}
                label="Summary"
              />
              <FormControlLabel
                value={"4"}
                control={<Radio color="primary" />}
                label="TAT Summary"
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
      </List>
    </div>
  )
}
