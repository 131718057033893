import React, { useState, useEffect, useRef } from 'react';
import CKEditor from 'ckeditor4-react';
import { COMMON_CK_EDITOR_CONFIG } from '../../../Config';
import { Button, Checkbox, ClickAwayListener, FormControlLabel, TextareaAutosize } from '@material-ui/core';
import { Toast } from '../../../shared/components';
import FileUploadComponent from '../../../shared/components/FileUploadComponent';
import CancelIcon from '@material-ui/icons/Cancel';
import { getDocUrl } from '../../../shared/methods/apiMethods';
import CustomBackdrop from '../../../pages/Components/CustomBackdrop';
import WrapWithGrammarly from '../../../shared/components/WrapWithGrammarly';

function EditorComponent(props) {
    const { TriggeredBy, buttonsToRender, data, handleClose, allowEmpty, editorType, placeholder, showFileSelection, files, checkBoxToRender } = props;
    const [content, setContent] = useState();
    const editorRef = useRef();
    const [filesSelected, setFileSelected] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [checkBoxValuesLocal, setCheckBoxValuesLocal] = useState();

    const handleCKEditorEdit = (value) => {
        setContent(value)
    }

    useEffect(() => {
        if (data) {
            setContent(data)
        }
        if (files && files.length > 0) {
            let tempFiles = files.map((item, index) => {
                return {
                    ...item,
                    id: index
                }
            })
            setFileSelected(tempFiles)
        }
    }, [data, files])

    const handleSaveButtonClick = (fncToExecute) => {
        if (allowEmpty) {
            if (showFileSelection) {
                fncToExecute(content, filesSelected);
            }
            else fncToExecute(content);
            handleClose();
        }
        else {
            if (!content) {
                Toast.error("Please enter comment to continue");
            }
            else {
                if (showFileSelection) {
                    fncToExecute(content, filesSelected);
                }
                else fncToExecute(content);
                handleClose();
            }
        }
    }

    const renderEditor = React.useMemo(() => {
        return (
            editorType === 'textarea' ?
                <TextareaAutosize aria-label="empty textarea" value={content} onChange={(e) => handleCKEditorEdit(e.target.value)} placeholder={placeholder} />
                :
                <CKEditor
                    data={content}
                    config={COMMON_CK_EDITOR_CONFIG}
                    onChange={(event) => {
                        handleCKEditorEdit(event.editor.getData())

                    }}
                    onInstanceReady={(instance) => {
                        instance.data = content;
                        setEditorLoaded(true)
                    }}
                    name={`ck-editor-${TriggeredBy}`}
                    id={`ck-editor-${TriggeredBy}`}
                    readOnly={false}
                />

        )
    }, [content, editorType])

    const handleRemoveParticularFile = (id) => {
        const fileList = filesSelected.filter((item) => item.id !== id);
        setFileSelected(fileList);
    };

    const handleFileDownload = (obj) => {
        if (obj && obj.DocId) {
            setSpinnerLoading(true);
            let cb = () => {
                setSpinnerLoading(false)
            }
            getDocUrl(obj.DocId, cb)
        }
    }

    useEffect(() => {
        setCheckBoxValuesLocal(checkBoxToRender);
    }, [])


    return (
        <ClickAwayListener
            onClickAway={handleClose}>
            <div className="customer-pendency-editor">
                {!editorType && !editorLoaded && <CustomBackdrop open={!editorLoaded} />}
                {checkBoxValuesLocal && checkBoxValuesLocal.length > 0 && checkBoxValuesLocal.map((item, index) => {
                    console.log('item-->', item)
                    return <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary"
                            defaultChecked={item.checked ? true : false}
                            // checked={item.checked ? true : false}
                            onChange={(e) => {
                                item.onChange(e.target.checked); // for changing value in the parent component
                                let arr = checkBoxValuesLocal;
                                let obj = arr[index];
                                obj.checked = e.target.checked;
                                arr[index] = obj;
                                setCheckBoxValuesLocal(arr);
                            }}
                        />}
                        label={item.Name}
                        labelPlacement="end" />
                })}
                <WrapWithGrammarly>{renderEditor}</WrapWithGrammarly>
                {console.log('checkbox values-->', checkBoxValuesLocal)}
                <div className='buttons-rendering'>
                    <div className="action-btn-box">
                        <div className="file-upload">
                            {
                                showFileSelection &&
                                <>
                                    <FileUploadComponent
                                        files={filesSelected}
                                        setFiles={setFileSelected}
                                        fileRef={editorRef}
                                        isMultipleFileAllowed={true}
                                        isAttachementIcon={false}
                                        className="hello"
                                    />
                                    {filesSelected.map((item) => {
                                        return (
                                            <>
                                                <span style={{ cursor: spinnerLoading ? 'progress' : 'pointer' }} className="file-detail name-file" title='Click to download file' key={item.id} onClick={() => handleFileDownload(item)}>
                                                    {item.FileName}{" "}
                                                </span>
                                                <button
                                                    className="remove-file-icon"
                                                    onClick={() => handleRemoveParticularFile(item.id)}
                                                >
                                                    <CancelIcon />
                                                </button>
                                            </>
                                        );
                                    })}
                                </>
                            }
                        </div>
                        <div className='text-right'>
                            {
                                buttonsToRender && buttonsToRender.length > 0 && buttonsToRender.map(button => {
                                    return <Button key={`custom-popup-btn-${button.Name}`} className={button.className} onClick={() => button.Name == "OK" ? handleSaveButtonClick(button.onClick) : button.onClick()}>{button.Name}</Button>
                                })
                            }
                        </div>
                    </div>
                </div>
                {spinnerLoading && <CustomBackdrop open={spinnerLoading} />}
            </div>
        </ClickAwayListener>
    )
}

export default EditorComponent