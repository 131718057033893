import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import SkipData from './SkipData';
import ReportData from './ReportData';
import OneTicketTeamPriority from "./OneTicketTeamPriority";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#f8f8f8",
    },
    appBar: {
        position: "relative",
        backgroundColor: "#ffffff !important",
        color: "#414141",
    },
    title: {
        flex: 1,
    },
    formControl: {
        maxWidth: "100%",
    },
    h6: {
        color: "#414141",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const OneLead = () => {
    const classes = useStyles();
    const [option, setOption] = useState("1");
    return (
        <div className={classes.root}>
            <div className="one-lead-data">
                <div className="search-box">
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            value={option}
                            defaultChecked={option}
                            onChange={(e) => setOption(e.target.value)}
                        >
                            <FormControlLabel
                                value={"1"}
                                control={<Radio color="primary" />}
                                label="Report"
                            />
                            <FormControlLabel
                                value={"2"}
                                control={<Radio color="primary" />}
                                label="Skip"
                            />
                            <FormControlLabel
                                value={"3"}
                                control={<Radio color="primary" />}
                                label="One Ticket Priority"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="skip-data">
                    {option === "1" && <ReportData />}
                    {option === "2" && <SkipData />}
                    {option === "3" && <OneTicketTeamPriority/>}
                </div>
            </div>
        </div>
    )
}

export default OneLead


