import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Checkbox, FormHelperText } from '@material-ui/core';
import { GET, POST } from '../../services/apiServices';
import { handleCatch } from '../../shared/methods/apiMethods';
import CustomBackdrop from '../../pages/Components/CustomBackdrop';
import { useDispatch, useSelector } from 'react-redux';
import { DialogFromRight, Toast } from '../../shared/components';
import { URLRegex } from '../../Constants';
import { navigateToTab } from '../../redux/actions/utlity_actions';
import { setConditionalRenderNewCustomerPendency } from '../../redux/actions/utlity_actions';
import { PAYMENT_PENDENCY_REMARKS_MASTER } from '../../options.config';
import Autocomplete from "@material-ui/lab/Autocomplete";

function PendencyDescription(props) {
    let dispatch = useDispatch()
    let { setCustomerPendencyListParent, setCustomerPendencyValidatedParent, randomValueParent, setRaisedPendencyListParent } = props;
    const ticketDetails_store = useSelector(state => state.ticketdetails && state.ticketdetails.ticketDetails || {})
    const screenName_store = useSelector(state => state.common.screenName)
    const [state, setState] = useReducer(
        (values, newValues) => ({ ...values, ...newValues }), {
        pendencyList: [],
        pendencyListTouched: [],
        raisedPendencyList: [],
        loader: true,
        dialogControls: null
    }
    )
    const paymentAmountRegex = /^(0|[1-9]\d*)(\.\d+)?$/

    const [pendencyMaster, setPendencyMaster] = useState(null)

    let basePayload = {
        "TicketId": ticketDetails_store?.TicketId,
        "LeadId": ticketDetails_store?.LeadID,
        'CustomerId': ticketDetails_store?.CustomerId,
        'TicketDetailsId': ticketDetails_store?.TicketDetailsID
    }


    useEffect(() => {
        fetchPendencyDetails();
        fetchPendencyMaster();
    }, [])

    useEffect(() => {
        if (setCustomerPendencyListParent) {
            setCustomerPendencyListParent(state.pendencyList)
        }
    }, [state.pendencyList])

    let ValidationMasterObject = {
        'Inspection Pending': [],
        // 'Payment Pending': ['PaymentAmount', 'PaymentLink', 'Remarks'],
        'Payment Pending': ['Remarks', 'PaymentAmount'],
        'Document Pending': [],
        'KYC Pending': [],
        'Confirmation Pending': ['Remarks'],
        'PF Pending': []
    }

    const checkIfHasError = (field, item, index, regexToTest, checkIfTouched) => {
        if (field === 'PaymentLink') {
        }
        if (regexToTest) {
            console.log('testing regex', item[field], regexToTest.test(item[field]))
            return Boolean((checkIfTouched ? state.pendencyListTouched[index]?.[field] : true) && !item[field] || (item[field] && !regexToTest.test(item[field]) ? true : false))
        }
        else return Boolean((checkIfTouched ? state.pendencyListTouched[index]?.[field] : true) && !item[field])
    }

    const userEntriesHaveError = (touchEntries) => {
        console.log('Touched', touchEntries)
        let error = false;
        let valuesToAppend = []
        if (state.pendencyList) {
            if (state.pendencyList.length === 0) {
                error = true
            }
            else {
                state.pendencyList.forEach((item, index) => {
                    console.log(item)
                    if (Object.keys(item).length === 0) {
                        error = true;
                    }
                    else {
                        if (item['PendencyType']) {
                            let KeyToTest = ValidationMasterObject[item['PendencyType']];
                            console.log(KeyToTest)
                            KeyToTest && KeyToTest.forEach(key => {
                                if (checkIfHasError(key, item, index, (key === "PaymentLink" ? URLRegex : key === 'PaymentAmount' ? paymentAmountRegex : null), false)) {
                                    error = true;
                                    if (touchEntries && !item[key]) {
                                        let obj = {
                                            index: index,
                                            name: key,
                                            value: ''
                                        }
                                        valuesToAppend.push(obj)
                                    }
                                }
                            })
                        }
                        else error = true
                    }
                })
            }
        }
        if (touchEntries && error) { appendValuesWithError(valuesToAppend) }
        return error
    }

    const appendValuesWithError = (arr) => {
        const tempValues = [...state.pendencyList];
        const tempValuesTouched = [...state.pendencyListTouched];
        arr.forEach(item => {
            let tempObj = { ...tempValues[item.index] };
            let tempObjTouched = { ...tempValuesTouched[item.index] };
            tempObj[item.name] = ''
            tempObjTouched[item.name] = true;
            tempValues[item.index] = tempObj;
            tempValuesTouched[item.index] = tempObjTouched
        })
        setState({ pendencyList: tempValues, pendencyListTouched: tempValuesTouched });
    }

    useEffect(() => {
        if (Array.isArray(state.raisedPendencyList) && state.raisedPendencyList.length > 0) {
            setCustomerPendencyValidatedParent(true)
        }
        else {
            setCustomerPendencyValidatedParent(userEntriesHaveError())
        }
    }, [state.pendencyList])

    useEffect(() => {
        if (randomValueParent && setCustomerPendencyValidatedParent && Array.isArray(state.raisedPendencyList) && state.raisedPendencyList.length === 0) {
            setCustomerPendencyValidatedParent(userEntriesHaveError(true))
        }
        else setCustomerPendencyValidatedParent(true)
    }, [randomValueParent]) // for every save button click

    const fetchPendencyMaster = () => {
        let endpoint = `/GetTicketMaster/PendencyTypeMasterByLeadID/${basePayload.LeadId}`
        GET(endpoint).then(res => {
            if (res && res["ErrorCode"] === 0) {
                setPendencyMaster(res['Data']?.['PendencyMaster'])
            }
            else setPendencyMaster([])
        }).catch(err => {
            handleCatch(err)
        })
    }


    const fetchPendencyDetails = () => {
        let endpoint = '/GetCustomerPendencyData';
        let payload = {
            ...basePayload,
        }
        setState({ loader: true });
        POST(endpoint, payload).then(res => {
            setState({ loader: false });
            if (res && res['ErrorCode'] === 0) {
                setState({
                    pendencyList: res['Data']['NewPendencies'],
                    pendencyListTouched: res['Data']['NewPendencies'].map(_ => { }),
                    raisedPendencyList: res['Data']['RaisedPendencies']
                })
                setRaisedPendencyListParent(res['Data']['RaisedPendencies'])
                if (res['Data']['RaisedPendencies']?.length > 0) {
                    dispatch(setConditionalRenderNewCustomerPendency(true))
                }

            }
        }).catch(err => {
            setState({ loader: false });
            handleCatch(err);
        })
    }

    const fetchDynamicPendencyUrl = (payloadPassed, title) => {
        let endpoint = 'GetPendencyPortalUrl';
        let payload = {
            ...basePayload,
            ...payloadPassed,
        }
        setState({ loader: true })
        POST(endpoint, payload).then(res => {
            setState({ loader: false })
            if (res && res['ErrorCode'] === 0) {
                setState({
                    dialogControls: {
                        open: true,
                        size: 70,
                        renderComponent: <iframe src={res['Data']} height={'100%'} />,
                        title: title
                    }
                })
            }
            else {
                Toast.error(res['Message'] || `Failed to get ${title} url`)
            }
        }).catch(err => {
            setState({ loader: false })
            handleCatch(err)
        })
    }

    const handleValueChange = (index, type, event) => {
        const { name } = event.target;

        const tempValues = [...state.pendencyList];
        const tempValuesTouched = [...state.pendencyListTouched];
        const tempObj = { ...tempValues[index] };
        const tempObjTouched = { ...tempValuesTouched[index] };

        if (type === 'checkbox') {
            const { checked } = event.target;
            tempObj[name] = checked;
        } else {
            const { value } = event.target;
            tempObj[name] = value;
        }

        tempObjTouched[name] = true;
        tempValues[index] = tempObj;
        tempValuesTouched[index] = tempObjTouched;

        setState({ pendencyList: tempValues, pendencyListTouched: tempValuesTouched });
    };


    const handleRemoveItem = (index) => {
        const tempValues = [...state.pendencyList];
        const tempValuesTouched = [...state.pendencyListTouched];
        const updatedValues = tempValues.filter((_, i) => i !== index);
        const updatedTouchedValues = tempValuesTouched.filter((_, i) => i !== index);
        setState({
            pendencyList: updatedValues,
            pendencyListTouched: updatedTouchedValues,
        });
    };

    const handleAddPendencyItem = () => {
        setState({ pendencyList: [...state.pendencyList, {}], pendencyListTouched: [...state.pendencyListTouched, {}] })
    }

    const checkIfPendencyTypeExist = (selectedPendency, index) => {
        let pendencyAdded = false;
        let arr = state.pendencyList.filter(a => a.PendencyType).map(item => item.PendencyType);
        arr && arr.length > 0 && arr.forEach((i, index2) => {
            if (i === selectedPendency && index2 !== index) {
                pendencyAdded = true
            }
        })
        return pendencyAdded ? false : true
    }

    const navigateToCustomerPendencyLogs = () => {
        let tabNumber;
        if (screenName_store && screenName_store === 'All Tickets') {
            tabNumber = 2 // tabs are shown at the bottom based on Product Endorsement
        }
        else tabNumber = 3 // tabs are shown at the top based on Product Endorsement
        dispatch(navigateToTab(tabNumber))
    }


    const pendencyItems = useMemo(() => {
                if (state.pendencyList?.length) {
            return (
                state.pendencyList.map((item, index) => {
                    if (item.PendencyType !== 'Payment Pending') {
                        return (
                            <ul className='abc'>
                                <li>
                                    <FormControl variant="outlined" fullWidth className="form-control" >
                                        <InputLabel id="demo-simple-select-outlined-label">Pendency Type</InputLabel>
                                        <Select
                                            id="demo-simple-select-outlined"
                                            name='PendencyType'
                                            value={item['PendencyType']}
                                            onChange={(e) => handleValueChange(index, 'dropdown', e)}
                                        >
                                            {pendencyMaster && pendencyMaster.filter(item => checkIfPendencyTypeExist(item.PendencyType, index)).map(item => {
                                                return <MenuItem value={item.PendencyType}>{item.PendencyType}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </li>
                                {!['Document Pending', 'KYC Pending', 'PF Pending'].includes(item.PendencyType) && <li>
                                    <div className='remarks'>
                                        <label>Remarks</label>
                                        <textarea placeholder=''
                                            name='Remarks'
                                            value={item['Remarks']}
                                            onChange={(e) => handleValueChange(index, 'text', e)}
                                        />
                                        {item.PendencyType !== 'Document Pending' && checkIfHasError('Remarks', item, index, null, true) && <span className="error-msg">Please enter remarks</span>}
                                    </div>
                                </li>
                                }
                                <li className='view-remove-box'>
                                    <div>
                                        {
                                            ['Document Pending', 'Inspection Pending'].includes(item.PendencyType) &&
                                            <Button className='view-detail' onClick={() => fetchDynamicPendencyUrl({ Type: item.PendencyType, InspectionId: item.InspectionId }, item.PendencyType)}>View details</Button>
                                        }
                                        <Button className='remove-btn' onClick={() => handleRemoveItem(index)}>
                                            <img src="/images/remove-circle.svg" alt="Copy" />
                                        </Button>
                                    </div>
                                </li>
                            </ul>
                        )
                    }
                    else {
                        return <ul className='pendency-listing'>
                            <li>
                                <FormControl variant="outlined" className="form-control" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Pendency Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        name='PendencyType'
                                        value={item['PendencyType'] || ""}
                                        onChange={(e) => handleValueChange(index, 'dropdown', e)}
                                    >
                                        {pendencyMaster && pendencyMaster.filter(item => checkIfPendencyTypeExist(item.PendencyType, index)).map(item => {
                                            return <MenuItem value={item.PendencyType}>{item.PendencyType}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </li>
                            <li>
                                {console.log(checkIfHasError('PaymentAmount', item, index))}
                                <FormControl fullWidth className='text-field-col' error={checkIfHasError('PaymentAmount', item, index, paymentAmountRegex, true)}>
                                    <TextField id="outlined-basic" label="Amount" name='PaymentAmount'
                                        value={item['PaymentAmount']}
                                        type='number'
                                        onChange={(e) => handleValueChange(index, 'text', e)}
                                        variant="outlined"
                                    />
                                    {checkIfHasError('PaymentAmount', item, index, paymentAmountRegex, true) && <FormHelperText>Please enter valid Payment Amount</FormHelperText>}
                                </FormControl>
                            </li>
                            {/* <li>
                                {console.log(checkIfHasError('PaymentLink', item, index, URLRegex, true))}
                                <FormControl fullWidth className='text-field-col' error={checkIfHasError('PaymentLink', item, index, URLRegex, true)}>
                                    <TextField id="outlined-basic" label="Payment Link" name='PaymentLink'
                                        value={item['PaymentLink']}
                                        onChange={(e) => handleValueChange(index, 'text', e)}
                                        variant="outlined"
                                    />
                                    {checkIfHasError('PaymentLink', item, index, URLRegex, true) && <FormHelperText>Please enter valid url</FormHelperText>}
                                </FormControl>
                            </li> */}
                            <li>
                                <FormControl variant="outlined" fullWidth className="form-control  multi-select-box" >
                                {console.log('state.pendencyList', state.pendencyList)}
                                    {/* <InputLabel id="demo-simple-select-outlined-label">Remarks</InputLabel> */}
                                    {/* <Select
                                        id="demo-simple-select-outlined"
                                        name='Remarks'
                                        value={item['Remarks'] ? item['Remarks'].split(',') : []}
                                        multiple
                                        title={item['Remarks']}
                                        onChange={(e) => handleValueChange(index, 'dropdown', {...e, target : {...e.target, value: e.target.value ? (e.target.value).join(',') : e.target.value}})}
                                    >
                                        {PAYMENT_PENDENCY_REMARKS_MASTER(ticketDetails_store?.ProductId).map(option=>{
                                            return <MenuItem value={option} key={option}>{option}</MenuItem>
                                        })}
                                    </Select> */}
                                    <Autocomplete
                                        options={PAYMENT_PENDENCY_REMARKS_MASTER(ticketDetails_store?.ProductId)}
                                        fullWidth
                                        className="single-select"
                                        name="Remarks"
                                        id="pendencyDescription-remarks"
                                        title={item['Remarks'] && item['Remarks']}
                                        disableCloseOnSelect
                                        getOptionLabel={(item) => item}
                                        value={PAYMENT_PENDENCY_REMARKS_MASTER(ticketDetails_store?.ProductId).filter(a => item['Remarks']?.split(',')?.includes(a))}
                                        onChange={(e, optionsSelected) => handleValueChange(index, 'dropdown', {...e, target: { ...e.target, name: 'Remarks', value: optionsSelected ? optionsSelected.join(',') : "" }})}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    className='autocomplete-checkbox'
                                                    checked={item['Remarks'] && item['Remarks'].split(',')?.includes(option) ? true : false}
                                                />
                                                <span className='product-names'>{option}</span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select Remarks"
                                            />
                                        )}
                                        multiple={true}
                                    />

                                    {checkIfHasError('Remarks', item, index, null, true) && <span className="error-msg">Please enter remarks</span>}
                                </FormControl>
                            </li>
                            <li className='view-remove-box'>
                                <div>
                                    <Button className='remove-btn' onClick={() => handleRemoveItem(index)}>
                                        <img src="/images/remove-circle.svg" alt="Copy" />
                                    </Button>
                                </div>
                            </li>
                        </ul>
                    }
                })
            )
        }
    }, [state.pendencyList, state.raisedPendencyList, pendencyMaster])

    return (
        <div className='pendency-description-box'>
            {state.loader && <CustomBackdrop open={state.loader} textToShow='Loading Data...' />}
            <div className='head-top'>
                <h4>Pendency Description</h4>
            </div>
            <div className='pendency-description-body'>
                {Array.isArray(state.raisedPendencyList) && state.raisedPendencyList.length === 0 ?
                    <>
                        {pendencyItems}
                        {state.pendencyList && Array.isArray(pendencyMaster) && state.pendencyList.length < pendencyMaster.length && <div className='addnew-box'>
                            <Button className="add-new-btn" onClick={handleAddPendencyItem}> <img src="/images/add_new-btn.svg" alt="Copy" /> Add New </Button>
                        </div>
                        }
                    </>
                    :
                    <p >Please navigate to <span onClick={navigateToCustomerPendencyLogs}>Customer Pendency Logs Section</span> to raise/resolve any pendency</p>
                }
            </div>
            {console.log(state.dialogControls)}
            {state.dialogControls && state.dialogControls.open &&
                <DialogFromRight
                    open={state.dialogControls.open}
                    handleClose={() => setState({ dialogControls: null })}
                    title={state.dialogControls.title}
                    size={state.dialogControls.size}
                >
                    {state.dialogControls.renderComponent}
                </DialogFromRight>
            }
        </div>
    )
}

export default PendencyDescription
