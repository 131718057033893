import React, { useState } from 'react';
import { Link, ListItem, Grid, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../../redux/actions/auth';
import { STORAGE } from '../../../shared/methods/methods';
import LogoutDialog from '../../../shared/components/LogoutDialog';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
    },
}))

const ED_HEADER = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state && state.auth && state.auth.userInfo);
    const [showLogout, setShowLogout] = useState(false);

    const history = useHistory();

    let logoutFnc = function () {
        dispatch(userLogout());
        STORAGE.deleteAuth().then(() => {
            history.push("/");
        })
    }


    return (
        <>
            <div className={classes.root}>
                <div className="pbdashboard-summary ">

                    <div className="pbdashboard-topbar">
                        <Grid container alignContent='center'>
                            <Grid item md={4} xs={12}>
                                <h2>Hi! {userInfo && userInfo.Name}
                                </h2>
                            </Grid>
                            <Grid item md={8} xs={12} className="text-right">
                                <List className="top-head-list">
                                    <ListItem>
                                        <Tooltip title="Log out" placement="top">
                                            <Link onClick={() => setShowLogout(true)}>
                                                <i className="fa fa-sign-out"></i>
                                            </Link>
                                        </Tooltip>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            {
                <LogoutDialog
                    open={showLogout}
                    handleClose={() => setShowLogout(false)}
                    // handleLogout={logoutFnc}
                />
            }
        </>
    )
}

export default connect(null, null)(ED_HEADER)