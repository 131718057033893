import { useEffect, useState, React, useReducer } from "react";
import { Link, ListItem, Grid, List, Button,FormControl,InputLabel,Select,MenuItem, FormHelperText,Checkbox,ListItemText } from '@material-ui/core'

import { makeStyles } from "@material-ui/core/styles";
import { GET, POST } from "../../../../services/apiServices";
import { GetDashboardForOtherDataExport, GetTicketCount, GetTicketData, GetTicketDataExport, GetTicketDetails } from "../../../../constants/apiUrls";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { exportDashboardData_method, handleCatch } from "../../../../shared/methods/apiMethods";
import CustomBackdrop from "../../../Components/CustomBackdrop";
import { HashLoader, PacmanLoader } from 'react-spinners';
import { fetchMasterList } from "../../../../redux/actions/common_action";
import { SUMMARY_DASHBOARD_TYPE } from "../../../../Config";
import { getUniqueSetBasedOnKey } from "../../../../shared/methods/methods";
import { Toast } from "../../../../shared/components";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
}));

const AdminSummary = ({isActionsAllowed}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const productSupplierMaster = useSelector(state => state && state.common && state.common.productSupplierMaster);
  const [ticketCount, setTicketCount] = useState(null);
  const [productList, setProductList] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [formValues, setFormValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ProductID : [],
      IsEsc : 0,
    }
  )

  const [formValuesTouched, setFormValuesTouched] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ProductID : false,
      IsEsc : false,
    }
  )
  
  const showMessage =(message, varient) =>{
    return enqueueSnackbar(message, {variant: varient, onClick: () => {
        closeSnackbar();
      },
    });
  }
  

  useEffect(() => {
    if(productSupplierMaster && productSupplierMaster.length===0){
      dispatch(fetchMasterList())
    }
    else if(productSupplierMaster){
      setProductListsFromAPI();
    }
  }, [productSupplierMaster]);

  const setProductListsFromAPI = () =>{
    let arr = getUniqueSetBasedOnKey(productSupplierMaster, 'ProductID', ['ProductID', 'ProductName']);
    arr.splice(0, 0, { ProductID: 0, ProductName: 'NO PRODUCT' })
    setProductList(arr);
    // let productList = [];
    //   let obj;
    //   productSupplierMaster.length > 0 &&
    //     productSupplierMaster.map((item) => {
    //       let flag = 0;
    //       if (productList && productList.length > 0) {
    //         productList.forEach((singleItem) => {
    //           if (singleItem.ProductID == item.ProductID) {
    //             return (flag = 1);
    //           }
    //         });
    //       }
    //       if (flag == 0) {
    //         obj = {
    //           ProductID: item.ProductID,
    //           ProductName: item.ProductName,
    //         };
    //         productList.push(obj);
    //       }
    //     });
    //   setProductList(productList);
  }

  const handleChange =(e) =>{
    let { name, value} = e.target;
    setFormValues({[name] : value});
    setFormValuesTouched({[name] : true});
  }

  const getDashboardData = (type, value, fileName) => {
    if(!value){ return Toast.error("No Data Available") };
    setDataLoading(true);
    const payload = {
      "IsUpdatedOn": "0",
      "FilterString": "",
      "FromDate": 1635928641637,
      "ToDate": 1635928641637,
      "Source": "",
      "SubSource": "",
      "ProductID":"0",
      "ProductIDs": (formValues.ProductID).toString(),
      "SupplierID": 0,
      "CBRequest": 0,
      "StatusID": 0,
      "SubStatusID": 0,
      "TATFilter": 0,
      "InsurerStatusID": 0,
      "STP": -1,
      "IsEsc": formValues.IsEsc || 0,
      "IsTAT" : 0,
      "UserType": 1, //Important
      "Type": type,
      "ProductIDs": formValues.ProductID.toString(),
      "SendToReport": ""
    }
    let url = '/GetDashboardDataExport';
    let callback = () =>{
      setDataLoading(false)
    }
    exportDashboardData_method(url, payload, callback, fileName)
  }

  const EscalationArray = [
    { DisplayName : "Yes", value : 1},
    { DisplayName : "No", value : 0},
    
  ]

  const handleLoadData = () =>{
    setFormValuesTouched({
      ProductID : true,
      IsEsc : true
    })
    if(formValues.ProductID.length === 0){ return };
    
    let url = `GetDashboardCount/${SUMMARY_DASHBOARD_TYPE}/${(formValues.ProductID).toString()}/${formValues.IsEsc || 0}`;
    setDataLoading(true);
    GET(url, null, 'Dashboard').then(res=>{
      setDataLoading(false);
      if(res && res && res.ErrorCode===0){
        setTicketCount(res.Data)
      }
      else showMessage(res.Message || "Failed to fetch details", "error");
    }).catch(err=>{
      setDataLoading(false);
      handleCatch(err, showMessage);
    })
  }

  
  return (
    <div className={classes.root}>
      {dataLoading ? <CustomBackdrop open={dataLoading}><HashLoader color='white'/></CustomBackdrop> : null}
      <div className="pbdashboard-summary ">
        <div className="ticket-summary-list">
          {/* <h4>Summary</h4> */}
          <List>
            <ListItem id="summaryNewTicket" onClick={() => getDashboardData(1, ticketCount && ticketCount.NewCase || 0, "NewTickets")}>
              <div className="new-ticket">
                <span>New Ticket</span>
                <p>{ticketCount ? ticketCount.NewCase : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryOverDueTickets" onClick={() => getDashboardData(2, ticketCount && ticketCount.OverDue || 0, "OverDueTickets")}>
              <div className="due-ticket">
                <span>Over Due</span>
                <p>{ticketCount ? ticketCount.OverDue : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryDueTodayTickets" onClick={() => getDashboardData(3, ticketCount && ticketCount.DueToday || 0,"DueTodayTickets")}>
              <div className="duetoday-ticket">
                <span>Due Today</span>
                <p>{ticketCount ? ticketCount.DueToday : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryUnassignedTickets" onClick={() => getDashboardData(4, ticketCount && ticketCount.UnAssignedCase || 0,'UnassignedTickets')}>
              <div className="Unassigned-ticket">
                <span>Unassigned</span>
                <p>{ticketCount ? ticketCount.UnAssignedCase : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryOpenTickets" onClick={() => getDashboardData(5, ticketCount && ticketCount.OpenCase || 0,'OpenTickets')}>
              <div className="open-ticket">
                <span>Open</span>
                <p>{ticketCount ? ticketCount.OpenCase : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryInsurerRevertTickets" onClick={() => getDashboardData(6, ticketCount && ticketCount.InsurerRevert || 0,'InsurerRevertTickets')}>
              <div className="revert-ticket">
                <span>Insurer Reverts</span>
                <p>{ticketCount ? ticketCount.InsurerRevert : null}</p>
              </div>
            </ListItem>
            {/* {
            isActionsAllowed
            &&
            <ListItem onClick={() => getDashboardData(6, ticketCount && ticketCount.InsurerRevert || 0)}>
              <div className="revert-ticket">
                <span>Insurer Reverts</span>
                <p>{ticketCount ? ticketCount.InsurerRevert : null}</p>
              </div>
            </ListItem>
            } */}
            <ListItem id="summaryCustReply" onClick={() => getDashboardData(7, ticketCount && ticketCount.CustReply || 0,'CustomerTickets')}>
              <div className="cust-ticket">
                <span>Cust Reply</span>
                <p>{ticketCount ? ticketCount.CustReply : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryReOpen" onClick={() => getDashboardData(8, ticketCount && ticketCount.ReOpen || 0,'ReopenTickets')}>
              <div className="reopen-ticket">
                <span>ReOpen</span>
                <p>{ticketCount ? ticketCount.ReOpen : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryResolvedToday" onClick={() => getDashboardData(9, ticketCount && ticketCount.ResolvedToday || 0,'ResolvedTickets')}>
              <div className="resolved-ticket">
                <span>Resolved Today</span>
                <p>{ticketCount ? ticketCount.ResolvedToday : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryEscalation" onClick={() => getDashboardData(10, ticketCount && ticketCount.Escalation || 0,'EscalationTickets')}>
              <div className="escalation-ticket">
                <span>Escalation</span>
                <p>{ticketCount ? ticketCount.Escalation : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryUnAssignedCustomerPendency" onClick={() => getDashboardData(19, ticketCount && ticketCount.UnAssignedCustomerPendency || 0,'UnassignedCustomerPendencyTickets')}>
              <div className="unassigned-customer-pendency-ticket">
                <span>Unassigned Customer Pendency</span>
                <p>{ticketCount ? ticketCount.UnAssignedCustomerPendency : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryPOSP" onClick={() => getDashboardData(14, ticketCount && ticketCount.POSP || 0,'GreenTickets')}>
              <div className="green-ticket">
                <span>Green Channel</span>
                <p>{ticketCount ? ticketCount.POSP: null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryRenewalSales" onClick={() => getDashboardData(15, ticketCount && ticketCount.RenewalSales || 0,'RenewalTickets')}>
              <div className="renewal-ticket">
                <span>Renewal Sales</span>
                <p>{ticketCount ? ticketCount.RenewalSales : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryJAG" onClick={() => getDashboardData(16, ticketCount && ticketCount && ticketCount.JAG || 0,'JagTickets')}>
              <div className="jag-ticket">
                <span>JAG</span>
                <p>{ticketCount ? ticketCount.JAG : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryNilEndo" onClick={() => getDashboardData(17, ticketCount && ticketCount.NilEndo || 0,'NilEndorsementTickets')}>
              <div className="nil-endorsement-ticket">
                <span>Nil Endorsement</span>
                <p>{ticketCount ? ticketCount.NilEndo : null}</p>
              </div>
            </ListItem>
            <ListItem id="summaryInsurerInitiated" onClick={() => getDashboardData(18, ticketCount && ticketCount.InsurerInitiated || 0,'InsurerInitiatedTickets')}>
              <div className="insurer-initiated-ticket">
                <span>Insurer Initiated</span>
                <p>{ticketCount ? ticketCount.InsurerInitiated : null}</p>
              </div>
            </ListItem>
            <ListItem  id="summarySelfEndorsement" onClick={() => getDashboardData(20, ticketCount && ticketCount.SelfEndorsement || 0,'SelfEndorsement')}>
                <div className="revert-ticket">
                  <span>Self Endorsement</span>
                  <p>{ticketCount ? ticketCount.SelfEndorsement : null}</p>
                </div>
              </ListItem>
          </List>
        </div>
        <div className='tat-summary-box'>
          <ul>
            <li>
              {/* <FormControl className="form-control" error={formValuesTouched.ProductID && formValues.ProductID.length===0}>
                <InputLabel id="demo-customized-select-label">
                  Select Product
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  onChange={handleChange}
                  name="ProductID"
                  multiple
                  value={formValues.ProductID}
                >
                {productList && productList.length > 0 && productList.map(item => {
                  return <MenuItem key={`product+${item.ProductID}`} value={item.ProductID}>{item.ProductName}</MenuItem>
                })}
                </Select>
                {formValuesTouched.ProductID && formValues.ProductID.length===0 && <FormHelperText>Please select product(s)</FormHelperText>}
              </FormControl> */}
              <FormControl className="form-control" error={formValuesTouched.ProductID && formValues.ProductID.length===0}>
                <InputLabel id="demo-customized-select-label">
                  Select Product 
                </InputLabel>
                <Select
                  labelId="adminSummarySelectProduct"
                  value={formValues.ProductID}
                  multiple
                  onChange={handleChange}
                  renderValue={(selected) => selected && selected.length>0 && selected.map(val=>{
                   return productList.filter(item=> {
                      return item.ProductID === val
                    })[0].ProductName
                  }).join(',')
                  }
                  name="ProductID"
                  >
                  {
                    productList && productList.map((item) => (
                      <MenuItem key={item.ProductID} value={item.ProductID}>

                      <Checkbox 
                        checked={formValues.ProductID.indexOf(item.ProductID) > -1} 
                        />
                        <ListItemText primary={item.ProductName} />
                      </MenuItem>
                    ))}
                 {/* {productList && productList.length > 0 && productList.map(item => {
                 return <MenuItem key={`product+${item.ProductID}`} value={item.ProductID}>{item.ProductName}</MenuItem>
                })} */}
                </Select>
                {formValuesTouched.ProductID && formValues.ProductID.length===0 && <FormHelperText className='error-msg'>Please select product(s)</FormHelperText>}
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <InputLabel id="demo-customized-select-label">
                  Select Escalation
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select tatSummarySelectProduct"
                  onChange={handleChange}
                  name="IsEsc"
                >
                  <MenuItem value={"0"}>Select</MenuItem>
                  <MenuItem value={"1"}>ALL</MenuItem>
                  {/* {EscalationArray.map(item=>{
                    return <MenuItem value={item.value}>{item.DisplayName}</MenuItem>
                  })} */}
                </Select>
              </FormControl>
            </li>
            <li>
              <Button id="summaryExportBtn" className="export-btn" onClick={handleLoadData}>Load Data</Button>
            </li>
          </ul>
        </div>
        {/*list */}
        {/* <AgentLoadDataList ticketData={ticketData}/> */}
       </div>
    </div>
  );
};

export default AdminSummary;
