import React, { useContext } from "react";
import { Tooltip, Button } from "@material-ui/core";
import {
  getReadableDate,
  convertToIsoFormate,
  openNewView,
} from "../../../shared/methods/methods";
import { useHistory } from "react-router-dom";
import TicketContext from "../../../TicketContext";
import { isEndorsementEligible} from "../../../Config";
import { CustomCopyToClipboard } from "../../../shared/components/CustomCopyToClipboard";
import { useDispatch, useSelector } from "react-redux";
import { navigateToTab } from "../../../redux/actions/utlity_actions";
import { setActiveTicketID } from "../../../redux/actions/ticketDetails";

const TicketCard = ({
  ticket,
  GetTicketDetailsByTicketIdApi,
  GetTicketLogsApi,
  getRedAmberResponseapi,
  GetFilterCountApi,
  setIsTicketDetailEditable,
  Responsibility,
  setErrors,
  viewtype,
  ProductID,
  componentID,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ActiveTicket_store = useSelector(state => state.ticketdetails?.selectedTicketID)
  const ticketContext = useContext(TicketContext);

  const escTypes = [
    null,
    "IRDA",
    "Social",
    "TAT",
    "HCS",
    "CGO",
    "reopen",
    "Escalation",
    "TOP Escalation",
  ];

  const setActiveTicket = (RefObjectId, TicketDetailsID, TicketId) => {
    if (ActiveTicket_store !== TicketId) {
      dispatch(setActiveTicketID(ticket.TicketId))
      setIsTicketDetailEditable(false);
      ticketContext.setActiveTicketId(TicketId);
      GetTicketDetailsByTicketIdApi(RefObjectId, TicketDetailsID);
      ticketContext.GetTicketCommApi(RefObjectId, TicketDetailsID);
      ticketContext.setIsProductValueChanged(false);
      GetTicketLogsApi(ticket.TicketId);
      getRedAmberResponseapi(TicketId);
      GetFilterCountApi(TicketId);
      setErrors({ issue: false, subIssue: false, followUpdate: false });
      if(isEndorsementEligible(ProductID) && openNewView(ProductID)){
        history.push(
          `/ticketdetails/${RefObjectId}/${TicketDetailsID}/${viewtype}`
        );
      }
      else{
        history.push(
          `/alltickets/${RefObjectId}/${TicketDetailsID}/${viewtype}`
        );
      }

    }
  };

  const getFormatedName = (name) => {
    if (name.includes("(")) {
      const nameArr = name.split("(");
      return nameArr[0] + " (" + nameArr[1];
    } else {
      return name;
    }
  };

  return (
    <div
      className={
        "card" +
        (ActiveTicket_store === ticket.TicketId ? " active" : "")
      }
      id={componentID}
      onClick={() => {
        if (viewtype == 0) {
          setActiveTicket(
            ticket.RefObjectId,
            ticket.TicketDetailsID,
            ticket.TicketId,
          );
          dispatch(navigateToTab(0))
        }
      }}
    >
      <div className="tag-btn">
        {ticket.ParentTicketID && ticket.ParentTicketID != 0 ? (
          <span id="TagChildTicket">Child Ticket</span>
        ) : (
          ""
        )}
        {ticket.TATEscalation ? <span id="tagTATESC">TAT ESC</span> : ""}
        {ticket.IsJag && ticket.IsJag==1 ? <span  id="tagJag">JAG</span> : ""}
        {ticket.IsReopen ? <span id="tagReopen">Reopen</span> : ""}
        {ticket.HighComplaint > 0 ? <span id="tagTopMgmt">TopMgmt</span> : ""}
        {ticket.EscLevel1 === "Yes" ? <span id="tagHCS">HCS</span> : ""}
        {ticket.EscLevel2 === "Yes" ? <span id="tagCGO">CGO</span> : ""}
        {ticket.Source === "Social" || ticket.IsSocial ? (
          <span id="tagIsSoial">{ticket.SubSource === "AskPB" ? "Ask PB" : "Social"}</span>
        ) : (
          ""
        )}
        {ticket.Escalation == "Yes" ? <span id="tagIRDA">IRDA</span> : ""}
        {ticket.EscalationType != 0 && escTypes[ticket.EscalationType] ? (
          null
        ) : (
          ""
        )}
        {ticket.PartnerLevel == 1 &&
        (ticket.ProductId == 186 ||
          ticket.ProductId == 187 ||
          ticket.ProductId == 188 ||
          ticket.ProductId == 189 ||
          ticket.ProductId == 190 ||
          ticket.ProductId == 194) ? (
          <span  id="tagKRG" className="krg-icon">KRG</span>
        ) : (
          ""
        )}
       {ticket.IsJourney ? <span id="tagJourney">Journey</span> : ""}
      </div>
      <h4>
        {ticket?.IssueName}
        <span id="tagEscalationType">
          {ticket.IsAutoClosure && !ticket.IsReopen && ticket.EscalationType == 0 ? (
            <Tooltip
              title="Auto Closure"
              aria-label="autoClosure"
              placement="top-start"
            >
              <Button className="auto-closue-btn">A</Button>
            </Tooltip>
          ) : (
            ""
          )}
        </span>
      </h4>
      <img className="tiked-icon" src="/images/Ticked.svg" alt="Ticked" />
      <div className="ticket-card-list">
        <ul>
          <li>
            <span>Sub Issue</span>
            <strong id="cardSubIssue">{ticket.SubIssueName ? ticket.SubIssueName : ""}</strong>
          </li>
          {ticket.TicketId && (
            <li>
              <span>Ticket ID</span>
              <strong id="cardTicketDetailsID" className="ticket-id-bold">
                {ticket.TicketDetailsID}
                {ticket.ParentTicketID && ticket.ParentTicketID != 0
                  ? "(C)"
                  : ""}  <CustomCopyToClipboard text={ticket.TicketDetailsID}/>
              </strong>
              {ticket.ParentTicketID && ticket.ParentTicketID != 0 ? (
                <strong id="cardParentTicketDetailsID" className="ticket-id-bold">
                  {ticket.ParentTicketDetailsID}(P)
                </strong>
              ) : (
                ""
              )}
            </li>
          )}
          {ticket.Source && (
            <li>
              <span>Source</span>
              <strong id="cardSubSource">
                {ticket.Source}{" "}
                {ticket.SubSource ? <span>({ticket.SubSource})</span> : ""}
              </strong>
            </li>
          )}
          {ticket.AssignedToName &&
            (!ticket.ParentTicketID || ticket.ParentTicketID == 0) && (
              <li>
                <span>Assigned To </span>
                <strong id="cardAssignedToName">{getFormatedName(ticket.AssignedToName)}</strong>
              </li>
            )}
          {ActiveTicket_store == ticket.TicketId && Responsibility && (
            <li>
              <span>Responsibility </span>
              <strong id="cardResponsibility">{getFormatedName(Responsibility)}</strong>
            </li>
          )}
          {ticket.CreatedOn && (
            <li>
              <span>Created on</span>
              <strong id="cardCreatedOn">{convertToIsoFormate(ticket.CreatedOn)}</strong>
            </li>
          )}
          {ticket.ResolvedDate !== 0 && (
            <li>
              <span>Resolved on</span>
              <strong id="cardResolvedDate">{convertToIsoFormate(ticket.ResolvedDate)}</strong>
            </li>
          )}
          {ticket.PartnerId &&
          (ticket.ProductId == 186 ||
            ticket.ProductId == 187 ||
            ticket.ProductId == 188) ? (
            <li>
              <span>Partner Id</span>
              <strong id="cardPartnerId">{ticket.PartnerId}</strong>
            </li>
          ) : null}
          {ticket.ProductId == 186 ||
          ticket.ProductId == 187 ||
          ticket.ProductId == 188 ? (
            <li>
              <span>Partner level</span>
              <strong  id="cardPartnerLevel" className="ticket-id-bold">
                {ticket?.PartnerLevel || 0}
              </strong>
            </li>
          ) : null}
        </ul>
        <div
          className={
            "ticket-card-footer" +
            (ticket.StatusID === 4 || ticket.StatusID === 3 ? " resolved" : "")
          }
        >
          {ticket.StatusID && (
            <>
              {ticket.StatusID === 2 && (
                <img src="/images/hourglass.svg" alt="hourglass" />
              )}
              {(ticket.StatusID === 4 || ticket.StatusID === 3) && (
                <img src="/images/checkmark_circle.svg" alt="checkmark" />
              )}
            </>
          )}
          <div className="footer-data">
            <h6 id="cardFooterStatus">{ticket?.Status}</h6>
            <strong id="cardFooterSubStatusName" >{ticket?.SubStatusName}</strong>
            <span id="cardFooterLastUpdatedOn">
              {ticket.LastUpdatedOn
                ? `Updated on : ${convertToIsoFormate(ticket.LastUpdatedOn)}`
                : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
