import React from 'react'
import ChildErrorBoundary from '../../../shared/components/ChildErrorBoundary'
import PBAgentDashboard from '../Agent/PBAgentDashboard'

export default function PBAdminAsAgentDashboard() {
  return (
        <ChildErrorBoundary>
            <PBAgentDashboard/>
        </ChildErrorBoundary>
  )
}
