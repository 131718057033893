import { call, put, takeEvery } from "redux-saga/effects";
import { AuthLogin, Login } from "../../constants/apiUrls";
import { GET, POST } from "../../services/apiServices";
import { getToken, STORAGE } from "../../shared/methods/methods";
import * as type from "../types/types";

function authLoginApi() {
  STORAGE.getAuthToken().then(token=>{
    const payload = {
      token: token,
    };
    return POST(AuthLogin, payload)
    .then((res) => res.Data)
    .catch((error) => {
      throw error;
    });
  })
}

function* fetchAuthLogin(action) {
  try {
    const auth = yield call(authLoginApi);
    yield put({ type: type.AUTH_LOGIN_SUCCESS, auth: auth });
  } catch (e) {
    yield put({ type: type.AUTH_LOGIN_FAILED, message: "Error in login report" });
  }
}

function* authSaga() {
  yield takeEvery(type.AUTH_LOGIN_REQUESTED, fetchAuthLogin);
}

export default authSaga;
