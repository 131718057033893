import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/scss/index.scss';
import { Provider } from 'react-redux';
import ReduxStore from './redux/store';


import { PersistGate } from 'redux-persist/integration/react'

const { store, persistor } = ReduxStore()
const app = (
<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>
)

ReactDOM.render(
  app,
  document.getElementById('root')
);


