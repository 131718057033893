import React from 'react';
import {Grid,LinearProgress} from '@material-ui/core';

export default function EscalationLevel({level,time,levelNumber,timeClass='',timePercentage,currentClass}) {
  
  return (
    <Grid item sm={4} xs={12}>
        <div className={`${level} srs-timeline`}>
            <span>{levelNumber}</span>  
            <LinearProgress variant="determinate" value={timePercentage}/>
            <em className={`${timeClass} ${currentClass}`}>{time}</em>
        </div>
    </Grid>
  );
}
