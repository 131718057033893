import React, { useEffect, useState,useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import EsclationLevel from "./Components/EsclationLevel";
import AssignDialog from "./Components/AssignDialog";
import { secondTodhm } from "../../shared/methods/methods";
import Spinner from "../../shared/components/Spinner";
import TicketContext from "../../TicketContext";

export default function TicketEscalation({
  esclationInfo,
  UpdateL0ToL1Api,
  isEsclationLoading,
  StatusID,
  IssueName,
  SubIssueName ,
  AssignedTo,
  EsclationLevelTime,
  setEsclationLevelTime,
  ParentTicketID,
  viewtype
}) {
  const [showAssignDialog, setShowAssignDialog] = useState(false);

  const ticketContext = useContext(TicketContext)

  const getFormatedDate = (d)=>{
    const dateArr = d.split(',')
    return dateArr[0].split(' ').reverse().join(' ') + ' ' + dateArr[1];
  }

  useEffect(() => {
    let timeoutId;
    let l0=0,l1=0,l2=0;
    if(EsclationLevelTime.L0 >= 60){
        l0 = Number(EsclationLevelTime.L0)-60; 
    }
    if(EsclationLevelTime.L1>= 60){
      l1 = Number(EsclationLevelTime.L1) - 60;
    }
    if(EsclationLevelTime.L2>= 60){
      l2 = Number(EsclationLevelTime.L2) - 60;
    }

    timeoutId = setTimeout(() => {
      setEsclationLevelTime((prev)=>({...prev,L0:l0,L1:l1,L2:l2}));
    }, 60*1000);
  
    return () => {
      clearTimeout(timeoutId);
    }
  }, [EsclationLevelTime]);

  const getTimePercent = (currentLevel,timePer)=> {
          if(timePer > 100) timePer = 100;
          if(currentLevel == 0){
            return [timePer,0,0];
          }
          else if(currentLevel == 1){
            return [100,timePer,0];
          }
          else if(currentLevel == 2){
            return [100,100,timePer];
          }
          else if(currentLevel > 2){
            return [100,100,100]
          }
          else{
            return [0,0,0];
          }
  }

  return viewtype == 0 && (
    <div className="escalation-loader">
      {isEsclationLoading ? (
        <div className="spinner-loader">
          <Spinner />
        </div>
      ) : esclationInfo && Object.keys(esclationInfo).length > 0 ? (
        <>
          <div className="esclation-box">
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={2} xs={12}>
                <h4 id="escalationLevel">Escalation Level</h4>
              </Grid>
              <Grid item md={10} xs={12}>
                <Grid container spacing={1}>
                    <EsclationLevel
                      level="level-one"
                      time={esclationInfo.CurrentLevel === 0 ? secondTodhm(EsclationLevelTime.L0) : secondTodhm(esclationInfo.L0_Green)}
                      levelNumber="L0"
                      currentClass={esclationInfo.CurrentLevel === 0 ? 'active' : ''}
                      timePercentage = {getTimePercent(esclationInfo.CurrentLevel,esclationInfo.TimeLapse)[0]}
                      id="escalationLevelOne"
                    />
         
                    <EsclationLevel
                      level="level-two"
                      time={esclationInfo.CurrentLevel === 1 ? secondTodhm(EsclationLevelTime.L1) : (esclationInfo.CurrentLevel > 1 ? secondTodhm(esclationInfo.L1_Amber):0)}
                      levelNumber="L1"
                      currentClass={esclationInfo.CurrentLevel === 1 ? 'active' : ''}
                      timePercentage = {getTimePercent(esclationInfo.CurrentLevel,esclationInfo.TimeLapse)[1]}
                      id="escalationLevelTwo"
                    />
 
                    <EsclationLevel
                      level="level-three"
                      time={esclationInfo.CurrentLevel === 2 ? secondTodhm(EsclationLevelTime.L2) : (esclationInfo.CurrentLevel > 2 ? secondTodhm(esclationInfo.L2_Red): 0)}
                      timeClass="current"
                      levelNumber="L2"
                      currentClass={esclationInfo.CurrentLevel === 2 ? 'active' : ''}
                      timePercentage = {getTimePercent(esclationInfo.CurrentLevel,esclationInfo.TimeLapse)[2]}
                      id="escalationLevelThree"
                    />
       
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className="esclation-resoution-timer">
            <ul>
              {
                esclationInfo.CustomerResolutionTime
                &&
                <li>
                <p>
                  Customer Resolution Time{" "}
                  <span className="timer">
                    {getFormatedDate(esclationInfo.CustomerResolutionTime)}
                  </span>
                </p>
              </li>
              }
              {
                esclationInfo.TargetResolutionTime
                &&
                <li>
                <p>
                  Target Resolution Time{" "}
                  <span className="timer">
                    {getFormatedDate(esclationInfo.TargetResolutionTime)}
                  </span>
                </p>
              </li>
              }
          
              {
                 (StatusID === 4 || StatusID === 3 || StatusID === 6 || ParentTicketID || !IssueName || !SubIssueName || !ticketContext.isActionsAllowed)
                 ?
                 ""
                 :
                 esclationInfo.CurrentLevel === 0 && AssignedTo > 0 && esclationInfo.CustomerResolutionTime
                 &&
                 (
                <Button
                  className="assign"
                  onClick={() => setShowAssignDialog(true)}
                >
                  L1 Help Required{" "}
                </Button>
              )
              }
            </ul>
          </div>

          {showAssignDialog && (
            <AssignDialog
              isAssignDialogOpen={showAssignDialog}
              setAssignDialogOpen={setShowAssignDialog}
              UpdateL0ToL1Api={UpdateL0ToL1Api}
            />
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
}
