import React, { useState, useEffect } from "react";
import { GET, POST } from "../services/apiServices";
import Paper from "@material-ui/core/Paper";
import { GetRMMappingMasterData, CreateUpdateDeleteRMMapping } from "../constants/apiUrls";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
    FormControl,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableFooter,
    TableRow,
  } from "@material-ui/core";

export default function RMLogMaster({}) {
  
  const dispatch = useDispatch();
  const [url, setUrl] = useState(window.location.href);
  const history = useHistory();
  let str;
  let landingpage = "landingpage/";
  if (url.toLowerCase().includes(landingpage)) {
    let index1 = url.toLowerCase().indexOf(landingpage);
    str = url.substring(index1, index1 + landingpage.length);
  }
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const encryptedParam = url.split(str)[1];
  const auth = useSelector((state) => state.auth.auth);
  const [RMMasterData, setRMMasterData] = useState(null);
  const [sortingData, setsortingData] = useState(null);
  const [createvar, setcreatevar] = useState({
    emailid: null,
    userid: null,
    username: null,
    employeeid: null,
    productid: null,
    productname: null,
    source: null,
    subsource: null,
    domainname: null,
    isrm: null,
  });
  const [flag, setflag] = useState(null);
  const [flag1, setflag1] = useState(null);
  const [flag2, setflag2] = useState(null);
  const [updatevar, setupdatevar] = useState({
    updemailid: null,
    upduserid: null,
    updusername: null,
    updemployeeid: null,
    updproductid: null,
    updproductname: null,
    updsource: null,
    updsubsource: null,
    upddomainname: null,
    updisrm: null,
    
});

  const [defemailid, setdefemailid] = useState(null);
  const [defuserid, setdefuserid] = useState(null);
  const [defusername, setdefusername] = useState(null);
  const [defemployeeid, setdefemployeeid] = useState(null);
  const [defproductid, setdefproductid] = useState(null);  
  const [defproductname, setdefproductname] = useState(null);
  const [defsource, setdefsource] = useState(null);
  const [defsubsource, setdefsubsource] = useState(null);
  const [defdomainname, setdefdomainname] = useState(null);
  const [defisrm, setdefisrm] = useState(null);
  const [updSno, setupdSno] = useState(null);
  const [deletevar, setdeletevar] = useState({
    apiname: null,
    
});  
  const [inputValue, setInputValue] = useState("");
  
  
  useEffect(() => {
    loadRMData();  
    
  }, []);

  const loadRMData = () => {
    GET(GetRMMappingMasterData)
    .then((response) => {
      if (response.ErrorCode == 0) {
        setRMMasterData(response.Data);
        
      }
    })
    .catch((err) => console.log(err));
  }
  const handleChangeUpdate = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    
    // setupdSno(SNo);
    // setdefemailid(EmailID);
    // setdefuserid(UserID);
    // setdefusername(UserName);
    // setdefemployeeid(EmployeeID);
    // setdefproductid(ProductID);    
    // setdefproductname(ProductName);
    // setdefsource(Source);
    // setdefsubsource(SubSource);
    // setdefdomainname(CompanyDomainName);
    // setdefisrm(IsRM);
    // setflag1("123");
   
      if (event.target.name=="defemailid"){
        setdefemailid(event.target.value);
      }
      else if(event.target.name=="defuserid"){
        setdefuserid(event.target.value);
      }
      else if(event.target.name=="username"){
        setdefusername(event.target.value);
      }
      else if(event.target.name=="defemployeeid"){
        setdefemployeeid(event.target.value);
      }
      else if(event.target.name=="productid"){
        setdefproductid(event.target.value);
      }
      else if(event.target.name=="defproductname"){
        setdefproductname(event.target.value);
      }
      else if(event.target.name=="defsource"){
        setdefsource(event.target.value);
      }
      else if(event.target.name=="defsubsource"){
        setdefsubsource(event.target.value);
      }
      else if(event.target.name=="defdomainname"){
        setdefdomainname(event.target.value);
      }
      else if(event.target.name=="defisrm"){
        setdefisrm(event.target.value);
      }
  }
  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist();
    }
     setcreatevar((createvar) => ({
      ...createvar,
      [event.target.name]: event.target.value,
    }));
    setupdatevar((updatevar) => ({
        ...updatevar,
        [event.target.name]: event.target.value,
      }));
    setdeletevar((deletevar) => ({
        ...deletevar,
        [event.target.name]: event.target.value,
      }));
      
  };

  const handleInputChange = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    setInputValue(event.target.value);
  };
 

  const createfunc = (event) => {
    if (event && event.persist) {
      event.persist();
    }

    if (createvar.emailid == null || !createvar.emailid.replace(/\s/g, '').length || !createvar.source.replace(/\s/g, '').length
    || !createvar.subsource.replace(/\s/g, '').length){
      enqueueSnackbar("Enter Reuqired Details", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      return;
    }
    else {
    // setDataLoading(true);
    const payload = {
      SNo: 0,
      EmailID: createvar.emailid,
      UserID: createvar.userid,
      UserName: createvar.username,
      EmployeeID: createvar.employeeid,
      ProductID: createvar.productid,
      ProductName: createvar.productname,
      Source: createvar.source,
      SubSource: createvar.subsource,
      DomainName: createvar.domainname,
      IsRM: createvar.isrm,
      Type: 1,
    };
    
    POST(CreateUpdateDeleteRMMapping, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          setflag(null);
          loadRMData();
          enqueueSnackbar("RM Created Successfully", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
              
            },
          });
        }
      })
      .catch((err) => console.log(err));
    
    //   setDataLoading(false);
  }};
  const clickfunc = (SNo, EmailID, UserID, UserName, 
    EmployeeID, ProductID, ProductName, Source, SubSource, CompanyDomainName, IsRM) => {
    setupdSno(SNo);
    setdefemailid(EmailID);
    setdefuserid(UserID);
    setdefusername(UserName);
    setdefemployeeid(EmployeeID);
    setdefproductid(ProductID);    
    setdefproductname(ProductName);
    setdefsource(Source);
    setdefsubsource(SubSource);
    setdefdomainname(CompanyDomainName);
    setdefisrm(IsRM);
    setflag1("123");
    // setDefApi(defapi);
    // setDefUID(defuid);
    
  }
   
  const flagfunc = () => {
    setflag("123");
    
  }
  const cancelcreate = () => {
    setflag(null);
  }
  const cancel = () => {
    setflag1(null);
  }
  const updatefunc = () => {
    // if (event && event.persist) {
    //   event.persist();
    // }
    if (defemailid == null || !defemailid.replace(/\s/g, '').length || !defsource.replace(/\s/g, '').length ||
    !defsubsource.replace(/\s/g, '').length){
      enqueueSnackbar("Enter Required Details", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
    }
    else{
    // <input type="text" name="uid" onChange={handleChange}>UID</input>;
    // setDataLoading(true);
    const payload = {
      SNo: updSno,
      EmailID: defemailid,
      UserID: defuserid,
      UserName: defusername,
      EmployeeID: defemployeeid,
      ProductID: defproductid,
      ProductName: updatevar.updproductname,
      Source: defsource,
      SubSource: defsubsource,
      CompanyDomainName: defdomainname,
      IsRM: defisrm,
      Type: 2,
    };
    
    POST(CreateUpdateDeleteRMMapping, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
            setflag1(null);
            loadRMData();
            enqueueSnackbar("RM Updated Successfully", {
              variant: "success",
              onClick: () => {
                closeSnackbar();
                
              },
            });
        }
      })
      .catch((err) => console.log(err));
    //   setDataLoading(false);
  };
  }
  const deletefunc = (apiname, uid, sno) => {
    // if (event && event.persist) {
    //   event.persist();
    // }
    // setDataLoading(true);
    
    const payload = {
    //   APIName: apiname,
    //   UID: uid,
      SNo: sno ,
      Type: 3,
    };

    POST(CreateUpdateDeleteRMMapping, payload)
      .then((response) => {
        if (response.ErrorCode == 0) {
          loadRMData();
          enqueueSnackbar("RM Deleted Successfully", {
            variant: "success",
            onClick: () => {
              closeSnackbar();
              
            },
          });
        }
      })
      .catch((err) => console.log(err));
    //   setDataLoading(false);
  };

  return (
    <div className="api-logs">
      <h2>RM Master</h2> <div className="search-option-list">
      {flag?. length ? (
            <ul className="listing">                
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Email ID"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="emailid"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="User ID"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="userid"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="User Name"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="username"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Employee ID"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="employeeid"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Product ID"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="productid"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Product Name"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="productname"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Source"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="source"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Sub Source"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="subsource"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Company Domain Name"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="domainname"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <FormControl className="form-control">
                <TextField
                  label="IsRM"
                  id="outlined-margin-normal"
                  margin="normal"
                  name="isrm"
                  onChange={handleChange}
                  
                />
              </FormControl>
            </li>
            <li>
              <Button className="api-btn" type="submit" onClick={(event) => createfunc(event)}> Create </Button>
              <Button className="api-btn cancel-btn" type="submit" onClick={(event) => cancelcreate(event)}> Cancel </Button>
            </li>
          </ul>
          
        ):<Button className="api-btn" type="submit" onClick={(event) => flagfunc(event)}>
        Create New RM
      </Button>
    }     
    </div>  
    <div>
    {RMMasterData?.length ? (
        <TableContainer component={Paper} className="api-log-table">
           <Table sx={{ minWidth: 1000 }} stickyHeader aria-label="sticky table">
             
             <TableHead className="table-head">
               <TableRow>
                 <TableCell width="100px">Email ID</TableCell>
                 <TableCell >User ID</TableCell>
                 <TableCell >User Name</TableCell>
                 <TableCell >Employee ID</TableCell>
                 <TableCell >Product ID</TableCell>
                 <TableCell >Product Name</TableCell>
                 <TableCell >Source</TableCell>
                 <TableCell >Sub Source</TableCell>
                 <TableCell >CompanyDomainName</TableCell>
                 <TableCell >IsRM</TableCell>
                 <TableCell >Created By</TableCell>
                 <TableCell >Created On</TableCell>
                 <TableCell >Updated By</TableCell>
                 <TableCell >Updated On</TableCell>
                 <TableCell >Action</TableCell>
                 {/* <TableCell >SNo</TableCell> */}
               </TableRow>
             </TableHead>
             <TableBody  className="api-log-table-body">
             {RMMasterData.map((row, i) => (
                 <TableRow
                   key={i}
                   sx={{
                     "&:last-child td, &:last-child th": { border: 0 },
                   }}
                 >
                   <TableCell>{row.EmailID} </TableCell>
                   <TableCell>{row.UserID}  </TableCell>
                   <TableCell>{row.UserName}  </TableCell>
                   <TableCell>{row.EmployeeID}  </TableCell>
                   <TableCell>{row.ProductID}  </TableCell>
                   <TableCell>{row.ProductName}  </TableCell>
                   <TableCell>{row.Source}  </TableCell>
                   <TableCell>{row.SubSource}  </TableCell>
                   <TableCell>{row.CompanyDomainName}  </TableCell>
                   <TableCell>{row.IsRM}  </TableCell>
                   <TableCell>{row.CreatedBy}  </TableCell>
                   <TableCell>{row.CreatedOn}  </TableCell>
                   <TableCell>{row.UpdatedBy}  </TableCell>
                   <TableCell>{row.UpdatedOn}  </TableCell>
                   {/* <TableCell name="sno">{row.SNo}</TableCell> */}
                   <TableCell><Button  className="edit-delete-btn" type="submit" onClick={() => clickfunc(row.SNo, row.EmailID, row.UserID, row.UserName, 
                    row.EmployeeID, row.ProductID, row.ProductName, row.Source, row.SubSource, row.CompanyDomainName, row.IsRM,
                    )}>Edit</Button> &nbsp;
                            <Button  className="edit-delete-btn" type="submit"onClick={(event) => deletefunc(row.APIName, row.UID ,row.SNo)}>Delete</Button></TableCell>
                 </TableRow>
               ))}
             </TableBody>
             <TableFooter>
      <TableRow>
        
      </TableRow>
    </TableFooter>
           </Table>
         </TableContainer>
          ): null}
    </div>
    
    {flag1?.length ? (
      <ul className="listing">
        <h2>Edit Details</h2>                
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Email ID"
                  value={defemailid}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defemailid"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="User ID"
                  value={defuserid}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defuserid"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="User Name"
                  value={defusername}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defusername"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Employee ID"
                  value={defemployeeid}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="updemployeeid"
                  onChange={defemployeeid}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Product ID"
                  value={defproductid}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defproductid"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Product Name"
                  value={defproductname}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defproductname"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Source"
                  value={defsource}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defsource"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Sub Source"
                  value={defsubsource}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defsubsource"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="Company Domain Name"
                  value={defdomainname}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defdomainname"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            <li>
              <FormControl className="form-control">
                <TextField
                  label="IsRM"
                  value={defisrm}
                  id="outlined-margin-normal"
                  margin="normal"
                  name="defisrm"
                  onChange={handleChangeUpdate}
                  
                />
              </FormControl>
            </li>
            
            
            <li>
            <Button className="api-btn" type="submit" onClick={(event) => updatefunc(event)}>  Update </Button>
            <Button className="api-btn cancel-btn" type="submit" onClick={(event) => cancel(event)}>  Cancel </Button>
            </li>
          </ul>
           ): null}
        
    
    </div>
    )
}
