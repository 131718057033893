import React, { useState, useContext, useRef } from "react";
import Menu from "@material-ui/core/Menu";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { POST } from "../../../services/apiServices";
import {
  UpdateAutoCommStatus,
  ToggleRefundStatusOfTicket,
  CreateTicketEscalation,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import AssignToDialog from "./components/AssignToDialog";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import TicketContext from "../../../TicketContext";
import EsclationFormDialog from "./components/EsclationFormDialog";
import {
  isResetRefundEligible,
  SubSourceListForEscalation,
} from "../../../Config";
import {
  InputLabel,
  Select,
  Grid,
  FormControl,
  Button,
  TextareaAutosize,
  FormHelperText,
  TextField,
  MenuItem,
  Slide,
  Typography,
  Dialog,
} from "@material-ui/core";
import FileUploadComponent from "../../../shared/components/FileUploadComponent";
import {
  validateEmail,
  convertToIsoFormate,
  handleDrag,
  handleDragIn,
  handleDragOut,
  handleDrop,
} from "../../../shared/methods/methods";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Escalate = ({
  isEscalateDialogOpen,
  setIsEscalateDialogOpen,
  TicketId,
  TicketDetailsID,
  RefObjectId,
  LeadId,
  ProductID,
  StatusID,
}) => {
  const classes = useStyles();
  const ticketContext = useContext(TicketContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openAssignTo, setAssignTo] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isResetRefundClicked, setIsResetRefundClicked] = useState(false);
  const [formValues, setFormValues] = useState({
    Remarks: "",
    SubSource: "",
  });
  const [isTouched, setIsTouched] = useState({
    Remarks: false,
    SubSource: false,
  });
  const [selectedFile, setselectedFile] = useState([]);
  const hiddenFileInput = useRef(null);
  const [isErr, setIsErr] = useState({
    Remarks: false,
    SubSource: false,
  });

  const handleClose = () => {
    setIsEscalateDialogOpen(false);
  };

  const handleChange = (e) => {
    if (e && e.persist) {
      e.persist();
    }
    const { value, name } = e.target;
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
    setIsTouched((isTouched) => ({
      ...isTouched,
      [name]: true,
    }));
  };

  const handleRemoveFile = (id) => {
    const fileList = selectedFile.filter((item) => item.id !== id);
    setselectedFile(fileList);
  };

  const hasError = (field) => {
    let err = false;
    if (!formValues[field]) {
      err = true;
    }
    return err;
  };

  const validate = () => {
    if (hasError("Remarks") || hasError("SubSource")) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    setIsTouched((isTouched) => ({
      ...isTouched,
      Remarks: true,
      SubSource: true,
    }));
    if (validate()) {
      const payload = {
        TicketId: TicketId,
        TicketDetailsId: TicketDetailsID,
        Remark: formValues.Remarks,
        Attachments:
          selectedFile.length > 0
            ? selectedFile.map((item) => ({
                FileName: item.FileName,
                AttachemntContent: item.AttachemntContent,
              }))
            : [],
        RefObjectId: RefObjectId,
        SubSource: formValues.SubSource,
        LeadID: LeadId,
        ProductID: ProductID,
        ProductName: "",
        StatusID: StatusID,
        StatusName: "",
        SubStatusName: "",
      };
      POST(CreateTicketEscalation, payload)
        .then((res) => {
          if (res.ErrorCode == 0) {
            if (res.Data) {
              enqueueSnackbar("Success", {
                variant: "success",
                onClick: () => {
                  closeSnackbar();
                },
              });
              handleClose();
              window.location.reload();
            } else {
              enqueueSnackbar(res.Message || "Some error occurred", {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            }
          } else {
            enqueueSnackbar(res.Message || "Some error occurred", {
              variant: "error",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <div
      onDragOver={handleDrag}
      onDrop={(e) => handleDrop(e, setselectedFile, hiddenFileInput)}
      onDragLeave={handleDragOut}
      onDragEnter={handleDragIn}
    >
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={isEscalateDialogOpen}
        className="endorement-dialog-box"
        maxWidth="md"
      >
        {/* <Button className="close-icon" onClick={handleClose}>
          <CloseIcon />
        </Button> */}
        <div className="endorement-inner-box">
          <h3>
            {/* <img width="50px" src="/images/raised_ticket.png" /> */}
            Escalation
          </h3>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12} xs={12}>
              <div className="comment-box">
                <label>Remarks</label>
                <TextareaAutosize
                  placeholder="Remarks"
                  onChange={handleChange}
                  name="Remarks"
                >
                  {formValues?.Remarks}
                </TextareaAutosize>
                {hasError("Remarks") && isTouched["Remarks"] ? (
                  <FormHelperText className="error-msg">
                    Required
                  </FormHelperText>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid item md={12} xs={12} className="text-center">
              <FormControl variant="outlined" className="select-box">
                <InputLabel id="demo-simple-select-outlined-label">
                  Sub-Source
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={formValues?.SubSource}
                  label="Sub-Source"
                  onChange={handleChange}
                  name="SubSource"
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {SubSourceListForEscalation.map((opt, i) => (
                    <MenuItem key={i} value={opt}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
                {hasError("SubSource") && isTouched["SubSource"] ? (
                  <FormHelperText
                    className="error-msg"
                    style={{ marginTop: "10px" }}
                  >
                    Required
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <p className="upload-btn">
                <FileUploadComponent
                  setFiles={setselectedFile}
                  fileRef={hiddenFileInput}
                />
                {selectedFile.map((item) => {
                  return (
                    <span className="file-detail" key={item.id}>
                      {item.FileName}{" "}
                      <Button onClick={() => handleRemoveFile(item.id)}>
                        <CloseIcon />
                      </Button>
                    </span>
                  );
                })}
              </p>
            </Grid>
            <Grid itme md={12} xs={12}>
              <Button className="action-btn" onClick={handleSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

export default Escalate;
