import React, {useState, useEffect, useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button,Dialog,Typography,Slide,Grid,FormControl,InputLabel,Select,TextField,FormHelperText} from '@material-ui/core';
import SelectAgentList from './SelectAgentList';
import { GET, POST } from '../../../../services/apiServices';
import {
  GetGroupByProduct_AgentType,
  GetDialerAgentList,
  GetTicketDetails
} from "../../../../constants/apiUrls";
import { useSnackbar } from 'notistack'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor:"#ffffff !important",
    color:"#414141",
  },
  title: {
    flex: 1,
  },
  formControl: {
    minWidth: "100%",
  },
  h6:{
    color:"#414141",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
  
});

const AssignToDialog = ({
  isAssignToDialogOpen,
  setAssignToDialogOpen,
  masters,
  TicketDetailsID,
  RefObjectId,
  setFormValues,
  UserProductID
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    agentType: -1,
    group: 0,
    product: 0,
    empId: null,
    agentTypeErr:""
  });

  const [productList, setProductList] = useState() // master list / productlist

  const [touched, setTouched] = useState({
    agentType: false,
    group: false,
    product: false,
  })
  const [groupMaster, setGroupMaster] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if(state.agentType && state.product){
      setState((state) => ({
        ...state,
        group: 0
      }))
      setGroupMasterByProduct()
    }
  }, [state.agentType, state.product])

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setTouched({
      ...touched,
      [name]: true
    })
    setAgentsList([])
  };

  const setGroupMasterByProduct = () => {
    if(state.agentType!=-1){
    GET(`${GetGroupByProduct_AgentType}/${state.agentType}/${state.product}`)
      .then((response) => {
        if(response.ErrorCode == 0){
          if(response.Data.length){
            setGroupMaster(response.Data);
          }
          else{
            enqueueSnackbar("No groups found", {
              variant: "error",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
        }
      })
      .catch(err=>{
        console.log(err);
      })
  }
}

  const search = (event) => {
    if(event){
      event.persist()
    }
    setState((state) => ({
      ...state,
      [event.target.name]: event.target.value
    }))
    searchButtonPressed(event.target.value)
  }

  const hasError = (field) => {
    let error;
    if(!state[field]){
      if((field == 'group' || field == 'empId') && (!state['group'] && !state['empId'])){
        error = 'Group or Emp name/id: either 1 is required'
      }
      else if(field == 'agentType' || field == 'product'){
        error = 'Field cannot be empty'
      }
    }
    return error
  }

  const validate = () => {
    if((hasError('agentType') || hasError('product')) || (hasError('group') && hasError('empId'))){
      return false
    }
    return true
  }

  const searchButtonPressed = (value) => {
    setTouched({
      agentType: true,
      group: true,
      product: true,
    })
    if(!validate()) return
    const payload = {
      ProductID : state.product,
      AgentType : Number(state.agentType)+1,
      GroupID : state.group,
      AgentNameEmpID: value,
      Type:"2"
    }
    if ((value && value.length >= 3) || !value) {
      POST(GetDialerAgentList, payload)
      .then(response => {
        setAgentsList(response.Data);
      })
    }
    
  }

  useEffect(()=>{
    UserProductID && GET(`${GetTicketDetails}/${UserProductID}`).then(res=>{
      if(res.Data ){
        const productList = [
          ...new Map(
            res.Data.suppliersList.map((item) => [item["ProductID"], item])
          ).values(),
        ];
      setProductList(productList)
      }  
    }).catch(err=>{
      console.log(err)
    })
  },[UserProductID])
 

  return (
    <div>
      <Dialog className="dialog-box-assign"  fullScreen open={isAssignToDialogOpen} onClose={()=>setAssignToDialogOpen(false)} TransitionComponent={Transition} aria-labelledby="responsive-dialog-title">
        <AppBar className={classes.appBar}>
          <Toolbar>
          <IconButton edge="start" color="inherit" onClick={()=>setAssignToDialogOpen(false)} aria-label="close">
          <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Assign Ticket
          </Typography>
          </Toolbar>
        </AppBar>
        <div  className="all-comments">
           <Button autoFocus color="inherit" className="close-btn" >
               X
            </Button>
            <Typography variant="h6" className={classes.title}>
              
            </Typography>
            <div className="assign-to-data">
              <Grid container spacing={1} className="border-bottom">
                <Grid item md={4} xs={12}>
                  <FormControl 
                    variant="outlined" className={classes.formControl}
                    error={
                      hasError('agentType') && touched['agentType']
                    }
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">Agent Type</InputLabel>
                    <Select
                      native
                      value={state.agentType}
                      onChange={handleChange}
                      label="Agent Type"
                      inputProps={{
                        name: 'agentType',
                        id: 'outlined-age-native-simple',
                      }}
                      >
                      <option aria-label="Agent" value={-1} disabled>Select Agent</option>
                      <option aria-label="Sales" value={0}>Sales</option>
                      <option aria-label="CRT" value={1}>CRT</option>
                      {/* {
                        masters && masters.agentTypeList.length && masters.agentTypeList.map((i) => <option value={i.ResponsibilityID}>{i.ResponsibilityName}</option>)
                      } */}
                    </Select>
                    {
                      hasError('agentType') && touched['agentType'] ?
                      <FormHelperText>
                        {
                          hasError('agentType')
                        }
                      </FormHelperText>
                      : null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl 
                    variant="outlined" 
                    className={classes.formControl}
                    error={
                      hasError('product') && touched['product']
                    }
                  >
                    <InputLabel htmlFor="outlined-age-native-simple" >Product</InputLabel>
                    <Select
                        native
                        value={state.product}
                        onChange={handleChange}
                        label="Product"
                        inputProps={{
                            name: 'product',
                            id: 'outlined-age-native-simple',
                        }}
                        >
                        <option aria-label="Product" value={0} disabled>Select Product</option>
                        {/* {productList} */}
                        {
                          productList && productList.length>0 ? productList.map((i) => <option value={i.ProductID}>{i.ProductName}</option>): null
                          }
                        
                      
                    </Select>
                    {
                      hasError('product') && touched['product'] ?
                      <FormHelperText>
                        {
                          hasError('product')
                        }
                      </FormHelperText>
                      : null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl 
                    variant="outlined" 
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">Group</InputLabel>
                    <Select
                        native
                        value={state.group}
                        onChange={handleChange}
                        label="Group"
                        inputProps={{
                            name: 'group',
                            id: 'outlined-age-native-simple',
                        }}
                        >
                        <option aria-label="Group" value={0}>Select Group</option>
                        {
                          groupMaster && groupMaster.length && groupMaster.map((i) => <option value={i.GroupID}>{i.GroupName}</option>)
                        }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={8} xs={8}>
                    <FormControl variant="outlined" 
                      className={classes.formControl}
                      error={
                        (hasError('empId') || hasError('group')) && touched['group']
                      }
                    >
                      <TextField
                        label="Employee Id/Name"
                        id="outlined-margin-normal"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={search}
                        name="empId"
                        value={state.empId}
                      />
                      {
                        (hasError('empId') || hasError('group')) && touched['group'] ?
                        <FormHelperText>
                          {
                            hasError('empId') || hasError('group')
                          }
                        </FormHelperText>
                        : null
                      }
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Button onClick={() => searchButtonPressed(state.empId)} className="search-btn">Search</Button>
                </Grid>
              </Grid>
              <SelectAgentList setFormValues={setFormValues}  TicketDetailsID={TicketDetailsID} RefObjectId={RefObjectId} setState={setState} setTouched={setTouched} setAssignToDialogOpen={setAssignToDialogOpen} agentsList={agentsList} setAgentsList={setAgentsList} isAssignToDialogOpen={isAssignToDialogOpen}/>
            </div>
          </div>
      </Dialog>
    </div>
  );
}

export default AssignToDialog;