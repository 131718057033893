import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {Button,RadioGroup,Checkbox,FormControlLabel,FormGroup} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
  InitiatePGRefundV2,
  GetTicketDetailsByTicketID,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import TicketContext from "../../../TicketContext";
import Spinner from "../../../shared/components/Spinner";
import { GET, POST } from "../../../services/apiServices";
import CancelIcon from "@material-ui/icons/Cancel";
import GeneratePDF from "../../../../src/Components/PGRefund/GeneratePDF";
import { useSelector } from "react-redux";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { 
    setGenerateDialog, 
    openPdfGenerater,
    isDialogOpen,
    isTicketTimelineLoading,
    LeadID,
    ProductID,
    Source,
    TicketDetailsID,
    IssueTypeId,
    RefObjectId,
    SupplierId,
    communications,
    ticketId,
    ticketCreationDate,
    viewtype,
    cancellationResponseData,
    formValues,
    state,
    setState,
    CustomerId,

} = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedMails, setSelectedMails] = useState([])
  const [areAttachmentsIncluded, setAreAttachmentsIncluded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorIdList, setErrorIdList] = useState([])
  const OpenedTicketDetails = useSelector(state => state.ticketdetails.ticketDetails)

  const handleClose = () => {
    setGenerateDialog(false);
  };

  // Include Attachement or not
  const hanleIncludeAttachement =(e) =>{
    setAreAttachmentsIncluded(e.target.checked)
  }

  // function checks unchecks mails with/without attachment based on user input

  const handleMailCheckUnckeck =(boolean, item, index)=>{
    if(boolean){
      let obj={
        key: index,
        val: item
      }
      setSelectedMails([...selectedMails, obj])
    }
    else{
      let filteredArrayWithoutCurrentMail = selectedMails.filter(item=> item.key != index)
      setSelectedMails(filteredArrayWithoutCurrentMail)
    }
  }

  // handling Multiple MailDownload
  const handleDownloadPDF =()=>{
    if(isLoading){// if user has already clicked and request is processing then nothing happens
      return 
    }
    let { TicketDetailsID, TicketId } = OpenedTicketDetails // from Redux Store
    var EmailDetails 
    if(areAttachmentsIncluded){
      EmailDetails = selectedMails.map(item=>{
        let { From, Subject, ReceivedOn, Body, MailAttachments } = item.val
        let obj={
          BodyHtml :`
          <div>From: ${From} </div>
          <div>To: care@policybazaar.com </div>
          <div>Subject: ${Subject}</div>
          <div>Date: ${new Date(ReceivedOn)}</div>
          <br/>
          <div>${Body}</div>
          `,
          EmailDataId : item.key,
          AttachmentData : MailAttachments && MailAttachments.length>0 ? MailAttachments.map(item=>{
            return{
                DocId : item.DocId,
                DocName : item.FileName
              }
          }) : []
        }
        return obj
      })
    }
    else{
      EmailDetails = selectedMails.map(item=>{
        let { From, Subject, ReceivedOn, Body } = item.val
        let obj={
          BodyHtml :`
          <div>From: ${From} </div>
          <div>To: care@policybazaar.com </div>
          <div>Subject: ${Subject}</div>
          <div>Date: ${new Date(ReceivedOn)}</div>
          <br/>
          <div>${Body}</div>
          `,
          EmailDataId : item.key
        }
        return obj
      })
    }
    
    let payload ={
      'TicketDetailsId' : TicketDetailsID,
      'EmailDetails': EmailDetails,
      'TicketId': TicketId
    }
    setIsLoading(true)
    POST('/MergeEmailtoPDF', payload).then(res=>{
      if(res && res.ErrorCode==0){
        setErrorIdList([]);
        setIsLoading(false)
         let newWindow=window.open(res.Data.PDFUrl, '_blank')
         if(newWindow){
          newWindow.focus();
         }
         else{
          enqueueSnackbar("Please allow popup to view PDF", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
          return
         }
      }
      else if( res && res.ErrorCode==1 && res.Message){
        setErrorIdList([])
        if(res.Data && res.Data.ErrorIdList && res.Data.ErrorIdList instanceof Array)
        {
          setErrorIdList(res.Data.ErrorIdList)
        }
        enqueueSnackbar(res.Message, {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        }
        );
        setIsLoading(false)
      }
      else{
        setErrorIdList([]);
        enqueueSnackbar("Oops! Something's not right", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
        setIsLoading(false)
      }
    }).catch(err=>{
      setErrorIdList([]);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        onClick: () => {
          closeSnackbar();
        },
      });
      console.log(err)
      setIsLoading(false)
    })
    
  }

  

  return (
    <div>
      <Dialog
        fullWidth
        className="generate-pdf-popup"
        onClose={isLoading ? null : handleClose}
        aria-labelledby="customized-dialog-title"
        open={setGenerateDialog}
      >
          <Button
            onClick={isLoading ? null : handleClose}
            className="cancel-button"
          >
            <CancelIcon />
          </Button>
        <DialogTitle className="title-heading" id="customized-dialog-title">
          Generate PDF
        </DialogTitle>
         <div className="include-pdf">
           <span>
           Select customer event(s) to merge
           </span>
            <span className="checkbox-data">
              <FormControlLabel
                value="Include Attachment(s)"
                control={<Checkbox color="primary"  onChange={(event)=>hanleIncludeAttachement(event)} />}
                label="Include Attachment(s)"
                labelPlacement="end"
              />
            </span>
          </div>
        {isTicketTimelineLoading ? (
          <div className="spinner-loader">
            <Spinner />
          </div>
        ) : (
          <>
          <DialogContent className="mail-inner-body scroll-btn">
            { communications && communications.length>0 ?
              (communications && communications.length>0 && communications.map((item, i) => {  
              return <>
              <div className="timeline-data">
                  <span className="select-checkbox">
                    <em><Checkbox onChange={(e) => handleMailCheckUnckeck(e.target.checked, item, i)}/> </em>
                  </span>
                    <GeneratePDF
                    ind={i}
                    item={item}
                    errorIdList = {errorIdList}
                    />
                </div>
              </>
                })
              ) : (
                <>
                  <div className="timeline-data">
                  <span className="select-checkbox">
                    <strong style={{fontSize:"17px",}}>There is no customer event from the following sources:</strong>
                    <span className="soucres">rmemails,  care, communications, myaccount, social, topmanagement, endorsementmyaccount</span>
                  </span>
                  </div>
                </>
                  )
            }
          </DialogContent>
          </>
        )}
        <DialogActions>
          <Button autoFocus className="download-pdf" onClick={handleDownloadPDF} color="primary" disabled={selectedMails && selectedMails.length==0}>
            {isLoading ? <>Download PDF <Spinner/></> : "Download PDF" }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
