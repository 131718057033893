import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  Select,
  Grid,
  FormControl,
  Button,
  TextField,
  MenuItem,
  FormHelperText,
  Slide,
  Typography,
  Dialog,
} from "@material-ui/core";
import { GET, POST } from "../../../../../services/apiServices";
import {
  GetIssueSubIssueAndLeadDetails,
  CreateTicketForCare,
  GetTicketSummary,
  GetEndorsementDetails,
} from "../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import Spinner from "../../../../../shared/components/Spinner";
import { checkProductIssueSubIssue, validateLeadId } from "../../../../../shared/methods/methods";
import TicketContext from "../../../../../TicketContext";
import CopyTicketIdDialog from "../../../../../shared/components/CopyTicketIdDialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { endorsementIssue, isEndorsementEligible } from "../../../../../Config";
import { useDispatch } from "react-redux";
import { setActiveTicketID } from "../../../../../redux/actions/ticketDetails";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    minWidth: "100%",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#ffffff !important",
    color: "#414141",
  },
  title: {
    flex: 1,
    fontSize: "16px",
  },
  formControl: {
    minWidth: "100%",
  },
  h6: {
    color: "#414141",
    fontSize: "14px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CreateTicketDialog({
  isCreateDialogOpen,
  setCreateDialog,
  subSource,
  LeadID,
  Body,
  Attachments,
  From,
  To,
  CC,
  RefObjectId,
  TicketDetailsID,
}) {
  const classes = useStyles();
  const ticketContext = useContext(TicketContext);
  const dispatch = useDispatch();
  const [leadId, setLeadId] = useState(LeadID);
  const [showFetchDetails, setShowFetchDetails] = useState(false);
  const [createTicketFormValues, setCreateTicketFormValues] = useState({});
  const [issueTypeMaster, setIssueTypeMaster] = useState([]);
  const [issueOptions, setIssueOptions] = useState([]);
  const [subIssueOptions, setSubIssueOptions] = useState([]);
  const [isFetchingDetail, setFetchingDetail] = useState(false);
  const [issue, setIssue] = useState({ issueId: "", issueName: "" });
  const [subIssue, setSubIssue] = useState({
    subIssueId: "",
    subIssueName: "",
  });
  const [isCreateTicketInProgress, setIsCreateTicketInProgress] =
    useState(false);
  const [errors, setErrors] = useState({
    leadId: false,
    issue: false,
    subIssue: false,
  });
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [TicketCreatedMsg, setTicketCreatedMsg] = useState("");
  const [raiseEndorsementURL, setRaiseEndorsementURL] = useState("");
  const [open, setOpen] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);
  const iframeRef = useRef(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChangeLeadId = (e) => {
    setErrors((prev) => ({ ...prev, issue: false, subIssue: false }));
    setCreateTicketFormValues({});
    setIssueOptions([]);
    setIssueTypeMaster([]);

    setLeadId(e.target.value);
    setShowFetchDetails(true);
  };

  const handleClose = () => {
    setCreateDialog(false);
    setShowFetchDetails(false);
    setErrors({ leadId: false, issue: false, subIssue: false });
    setSubIssue({ subIssueId: "", subIssueName: "" });
    setIssue({ issueId: "", issueName: "" });
    setSubIssueOptions([]);
    setLeadId(LeadID);
    // GetIssueSubIssueAndLeadDetailsapi(LeadID);
  };

  const GetIssueSubIssueAndLeadDetailsapi = (lead_id) => {
    setFetchingDetail(true);
    if (leadId || leadId == 0) {
      GET(`${GetIssueSubIssueAndLeadDetails}/${lead_id}/${1}`)
        .then((response) => {
          if (response.ErrorCode === 0) {
            setCreateTicketFormValues(response.Data.leadDetailResForUI);
            const issueList = response.Data.issueTypeMasters.filter(
              (item) => item.ParentID === 0
            );
            setIssueOptions(issueList);
            setIssueTypeMaster(response.Data.issueTypeMasters);
          } else {
            enqueueSnackbar("Something went wrong!", {
              variant: "error",
              onClick: () => {
                closeSnackbar();
              },
            });
          }
          setFetchingDetail(false);
        })
        .catch((err) => {
          setFetchingDetail(false);
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        });
    }
  };

  const getAllTicketSummary = (RefObjectId, TicketDetailsID) => {
    ticketContext.setAllTicketsLoading(true);
    GET(`${GetTicketSummary}/${RefObjectId}/${TicketDetailsID}`)
      .then((response) => {
        if (response.ErrorCode === 0) {
          const sortedData1 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter(
                (i) => i.StatusID != 3 && i.StatusID != 4
              ).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData2 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 3).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          const sortedData3 = response.Data.AllTicketList
            ? response.Data.AllTicketList.filter((i) => i.StatusID == 4).sort(
                (a, b) =>
                  (b.LastUpdatedOn || b.CreatedOn) -
                  (a.LastUpdatedOn || a.CreatedOn)
              )
            : [];
          ticketContext.setAllTickets({
            ...response.Data,
            AllTicketList: [...sortedData1, ...sortedData2, ...sortedData3],
          });
          dispatch(setActiveTicketID(response.Data.TicketId))
          ticketContext.setActiveTicketId(response.Data.TicketId);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }

        ticketContext.setAllTicketsLoading(false);
      })
      .catch((err) => {
        ticketContext.setAllTicketsLoading(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };

  const getSupplierName = () => {
    if (createTicketFormValues && createTicketFormValues.SupplierID) {
      const supplier = ticketContext.suppliersList.find(
        (item) => item.SupplierID === createTicketFormValues.SupplierID
      );
      if (supplier) {
        return supplier.SupplierName;
      }
    }
    return "";
  };

  const handleCreateTicket = () => {
    if (isCreateTicketInProgress) {
      return;
    }

    if (isEndorsementEligible(createTicketFormValues.ProductID, createTicketFormValues.SupplierID, createTicketFormValues?.InvestmentTypeID) && checkProductIssueSubIssue(createTicketFormValues.ProductID, issue?.issueName, subIssue?.subIssueName) && createTicketFormValues?.LeadID
    //  issue.issueName.toLowerCase() == endorsementIssue
    ) {
      setOpen(true);
      return;
    }

    !issue.issueName && setErrors((prev) => ({ ...prev, issue: true }));
    !subIssue.subIssueName &&
      setErrors((prev) => ({ ...prev, subIssue: true }));

    if (!issue.issueName || !subIssue.subIssueName) {
      return;
    }

    const {
      PolicyNo,
      ProductID,
      ProductName,
      SupplierID,
      SupplierName,
      EmailID,
      LeadID,
      RegistrationNo,
      ApplicationNo,
      CustomerName,
    } = createTicketFormValues;
    const payload = {
      Name: CustomerName,
      PolicyNumber: PolicyNo,
      ClaimNumber: null,
      Source: "PB Manual",
      ProductID: ProductID,
      ProductName: ProductName,
      InsurerID: SupplierID,
      InsurerName: SupplierName || getSupplierName(),
      EmailID: EmailID,
      To: To,
      CC: CC,
      Comments: Body,
      DialerUniqueID: null,
      Subject: null,
      LeadID: LeadID,
      CustomerID: 0,
      Issue: issue.issueId,
      IsCallBackRequest: 0,
      AutoClosure: 0,
      Attachments: Attachments,
      RefObjectId: null,
      FollowUpDate: null,
      AddressDetails: null,
      Pincode: 0,
      RegistrationNo: RegistrationNo,
      ApplicationNo: ApplicationNo,
      QueryTypeID: 0,
      IssueIn: 0,
      SubIssueIn: 0,
      SubIssueId: subIssue.subIssueId,
      SubSource: ticketContext.sourceForCreateTicket,
      IsAttachment: Array.isArray(Attachments)
        ? Attachments.length > 0
          ? true
          : false
        : false,
      PrevRefObjectId: null,
      SubIssueIDList: null,
      IsMultipleEndorsement: false,
    };
    setIsCreateTicketInProgress(true);
    POST(CreateTicketForCare, payload)
      .then((response) => {
        if (response.ErrorCode === 0) {
          setTicketCreatedMsg(`Ticket ID: ${response.Data.TicketDetailsId}`);
          setDialogOpen(true);
          handleClose();
          ticketContext.GetTicketLogsApi();
          ticketContext.GetFilterCountApi();
          getAllTicketSummary(RefObjectId, TicketDetailsID);
          ticketContext.GetTicketCommApi(RefObjectId, TicketDetailsID);
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            onClick: () => {
              closeSnackbar();
            },
          });
        }
        setIsCreateTicketInProgress(false);
      })
      .catch((err) => {
        console.log(err);
        setIsCreateTicketInProgress(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          onClick: () => {
            closeSnackbar();
          },
        });
      });
  };

  const handleFetchDetail = () => {
    if (validateLeadId(leadId)) {
      return;
    }

    GetIssueSubIssueAndLeadDetailsapi(leadId);
    setShowFetchDetails(false);
  };

  // useEffect(() => {
  //   GetIssueSubIssueAndLeadDetailsapi(LeadID);
  // }, []);

  useEffect(() => {
    const subIssueOptionsList = [];
    if (issue.issueName) {
      issueTypeMaster.map((item) => {
        if (item.ParentID === issue.issueId) {
          subIssueOptionsList.push(item);
        }
      });
      setSubIssueOptions(subIssueOptionsList);
    }
  }, [issue.issueName]);

  const closeRaiseEndorsement = () => {
    setRaiseEndorsementURL(null);
    setOpenIframe(false);
    handleClose();
    window.location.reload();
  };

  const handleEndorsementClose = () => {
    setOpen(false);
    // window.location.reload();
  };

  const raiseEndorsementFunc = () => {
    setOpenIframe(true);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        className="dialog-create-ticket"
        fullScreen
        open={isCreateDialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="right"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Create New Ticket
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="all-comments">
          {/* <Button autoFocus color="inherit" className="close-btn" onClick={handleClose}>
               X
            </Button>
            <Typography variant="h6" className={classes.title}>
                Create New Ticket
            </Typography> */}
        </div>
        <div className="create-new-ticket">
          <Grid container spacing={1}>
            <Grid item md={12} xs={12} className="select-box">
              <p className="source-box">PB Manual</p>
              {subSource && <p className="source-box">{subSource}</p>}
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={validateLeadId(leadId)}
              >
                <TextField
                  label="Enter Lead ID"
                  id="outlined-margin-normal"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="leadId"
                  value={leadId}
                  onChange={handleChangeLeadId}
                />
                <FormHelperText>{validateLeadId(leadId)}</FormHelperText>
              </FormControl>
            </Grid>
            {showFetchDetails ? (
              <Grid item md={12} xs={12}>
                <Button className="fetch-details" onClick={handleFetchDetail}>
                  <span>Fetch Details</span>
                </Button>
              </Grid>
            ) : isFetchingDetail ? (
              <div className="spinner-block">
                <Spinner />
              </div>
            ) : (
              <>
                <Grid item md={12} xs={12} className="select-box">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={!issue.issueName && errors.issue}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Issue
                    </InputLabel>
                    <Select
                      label="Issue"
                      inputProps={{
                        name: "issue",
                        id: "outlined-age-native-simple",
                      }}
                      value={issue?.IssueName}
                    >
                      {issueOptions.map((item) => {
                        return (
                          <MenuItem
                            value={item.IssueName}
                            onClick={() => {
                              if (
                                isEndorsementEligible(createTicketFormValues.ProductID, createTicketFormValues.SupplierID, createTicketFormValues?.InvestmentTypeID) &&
                                //  item.IssueName.toLowerCase() == endorsementIssue
                                checkProductIssueSubIssue(createTicketFormValues.ProductID, item.IssueName) && createTicketFormValues?.LeadID
                              ) {
                                const payload = {
                                  EndorsementID: 0, //10070,
                                  BookingID: leadId || 0, //43362530,
                                  TicketNumber: "",
                                  ProductID:
                                    createTicketFormValues.ProductID || 0,
                                  TicketID: 0,
                                  CustomerID: createTicketFormValues && createTicketFormValues.leadDetailResForUI &&  createTicketFormValues.leadDetailResForUI.CustomerID || 0,
                                };
                                POST(`${GetEndorsementDetails}`, payload)
                                  .then((res) => {
                                    setRaiseEndorsementURL(
                                      res.Data.RaiseNewEndorsementURL
                                    );
                                    setOpen(true);
                                    // setEndorsementDetails(res.Data.endorsements[0]);
                                    // setTableDetails(res.Data.endorsements[0].endorsedFields);
                                    // setEndorsementHistory(res.Data.endorsementHistory[0]);
                                  })
                                  .catch((e) => console.log(e));
                              }
                              setIssue({
                                issueId: item.IssueTypeId,
                                issueName: item.IssueName,
                              });
                              setErrors((prev) => ({ ...prev, issue: false }));
                            }}
                          >
                            {" "}
                            {item.IssueName}{" "}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!issue.issueName && errors.issue && (
                      <FormHelperText>Required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12} className="select-box">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={!subIssue.subIssueName && errors.subIssue}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Sub Issue
                    </InputLabel>
                    <Select
                      label="Sub Issue"
                      inputProps={{
                        name: "subIssue",
                        id: "outlined-age-native-simple",
                      }}
                      value={subIssue.subIssueName}
                    >
                      {subIssueOptions.map((item) => {
                        return (
                          <MenuItem
                            value={item.IssueName}
                            onClick={() => {
                              setSubIssue({
                                subIssueId: item.IssueTypeId,
                                subIssueName: item.IssueName,
                              });
                              setErrors((prev) => ({
                                ...prev,
                                subIssue: false,
                              }));
                            }}
                          >
                            {" "}
                            {item.IssueName}{" "}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!subIssue.subIssueName && errors.subIssue && (
                      <FormHelperText>Required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      label="customerName"
                      id="outlined-margin-normal"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={createTicketFormValues.CustomerName}
                      disabled="true"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      label="Email"
                      id="outlined-margin-normal"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={createTicketFormValues.EmailID}
                      disabled="true"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      label="product"
                      id="outlined-margin-normal"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={createTicketFormValues.ProductName}
                      disabled="true"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      label="insurer"
                      id="outlined-margin-normal"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        createTicketFormValues.SupplierName || getSupplierName()
                      }
                      disabled="true"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      label="Application No"
                      id="outlined-margin-normal"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      value={createTicketFormValues.ApplicationNo}
                      disabled="true"
                    />
                  </FormControl>
                </Grid>

                {(createTicketFormValues.ProductID == 117 ||
                  createTicketFormValues.ProductID === 114) && (
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        label="Registraion No"
                        id="outlined-margin-normal"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={createTicketFormValues.RegistrationNo}
                        disabled="true"
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item md={12} xs={12}>
                  <Button
                    className="submit-btn"
                    onClick={handleCreateTicket}
                    disabled={isCreateTicketInProgress}
                  >
                    {isCreateTicketInProgress ? (
                      <Spinner className="loder" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Dialog>
      <CopyTicketIdDialog
        isDialogOpen={isDialogOpen}
        setDialogOpen={setDialogOpen}
        Msg={TicketCreatedMsg}
        setTicketCreatedMsg={setTicketCreatedMsg}
      />
      <Dialog
        onClose={handleEndorsementClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        className="raised-dialog"
      >
        {/* <Button className="close-icon" onClick={handleClose}>
          <CloseIcon />
        </Button> */}
        <div className="raise-ticket-pop">
          <h3>
            <img width="50px" src="/images/raised_ticket.png" />
            Raise new endorsement request?
          </h3>

          <Button className="raise-cencel" onClick={handleEndorsementClose}>
            Cancel
          </Button>
          <Button className="raise-btn" onClick={raiseEndorsementFunc}>
            Raise Endorsement
          </Button>
        </div>
      </Dialog>
      <Dialog
        className="dialog-create-endorsement"
        fullScreen
        open={openIframe}
        // onClose={() => {return leadId ? null : handleClose}}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="right"
              color="inherit"
              onClick={closeRaiseEndorsement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Raise New Endorsement
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="endorsement-dialog-popup">
          <iframe
            style={{ width: "100%", height: "100vh" }}
            // id="followUpCalender-calender"
            ref={iframeRef}
            // className="iframe-box"
            src={raiseEndorsementURL}
          ></iframe>
        </div>
      </Dialog>
    </div>
  );
}
